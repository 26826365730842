import { FETCH_REPEAT_PAYMENT_OFFER } from '../../../constants';
import type { Offer } from 'models';

type RepeatPaymentOfferAction = {
  type: typeof FETCH_REPEAT_PAYMENT_OFFER;
  payload: Offer;
};

const repeatPaymentOffer = (
  state: Offer | Record<string, never> = {},
  action: RepeatPaymentOfferAction,
) => {
  switch (action.type) {
    case FETCH_REPEAT_PAYMENT_OFFER:
      return action.payload;
    default:
      return state;
  }
};

export default repeatPaymentOffer;
