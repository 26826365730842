import React, { forwardRef, HTMLAttributes, Ref } from 'react';

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  className?: string;
  htmlFor: string;
  required?: boolean;
  value: string;
}

const Label = forwardRef<HTMLLabelElement, LabelProps>(
  (
    { className, htmlFor, required, value, id, ...otherProps },
    ref: Ref<HTMLLabelElement>,
  ) => (
    <label
      className={['Label', className].filter(Boolean).join(' ')}
      {...otherProps}
      htmlFor={htmlFor}
      id={id}
      ref={ref}
    >
      {value}
      {required ? <span aria-hidden="true"> *</span> : null}
    </label>
  ),
);

Label.displayName = 'Label';

export { Label };
