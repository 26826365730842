import { useContext } from 'react';
import { CardTokenizationContext } from 'contexts/CardTokenizationContext/CardTokenizationContext';

function useCardTokenizationContext() {
  const context = useContext(CardTokenizationContext);

  if (context === undefined) {
    throw new Error(
      'useCardTokenizationContext must be used within a CardTokenizationProvider',
    );
  }

  return context;
}

export default useCardTokenizationContext;
