import apiV3 from 'services/apiV3';
import schemas from 'constants/schemas';
import { normalizeResponse } from 'utils/normalize/normalize';
import { SMSNotification } from 'models/smsNotification';
import { SMSNotificationResponse } from 'services/apiV3/payments/types';

export const smsNotifications = {
  enable: async ({ number, url }: { number: string; url: string }) => {
    const active = true;
    const payload = { active, number, url };
    const response = await apiV3.payments.updateSMSNotification(payload);

    return normalizeSmsNotifications(response);
  },
};

export const normalizeSmsNotifications = (
  smsNotification: SMSNotificationResponse,
): {
  entities: {
    smsNotification: Record<string, SMSNotification>;
  };
  result: string[];
} => normalizeResponse(smsNotification, schemas.smsNotification);
