import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { getRecipientCountriesForSelect } from 'selectors';
import { FieldInterface, FieldValueType } from '../Field';
import { RootState } from '../../../../reducers/types';
import { CountryForSelect } from 'models';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils/translations/useTranslations';

export interface CountryFieldInterface extends FieldInterface {
  value: Array<FieldValueType>;
}

export type CountryFieldProps = {
  countries: CountryForSelect[];
  field: CountryFieldInterface;
  handleBlur: () => void;
  handleChange: (name: string, value: string) => void;
};

const EMPTY_VALUE = '';

const CountryFieldComponent = ({
  countries,
  field,
  handleBlur,
  handleChange,
}: CountryFieldProps) => {
  const { defaultValue, error, help, label, id, readOnly, required } = field;

  const dataTestID = `data-testid-${id}`;
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [readOnlyState, setReadOnlyState] = useState(!!readOnly);

  const autocompleteHandleChange = (value: string) => {
    setSelectedValue(value);
    handleChange(id, value);
  };

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const found = countries.find((country: Record<string, string>) => {
      if (country.value !== defaultValue) {
        return false;
      }

      autocompleteHandleChange(defaultValue);
      return true;
    });

    if (!found) {
      setReadOnlyState(false);
      autocompleteHandleChange(EMPTY_VALUE);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const i18n = useTranslations();

  return (
    <Autocomplete
      name={id}
      onChange={autocompleteHandleChange}
      onBlur={handleBlur}
      options={countries}
      data-testid={dataTestID}
      selected={selectedValue}
      error={error}
      hint={help}
      required={!!required}
      readOnly={readOnlyState}
    >
      <Autocomplete.Input data-testid={dataTestID} placeholder={label} />
      <Autocomplete.Options />
      {help && (
        <Autocomplete.Hint className="FormGroup-hint">{help}</Autocomplete.Hint>
      )}
      {error && typeof error === 'string' && (
        <Autocomplete.Error className="FormGroup-feedback">
          <VisuallyHidden as="span">{label}</VisuallyHidden>
          {i18n.t(error)}
        </Autocomplete.Error>
      )}
    </Autocomplete>
  );
};

CountryFieldComponent.propTypes = {
  countries: PropTypes.array,
  field: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
};

const mapStateToProps = (state: RootState) => ({
  countries: getRecipientCountriesForSelect(state),
});

const CountryField = connect(mapStateToProps)(CountryFieldComponent);

export { CountryField };
