export type NonFXFeedbackResponses = {
  agent?: string;
  bank?: string;
  dealer?: string;
  nonBankingInstitution?: string;
  payer: string;
  payerValue?: string;
  sourceOfFunds: string;
  usdBetterRate?: string;
  usdOther?: string;
  usdPaymentReason: string;
};

export const formatNonFXFeedbackResponsesForRequest = (
  responses: NonFXFeedbackResponses,
) => ({
  fx_feedback_payer: responses.payer,
  fx_feedback_payer_value: responses.payerValue,
  fx_feedback_source_of_funds: responses.sourceOfFunds,
  fx_feedback_usd_payment_reason: responses.usdPaymentReason,
  ...(responses.agent && {
    fx_feedback_agent: responses.agent,
  }),
  ...(responses.dealer && {
    fx_feedback_dealer: responses.dealer,
  }),
  ...(responses.bank && {
    fx_feedback_bank: responses.bank,
  }),
  ...(responses.nonBankingInstitution && {
    fx_feedback_non_banking_institution: responses.nonBankingInstitution,
  }),
  ...(responses.usdBetterRate && {
    fx_feedback_better_fx_rate_from: responses.usdBetterRate,
  }),
  ...(responses.usdOther && {
    fx_feedback_another_provider: responses.usdOther,
  }),
});
