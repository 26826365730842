export const getCurrentUrl = () => window.location.origin || composeUrl();

const composeUrl = () => {
  const { port, protocol, hostname } = window.location;
  const url = `${protocol}//${hostname}`;

  if (port) return `${url}:${port}`;

  return url;
};
