export const FETCH_USER_RECURRING_RECEIVABLES_REQUEST =
  'FETCH_USER_RECURRING_RECEIVABLES_REQUEST';
export const FETCH_USER_RECURRING_RECEIVABLES_SUCCESS =
  'FETCH_USER_RECURRING_RECEIVABLES_SUCCESS';
export const FETCH_USER_RECURRING_RECEIVABLES_FAILURE =
  'FETCH_USER_RECURRING_RECEIVABLES_FAILURE';
export const FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_REQUEST =
  'FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_REQUEST';
export const FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS =
  'FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS';
export const FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE =
  'FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE';
export const CANCEL_RECURRING_RECEIVABLE_REQUEST =
  'CANCEL_RECURRING_RECEIVABLE_REQUEST';
export const CANCEL_RECURRING_RECEIVABLE_SUCCESS =
  'CANCEL_RECURRING_RECEIVABLE_SUCCESS';
export const CANCEL_RECURRING_RECEIVABLE_FAILURE =
  'CANCEL_RECURRING_RECEIVABLE_FAILURE';
export const IS_FETCHING_USER_RECURRING_RECEIVABLES_FLAG =
  'recurringReceivables';
export const IS_FETCHING_RECURRING_RECEIVABLE_CANCELLATION_FLAG =
  'recurringReceivableCancellation';
export const RECURRING_RECEIVABLE_INITIATED_STATUS = 'initiated';
export const RECURRING_RECEIVABLE_ACTIVE_STATUS = 'active';
export const RECURRING_RECEIVABLE_IN_PROGRESS_STATUS = 'in_progress';
export const RECURRING_RECEIVABLE_PAUSED_STATUS = 'paused';
export const RECURRING_RECEIVABLE_SCHEDULED_STATUS = 'scheduled';
export const RECURRING_RECEIVABLE_FINISHED_STATUS = 'finished';
export const RECURRING_RECEIVABLE_CANCELLED_STATUS = 'cancelled';
export const RECURRING_RECEIVABLE_PAYMENT_INITIATED_STATUS = 'initiated';
export const RECURRING_RECEIVABLE_PAYMENT_PENDING_STATUS = 'pending';
export const RECURRING_RECEIVABLE_PAYMENT_SUCCESS_STATUS = 'success';
export const RECURRING_RECEIVABLE_PAYMENT_FAILED_STATUS = 'failed';
export const RECURRING_RECEIVABLE_PAYMENT_CANCELLED_STATUS = 'cancelled';
export const CREATE_RECURRING_RECEIVABLE_FAILURE =
  'CREATE_RECURRING_RECEIVABLE_FAILURE';
export const CREATE_RECURRING_RECEIVABLE_REQUEST =
  'CREATE_RECURRING_RECEIVABLE_REQUEST';
export const CREATE_RECURRING_RECEIVABLE_SUCCESS =
  'CREATE_RECURRING_RECEIVABLE_SUCCESS';
export const RECURRING_SCHEMES = {
  ACH: 'ACH',
};

export const OPEN_MODAL_REMAINING_BALANCE_PAYMENT_CREATED =
  'OPEN_MODAL_REMAINING_BALANCE_PAYMENT_CREATED';

export const CLOSE_MODAL_REMAINING_BALANCE_PAYMENT_CREATED =
  'CLOSE_MODAL_REMAINING_BALANCE_PAYMENT_CREATED';
