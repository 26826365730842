import { LOGGER_URL } from 'constants/config';
import { PERMANENT_COOKIE_NAME } from 'constants/cookies';
import { cookies } from 'utils/cookies/cookies';

const DEFAULT_SCRIPT_ID = 'heap';

interface Heap {
  track: (event: string, properties?: unknown) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: unknown) => void;
  addEventProperties: (properties: unknown) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: unknown;
}

declare global {
  interface Window {
    heap: Heap;
  }
}

const getFetchOptions = (payload: Record<string, unknown>) => {
  const body = JSON.stringify(payload);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const method = 'POST';

  return { body, headers, method };
};

const log = (id: string) => {
  fetch(
    LOGGER_URL,
    getFetchOptions({
      id: `PAYEX_${id}`,
      peerTrackerId: cookies.read(PERMANENT_COOKIE_NAME),
      cookiesEnabled: navigator.cookieEnabled,
    }),
  );
};

const getHeapScript = (id: string | number) => `
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${id}", { secureCookie: true });
`;

const initialize = (id: string | number) => {
  if (!id) {
    throw new Error('You need to provide your Heap Analytics ID.');
  }

  if (!document) {
    return;
  }

  const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);

  if (hasScript) {
    return;
  }

  if (window.heap) {
    log('[PAAR-4486] window.heap is defined');
  }

  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.innerHTML = getHeapScript(id);
  script.id = DEFAULT_SCRIPT_ID;
  script.async = true;

  document.body.appendChild(script);
};

const Heap = {
  initialize,
};

export { Heap };
