import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTheme } from 'selectors';
import embeddedStyler from 'utils/embeddedStyler';
import type { RootState } from 'reducers/types';

export const EmbeddedStyleComponent = ({ theme }) => {
  const { brandColor } = theme;

  const button = embeddedStyler('button', brandColor);
  const inputFocus = embeddedStyler('inputFocus', brandColor);
  const textAreaFocus = embeddedStyler('textAreaFocus', brandColor);
  const autocompleteFocus = embeddedStyler('autocompleteFocus', brandColor);
  const checkbox = embeddedStyler('checkbox', brandColor);
  const modal = embeddedStyler('modal', brandColor);
  const site = embeddedStyler('site');
  const spinner = embeddedStyler('spinner', brandColor);

  return (
    <style>
      {button}
      {inputFocus}
      {textAreaFocus}
      {autocompleteFocus}
      {checkbox}
      {modal}
      {site}
      {spinner}
    </style>
  );
};

EmbeddedStyleComponent.propTypes = {
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state: RootState) => ({
  theme: getTheme(state),
});

const EmbeddedStyle = connect(mapStateToProps)(EmbeddedStyleComponent);

export { EmbeddedStyle };
