import React, { Children } from 'react';
import { WizardContext, WizardContextType } from '../context';

const Steps = ({ children }: { children: React.ReactNode }) => (
  <WizardContext.Consumer>
    {({ activeStepIndex }: WizardContextType) => {
      return Children.toArray(children)[activeStepIndex];
    }}
  </WizardContext.Consumer>
);

Steps.displayName = 'Steps';
export { Steps };
