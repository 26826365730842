import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils';
import { Spinner } from 'components/Spinner/Spinner';
import { isFetching } from 'selectors';
import { FIVE_SECONDS_IN_MS } from 'constants/index';

import './Fetching.scss';

const FetchingComponent = ({ children = null, isFetching }) => {
  const [isStillFetching, setIsStillFetching] = useState(false);
  const countdown = useRef(null);
  const i18n = useTranslations();

  useEffect(() => {
    if (!isFetching) {
      isStillFetching && setIsStillFetching(false);

      return;
    }

    countdown.current = setTimeout(() => {
      setIsStillFetching(true);
    }, FIVE_SECONDS_IN_MS);

    return () => clearTimeout(countdown.current);
  }, [isFetching, isStillFetching]);

  return (
    <>
      {isFetching && (
        <div className="Fetching">
          <Spinner role="status" data-testid="spinner">
            <span
              className={[isStillFetching ? '' : 'hidden']
                .filter(Boolean)
                .join(' ')}
              data-hidden={!isStillFetching}
              data-testid="isStillFetching"
              dangerouslySetInnerHTML={createMarkup(
                i18n.t('fetching.isStillFetching'),
              )}
            />
          </Spinner>
        </div>
      )}
      {typeof children === 'function' ? children({ isFetching }) : children}
    </>
  );
};

FetchingComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isFetching: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const { entity } = props;

  return {
    isFetching: isFetching(state, entity),
  };
};

const Fetching = connect(mapStateToProps)(FetchingComponent);

export { Fetching };
