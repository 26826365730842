import fetch from 'services/apiV3/fetch';
import { PAYEX_URL } from 'constants/index';
import {
  Currency,
  Field,
  GetOffersResponse,
  GetRecurringOffersResponse,
  OfferWithIds,
  Price,
  Section,
} from 'models';
import { normalizeResponse } from 'utils/normalize/normalize';
import schemas from 'constants/schemas';

export type GetAllOffersPayload = {
  amount: number;
  country: string;
  recipientId: string;
  fingerprint: string;
  locale: string;
};

export type GetRecurringOffersPayload = {
  amount: number;
  country: string;
  recipientId: string;
  installments: number;
};

export type OffersService = {
  getAll: (payload: GetAllOffersPayload) => Promise<GetOffersResponse>;
  getRecurringOffers: (
    payload: GetRecurringOffersPayload,
  ) => Promise<GetRecurringOffersResponse>;
};

export const offers: OffersService = {
  getAll: ({ amount, country, recipientId, fingerprint, locale }) => {
    const path = `${PAYEX_URL}/offers?amount=${amount}&country=${country}&recipient=${recipientId}&fingerprint=${fingerprint}&locale=${locale}`;
    const options = {
      method: 'GET',
    };

    return fetch(path, options);
  },
  getRecurringOffers: ({ amount, country, installments, recipientId }) => {
    const path = `${PAYEX_URL}/offers/recurring?installments=${installments}&amount=${amount}&country=${country}&recipient=${recipientId}`;

    const options = {
      method: 'GET',
    };

    return fetch(path, options);
  },
};

export const normalizeOffers = (
  offers: OfferWithIds[],
): {
  entities: {
    currencies: Record<string, Currency>;
    fields: Record<string, Field>;
    offers: Record<string, OfferWithIds>;
    prices: Record<string, Price>;
    sections: Record<string, Section>;
  };
  result: string[];
} => normalizeResponse(offers, schemas.arrayOfOffers);
