import React, { Fragment } from 'react';
import { Button } from 'lib/flywire-tailwind/Button';
import { Modal } from 'components/ui/Modal/Modal';
import { Heading } from 'components/Heading/Heading';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import { ENTER, SPACE } from 'constants/index';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils/translations/useTranslations';
import type { Price, Tax } from 'models';

import './TaxModal.scss';

type TaxModalProps = {
  hasOnlyGST: boolean;
  isOfferDBIN: boolean;
  isOpen: boolean;
  price: Price;
  taxes: Tax[];
  indianPartnerData: Record<string, string>;
  onConfirm?: () => void;
};

const TaxModal = ({
  hasOnlyGST,
  isOfferDBIN = false,
  isOpen = false,
  onConfirm,
  price,
  taxes,
  indianPartnerData,
}: TaxModalProps) => {
  const i18n = useTranslations();

  let totalPrice = price.value;

  const renderTaxes = () => {
    return taxes.map(tax => {
      const { amount, currency, id } = tax;
      totalPrice += amount;
      const taxTitle =
        tax.id === 'indian_tax' ? 'taxModal.tax' : `taxModal.${tax.id}`;

      return (
        <Fragment key={id}>
          <dt>{i18n.t(taxTitle)}</dt>
          <dd>
            <FormattedAmount value={amount} currency={currency} />
          </dd>
        </Fragment>
      );
    });
  };

  function getTaxesText() {
    if (hasOnlyGST) {
      return i18n.t('taxModal.text.only_gst', indianPartnerData);
    }
    return i18n.t('taxModal.text.tcs_gst', indianPartnerData);
  }

  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      allowClosing={false}
      className="TaxModal"
    >
      <Heading as="h3" size="large">
        {i18n.t('taxModal.title')}
      </Heading>
      <div
        className="TaxModal-description"
        dangerouslySetInnerHTML={createMarkup(getTaxesText())}
      />
      {isOfferDBIN && (
        <div className="TaxModal-warning">{i18n.t('taxModal.warning')}</div>
      )}
      <div className="TaxModal-taxes">
        <dt>{i18n.t('taxModal.price')}</dt>
        <dd>
          <FormattedAmount {...price} />
        </dd>
        {renderTaxes()}
      </div>
      <div className="TaxModal-taxes TaxModal-totalPrice">
        <dt>{i18n.t('taxModal.total')}</dt>
        <dd className="big">
          <FormattedAmount value={totalPrice} currency={price.currency} />
        </dd>
      </div>
      <footer>
        <Button
          tone="primary"
          data-testid="confirmButton"
          onMouseDown={onConfirm}
          onKeyDown={evt =>
            [ENTER, SPACE].includes(evt.keyCode) && onConfirm?.()
          }
        >
          {i18n.t('taxModal.accept')}
        </Button>
      </footer>
    </Modal>
  );
};

export { TaxModal };
