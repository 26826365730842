import React from 'react';
import PropTypes from 'prop-types';

function Name({ children, value }) {
  return (
    <h3 className="Offer-name" data-testid="name">
      {children || value}
    </h3>
  );
}

Name.displayName = 'Name';
Name.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

export { Name };
