import {
  getCurrentLocale,
  getPaymentId,
  getRecipientName,
  getSenderFieldValue,
  getSenderName,
} from 'selectors';
import { feedback } from 'services/feedback/feedback';
import type { RootState } from 'reducers/types';
import type { PayexThunkAction } from 'store/configureStore';

function getUser(state: RootState) {
  const { firstName, lastName } = getSenderName(state);
  const email = getSenderFieldValue(state, 'email');
  const country = getSenderFieldValue(state, 'country');
  const language = getCurrentLocale(state);

  return {
    firstName,
    lastName,
    email: email ? (email as string) : undefined,
    country: country ? (country as string).toLowerCase() : undefined,
    language,
  };
}

export const sendFeedback =
  (rating: number, comment: string | undefined): PayexThunkAction =>
  (_, getState) => {
    const state = getState();
    const paymentId = getPaymentId(state);

    const message = {
      value: rating,
      comment,
      payment: paymentId,
      user: getUser(state),
      recipient_name: getRecipientName(state),
    };

    feedback.send(message);
  };
