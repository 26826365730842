import {
  FETCH_USER_PAYMENTS_SUCCESS,
  LOGOUT_USER_SUCCESS,
} from '../../../constants';
import type { Payment } from 'models';

type PaymentsAction =
  | {
      type: typeof FETCH_USER_PAYMENTS_SUCCESS;
      payload: {
        payments: Payment[];
      };
    }
  | { type: typeof LOGOUT_USER_SUCCESS };

export const payments = (state: Payment[] = [], action: PaymentsAction) => {
  switch (action.type) {
    case FETCH_USER_PAYMENTS_SUCCESS:
      const { payments } = action.payload;

      return payments;
    case LOGOUT_USER_SUCCESS:
      return [];
    default:
      return state;
  }
};

export default payments;
