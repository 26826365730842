/* eslint-disable */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLocale as setStoreLocale } from 'actions';
import { browser, embedded } from 'repositories';
import EN from '../../utils/translations/locales/EN.json';
import { DEFAULT_LOCALE, LOCALE_COOKIE_NAME } from '../../constants/index';
import {
  SupportedLocalesKeys,
  locales,
  Translations,
  isValidLocale,
  getKeyFromLocaleCode,
  isRtlLocale,
} from 'utils/translations/translations';
import { cookies } from 'utils/cookies/cookies';

export interface TranslationContextInterface {
  locale: SupportedLocalesKeys;
  keys: Translations;
  fallbackKeys: Translations;
  setLocale: (locale: SupportedLocalesKeys) => void;
}

const loadLanguageFile = async (
  language: SupportedLocalesKeys,
): Promise<Translations> => {
  const key = locales[language];

  return await import(
    /* webpackChunkName: "locales/[request]" */ `utils/translations/locales/${key}.json`
  );
};

const defaultLanguageFile: Translations = EN;

const TranslationContext = createContext<TranslationContextInterface>({
  locale: DEFAULT_LOCALE as SupportedLocalesKeys,
  keys: defaultLanguageFile,
  fallbackKeys: defaultLanguageFile,
  setLocale: () => {},
});

const getUrlLocale = (): string | null => {
  return new URLSearchParams(window.location.search).get('locale');
};

const getInitialLocale = (): SupportedLocalesKeys => {
  const embeddedLocale = embedded.getProp('locale', '') as string;
  const urlLocale = getUrlLocale() || '';
  const previousLocale =
    getKeyFromLocaleCode(cookies.read(LOCALE_COOKIE_NAME) || '') || '';

  if (isValidLocale(embeddedLocale))
    return embeddedLocale as SupportedLocalesKeys;
  if (isValidLocale(urlLocale)) return urlLocale as SupportedLocalesKeys;
  if (isValidLocale(previousLocale))
    return previousLocale as SupportedLocalesKeys;

  return DEFAULT_LOCALE;
};

const setLanguageInformation = (locale: string) => {
  document.documentElement.setAttribute('lang', locale);
  const isCookiePermanent = false;

  cookies.set(
    LOCALE_COOKIE_NAME,
    locale,
    isCookiePermanent,
    browser.getFlywireSubdomain(),
  );

  document.documentElement.setAttribute(
    'dir',
    isRtlLocale(locale) ? 'rtl' : 'ltr',
  );
};

function TranslationProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const initialLocale = useMemo(() => {
    const initialLocale = getInitialLocale();
    dispatch(setStoreLocale(initialLocale));
    setLanguageInformation(initialLocale);
    return initialLocale;
  }, [dispatch]);
  const [locale, setLocale] = useState(initialLocale);
  const fallbackKeys = defaultLanguageFile;
  const [keys, setKeys] = useState(fallbackKeys);

  const loadLanguage = async (locale: SupportedLocalesKeys) => {
    setKeys(await loadLanguageFile(locale));
  };

  useEffect(() => {
    loadLanguage(locale);
  }, [locale]);

  const value: TranslationContextInterface = useMemo(
    () => ({
      keys,
      fallbackKeys,
      locale,
      setLocale: (locale: SupportedLocalesKeys) => {
        setLocale(locale);
        setLanguageInformation(locale);
        dispatch(setStoreLocale(locale));
      },
    }),
    [keys, fallbackKeys, locale, setLocale, dispatch],
  );

  if (!Object.keys(locales).includes(locale)) {
    setLocale(DEFAULT_LOCALE);
  }

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
}

export { TranslationContext, TranslationProvider };
