/* eslint-disable camelcase */

import { Decamelized } from 'humps';
import { CreateSessionPayload } from '../sessions';

type CreateMapper = {
  mapToBackend: (
    params: CreateSessionPayload,
  ) => Decamelized<CreateSessionPayload>;
};
export const createMapper: CreateMapper = {
  mapToBackend: params => {
    const { paymentMethodToken, recipientId, sessionType } = params;
    return {
      payment_method_token: paymentMethodToken,
      recipient_id: recipientId,
      session_type: sessionType,
    };
  },
};
