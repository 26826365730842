const EMPTY = '';

function cleanEmptyKeys(obj: Record<string, unknown>) {
  return Object.keys(obj).reduce((acc: Record<string, unknown>, key) => {
    if (obj[key] && obj[key] !== EMPTY) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
}

export { cleanEmptyKeys };
