import React, { ReactNode } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Wizard as Wizr } from './components/Wizard/Wizard';
import { Steps as WizardSteps } from './components/Steps/Steps';
import { Step as WizardStep } from './components/Step/Step';
import {
  goToStep,
  createPaymentFromOrder,
  createRecurringReceivable,
} from 'actions';
import {
  getCurrentStepIndex,
  getOrder,
  getPaymentStepsForWizard,
  isEmbedded,
  isFetching,
  isPaymentProcess,
  isRecurringOfferSelected,
} from 'selectors';
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop';
import { PaymentStepList } from 'containers/PaymentStepList/PaymentStepList';
import { Nothing } from 'components/Nothing/Nothing';
import {
  STEP_CARD_SURCHARGE,
  STEP_CONFIRMATION,
  STEP_OFFERS,
  STEP_PAY,
  STEP_PAYMENT,
  STEP_RECIPIENT,
  STEP_RECURRING_CARD_PAYMENT,
  STEP_RECURRING_DIRECT_DEBIT_PAYMENT,
  STEP_SENDER,
  PaymentSteps,
  RECURRING_RECEIVABLES_ROUTE,
} from 'constants/index';
import { PaymentInformation } from 'components/PaymentInformation/PaymentInformation';
import { PaymentOptions } from 'components/PaymentOptions/PaymentOptions';
import { SenderInformation } from 'components/SenderInformation/SenderInformation';
import { RecipientInformation } from 'components/RecipientInformation/RecipientInformation';
import { PaymentConfirmation } from 'components/PaymentConfirmation/PaymentConfirmation';
import { CardFormWrapper } from 'components/CardTokenization/CardFormWrapper';
import { CardPayment as RecurringCardPayment } from 'components/Recurring/CardPayment/CardPayment';
import { DirectDebit as RecurringDirectDebitPayment } from 'components/Recurring/DirectDebit/DirectDebit';
import type { RootState } from 'reducers/types';
import { Spinner } from '../Spinner/Spinner';

const StepViews: { [key in PaymentSteps]: ReactNode } = {
  [STEP_PAYMENT]: PaymentInformation,
  [STEP_OFFERS]: PaymentOptions,
  [STEP_SENDER]: SenderInformation,
  [STEP_RECIPIENT]: RecipientInformation,
  [STEP_CARD_SURCHARGE]: CardFormWrapper,
  [STEP_CONFIRMATION]: PaymentConfirmation,
  [STEP_RECURRING_CARD_PAYMENT]: RecurringCardPayment,
  [STEP_RECURRING_DIRECT_DEBIT_PAYMENT]: RecurringDirectDebitPayment,
};

type StepView = keyof typeof StepViews;

type WizardProps = ConnectedProps<typeof connector> & {
  stepIndex: number;
};

const WizardComponent = ({
  createPaymentFromOrder,
  createRecurringReceivable,
  goToStep,
  isEmbedded,
  isPaymentProcessCompleted,
  isRecurring,
  isCreatingRecurringReceivable,
  order: { id, token },
  stepIndex,
  steps,
}: WizardProps) => {
  const handleStepChanged = ({
    activeStepIndex,
  }: {
    activeStepIndex: number;
  }) => {
    window._sift.push(['_trackPageview']);
    return goToStep(activeStepIndex);
  };

  const handleLastWizardStep = async () => {
    if (isRecurring) {
      await createRecurringReceivable();
      return;
    }
    await createPaymentFromOrder();
  };

  if (isCreatingRecurringReceivable) {
    return <Spinner />;
  }

  if (isPaymentProcessCompleted) {
    if (isRecurring) {
      return <Navigate push to={RECURRING_RECEIVABLES_ROUTE} />;
    }
    return <Navigate push to={`/tracking/${id}?token=${token}`} />;
  }

  return (
    <div className="Wizard" data-testid="wizard">
      <Wizr
        baseUrl={`/${STEP_PAY}`}
        activeStepIndex={stepIndex}
        onStepChanged={handleStepChanged}
        onWizardFinished={handleLastWizardStep}
      >
        {!isEmbedded && <PaymentStepList />}
        <WizardSteps>
          {steps.map(step => {
            const stepId = [
              STEP_CARD_SURCHARGE,
              STEP_RECURRING_CARD_PAYMENT,
              STEP_RECURRING_DIRECT_DEBIT_PAYMENT,
            ].includes(step)
              ? STEP_PAY
              : step;
            const Step = StepViews[step as StepView] ?? Nothing;

            return (
              <WizardStep id={stepId} key={stepId}>
                <form
                  onSubmit={e => e.preventDefault()}
                  aria-labelledby="Page-title"
                  noValidate
                >
                  <ScrollToTop>
                    <Step />
                  </ScrollToTop>
                </form>
              </WizardStep>
            );
          })}
        </WizardSteps>
      </Wizr>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isEmbedded: isEmbedded(state),
  isPaymentProcessCompleted: !isPaymentProcess(state),
  order: getOrder(state),
  stepIndex: getCurrentStepIndex(state),
  steps: getPaymentStepsForWizard(state),
  isRecurring: isRecurringOfferSelected(state),
  isCreatingRecurringReceivable: isFetching(state, 'recurringReceivables'),
});

const mapDispatchToProps = {
  createPaymentFromOrder,
  createRecurringReceivable,
  goToStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Wizard = connector(WizardComponent);

export { Wizard };
