import React from 'react';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { goToStep } from 'actions/ui/ui';
import { trackChangeCountryOfCardIssuanceWhenBlocked } from 'actions/tracker/tracker';
import { getCountry } from 'selectors/entities/countries/countries';
import { Button } from 'lib/flywire-tailwind/Button';
import { Heading } from 'components/Heading/Heading';
import { Modal } from 'components/ui/Modal/Modal';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils/translations/useTranslations';
import type { RootState } from 'reducers/types';

import './RedirectionModal.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;
type RedirectionModalComponentProps = PropsFromRedux & {
  cardProductType: string;
  country: string;
  show: boolean;
};

const UNITED_KINGDOM = 'GB';

const RedirectionModalComponent = ({
  cardProductType,
  country,
  goToStep,
  show,
  trackChangeCountryOfCardIssuanceWhenBlocked,
}: RedirectionModalComponentProps) => {
  const i18n = useTranslations();

  const countryName = useSelector((state: RootState) => {
    const countryObject = getCountry(state, country);
    return countryObject ? countryObject.name : '';
  });

  let message = i18n.t('redirectionModal.warning', {
    country: countryName,
  });

  if (country === UNITED_KINGDOM) {
    message = i18n.t('gbp.confirm.blockedNonEEACards.description', {
      country: countryName,
    });
  }

  const trackChangeCountry = () => {
    trackChangeCountryOfCardIssuanceWhenBlocked({
      productType: cardProductType,
      country,
    });
  };

  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>): void => {
    evt.preventDefault();
    trackChangeCountry();
    goToStep(0);
  };

  const handleModalClose = () => {
    goToStep(0);
  };

  return (
    <Modal
      allowClosing={false}
      isOpen={show}
      onClose={handleModalClose}
      size="medium"
    >
      <div className="RedirectionModal" data-testid="redirection">
        <Heading as="h2" size="large" className="fontWeight-semiBold">
          {i18n.t('redirectionModal.title')}
        </Heading>
        <div className="RedirectionModal-description">
          <p dangerouslySetInnerHTML={createMarkup(message as string)} />
        </div>
        <footer>
          <Button
            tone="primary"
            size="full"
            onClick={handleClick}
            data-testid="changeCountryBtn"
          >
            {i18n.t('redirectionModal.button')}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  goToStep,
  trackChangeCountryOfCardIssuanceWhenBlocked,
};

const connector = connect(null, mapDispatchToProps);
const RedirectionModal = connector(RedirectionModalComponent);

export { RedirectionModal };
