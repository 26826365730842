import React from 'react';
import { TransactionError } from 'components/RecurringReceivables/components/TransactionError/TransactionError';
import { useDirectDebitPaymentContext } from '../../hooks/useDirectDebitPaymentContext';

import './Error.scss';

const FIRST_STEP = 0;

function Error() {
  const { directDebitWizardNavigation } = useDirectDebitPaymentContext();

  function handleOnRetry() {
    directDebitWizardNavigation.go(FIRST_STEP);
  }

  return (
    <TransactionError
      className="RecurringPayment-Error"
      handleOnRetry={handleOnRetry}
    />
  );
}

export { Error };
