export const ROOT_ROUTE = '/';
export const PAY_ROUTE = '/pay';
export const PAY_STEP_ROUTE = '/pay/:step';
export const ORDER_DETAIL_ROUTE = '/orders/:orderId';
export const ORDER_TRACKING_ROUTE = '/tracking/:orderId';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const REQUEST_RESET_PASSWORD_ROUTE = '/request-reset-password';
export const CHANGE_PASSWORD_ROUTE = '/change-password';
export const SIGN_UP_ROUTE = '/signup';
export const CAP_DUPLICATED_ROUTE = '/cap-duplicated';
export const GATEWAY_DUPLICATED_ROUTE = '/gateway-duplicated';
export const WIDGET_ROUTE = '/widget';
export const KYC_DETAILS_ROUTE = '/kyc/:kycId';
export const PAYMENTS_ROUTE = '/payments';
export const CASHBACK_ROUTE = '/rewards';
export const PAYMENT_DETAILS_ROUTE = '/payment/:paymentId';
export const PROFILE_ROUTE = '/profile';
export const PROFILE_SECTION_ROUTE = '/profile/:section';
export const PROFILE_MY_PAYMENT_METHODS_ROUTE = '/profile/my-payment-methods';
export const IMPERSONATE_ROUTE = '/impersonate/:accessToken';
export const REFUND_ROUTE = '/payment/:paymentId/refunds';
export const RECURRING_RECEIVABLES_ROUTE = '/receivables';
