import React, { createContext, useContext, useMemo } from 'react';
import { useStep } from '@flywire/react-hooks';
import { BankAccountInstructionsAttributes } from 'services/tokenization/models/models';
import { RECURRING_SCHEMES } from 'constants/index';
import { useSteps, Steps } from './useSteps';
import { useTranslations } from 'utils';
import type { Step } from 'models';

export type Navigation = {
  next: () => void;
  prev: () => void;
  go: (nextStep: number | string) => void;
};

export type CreateRecurringBody = {
  apiConfirmationURL: string;
  uuidToken: string;
};

interface DirectDebitPaymentContextType {
  accessToken: string;
  directDebitWizardNavigation?: Navigation;
  children: React.ReactNode;
  country: string;
  newNotification: ({ message }: { message: string }) => void;
  createRecurringReceivable: ({
    apiConfirmationURL,
    uuidToken,
  }: CreateRecurringBody) => void;
  paymentWizardNavigation: Navigation;
  recipientId: string;
}

export interface DirectDebitPaymentProviderType
  extends DirectDebitPaymentContextType {
  directDebitWizardNavigation: Navigation;
  currentStep: Step<Steps>;
  index: number;
  steps: Array<Step<Steps>>;
  instructionsAttributes?: BankAccountInstructionsAttributes;
}

const DirectDebitPaymentContext = createContext<DirectDebitPaymentProviderType>(
  {} as DirectDebitPaymentProviderType,
);

function DirectDebitPaymentProvider({
  accessToken,
  children,
  country,
  createRecurringReceivable,
  directDebitWizardNavigation,
  newNotification,
  paymentWizardNavigation,
  recipientId,
}: DirectDebitPaymentContextType) {
  const i18n = useTranslations();
  const steps = useSteps();
  const { index, navigation } = useStep({
    initialStep: 0,
    steps,
  });

  const instructionsAttributes = useMemo(
    () =>
      ({
        accessToken,
        country,
        locale: i18n.localeCode,
        recipientId,
        scheme: RECURRING_SCHEMES.ACH,
      } as BankAccountInstructionsAttributes),
    [accessToken, country, i18n.localeCode, recipientId],
  );

  const value = useMemo(
    () => ({
      accessToken,
      directDebitWizardNavigation: directDebitWizardNavigation || navigation,
      children,
      country,
      currentStep: steps[index],
      index,
      instructionsAttributes,
      paymentWizardNavigation,
      recipientId,
      steps,
      createRecurringReceivable,
      newNotification,
    }),
    [
      accessToken,
      directDebitWizardNavigation,
      children,
      country,
      index,
      instructionsAttributes,
      navigation,
      paymentWizardNavigation,
      recipientId,
      steps,
      createRecurringReceivable,
      newNotification,
    ],
  );

  return (
    <DirectDebitPaymentContext.Provider value={value}>
      {children}
    </DirectDebitPaymentContext.Provider>
  );
}

function useDirectDebitPaymentContext() {
  const context = useContext(DirectDebitPaymentContext);

  if (context === undefined) {
    throw new Error(
      'useDirectDebitPaymentContext must be used within a DirectDebitPaymentProvider',
    );
  }

  return context;
}

export { DirectDebitPaymentProvider, useDirectDebitPaymentContext };
