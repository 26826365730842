import fetchJson from 'utils/fetchJson';

type ChargeDecisionAttrs = {
  cardClassification: string;
  cardCountry: string;
  cardProductType: string;
  orderId: string;
  orderToken: string;
};
export type ChargeDecisionResponse = {
  action: 'charge' | 'redirection' | 'surcharge';
  percentage?: number;
};
export type ChargeAttrs = {
  accessToken?: string;
  confirmURL: string;
  cardClassification: string;
  cardCountry: string;
  cardProductType: string;
  orderId: string;
  orderToken: string;
  sessionId: string;
};
export type ChargeResponse = {
  amount?: number;
  currency?: string;
  message?: string;
  paymentId?: string;
  status: string;
};
type CardOnFileService = {
  chargeDecision: (
    attrs: ChargeDecisionAttrs,
  ) => Promise<ChargeDecisionResponse>;

  charge: (attrs: ChargeAttrs) => Promise<ChargeResponse>;
};
type MapperAttrs = {
  confirmURL?: string;
  cardClassification: string;
  cardCountry: string;
  cardProductType: string;
  orderId: string;
  orderToken: string;
  sessionId?: string;
};

const mapParamsToBackEnd = (attrs: MapperAttrs) => {
  const {
    confirmURL,
    cardClassification,
    cardCountry,
    cardProductType,
    orderId,
    orderToken,
    sessionId,
    ...rest
  } = attrs;

  return {
    api_confirmation_url: confirmURL,
    card: {
      classification: cardClassification,
      country: cardCountry,
      product_type: cardProductType,
    },
    order: {
      id: orderId,
      token: orderToken,
    },
    session_id: sessionId,
    ...rest,
  };
};

const cardOnFile: CardOnFileService = {
  chargeDecision: async attrs => {
    return fetchJson<ChargeDecisionResponse>('/card_on_file/charge_decision', {
      method: 'POST',
      body: JSON.stringify(mapParamsToBackEnd(attrs)),
    });
  },

  charge: async ({ accessToken, ...attrs }) => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(mapParamsToBackEnd(attrs)),
    };
    const options = {} as Record<string, unknown>;

    if (accessToken) {
      options.headers = { Authorization: `Bearer ${accessToken}` };
    }

    return fetchJson<ChargeResponse>('/card_on_file/charge', {
      ...requestOptions,
      ...options,
    });
  },
};

export { cardOnFile };
