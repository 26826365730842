import type { RootState } from 'reducers/types';
import { sortCountriesByLabel } from 'utils/countries/countries';

export const getCountries = (state: RootState) => {
  const {
    entities: {
      countries: { byId, ids },
    },
  } = state;

  return ids.map(id => byId[id]);
};

export const getCountriesForSelect = (state: RootState) => {
  const countries = getCountries(state);

  const countriesForSelect = countries.map(
    ({ dialingCode, name: label, code: value }) => ({
      dialingCode,
      label,
      value,
    }),
  );

  return sortCountriesByLabel(countriesForSelect);
};

export const getCountry = (state: RootState, id: string) => {
  const {
    entities: {
      countries: { byId },
    },
  } = state;
  const country = byId[id] || {};

  return country;
};

export const getCountryCurrency = (state: RootState, id: string) => {
  const country = getCountry(state, id);

  return country && country.currency;
};
