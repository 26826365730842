import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { Alert } from 'components/Alert/Alert';
import { WizardContext } from 'components/Wizard/components/context';
import { Captcha, CaptchaRef } from '../thirdParty/Captcha/Captcha';
import {
  getCreatePaymentError,
  getFieldById,
  getFieldValue,
  getPaymentOption,
  getRecipientId,
  hasDuplicatedPaymentError,
  hasDuplicatedPANNumberError,
  hasTaxServiceUnavailableError,
  isSelectedOfferBankTransfer,
  isReadOnly,
} from 'selectors';
import {
  savedSuccessfully,
  setCaptchaResponse,
  setFieldsValues,
  trackEditDetails,
  trackSaveEditedDetails,
  updateOrderWithEntities,
} from 'actions';
import { Navigation } from 'components/Navigation/Navigation';
import { AlipayBanner } from './AlipayBanner/AlipayBanner';
import { PaymentInformationBreakdown } from './PaymentInformationBreakdown/PaymentInformationBreakdown';
import { PaymentOptionsBreakdown } from './PaymentOptionsBreakdown/PaymentOptionsBreakdown';
import { SectionBreakdown } from './SectionBreakdown/SectionBreakdown';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { contactUrl, helpUrl } from 'utils/flysiteUrls';
import { GENERIC_ERROR, REVIEW, SENDER_ENTITY } from 'constants/index';
import { ContactInformationSection } from './ContactInformationSection/ContactInformationSection';
import { shouldShowCaptcha } from 'utils/captcha/captcha';
import { useTranslations } from 'utils/translations/useTranslations';
import type { RootState } from 'reducers/types';

const ALIPAY = 'alipay_cny';

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentConfirmationComponent = ({
  createPaymentError,
  hasDuplicatedPANNumberError,
  hasDuplicatedPaymentError,
  hasTaxServiceUnavailableError,
  isReadOnly,
  isSelectedOfferBankTransfer,
  paymentMethod,
  recipientId,
  savedSuccessfully,
  senderFields,
  setCaptchaResponse,
  setFieldsValues,
  trackEditDetails,
  trackSaveEditedDetails,
  updateOrderWithEntities,
}: PropsFromRedux) => {
  const [captchaVerificationError, setCaptchaVerificationError] =
    useState(false);

  const errorElement = useRef<HTMLSpanElement>(null);
  const captchaRef = useRef<CaptchaRef>(null);
  const wizardContext = useContext(WizardContext);

  const i18n = useTranslations();

  useEffect(() => {
    scrollToError();
  });

  const showCaptcha = () => shouldShowCaptcha(recipientId);

  const continueToNextStep = () => {
    wizardContext.goToNextStep();
  };

  const handleClickNext = async () => {
    if (!captchaRef.current) {
      continueToNextStep();
    } else {
      await captchaRef.current?.execute();
    }
  };

  const handleClickNextWithToken = async (token: string) => {
    if (token) {
      setCaptchaResponse(token);
      continueToNextStep();
    } else {
      setCaptchaVerificationError(true);
    }
  };

  const handleClickPrev = () => {
    wizardContext.goToPrevStep();
  };

  const scrollToError = () => {
    const { current } = errorElement;
    if (current) current.scrollIntoView();
  };

  const errorAlert = (message: string) => {
    return (
      <Alert type="danger">
        <span
          ref={errorElement}
          dangerouslySetInnerHTML={createMarkup(message)}
        />
      </Alert>
    );
  };

  const handleOnSubmit = (values = {}) => {
    try {
      setFieldsValues(values);
      updateOrderWithEntities(SENDER_ENTITY);
      savedSuccessfully();
    } catch (e) {}
  };

  const noSpecificError =
    !hasDuplicatedPaymentError &&
    !hasDuplicatedPANNumberError &&
    !hasTaxServiceUnavailableError;

  const duplicatedAlert =
    hasDuplicatedPaymentError &&
    errorAlert(
      i18n.t('paymentConfirmation.duplicatedPayment', {
        customerServiceUrl: contactUrl(i18n.currentLocale),
      }),
    );

  const duplicatedPANAlert =
    hasDuplicatedPANNumberError &&
    errorAlert(i18n.t('paymentConfirmation.duplicatedPAN'));

  const taxServiceUnavailableAlert =
    hasTaxServiceUnavailableError &&
    errorAlert(
      i18n.t('paymentConfirmation.taxServiceUnavailable', {
        helpUrl: helpUrl(i18n.currentLocale),
      }),
    );

  const createPaymentAlert = () => {
    if (createPaymentError) {
      if (createPaymentError !== GENERIC_ERROR) {
        return errorAlert(createPaymentError);
      } else if (noSpecificError) {
        return errorAlert(i18n.t('notifications.create_payment_failure'));
      }
    }
  };

  const captchaVerificationAlert =
    captchaVerificationError &&
    errorAlert(i18n.t('notifications.create_payment_failure'));

  const navigation = (
    <Navigation
      onClickPrev={handleClickPrev}
      onClickNext={handleClickNext}
      nextLabel={
        isSelectedOfferBankTransfer
          ? i18n.t('navigation.next')
          : i18n.t('navigation.pay')
      }
      previousStepName={i18n.t('stepsList.recipient')}
      nextStepName={i18n.t('stepsList.pay')}
    />
  );

  const title = i18n.t('paymentConfirmation.title') as string;
  const description = i18n.t('paymentConfirmation.description') as string;

  return (
    <PaymentStep
      ariaLabel={`${title}. ${description}`}
      navigation={navigation}
      stepName={REVIEW}
      title={title}
    >
      {paymentMethod === ALIPAY && <AlipayBanner />}
      <PaymentInformationBreakdown />
      {!isReadOnly('offer') ? <PaymentOptionsBreakdown /> : null}
      <SectionBreakdown entity="sender" />
      <SectionBreakdown entity="recipient" />
      {showCaptcha() && (
        <Captcha ref={captchaRef} onVerify={handleClickNextWithToken} />
      )}
      <section className="Breakdown"></section>
      <ContactInformationSection
        i18n={i18n}
        fields={senderFields}
        onSubmit={handleOnSubmit}
        trackEditDetails={trackEditDetails}
        trackSaveEditedDetails={trackSaveEditedDetails}
      />
      {duplicatedAlert}
      {duplicatedPANAlert}
      {createPaymentAlert()}
      {taxServiceUnavailableAlert}
      {captchaVerificationAlert}
    </PaymentStep>
  );
};

const mapDispatchToProps = {
  savedSuccessfully,
  setCaptchaResponse,
  setFieldsValues,
  trackEditDetails,
  trackSaveEditedDetails,
  updateOrderWithEntities,
};

const mapStateToProps = (state: RootState) => ({
  createPaymentError: getCreatePaymentError(state),
  hasDuplicatedPANNumberError: hasDuplicatedPANNumberError(state),
  hasDuplicatedPaymentError: hasDuplicatedPaymentError(state),
  hasTaxServiceUnavailableError: hasTaxServiceUnavailableError(state),
  isReadOnly: (name: string) => isReadOnly(state, name),
  isSelectedOfferBankTransfer: isSelectedOfferBankTransfer(state),
  itemDescription: getFieldValue(state, 'item_description'),
  paymentMethod: getPaymentOption(state),
  recipientId: getRecipientId(state),
  senderFields: {
    email: getFieldById(state, 'sender_email'),
    phone: getFieldById(state, 'sender_phone'),
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const PaymentConfirmation = connector(PaymentConfirmationComponent);

export { PaymentConfirmation };
