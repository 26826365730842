import React, { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToggleMenu } from 'components/ToggleMenu/ToggleMenu';
import { logout, newNotification } from 'actions';
import { getAccessToken, getUser, paymentsFromIndia } from 'selectors';
import {
  CASHBACK_ROUTE,
  LOGOUT_ROUTE,
  PAYMENTS_ROUTE,
  PROFILE_ROUTE,
} from 'constants/index';
import { User } from 'models';
import { useTranslations } from 'utils';
import { RootState } from 'reducers/types';

import './MyAccountMenu.scss';

type MyAccountMenuProps = {
  accessToken: string | null;
  hasPaymentsFromIndia?: boolean;
  logout: () => void;
  newNotification: (message: string) => void;
  onClick?: MouseEventHandler<HTMLLIElement>;
  user: User;
};

export function MyAccountMenuComponent({
  accessToken,
  hasPaymentsFromIndia = false,
  logout,
  newNotification,
  onClick,
  user,
}: MyAccountMenuProps) {
  const navigate = useNavigate();
  const i18n = useTranslations();

  if (!accessToken) return null;

  const logoutAndNotify = () => {
    logout();
    newNotification({ message: 'notifications.logout_user_success' });
    onClick?.();
  };

  const goTo = (path: string) => {
    navigate(path);
    onClick?.();
  };

  const { firstName: userName } = user;

  const menuItems = [
    {
      label: i18n.t('myPayments'),
      path: PAYMENTS_ROUTE,
      onClick: () => goTo(PAYMENTS_ROUTE),
    },
    {
      label: i18n.t('profile'),
      path: PROFILE_ROUTE,
      onClick: () => goTo(PROFILE_ROUTE),
    },
    {
      className: 'is-logout',
      label: i18n.t('logout'),
      path: LOGOUT_ROUTE,
      onClick: () => logoutAndNotify(),
    },
  ];

  if (hasPaymentsFromIndia) {
    menuItems.splice(1, 0, {
      label: i18n.t('myCashBack'),
      path: CASHBACK_ROUTE,
      onClick: () => goTo(CASHBACK_ROUTE),
    });
  }

  return (
    <>
      {userName && (
        <span className="MyAccountMenu-welcomeMessage">
          {i18n.t('myAccountMenu.welcomeMessage', { userName })}
        </span>
      )}
      <ToggleMenu menuItems={menuItems} label={i18n.t('myAccount')} />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  accessToken: getAccessToken(state),
  user: getUser(state),
  hasPaymentsFromIndia: paymentsFromIndia(state),
});

const mapDispatchToProps = {
  logout,
  newNotification,
};

const MyAccountMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAccountMenuComponent);

export { MyAccountMenu };
