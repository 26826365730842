export const API_URL_V3 = '/v3';
export const PAYEX_URL = '/payex';
export const WALLET_URL = '/wallet';
export const SELECT_INSTITUTION_PATH = '/select-institution';
export const DEFAULT_LOCALE = 'en';
export const GOOGLE_ANALYTICS_ID = 'UA-21478818-13';
export const GOOGLE_TAG_MANAGER_ID = 'GTM-NRLXKJFZ';
export const GOOGLE_TAG_MANAGER_ENVS = ['payment.flywire.com'];
export const GOOGLE_MARKETING_ID = 'AW-410801422';
export const GOOGLE_MARKETING_ENVS = ['payment.flywire.com'];
export const META_MARKETING_ID = '671130799943476';
export const META_MARKETING_ENVS = ['payment.flywire.com'];
export const PUBLIC_KEY_URL = '/public_key';
export const SENTRY_DSN =
  'https://e148c89ca64c434d865048f29cfa9e56@sentry.io/271161';
// @ts-expect-error ts-migrate(2339) FIXME: Property 'SENTRY_ENVIRONMENT' does not exist on ty... Remove this comment to see the full error message
export const SENTRY_ENVIRONMENT = window.SENTRY_ENVIRONMENT;
// @ts-expect-error ts-migrate(2339)
export const FLYWIRE_JS_ENVIRONMENT = window.FLYWIRE_JS_ENVIRONMENT;
export const TRACKER_URL = '/tracker';
export const LOGGER_URL = '/logger';
export const PAY_LATER_URL = '/pay-later';
export const LRS_URL = '/lrs';
export const LRS_PREVIEW_URL = '/lrs/preview';
export const FEEDBACK_ENDPOINT_URL = '/feedback';
export const GATEWAY_URL = '/payex/gateway';
export const FLYSITE_URL = 'https://www.flywire.com';
export const FLYSITE_HELP_URL = 'https://help.flywire.com';
export const EXCHANGE_URL = '/exchange';
export const CAP_URL = '/payex/cap';
export const USER_LANGUAGE_URL = '/user_language';
export const PROMOTIONS_URL = '/promotions';
export const RECURRING_RECEIVABLES_URL = '/recurring_receivables';
export const FILES_URL = '/files';
export const PAYMENT_GUIDE_URL =
  'https://help.flywire.com/hc/articles/360012463654';
export const COMMON_APP_URL = 'https://apply.commonapp.org/login';
export const HEAP_APP_ID = '476072953';
export const HEAP_ENVS = ['payment.flywire.com'];
export const PROD_DOMAINS_FOR_CAPTCHA = ['flywire.com'];
export const HCAPTCHA_SITE_KEY_DEV = '05fbd142-3b89-438e-9869-79da823bec7c';
export const HCAPTCHA_SITE_KEY_PROD = '70634935-df0a-4f4a-a912-855db411eba9';
export const HOTJAR_APP_ID = '3487825';
export const HOTJAR_ENVS = ['payment.flywire.com'];
export const USERPROFILE_URL = '/userprofile';
export const PROD_DOMAINS_FOR_SOCIAL_SIGN_IN = ['flywire.com'];
export const GOOGLE_CLIENT_ID_DEV =
  '981518199807-mu5d6juqqmus50mfvqmu0ia162tlq3u7.apps.googleusercontent.com';
export const GOOGLE_CLIENT_ID_PROD =
  '149416257811-4pnu8virq6kdjh7rbg0blfneea2dj52l.apps.googleusercontent.com';
