// @ts-expect-error accounting types don't exist
import { formatMoney, unformat } from 'accounting';
import type { Currency } from 'models';

type FormatOptions = {
  cents?: boolean;
  decimal?: string;
  thousand?: string;
  subunitToUnit?: number;
  symbol?: string | boolean;
  symbolFirst?: boolean;
};

const toMoney = (number: number, options: FormatOptions) => {
  const defaults = {
    cents: true,
    decimal: '.',
    thousand: ',',
    subunitToUnit: 100,
    symbol: '$',
    symbolFirst: true,
  };

  const config = { ...defaults, ...options };
  const { cents, decimal, subunitToUnit, symbol, symbolFirst, thousand } =
    config;
  const precision = cents ? Math.log10(subunitToUnit) : 0;
  const amount = number / subunitToUnit;
  let format = '%v';
  if (symbol) {
    format = symbolFirst ? '%s%v' : '%v %s';
  }
  const settings = { format, decimal, symbol, precision, thousand };
  return formatMoney(amount, settings);
};

const toCents = (number: number, options: FormatOptions) => {
  const defaults = {
    decimal: '.',
    subunitToUnit: 100,
  };

  const config = { ...defaults, ...options };
  const { decimal, subunitToUnit } = config;

  return unformat(number, decimal) * subunitToUnit;
};

const formatAmount = (
  amount: number,
  currency: Currency,
  options?: FormatOptions,
) => {
  const {
    decimalMark: decimal,
    subunitToUnit,
    symbol,
    symbolFirst,
    thousandsSeparator: thousand,
  } = currency;

  const formatAttrs = {
    decimal,
    subunitToUnit,
    symbol,
    symbolFirst,
    thousand,
    ...options,
  };

  return toMoney(amount, formatAttrs);
};

export { formatAmount, toCents, toMoney };
