import React from 'react';
import { Heading } from 'components/Heading/Heading';
import { FieldList } from 'components/FieldList/FieldList';
import type { Section as SectionType } from 'models';

import './Section.scss';

type SectionProps = Pick<SectionType, 'fields'> & {
  name?: string;
  description?: string;
};

const Section = ({ description, fields, name }: SectionProps) => {
  return (
    <section className="SectionList-section">
      {name && (
        <Heading as="h2" size="medium" className="marginBottom-lg">
          {name}
        </Heading>
      )}
      {description && <p className="Section-description">{description}</p>}
      <FieldList fields={fields} />
    </section>
  );
};

export { Section };
