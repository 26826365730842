import { combineReducers } from 'redux';
import {
  CLEAR_FIELDS,
  CONFIRM_TAX_MODAL,
  SENDER_COUNTRY_FIELD,
  SET_FIELD_VALUE,
  UPDATE_ORDER_SUCCESS,
} from 'constants/index';
import type { Offer } from 'models';

type TaxAction =
  | {
      type: typeof SENDER_COUNTRY_FIELD | typeof UPDATE_ORDER_SUCCESS;
      payload: {
        offer: Offer;
      };
    }
  | {
      type: typeof SET_FIELD_VALUE;
      payload: {
        name: string;
      };
    }
  | {
      type: typeof CLEAR_FIELDS;
      payload: string[];
    }
  | {
      type: typeof CONFIRM_TAX_MODAL;
    };

const isAnOfferField = (field: string) => field?.startsWith('offer_');
const isSenderCountryField = (field: string) => field === SENDER_COUNTRY_FIELD;

export const isVisible = (state = false, action: TaxAction) => {
  switch (action.type) {
    case UPDATE_ORDER_SUCCESS:
      const { offer: { taxes = [] } = {} } = action.payload;
      const total = taxes.reduce((total, tax) => total + +tax.amount, 0);

      return total > 0;
    case CONFIRM_TAX_MODAL:
      return false;
    case SET_FIELD_VALUE:
      const { name } = action.payload;

      if (isAnOfferField(name) || isSenderCountryField(name)) {
        return false;
      }

      return state;
    case CLEAR_FIELDS:
      return false;
    default:
      return state;
  }
};

export const isConfirmed = (state = false, action: TaxAction) => {
  switch (action.type) {
    case CONFIRM_TAX_MODAL:
      return true;
    case SET_FIELD_VALUE:
      const { name } = action.payload;

      if (isAnOfferField(name) || isSenderCountryField(name)) {
        return false;
      }

      return state;
    case CLEAR_FIELDS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({ isVisible, isConfirmed });
