/* eslint-disable camelcase */
import fetch from 'services/apiV3/fetch';
import fetchJson from 'utils/fetchJson';
import { API_URL_V3 } from '../../../constants';
import type { SupportedLocalesKeys } from 'utils';
import type { Field, Item, Payment, Payout } from 'models';
import {
  AddOrderToUserRequestParams,
  CalculteRedeemCashbackResponse,
  CancelPaymentRequestParams,
  CancelPaymentResponse,
  CouponParams,
  CreateOrderRequestBody,
  FetchOwnershipRequestParams,
  FetchPaymentRequestParams,
  InitiatePaymentRequestParams,
  InitiatePaymentResponse,
  OrderResponse,
  OwnershipResponse,
  RedeemCashbackParams,
  UpdateOrderRequestBody,
} from './types';

type EntityFields = { fields: Field[]; id?: string; provider?: string };
export type OfferEntityFields = EntityFields & { id: string };
export type RecipientEntityFields = EntityFields & {
  items: Pick<Item, 'id' | 'amount'>[];
};
export type CreatePayload = {
  callback: {
    id: string;
    url?: string;
    version?: string;
  };
  fingerprint?: string | null;
  locale: SupportedLocalesKeys;
  metadata?: {
    tracking_url: string; // eslint-disable-line camelcase
  };
  offer: OfferEntityFields;
  payables: Payout[];
  provider: string;
  recipient: RecipientEntityFields;
  return_url: string; // eslint-disable-line camelcase
  sender: EntityFields;
};
export type UpdatePayload = {
  callback: {
    id: string;
    url?: string;
    version?: string;
  };
  fingerprint?: string | null;
  locale: SupportedLocalesKeys;
  metadata?: {
    tracking_url: string; // eslint-disable-line camelcase
  };
  offer: OfferEntityFields;
  orderId: string;
  payables: Payout[];
  provider: string;
  recipient: RecipientEntityFields;
  refund_lock_fee: boolean; // eslint-disable-line camelcase
  return_url: string; // eslint-disable-line camelcase
  sender: EntityFields;
  token: string;
  recipientId: string;
};
export type OrderServiceError = {
  message?: string;
  param?: string;
  source?: string;
  type?: string;
};

export type OrderService = {
  create: (payload: CreateOrderRequestBody) => Promise<OrderResponse>;
  update: (payload: UpdateOrderRequestBody) => Promise<OrderResponse>;
  fetch: (payload: {
    orderId: string;
    token: string;
  }) => Promise<OrderResponse>;
  fetchPayment: (payload: FetchPaymentRequestParams) => Promise<Payment[]>;
  cancelPayment: (
    payload: CancelPaymentRequestParams,
  ) => Promise<CancelPaymentResponse>;
  initiatePayment: (
    payload: InitiatePaymentRequestParams,
  ) => Promise<InitiatePaymentResponse>;
  addOrderToUser: (
    payload: AddOrderToUserRequestParams,
  ) => Promise<OwnershipResponse>;
  ownership: (
    payload: FetchOwnershipRequestParams,
  ) => Promise<OwnershipResponse>;
  addCoupon: (payload: CouponParams) => Promise<OrderResponse>;
  deleteCoupon: (payload: CouponParams) => Promise<OrderResponse>;
  addRedeemCashback: (payload: RedeemCashbackParams) => Promise<OrderResponse>;
  deleteRedeemCashback: (
    payload: RedeemCashbackParams,
  ) => Promise<OrderResponse>;
  calculateRedeemCashback: (
    payload: RedeemCashbackParams,
  ) => Promise<CalculteRedeemCashbackResponse>;
};

export const orders: OrderService = {
  create: ({ locale = 'EN', ...payload }) => {
    const path = `${API_URL_V3}/orders?locale=${locale}`;
    const body = JSON.stringify(payload);

    const options = { method: 'POST', body };

    return fetchJson(path, options);
  },

  update: ({ orderId, token, ...payload }) => {
    const recipientId = payload.recipientId || payload.recipient?.id;
    const queryParams = [
      `token=${token}`,
      ...(recipientId === 'PTU' ? ['avoid_maintenance=true'] : []),
    ].join('&');
    const path = `${API_URL_V3}/orders/${orderId}?${queryParams}`;
    const body = JSON.stringify(payload);

    const options = { method: 'PATCH', body };

    return fetch(path, options);
  },

  fetch: ({ orderId, token }) => {
    const path = `${API_URL_V3}/orders/${orderId}?token=${token}`;

    return fetch(path, { method: 'GET' });
  },
  fetchPayment: ({ orderId, token, locale = 'EN' }) => {
    const path = `${API_URL_V3}/orders/${orderId}/payments?token=${token}&locale=${locale}`;
    return fetch(path, { method: 'GET' });
  },

  cancelPayment: ({ url, method, cancellationReason }) => {
    const options = {
      method,
      body: JSON.stringify(
        cancellationReason ? { cancellation_reason: cancellationReason } : {},
      ),
    };

    return fetch(url, options);
  },

  initiatePayment: ({ url, method, date }) => {
    const body = JSON.stringify({ date });
    const options = {
      method,
      body,
    };

    return fetch(url, options);
  },

  addOrderToUser: ({ orderId, token }) => {
    const path = `${API_URL_V3}/orders/${orderId}/ownership?token=${token}`;
    const method = 'POST';

    return fetch(path, { method });
  },

  ownership: ({ id, token }) => {
    const path = `${API_URL_V3}/orders/${id}/ownership?token=${token}`;

    return fetch(path, { method: 'GET' });
  },

  addCoupon: ({ orderId, token, couponCode }) => {
    const path = `${API_URL_V3}/orders/${orderId}/coupon?token=${token}`;
    const body = JSON.stringify({ coupon_code: couponCode });
    const options = { method: 'PATCH', body };

    return fetch(path, options);
  },

  deleteCoupon: ({ orderId, token, couponCode }) => {
    const path = `${API_URL_V3}/orders/${orderId}/coupon/${couponCode}?token=${token}`;
    const options = { method: 'DELETE' };

    return fetch(path, options);
  },

  addRedeemCashback: ({ orderId, token }) => {
    const path = `${API_URL_V3}/orders/${orderId}/cashback?token=${token}`;
    const options = { method: 'PATCH' };

    return fetch(path, options);
  },

  deleteRedeemCashback: ({ orderId, token }) => {
    const path = `${API_URL_V3}/orders/${orderId}/cashback?token=${token}`;
    const options = { method: 'DELETE' };

    return fetch(path, options);
  },

  calculateRedeemCashback: ({ orderId, token }) => {
    const path = `${API_URL_V3}/orders/${orderId}/cashback/calculate?token=${token}`;
    const options = { method: 'GET' };

    return fetch(path, options);
  },
};
