import { getCurrentLocale } from 'selectors';
import { supportUrl } from 'utils/flysiteUrls';
import { newNotification } from 'actions';
import {
  SET_VALIDATION_ERRORS,
  RESET_VALIDATION_ERRORS,
  UPDATE_READ_ONLY_FIELDS,
} from 'constants/index';
import type { PayexThunkAction } from 'store/configureStore';

type ValidationErrors = Record<string, unknown>;

export const setValidationErrors =
  (
    errors: ValidationErrors,
    excludeUpdateReadOnlyFields?: string[],
  ): PayexThunkAction =>
  dispatch => {
    const updateReadOnlyPayload: ValidationErrors = {};

    for (const [key, value] of Object.entries(errors)) {
      if (!excludeUpdateReadOnlyFields?.includes(key)) {
        updateReadOnlyPayload[key] = value;
      }
    }

    dispatch({
      type: UPDATE_READ_ONLY_FIELDS,
      payload: updateReadOnlyPayload,
    });

    return dispatch({
      type: SET_VALIDATION_ERRORS,
      payload: errors,
    });
  };

export const setTermsErrors = () => {
  const error = { are_terms_accepted: ['not accepted'] };
  return setValidationErrors(error);
};

export const resetValidationErrors = () => ({
  type: RESET_VALIDATION_ERRORS,
});

export const taxErrorNotification =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const locale = getCurrentLocale(state);

    dispatch(
      newNotification({
        message: 'taxError.message',
        opts: { contactUrl: supportUrl(locale) },
        type: 'error',
      }),
    );
  };
