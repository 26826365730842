import React from 'react';

const Online = props => {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g transform="translate(1 1)">
          <rect width="20" height="15.652" rx="1.3" />
          <path d="M5.333 19.565h9.334M0 11.739h20" />
        </g>
        <path d="M12.333 16.652a7.656 7.656 0 0 0 1.334 3.913H8.333a7.656 7.656 0 0 0 1.334-3.913" />
      </g>
    </svg>
  );
};

export default Online;
