import {
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_MAX_FILES_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  HttpError,
} from 'constants/index';
import { isFetching } from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import type { PayexThunkAction } from 'store/configureStore';
import { isHttpErrorWithStatus } from 'utils/errors/errors';

export const fetchUploadFileURL =
  (documentUploadURL: string): PayexThunkAction =>
  async (_dispatch, _getState, { apiV3 }) => {
    try {
      const response = await apiV3.payments.fetchFileUploadURL({
        documentUploadURL,
      });

      if (!response.url) throw new Error('documentCollector.error');

      return response.url;
    } catch (error) {
      errorNotifier.notify(error);
      throw error;
    }
  };

export const uploadFile =
  (file: File, documentUrl: string): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingDocuments = isFetching(state, 'documents');

    if (isFetchingDocuments) return;

    dispatch({ type: UPLOAD_DOCUMENT_REQUEST });

    try {
      await apiV3.payments.uploadFileToUrl({
        file,
        url: documentUrl,
      });

      return dispatch({
        type: UPLOAD_DOCUMENT_SUCCESS,
      });
    } catch (error) {
      const { name } = file;

      errorNotifier.notify(error);

      return dispatch({
        type: UPLOAD_DOCUMENT_FAILURE,
        payload: { name },
      });
    }
  };

export const deleteFile =
  (url: string, fileId: string): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingDocuments = isFetching(state, 'documents');

    if (isFetchingDocuments) return;

    dispatch({ type: DELETE_FILE_REQUEST });

    try {
      await apiV3.payments.deleteFile({ url });

      return dispatch({
        type: DELETE_FILE_SUCCESS,
        payload: { fileId },
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const _error = error as HttpError;
        const { status = '' } = _error;

        _error.message = `${DELETE_FILE_FAILURE} ${_error.message}`;

        errorNotifier.notifyWithFingerprint(_error, [
          DELETE_FILE_FAILURE,
          `${status}`,
        ]);
      }
    }
  };

export const fileNumberExceeded = () => ({
  type: UPLOAD_DOCUMENT_MAX_FILES_FAILURE,
});
