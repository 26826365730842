import '../config/sentry';
import {
  createStore,
  applyMiddleware,
  compose,
  AnyAction,
  Action,
  Store,
} from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import merge from 'lodash/merge';
import rootReducer from 'reducers';
import throttle from 'lodash/throttle';
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
  loadStateFromCookie,
  saveStateToCookie,
  loadStateFromSessionStorage,
  saveStateToSessionStorage,
} from './storage';
import { getPlatform } from 'selectors';
import apiV3 from 'services/apiV3';
import wallet from 'services/wallet';
import tracker from 'services/tracker';
import { heapAgent } from 'services/analytics/heapAgent/heapAgent';
import { lrs } from 'services/lrs/lrs';
import userLanguage from 'services/userLanguage';
import { payments } from 'services/payments/payments';
import { smsNotifications } from 'services/smsNotifications/smsNotifications';
import { promotions } from 'services/promotions/promotions';
import { payex } from 'services/payex';
import studentExpenses from 'services/studentExpenses';
import { recurringReceivables } from 'domain/recurringReceivables/repository';
import * as Sentry from '@sentry/react';
import UINotifications from './middlewares/UINotifications/UINotifications';
import { Authentication } from './middlewares/Authentication/Authentication';
import { ErrorLogger } from './middlewares/ErrorLogger/ErrorLogger';
import type { RootState } from 'reducers/types';

export type StoreServices = typeof SERVICES;

// Useful to type actions
type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends Action, // known types of actions that can be dispatched
> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E,
) => R;

export type PayexThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  StoreServices,
  AnyAction
>;

type PayexThunkActionArgs = Parameters<PayexThunkAction>;

export type PayexDispatch = PayexThunkActionArgs[0];
export type PayexGetState = PayexThunkActionArgs[1];
export type PayexStoreServices = PayexThunkActionArgs[2];

const SERVICES = {
  apiV3,
  tracker,
  wallet,
  lrs,
  userLanguage,
  payments,
  smsNotifications,
  heapAgent,
  payex,
  promotions,
  recurringReceivables,
  studentExpenses,
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state: RootState) => {
    const recipient = state?.ui?.fieldValues?.recipient;
    const paymentId = state?.entities?.payment?.id;
    const senderCountry = state?.ui?.fieldValues?.sender_country;
    const orderId = state?.entities?.order?.id;
    const provider = getPlatform(state);

    if (recipient) {
      scope.setTag('recipient', recipient);
    }

    if (paymentId) {
      scope.setTag('payment', paymentId);
    }

    if (orderId) {
      scope.setTag('order', orderId);
    }

    if (senderCountry) {
      scope.setTag('country', senderCountry);
    }

    if (provider) {
      scope.setTag('provider', provider);
    }
  },
});

const store: {
  store?: Store;
  get: () => Store | undefined;
  configure: (state?: Partial<RootState>) => Store;
} = {
  get() {
    return this.store;
  },
  configure(ownState?: Partial<RootState>) {
    let middlewares = [
      thunk.withExtraArgument(SERVICES),
      UINotifications,
      Authentication,
      ErrorLogger,
    ];

    if (!['production', 'test'].includes(process.env.NODE_ENV || '')) {
      const logger = createLogger({ collapsed: true });
      middlewares = [...middlewares, logger];
    } else {
      middlewares = [...middlewares];
    }

    const savedState = loadSavedState();
    const initialState = { ...ownState, ...savedState };

    this.store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(...middlewares), sentryReduxEnhancer),
    );

    this.store.subscribe(
      throttle(() => {
        const state = this.store?.getState();
        saveStateToCookie(state);
        saveStateToSessionStorage(state);
        saveStateToLocalStorage(state);
      }),
      // @ts-expect-error TODO we need to check this
      1000,
    );

    return this.store;
  },
};

const loadSavedState = () => {
  const state = loadStateFromLocalStorage();
  const cookiesState = loadStateFromCookie();
  const sessionStorageState = loadStateFromSessionStorage();

  const authenticationState = merge(cookiesState, sessionStorageState);

  return merge(state, authenticationState);
};

export default store;
