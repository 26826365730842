import React, { createContext, useState, useMemo } from 'react';
import type {
  CardError,
  CardTokenizationEvent,
  Instructions,
  CardInstructionsAttributes,
  TokenizationFormError,
} from 'services/tokenization/models/models';
import { useTranslations } from 'utils';

type CardTokenizationContext = {
  children: React.ReactNode;
  error?: CardError | TokenizationFormError;
  instructions?: Instructions;
  instructionsAttributes: CardInstructionsAttributes;
  isLoading?: boolean;
  onSuccess: (tokenizationResult: CardTokenizationEvent) => void;
  onError: () => void;
  tokenizationResult?: CardTokenizationEvent;
};

type CardTokenizationProvider = CardTokenizationContext & {
  clear: () => void;
  locale: string;
  setError: React.Dispatch<
    React.SetStateAction<CardError | TokenizationFormError>
  >;
  setInstructions: React.Dispatch<React.SetStateAction<Instructions>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenizationResult: React.Dispatch<
    React.SetStateAction<CardTokenizationEvent>
  >;
};

const CardTokenizationContext = createContext<CardTokenizationProvider>(
  {} as CardTokenizationProvider,
);

const NO_ERROR = {} as TokenizationFormError;
const NO_INSTRUCTIONS = {} as Instructions;
const NO_RESULT = {} as CardTokenizationEvent;

const CardTokenizationProvider = ({
  children,
  error: initialError = NO_ERROR,
  instructions: initialInstructions = NO_INSTRUCTIONS,
  instructionsAttributes = {} as CardInstructionsAttributes,
  isLoading: initialIsLoading = false,
  onSuccess,
  onError,
  tokenizationResult: initialTokenizationResult = NO_RESULT,
}: CardTokenizationContext) => {
  const [instructions, setInstructions] = useState(initialInstructions);
  const [error, setError] = useState(initialError);
  const [tokenizationResult, setTokenizationResult] = useState(
    initialTokenizationResult,
  );
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const i18n = useTranslations();

  function clear() {
    setInstructions(NO_INSTRUCTIONS);
    setError(NO_ERROR);
    setTokenizationResult(NO_RESULT);
  }

  const value = useMemo(
    () => ({
      children,
      clear,
      error,
      instructions,
      instructionsAttributes,
      isLoading,
      locale: i18n.localeCode,
      onSuccess,
      onError,
      setError,
      setInstructions,
      setIsLoading,
      setTokenizationResult,
      tokenizationResult,
    }),
    [
      children,
      error,
      i18n.localeCode,
      instructions,
      instructionsAttributes,
      isLoading,
      onError,
      onSuccess,
      tokenizationResult,
    ],
  );

  return (
    <CardTokenizationContext.Provider value={value}>
      {children}
    </CardTokenizationContext.Provider>
  );
};

export { CardTokenizationContext, CardTokenizationProvider };
