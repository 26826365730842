import React from 'react';
import { createMarkup } from 'utils/createMarkup/createMarkup';

type DescriptionProps = {
  children?: React.ReactNode;
  value: string;
};

const Description = ({ children, value }: DescriptionProps) =>
  children || (
    <p
      aria-live="polite"
      aria-relevant="additions text"
      dangerouslySetInnerHTML={createMarkup(value)}
      role="status"
    />
  );

export { Description };
