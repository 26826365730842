import {
  FETCH_KYC_TOKEN_SUCCESS,
  FETCH_PAYMENT_REFUNDS_REQUEST,
} from 'constants/index';
import type { KYC } from 'models';

type KYCAction =
  | { type: typeof FETCH_PAYMENT_REFUNDS_REQUEST }
  | {
      type: typeof FETCH_KYC_TOKEN_SUCCESS;
      payload: string;
    };

const kyc = (state: KYC = {}, action: KYCAction) => {
  switch (action.type) {
    case FETCH_KYC_TOKEN_SUCCESS:
      const { payload: token } = action;
      return { ...state, token };
    case FETCH_PAYMENT_REFUNDS_REQUEST:
      return {};
    default:
      return state;
  }
};

export default kyc;
