import React, {
  ReactNode,
  ReactElement,
  forwardRef,
  ForwardedRef,
} from 'react';
import classNames from 'classnames';
import { Label } from '../Label/Label';
import { useTranslations } from 'utils/translations/useTranslations';

interface FormGroupProps {
  'data-testid'?: string;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string | boolean;
  floatingLabel?: boolean;
  hasPrefix?: boolean;
  hasSuffix?: boolean;
  hasValue?: boolean;
  hint?: string;
  isFocused?: boolean;
  label?: string;
  name: string;
  readOnly?: boolean;
  required?: boolean;
}

const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>(
  (
    {
      'data-testid': dataTestId,
      children,
      className,
      disabled = false,
      error,
      floatingLabel = true,
      hasPrefix = false,
      hasSuffix = false,
      hasValue = false,
      hint,
      isFocused = false,
      label,
      name,
      readOnly = false,
      required = false,
    }: FormGroupProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): ReactElement => {
    const feedbackId = `${name}-error-msg`;
    const hintId = `${name}-hint-msg`;
    const i18n = useTranslations();

    return (
      <div
        className={classNames('FormGroup', className, {
          'has-error': error,
          'FormGroup--floatingLabel': floatingLabel,
          'has-value': hasValue,
          'is-disabled': disabled,
          'is-focused': isFocused,
          'is-readOnly': readOnly,
          'FormGroup--hasPrefix': hasPrefix,
          'FormGroup--hasSuffix': hasSuffix,
        })}
        ref={ref}
        data-testid={dataTestId}
      >
        {label && (
          <Label
            htmlFor={name}
            id={`${name}-label`}
            required={required}
            value={label}
          />
        )}
        {children}
        <p
          className="FormGroup-feedback"
          id={feedbackId}
          data-testid={feedbackId}
          aria-live="off"
        >
          {error && typeof error === 'string' && i18n.t(error)}
        </p>
        {hint && (
          <p className="FormGroup-hint" id={hintId} data-testid={hintId}>
            {hint}
          </p>
        )}
      </div>
    );
  },
);

FormGroup.displayName = 'FormGroup';

export { FormGroup };
