import React from 'react';
import { connect } from 'react-redux';
import {
  getSelectedOffer,
  isSelectedOfferLegacyInstallment,
  isSelectedOfferLegacySubscription,
  isSelectedOfferRecurring,
} from 'selectors';
import { LegacyRecurringBreakdown } from './components/LegacyRecurringBreakdown/LegacyRecurringBreakdown';
import { RecurringBreakdown } from './components/RecurringBreakdown/RecurringBreakdown';
import { SubscriptionBreakdown } from './components/SubscriptionBreakdown/SubscriptionBreakdown';
import { Breakdown } from '../Breakdown/Breakdown';
import { useTranslations } from 'utils';
import type { RootState } from 'reducers/types';

export const PaymentOptionsBreakdownComponent = ({
  offer: { name },
  isLegacyInstallment = false,
  isLegacySubscription = false,
  isRecurring = false,
}: {
  offer: { name: string };
  isLegacyInstallment: boolean;
  isLegacySubscription: boolean;
  isRecurring: boolean;
}) => {
  const i18n = useTranslations();

  return (
    <Breakdown
      title={i18n.t('paymentOptionsBreakdown.paymentMethod') as string}
      id="paymentMethod"
    >
      <div id="paymentMethod-description">
        <dl>
          <div>
            <dt>{i18n.t('paymentOptionsBreakdown.paymentOption')}</dt>
            <dd data-hj-suppress>{name}</dd>
          </div>
          {isLegacyInstallment && <LegacyRecurringBreakdown />}
          {isLegacySubscription && <SubscriptionBreakdown i18n={i18n} />}
          {isRecurring && <RecurringBreakdown />}
        </dl>
      </div>
    </Breakdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLegacyInstallment: isSelectedOfferLegacyInstallment(state),
  isLegacySubscription: isSelectedOfferLegacySubscription(state),
  isRecurring: isSelectedOfferRecurring(state),
  offer: getSelectedOffer(state),
});

const PaymentOptionsBreakdown = connect(mapStateToProps)(
  PaymentOptionsBreakdownComponent,
);

export { PaymentOptionsBreakdown };
