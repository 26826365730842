import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCurrentLocale, shouldShowChineseBanner } from 'selectors';
import { exchangeCurrency } from 'actions/ui/exchange';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import icon from './ico-info.svg';
import type { RootState } from 'reducers/types';
import { useTranslations } from 'utils';

import './ChineseBanner.scss';

const CHINESE_URL =
  'https://help.flywire.com/hc/zh-cn/articles/360012920474-我可以支付人民币吗-有哪些付款方式可供选择-';
const DEFAULT_URL =
  'https://help.flywire.com/hc/en-us/articles/360012920474-Can-I-pay-in-Chinese-Yuan-What-payment-options-are-available-';
const CHINESE_LOCALE = 'zh-CN';

const helpUrl = (locale: string) => {
  return locale === CHINESE_LOCALE ? CHINESE_URL : DEFAULT_URL;
};

type ChineseBannerComponentProps = {
  exchangeCurrency: () => void;
  locale: string;
  shouldShowChineseBanner: boolean;
};

const ChineseBannerComponent = (props: ChineseBannerComponentProps) => {
  useEffect(() => {
    props.exchangeCurrency();
  }, [props]);

  const i18n = useTranslations();

  if (!props.shouldShowChineseBanner) return null;

  return (
    <div data-testid="chineseFaq" className="ChineseBanner">
      <img className="ChineseBanner-icon" src={icon} alt="Chinese FAQ" />
      <h3 className="ChineseBanner-title">Please Note</h3>
      <p
        className="ChineseBanner-description"
        dangerouslySetInnerHTML={createMarkup(
          i18n.t('chineseBanner.description', {
            url: helpUrl(props.locale),
          }),
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  shouldShowChineseBanner: shouldShowChineseBanner(state),
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {
  exchangeCurrency,
};

const ChineseBanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChineseBannerComponent);

export { ChineseBanner };
