import React from 'react';
import { useWizard } from 'components/Wizard/components/context';
import { Navigation } from 'components/Navigation/Navigation';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import { CardForm } from './CardForm/CardForm';
import { CARD_FORM } from 'constants/index';
import { useTranslations } from 'utils/translations/useTranslations';

const CardFormWrapper = () => {
  const i18n = useTranslations();

  const { goToPrevStep } = useWizard();

  const navigation = (
    <Navigation
      hideNext
      onClickPrev={goToPrevStep}
      nextStepName={i18n.t('stepsList.track')}
    />
  );

  return (
    <PaymentStep navigation={navigation} stepName={CARD_FORM}>
      <CardForm />
    </PaymentStep>
  );
};

export { CardFormWrapper };
