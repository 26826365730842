import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { trackCheckMarkup } from 'actions';
import classNames from 'classnames';
import { useOnClickOutside, useOnScroll } from '@flywire/react-hooks';
import { ReactComponent as InfoIcon } from './ico-info.svg';
import { OfferContext } from '../../Offer';
import {
  MARKUP_RATE_DECIMAL_POSITIONS,
  EXCHANGE_RATE_DECIMAL_POSITIONS,
  NOOP,
} from 'constants/index';

import './FXInfo.scss';

const FXInfoComponent = ({ exchangeRate, markupRate, onToggle = NOOP }) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const ref = useRef();
  const closeTooltip = () => setShouldShowTooltip(false);
  const { i18n } = useContext(OfferContext);

  useOnClickOutside(ref, closeTooltip);
  useOnScroll(closeTooltip);

  const toggleTooltip = () => {
    if (!shouldShowTooltip) {
      onToggle();
    }

    setShouldShowTooltip(!shouldShowTooltip);
  };

  const roundedMarkupRate =
    markupRate && markupRate.toFixed(MARKUP_RATE_DECIMAL_POSITIONS);
  const roundedExchangeRate =
    exchangeRate && exchangeRate.toFixed(EXCHANGE_RATE_DECIMAL_POSITIONS);

  return (
    <>
      <div ref={ref} className="Offer-FXInfo">
        <button
          className="Offer-FXInfo-button"
          onClick={toggleTooltip}
          aria-labelledby="fxInfo-description"
        >
          <InfoIcon className="Offer-FXInfo-icon" />
          <span>{i18n.t('paymentOptions.fxInfo.text')}</span>
        </button>
        <div
          id="fxInfo-description"
          className={classNames('Offer-FXInfo-tooltip', {
            'Offer-FXInfo-tooltip--visible': shouldShowTooltip,
          })}
          role="tooltip"
          data-testid="fx-content"
        >
          <ul>
            <li data-testid="markupRate">
              {`${i18n.t(
                'paymentOptions.fxInfo.markupRate',
              )}: ${roundedMarkupRate} %`}
            </li>
            <li data-testid="exchangeRate">
              {`${i18n.t(
                'paymentOptions.fxInfo.exchangeRate',
              )}: ${roundedExchangeRate}`}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

FXInfoComponent.propTypes = {
  exchangeRate: PropTypes.number,
  markupRate: PropTypes.number,
  onToggle: PropTypes.func,
};

const mapDispatchToProps = {
  trackCheckMarkup,
};

const FXInfo = connect(null, mapDispatchToProps)(FXInfoComponent);

export { FXInfo };
