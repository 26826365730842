export const ENTER = 13;
export const SPACE = 32;
export const ESC = 27;

export const Keys = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  C: 'c',
  COMMA: ',',
  DELETE: 'Backspace',
  ENTER: 'Enter',
  ESC: 'Escape',
  NUMPAD_0: '0',
  NUMPAD_1: '1',
  NUMPAD_2: '2',
  NUMPAD_3: '3',
  NUMPAD_4: '4',
  NUMPAD_5: '5',
  NUMPAD_6: '6',
  NUMPAD_7: '7',
  NUMPAD_8: '8',
  NUMPAD_9: '9',
  PERIOD: '.',
  SPACE: ' ',
  SUPR: 'Delete',
  TAB: 'Tab',
  V: 'v',
  X: 'x',
};
