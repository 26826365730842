import { getFieldValue, getCountry, getRecipientSubdomain } from 'selectors';
import { GATEWAY_URL } from 'constants/index';
import type { RootState } from 'reducers/types';

export const getGatewayUrl = (state: RootState) =>
  `${GATEWAY_URL}?${getQueryParams(state)}`;

const getQueryParams = (state: RootState) => {
  return [
    ...getCountryName(state),
    ...getSubdomain(state),
    ...getProvider(),
    ...getAmount(state),
  ].join('&');
};

const getCountryName = (state: RootState) => {
  const id = getFieldValue(state, 'sender_country');
  const { name } = getCountry(state, id as string);
  return name ? [`country=${name}`] : [];
};

const getSubdomain = (state: RootState) => {
  const subdomain = getRecipientSubdomain(state);
  return [`payment_destination=${subdomain}`];
};

const getProvider = () => ['provider=widget'];

const getAmount = (state: RootState) => {
  const amount = getFieldValue(state, 'amount');
  if (!amount) return [];
  return [`amount=${amount}`];
};
