import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'lib/flywire-tailwind/Button';
import { OfferContext } from '../../Offer';
import { formatAmount } from 'utils/money';
import { NOOP } from 'constants/index';

function Actions({ onSelect = NOOP }) {
  const { id, i18n, name, price, fee, currency } = useContext(OfferContext);
  const { value: priceValue } = price;
  const { value: feeValue } = fee;
  const hasFee = feeValue > 0;
  const priceWithFormat = formatAmount(priceValue, currency);
  const feeWithFormat = formatAmount(feeValue, currency);
  const a11yMessage = [
    `${i18n.t('offers.select')}: ${name}. `,
    `${i18n.t('paymentTracking.amount_to_send')}: ${priceWithFormat}`,
    ...[
      hasFee
        ? [
            `, ${i18n
              .t('offers.includes_fee', { fee: feeWithFormat })
              .toLowerCase()}.`,
          ]
        : [],
    ],
  ].join('');

  function handleOnClick() {
    onSelect(id);
  }

  return (
    <div className="Offer-actions">
      <Button
        tone="primary"
        className="w-full md:w-32"
        onClick={handleOnClick}
        data-testid="offer-select"
        aria-label={a11yMessage}
      >
        <span className="downcapitalize">{i18n.t('offers.select')}</span>
      </Button>
    </div>
  );
}

Actions.displayName = 'Actions';
Actions.propTypes = {
  onSelect: PropTypes.func,
};

export { Actions };
