import {
  ADD_ORDER_TO_USER_SUCCESS,
  CREATE_ORDER_SUCCESS,
  FETCH_OFFER_SUCCESS,
  FETCH_ORDER_OWNERSHIP_SUCCESS,
  FETCH_ORDER_SUCCESS,
  FETCH_TRACKING_SETUP_DATA_SUCCESS,
  RESET_SELECTED_OFFER,
  RESTART,
  SET_FIELD_VALUE,
  UPDATE_ORDER_SUCCESS,
  RESET_ORDER,
} from '../../../constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import type { Order } from 'models';

type OrderAction =
  | {
      type:
        | typeof ADD_ORDER_TO_USER_SUCCESS
        | typeof FETCH_ORDER_OWNERSHIP_SUCCESS
        | typeof RESET_SELECTED_OFFER
        | typeof RESTART
        | typeof RESET_ORDER;
    }
  | {
      type: typeof CREATE_ORDER_SUCCESS | typeof FETCH_ORDER_SUCCESS;
      payload: Order;
    }
  | {
      type: typeof UPDATE_ORDER_SUCCESS;
      payload: Record<string, unknown>;
    }
  | {
      type: typeof FETCH_TRACKING_SETUP_DATA_SUCCESS;
      payload: {
        order: Order;
      };
    }
  | {
      type: typeof FETCH_OFFER_SUCCESS;
      payload: {
        result: string[];
      };
    }
  | {
      type: typeof SET_FIELD_VALUE;
      payload: {
        name: string;
      };
    };

const isAnOfferField = (field: string) => field?.startsWith('offer_');

export default (
  state: Order | Record<string, never> = {},
  action: OrderAction,
) => {
  switch (action.type) {
    case CREATE_ORDER_SUCCESS:
    case FETCH_ORDER_SUCCESS:
      return action.payload;
    case FETCH_TRACKING_SETUP_DATA_SUCCESS:
      const {
        payload: { order },
      } = action;
      return order;
    case FETCH_OFFER_SUCCESS:
      const { payload } = action;
      const updatedOffer = get(
        payload,
        `entities.offers.${payload.result}`,
        {},
      );

      const newOrder = { ...state };

      if (isEmpty(!updatedOffer)) {
        newOrder.offer = updatedOffer;
      }

      return newOrder;
    case UPDATE_ORDER_SUCCESS:
      const newState = { ...state, ...action.payload };
      if ('extraFees' in newState && !action.payload.extraFees) {
        delete newState.extraFees;
      }
      return newState;
    case RESET_SELECTED_OFFER:
      return { ...state, ...{ offer: {} } };
    case RESTART:
      return {};
    case ADD_ORDER_TO_USER_SUCCESS:
    case FETCH_ORDER_OWNERSHIP_SUCCESS:
      return { ...state, hasOwner: true };
    case SET_FIELD_VALUE:
      const { name } = action.payload;
      if (isAnOfferField(name)) {
        const { taxes, ...rest } = state;
        return rest;
      }

      return state;
    case RESET_ORDER:
      return {};
    default:
      return state;
  }
};
