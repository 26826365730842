import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmTax } from 'actions';
import {
  getTaxes,
  amountFrom,
  getSelectedOffer,
  hasOnlyGSTTaxes,
  isOfferDBIN,
  indianPartnerData,
} from 'selectors';
import { TaxModal } from 'components/SenderInformation/components/TaxModal/TaxModal';
import type { RootState } from 'reducers/types';

const NOOP = () => {};
const NO_MONEY = {};

function TaxModalContainerComponent({
  confirmTax = NOOP,
  hasOnlyGST = false,
  isOfferDBIN = false,
  isOpen = false,
  onClose = NOOP,
  price = NO_MONEY,
  taxes = [],
  indianPartnerData = {},
}) {
  const handleConfirmTax = () => {
    confirmTax();
    onClose();
  };

  return (
    <TaxModal
      hasOnlyGST={hasOnlyGST}
      isOfferDBIN={isOfferDBIN}
      isOpen={isOpen}
      onConfirm={handleConfirmTax}
      price={price}
      taxes={taxes}
      indianPartnerData={indianPartnerData}
    />
  );
}

TaxModalContainerComponent.displayName = 'TaxModalContainer';
TaxModalContainerComponent.propTypes = {
  confirmTax: PropTypes.func,
  currency: PropTypes.shape({ code: PropTypes.string }),
  hasOnlyGST: PropTypes.bool,
  i18n: PropTypes.shape({ t: PropTypes.func }),
  indianPartnerData: PropTypes.shape({
    indianPartner: PropTypes.string,
    indianPartnerGstin: PropTypes.string,
  }),
  isOfferDBIN: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  price: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.number,
  }),
  taxes: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};

const mapStateToProps = (state: RootState) => {
  const offer = getSelectedOffer(state);

  return {
    hasOnlyGST: hasOnlyGSTTaxes(state),
    isOfferDBIN: isOfferDBIN(offer),
    price: amountFrom(state),
    taxes: getTaxes(state),
    indianPartnerData: indianPartnerData(state),
  };
};

const mapDispatchToProps = {
  confirmTax,
};

const TaxModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaxModalContainerComponent);

export { TaxModalContainer };
