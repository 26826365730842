import React from 'react';
import PropTypes from 'prop-types';

function EURateDisclaimer({ i18n }) {
  return (
    <p className="PaymentOptions-disclaimer">
      {i18n.t('paymentOptions.disclaimer')}
    </p>
  );
}

EURateDisclaimer.displayName = 'EURateDisclaimer';
EURateDisclaimer.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }),
};

export { EURateDisclaimer };
