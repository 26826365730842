import React from 'react';
import { useCardPaymentContext } from '../../hooks/useCardPaymentContext';
import { TransactionError } from 'components/RecurringReceivables/components/TransactionError/TransactionError';

import './Error.scss';

const FIRST_STEP = 0;

function Error() {
  const { cardWizardNavigation, errorKey } = useCardPaymentContext();

  function handleOnRetry() {
    cardWizardNavigation.go(FIRST_STEP);
  }

  return (
    <TransactionError
      errorKey={errorKey}
      className="RecurringPayment-Error"
      handleOnRetry={handleOnRetry}
    />
  );
}

export { Error };
