import {
  isCAP,
  isFetching,
  getCountryCode,
  getCurrentCountryCode,
  localPaymentsAllowed,
} from 'selectors';
import geolocation from 'services/geolocation';
import {
  UPDATE_IP_COUNTRY_CODE_REQUEST,
  UPDATE_IP_COUNTRY_CODE_SUCCESS,
  SET_SENDER_COUNTRY_FIELD,
} from '../../constants';
import type { PayexThunkAction } from 'store/configureStore';

export const fetchIPCountryCode =
  (): PayexThunkAction => async (dispatch, getState) => {
    const state = getState();
    const isFetchingGeolocation = isFetching(state, 'geolocation');

    if (isFetchingGeolocation) return Promise.resolve();

    dispatch({ type: UPDATE_IP_COUNTRY_CODE_REQUEST });

    const payload = await geolocation.fetch();

    return dispatch({
      type: UPDATE_IP_COUNTRY_CODE_SUCCESS,
      payload: { countryCode: payload.countryCode },
    });
  };

export const setSenderCountryFromGeolocation =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const recipientIsCAP = isCAP(state);
    const countryCode = getCountryCode(state);
    const isCountrySet = getCurrentCountryCode(state);
    const areLocalPaymentsAllowed = localPaymentsAllowed(state);

    if (
      !countryCode ||
      recipientIsCAP ||
      isCountrySet ||
      areLocalPaymentsAllowed
    )
      return;

    dispatch({ type: SET_SENDER_COUNTRY_FIELD, payload: { countryCode } });
  };
