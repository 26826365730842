import { PERMANENT_COOKIE_NAME } from 'constants/cookies';
import { cookies } from 'utils/cookies/cookies';
import fetchJson from 'utils/fetchJson';

export const featureFlags = {
  // TODO remove parameter once we remove the camelized FFs
  fetch: (features: readonly string[], camelize?: boolean) => {
    const fingerprint = cookies.read(PERMANENT_COOKIE_NAME) || 'no_fingerprint';
    let queryString = features
      .map(feature => `features[]=${feature}`)
      .join('&');
    if (features && features.length) {
      queryString = `${queryString}&`;
    }

    queryString = `${queryString}fingerprint=${fingerprint}`;

    return fetchJson<Record<string, boolean>>(`/feature_flags?${queryString}`, {
      camelize,
    });
  },
};
