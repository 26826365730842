import React from 'react';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from 'icons/ico-info-circle.svg';
import { useTranslations } from 'utils/translations/useTranslations';
import type { Tax } from 'models/tax';
import type { ExtraFee } from 'models/extraFee';

import './AmountFromWithFeesAndTaxes.scss';

export type AmountFromWithFeesAndTaxesProps = {
  amountFrom: { value: number; currency: string };
  extraFees: ExtraFee[];
  taxes: Tax[];
};

const AmountFromWithFeesAndTaxes = ({
  amountFrom,
  extraFees = [],
  taxes = [],
}: AmountFromWithFeesAndTaxesProps) => {
  const i18n = useTranslations();
  const amountFromValue = amountFrom.value;
  const taxesAmount = taxes.reduce((total, tax) => {
    return total + tax.amount;
  }, 0);

  const extraFeesAmount = extraFees.reduce((total, extraFee) => {
    const {
      price: { value },
    } = extraFee;
    return total + value;
  }, 0);

  const totalValue = amountFromValue + taxesAmount + extraFeesAmount;

  const taxesPart = taxes.map(tax => {
    return (
      <div key={tax.id}>
        <FormattedAmount value={tax.amount} currency={tax.currency} />
        <span> {i18n.t(`paymentInformationBreakdown.${tax.id}`)}</span>
        {extraFees.length > 0 && (
          <Tooltip
            data-testid="tooltip-info"
            defaultMessage={i18n.t(
              `paymentInformationBreakdown.info.${tax.id}`,
            )}
          >
            <InfoIcon aria-hidden={true} />
          </Tooltip>
        )}
      </div>
    );
  });

  const extraFeesPart = extraFees.map(extraFee => {
    const {
      category,
      price: {
        value,
        currency: { code: currencyCode },
      },
      subcategory,
    } = extraFee;

    return (
      <div
        key={category}
        className={`AmountFromWithFeesAndTaxes-extraFee-${category}`}
      >
        <FormattedAmount value={value} currency={currencyCode} />
        <span>
          {' '}
          {subcategory === 'indian_non_fx'
            ? i18n.t('paymentInformationBreakdown.extraFees.indianNonFX')
            : i18n.t(`paymentInformationBreakdown.extraFees.${category}`)}
        </span>
      </div>
    );
  });

  return (
    <>
      <div>
        <FormattedAmount
          value={amountFromValue}
          currency={amountFrom.currency}
        />
      </div>
      {taxes.length > 0 && <div>{taxesPart}</div>}
      {extraFees.length > 0 && <div>{extraFeesPart}</div>}

      {(taxes.length > 0 || extraFees.length > 0) && (
        <>
          <hr className="AmountFromWithFeesAndTaxes-separator" />
          <FormattedAmount value={totalValue} currency={amountFrom.currency} />
        </>
      )}
    </>
  );
};

export { AmountFromWithFeesAndTaxes };
