export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';
export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const WHITELISTED_COUNTRIES_FOR_PO_BOX = [
  'AE',
  'AW',
  'BF',
  'BI',
  'BJ',
  'BL',
  'CC',
  'CF',
  'CI',
  'CK',
  'CM',
  'CX',
  'DJ',
  'FM',
  'GD',
  'GM',
  'GN',
  'GQ',
  'HM',
  'JO',
  'KE',
  'KI',
  'KM',
  'KW',
  'LB',
  'LS',
  'MF',
  'MR',
  'MS',
  'MW',
  'NE',
  'NR',
  'OM',
  'QA',
  'RW',
  'SA',
  'SB',
  'SD',
  'SN',
  'SO',
  'SZ',
  'TC',
  'TD',
  'TG',
  'TK',
  'TO',
  'TV',
  'TZ',
  'UG',
  'VI',
  'VU',
  'WS',
  'ZW',
];
export const SURCHARGE_COUNTRIES_CUSTOM_WARNING = ['AU', 'SG'];
