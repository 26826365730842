import embeddedStyles from './embeddedStyles';

const embeddedStyler = (
  element: keyof typeof embeddedStyles,
  brandColor: string,
): string => {
  const styleString = embeddedStyles[element];

  return styleString.replace(/%{([a-zA-Z]+)}/g, () => brandColor);
};

export default embeddedStyler;
