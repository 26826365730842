import React from 'react';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { HeaderMessage } from 'components/CAP/HeaderMessage/HeaderMessage';
import { IsCAP } from 'components/CAP/IsCAP/IsCAP';

type StepListProps = {
  activeStepIndex: number;
  totalSteps: number;
};

const StepList = ({ activeStepIndex, totalSteps }: StepListProps) => {
  return (
    <>
      <ProgressBar activeStepIndex={activeStepIndex} totalSteps={totalSteps} />
      <IsCAP>{activeStepIndex <= 5 && <HeaderMessage />}</IsCAP>
    </>
  );
};

export { StepList };
