import React from 'react';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { SupportedLocalesKeys, localeCodes, useTranslations } from 'utils';
import { GetA11yStatusMessageProps } from '../Dropdown/Dropdown';
import { ReactComponent as Globe } from 'icons/globe.svg';

type LanguageSelectorProps = {
  className?: string;
  upward: boolean;
  onClick?: () => void;
};

const LanguageSelector = ({
  upward,
  onClick,
  className,
}: LanguageSelectorProps) => {
  const i18n = useTranslations();
  const { setLocale, currentLocale } = i18n;
  const options = localeCodes.map(locale => {
    return {
      label: i18n.t(`locales.${locale}`),
      value: locale,
      lang: locale,
    };
  });

  const getA11yStatusMessage = ({
    isOpen,
    options = [],
    selectedOption = {},
  }: GetA11yStatusMessageProps) => {
    const isClosed = !isOpen;
    const { label } = selectedOption;

    if (isClosed) {
      return i18n.t('languageSelector.results.selected', {
        label: label || i18n.t(`locales.${currentLocale}`),
      });
    }

    const resultCount = options.length;

    return resultCount === 1
      ? i18n.t('languageSelector.results.one')
      : i18n.t('languageSelector.results.many', {
          resultCount: resultCount.toString(),
        });
  };

  const changeLocale = (locale: SupportedLocalesKeys) => {
    setLocale(locale);
    onClick?.();
  };

  return (
    <Dropdown
      defaultValue={currentLocale}
      getA11yStatusMessage={getA11yStatusMessage}
      label={i18n.t('languageSelector.label')}
      onChange={changeLocale}
      options={options}
      upward={upward}
      render={({ selectedLabel }) => (
        <>
          <Globe
            className="h-4 w-4 text-gray-950 group-hover:text-gray-700"
            aria-hidden
          />
          <span className={className}>{selectedLabel}</span>
        </>
      )}
    />
  );
};

export { LanguageSelector };
