import React from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useTranslations } from 'utils/translations/useTranslations';

type PromotionsOptInProps = {
  defaultChecked?: boolean;
  onChange?: () => void;
};

function PromotionsOptIn({ defaultChecked, onChange }: PromotionsOptInProps) {
  const i18n = useTranslations();

  return (
    <Checkbox
      aria-invalid={false}
      aria-readonly={false}
      aria-required={false}
      checked={defaultChecked}
      label={i18n.t('senderInformation.promotionsOptIn')}
      name="promotionsOptIn"
      onChange={onChange}
      readOnly={false}
      required={false}
      className="PromotionsCheckbox"
      data-testid="promotions-checkbox"
    />
  );
}

export { PromotionsOptIn };
