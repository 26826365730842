import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { repository } from 'services/tokenization/repository/repository';
import { BankAccountInstructionsAttributes } from 'services/tokenization/models/models';

function useBankAccountTokenizationInstructions(opts = {}) {
  const mutation = useCallback(
    (attrs: BankAccountInstructionsAttributes = {}) => {
      return repository.bankAccounts.createInstructions(attrs);
    },
    [],
  );

  return useMutation(mutation, opts);
}

export default useBankAccountTokenizationInstructions;
