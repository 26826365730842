import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/ui/Select/Select';
import { FormGroup } from 'components/ui/FormGroup/FormGroup';
import mobileDetector from 'utils/mobileDetector';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { FieldInterface, FieldValueType } from '../Field';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils/translations/useTranslations';

type AutocompleteFieldProps = {
  field: AutocompleteField;
  handleBlur: () => void;
  handleChange: (name: string, value: string) => void;
};

interface AutocompleteField extends FieldInterface {
  value: Array<FieldValueType>;
}

const AutocompleteField = ({
  field,
  handleBlur,
  handleChange,
}: AutocompleteFieldProps) => {
  const i18n = useTranslations();
  const { defaultValue, error, help, label, id, readOnly, required, value } =
    field;

  if (mobileDetector.isMobile()) {
    const emptyOption = {
      label: i18n.t('autocompletefield.default.option'),
      value: '',
    };

    const valueWithEmptyOption = [emptyOption, ...value];

    const handleSelectChange = (e: React.SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement;
      handleChange(id, target.value);
    };

    return (
      <FormGroup
        error={error}
        hint={help}
        name={id}
        label={label}
        required={required}
        className="has-value"
      >
        <Select
          aria-required={required}
          disabled={readOnly}
          name={id}
          values={valueWithEmptyOption}
          selectedValue={defaultValue}
          onChange={handleSelectChange}
        />
      </FormGroup>
    );
  }

  const dataTestID = `data-testid-${id}`;
  const autocompleteHandleChange = (value: string) => {
    handleChange(id, value);
  };

  return (
    <Autocomplete
      name={id}
      onChange={autocompleteHandleChange}
      onBlur={handleBlur}
      options={value}
      data-testid={dataTestID}
      selected={defaultValue}
      error={error}
      hint={help}
      required={!!required}
      readOnly={!!readOnly}
    >
      <Autocomplete.Input data-testid={dataTestID} placeholder={label} />
      <Autocomplete.Options />
      {help && (
        <Autocomplete.Hint className="FormGroup-hint">{help}</Autocomplete.Hint>
      )}
      {error && typeof error === 'string' && (
        <Autocomplete.Error className="FormGroup-feedback">
          <VisuallyHidden as="span">{label}</VisuallyHidden>
          {i18n.t(error)}
        </Autocomplete.Error>
      )}
    </Autocomplete>
  );
};

AutocompleteField.propTypes = {
  field: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
};

export { AutocompleteField };
