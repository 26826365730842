import React, { FormEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'components/Alert/Alert';
import { Heading } from 'components/Heading/Heading';
import { TextInput } from 'components/input/TextInput/TextInput';
import { Fetching } from 'components/Fetching/Fetching';
import {
  logIn,
  newNotification,
  setCaptchaResponse,
  setKeepSession,
  trackUserLoginWithUserAndPassword,
} from 'actions';
import { getKeepSession, getFieldValue } from 'selectors';
import { SENDER_EMAIL_FIELD } from 'constants/index';
import { RootState } from 'reducers/types';
import { useTranslations } from 'utils';
import { RequiredFieldLabel } from 'components/RequiredFieldLabel/RequiredFieldLabel';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { SocialSignIn } from 'components/SocialSignIn/SocialSignIn';
import { isHttpErrorWithParsedBody } from 'utils/errors/errors';
import { Captcha, CaptchaRef } from 'components/thirdParty/Captcha/Captcha';
import { shouldShowCaptchaInLogin } from 'utils/captcha/captcha';
import { Button } from 'lib/flywire-tailwind/Button';

import './UserLogin.scss';

const LOCKED_USER = 'locked_user';
const USER_NEEDS_TO_CHANGE_PASSWORD = 'user_needs_to_change_password';
const INVALID_CREDENTIALS = 'invalid_credentials';
const WAIT_TIME = 75;

type UserLoginProps = {
  firstInputRef?: React.MutableRefObject<HTMLInputElement>;
  handleSignUpClick: () => void;
  onLoginSuccess?: () => void;
  resetPassword: (showAlert?: boolean) => void;
};

type Error = { error: string; message?: string };
type ErrorBody = { parsedBody?: { errors?: Error[] } };
type FormValues = {
  username: string;
  password: string;
  error?: string | boolean;
};

const UserLogin = ({
  firstInputRef,
  handleSignUpClick,
  onLoginSuccess,
  resetPassword,
}: UserLoginProps) => {
  const i18n = useTranslations();
  const dispatch = useDispatch();
  const captchaRef = useRef<CaptchaRef>(null);
  const shouldKeepSession = useSelector(getKeepSession);
  const senderEmail = useSelector((state: RootState) =>
    getFieldValue(state, SENDER_EMAIL_FIELD),
  );
  const [formValues, setFormValues] = useState<FormValues>({
    username: senderEmail ? (senderEmail as string) : '',
    password: '',
  });

  const loginSuccess = () => {
    dispatch(
      newNotification({
        message: 'notifications.login_user_success',
      }),
    );
    onLoginSuccess?.();
  };
  const socialSignInError = () => {
    dispatch(
      newNotification({
        message: 'notifications.internal_server_failure',
        type: 'error',
      }),
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (captchaRef.current) {
      captchaRef.current?.execute();
    } else {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    const { username, password } = formValues;

    setFormValues({ ...formValues, error: undefined });

    try {
      await dispatch(
        logIn({
          username,
          password,
        }),
      );
      loginSuccess();
      dispatch(trackUserLoginWithUserAndPassword());
    } catch (error) {
      if (isHttpErrorWithParsedBody(error)) {
        const { parsedBody: { errors = [] } = {} } = error as ErrorBody;
        const userNeedsToChangePassword = errors.find(
          (item: Error) => item.error === USER_NEEDS_TO_CHANGE_PASSWORD,
        );

        const showUpdatePasswordAlert = true;
        if (userNeedsToChangePassword)
          return resetPassword(showUpdatePasswordAlert);

        const code = errors.find((item: Error) => item.error === LOCKED_USER);

        onError(code);
      } else {
        onError();
      }
    }
  };

  const onError = (code?: { error: string }) => {
    setFormValues({
      ...formValues,
      error: code ? code.error : INVALID_CREDENTIALS,
    });
    setTimeout(() => {
      firstInputRef?.current?.focus();
    }, WAIT_TIME);
  };

  const handleChangeEmail = (_: string, value = '') => {
    setFormValues({ ...formValues, username: value });
  };

  const handleChangePassword = (_: string, value = '') => {
    setFormValues({ ...formValues, password: value });
  };

  const handleKeepSession = () => {
    dispatch(setKeepSession(!shouldKeepSession));
  };

  const handleLoginWithCaptcha = async (token: string) => {
    if (token) {
      dispatch(setCaptchaResponse(token));
      handleLogin();
    } else {
      onError();
    }
  };

  const { error, username, password } = formValues;

  const showCaptcha = shouldShowCaptchaInLogin(username);

  return (
    <>
      <Fetching entity="authentication" />

      <Heading
        as="h2"
        size="large"
        className="textAlign-center marginBottom-xxl"
        id="Page-title"
      >
        {i18n.t('userLogin.title')}
      </Heading>

      <SocialSignIn
        onSuccess={loginSuccess}
        onError={socialSignInError}
        isLogin={true}
      />

      <form data-testid="logInForm" onSubmit={handleSubmit} noValidate>
        <div aria-live="assertive" role="status">
          {error && (
            <Alert
              type="danger"
              id="userLogin-error-msg"
              aria-live="assertive"
              role="status"
            >
              {i18n.t(`userLogin.alert.${error}`)}
            </Alert>
          )}
        </div>
        <RequiredFieldLabel />
        <TextInput
          aria-describedby={error ? 'userLogin-error-msg' : undefined}
          autoFocus
          required
          error={!!error}
          data-testid="logInEmail"
          floatingLabel
          forwardRef={firstInputRef}
          label={i18n.t('userLogin.username')}
          name="username"
          onChange={handleChangeEmail}
          value={username}
        />
        <TextInput
          aria-describedby={error ? 'userLogin-error-msg' : undefined}
          required
          error={!!error}
          data-testid="logInPassword"
          floatingLabel
          label={i18n.t('userLogin.password')}
          name="logInPassword"
          onChange={handleChangePassword}
          type="password"
          value={password}
        />
        <div className="RecoverPassword">
          <button
            className="RecoverPassword-button buttonLikeLink"
            onClick={() => resetPassword()}
            data-testid="recoverPassword"
            type="button"
            role="link"
          >
            {i18n.t('userLogin.recoverPassword')}
          </button>
        </div>
        <div className="paddingTop-lg">
          <Checkbox
            name="keep-session-open"
            label={i18n.t('userLogin.keepSession')}
            checked={shouldKeepSession}
            onChange={handleKeepSession}
            className="UserLogin-keepSession"
          />
        </div>
        <Button
          tone="primary"
          size="full"
          type="submit"
          className="mt-10"
          data-testid="logInButton"
        >
          {i18n.t('userLogin.button.login')}
        </Button>
      </form>
      <div className="UserLogin-question">{i18n.t('userLogin.question')}</div>
      <div className="UserLogin-link">
        <button
          onClick={handleSignUpClick}
          type="button"
          role="link"
          data-testid="signup"
        >
          {i18n.t('userLogin.signup.link')}
        </button>
      </div>
      {showCaptcha && (
        <Captcha ref={captchaRef} onVerify={handleLoginWithCaptcha} />
      )}
    </>
  );
};

export { UserLogin };
