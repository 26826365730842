import { useQuery } from 'react-query';
import { cap } from 'services/cap';

export const FIVE_MIN_IN_MS = 300000;

const useCapFee = ({ currency }: { currency?: string }, opts = {}) => {
  return useQuery(['capFee', currency], () => cap.fee({ currency }), {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: FIVE_MIN_IN_MS,
    ...opts,
  });
};

export default useCapFee;
