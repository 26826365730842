import { PureComponent } from 'react';

interface GoogleAnalyticsProps {
  siteId: string;
}

class GoogleAnalytics extends PureComponent<GoogleAnalyticsProps> {
  componentDidMount() {
    this.injectSnippet();
  }

  injectSnippet() {
    const gtag = document.createElement('script');
    gtag.setAttribute('async', 'true');
    gtag.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${this.props.siteId}`,
    );

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${this.props.siteId}');
    `;

    document.body.appendChild(gtag);
    document.body.appendChild(script);
  }

  render() {
    return null;
  }
}

export { GoogleAnalytics };
