import { combineReducers } from 'redux';
import {
  FETCH_USER_RECURRING_RECEIVABLES_FAILURE,
  FETCH_USER_RECURRING_RECEIVABLES_SUCCESS,
  FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE,
  FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS,
} from 'constants/index';
import type { RecurringPayment } from 'models';

type RecurringReceivablesActions =
  | {
      type: typeof FETCH_USER_RECURRING_RECEIVABLES_FAILURE;
    }
  | {
      type: typeof FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE;
    }
  | {
      type: typeof FETCH_USER_RECURRING_RECEIVABLES_SUCCESS;
      payload?: {
        recurringReceivables: RecurringPayment[];
        totalEntries: number;
        totalPages: number;
      };
    }
  | {
      type: typeof FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS;
      payload?: {
        recurringReceivables: RecurringPayment[];
        totalEntries: number;
        totalPages: number;
      };
    };

const INITIAL_ENTRIES = 0;
const INITIAL_PAGES = 0;

const totalEntries = (
  state = INITIAL_ENTRIES,
  action: RecurringReceivablesActions,
) => {
  switch (action.type) {
    case FETCH_USER_RECURRING_RECEIVABLES_SUCCESS:
    case FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS:
      return action.payload?.totalEntries || state;
    case FETCH_USER_RECURRING_RECEIVABLES_FAILURE:
    case FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE:
      return INITIAL_ENTRIES;

    default: {
      return state;
    }
  }
};

const totalPages = (
  state = INITIAL_PAGES,
  action: RecurringReceivablesActions,
) => {
  switch (action.type) {
    case FETCH_USER_RECURRING_RECEIVABLES_SUCCESS:
    case FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS:
      return action.payload?.totalPages || state;
    case FETCH_USER_RECURRING_RECEIVABLES_FAILURE:
    case FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE:
      return INITIAL_PAGES;

    default: {
      return state;
    }
  }
};

export default combineReducers({ totalEntries, totalPages });
