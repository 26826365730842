export enum HTTPStatuses {
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  NOT_ALLOWED = 405,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
}

export const REQUEST_ID_HEADER = 'X-Request-Id';
export const ACCEPT_JSON_HEADER = 'application/json';
export const GET_METHOD = 'GET';

export const REQUESTER_HEADER = 'X-Requester';

export type HttpError = Error & {
  response?: Response;
  status?: number;
  parsedBody?: {
    errors: { error: string }[] | unknown;
    error?: unknown;
  };
};
