import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components/Heading/Heading';

import './Breakdown.scss';

const Breakdown = ({ children, title, id }) => (
  <section className="Breakdown" data-testid={`section_${id}`}>
    <div className="Breakdown-header">
      <Heading as="h2" size="medium" id={`${id}-title`}>
        {title}
      </Heading>
    </div>
    <div className="Breakdown-body">{children}</div>
  </section>
);

const { node, string } = PropTypes;

Breakdown.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
  title: string.isRequired,
};

export { Breakdown };
