import { UPDATE_IP_COUNTRY_CODE_SUCCESS } from '../../constants';
import type { Geolocation } from 'models';

type GeolocationAction = {
  type: typeof UPDATE_IP_COUNTRY_CODE_SUCCESS;
  payload: Geolocation;
};

export default (
  state: Geolocation | Record<string, never> = {},
  action: GeolocationAction,
) => {
  switch (action.type) {
    case UPDATE_IP_COUNTRY_CODE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
