import {
  FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS,
  FETCH_USER_RECURRING_RECEIVABLES_SUCCESS,
  LOGOUT_USER_SUCCESS,
} from 'constants/index';
import type { RecurringPayment } from 'models';

type RecurringReceivablesAction =
  | {
      type: typeof FETCH_USER_RECURRING_RECEIVABLES_SUCCESS;
      payload: {
        recurringReceivables: RecurringPayment[];
      };
    }
  | {
      type: typeof FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS;
      payload: {
        recurringReceivables: RecurringPayment[];
      };
    }
  | { type: typeof LOGOUT_USER_SUCCESS };

export const recurringReceivables = (
  state: RecurringPayment[] = [],
  action: RecurringReceivablesAction,
) => {
  switch (action.type) {
    case FETCH_USER_RECURRING_RECEIVABLES_SUCCESS:
    case FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS:
      return action.payload?.recurringReceivables;
    case LOGOUT_USER_SUCCESS:
      return [];
    default:
      return state;
  }
};

export default recurringReceivables;
