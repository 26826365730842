import fetchJson from '../../../utils/fetchJson';
import { API_URL_V3 } from '../../../constants';
import { Field } from 'models/field';

type FetchSendersRequestBody = {
  country: string;
  locale?: string;
  isBankTransfer?: boolean;
  isFromIndia?: boolean;
  isFromLulu?: boolean;
};

export const senders = {
  fetch: ({
    country,
    locale = 'EN',
    isBankTransfer = false,
    isFromIndia = false,
    isFromLulu = false,
  }: FetchSendersRequestBody) => {
    let path = `${API_URL_V3}/senders?locale=${locale}&country=${country}`;
    path += isBankTransfer ? `&isBankTransfer=${isBankTransfer}` : '';
    path += isFromIndia ? `&isFromIndia=${isFromIndia}` : '';
    path += isFromLulu ? `&isFromLulu=${isFromLulu}` : '';
    return fetchJson<{ fields?: Field[] }>(path);
  },
};
