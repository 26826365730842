import {
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_FAILURE,
} from '../../../constants';
import { getCurrentLocale, isFetching } from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import type { PayexThunkAction } from 'store/configureStore';
import { normalizeCountries } from 'services/countries/countries';

export const fetchCountries =
  (locale?: string): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const isFetchingCountries = isFetching(state, 'countries');
    if (isFetchingCountries) return Promise.resolve();

    dispatch({
      type: FETCH_COUNTRIES_REQUEST,
    });

    try {
      const response = await apiV3.countries.fetch({
        locale: locale || getCurrentLocale(state),
      });
      return dispatch({
        type: FETCH_COUNTRIES_SUCCESS,
        payload: normalizeCountries(response),
      });
    } catch (error) {
      errorNotifier.notify(error);
      dispatch({ type: FETCH_COUNTRIES_FAILURE });
    }
  };
