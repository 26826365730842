import React, {
  Children,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  isValidElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { CardPaymentMethod } from 'models';
import {
  CardNewMandateEvent,
  CardNewMandateRef,
} from '@flywire/internal-react-elements';
import { CreditCards } from '../CreditCards';

type CreditCardsContextType = {
  cards: CardPaymentMethod[];
  cvvRef: RefObject<CardNewMandateRef>;
  cvvEvent?: CardNewMandateEvent;
  isSubmitting: boolean;
  isCVVLoading: boolean;
  setIsCVVLoading: Dispatch<SetStateAction<boolean>>;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  setCvvEvent: Dispatch<SetStateAction<CardNewMandateEvent | undefined>>;
  hasAddForm: boolean;
  showTokenizationForm: boolean;
  setShowTokenizationForm: Dispatch<SetStateAction<boolean>>;
};

const CreditCardsContext = createContext<CreditCardsContextType | undefined>(
  undefined,
);

type CreditCardsProps = {
  cards?: CardPaymentMethod[];
  cvvRef?: RefObject<CardNewMandateRef>;
};

const CreditCardsProvider = ({
  cards = [],
  cvvRef,
  children,
}: PropsWithChildren<CreditCardsProps>) => {
  const { hasAddForm, hasCardsList } = detectChildren(children);
  const hasNoCards = !cards.length || !hasCardsList;

  const [showTokenizationForm, setShowTokenizationForm] = useState(
    hasNoCards && hasAddForm,
  );
  const _cvvRef = useRef<CardNewMandateRef>(null);
  const [isCVVLoading, setIsCVVLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cvvEvent, setCvvEvent] = useState<CardNewMandateEvent | undefined>();

  useEffect(() => {
    setShowTokenizationForm(hasNoCards && hasAddForm);
  }, [hasAddForm, hasNoCards]);

  if (hasNoCards && !showTokenizationForm) {
    return null;
  }

  return (
    <CreditCardsContext.Provider
      value={{
        cards,
        cvvEvent,
        cvvRef: cvvRef || _cvvRef,
        hasAddForm,
        isCVVLoading,
        isSubmitting,
        showTokenizationForm,
        setCvvEvent,
        setIsCVVLoading,
        setIsSubmitting,
        setShowTokenizationForm,
      }}
    >
      {children}
    </CreditCardsContext.Provider>
  );
};

const useCreditCards = () => {
  const context = useContext(CreditCardsContext);

  if (!context) {
    throw new Error(
      'CreditCardsContext must be used within a context provider',
    );
  }
  return context;
};

type ChildrenTypes = {
  hasAddForm: boolean;
  hasCardsList: boolean;
  hasDisclaimer: boolean;
};

type ElementType =
  | string
  | React.FunctionComponent<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  | React.ComponentClass<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

function areComponentsEqual(a: ElementType, b: ElementType): boolean {
  return a === b;
}

const detectChildren = (children: ReactNode) => {
  return Children.toArray(children).reduce(
    (acc: ChildrenTypes, current: ReactNode) => {
      if (isValidElement(current)) {
        if (areComponentsEqual(current.type, CreditCards.AddCardForm)) {
          return {
            ...acc,
            hasAddForm: true,
          };
        }

        if (areComponentsEqual(current.type, CreditCards.CardList)) {
          return {
            ...acc,
            hasCardsList: true,
          };
        }
      }

      return acc;
    },
    {
      hasAddForm: false,
      hasCardsList: false,
    } as ChildrenTypes,
  );
};

export { CreditCardsProvider, useCreditCards };
