export const embeddedStyles = {
  inputFocus: `
    .Input:focus-visible {
      border: 1px solid  %{brandColor};
      box-shadow: inset 0 0 0 1px  %{brandColor};
    }
  `,
  button: `
    [data-tone="primary"] {
      background: %{brandColor};
      border-color: %{brandColor};
    }
    [data-tone="primary"]:hover {
      background: %{brandColor};
      border-color: %{brandColor};
      opacity: .8;
    }
    [data-tone="primary"]:focus-visible {
      background: %{brandColor};
      box-shadow: inset 0px 0px 0px 2px %{brandColor};
      opacity: .8;
    }
    .Button--outlinePrimary {
      color: %{brandColor};
      box-shadow: inset 0px 0px 0px 1px %{brandColor};
    }
    .Button--outlinePrimary:hover {
      background-color: %{brandColor};
      opacity: .8;
    }
    .Button--outlinePrimary:focus-visible {
      box-shadow: inset 0px 0px 0px 2px %{brandColor};
    }
  `,
  textAreaFocus: `
    .Textarea:focus-visible {
      border: 1px solid  %{brandColor};
      box-shadow: inset 0 0 0 1px  %{brandColor};
    }
  `,
  autocompleteFocus: `
    .Autocomplete.is-searching input {
      border: 1px solid  %{brandColor} !important;
      box-shadow: inset 0 0 0 1px %{brandColor} !important;
    }
    .Autocomplete.is-searching ul {
      border: 2px solid  %{brandColor} !important;
      border-top: 1px solid #f7f9fa !important;
    }
    .Autocomplete-option.is-active {
      background-color: %{brandColor};
    }
    .PhoneNumber .is-searching .Autocomplete-options {
      border-top: 2px solid  %{brandColor} !important;
    }
    .is-searching .Autocomplete-search {
      border: 1px solid  %{brandColor} !important;
      box-shadow: inset 0 0 0 1px %{brandColor} !important;
    }
  `,
  checkbox: `
    .Checkbox-input:focus-visible ~ .Checkbox-label:before {
      border-color: %{brandColor};
    }
    .Checkbox-input:checked ~ .Checkbox-label:before,
    .Checkbox-input:focus-visible:checked ~ .Checkbox-label:before {
      background-color: %{brandColor};
      border-color: %{brandColor};
    }
  `,
  modal: `
    .Modal--medium .Modal-dialog {
      max-width: 90%;
    }

    @media (min-width: 545px) {
      .Modal--medium .Modal-dialog {
        max-width: 528px;
      }
    }
  `,
  site: `
    .Site,html {
      height: auto;
    }
  `,
  spinner: `
    .Spinner:after {
      border-color: %{brandColor} transparent %{brandColor} %{brandColor};
    }
  `,
};

export default embeddedStyles;
