import fetchJson from 'utils/fetchJson';
import {
  CardError,
  Instructions,
  CardInstructionsAttributes,
  BankAccountInstructionsAttributes,
  TokenizationFormError,
  InstructionsParams,
} from '../models/models';
import { chargeMapper, instructionsMapper } from '../mappers';
import { Order } from 'models';

export type ChargeResponse = {
  status: string;
  paymentId: string;
  amount: number;
  currency: string;
  message: string;
};

type TokenizationRepository = {
  cards: {
    createInstructions: (
      attrs: CardInstructionsAttributes,
    ) => Promise<Instructions>;
    charge: (attrs: {
      apiConfirmationURL: string;
      order: Order;
      payorId: string;
      uuidToken: string;
    }) => Promise<ChargeResponse>;
  };
  bankAccounts: {
    createInstructions: (
      attrs: BankAccountInstructionsAttributes,
    ) => Promise<Instructions>;
  };
};

export const repository: TokenizationRepository = {
  cards: {
    createInstructions: async attrs => {
      const { accessToken, ...rest } = attrs;
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(instructionsMapper.cards.mapToBackEnd(rest)),
      };
      const options = {} as Record<string, unknown>;
      if (accessToken) {
        options.headers = { Authorization: `Bearer ${accessToken}` };
      }
      return fetchJson<InstructionsParams>('/card_on_file/instructions', {
        ...requestOptions,
        ...options,
      }).then(
        data => {
          return new Instructions(data);
        },
        () => {
          throw new TokenizationFormError({ status: 'failed' });
        },
      );
    },

    charge: async attrs => {
      return fetchJson<ChargeResponse>('/card_on_file/charges/surcharge', {
        method: 'POST',
        body: JSON.stringify(chargeMapper.mapToBackEnd(attrs)),
      }).catch(err => {
        throw new CardError(err);
      });
    },
  },
  bankAccounts: {
    createInstructions: async attrs => {
      const { accessToken, ...rest } = attrs || {};
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(
          instructionsMapper.bankAccounts.mapToBackEnd(rest),
        ),
      };
      const options = {} as Record<string, unknown>;

      if (accessToken) {
        options.headers = { Authorization: `Bearer ${accessToken}` };
      }

      return fetchJson<InstructionsParams>(
        '/tokenization/instructions/bank_accounts',
        {
          ...requestOptions,
          ...options,
        },
      ).then(
        data => new Instructions(data),
        () => {
          throw new TokenizationFormError({ status: 'failed' });
        },
      );
    },
  },
};
