import {
  FETCH_OFFERS_FAILURE,
  FETCH_OFFERS_REQUEST,
  FETCH_OFFERS_SUCCESS,
  FETCH_LEGACY_RECURRING_OFFERS_SUCCESS,
  FETCH_RECURRING_OFFERS_REQUEST,
  FETCH_RECURRING_OFFERS_FAILURE,
  FETCH_RECURRING_OFFERS_SUCCESS,
  HTTPStatuses,
  PERMANENT_COOKIE_NAME,
} from 'constants/index';
import {
  getRecipient,
  getOrderAmount,
  getSenderCountry,
  isFetching,
  getCurrentLocale,
} from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import { cookies } from 'utils/cookies/cookies';
import type { RootState } from 'reducers/types';
import type { PayexDispatch, PayexThunkAction } from 'store/configureStore';
import { normalizeOffers } from 'services/payex/offers/offers';
import type { PayexService } from 'services/payex';

export const fetchOffersFromOrderIfNeeded =
  (): PayexThunkAction => (dispatch, getState) => {
    if (shouldFetchOffers(getState())) {
      return dispatch(fetchOffersFromOrder());
    }

    return Promise.resolve();
  };

export const fetchOffersFromOrder =
  (): PayexThunkAction =>
  (dispatch, getState, { payex }) => {
    const state = getState();

    const { id: recipientId } = getRecipient(state);
    const country = getSenderCountry(state);
    const amount = getOrderAmount(state);
    const locale = getCurrentLocale(state);
    const fingerprint = cookies.read(PERMANENT_COOKIE_NAME) || 'no_fingerprint';

    return fetchOffers({
      recipientId,
      country: country as string,
      amount,
      fingerprint,
      locale,
      dispatch,
      state,
      payex,
    });
  };

const shouldFetchOffers = (state: RootState) => {
  const offers = state.entities.offers;
  const none = offers.ids.length === 0;

  if (none) {
    return true;
  }

  return offers.didInvalidate;
};

const fetchOffers = ({
  recipientId,
  country,
  amount,
  fingerprint,
  locale,
  dispatch,
  state,
  payex,
}: {
  recipientId: string;
  country: string;
  amount: number;
  fingerprint: string;
  locale: string;
  dispatch: PayexDispatch;
  state: RootState;
  payex: PayexService;
}) => {
  const isFetchingOffers = isFetching(state, 'offers');
  if (isFetchingOffers) return Promise.resolve();

  dispatch({
    type: FETCH_OFFERS_REQUEST,
  });

  return payex.offers
    .getAll({ recipientId, country, amount, fingerprint, locale })
    .then(response => {
      const singleOffersPayload = normalizeOffers(response.single || []);
      const legacyRecurringOffersPayload = normalizeOffers(
        response.legacyRecurring || [],
      );

      dispatch({
        type: FETCH_OFFERS_SUCCESS,
        payload: singleOffersPayload,
      });

      dispatch({
        type: FETCH_LEGACY_RECURRING_OFFERS_SUCCESS,
        payload: legacyRecurringOffersPayload,
      });

      const recurringOffersPayload = normalizeOffers(
        response?.recurring?.data || [],
      );

      dispatch({
        type: FETCH_RECURRING_OFFERS_SUCCESS,
        payload: recurringOffersPayload,
        metadata: response?.recurring?.metadata || {},
      });
    })
    .catch(error => {
      dispatch({ type: FETCH_OFFERS_FAILURE });
      const { status } = error;
      if (status !== HTTPStatuses.NOT_FOUND) {
        error.message = `${FETCH_OFFERS_FAILURE} ${error.message}`;
        errorNotifier.notifyWithFingerprint(error, [
          FETCH_OFFERS_FAILURE,
          status,
        ]);
      }
    });
};

export const fetchRecurringOffers = ({
  recipientId,
  country,
  amount,
  installments,
  dispatch,
  state,
  payex,
}: {
  recipientId: string;
  country: string;
  amount: number;
  installments: number;
  dispatch: PayexDispatch;
  state: RootState;
  payex: PayexService;
}) => {
  const isFetchingOffers = isFetching(state, 'recurringOffers');
  if (isFetchingOffers) return Promise.resolve();

  dispatch({
    type: FETCH_RECURRING_OFFERS_REQUEST,
  });

  return payex.offers
    .getRecurringOffers({ recipientId, country, amount, installments })
    .then(response => {
      const recurringOffersPayload = normalizeOffers(response.data || []);

      dispatch({
        type: FETCH_RECURRING_OFFERS_SUCCESS,
        payload: recurringOffersPayload,
        metadata: response.metadata || {},
      });
    })
    .catch(error => {
      dispatch({ type: FETCH_RECURRING_OFFERS_FAILURE });
      const { status } = error;

      if (status !== HTTPStatuses.NOT_FOUND) {
        error.message = `${FETCH_OFFERS_FAILURE} ${error.message}`;
        errorNotifier.notifyWithFingerprint(error, [
          FETCH_RECURRING_OFFERS_FAILURE,
          status,
        ]);
      }
    });
};

export const fetchRecurringOffersFromOrder =
  ({ installments }: { installments: number }): PayexThunkAction =>
  (dispatch, getState, { payex }) => {
    const state = getState();

    const { id: recipientId } = getRecipient(state);
    const country = getSenderCountry(state);
    const amount = getOrderAmount(state);

    return fetchRecurringOffers({
      recipientId,
      amount,
      country: country as string,
      installments,
      dispatch,
      state,
      payex,
    });
  };
