import React, {
  FormEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Alert } from 'components/Alert/Alert';
import { Button } from 'lib/flywire-tailwind/Button';
import { Heading } from 'components/Heading/Heading';
import { ConnectedProps, connect } from 'react-redux';
import { TextInput } from 'components/input/TextInput/TextInput';
import { resetUserPassword } from 'actions';
import { clientValidate } from 'utils/clientValidate/clientValidate';
import isEmpty from 'lodash/isEmpty';
import { useTranslations } from 'utils';
import errorNotifier from 'utils/errorNotifier';

import './UserForgotPassword.scss';

const WAIT_TIME = 75;

type UserForgotPasswordProps = {
  onLogin: () => void;
  resetUserPassword?: (email: string) => void;
  showUpdatePasswordAlert?: boolean;
} & ConnectedProps<typeof connector>;

export const UserForgotPasswordComponent = ({
  onLogin,
  resetUserPassword,
  showUpdatePasswordAlert = false,
}: UserForgotPasswordProps) => {
  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const i18n = useTranslations();
  const emailInputRef = useRef();

  const setFocusOnInput = () =>
    setTimeout(() => {
      emailInputRef.current?.focus();
    }, WAIT_TIME);

  useEffect(() => {
    setFocusOnInput();
  }, [error]);

  const validateErrors = (fields: { userEmail: string }) => {
    return (
      clientValidate.validate(fields, getConstraints(), {
        fullMessages: false,
      }) || {}
    );
  };

  const getConstraints = () => {
    return {
      userEmail: {
        email: { message: i18n.t('userForgotPassword.userEmail.invalid') },
      },
    };
  };

  const handleResetPassword = async (e: FormEvent) => {
    e.preventDefault();
    const error = validateErrors({ userEmail: userEmail });
    if (!isEmpty(error)) return setError(error.userEmail[0]);

    try {
      await resetUserPassword(userEmail);
    } catch (error) {
      errorNotifier.notify(error);
    } finally {
      setIsSuccess(true);
    }
  };

  const handleUserEmailChange = (name: string, value: string) => {
    setUserEmail(value);
  };

  return (
    <>
      {isSuccess ? (
        <UserForgotPasswordSuccess onLogin={onLogin} userEmail={userEmail} />
      ) : (
        <UserForgotPasswordForm
          onLogin={onLogin}
          showUpdatePasswordAlert={showUpdatePasswordAlert}
          handleResetPassword={handleResetPassword}
          handleUserEmailChange={handleUserEmailChange}
          error={error}
          forwardRef={emailInputRef}
        />
      )}
    </>
  );
};

type UserForgotPasswordSuccessProps = {
  onLogin: () => void;
  userEmail: string;
};

const UserForgotPasswordSuccess = ({
  onLogin,
  userEmail,
}: UserForgotPasswordSuccessProps) => {
  const i18n = useTranslations();

  return (
    <>
      <span className="UserForgotPassword-checked" />
      <Heading
        as="h4"
        size="large"
        className="textAlign-center marginBottom-xxl"
        id="Page-title"
      >
        {i18n.t('userForgotPassword.success.title')}
      </Heading>
      <div className="textAlign-center paddingLeft-md paddingRight-md">
        <p>{i18n.t('userForgotPassword.success.text', { userEmail })}</p>
      </div>
      <div className="UserForgotPassword-login">
        <button
          className="UserForgotPassword-button"
          onClick={onLogin}
          type="button"
          role="link"
        >
          {i18n.t('userForgotPassword.login.link')}
        </button>
      </div>
    </>
  );
};

type UserForgotPasswordFormProps = {
  error?: string | boolean;
  forwardRef?: RefObject<HTMLInputElement>;
  handleResetPassword: (e: FormEvent) => unknown;
  handleUserEmailChange: (name: string, value: string) => void;
  onLogin: () => void;
  showUpdatePasswordAlert?: boolean;
};

const UserForgotPasswordForm = ({
  handleResetPassword,
  handleUserEmailChange,
  error,
  onLogin,
  showUpdatePasswordAlert,
  forwardRef,
}: UserForgotPasswordFormProps) => {
  const i18n = useTranslations();

  return (
    <>
      {showUpdatePasswordAlert && (
        <Alert type="warning">
          {i18n.t(`userLogin.alert.updatePasswordAlert`)}
        </Alert>
      )}
      <form className="paddingBottom-lg" onSubmit={handleResetPassword}>
        <Heading
          as="h4"
          size="large"
          className="textAlign-center marginBottom-xxl"
          id="Page-title"
        >
          {i18n.t('userForgotPassword.title')}
        </Heading>
        <TextInput
          aria-required="true"
          autoFocus
          error={error}
          floatingLabel
          forwardRef={forwardRef}
          label={i18n.t('userForgotPassword.userEmail.inputLabel') as string}
          name="userEmail"
          onChange={handleUserEmailChange}
          value={''}
        />
        <Button
          tone="primary"
          size="full"
          type="submit"
          className="mt-10"
          data-testid="resetPasswordButton"
        >
          {i18n.t('userForgotPassword.resetPassword.buttonText')}
        </Button>
      </form>
      <div className="UserForgotPassword-login">
        <button
          className="UserForgotPassword-button"
          onClick={onLogin}
          type="button"
          role="link"
        >
          {i18n.t('userForgotPassword.login.link')}
        </button>
      </div>
    </>
  );
};

const mapDispatchProps = {
  resetUserPassword,
};

const connector = connect(null, mapDispatchProps);
const UserForgotPassword = connector(UserForgotPasswordComponent);

export { UserForgotPassword };
