import React from 'react';
import { useTranslations } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedOffer,
  getCurrency,
  isRedeemCashbackApplied,
  isFetching,
} from 'selectors';
import { Accept } from 'components/Accept/Accept';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { formatAmount } from 'utils/money';
import { RootState } from 'reducers/types';
import { ReactComponent as BannerBackground } from './assets/bannerBackground.svg';
import { ReactComponent as BannerBackgroundBonus } from './assets/bannerBackgroundBonus.svg';
import { addRedeemCashback, deleteRedeemCashback } from 'actions';
import './RedeemCashback.scss';
import { Currency } from '@flywire/internal-react-elements';

const RedeemCashback = ({
  availableRedeemAmount,
  walletAmount,
  bonusAmount = 0,
}: {
  availableRedeemAmount: number;
  walletAmount: number;
  bonusAmount?: number;
}) => {
  const i18n = useTranslations();
  const dispatch = useDispatch();
  const redeemCashbackApplied = useSelector(isRedeemCashbackApplied);
  const offer = useSelector(getSelectedOffer);
  const { price: { currency: currencyFromCode } = {} } = offer;
  const currencyFrom = useSelector((state: RootState) =>
    getCurrency(state, currencyFromCode as string),
  );
  const orderIsFetching = useSelector((state: RootState) =>
    isFetching(state, 'order'),
  );

  const handleAcceptedChange = async () => {
    redeemCashbackApplied
      ? await dispatch(deleteRedeemCashback())
      : await dispatch(addRedeemCashback());
  };

  const redeemAmount = availableRedeemAmount + bonusAmount;

  return redeemAmount > 0 ? (
    <div className="RedeemCashback" data-testid="RedeemCashback">
      <div
        className={
          bonusAmount ? 'RedeemCashbackBonus-banner' : 'RedeemCashback-banner'
        }
      >
        {bonusAmount ? (
          <BannerBackgroundBonus className="RedeemCashbackBonus-bannerBackgound" />
        ) : (
          <BannerBackground className="RedeemCashback-bannerBackgound" />
        )}
        <div className="RedeemCashback-bannerMessage">
          <p className="RedeemCashback-bannerMessageTitle">
            {i18n.t('redeemCashback.cashback.earnRewards')}
          </p>
          <p className="RedeemCashback-bannerMessageAmount">
            {formatAmount(walletAmount, currencyFrom, {
              cents: false,
            })}
          </p>
          <BonusAmount bonusAmount={bonusAmount} currencyFrom={currencyFrom} />
        </div>
      </div>

      <Accept
        name="redeemCashbackAccepted"
        checked={redeemCashbackApplied}
        onChange={handleAcceptedChange}
        readOnly={orderIsFetching}
        dataTestId="redeemCashbackCheckbox"
      >
        <span
          dangerouslySetInnerHTML={createMarkup(
            i18n.t(
              bonusAmount
                ? 'redeemCashback.checkboxText.bonus'
                : 'redeemCashback.checkboxText',
              {
                amount: formatAmount(redeemAmount, currencyFrom, {
                  cents: false,
                }),
              },
            ),
          )}
          data-testid="redeemCashbackCheckbox-label"
        />
      </Accept>
    </div>
  ) : null;
};

const BonusAmount = ({
  bonusAmount,
  currencyFrom,
}: {
  bonusAmount?: number;
  currencyFrom: Currency;
}) => {
  const i18n = useTranslations();

  if (!bonusAmount) return null;

  return (
    <>
      <p className="RedeemCashbackBonus-bannerMessageAmount">
        +
        {formatAmount(bonusAmount, currencyFrom, {
          cents: false,
        })}
      </p>
      <p className="RedeemCashbackBonus-bannerMessageTitle">
        {i18n.t('redeemCashback.cashback.bonus')}
      </p>
    </>
  );
};

export { RedeemCashback };
