import {
  FETCH_DOCUMENTS_SUCCESS,
  UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT,
} from 'constants/index';
import type { LRS, PreviousPayment } from 'models';

type LRSAction =
  | {
      type: typeof FETCH_DOCUMENTS_SUCCESS;
      payload: {
        previousPayments?: PreviousPayment[];
        idVerificationNeeded?: boolean;
      };
    }
  | { type: typeof UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT };

const initialState = {
  idVerificationNeeded: true,
  previousPayments: [],
  signatureUploadFailed: false,
};

const lrs = (state: LRS = initialState, action: LRSAction) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      const { previousPayments, idVerificationNeeded } = action.payload;

      return {
        ...state,
        previousPayments: previousPayments || ([] as PreviousPayment[]),
        idVerificationNeeded,
      };
    case UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT:
      return {
        ...state,
        signatureUploadFailed: true,
      };
    default:
      return state;
  }
};

export default lrs;
