import React from 'react';
import { Offer } from './components/Offer/Offer';
import { formatAmount } from 'utils/money';
import { ReactComponent as InfoIcon } from 'icons/ico-info-current-color.svg';
import { Tooltip } from 'components/Tooltip/Tooltip';
import {
  NOOP,
  OPP_UNIONPAY_CAMPAIGN,
  RECOMMENDED_OFFERS_FOR_INDIA,
  SBI_CAMPAIGN_OPTIONS,
} from 'constants/index';
import { useTranslations } from 'utils';
import date from 'utils/date';
import { IsNotCAP } from 'components/CAP/IsNotCAP/IsNotCAP';
import type { Currency, Fee, Price } from 'models';
import { useSelector } from 'react-redux';
import { isEmbedded } from 'selectors';

const TRACKER_SELECT_VALUE = 'select';
const TRACKER_MORE_INFO_VALUE = 'show more';

type OptionProps = {
  chargeDay?: number;
  currency: Currency;
  description: string;
  exchangeRate?: number;
  fee: Fee;
  id: string;
  interval?: number;
  logo?: unknown;
  logos?: unknown[];
  markupRate?: number;
  name: string;
  onSelect?: (id: string) => void;
  payingFromIndiaInUSD: boolean;
  paymentMethod: string;
  paymentOption: string;
  price: Price;
  recommended?: boolean;
  requiresDocuments?: boolean;
  trackCheckMarkup: () => void;
  trackHeapSelectedPaymentOption: () => void;
  trackPaymentOption: (paymentOption: string, value: string) => void;
  unfoldImportantInfo: boolean;
  redeemRewards?: boolean;
};

function Option({
  chargeDay,
  currency,
  description,
  exchangeRate,
  fee,
  id,
  interval,
  logo,
  logos = [],
  markupRate,
  name,
  onSelect,
  payingFromIndiaInUSD,
  paymentMethod,
  paymentOption,
  price,
  recommended = false,
  requiresDocuments = false,
  trackCheckMarkup = NOOP,
  trackHeapSelectedPaymentOption = NOOP,
  trackPaymentOption = NOOP,
  unfoldImportantInfo,
  redeemRewards,
}: OptionProps) {
  const i18n = useTranslations();
  const { value: priceValue } = price;
  const { value: feeValue } = fee;
  const hasFee = feeValue > 0;
  const hasFx = markupRate && exchangeRate;
  const feeWithFormat = formatAmount(feeValue, currency);
  const priceWithFormat = formatAmount(priceValue, currency);
  const {
    Actions,
    Body,
    Content,
    Description,
    FXInfo,
    Fee,
    Label,
    LogosList,
    MoreInfo,
    Name,
    PaymentMethodLogo,
    Price,
    Recommended,
  } = Offer;

  const embedded: boolean = useSelector(isEmbedded);
  const showRedeemRewardsLabel = redeemRewards && !embedded;

  function handleMoreInfoOnToggle() {
    trackPaymentOption(paymentOption, TRACKER_MORE_INFO_VALUE);
  }

  function handleFXInfoOnToggle() {
    trackCheckMarkup();
  }

  function handleOnSelect(id: string) {
    trackPaymentOption(paymentOption, TRACKER_SELECT_VALUE);
    trackHeapSelectedPaymentOption();
    onSelect?.(id);
  }

  function isUnionPayRecommendedOption() {
    return OPP_UNIONPAY_CAMPAIGN.includes(paymentOption) && !embedded;
  }

  function isSBIRecommendedOption() {
    return SBI_CAMPAIGN_OPTIONS.includes(paymentOption) && !embedded;
  }

  function recommendedForIndia(paymentOption: string) {
    return (
      payingFromIndiaInUSD &&
      RECOMMENDED_OFFERS_FOR_INDIA.includes(paymentOption)
    );
  }

  function isRecommended() {
    return (
      recommended ||
      isUnionPayRecommendedOption() ||
      isSBIRecommendedOption() ||
      recommendedForIndia(paymentOption)
    );
  }

  function recommendationText() {
    if (recommended) return 'paymentOptions.recommended';

    if (isSBIRecommendedOption()) {
      return 'paymentOptions.recommended.newAndExclusive';
    }

    return 'paymentOptions.recommended.fastAndMostPopular';
  }

  const feeComponent = hasFee && <Fee value={feeWithFormat} />;

  return (
    <Offer
      i18n={i18n}
      id={id}
      name={name}
      currency={currency}
      fee={fee}
      price={price}
      className={isRecommended() ? 'Offer--recommended' : null}
    >
      {isRecommended() && (
        <Recommended>
          <span className="Offer--recommended-desktopTitle">
            {i18n.t(recommendationText())}
          </span>
          <span className="Offer--recommended-mobileTitle">
            {i18n.t('paymentOptions.recommended.mobile')}
          </span>
        </Recommended>
      )}
      <Body>
        <PaymentMethodLogo logo={logo} paymentMethod={paymentMethod} />
        <Content>
          <Name value={name} />
          <Price>
            {priceWithFormat}{' '}
            {interval && (
              <span>
                {i18n.t('paymentOptions.recurringPayment.interval', {
                  interval: `${interval}`,
                })}
                {chargeDay && (
                  <>
                    {', '}
                    <span className="Offer-recurringPayment-chargeDay">
                      {i18n.t('paymentOptions.recurringPayment.chargedDate', {
                        chargeDayOrdinal: `${date.getCardinalNumber(
                          chargeDay,
                          i18n.currentLocale,
                        )}`,
                        chargeDay: `${chargeDay}`,
                      })}
                      <Tooltip
                        defaultMessage={
                          i18n.t(
                            'paymentOptions.recurringPayment.chargedDate.tooltip',
                          ) as string
                        }
                      >
                        <InfoIcon
                          height="12"
                          width="12"
                          className="marginRight-xs"
                          aria-hidden="true"
                        />
                      </Tooltip>
                    </span>
                  </>
                )}
              </span>
            )}
          </Price>
          {requiresDocuments && (
            <span className="Offer--requiresDocumentation">
              <Tooltip
                defaultMessage={
                  i18n.t(
                    'paymentOptions.requiresDocumentation.tooltip',
                  ) as string
                }
                className="Offer--requiresDocumentation-tooltip"
              >
                <InfoIcon
                  height="12"
                  width="12"
                  className="marginRight-xs"
                  aria-hidden="true"
                />
                {i18n.t('paymentOptions.requiresDocumentation')}
              </Tooltip>
            </span>
          )}

          <IsNotCAP>{feeComponent}</IsNotCAP>

          {hasFx && (
            <FXInfo
              className="Offer-fxInfo"
              markupRate={markupRate}
              exchangeRate={exchangeRate}
              i18n={i18n}
              onToggle={handleFXInfoOnToggle}
              id={id}
            />
          )}
        </Content>
      </Body>
      <LogosList logos={logos} />
      <div className="Offer-actionsContainer">
        <Actions onSelect={handleOnSelect} i18n={i18n} />
        {showRedeemRewardsLabel && (
          <Label value={i18n.t('paymentOptions.redeemRewards')} />
        )}
      </div>

      {description && (
        <MoreInfo
          onToggle={handleMoreInfoOnToggle}
          initialOpen={unfoldImportantInfo}
        >
          <Description value={description} />
        </MoreInfo>
      )}
    </Offer>
  );
}

export { Option };
