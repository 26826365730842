import {
  CLOSE_MODAL_REMAINING_BALANCE_PAYMENT_CREATED,
  OPEN_MODAL_REMAINING_BALANCE_PAYMENT_CREATED,
} from 'constants/index';
import { combineReducers } from 'redux';

type PaymentCreatedModalAction = {
  type:
    | typeof OPEN_MODAL_REMAINING_BALANCE_PAYMENT_CREATED
    | typeof CLOSE_MODAL_REMAINING_BALANCE_PAYMENT_CREATED;
};

const isVisible = (state = false, action: PaymentCreatedModalAction) => {
  switch (action.type) {
    case OPEN_MODAL_REMAINING_BALANCE_PAYMENT_CREATED:
      return true;
    case CLOSE_MODAL_REMAINING_BALANCE_PAYMENT_CREATED:
      return false;
    default:
      return state;
  }
};

export const remainingBalancePaymentCreated = combineReducers({ isVisible });
