import { SupportedLocalesKeys } from 'utils/translations';
import get from 'lodash/get';
import {
  canChangePaymentMethod,
  findLegacyRecurringOffer,
  findRecurringOffer,
  findSingleOffer,
  gatewayOnly,
  getCurrentCurrency,
  greaterThan10kUSD,
  getHiddenFieldIds,
  getItemsIds,
  getLocalCurrency,
  getOrderOffer,
  getPaymentOfferFromPayment,
  getRecipient,
  getRecipientId,
  getSectionsByEntities,
  getTaxes,
  isCAP,
  isEducationClient,
  isLegacyInstallment,
  isPending,
  isRecipientClientPrepopulated,
  isLegacyRecurring,
  isLegacySubscription,
  isDirectDebitOfferSelected,
  supportsOnlyRecurringOffers,
  SectionName,
  getPayment,
  isIndiaIpAddress,
  isLoggedIn,
} from 'selectors';
import {
  ASTROPAY_PAYMENT_OPTIONS,
  BANK_TRANSFER,
  CHINA_COUNTRY_CODE,
  DBIN_PAYMENT_OPTION,
  IN_COUNTRY_CODE,
  INTEGRATED_CLIENTS,
  OFFER_ENTITY,
  OFFER_RELATIONSHIP_STUDENT,
  RECIPIENT_ENTITY,
  SENDER_COUNTRY_FIELD,
  SENDER_ENTITY,
  STEP_CONFIRMATION,
  STEP_CARD_SURCHARGE,
  STEP_RECURRING_CARD_PAYMENT,
  STEP_RECURRING_DIRECT_DEBIT_PAYMENT,
  STEP_OFFERS,
  STEP_PAYMENT,
  STEP_RECIPIENT,
  STEP_SENDER,
  STUDENT_FIRST_NAME,
  STUDENT_ID,
  STUDENT_LAST_NAME,
  STUDENT_MIDDLE_NAME,
  PAYEX_PLATFORM,
  MOBILE_PLATFORM,
  INVOICING_PLATFORM,
  WIDGET_PLATFORM,
  AGENTS_PLATFORM,
  GATEWAY_PLATFORM,
  EMBEDDED_PLATFORM,
  VIETNAM_COUNTRY_CODE,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN,
  IS_RECURRING_FIELD,
  IS_LEGACY_RECURRING_FIELD,
  STEP_PAY,
  SCHEDULE_FIELD,
  INTERVAL_FIELD,
} from 'constants/index';
import { clientValidate } from 'utils/clientValidate/clientValidate';
import timeout from 'utils/timeout';
import type { RootState } from 'reducers/types';
import type {
  FieldValue,
  Offer,
  RecurringOfferWithIds,
  SelectedOffer,
} from 'models';
import type { PayexGetState } from 'store/configureStore';
import { COUPON_CODE } from 'constants/coupons';

const NO_VALUE = '';

export const amountTo = (state: RootState) => {
  const {
    entities: {
      recipient: { currency },
    },
  } = state;

  return { value: getTotalAmount(state), currency };
};

export const getSelectedOfferId = (state: RootState) => {
  const { ui: { fieldValues: { offer: id } = {} } = {} } = state;

  return id as string;
};

export const isSelectedOfferRecurring = (state: RootState) => {
  return getFieldValue(state, IS_RECURRING_FIELD);
};

export const getSelectedOffer = (state: RootState): SelectedOffer => {
  const NO_OFFER = {} as SelectedOffer;
  const { entities: { prices = {} } = {} } = state;

  const id = getSelectedOfferId(state);

  if (!id) return NO_OFFER;

  const isLegacyOfferRecurring = getFieldValue(
    state,
    IS_LEGACY_RECURRING_FIELD,
  );

  const isRecurring = isSelectedOfferRecurring(state);
  const offer = isLegacyOfferRecurring
    ? findLegacyRecurringOffer(state, id)
    : isRecurring
    ? findRecurringOffer(state, id)
    : findSingleOffer(state, id);

  if (!offer) return NO_OFFER;

  const price = prices?.[offer?.price as string];

  return { ...offer, price };
};

export const getSelectedRecurringOffer = (
  state: RootState,
): RecurringOfferWithIds => {
  const NO_OFFER = {} as RecurringOfferWithIds;

  const id = getSelectedOfferId(state);

  if (!id) return NO_OFFER;

  const isRecurring = isSelectedOfferRecurring(state);
  const offer = isRecurring && findRecurringOffer(state, id);

  if (!offer) return NO_OFFER;

  return offer;
};

export const amountFrom = (state: RootState) => {
  const offer = getSelectedOffer(state);
  const { price: { value, currency } = {} } = offer;

  return { value, currency };
};

export const getAmountFromWithTaxes = (state: RootState) => {
  const amount = amountFrom(state);
  const taxes = getTaxes(state);

  if (!taxes.length) {
    return amount;
  }

  const taxesAmount = taxes.reduce((total, tax) => {
    return total + tax.amount;
  }, 0);

  const amountValue = amount.value || 0;

  return { value: amountValue + taxesAmount, currency: amount.currency };
};

export const hasOnlyGSTTaxes = (state: RootState) => {
  const taxes = getTaxes(state);
  const taxIds = taxes.map(tax => {
    return tax.id;
  });

  if (
    taxIds.includes('gst_tax') &&
    !taxIds.includes('tcs_tax') &&
    !taxIds.includes('indian_tax')
  ) {
    return true;
  }

  return false;
};

export const amountFromWithoutFees = (state: RootState) => {
  const offer = getSelectedOffer(state);
  const {
    price: { value, currency },
    fees,
  } = offer;

  return { value: value - fees, currency };
};

export const isFetching = (
  state: RootState,
  entityOrEntities: string | string[],
) => {
  const { isFetching } = state;
  const entities = Array.isArray(entityOrEntities)
    ? entityOrEntities
    : [entityOrEntities];

  return entities.some(
    (entity: string) => !!isFetching[entity as keyof typeof isFetching],
  );
};

export const isFetched = (state: RootState, entity: string) => {
  const test = isFetching(state, entity);
  return !test;
};

export const untilIsFetched = async (
  getState: PayexGetState,
  entity: string,
  retries = 20,
  retryInterval = 250,
): Promise<boolean> => {
  const fetched = isFetched(getState(), entity);

  if (!fetched && retries > 0) {
    await timeout(retryInterval);
    return untilIsFetched(getState, entity, retries - 1);
  } else if (retries === 0) {
    return Promise.reject(new Error('Timeout'));
  }

  return Promise.resolve(true);
};

export const getCurrentCountry = (state: RootState) => {
  const NO_COUNTRY = {};
  const code = getCurrentCountryCode(state);
  const { entities: { countries: { byId = {} } = {} } = {} } = state;

  return byId[code as string] || NO_COUNTRY;
};

export const isPayingFromIndia = (state: RootState) => {
  const { code } = getCurrentCountry(state);

  if (!code) {
    return false;
  }

  return code.toUpperCase() === IN_COUNTRY_CODE;
};

export const getCurrentLocale = (state: RootState) => {
  const {
    ui: { locale = '' },
  } = state;

  return locale as SupportedLocalesKeys;
};

export const getCurrentStepIndex = (state: RootState) => {
  const {
    ui: { currentStepIndex },
  } = state;

  return currentStepIndex;
};

export const getSelectedOfferFees = (state: RootState) => {
  const selectedOffer = getSelectedOffer(state);
  const { fees, price: { currency } = {} } = selectedOffer;

  return { value: fees, currency };
};

export const isSelectedOfferBankTransfer = (state: RootState) => {
  const selectedOffer = getSelectedOffer(state);

  return selectedOffer.paymentMethod === BANK_TRANSFER;
};

export const getCreatePaymentError = (state: RootState) => {
  const { ui: { createPaymentFailure = null } = {} } = state;

  return createPaymentFailure;
};

export const getFieldValues = (state: RootState) => {
  const { ui: { fieldValues = {} } = {} } = state;

  return fieldValues;
};

export const getNumberOfInstallments = (state: RootState): number => {
  const { [INTERVAL_FIELD]: numberOfInstallments } = getFieldValues(state);

  return numberOfInstallments ? (numberOfInstallments as number) : 0;
};

export const getFieldValue = (state: RootState, fieldName: string) => {
  const { ui: { fieldValues = {} } = {} } = state;

  return fieldValues[fieldName] ?? NO_VALUE;
};

export const getStudentID = (state: RootState) => {
  return getFieldValue(state, STUDENT_ID);
};

export const getFieldIdsAndValues = (
  state: RootState,
  fieldNames: string[],
) => {
  const { ui: { fieldValues = {} } = {} } = state;

  return fieldNames.reduce((acc, fieldName) => {
    acc[fieldName] = fieldValues[fieldName] ?? NO_VALUE;
    return acc;
  }, {} as Record<string, FieldValue>);
};

export const getStudentFullName = (state: RootState) => {
  const studentFirstName = getFieldValue(state, STUDENT_FIRST_NAME);
  const studentMiddleName = getFieldValue(state, STUDENT_MIDDLE_NAME);
  const studentLastName = getFieldValue(state, STUDENT_LAST_NAME);

  return [studentFirstName, studentMiddleName, studentLastName]
    .filter(Boolean)
    .join(' ');
};

export const isStepVisible = (
  state: RootState,
  entityOrEntities: SectionName | SectionName[],
) => {
  const isIntegrated = isEmbedded(state) || hasGatewayOrigin(state);
  if (!isIntegrated) return true;

  const entities = Array.isArray(entityOrEntities)
    ? entityOrEntities
    : new Array(entityOrEntities);
  const sections = getSectionsByEntities(state, entities);
  return sections.some(section => !section.prefilled);
};

export const isChangePaymentMethodVisible = (state: RootState) =>
  !shouldHideUserManagement(state) &&
  isPending(state) &&
  !isIntegratedExperience(state) &&
  !isIntegratedClient(state) &&
  canChangePaymentMethod(state) &&
  !supportsOnlyRecurringOffers(state) &&
  !isRecipientClientPrepopulated(state);

const isIntegratedExperience = (state: RootState) =>
  isEmbedded(state) ||
  hasGatewayOrigin(state) ||
  get(gatewayOnly(state), 'enabled');

const isIntegratedClient = (state: RootState) =>
  INTEGRATED_CLIENTS.includes(getRecipientId(state));

export const isReadOnly = (state: RootState, name: string) => {
  const { ui: { readOnlyFields = [] } = {} } = state;

  return readOnlyFields.includes(name);
};

export const isApiReadOnly = (state: RootState, name: string) => {
  return apiReadOnlyFields(state).includes(name);
};

const apiReadOnlyFields = (state: RootState) => {
  const { ui: { apiReadOnlyFields = [] } = {} } = state;

  return apiReadOnlyFields;
};

export const isPrefilled = (state: RootState, name: string) => {
  const { ui: { prefilledFields = [] } = {} } = state;

  return prefilledFields.includes(name);
};

export const getTotalAmount = (state: RootState) => {
  const itemsIds = getItemsIds(state);
  const itemsAmounts = itemsIds.map(
    item => getFieldValue(state, item) as number,
  );

  return itemsAmounts.reduce((acc, amount) => {
    acc += Number(amount);
    return acc;
  }, 0);
};

export const getNotifications = (state: RootState) => {
  const {
    ui: { notifications },
  } = state;

  return notifications;
};

export const isEmbedded = (state: RootState) => {
  const { ui: { embedded = false } = {} } = state;

  return embedded;
};

export const getTheme = (state: RootState) => {
  const {
    ui: { theme },
  } = state;

  return theme;
};

export const chatEnabled = (state: RootState) => {
  const theme = getTheme(state);
  const { chat } = theme;

  return chat;
};

export const hasGatewayOrigin = (state: RootState) => {
  const {
    ui: { gatewayOrigin },
  } = state;

  return gatewayOrigin;
};

export const getGatewaySource = (state: RootState) => {
  const {
    ui: { gatewaySource },
  } = state;

  return gatewaySource;
};

export const hasFooter = (state: RootState) => {
  const { footer = true } = getTheme(state);

  return footer;
};

export const shouldShowOfflineInstructions = (state: RootState) => {
  const {
    ui: { showOfflineInstructions },
  } = state;

  return showOfflineInstructions;
};

export const docCollectorFinished = (state: RootState) => {
  const {
    ui: { docCollectorFinished },
  } = state;

  return docCollectorFinished;
};

export const prefilledRejectOrConfirm = (state: RootState) => {
  const {
    ui: { prefilledRejectOrConfirm },
  } = state;

  return prefilledRejectOrConfirm;
};

export const shouldReceiveSMSNotification = (state: RootState) => {
  const {
    ui: { receiveSMSNotification },
  } = state;

  return receiveSMSNotification;
};

export const shouldReceiveWhatsappNotification = (state: RootState) => {
  const {
    ui: { receiveWhatsappNotification },
  } = state;

  return receiveWhatsappNotification;
};

export const hasDuplicatedPaymentError = (state: RootState) => {
  const {
    ui: { duplicatedPayment },
  } = state;

  return duplicatedPayment;
};

export const hasDuplicatedPANNumberError = (state: RootState) => {
  const {
    ui: { duplicatedPANNumber },
  } = state;

  return duplicatedPANNumber;
};

export const hasTaxServiceUnavailableError = (state: RootState) => {
  const {
    ui: { taxServiceUnavailable },
  } = state;

  return taxServiceUnavailable;
};

export const getLastPaymentFromSameRecipient = (state: RootState) => {
  const {
    ui: { lastPaymentFromSameRecipient },
  } = state;

  return lastPaymentFromSameRecipient;
};

export const shouldHideUserManagement = (state: RootState) => {
  const {
    ui: { hideUserManagement },
  } = state;

  return !!hideUserManagement;
};

export const isRefundKYCProcessCompleted = (state: RootState) => {
  const {
    ui: { refundKYCProcessCompleted },
  } = state;

  return refundKYCProcessCompleted;
};

export const isTokenCorrect = (state: RootState) => {
  const {
    ui: { validRefundKYCToken },
  } = state;

  return validRefundKYCToken;
};

export const shouldHideCancelPaymentOption = (state: RootState) => {
  const {
    ui: { hideCancelPaymentOption = false },
  } = state;

  return hideCancelPaymentOption || shouldHideUserManagement(state);
};

export const shouldAutoLoadOnlineScript = (state: RootState) => {
  const {
    ui: { autoLoadOnlineScript = true },
  } = state;

  return autoLoadOnlineScript;
};

export const shouldShowPrefillForm = (state: RootState) => {
  const isEnabledInIntegration = isCAP(state) || !hasGatewayOrigin(state);

  return (
    isEnabledInIntegration &&
    !!getLastPaymentFromSameRecipient(state) &&
    !prefilledRejectOrConfirm(state)
  );
};

export const getNonPrefillableFields = (state: RootState) => {
  const hiddenFields = getHiddenFieldIds(state);

  return apiReadOnlyFields(state).concat(hiddenFields);
};

export const getRedirectToReferrer = (state: RootState) => {
  const {
    ui: { redirectToReferrer = true },
  } = state;

  return redirectToReferrer;
};

export const isPaymentProcess = (state: RootState) => {
  const {
    ui: { isPaymentProcess },
  } = state;

  return isPaymentProcess;
};

export const isRepeatingPayment = (state: RootState) => {
  const {
    ui: { isRepeatingPayment },
  } = state;

  return isRepeatingPayment;
};

export const attemptedInvalidSMSNumber = (state: RootState) => {
  const {
    ui: { attemptedInvalidSMSNumber },
  } = state;

  return attemptedInvalidSMSNumber;
};

const isValidUrl = (website: unknown) => {
  if (!website) {
    return false;
  }
  return (
    clientValidate.validate({ website }, { website: { url: true } }) == null
  );
};

export const isPaymentProcessWithNobookingCta = (state: RootState) => {
  const {
    entities: {
      order: { metadata: { returnNobookingCta } = {} },
    },
  } = state;

  return isValidUrl(returnNobookingCta) && isPaymentProcess(state);
};

export const showAddressInFooter = (state: RootState) => {
  const { address, address2 } = getRecipient(state);

  const hasAddress = address || address2;

  return (
    (hasAddress || isPaymentProcessWithNobookingCta(state)) &&
    !isEmbedded(state)
  );
};

export const isUserCreated = (state: RootState) => {
  const {
    ui: { userExists },
  } = state;

  return userExists;
};

export const shouldShowChineseBanner = (state: RootState) => {
  if (!isPayingFromChina(state)) {
    return false;
  }
  if (!isEducationClient(state)) {
    return false;
  }

  return greaterThan10kUSD(state);
};

export const isPayingFromChina = (state: RootState) => {
  const countryCode = getCurrentCountryCode(state);

  return countryCode === CHINA_COUNTRY_CODE;
};

export const isPayingFromVietnam = (state: RootState) => {
  const countryCode = getCurrentCountryCode(state);

  return countryCode === VIETNAM_COUNTRY_CODE;
};

export const getCurrentCountryCode = (state: RootState) =>
  getFieldValue(state, SENDER_COUNTRY_FIELD);

export const isTrackingPage = (state: RootState) => state.ui.isTrackingPage;

const skipCompletedStepsInEmbedded = (state: RootState) => {
  const { skipCompletedSteps = false } = getTheme(state);
  return skipCompletedSteps && isEmbedded(state);
};

export const isRecurringOfferSelected = (state: RootState) => {
  return Boolean(getFieldValue(state, IS_RECURRING_FIELD));
};

export const getPaymentStepsForWizard = (state: RootState) => {
  const noop: string[] = [];
  const hasRecipient = isStepVisible(state, RECIPIENT_ENTITY);
  const showConfirmationStep = !isEmbedded(state);

  const isSenderStepVisible = skipCompletedStepsInEmbedded(state)
    ? isStepVisible(state, [SENDER_ENTITY, OFFER_ENTITY])
    : true;

  const showCardSurchargeStep = isCardSurchargeOrder(state);
  const isRecurring = isRecurringOfferSelected(state);
  const isAutomated = isRecurring && getSelectedRecurringOffer(state).recurring;

  const shouldShowTokenizationStep = isRecurring && isAutomated;

  return [
    STEP_PAYMENT,
    STEP_OFFERS,
    ...(isSenderStepVisible ? [STEP_SENDER] : noop),
    ...(hasRecipient ? [STEP_RECIPIENT] : noop),
    ...(showConfirmationStep ? [STEP_CONFIRMATION] : noop),
    ...(showCardSurchargeStep ? [STEP_CARD_SURCHARGE] : noop),
    ...(shouldShowTokenizationStep
      ? isDirectDebitOfferSelected(state)
        ? [STEP_RECURRING_DIRECT_DEBIT_PAYMENT]
        : [STEP_RECURRING_CARD_PAYMENT]
      : noop),
  ];
};

export const getPaymentSteps = (state: RootState) => {
  const steps = getPaymentStepsForWizard(state);

  if (
    steps.includes(STEP_CARD_SURCHARGE) ||
    steps.includes(STEP_RECURRING_CARD_PAYMENT) ||
    steps.includes(STEP_RECURRING_DIRECT_DEBIT_PAYMENT)
  ) {
    return [
      ...steps.filter(
        step =>
          step !== STEP_CARD_SURCHARGE &&
          step !== STEP_RECURRING_CARD_PAYMENT &&
          step !== STEP_RECURRING_DIRECT_DEBIT_PAYMENT,
      ),
      STEP_PAY,
    ];
  }

  return steps;
};

export const isCardSurchargeOrder = (state: RootState) => {
  const offer = getOrderOffer(state);
  return isCardSurchargeFlow(offer as Offer);
};

export const isCardSurchargePayment = (state: RootState) => {
  const offer = getPaymentOfferFromPayment(state);
  return isCardSurchargeFlow(offer);
};

export const isCardSurchargeFlow = (offer?: Offer) => {
  return !!offer?.surcharge;
};

export const hasForeignCardFee = (state: RootState): boolean => {
  const payment = getPayment(state);
  return !!payment.extraFees?.find(e => e.subcategory === 'foreign_card');
};

export const isImpersonatingUser = (state: RootState) => {
  const {
    ui: { isImpersonatingUser = false },
  } = state;

  return isImpersonatingUser;
};

export const getSelectedOfferExchangeRate = (state: RootState) => {
  const { exchangeRate } = getSelectedOffer(state);
  const currencyFrom = getLocalCurrency(state);
  const currencyTo = getCurrentCurrency(state);

  return { currencyFrom, currencyTo, value: exchangeRate };
};

export const isChatVisible = (state: RootState) => state.ui.isChatVisible;

export const isDocumentsFetchFailure = (state: RootState) => {
  const {
    ui: { isDocumentsFetchFailure = false },
  } = state;

  return isDocumentsFetchFailure;
};

export const getMaxAmount = (state: RootState) => {
  const {
    ui: { maxAmount },
  } = state;

  return maxAmount;
};

export const isOfferDBIN = (offer: Offer) => {
  const { paymentOption: selectedPaymentOption } = offer;

  return DBIN_PAYMENT_OPTION === selectedPaymentOption;
};

export const isOfferAstropay = (state: RootState) => {
  const { paymentOption } = getSelectedOffer(state);

  return ASTROPAY_PAYMENT_OPTIONS.includes(paymentOption);
};

export const isSelectedOfferLegacyRecurring = (state: RootState) => {
  const selected = getSelectedOffer(state);

  return selected && isLegacyRecurring(selected);
};

export const isSelectedOfferLegacyInstallment = (state: RootState) => {
  const selectedOffer = getSelectedOffer(state);
  return selectedOffer && isLegacyInstallment(selectedOffer);
};

export const isSelectedOfferLegacySubscription = (state: RootState) => {
  const selectedOffer = getSelectedOffer(state);
  return selectedOffer && isLegacySubscription(selectedOffer);
};

export const getRecurringInterval = (state: RootState) => {
  const { ui: { fieldValues: { interval } = {} } = {} } = state;

  return Number.parseInt(interval as string);
};

export const isStudentPaying = (state: RootState) => {
  const {
    ui: { fieldValues: { offer_relationship: offerRelationship } = {} } = {},
  } = state;

  return offerRelationship === OFFER_RELATIONSHIP_STUDENT;
};

export const sourceOfFundsIndia = (state: RootState) => {
  const {
    ui: {
      fieldValues: { offer_source_of_funds_india: offerSourceOfFunds } = {},
    } = {},
  } = state;
  return offerSourceOfFunds;
};

export const getPlatform = (state: RootState) => {
  const provider = getFieldValue(state, 'provider');

  if (provider === MOBILE_PLATFORM) return MOBILE_PLATFORM;
  if (provider === INVOICING_PLATFORM) return INVOICING_PLATFORM;
  if (provider === WIDGET_PLATFORM) return WIDGET_PLATFORM;
  if (provider === AGENTS_PLATFORM) return AGENTS_PLATFORM;
  if (isEmbedded(state)) return EMBEDDED_PLATFORM;
  if (hasGatewayOrigin(state)) return GATEWAY_PLATFORM;

  return PAYEX_PLATFORM;
};

export const getSchedule = (state: RootState) => {
  return getFieldValue(state, SCHEDULE_FIELD);
};

export const paymentsFromIndia = (state: RootState) => {
  const {
    ui: { paymentsFromIndia },
  } = state;

  return paymentsFromIndia;
};

export const getCouponCode = (state: RootState) => {
  return getFieldValue(state, COUPON_CODE);
};

export const shouldShowCoupon = (state: RootState) => {
  return (
    (isPayingFromChina(state) || isPayingFromIndia(state)) &&
    !isRecurringOfferSelected(state) &&
    !isSelectedOfferLegacyRecurring(state)
  );
};

export const shouldShowRedeemCashback = (state: RootState) => {
  return (
    isLoggedIn(state) &&
    (isPayingFromIndia(state) || isIndiaIpAddress(state)) &&
    !isRecurringOfferSelected(state) &&
    !isSelectedOfferLegacyRecurring(state)
  );
};

export const shouldShowWhatsappSubscriptions = (state: RootState) => {
  return isPayingFromIndia(state) || isIndiaIpAddress(state);
};
