import React from 'react';
import { TrustpilotLogo } from './Trustpilot/TrustpilotLogo';
import { useTranslations } from 'utils';
import { Carousel } from 'components/ui/Carousel/Carousel';

import './Testimony.scss';

function Testimony() {
  const i18n = useTranslations();
  const testimonies = [
    {
      id: 'testimony1',
      fullName: i18n.t('CAP.testimony1.fullName'),
      quote: i18n.t('CAP.testimony1.quote'),
      career: i18n.t('CAP.testimony1.career'),
    },
    {
      id: 'testimony2',
      fullName: i18n.t('CAP.testimony2.fullName'),
      quote: i18n.t('CAP.testimony2.quote'),
      career: i18n.t('CAP.testimony2.career'),
    },
    {
      id: 'testimony3',
      fullName: i18n.t('CAP.testimony3.fullName'),
      quote: i18n.t('CAP.testimony3.quote'),
      career: i18n.t('CAP.testimony3.career'),
    },
  ];

  return (
    <Carousel className="TestimonyCarousel">
      {testimonies.map(testimony => {
        return (
          <section
            key={testimony.id}
            data-testid={testimony.id}
            className="TestimonyContainer"
          >
            <div className="TestimonyProviderBrand">
              <TrustpilotLogo />
            </div>
            <div className="TestimonyQuote">
              {testimony.quote}
              <div className="TestimonyFullNameCareer">
                <span className="TestimonyFullName">
                  ~ {testimony.fullName}
                </span>
                <span className="TestimonyCareer">{testimony.career}</span>
              </div>
            </div>
          </section>
        );
      })}
    </Carousel>
  );
}

export { Testimony };
