import {
  CANCEL_RECURRING_RECEIVABLE_FAILURE,
  CANCEL_RECURRING_RECEIVABLE_REQUEST,
  CANCEL_RECURRING_RECEIVABLE_SUCCESS,
  CREATE_RECURRING_RECEIVABLE_FAILURE,
  CREATE_RECURRING_RECEIVABLE_REQUEST,
  CREATE_RECURRING_RECEIVABLE_SUCCESS,
  FETCH_USER_RECURRING_RECEIVABLES_FAILURE,
  FETCH_USER_RECURRING_RECEIVABLES_REQUEST,
  FETCH_USER_RECURRING_RECEIVABLES_SUCCESS,
  FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE,
  FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_REQUEST,
  FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS,
  IS_FETCHING_RECURRING_RECEIVABLE_CANCELLATION_FLAG,
  IS_FETCHING_USER_RECURRING_RECEIVABLES_FLAG,
  PERMANENT_COOKIE_NAME,
} from 'constants/index';
import {
  isFetching,
  getCurrentLocale,
  getOrder,
  getNumberOfInstallments,
  getSelectedRecurringOffer, hasGatewayOrigin,
} from 'selectors';
import { cookies } from 'utils/cookies/cookies';
import type { PayexThunkAction } from 'store/configureStore';
import type { CardTokenParams } from 'domain/recurringReceivables/repository/types';

export const fetchUserRecurringReceivables =
  (params = {}, silently = false): PayexThunkAction =>
  async (dispatch, getState, { recurringReceivables }) => {
    try {
      const state = getState();
      const isFetchingRecurringReceivables = isFetching(
        state,
        IS_FETCHING_USER_RECURRING_RECEIVABLES_FLAG,
      );

      if (isFetchingRecurringReceivables) return null;

      dispatch({
        type: silently
          ? FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_REQUEST
          : FETCH_USER_RECURRING_RECEIVABLES_REQUEST,
      });

      const response = await recurringReceivables.fetch(params);

      return dispatch({
        type: silently
          ? FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS
          : FETCH_USER_RECURRING_RECEIVABLES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      return dispatch({
        type: silently
          ? FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE
          : FETCH_USER_RECURRING_RECEIVABLES_FAILURE,
        payload: error,
      });
    }
  };

export const fetchUserRecurringReceivablesIfNeeded =
  (params = {}): PayexThunkAction =>
  async (dispatch, getState) => {
    const { entities: { recurringReceivables = [] } = {} } = getState();

    if (recurringReceivables.length > 0) {
      return Promise.resolve();
    }

    return dispatch(fetchUserRecurringReceivables(params));
  };

export const cancelRecurringReceivable =
  (id: string): PayexThunkAction =>
  async (dispatch, getState, { recurringReceivables }) => {
    try {
      const state = getState();
      const isCancellingRecurringReceivable = isFetching(
        state,
        IS_FETCHING_RECURRING_RECEIVABLE_CANCELLATION_FLAG,
      );

      if (isCancellingRecurringReceivable) return;

      dispatch({ type: CANCEL_RECURRING_RECEIVABLE_REQUEST });

      await recurringReceivables.cancel(id);

      return dispatch({
        type: CANCEL_RECURRING_RECEIVABLE_SUCCESS,
        payload: { id },
      });
    } catch (error) {
      return dispatch({
        type: CANCEL_RECURRING_RECEIVABLE_FAILURE,
        payload: error,
      });
    }
  };

export const createRecurringReceivable =
  (cardTokenParams?: CardTokenParams): PayexThunkAction =>
  async (dispatch, getState, { recurringReceivables }) => {
    try {
      const state = getState();
      const fingerprint = cookies.read(PERMANENT_COOKIE_NAME);
      const locale = getCurrentLocale(state);
      const { id: orderId, token: orderToken } = getOrder(state);
      const numberOfInstallments = getNumberOfInstallments(state);
      const isNonAutomated = !getSelectedRecurringOffer(state).recurring;
      const isFromGateway = hasGatewayOrigin(state);

      dispatch({ type: CREATE_RECURRING_RECEIVABLE_REQUEST });
      await recurringReceivables.create({
        ...(cardTokenParams || {}),
        fingerprint,
        locale,
        numberOfInstallments,
        orderId,
        orderToken,
        nonAutomated: isNonAutomated,
        isFromGateway,
      });

      return dispatch({ type: CREATE_RECURRING_RECEIVABLE_SUCCESS });
    } catch (error) {
      dispatch({
        type: CREATE_RECURRING_RECEIVABLE_FAILURE,
        payload: error,
      });

      throw error;
    }
  };
