import React, { ElementType, HTMLProps, Ref } from 'react';
import classNames from 'classnames';

type VisuallyHiddenProps = HTMLProps<HTMLElement> & {
  as?: ElementType;
  className?: string;
  forwardRef?: Ref<HTMLElement>;
};

function VisuallyHidden({
  as: Comp = 'div',
  className,
  forwardRef: ref,
  ...rest
}: VisuallyHiddenProps) {
  return (
    <Comp className={classNames('sr-only', className)} {...rest} ref={ref} />
  );
}

export { VisuallyHidden };
