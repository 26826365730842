import { RootState } from 'reducers/types';

const NO_NOTIF = {};

export const getSmsNotification = (state: RootState, id: string) => {
  const {
    entities: {
      smsNotifications: { byId },
    },
  } = state;

  return byId[id] || NO_NOTIF;
};
