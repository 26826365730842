export const STEP_PAYMENT = 'payment';
export const STEP_OFFERS = 'offers';
export const STEP_SENDER = 'sender';
export const STEP_RECIPIENT = 'recipient';
export const STEP_CONFIRMATION = 'confirmation';
export const STEP_PAY = 'pay';
export const STEP_CARD_SURCHARGE = 'card_surcharge';
export const STEP_RECURRING_CARD_PAYMENT = 'pay_recurring';
export const STEP_RECURRING_DIRECT_DEBIT_PAYMENT = 'pay_recurring_direct_debit';
export const TRACK_PAYMENT = 'track';
export enum PaymentSteps {
  PAYMENT = 'payment',
  OFFERS = 'offers',
  SENDER = 'sender',
  RECIPIENT = 'recipient',
  CARD_SURCHARGE = 'card_surcharge',
  CONFIRMATION = 'confirmation',
  RECURRING_CARD_PAYMENT = 'pay_recurring',
  RECURRING_DIRECT_DEBIT_PAYMENT = 'pay_recurring_direct_debit',
}
