import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Heading } from 'components/Heading/Heading';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { arrayKey } from 'utils/arrayKey/arrayKey';
import debounce from 'lodash/debounce';
import {
  getCurrentCurrency,
  getRecipient,
  getItems,
  getItemById,
  getFieldValue,
  getPaymentItemError,
} from 'selectors';
import { PAYMENT_ITEM } from 'constants/index';
import { setFieldValue } from 'actions';
import {
  MoneyInput,
  MoneyInputProps,
} from 'components/FieldList/Field/MoneyInputField/MoneyInput';
import { Currency, Error, PaymentItem, Recipient } from 'models';
import { useTranslations } from 'utils';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import type { RootState } from 'reducers/types';

const WAIT_TIME = 250;

type PropsFromRedux = {
  currency: Currency;
  selectedItem?: PaymentItem;
  items: PaymentItem[];
  paymentItemError?: Error;
  recipient: Recipient;
  setFieldValue: (name: string, value?: number | string | null) => void;
};

type PaymentItemSelectorProps = {
  amountHint?: string;
  amountError?: string;
  onAmountBlur: () => void;
  onAmountChange: () => void;
  onPaymentItemBlur: () => void;
  onPaymentItemChange: (item: string, value?: number | string | null) => void;
};

const PaymentItemSelectorComponent: React.FunctionComponent<
  PaymentItemSelectorProps & PropsFromRedux
> = ({
  amountHint,
  amountError,
  currency,
  paymentItemError,
  selectedItem,
  setFieldValue,
  items,
  onAmountBlur,
  onAmountChange,
  onPaymentItemBlur,
  onPaymentItemChange,
  recipient,
}) => {
  const i18n = useTranslations();
  const itemOptions = items.map(item => ({
    label: item.label,
    value: item.id,
  }));

  const amountProps = (): Partial<MoneyInputProps> => {
    return {
      currencySymbol: currency.symbol,
      decimalMark: currency.decimalMark,
      label: i18n.t('paymentInformation.itemSelection.amount.label') as string,
      maxLength: 12,
      subunitToUnit: currency.subunitToUnit,
      symbolFirst: currency.symbolFirst,
      thousandsSeparator: currency.thousandsSeparator,
      onBlur: onAmountBlur,
      onChange: debounce(onAmountChange, WAIT_TIME),
    };
  };

  const getItemDefaultValue = () => {
    const defaultValue = selectedItem?.defaultValue || 0;
    return defaultValue as number;
  };

  const handlePaymentItemChange = (name: string, value: string) => {
    if (selectedItem) setFieldValue(selectedItem.id, 0);
    onPaymentItemChange(name, value);
  };

  useEffect(() => {
    if (
      items.length === 1 &&
      !Object.prototype.hasOwnProperty.call(selectedItem, 'id')
    ) {
      onPaymentItemChange(PAYMENT_ITEM, items[0].id);
    }
  }, [items, onPaymentItemChange, selectedItem]);

  return (
    <>
      <Heading
        as="h2"
        size="medium"
        className="PaymentInformation-heading"
        id="paymentInformation-itemSelection-msg"
      >
        {i18n.t('paymentInformation.itemSelection.title')}
      </Heading>
      <div
        className="PaymentInformation-input"
        data-testid="paymentItemSelector"
      >
        <Autocomplete
          error={paymentItemError?.message}
          name={PAYMENT_ITEM}
          required
          onBlur={onPaymentItemBlur}
          onChange={value => {
            handlePaymentItemChange(PAYMENT_ITEM, value);
          }}
          options={itemOptions}
          data-testid={PAYMENT_ITEM}
          selected={
            selectedItem?.id || (items.length === 1 ? items[0].id : null)
          }
          key={arrayKey(itemOptions)}
        >
          <Autocomplete.Input
            data-testid={PAYMENT_ITEM}
            placeholder={i18n.t(
              'paymentInformation.itemSelection.paymentType.label',
            )}
          />
          <Autocomplete.Options />
          {paymentItemError?.message && (
            <Autocomplete.Error className="FormGroup-feedback">
              <VisuallyHidden as="span">{selectedItem?.label}</VisuallyHidden>
              {paymentItemError?.message}
            </Autocomplete.Error>
          )}
        </Autocomplete>
      </div>
      <Heading
        as="h2"
        size="medium"
        className="PaymentInformation-heading"
        id="paymentInformation-amount-msg"
      >
        {i18n.t('paymentInformation.amount.title', {
          recipient: recipient.name as string,
        })}
      </Heading>
      <div className="PaymentInformation-input">
        <MoneyInput
          data-testid="paymentItemAmount"
          key={selectedItem?.id}
          name={selectedItem?.id || 'undefined_item'}
          readOnly={selectedItem?.readOnly || selectedItem?.apiReadOnly}
          {...amountProps()}
          error={amountError}
          help={amountHint}
          aria-describedby="paymentInformation-amount-msg"
          value={getItemDefaultValue()}
          required
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currency: getCurrentCurrency(state),
  selectedItem: getItemById(
    state,
    getFieldValue(state, PAYMENT_ITEM) as string,
  ),
  items: getItems(state),
  paymentItemError: getPaymentItemError(state),
  recipient: getRecipient(state),
});

const mapDispatchProps = { setFieldValue };

const PaymentItemSelector = connect(
  mapStateToProps,
  mapDispatchProps,
)(PaymentItemSelectorComponent);

export { PaymentItemSelector };
