import { useSelector } from 'react-redux';
import {
  getAccessToken,
  getRefreshToken,
} from 'selectors/entities/authentication/authentication';

export interface AuthStatus {
  isAuthenticated: boolean;
  canAuthenticate: boolean;
}

export const useAuth = (): AuthStatus => {
  const accessToken = useSelector(getAccessToken);
  const hasRefreshToken = useSelector(getRefreshToken);

  return {
    isAuthenticated: !!accessToken,
    canAuthenticate: !!hasRefreshToken,
  };
};
