import React from 'react';
import PropTypes from 'prop-types';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import { useTranslations } from 'utils';

import './IncludedFees.scss';

const IncludedFees = ({ fees }) => {
  const { value = 0, currency } = fees;
  const hasFees = value > 0;
  const i18n = useTranslations();

  return (
    <FormattedAmount
      value={value}
      currency={currency}
      render={({ formattedAmount: fees }) => (
        <small className="IncludedFees-text">
          {hasFees ? i18n.t('includedFees.msg', { fees }) : null}
        </small>
      )}
    />
  );
};

IncludedFees.propTypes = {
  fees: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
};

export { IncludedFees };
