import { combineReducers } from 'redux';
import {
  CLOSE_DUPLICATED_TAX_PAYMENT_MODAL,
  DUPLICATED_PAYMENT_ERROR,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
} from 'constants/index';
import type { Error } from 'models';

type DuplicatedTaxPayment =
  | {
      type:
        | typeof CLOSE_DUPLICATED_TAX_PAYMENT_MODAL
        | typeof UPDATE_ORDER_SUCCESS;
    }
  | {
      type: typeof UPDATE_ORDER_FAILURE;
      payload: {
        entities: {
          errors: Record<string, Error>;
        };
      };
    };

export const isVisible = (state = false, action: DuplicatedTaxPayment) => {
  switch (action.type) {
    case UPDATE_ORDER_SUCCESS:
    case CLOSE_DUPLICATED_TAX_PAYMENT_MODAL:
      return false;
    case UPDATE_ORDER_FAILURE:
      const { entities: { errors = {} } = {} } = action.payload;
      const error = errors.offer_pan_number;

      return error?.type === DUPLICATED_PAYMENT_ERROR;
    default:
      return state;
  }
};

export default combineReducers({ isVisible });
