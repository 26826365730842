import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentLocale } from 'selectors';
import { DefaultLogo } from 'components/DefaultLogo/DefaultLogo';
import { ChineseLogo } from 'components/ChineseLogo/ChineseLogo';
import type { RootState } from 'reducers/types';

const LogoComponent = ({ className, locale, size }) => {
  const showChineseLogo = locale === 'zh-CN';
  const Logo = showChineseLogo ? ChineseLogo : DefaultLogo;
  return <Logo size={size} className={className} />;
};

LogoComponent.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string,
  size: PropTypes.string,
};

const mapStateToProps = (state: RootState) => {
  const locale = getCurrentLocale(state);
  return { locale };
};

const Logo = connect(mapStateToProps)(LogoComponent);

export { Logo };
