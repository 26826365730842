import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { isCAP as isCAPSelector } from 'selectors';
import type { RootState } from 'reducers/types';

type Props = {
  children: ReactNode;
  isCap: boolean;
};

const IsCAPComponent = ({ children = null, isCap = false }: Props) =>
  isCap ? children : null;

const mapStateToProps = (state: RootState) => ({
  isCap: isCAPSelector(state),
});

const IsCAP = connect(mapStateToProps)(IsCAPComponent);

export { IsCAP };
