import React from 'react';
import PropTypes from 'prop-types';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import { RecurringReceivable } from 'containers/RecurringReceivable/RecurringReceivable';
import { Tooltip } from 'components/Tooltip/Tooltip';

import './LegacyRecurringBreakdown.scss';

const LegacyRecurringBreakdown = () => (
  <RecurringReceivable
    render={({ amountTo, currencyTo, ends, i18n, interval, starts }) => (
      <div
        id="payer_information-description"
        className="FlexGrid FlexGrid--fromXSmall-2"
      >
        <div>
          <dt>{i18n.t('recurringReceivableBreakdown.interval')}</dt>
          <dd data-hj-suppress data-testid="interval">
            {interval}
          </dd>
        </div>

        <div>
          <dt>{i18n.t('paymentInformationBreakdown.firstRecurring')}</dt>
          <dd
            data-hj-suppress
            className="recurringReceivableBreakdown-amount"
            data-testid="amount"
          >
            <FormattedAmount
              value={amountTo / interval}
              currency={currencyTo}
            />
            <Tooltip
              defaultMessage={i18n.t('recurringReceivableBreakdown.help')}
              className="recurringReceivableBreakdown-help"
            />
          </dd>
        </div>

        <div>
          <dt>{i18n.t('recurringReceivableBreakdown.first')}</dt>
          <dd data-hj-suppress data-testid="starts">
            {starts}
          </dd>
        </div>

        <div>
          <dt>{i18n.t('recurringReceivableBreakdown.last')}</dt>
          <dd data-hj-suppress data-testid="ends">
            {ends}
          </dd>
        </div>
      </div>
    )}
  />
);

LegacyRecurringBreakdown.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }),
};

export { LegacyRecurringBreakdown };
