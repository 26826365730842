import React, { createContext } from 'react';
import { Actions } from './components/Actions/Actions';
import { Body } from './components/Body/Body';
import { Content } from './components/Content/Content';
import { Description } from './components/Description/Description';
import { Fee } from './components/Fee/Fee';
import { FXInfo } from './components/FXInfo/FXInfo';
import { Label } from './components/Label/Label';
import { LogosList } from './components/LogosList/LogosList';
import { MoreInfo } from './components/MoreInfo/MoreInfo';
import { Name } from './components/Name/Name';
import { PaymentMethodLogo } from './components/PaymentMethodLogo/PaymentMethodLogo';
import { Price } from './components/Price/Price';
import { Recommended } from './components/Recommended/Recommended';
import type { Currency, Fee as FeeType, Price as PriceType } from 'models';
import type { I18n } from 'utils';

import './Offer.scss';

type ContextProps = {
  currency: Currency;
  fee: FeeType;
  i18n: I18n;
  id: string;
  name: string;
  price: PriceType;
};

const OfferContext = createContext<ContextProps>({} as ContextProps);

type OfferProps = ContextProps & {
  children: React.ReactNode;
  className: string;
};

function Offer({
  children,
  className,
  currency,
  fee,
  i18n,
  id,
  name,
  price,
}: OfferProps) {
  return (
    <OfferContext.Provider
      value={{
        currency,
        fee,
        i18n,
        id,
        name,
        price,
      }}
    >
      <li
        data-testid={`option-${id}`}
        className={['PaymentOptions-offer', 'Offer', className]
          .filter(Boolean)
          .join(' ')}
      >
        {children}
      </li>
    </OfferContext.Provider>
  );
}

Offer.displayName = 'Offer';
Offer.Actions = Actions;
Offer.Body = Body;
Offer.Content = Content;
Offer.Description = Description;
Offer.Fee = Fee;
Offer.FXInfo = FXInfo;
Offer.Label = Label;
Offer.LogosList = LogosList;
Offer.MoreInfo = MoreInfo;
Offer.Name = Name;
Offer.PaymentMethodLogo = PaymentMethodLogo;
Offer.Price = Price;
Offer.Recommended = Recommended;

export { Offer, OfferContext };
