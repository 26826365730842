import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { PAY_ROUTE } from 'constants/index';

const RedirectToPay = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    navigate(PAY_ROUTE + search);
  }, [navigate, search]);

  return null;
};

export { RedirectToPay };
