import {
  requiresDocuments,
  docCollectorFinished,
  isCAP,
  getPaymentStatus,
  getRecipientId,
  getPaymentId,
  getPaymentToken,
} from 'selectors';
import {
  PAYMENT_STATUS_DELIVERED,
  RECIPIENTS_WITH_GUARANTEED_TO_DELIVERED_STATUS,
} from '../../../constants';
import { files } from 'services/files/files';
import { RootState } from 'reducers/types';
import { Link } from 'models';

export const getLinkById = (state: RootState, id: string) => {
  const {
    entities: { links },
  } = state;

  return links[id] || null;
};

const getInitiateLink = (state: RootState, links: Record<string, Link>) => {
  const { initiate = null } = links;

  if (requiresDocuments(state) && !docCollectorFinished(state)) return;

  return initiate;
};

const getSmsNotificationLink = (
  state: RootState,
  links: Record<string, Link>,
) => {
  if (!links.sms_notification) return;
  if (isCAP(state)) return;

  return links.sms_notification;
};

const getReceiptLink = (state: RootState) => {
  const receipt = getLinkById(state, 'receipt');

  if (receipt) return receipt;

  const recipientId = getRecipientId(state);
  const isRecipientInGuaranteedToDeliveredExperiment =
    RECIPIENTS_WITH_GUARANTEED_TO_DELIVERED_STATUS.includes(recipientId);
  const paymentStatus = getPaymentStatus(state);
  const isDeliveredPayment = paymentStatus === PAYMENT_STATUS_DELIVERED;

  if (isRecipientInGuaranteedToDeliveredExperiment && isDeliveredPayment) {
    const paymentReference = getPaymentId(state);
    const token = getPaymentToken(state);

    return {
      href: files.getReceiptUrl(paymentReference, token),
      rel: 'receipt',
      method: 'GET',
    };
  }
};

export const getManagementLinks = (state: RootState): Record<string, Link> => {
  const {
    entities: { links },
  } = state;

  const initiate = getInitiateLink(state, links);
  const cancellation = getLinkById(state, 'cancellation');
  const smsNotification = getSmsNotificationLink(state, links);
  const receipt = getReceiptLink(state);
  const guaranteedReceipt = getLinkById(state, 'guaranteed_receipt');

  return {
    ...(initiate && { initiate }),
    ...(cancellation && { cancellation }),
    ...(smsNotification && { sms_notification: smsNotification }),
    ...(receipt && { receipt }),
    ...(guaranteedReceipt && { guaranteedReceipt }),
  };
};
