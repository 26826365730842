import PropTypes from 'prop-types';
import React, { useState, useRef, createRef } from 'react';
import classNames from 'classnames';
import { useOnClickOutside } from '@flywire/react-hooks';

import './ToggleMenu.scss';

const ARROW_UP = 38;
const ARROW_DOWN = 40;
const ENTER = 13;
const ESCAPE = 27;
const TAB = 9;
const NAVIGATION_ARROW_KEYS = [ARROW_UP, ARROW_DOWN];

const ToggleMenu = ({ label, menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const ref = useRef();
  const itemsRef = useRef(menuItems.map(() => createRef()));

  useOnClickOutside(ref, () => {
    setIsOpen(false);
    selectElement(null);
  });

  const selectElement = async element => {
    if (isNaN(element) || element === null) return;

    await setSelectedElement(element);
    itemsRef.current[element].current?.focus();
  };

  const lastElementIndex = menuItems.length - 1;
  const noElementIsSelected = () => selectedElement === null;

  const handleButtonKeyDown = e => {
    if (NAVIGATION_ARROW_KEYS.includes(e.keyCode)) e.preventDefault();
    if (e.keyCode === TAB) setIsOpen(false);

    if (isOpen) return handleListKeyDown(e);

    if (NAVIGATION_ARROW_KEYS.includes(e.keyCode)) toggleMenuItems();
    if (e.keyCode === ARROW_DOWN) selectFirstElement();
    if (e.keyCode === ARROW_UP) selectLastElement();
  };

  const handleListKeyDown = e => {
    const selectedMenuItem = menuItems[selectedElement];

    if (e.keyCode === ESCAPE) toggleMenuItems();
    if (e.keyCode === ENTER && selectedMenuItem) selectedMenuItem.onClick();
    if (e.keyCode === ARROW_DOWN) return selectNextListElement();
    if (e.keyCode === ARROW_UP) return selectPreviousListElement();
  };

  const selectFirstElement = () => {
    const firstElement = 0;
    selectElement(firstElement);
  };

  const selectLastElement = () => {
    selectElement(lastElementIndex);
  };

  const selectNextListElement = () => {
    const isLastElementSelected = () => selectedElement === lastElementIndex;

    if (noElementIsSelected()) return selectFirstElement();
    if (isLastElementSelected()) return selectFirstElement();

    selectElement(selectedElement + 1);
  };

  const selectPreviousListElement = () => {
    const isFirstElementSelected = () => selectedElement === 0;

    if (noElementIsSelected()) return selectLastElement();
    if (isFirstElementSelected()) return selectLastElement();

    selectElement(selectedElement - 1);
  };

  const handleMenuItemClick = onClick => {
    setIsOpen(false);
    onClick();
  };

  const toggleMenuItems = () => {
    setIsOpen(!isOpen);
    selectElement(null);
  };

  const withoutSpaces = s => s.split(' ').join('');

  const renderMenuItem = (option, index) => {
    const { className, label: itemLabel, path, onClick } = option;
    const isActive = selectedElement === index;
    const itemId = `${withoutSpaces(label)}-item-${index}`;

    return (
      <li
        onKeyDown={e => handleButtonKeyDown(e)}
        aria-label={itemLabel}
        aria-selected={isActive}
        role="option"
        ref={itemsRef.current[index]}
        id={itemId}
        className={classNames('ToggleMenu-menuItem', className, {
          'is-active': isActive,
        })}
        data-label={itemLabel}
        key={path}
        onClick={() => handleMenuItemClick(onClick)}
        tabIndex="-1"
      >
        {itemLabel}
      </li>
    );
  };

  const numberOfItems = menuItems.length;
  const statusText = isOpen
    ? `${numberOfItems} options are available, use up and down arrow keys to navigate. Press Enter key to select or Escape key to cancel.`
    : '';
  const selectedOptionId = `${withoutSpaces(label)}-item-${selectedElement}`;

  return (
    <div ref={ref} className={classNames('ToggleMenu', { 'is-open': isOpen })}>
      <button
        aria-expanded={isOpen}
        aria-label={label}
        className="ToggleMenu-label"
        onClick={() => toggleMenuItems()}
        onKeyDown={e => handleButtonKeyDown(e)}
        data-testid="my-account"
      >
        {label}
      </button>
      <ul
        role="listbox"
        tabIndex="-1"
        aria-activedescendant={selectedOptionId}
        className={classNames('ToggleMenu-menuItems', {
          'focus-within:outline focus-within:outline-2 active:outline-none':
            isOpen,
        })}
      >
        {menuItems.map(renderMenuItem)}
      </ul>
      <div className="ToggleMenu-status" role="status" aria-relevant="all">
        {statusText}
      </div>
    </div>
  );
};

ToggleMenu.propTypes = {
  label: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

export { ToggleMenu };
