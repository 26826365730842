import { batch } from 'react-redux';
import {
  fetch as fetchRecipient,
  fetchCountries as fetchRecipientCountries,
  normalizeRecipient,
} from 'services/recipients';
import { fetch as fetchCountries } from 'services/countries/countries';
import {
  FETCH_RECIPIENT_REQUEST,
  FETCH_COUNTRIES_REQUEST,
  FETCH_RECIPIENT_COUNTRIES_REQUEST,
  FETCH_RECIPIENT_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_RECIPIENT_COUNTRIES_SUCCESS,
  FETCH_RECIPIENT_FAILURE,
  FETCH_COUNTRIES_FAILURE,
  SET_DEFAULT_FIELD_VALUES,
} from 'constants/index';
import { getCurrentLocale, getFieldValue } from 'selectors';
import { PayexThunkAction } from 'store/configureStore';
import { RootState } from 'reducers/types';
import { Field } from 'models/field';

const fetchData =
  (recipientId: string): PayexThunkAction =>
  async (dispatch, getState) => {
    if (!recipientId || recipientId === '') {
      return dispatch({ type: FETCH_RECIPIENT_FAILURE });
    }

    batch(() => {
      dispatch({ type: FETCH_RECIPIENT_REQUEST });
      dispatch({ type: FETCH_COUNTRIES_REQUEST });
      dispatch({ type: FETCH_RECIPIENT_COUNTRIES_REQUEST });
    });
    const state = getState();
    const locale = getCurrentLocale(state);

    try {
      const [recipient, recipientCountries, countries] = await Promise.all([
        fetchRecipient({ id: recipientId, locale }),
        fetchRecipientCountries({ id: recipientId, locale }),
        fetchCountries({ locale }),
      ]);

      return batch(() => {
        dispatch({ type: FETCH_RECIPIENT_SUCCESS, payload: recipient });
        dispatch({ type: FETCH_COUNTRIES_SUCCESS, payload: countries });
        dispatch({
          type: FETCH_RECIPIENT_COUNTRIES_SUCCESS,
          payload: recipientCountries,
        });
        dispatch({
          type: SET_DEFAULT_FIELD_VALUES,
          payload: removePrefilledFields(getState(), recipient),
        });
      });
    } catch (e) {
      return batch(() => {
        dispatch({ type: FETCH_RECIPIENT_FAILURE });
        dispatch({ type: FETCH_COUNTRIES_FAILURE });
      });
    }
  };

const removePrefilledFields = (
  state: RootState,
  payload: ReturnType<typeof normalizeRecipient>,
) => {
  const NO_VALUE = '';
  const newPayload = { ...payload };

  newPayload.entities = { ...payload.entities };

  const {
    entities: { fields },
  } = newPayload;

  newPayload.entities.fields = Object.keys(fields)
    .filter(field => getFieldValue(state, field) === NO_VALUE)
    .reduce((acc, key) => {
      acc[key] = fields[key];
      return acc;
    }, {} as Record<string, Field>);

  return newPayload;
};

export default fetchData;
