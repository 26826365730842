import { EXCHANGE_CURRENCY_SUCCESS } from 'constants/index';

type ExchangeAction = {
  type: typeof EXCHANGE_CURRENCY_SUCCESS;
  payload: {
    greaterThan10kUSD: boolean;
  };
};

export const exchange = (
  state = { greaterThan10kUSD: false },
  action: ExchangeAction,
) => {
  switch (action.type) {
    case EXCHANGE_CURRENCY_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
