export const SET_SENDER_COUNTRY_FIELD = 'SET_SENDER_COUNTRY_FIELD';
export const UPDATE_IP_COUNTRY_CODE_REQUEST = 'UPDATE_IP_COUNTRY_CODE_REQUEST';
export const UPDATE_IP_COUNTRY_CODE_SUCCESS = 'UPDATE_IP_COUNTRY_CODE_SUCCESS';
export const EU_COUNTRY_CODES = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'UK',
];
