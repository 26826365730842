import {
  CREATE_PAYMENT_SUCCESS,
  FETCH_KYC_SUCCESS,
  FETCH_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_SUCCESS,
  SELECT_PAYMENT,
} from 'constants/index';
import omit from 'lodash/omit';
import type { Link } from 'models';

type LinksAction =
  | {
      type:
        | typeof CREATE_PAYMENT_SUCCESS
        | typeof FETCH_PAYMENT_SUCCESS
        | typeof SELECT_PAYMENT;
      payload: {
        entities: {
          links: Record<string, Link>;
        };
      };
    }
  | {
      type: typeof FETCH_KYC_SUCCESS;
      payload: {
        uploadUrl: string;
      };
    }
  | {
      type: typeof INITIATE_PAYMENT_SUCCESS;
    };

export type LinksState = Record<string, Link> & { uploadUrl?: string };

export default (state: LinksState = {}, action: LinksAction) => {
  const newState = { ...state };

  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
    case FETCH_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
      const {
        entities: { links = {} },
      } = action.payload;
      return { ...links };
    case INITIATE_PAYMENT_SUCCESS:
      return omit(newState, 'initiate');
    case FETCH_KYC_SUCCESS:
      const { uploadUrl } = action.payload;
      return { uploadUrl };
    default:
      return state;
  }
};
