import schemas from 'constants/schemas';
import { Country } from 'models/country';
import apiV3 from 'services/apiV3';
import { normalizeResponse } from 'utils/normalize/normalize';

export const fetch = async ({ locale = 'EN' }) => {
  const countries = await apiV3.countries.fetch({ locale });

  return normalizeCountries(countries);
};

export const normalizeCountries = <T extends Partial<Country> = Country>(
  countries: T[],
): {
  entities?: {
    countries: Record<string, Country>;
  };
  result?: string[];
} => {
  return countries.length
    ? normalizeResponse<{
        countries: Record<string, Country>;
      }>(countries, schemas.arrayOfCountries)
    : {};
};
