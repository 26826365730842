import React from 'react';

import './ErrorCounter.scss';

type ErroCounterProps = {
  count: number;
};

const ErrorCounter = ({ count = 0 }: ErroCounterProps) => (
  <span data-testid="numberOfErrors" className="ErrorCounter">
    {count}
  </span>
);

export { ErrorCounter };
