import { combineReducers } from 'redux';
import {
  DELETE_FILE_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS,
  FETCH_DOCUMENTS_FAILURE,
} from 'constants/index';
import type { DocumentLegacy, DocumentFile } from 'models';

type DocumentsAction =
  | {
      type:
        | typeof FETCH_DOCUMENTS_FAILURE
        | typeof FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS;
    }
  | {
      type: typeof DELETE_FILE_SUCCESS;
      payload: {
        fileId: string;
      };
    }
  | {
      type: typeof FETCH_DOCUMENTS_SUCCESS;
      payload: {
        documents?: Record<string, DocumentLegacy>;
      };
    };

export const byId = (
  state: Record<string, DocumentLegacy> = {},
  action: DocumentsAction,
) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS: {
      const { documents = {} } = action.payload;

      return documents;
    }
    case DELETE_FILE_SUCCESS: {
      const { fileId } = action.payload;
      const documentId = Object.keys(state)[0];

      if (!documentId) return state;

      const document = state[documentId];
      const { files } = document;

      const newFiles: Record<string, DocumentFile> = { ...files };
      delete newFiles[fileId];
      const newDocument = { ...document, files: newFiles };

      return { [documentId]: newDocument };
    }
    case FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS:
    case FETCH_DOCUMENTS_FAILURE:
      return {};
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: DocumentsAction) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS: {
      const { documents = {} } = action.payload;
      return Object.keys(documents);
    }
    case FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS:
    case FETCH_DOCUMENTS_FAILURE:
      return [];
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
