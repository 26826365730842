import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, ConnectedProps } from 'react-redux';
import { WizardContext } from 'components/Wizard/components/context';
import { validateSections, updateOrderWithEntities } from 'actions';
import { getErrors } from 'selectors';
import { Navigation } from 'components/Navigation/Navigation';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import { RequiredFieldLabel } from 'components/RequiredFieldLabel/RequiredFieldLabel';
import { WithTranslations } from 'components/HOC/WithTranslations/WithTranslations';
import { WithFocusOnError } from 'components/HOC/WithFocusOnError/WithFocusOnError';
import { Section } from './Section/Section';
import { CLIENT_FORM } from 'constants/index';
import type { RootState } from 'reducers/types';
import type { I18n } from 'utils';

import './SectionList.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;
type SectionListProps = PropsFromRedux & {
  entity: string;
  focusOnFirstError: () => void;
  i18n: I18n;
  title: string;
};
class SectionListComponent extends Component<SectionListProps> {
  static propTypes = {
    entity: PropTypes.string,
    focusOnFirstError: PropTypes.func.isRequired,
    getErrors: PropTypes.func,
    i18n: PropTypes.object.isRequired,
    sections: PropTypes.array,
    title: PropTypes.string,
    updateOrderWithEntities: PropTypes.func.isRequired,
    validateSections: PropTypes.func,
  };

  handleClickPrev = () => {
    const { goToPrevStep } = this.context;
    goToPrevStep();
  };

  handleClickNext = async () => {
    const {
      sections,
      focusOnFirstError,
      validateSections,
      updateOrderWithEntities,
      entity,
    } = this.props;

    const { goToNextStep } = this.context;
    const success = await validateSections(sections);

    if (success) {
      updateOrderWithEntities(entity)
        .then(goToNextStep)
        .catch(focusOnFirstError);
    } else {
      focusOnFirstError();
    }
  };

  renderSection = section => {
    return <Section key={section.id} {...section} />;
  };

  render() {
    const { getErrors, i18n } = this.props;

    const navigation = (
      <Navigation
        onClickPrev={this.handleClickPrev}
        onClickNext={this.handleClickNext}
        errors={getErrors()}
        previousStepName={i18n.t('stepsList.sender')}
        nextStepName={i18n.t('stepsList.confirmation')}
      />
    );

    const { sections, title } = this.props;
    const sectionList = sections.map(this.renderSection);

    return (
      <PaymentStep navigation={navigation} stepName={CLIENT_FORM} title={title}>
        <RequiredFieldLabel />
        {sectionList}
      </PaymentStep>
    );
  }
}

SectionListComponent.contextType = WizardContext;

const mapStateToProps = (state: RootState) => ({
  getErrors: () => getErrors(state),
});

const mapDispatchToProps = {
  updateOrderWithEntities,
  validateSections,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SectionList = connector(
  WithFocusOnError(WithTranslations(SectionListComponent)),
);

export { SectionList };
