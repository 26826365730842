import React, { useState, useEffect, ReactNode } from 'react';
import { connect } from 'react-redux';
import { isLoggedIn } from 'selectors';
import {
  recoverRefreshTokenIfNeeded,
  fetchUser,
  fetchUserPaymentsIfNeeded,
} from 'actions';
import { Spinner } from 'components/Spinner/Spinner';
import type { RootState } from 'reducers/types';

type AuthUserProps = {
  recoverRefreshTokenIfNeeded: () => Promise<void>;
  children?: ReactNode | undefined;
  fetchUser: () => Promise<void>;
  fetchUserPaymentsIfNeeded: () => Promise<void>;
  isLoggedIn: boolean;
};

function AuthUserComponent({
  recoverRefreshTokenIfNeeded,
  children,
  fetchUser,
  fetchUserPaymentsIfNeeded,
  isLoggedIn,
}: AuthUserProps) {
  const [isFetching, setIsFetching] = useState(true);

  function stopFetching() {
    setIsFetching(false);
  }

  useEffect(() => {
    async function fetchData() {
      if (isLoggedIn) {
        await fetchUser();
        await fetchUserPaymentsIfNeeded();
      }

      stopFetching();
    }

    fetchData();

    return () => stopFetching();
  }, [fetchUser, fetchUserPaymentsIfNeeded, isLoggedIn]);

  useEffect(() => {
    window.addEventListener('focus', recoverRefreshTokenIfNeeded);

    return () => {
      window.removeEventListener('focus', recoverRefreshTokenIfNeeded);
    };
  }, [recoverRefreshTokenIfNeeded]);

  if (isFetching) return <Spinner />;

  return children;
}

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = {
  recoverRefreshTokenIfNeeded: recoverRefreshTokenIfNeeded,
  fetchUser,
  fetchUserPaymentsIfNeeded,
};

const AuthUser = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthUserComponent);

export { AuthUser };
