import { useEffect, useState } from 'react';
import { CardTokenizationEvent } from '../../services/tokenization/models/models';

function useCardTokenizationEvent() {
  const [tokenizationResult, setTokenizationResult] =
    useState<CardTokenizationEvent>();

  useEffect(() => {
    const handleEvent = (event: MessageEvent) => {
      const cardTokenizationEvent = new CardTokenizationEvent(event);

      if (cardTokenizationEvent.isCardTokenizationEvent) {
        setTokenizationResult(cardTokenizationEvent);
      }
    };

    window.addEventListener('message', handleEvent);

    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  return { tokenizationResult };
}

export default useCardTokenizationEvent;
