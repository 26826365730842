import { combineReducers } from 'redux';
import { FETCH_COUNTRIES_SUCCESS } from '../../../constants';
import type { Country } from 'models';

type CountriesAction = {
  type: typeof FETCH_COUNTRIES_SUCCESS;
  payload: {
    entities: {
      countries: Record<string, Country>;
    };
    result: string[];
  };
};

export const byId = (
  state: Record<string, Country> = {},
  action: CountriesAction,
) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      const {
        entities: { countries },
      } = action.payload;
      return countries;
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: CountriesAction) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      const { result } = action.payload;
      return result;
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
