import { authentication } from './authentication/authentication';
import { countries } from './countries/countries';
import { kycs } from './kycs/kycs';
import { orders } from './orders/orders';
import { payments } from './payments/payments';
import { recipients } from './recipients/recipients';
import { senders } from './senders/senders';
import { users } from './users/users';

export default {
  authentication,
  countries,
  kycs,
  orders,
  payments,
  recipients,
  senders,
  users,
};
