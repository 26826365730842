import { FETCH_PUBLIC_KEY_SUCCESS } from '../../constants';

type PublicKeyAction = {
  type: typeof FETCH_PUBLIC_KEY_SUCCESS;
  payload: {
    publicKey: string;
  };
};

export const publicKey = (state = '', action: PublicKeyAction) => {
  switch (action.type) {
    case FETCH_PUBLIC_KEY_SUCCESS:
      return action.payload.publicKey;
    default:
      return state;
  }
};

export default publicKey;
