import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { OfferContext } from '../../Offer';

const Logo = ({ logo }) => {
  const src = logo.links.original.includes('svg')
    ? logo.links.original
    : logo.links.thumb;

  return <img alt={logo.name} className="Offer-logo" src={src} />;
};

Logo.propTypes = {
  logo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    links: PropTypes.shape({
      original: PropTypes.string,
      thumb: PropTypes.string,
    }),
  }),
};

const LogosList = ({ logos }) => {
  const { i18n } = useContext(OfferContext);

  if (!logos || !logos.length) return null;

  const renderedLogos = logos.map(logo => <Logo logo={logo} key={logo.name} />);

  return (
    <div className="Offer-logos">
      <span className="Offer-logos__heading">
        {i18n.t('LogosList.supports')}
      </span>
      <div className="Offer-logos__box">{renderedLogos}</div>
    </div>
  );
};

LogosList.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        original: PropTypes.string,
        thumb: PropTypes.string,
      }),
    }),
  ),
};

LogosList.defaultProps = {
  logos: [],
};

export { LogosList };
