import fetchJson from 'utils/fetchJson';
import { CAP_URL } from 'constants/index';

export const cap = {
  fee: ({ currency }: { currency?: string }) => {
    const url = `${CAP_URL}/fee`;
    const payload = { currency };
    const body = JSON.stringify(payload);
    const options = { method: 'POST', body };

    return fetchJson(url, options);
  },
};
