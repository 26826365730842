import React, { useEffect, useState, useRef } from 'react';
import { Nothing } from 'components/Nothing/Nothing';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useUniqueId } from 'hooks';
import { Keys, NOOP } from 'constants/index';
import classNames from 'classnames';

import './Tooltip.scss';

type TooltipProps = {
  className?: string;
  children?: React.ReactNode;
  'data-testid': string;
  defaultMessage?: string;
  messageChildren?: React.ReactNode;
  messageClassName?: string;
  successMessage?: string;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
};

function Tooltip({
  className,
  children,
  'data-testid': dataTestId = 'tooltip',
  defaultMessage,
  messageChildren,
  messageClassName,
  successMessage,
  onClick = NOOP,
}: TooltipProps) {
  const containerRef = useRef<HTMLButtonElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [message, setMessage] = useState(defaultMessage);
  const [isOpen, setIsOpen] = useState(false);
  const id = useUniqueId('tooltip');

  useEffect(() => {
    setMessage(defaultMessage);
  }, [defaultMessage]);

  function handleOnClick(evt: React.MouseEvent<HTMLButtonElement>) {
    onClick(evt);

    if (successMessage) {
      setMessage(successMessage);
      return;
    }

    setIsOpen(isOpen => !isOpen);
  }

  function handleOnMouseEnter() {
    setIsOpen(true);
  }

  function handleOnMouseLeave(
    evt:
      | React.MouseEvent<HTMLButtonElement>
      | React.FocusEvent<HTMLButtonElement>,
  ) {
    if (
      tooltipRef.current &&
      evt.relatedTarget &&
      evt.relatedTarget instanceof Element &&
      tooltipRef.current.contains(evt.relatedTarget)
    ) {
      return;
    }

    setMessage(defaultMessage);
    setIsOpen(false);
  }

  function handleOnKeyDown(evt: React.KeyboardEvent) {
    if (evt.key === Keys.ESC) {
      setIsOpen(false);
    }
  }

  if (!message && !messageChildren) {
    return <Nothing />;
  }

  return (
    <button
      ref={containerRef}
      aria-label={message}
      aria-labelledby={id}
      data-testid="container"
      className={classNames('Tooltip-container', className)}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnClick}
      onFocus={handleOnMouseEnter}
      onBlur={handleOnMouseLeave}
      onKeyDown={handleOnKeyDown}
      tabIndex={0}
    >
      {children}
      <div
        ref={tooltipRef}
        className={classNames(
          'Tooltip',
          { 'Tooltip-hidden': !isOpen },
          messageClassName,
        )}
        aria-hidden={!isOpen}
        data-testid={dataTestId}
        id={id}
        role="tooltip"
        {...(!messageChildren && {
          dangerouslySetInnerHTML: createMarkup(message),
        })}
      >
        {messageChildren}
      </div>
    </button>
  );
}

export { Tooltip };
