import React from 'react';
import { useTranslations } from 'utils';
import { CreditCardIcon } from 'components/CreditCardIcon/CreditCardIcon';
import { CardNewMandate } from '@flywire/internal-react-elements';
import type { CardPaymentMethod } from 'models';

import '@flywire/internal-react-elements/styles.css';
import './CreditCardListItem.scss';

type CreditCardListItemProps = {
  card?: CardPaymentMethod;
  isSelected?: boolean;
  isCvvLoading?: boolean;
  onSelect: (creditCard?: CardPaymentMethod) => void;
};

type AddCardProps = Omit<CreditCardListItemProps, 'card' | 'isCvvLoading'>;
type CreditCardProps = Omit<CreditCardListItemProps, 'card'> & {
  card: CardPaymentMethod;
};

const AddCard = ({ isSelected = false, onSelect }: AddCardProps) => {
  const i18n = useTranslations();
  return (
    <>
      <div data-testid="addNewCard" className="CreditCardListItem-AddCard">
        <input
          type="radio"
          name="user-cc"
          id="input-other"
          checked={isSelected}
          onChange={() => onSelect()}
        />
        <label htmlFor="input-other">
          <span>{i18n.t('recurring.payment.cards.other')}</span>
        </label>
      </div>
      <div
        className="CreditCardListItem-AddCard-icons"
        data-testid="creditCardIcons"
      >
        <CreditCardIcon name="VISA" />
        <CreditCardIcon name="MC" />
        <CreditCardIcon name="AMEX" />
      </div>
    </>
  );
};

const CreditCard = ({
  card,
  isCvvLoading,
  isSelected,
  onSelect,
}: CreditCardProps) => {
  const i18n = useTranslations();

  const showCvv = isSelected && !isCvvLoading;

  return (
    <div data-testid="creditCard">
      <div className="CreditCardDetails">
        <input
          type="radio"
          value={card.token}
          name="user-cc"
          id={`input-${card.token}`}
          checked={isSelected}
          onChange={() => onSelect(card)}
        />
        <div>
          <label htmlFor={`input-${card.token}`}>
            <CreditCardIcon name={card.details.scheme} />
            <span>
              {i18n.t('recurring.payment.cc.pay', {
                type: card.details.brand,
                lastFour: card.details.lastFour,
              })}
            </span>
          </label>
          {showCvv && <CardNewMandate.CVV className="CreditCardDetails-Cvv" />}
        </div>
      </div>
    </div>
  );
};

const CreditCardListItem = ({
  card,
  isCvvLoading = false,
  isSelected = false,
  onSelect,
}: CreditCardListItemProps) => {
  return (
    <li className="CreditCardListItem">
      {card ? (
        <CreditCard
          card={card}
          isCvvLoading={isCvvLoading}
          isSelected={isSelected}
          onSelect={onSelect}
        />
      ) : (
        <AddCard onSelect={onSelect} isSelected={isSelected} />
      )}
    </li>
  );
};

export { CreditCardListItem };
