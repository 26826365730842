import { PAYMENT_STATUS_INITIATED } from 'constants/payment';
import isEmpty from 'lodash/isEmpty';
import { Proof } from 'models/proof';
import type { RootState } from 'reducers/types';

export const getProof = (state: RootState) => {
  const {
    entities: {
      proofs: { byId },
      payment: { status },
    },
  } = state;
  const key = Object.keys(byId)[0];

  if (status !== PAYMENT_STATUS_INITIATED) return {};
  if (!key) return {};

  const proof = byId[key] || {};
  const files = getProofFiles(state, proof);

  return { ...proof, files };
};

const getProofFiles = (state: RootState, proof: Proof) => {
  const {
    entities: { proofFiles },
  } = state;

  const areFilesMissing = isEmpty(proof.files) || isEmpty(proofFiles);

  if (areFilesMissing) return [];

  const files = proof.files.map(fileId => {
    const file = proofFiles[fileId];
    const { name } = file;
    const { href: downloadLink } =
      file.links.find(link => link.rel === 'download') || {};
    const { href: deleteLink } =
      file.links.find(link => link.rel !== 'download') || {};

    return { downloadLink, deleteLink, fileId, name };
  });

  return files;
};

export const isPaymentProofUploaded = (state: RootState) => {
  return !isEmpty(state.entities.proofFiles);
};
