import React from 'react';
import PropTypes from 'prop-types';
import { TextInputField } from '../TextInputField/TextInputField';
import { connect } from 'react-redux';
import { WithTranslations } from 'components/HOC/WithTranslations/WithTranslations';
import classNames from 'classnames';
import { UserAccessModal } from 'components/UserAccessModal/UserAccessModal';
import signupImage from './ico-tooltip-signup.svg';
import loginImage from './ico-tooltip-login.svg';
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';
import { userExists, fetchUserPayments } from 'actions';
import { isEmbedded, isLoggedIn, isUserCreated } from 'selectors';
import type { RootState } from 'reducers/types';

import './SenderEmailInputField.scss';

class SenderEmailInputFieldComponent extends React.Component {
  static propTypes = {
    fetchUserPayments: PropTypes.func.isRequired,
    field: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    i18n: PropTypes.object.isRequired,
    isEmbedded: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isUserCreated: PropTypes.bool.isRequired,
    userExists: PropTypes.func.isRequired,
  };

  state = {
    isTooltipOpen: true,
    isModalOpen: false,
    isValidEmail: false,
  };

  openTooltip = () => this.setState({ isTooltipOpen: true });
  closeTooltip = () => this.setState({ isTooltipOpen: false });
  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });
  setValidEmail = isValidEmail => this.setState({ isValidEmail });

  validateEmail = async name => {
    const { handleBlur: validateField, userExists } = this.props;
    const isValid = await validateField(name);
    if (isValid) await userExists();
    this.setValidEmail(isValid);
  };

  changeEmail = (name, value) => {
    const { handleChange: saveEmail } = this.props;
    this.setValidEmail(false);
    this.openTooltip();
    saveEmail(name, value);
  };

  handleLoginSuccess = () => {
    const { fetchUserPayments } = this.props;
    fetchUserPayments();
    this.closeModal();
  };

  get shouldShowTooltip() {
    if (!this.state.isValidEmail) return false;

    const { isEmbedded, isLoggedIn } = this.props;
    return !isEmbedded && !isLoggedIn;
  }

  render() {
    const { field, i18n, isUserCreated } = this.props;
    const { isTooltipOpen, isModalOpen } = this.state;

    return (
      <>
        <UserAccessModal
          isOpen={isModalOpen}
          onClose={this.closeModal}
          showSignupTab={!isUserCreated}
          onLoginSuccess={this.handleLoginSuccess}
          onSignUpSuccess={this.closeModal}
        />
        <aside className="SenderEmailInputField-tooltip-container">
          <TextInputField
            handleBlur={this.validateEmail}
            handleChange={this.changeEmail}
            field={field}
          />
          {this.shouldShowTooltip && (
            <Tooltip
              i18n={i18n}
              showLogin={isUserCreated}
              isTooltipOpen={isTooltipOpen}
              handleClick={this.openModal}
              handleClose={this.closeTooltip}
            />
          )}
        </aside>
      </>
    );
  }
}

const Tooltip = ({
  i18n,
  isTooltipOpen,
  handleClick,
  handleClose,
  showLogin,
}) => {
  const tooltipClassNames = classNames('SenderEmailInputField-tooltip', {
    'SenderEmailInputField-tooltip--hidden': !isTooltipOpen,
  });

  const key = showLogin ? 'login' : 'signup';

  return (
    <div className={tooltipClassNames} hidden={!isTooltipOpen}>
      <div className="SenderEmailInputField-tooltipContent">
        <img
          className="SenderEmailInputField-tooltipImage"
          src={showLogin ? loginImage : signupImage}
          alt="login"
        />
        <div className="SenderEmailInputField-tooltipTexts">
          {showLogin && (
            <p className="SenderEmailInputField-tooltipText">
              {i18n.t(`senderEmailInputField.${key}.title`)}
            </p>
          )}
          <p className="SenderEmailInputField-tooltipText">
            <button
              onClick={handleClick}
              className="SenderEmailInputField-tooltipLink"
              aria-label={i18n.t(`senderEmailInputField.${key}.link`)}
            >
              {i18n.t(`senderEmailInputField.${key}.link`)}
            </button>
            {i18n.t(`senderEmailInputField.${key}.description`)}
          </p>
        </div>
      </div>
      <button
        onClick={handleClose}
        className="SenderEmailInputField-tooltipClose"
        aria-label="Close"
      >
        <CloseIcon aria-hidden="true" />
      </button>
    </div>
  );
};

Tooltip.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  isTooltipOpen: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state: RootState) => ({
  isEmbedded: isEmbedded(state),
  isLoggedIn: isLoggedIn(state),
  isUserCreated: isUserCreated(state),
});

const mapDispatchToProps = {
  fetchUserPayments,
  userExists,
};

const SenderEmailInputField = WithTranslations(
  connect(mapStateToProps, mapDispatchToProps)(SenderEmailInputFieldComponent),
);

export { SenderEmailInputField };
