import React, { useState } from 'react';
import { Field } from '@flywire/react-headlessui';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils/translations/useTranslations';
import { Checkbox } from './Checkbox';

import './MultipleCheckbox.scss';

type MultipleCheckboxProps = {
  error?: string | boolean;
  label?: string;
  name: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
  checked?: string[];
  required?: boolean;
  onBlur?: () => void;
  onChange?: (params: { name: string; checked: string[] }) => void;
};

const MultipleCheckbox = ({
  checked,
  name,
  options,
  disabled,
  error,
  label,
  required,
  onBlur,
  onChange,
}: MultipleCheckboxProps) => {
  const [checkedValues, setCheckedValues] = useState(
    options.reduce((acc, check) => {
      acc[check.label] = {
        value: check.value,
        checked: !!checked?.includes(check.value),
      };

      return acc;
    }, {} as Record<string, { value: string; checked: boolean }>),
  );

  const i18n = useTranslations();

  const onCheckboxChange = (label: string, value: boolean) => {
    const _checkedValues = {
      ...checkedValues,
      [label]: {
        ...checkedValues[label],
        checked: value,
      },
    };
    setCheckedValues(_checkedValues);

    const checked = Object.entries(_checkedValues)
      .filter(([, { checked }]) => checked)
      .map(([, { value }]) => value);

    onChange?.({ name, checked });
  };
  return (
    <div>
      <Field.Label className="MultipleCheckbox-Label">
        {label}
        {required && <span aria-hidden> *</span>}
      </Field.Label>
      <fieldset className="MultipleCheckbox-Fieldset" aria-labelledby={label}>
        {Object.entries(checkedValues).map(([label, { checked, value }]) => (
          <div key={label} className="MultipleCheckbox">
            <Checkbox
              id={`${name}-${value}`}
              disabled={disabled}
              required={required}
              error={!!error}
              label={label}
              name={name}
              checked={checked}
              onBlur={onBlur}
              onChange={e => onCheckboxChange(label, e.target.checked)}
            />
          </div>
        ))}
        {error && typeof error === 'string' && (
          <Field.Error
            data-testid={`${name}-error`}
            className="MultipleCheckbox-error"
          >
            <VisuallyHidden as="span">{label}</VisuallyHidden>
            {i18n.t(error)}
          </Field.Error>
        )}
      </fieldset>
    </div>
  );
};

export { MultipleCheckbox };
