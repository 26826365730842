import React from 'react';
import { useTranslations } from 'utils';
import { Button } from 'lib/flywire-tailwind/Button';

type RedirectButtonProps = {
  url: string;
};

const RedirectButton = ({ url }: RedirectButtonProps) => {
  const i18n = useTranslations();

  return (
    <a href={url}>
      <Button tone="primary">{i18n.t('gatewayOnly.button')}</Button>
    </a>
  );
};

export { RedirectButton };
