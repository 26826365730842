import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FooterWithAddress } from 'containers/FooterWithAddress/FooterWithAddress';
import { Notifications } from 'components/Notifications/Notifications';
import { PreFooter } from 'components/PreFooter/PreFooter';
import { Wizard } from 'components/Wizard/Wizard';
import { Header } from 'components/Header/Header';
import { ConnectedProps, connect } from 'react-redux';
import { GatewayOnly } from 'components/GatewayOnly/GatewayOnly';
import { initializeHeapAgent } from 'actions/analytics/heapAgent/heapAgent';
import {
  fetchOfferFromOrder,
  fetchPaymentById,
  fetchUserPayments,
  fetchPaymentsRecipient,
  setFieldValue,
  setFieldsValues,
  setGatewayOrigin,
  setPrefilledFields,
  setReadOnlyFields,
  setSenderCountryFromGeolocation,
} from 'actions';
import {
  getAccessToken,
  getCurrentCurrency,
  getCurrentLocale,
  getLastMatchingPaymentFromRecipient,
  getLastPaymentFromSameRecipient,
  getSelectedOfferId,
  hasGatewayOrigin,
  gatewayOnly,
  getRecipient,
  isFetching,
} from 'selectors';
import { Spinner } from 'components/Spinner/Spinner';
import { EmbeddedRepository as embedded } from 'repositories/embedded/embedded';
import { unsetEmbedded } from 'actions/ui/ui';
import type { RootState } from 'reducers/types';

const SENDER_COUNTRY_FIELD = 'sender_country';

function decodeFields(fields: Record<string, string>) {
  const decodedFields: Record<string, string> = {};

  Object.keys(fields).forEach(key => {
    decodedFields[key] = decodeURIComponent(fields[key]);
  });

  return decodedFields;
}

function removeEmptyFields(fields: Record<string, string>) {
  const filteredEntries = Object.entries(fields).filter(
    ([, value]) => value !== '',
  );
  return Object.fromEntries(filteredEntries);
}

type PaymentSetupComponentProps = ConnectedProps<typeof connector>;

export function PaymentSetupComponent({
  accessToken,
  currency,
  fetchOfferFromOrder,
  fetchPaymentById,
  fetchPaymentsRecipient,
  fetchUserPayments,
  gatewayOnly,
  hasGatewayOrigin,
  initializeHeapAgent,
  isCreatingPayment = false,
  lastPaymentFromSameRecipient,
  locale,
  matchingPaymentFromRecipient,
  recipient,
  selectedOfferId,
  setFieldValue,
  setFieldsValues,
  setGatewayOrigin,
  setPrefilledFields,
  setReadOnlyFields,
  setSenderCountryFromGeolocation,
  unsetEmbedded,
}: PaymentSetupComponentProps) {
  const prevLocaleRef = useRef<string>(locale);
  const [searchParams] = useSearchParams();

  const [isFetching, setIsFetching] = useState(true);
  const fetchPayments = useCallback(async () => {
    await Promise.all([fetchUserPayments(), fetchPaymentsRecipient()]);
  }, [fetchPaymentsRecipient, fetchUserPayments]);

  const fetchData = useCallback(async () => {
    setSenderCountryFromGeolocation();
    await Promise.all([
      selectedOfferId
        ? fetchOfferFromOrder(selectedOfferId)
        : Promise.resolve(),
    ]);

    if (accessToken) {
      await fetchPayments();
    }
  }, [
    accessToken,
    fetchOfferFromOrder,
    fetchPayments,
    selectedOfferId,
    setSenderCountryFromGeolocation,
  ]);

  const removeEmbedded = useCallback(() => {
    if (!embedded.isEnabled()) {
      unsetEmbedded();
    }
  }, [unsetEmbedded]);

  const setUrlFields = (fields: Record<string, string>) => {
    const fieldKeys = Object.keys(removeEmptyFields(fields));
    const removePaymentInformationFields = (field: string) =>
      field !== 'sender_country' && field !== 'amount';

    if (!embedded.isEnabled()) {
      setPrefilledFields(fieldKeys.filter(removePaymentInformationFields));
    }

    if (!fieldKeys.length) return;

    setGatewayOrigin();

    const decodedFields = decodeFields(fields);

    setFieldsValues(decodedFields);
  };

  const setFieldsAsReadOnly = (readOnlyFields: string) => {
    if (embedded.isEnabled()) return;
    const readOnlyFieldsArray = readOnlyFields.split(',');
    if (!readOnlyFieldsArray.length) return;
    setReadOnlyFields(readOnlyFieldsArray);
  };

  const transformXeroAmount = xeroAmount => {
    const { subunitToUnit } = currency;
    const amount = Math.round(xeroAmount * subunitToUnit);

    setFieldValue('amount', amount);
  };

  const setUrlParams = () => {
    const {
      country,
      destination,
      recipient,
      xero_amount: xeroAmount,
      read_only: readOnlyFields = '',
      ...fields
    } = Object.fromEntries(searchParams.entries());

    setFieldsAsReadOnly(readOnlyFields);
    setUrlFields(fields);

    if (xeroAmount) transformXeroAmount(xeroAmount);
    if (country) setFieldValue(SENDER_COUNTRY_FIELD, country);
  };

  useEffect(() => {
    if (prevLocaleRef.current !== locale) {
      fetchData();
    }

    prevLocaleRef.current = locale;
  }, [locale, fetchData]);

  useEffect(() => {
    if (matchingPaymentFromRecipient && !lastPaymentFromSameRecipient) {
      fetchPaymentById(matchingPaymentFromRecipient.id, true);
    }
  }, [
    matchingPaymentFromRecipient,
    lastPaymentFromSameRecipient,
    fetchPaymentById,
  ]);

  useEffect(() => {
    async function fetchDataAndInit() {
      await fetchData();
      setUrlParams();
      removeEmbedded();
      initializeHeapAgent();
      setIsFetching(false);
    }

    fetchDataAndInit();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isFetching || isCreatingPayment) {
    return <Spinner />;
  }

  const isGatewayOnly = gatewayOnly && gatewayOnly.enabled;
  const showGatewayOnly = isGatewayOnly && !hasGatewayOrigin;

  if (showGatewayOnly) {
    return (
      <GatewayOnly url={gatewayOnly.redirectUrl} clientName={recipient.name} />
    );
  }

  return (
    <div className="Site">
      <Notifications />
      <Header onLoginSuccess={fetchPayments} />
      <main className="App">
        <Wizard />
      </main>
      <PreFooter />
      <FooterWithAddress />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  selectedOfferId: getSelectedOfferId(state),
  accessToken: getAccessToken(state),
  currency: getCurrentCurrency(state),
  lastPaymentFromSameRecipient: getLastPaymentFromSameRecipient(state),
  locale: getCurrentLocale(state),
  recipient: getRecipient(state),
  hasGatewayOrigin: hasGatewayOrigin(state),
  matchingPaymentFromRecipient: getLastMatchingPaymentFromRecipient(state),
  gatewayOnly: gatewayOnly(state),
  isCreatingPayment: isFetching(state, 'createPaymentFromOrder'),
});

const mapDispatchToProps = {
  fetchPaymentById,
  fetchUserPayments,
  fetchPaymentsRecipient,
  initializeHeapAgent,
  setFieldValue,
  setFieldsValues,
  setGatewayOrigin,
  setPrefilledFields,
  setReadOnlyFields,
  setSenderCountryFromGeolocation,
  unsetEmbedded,
  fetchOfferFromOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const PaymentSetup = connector(PaymentSetupComponent);

export { PaymentSetup };
