import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OptionList } from '../OptionList/OptionList';
import { UserAccessModal } from 'components/UserAccessModal/UserAccessModal';
import { Notes } from './components/Notes/Notes';
import { NoOptions } from './components/NoOptions/NoOptions';
import { NOOP, INTERVAL_FIELD } from 'constants/index';
import { useTranslations } from 'utils';

function LegacyRecurringPaymentOptions({
  getCurrency = NOOP,
  isLoggedIn,
  onUserLogIn,
  onOptionSelect = NOOP,
  options = [],
  trackCheckMarkup = NOOP,
  trackPaymentOption = NOOP,
}) {
  const i18n = useTranslations();
  const [selectedOptionId, setSelectedOptionId] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const noOptions = options.length === 0;
  const hasInterval = option =>
    Object.prototype.hasOwnProperty.call(option, INTERVAL_FIELD);
  const isNoteVisible = options.some(hasInterval);

  function closeModal() {
    setIsModalVisible(false);
  }

  function handleOnLoginSuccess() {
    onUserLogIn();
    closeModal();
    selectedOptionId && onOptionSelect(selectedOptionId);
  }

  function handleOnSignUpSuccess() {
    closeModal();
    selectedOptionId && onOptionSelect(selectedOptionId);
  }

  function handleOnOptionSelect(id: string) {
    if (!isLoggedIn) {
      setSelectedOptionId(id);
      setIsModalVisible(true);
      return;
    }

    onOptionSelect(id);
  }

  if (noOptions) {
    return <NoOptions i18n={i18n} />;
  }

  return (
    <div className="PaymentOptions">
      <UserAccessModal
        isOpen={isModalVisible}
        onClose={closeModal}
        onLoginSuccess={handleOnLoginSuccess}
        onSignUpSuccess={handleOnSignUpSuccess}
      />
      {isNoteVisible && <Notes i18n={i18n} />}
      <div className="PaymentOptions-recurringOptions">
        <OptionList
          a11yMessage={i18n.t('paymentOptions.localOptions')}
          getCurrency={getCurrency}
          onOptionSelect={handleOnOptionSelect}
          options={options}
          readonly
          trackCheckMarkup={trackCheckMarkup}
          trackPaymentOption={trackPaymentOption}
        />
      </div>
    </div>
  );
}

LegacyRecurringPaymentOptions.displayName = 'RecurringPaymentOptions';
LegacyRecurringPaymentOptions.propTypes = {
  getCurrency: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  onOptionSelect: PropTypes.func,
  onUserLogIn: PropTypes.func,
  options: PropTypes.array,
  trackCheckMarkup: PropTypes.func,
  trackPaymentOption: PropTypes.func,
};

export { LegacyRecurringPaymentOptions };
