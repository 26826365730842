import React, { useEffect } from 'react';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { PhoneInputField } from '../PhoneInputField/PhoneInputField';
import {
  isEmbedded,
  getCountry,
  getFieldValue,
  shouldShowWhatsappSubscriptions,
} from 'selectors';
import { setFieldValue } from 'actions';
import { FieldInterface } from '../Field';
import type { RootState } from 'reducers/types';
import { CheckSMSNotification } from './CheckSMSNotification/CheckSMSNotification';
import { CheckWhatsappNotification } from './CheckWhatsappNotification/CheckWhatsappNotification';
import { usePeertransferFlags } from 'hooks/usePeertransferFlags/usePeertransferFlags';
import { FF_WHATSAPP_SUBSCRIPTIONS } from 'constants/index';
import './SenderPhoneInputField.scss';

type SenderPhoneInputFieldProps = ConnectedProps<typeof connector> & {
  field: FieldInterface;
  handleBlur: (name: string) => void;
  handleChange: (name: string, value?: string) => void;
};

const SPACE = ' ';

const SenderPhoneInputFieldComponent = ({
  countryPrefix,
  field,
  handleBlur,
  handleChange,
  isEmbedded,
  setFieldValue,
}: SenderPhoneInputFieldProps) => {
  const showWhatsappSubscriptions = useSelector(
    shouldShowWhatsappSubscriptions,
  );
  const { data: featureFlags } = usePeertransferFlags(
    FF_WHATSAPP_SUBSCRIPTIONS,
  );
  const isWhatsappSubscriptionsEnabled =
    featureFlags?.[FF_WHATSAPP_SUBSCRIPTIONS];

  const showCheckWhatsappNotitication =
    isWhatsappSubscriptionsEnabled && showWhatsappSubscriptions;

  const unsetablePrefix = (field: FieldInterface) => {
    return (
      field.readOnly ||
      (field.defaultValue && !field.defaultValue?.includes(' '))
    );
  };

  const phoneField = () => {
    if (unsetablePrefix(field)) {
      return field;
    }

    const [prefix, ...numberParts] = (
      field.defaultValue || `+${countryPrefix}${SPACE}`
    ).split(SPACE);
    const number = numberParts.join(' ');

    field.defaultValue = `${prefix} ${number}`;
    return field;
  };

  useEffect(() => {
    setFieldValue('sender_phone', phoneField().defaultValue);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="SenderPhoneInputField">
      <PhoneInputField
        field={phoneField()}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      {!isEmbedded &&
        (showCheckWhatsappNotitication ? (
          <CheckWhatsappNotification />
        ) : (
          <CheckSMSNotification />
        ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const id = getFieldValue(state, 'sender_country');

  return {
    countryPrefix: getCountry(state, id as string).dialingCode,
    isEmbedded: isEmbedded(state),
  };
};

const mapDispatchToProps = {
  setFieldValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SenderPhoneInputField = connector(SenderPhoneInputFieldComponent);

export { SenderPhoneInputField };
