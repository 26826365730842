import React, { MouseEventHandler } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WithFlysiteUrls } from 'components/HOC/WithFlysiteUrls/WithFlysiteUrls';
import { trackHelpEvent } from 'actions';
import { useTranslations } from 'utils';

type PropsFromRedux = ConnectedProps<typeof connector>;
type HelpComponentProps = PropsFromRedux & {
  supportUrl: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const HelpComponent = ({
  supportUrl,
  trackHelpEvent,
  onClick,
}: HelpComponentProps) => {
  const i18n = useTranslations();
  const handleClick: MouseEventHandler<HTMLAnchorElement> = evt => {
    trackHelpEvent();
    onClick?.(evt);
  };

  return (
    <a
      href={supportUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      data-testid="HelpLink"
    >
      {i18n.t('step.aside.help')}
    </a>
  );
};

const mapDispatchToProps = {
  trackHelpEvent,
};

const connector = connect(null, mapDispatchToProps);
const Help = WithFlysiteUrls(connector(HelpComponent));

export { Help };
