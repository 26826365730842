import { DELETE_COOKIE_EXPIRATION } from 'constants/cookies';
import { PERMANENT_COOKIE_EXPIRATION } from 'constants/index';

const cookies = {
  read: (name: string) => {
    const cookie = findCookie(name);
    if (cookie === undefined) return null;

    return extractValue(cookie, name);
  },

  set: (
    name: string,
    value: string,
    isPermanent = false,
    domain: string | undefined,
  ) => {
    const cookieExpiration = isPermanent
      ? `expires=${PERMANENT_COOKIE_EXPIRATION};`
      : '';
    const cookieDomain = domain ? `domain=${domain}` : '';

    const cookie = `${name}=${value}; ${cookieExpiration} path=/; ${cookieDomain}`;

    document.cookie = cookie;
  },

  delete: (name: string, domain: string | undefined = undefined) => {
    const cookieExpiration = DELETE_COOKIE_EXPIRATION;
    const cookieDomain = domain ? `domain=${domain}` : '';

    const cookie = `${name}=''; expires=${cookieExpiration}; path=/; ${cookieDomain}`;

    document.cookie = cookie;
  },
};

const findCookie = (name: string) => {
  const result = document.cookie
    .split(';')
    .map(cookie => cookie.trim())
    .filter(cookie => cookie.startsWith(`${name}=`));

  return result.length ? result[0] : undefined;
};

const extractValue = (cookie: string, name: string) =>
  decodeURIComponent(
    cookie
      .substring(name.length + 1)
      .split('+')
      .join(' '),
  );

export { cookies };
