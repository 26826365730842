import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getRecipient, getSectionsByEntity } from 'selectors';
import { SectionList } from 'components/SectionList/SectionList';
import { Fetching } from 'components/Fetching/Fetching';
import { useTranslations } from 'utils/translations/useTranslations';
import type { RootState } from 'reducers/types';

type PropsFromRedux = ConnectedProps<typeof connector>;

const ENTITY = 'recipient';

const RecipientInformationComponent = ({
  recipient,
  sections,
}: PropsFromRedux) => {
  const i18n = useTranslations();

  const title = i18n.t('recipientInformation.title', {
    recipient: recipient.name as string,
  });

  return (
    <>
      <Fetching entity="order" />
      <SectionList sections={sections} title={title} entity={ENTITY} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  recipient: getRecipient(state),
  sections: getSectionsByEntity(state, ENTITY),
});

const connector = connect(mapStateToProps);
const RecipientInformation = connector(RecipientInformationComponent);

export { RecipientInformation };
