import date from 'utils/date';

class Installment {
  amount;
  interval;
  startDate;
  subunitToUnit;
  unitsToRound;
  type;

  constructor({
    amount = 0,
    interval = 1,
    startDate = Date.now(),
    subunitToUnit = 100,
    unitsToRound = 1,
    type = 'fixed',
  }) {
    this.amount = amount;
    this.interval = interval;
    this.startDate = startDate;
    this.subunitToUnit = subunitToUnit;
    this.unitsToRound = unitsToRound;
    this.type = type;
  }

  get fee() {
    const fee = this.amount / this.interval;
    const roundedSubunits = this.unitsToRound * this.subunitToUnit;

    return Math.ceil(fee / roundedSubunits) * roundedSubunits;
  }

  get dates() {
    return [...Array(this.interval).keys()].map(num => {
      return date.addMonths(this.startDate, num);
    });
  }

  get starts() {
    return this.dates[0];
  }

  get ends() {
    return this.dates.slice(-1)[0];
  }
}

export default Installment;
