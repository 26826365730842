import { combineReducers } from 'redux';
import authentication from './authentication';
import countries from './countries';
import currencies from './currencies';
import documentFiles from './documentFiles';
import documents from './documents';
import documentsCollection from './documentsCollection';
import errors from './errors';
import extendedUser from './extendedUser';
import fees from './fees';
import fields from './fields';
import instructions from './instructions';
import items from './items';
import kyc from './kyc';
import links from './links';
import lrs from './lrs';
import { offers } from './offers';
import order from './order';
import payment from './payment';
import payments from './payments';
import payouts from './payouts';
import prices from './prices';
import proofFiles from './proofFiles';
import proofs from './proofs';
import purchases from './purchases';
import recipient from './recipient';
import recipients from './recipients';
import { recurringOffers } from './recurringOffers';
import recurringReceivables from './recurringReceivables';
import refunds from './refunds';
import repeatPaymentOffer from './repeatPaymentOffer';
import sections from './sections';
import selected from './selected';
import smsNotifications from './smsNotifications';
import user from './user';
import { legacyRecurringOffers } from './legacyRecurringOffers';
import { cashback } from './cashback';

export default combineReducers({
  authentication,
  cashback,
  countries,
  currencies,
  documentFiles,
  documents,
  documentsCollection,
  errors,
  extendedUser,
  fees,
  fields,
  instructions,
  items,
  kyc,
  links,
  lrs,
  offers,
  order,
  payment,
  payments,
  payouts,
  prices,
  proofFiles,
  proofs,
  purchases,
  recipient,
  recipients,
  legacyRecurringOffers,
  recurringOffers,
  recurringReceivables,
  refunds,
  repeatPaymentOffer,
  sections,
  selected,
  smsNotifications,
  user,
});
