import { useDispatch, useSelector } from 'react-redux';
import { getCreditCards } from 'selectors';
import { fetchPaymentMethods } from 'actions';
import { useEffect } from 'react';

export function useCreditCards() {
  const creditCards = useSelector(getCreditCards);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPaymentMethods());
  }, [dispatch]);

  return {
    creditCards,
    hasCreditCards: creditCards.length > 0,
  };
}
