import {
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  FETCH_PAYMENT_REQUEST,
  FETCH_PAYMENT_SUCCESS,
  FETCH_PAYMENT_FAILURE,
  PROMOTIONS_OPT_IN_FIELD,
  CANCEL_PAYMENT_REQUEST,
  CANCEL_PAYMENT_SUCCESS,
  CANCEL_PAYMENT_FAILURE,
  INITIATE_PAYMENT_REQUEST,
  INITIATE_PAYMENT_SUCCESS,
  FETCH_USER_PAYMENTS_REQUEST,
  FETCH_USER_PAYMENTS_SUCCESS,
  FETCH_USER_PAYMENTS_FAILURE,
  FETCH_USER_PAYMENT_REQUEST,
  FETCH_USER_PAYMENT_SUCCESS,
  FETCH_USER_PAYMENT_FAILURE,
  FETCH_LOGGED_USER_PAYMENT_REQUEST,
  FETCH_LOGGED_USER_PAYMENT_SUCCESS,
  FETCH_LOGGED_USER_PAYMENT_FAILURE,
  FETCH_SMS_NOTIFICATION_REQUEST,
  FETCH_SMS_NOTIFICATION_SUCCESS,
  FETCH_SMS_NOTIFICATION_FAILURE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  UPDATE_SENDER_EMAIL,
  CHINESE_FIELD_ID,
  STUDENT_CHINESE_FIELD_ID,
  STUDENT_PASSPORT_FIELD_ID,
  STUDENT_CNY_ID_NUMBER,
  PAYER_PASSPORT_FIELD_ID,
  FETCH_PAYMENT_LRS_REQUEST,
  FETCH_PAYMENT_LRS_SUCCESS,
  FETCH_PAYMENT_LRS_FAILURE,
  DELETE_PAYMENT_LRS_REQUEST,
  DELETE_PAYMENT_LRS_SUCCESS,
  DELETE_PAYMENT_LRS_FAILURE,
  UPLOAD_LRS_INFO_REQUEST,
  UPLOAD_LRS_INFO_SUCCESS,
  UPLOAD_LRS_INFO_FAILURE,
  UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT,
  INTERVAL_FIELD,
  PAYMENT_PROOF_COMPLETED,
  MAINTENANCE_MODE,
  HTTPStatuses,
  PERMANENT_COOKIE_NAME,
  HttpError,
  PAYMENT_FETCH_ERROR,
} from 'constants/index';
import {
  getCurrentLocale,
  getFieldByEntity,
  getFieldValue,
  getLinkById,
  getSenderFieldValue,
  getOrder,
  getPaymentToken,
  getPaymentId,
  isFetching,
  shouldHideUserManagement,
  paymentIsFromAgents,
  untilIsFetched,
  isTrackingPage,
  isMuthoot,
  isLulu,
  isLoggedIn,
  getSelectedOffer,
  isSelectedOfferLegacyRecurring,
  isSelectedOfferLegacySubscription,
  getPayment,
  getCaptchaResponse,
  getPlatform,
  getRecipientId,
  getItems,
  orderHasOwner,
  shouldReceiveWhatsappNotification,
} from 'selectors';
import date from 'utils/date';
import { errorNotifier } from 'utils/errorNotifier';
import { callbackPayment } from 'utils/callbackPayment/callbackPayment';
import { lrs } from 'services/lrs/lrs';
import { embedded } from 'repositories';
import {
  addOrderToUser,
  trackRecurringPaymentBooked,
  trackSubscriptionPaymentBooked,
  trackSubscriptionWhatsapp,
  enableFeature,
  newNotification,
  setDocCollectorFinished,
  fetchDocuments,
} from 'actions';
import logger from 'services/logger';
import { cookies } from 'utils/cookies/cookies';
import { RepeatPaymentPayload } from 'services/payex/payments/payments';
import {
  FieldEntityType,
  LegacyRecurringSelectedOffer,
  Payment,
  PreviousPayment,
} from 'models';
import {
  CreatePaymentPayload,
  normalizeErrors,
  normalizePayment,
} from 'services/payments/payments';
import type { PayexDispatch, PayexThunkAction } from 'store/configureStore';
import type { RootState } from 'reducers/types';
import {
  isHttpErrorWithParsedBody,
  isHttpErrorWithStatus,
} from 'utils/errors/errors';
import { normalizeSmsNotifications } from 'services/smsNotifications/smsNotifications';
import type { PaymentsService } from 'services/payex/payments/payments';

const MUTHOOT_OFFER = 'muthoot';
const LULU_OFFER = 'lulu';
const SIGNATURE_FILE_ERROR = 'invalid_signature';
const PERFORMING_ACTION_ERROR =
  'notifications.edit_payment_details.performing_action_error';
const ENGLISH = 'en';

const getCreatePaymentCallback = ():
  | ((payment: Payment) => unknown)
  | undefined => {
  const onPaymentCreated = embedded.getProp('onPaymentCreated');

  const callback = (payment: Payment): unknown =>
    // @ts-expect-error this is coming from embedded props and it's hard to type
    onPaymentCreated(callbackPayment(payment));

  return onPaymentCreated ? callback : undefined;
};

export const createPayment =
  (platform: string): PayexThunkAction =>
  async (dispatch, getState, { payments, promotions, userLanguage }) => {
    const state = getState();
    const isCreatingPayment = isFetching(state, 'createPayment');

    if (isCreatingPayment) return;

    dispatch({ type: CREATE_PAYMENT_REQUEST });

    const locale = getCurrentLocale(state);
    const senderEmail = getFieldValue(state, 'sender_email') as string;
    if (locale !== ENGLISH) {
      userLanguage.update(senderEmail as string, locale);
    }

    const promotionsOptIn = getFieldValue(state, PROMOTIONS_OPT_IN_FIELD);
    if (promotionsOptIn) promotions.subscribe(senderEmail as string);

    const { id, token } = getOrder(state);
    const captchaResponse = getCaptchaResponse(state);
    const selectedOffer = getSelectedOffer(state);
    const callback = getCreatePaymentCallback();
    const hideUserManagement = shouldHideUserManagement(state);
    const interval = getFieldValue(state, INTERVAL_FIELD);

    const subscriptionType =
      'recurring' in selectedOffer
        ? (selectedOffer as LegacyRecurringSelectedOffer).recurring.type
        : undefined;

    const isSubscription = subscriptionType && subscriptionType !== 'fixed';
    const isRecurring =
      interval && (!subscriptionType || subscriptionType === 'fixed');

    const recipient = getRecipientId(state);
    const whatsappNotification = shouldReceiveWhatsappNotification(state);

    const params: CreatePaymentPayload = {
      orderId: id,
      token,
      locale,
      callback,
      hideUserManagement,
      captchaResponse: captchaResponse as string,
      ...(isSubscription && {
        recurring: { type: subscriptionType },
      }),
      ...(isRecurring && {
        recurring: {
          type: 'fixed',
          interval: interval as number,
        },
      }),
      platform,
      recipient,
      ...(whatsappNotification != null && { whatsappNotification }),
    };

    try {
      const normalizedPayment = await payments.create(params);

      if (whatsappNotification !== undefined && whatsappNotification !== null)
        dispatch(trackSubscriptionWhatsapp(whatsappNotification));

      if (isSelectedOfferLegacyRecurring(state)) {
        if (isSelectedOfferLegacySubscription(state)) {
          dispatch(trackSubscriptionPaymentBooked());
        } else if (interval) {
          dispatch(trackRecurringPaymentBooked(interval as number));
        }
      }

      return dispatch({
        type: CREATE_PAYMENT_SUCCESS,
        payload: normalizedPayment,
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const { status, parsedBody } = error as HttpError;

        if (status === 503) {
          return dispatch(enableFeature(MAINTENANCE_MODE));
        }

        return dispatch({
          type: CREATE_PAYMENT_FAILURE,
          payload: parsedBody,
        });
      } else {
        return dispatch({
          type: CREATE_PAYMENT_FAILURE,
        });
      }
    }
  };

export const fetchPayment =
  ({
    orderId,
    token,
  }: {
    orderId?: string;
    token?: string;
  } = {}): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();

    await untilIsFetched(getState, 'payment');

    dispatch({ type: FETCH_PAYMENT_REQUEST });

    const locale = getCurrentLocale(state);

    if (!orderId || !token) {
      ({ id: orderId, token } = getOrder(state));
    }

    if (!orderId || !token) {
      errorNotifier.notify(
        `${FETCH_PAYMENT_FAILURE} Invalid order id (${orderId}) or token (${token}) when fetching order payment`,
      );
      return dispatch({ type: FETCH_PAYMENT_FAILURE });
    }

    try {
      const response = await apiV3.orders.fetchPayment({
        orderId,
        token,
        locale,
      });

      const payment = response[response.length - 1];

      if (!payment) return dispatch({ type: FETCH_PAYMENT_FAILURE });

      dispatch({
        type: FETCH_PAYMENT_SUCCESS,
        payload: normalizePayment(payment),
      });

      return payment;
    } catch (error) {
      return dispatch({ type: FETCH_PAYMENT_FAILURE });
    }
  };

export const cancelPayment =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isCancellingPayment = isFetching(state, 'paymentManagement');

    if (isCancellingPayment) return;

    dispatch({ type: CANCEL_PAYMENT_REQUEST });

    const { href, method } = getLinkById(state, 'cancellation') || {};

    if (!href) {
      return;
    }

    let cancellationReason;
    if (paymentIsFromAgents(state)) cancellationReason = 'agents';

    try {
      const response = await apiV3.orders.cancelPayment({
        url: href,
        method,
        cancellationReason,
      });

      await logger.log({
        description: 'payment cancelled by user',
        parameters: {
          payment: getPayment(state),
        },
      });

      return dispatch({
        type: CANCEL_PAYMENT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const httpError = error as HttpError;
        const { status } = httpError;

        if (
          status !== HTTPStatuses.NOT_FOUND &&
          status !== HTTPStatuses.BAD_REQUEST &&
          status !== HTTPStatuses.NOT_ALLOWED
        ) {
          httpError.message = `${CANCEL_PAYMENT_FAILURE} ${httpError.message}`;
          errorNotifier.notifyWithFingerprint(httpError, [
            CANCEL_PAYMENT_FAILURE,
            `${status || ''}`,
          ]);
        }
      }
      return dispatch({ type: CANCEL_PAYMENT_FAILURE });
    }
  };

export const initiatePayment =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingPaymentActions = isFetching(state, 'paymentActions');

    if (isFetchingPaymentActions) return;

    dispatch({ type: INITIATE_PAYMENT_REQUEST });

    const { href: url, method } = getLinkById(state, 'initiate');
    const currentIsoDate = date.now();
    const response = await apiV3.orders.initiatePayment({
      url,
      method,
      date: currentIsoDate,
    });

    return dispatch({
      type: INITIATE_PAYMENT_SUCCESS,
      payload: { ...response },
    });
  };

export const fetchUserPayments =
  ({ page = 1 }: { page?: number } = {}): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    try {
      await untilIsFetched(getState, 'payments');
      dispatch({ type: FETCH_USER_PAYMENTS_REQUEST });
      const payments = await apiV3.payments.fetchUserPayments({ page });

      return dispatch({
        type: FETCH_USER_PAYMENTS_SUCCESS,
        payload: payments,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_USER_PAYMENTS_FAILURE,
        payload: error,
      });
    }
  };

export const fetchUserPaymentsIfNeeded =
  (): PayexThunkAction => async (dispatch, getState) => {
    const { entities: { payments = [] } = {} } = getState();

    if (payments.length > 0) {
      return;
    }

    return dispatch(fetchUserPayments());
  };

export const fetchPaymentById =
  (id: string, isUserPayment = false): PayexThunkAction =>
  async (dispatch, getState, { payex }) => {
    const state = getState();
    const isFetchingPayment = isFetching(state, 'payment');
    const locale = getCurrentLocale(state);

    if (isFetchingPayment) return;
    if (isUserPayment)
      return fetchUserPaymentById(id, dispatch, { payments: payex.payments });

    dispatch({ type: FETCH_PAYMENT_REQUEST });

    try {
      const payment = await payex.payments.fetchById({ id, locale });
      const { payload } = await dispatch({
        type: FETCH_PAYMENT_SUCCESS,
        payload: normalizePayment(payment),
      });
      return payload;
    } catch (e) {
      return dispatch({ type: FETCH_PAYMENT_FAILURE });
    }
  };

const fetchUserPaymentById = (
  paymentId: string,
  dispatch: PayexDispatch,
  { payments }: { payments: PaymentsService },
) => {
  dispatch({ type: FETCH_USER_PAYMENT_REQUEST });

  return payments
    .fetchById({
      id: paymentId,
    })
    .then(payment => {
      if (!payment) {
        return dispatch({ type: FETCH_USER_PAYMENT_FAILURE });
      }
      const payload = normalizePayment(payment);

      const {
        entities: { payments },
        result,
      } = payload;

      return dispatch({
        type: FETCH_USER_PAYMENT_SUCCESS,
        payload: payments[result],
      });
    })
    .then((response: { type: string; payload?: unknown }) => response.payload);
};

export const fetchLoggedUserPaymentById =
  (paymentId: string): PayexThunkAction =>
  async (dispatch, _, { payex }) => {
    dispatch({ type: FETCH_LOGGED_USER_PAYMENT_REQUEST });

    return payex.payments
      .fetchById({
        id: paymentId,
      })
      .then(payment => {
        const payload = normalizePayment(payment);
        const {
          entities: { payments },
          result,
        } = payload;

        return dispatch({
          type: FETCH_LOGGED_USER_PAYMENT_SUCCESS,
          payload: payments[result],
        });
      })
      .catch(error => {
        if (isHttpErrorWithStatus(error)) {
          const httpError = error as HttpError;
          const { status = '' } = httpError;
          if (status === HTTPStatuses.NOT_FOUND) {
            const message = PERFORMING_ACTION_ERROR;
            const noti = newNotification({ message, type: 'error' });

            dispatch(noti);
          }
        }

        dispatch({
          type: FETCH_LOGGED_USER_PAYMENT_FAILURE,
        });

        throw new Error(PAYMENT_FETCH_ERROR);
      });
  };

export const fetchSMSNotification =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    try {
      const state = getState();
      const isFetchingPaymentActions = isFetching(state, 'paymentActions');

      if (isFetchingPaymentActions) return;

      dispatch({ type: FETCH_SMS_NOTIFICATION_REQUEST });

      const { href: url } = getLinkById(state, 'sms_notification');
      const response = await apiV3.payments.fetchSMSNotification({ url });

      return dispatch({
        type: FETCH_SMS_NOTIFICATION_SUCCESS,
        payload: normalizeSmsNotifications(response),
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const httpError = error as HttpError;
        const { status = '' } = httpError;
        if (status !== HTTPStatuses.NOT_FOUND) {
          httpError.message = `${FETCH_SMS_NOTIFICATION_FAILURE} ${httpError.message}`;
          errorNotifier.notifyWithFingerprint(httpError, [
            FETCH_SMS_NOTIFICATION_FAILURE,
            `${status}`,
          ]);
        }
      }
      return dispatch({ type: FETCH_SMS_NOTIFICATION_FAILURE });
    }
  };

export const updatePayment =
  (): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isUpdatingPayment = isFetching(state, 'payment');

    if (isUpdatingPayment)
      return Promise.reject(new Error('Already updating a payment...'));

    dispatch({ type: UPDATE_PAYMENT_REQUEST });

    const token = getPaymentToken(state);
    const id = getPaymentId(state);
    const payload = updatePaymentPayload(state);

    return apiV3.payments
      .update({ ...payload, id, token })
      .then(payment => {
        updateSenderEmail(dispatch, payload);

        dispatch({
          type: UPDATE_PAYMENT_SUCCESS,
          payload: {
            sender: payment.sender,
            offer: payment.offer,
            recipient: payment.recipient,
          },
        });
      })
      .catch(error => {
        const {
          parsedBody: { errors },
        } = error;

        dispatch({
          type: UPDATE_PAYMENT_FAILURE,
          payload: normalizeErrors(errors),
        });

        throw new Error();
      });
  };

const updateSenderEmail = (
  dispatch: PayexDispatch,
  payload: ReturnType<typeof updatePaymentPayload>,
) => {
  const {
    sender: { fields },
  } = payload;
  const findEmail = ({ id }: { id?: string }) => id === 'email';
  const email = fields.find(findEmail)?.value;

  dispatch({ type: UPDATE_SENDER_EMAIL, payload: email });
};

const updatePaymentPayload = (state: RootState) => {
  const shouldNotFilterEncryptedFields = () =>
    !isTrackingPage(state) || (orderHasOwner(state) && isLoggedIn(state));

  const filterChineseId = (fieldId: string) => {
    if (shouldNotFilterEncryptedFields()) return true;
    const chineseFields = [
      `offer_${CHINESE_FIELD_ID}`,
      `offer_${STUDENT_CHINESE_FIELD_ID}`,
    ];
    return !chineseFields.includes(fieldId);
  };

  const filterStudentCnyIdNumber = (fieldId: string) => {
    if (shouldNotFilterEncryptedFields()) return true;
    return fieldId !== STUDENT_CNY_ID_NUMBER;
  };

  const filterStudentPassport = (fieldId: string) => {
    if (shouldNotFilterEncryptedFields()) return true;
    return fieldId !== STUDENT_PASSPORT_FIELD_ID;
  };

  const filterPayerPassport = (fieldId: string) => {
    if (shouldNotFilterEncryptedFields()) return true;
    return fieldId !== PAYER_PASSPORT_FIELD_ID;
  };

  const entityFieldsPayload = (name: FieldEntityType) => {
    const entity = getFieldByEntity(state, name);
    const { byId: fields, ids: fieldList } = entity;

    return fieldList
      .filter(filterChineseId)
      .filter(filterStudentCnyIdNumber)
      .filter(filterStudentPassport)
      .filter(filterPayerPassport)
      .filter((fieldId: string) => {
        const field = fields[fieldId];
        return 'updatableForPayments' in field
          ? field.updatableForPayments
          : true;
      })
      .map(field => {
        const { name } = fields[field];
        const value = getFieldValue(state, field);
        return { id: name, value };
      });
  };

  return {
    recipient: {
      fields: entityFieldsPayload('recipient'),
    },
    offer: {
      fields: entityFieldsPayload('offers'),
    },
    sender: {
      fields: entityFieldsPayload('sender'),
    },
  };
};

export const fetchPaymentLRS =
  (): PayexThunkAction =>
  async (dispatch, getState, { lrs }) => {
    const state = getState();
    const isFetchingPayment = isFetching(state, 'payment');

    if (isFetchingPayment) return;

    const paymentId = getPaymentId(state);
    const paymentToken = getPaymentToken(state);

    dispatch({ type: FETCH_PAYMENT_LRS_REQUEST });

    return lrs
      .fetch({ paymentId, paymentToken })
      .then(() => {
        dispatch({ type: FETCH_PAYMENT_LRS_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: FETCH_PAYMENT_LRS_FAILURE });
      });
  };

export const deletePaymentLRS =
  (): PayexThunkAction =>
  async (dispatch, getState, { lrs }) => {
    const state = getState();
    const isFetchingPayment = isFetching(state, 'payment');

    if (isFetchingPayment) return;

    const paymentId = getPaymentId(state);
    const paymentToken = getPaymentToken(state);

    dispatch({ type: DELETE_PAYMENT_LRS_REQUEST });
    try {
      await lrs.delete({ paymentId, paymentToken });
      dispatch({ type: DELETE_PAYMENT_LRS_SUCCESS });
      dispatch(setDocCollectorFinished(false));
      dispatch(fetchDocuments());
    } catch (error) {
      dispatch({ type: DELETE_PAYMENT_LRS_FAILURE });
    }
  };

type SendLRSDataParams = {
  bankAccountNumber: string;
  studentFullName: string;
  studentID: string;
  transactions: PreviousPayment[];
  signatureImage: string;
  idVerificationNeeded: boolean;
  isStudentPaying: boolean;
};

export const sendLRSData =
  (data: SendLRSDataParams): PayexThunkAction =>
  async (dispatch, getState) => {
    const state = getState();

    const senderEmail = getSenderFieldValue(state, 'email');
    const paymentId = getPaymentId(state);
    const paymentToken = getPaymentToken(state);
    const partner = getPartner(state);

    dispatch({ type: UPLOAD_LRS_INFO_REQUEST });
    try {
      await lrs.uploadInfo({
        ...data,
        senderEmail: senderEmail ? (senderEmail as string) : undefined,
        partner,
        paymentId,
        paymentToken,
      });
      return dispatch({ type: UPLOAD_LRS_INFO_SUCCESS });
    } catch (error) {
      if (isHttpErrorWithParsedBody(error)) {
        const httpError = error as HttpError;
        httpError.message = `${UPLOAD_LRS_INFO_FAILURE} ${httpError.message}`;
        errorNotifier.notifyWithFingerprint(
          httpError,
          [UPLOAD_LRS_INFO_FAILURE, `${httpError.status || ''}`],
          { ...data, senderEmail, partner, paymentId },
        );

        let message = `notifications.${UPLOAD_LRS_INFO_FAILURE.toLowerCase()}`;

        const { parsedBody } = httpError;
        if (
          parsedBody?.error &&
          typeof parsedBody?.error === 'string' &&
          SIGNATURE_FILE_ERROR === parsedBody.error.toLowerCase()
        ) {
          message = `${message}.${SIGNATURE_FILE_ERROR}`;
          dispatch({ type: UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT });
        }
        const noti = newNotification({ message, type: 'error' });

        dispatch(noti);
      }

      return dispatch({ type: UPLOAD_LRS_INFO_FAILURE });
    }
  };

type UploadLRSParams = {
  bankAccountNumber: string;
  studentFullName: string;
  studentID: string;
  transactions: PreviousPayment[];
  signatureImage: string;
};

export const uploadLRS =
  (data: UploadLRSParams): PayexThunkAction<Promise<{ success: boolean }>> =>
  async (dispatch, getState) => {
    const state = getState();
    const paymentId = getPaymentId(state);
    const paymentToken = getPaymentToken(state);

    dispatch({ type: UPLOAD_LRS_INFO_REQUEST });
    try {
      await lrs.upload({
        ...data,
        paymentId,
        paymentToken,
      });
      setDocCollectorFinished(true);
      dispatch(fetchDocuments());
      dispatch({ type: UPLOAD_LRS_INFO_SUCCESS });
      return { success: true };
    } catch (error) {
      let message = `notifications.${UPLOAD_LRS_INFO_FAILURE.toLowerCase()}`;
      if (isHttpErrorWithParsedBody(error)) {
        const httpError = error as HttpError;
        httpError.message = `${UPLOAD_LRS_INFO_FAILURE} ${httpError.message}`;
        errorNotifier.notifyWithFingerprint(
          httpError,
          [UPLOAD_LRS_INFO_FAILURE, `${httpError.status || ''}`],
          { ...data, paymentId },
        );

        const { parsedBody } = httpError;
        if (
          parsedBody?.error &&
          typeof parsedBody?.error === 'string' &&
          SIGNATURE_FILE_ERROR === parsedBody.error.toLowerCase()
        ) {
          message = `${message}.${SIGNATURE_FILE_ERROR}`;
          dispatch({ type: UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT });
        }
      }
      const noti = newNotification({ message, type: 'error' });

      dispatch(noti);

      dispatch({ type: UPLOAD_LRS_INFO_FAILURE });

      return { success: false };
    }
  };

export const previewLRSData =
  (data: object): PayexThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const paymentId = getPaymentId(state);
    const paymentToken = getPaymentToken(state);

    try {
      const lrsData = await lrs.previewForm({
        ...data,
        paymentId,
        paymentToken,
      });

      return lrsData;
    } catch (error) {
      errorNotifier.notify(error, {
        ...data,
        paymentId,
      });
      return dispatch({ type: UPLOAD_LRS_INFO_FAILURE });
    }
  };

const getPartner = (state: RootState) => {
  if (isMuthoot(state)) return MUTHOOT_OFFER;
  if (isLulu(state)) return LULU_OFFER;
};

export const repeatPayment =
  (): PayexThunkAction =>
  async (_, getState, { payex }) => {
    const fingerprint = cookies.read(PERMANENT_COOKIE_NAME) || 'no_fingerprint';
    const state = getState();
    const payment = getPayment(state);
    const items = getItems(state);
    const locale = getCurrentLocale(state);
    const itemsPayload = items
      .filter(field => !field.apiReadOnly)
      .map(item => ({
        id: item.id,
        amount: Number(getFieldValue(state, item.id)),
      }));

    const payload: RepeatPaymentPayload = {
      id: payment.id,
      paymentToken: payment.token,
      items: itemsPayload,
      fingerprint,
      locale,
    };
    const repeatedPayment = await payex.payments.repeatPayment(payload);

    return repeatedPayment;
  };

export const changePaymentMethodLegacy =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const { href, method } = getLinkById(state, 'cancellation');

    const payment = await dispatch(createPayment(getPlatform(state)));
    if (isLoggedIn(state)) {
      dispatch(addOrderToUser());
    }
    apiV3.orders.cancelPayment({
      url: href,
      method,
    });

    return payment;
  };

export const changePaymentMethod =
  (): PayexThunkAction =>
  async (dispatch, getState, { payex }) => {
    const state = getState();
    const currentPayment = getPayment(state);
    const { id, token } = getOrder(state);
    const fingerprint = cookies.read(PERMANENT_COOKIE_NAME) || 'no_fingerprint';
    const locale = getCurrentLocale(state);
    const payload = {
      orderId: id,
      orderToken: token,
      paymentId: currentPayment.id,
      paymentToken: currentPayment.token,
      fingerprint,
      locale,
    };
    const payment = await payex.payments.changePaymentMethod(payload);
    return dispatch({
      type: CREATE_PAYMENT_SUCCESS,
      payload: normalizePayment(payment),
    });
  };

export const completePaymentProof = () => ({
  type: PAYMENT_PROOF_COMPLETED,
});
