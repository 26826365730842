import {
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
} from 'constants/index';
import { getPayment, getPaymentId, isFetching } from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import type { PayexThunkAction } from 'store/configureStore';

export const generateDocumentUrl =
  (filename: string): PayexThunkAction =>
  async (_dispatch, getState, { apiV3 }) => {
    const state = getState();
    const { token } = getPayment(state);
    const id = getPaymentId(state);

    try {
      const response = await apiV3.payments.generateDocumentUrl({
        id,
        token,
        filename,
      });

      if (!response.url) throw new Error('docCollector.error');

      return response.url;
    } catch (error) {
      errorNotifier.notify(error);
      throw error;
    }
  };

export const uploadDocument =
  (
    file: File,
    documentUrl: string,
    // eslint-disable-next-line camelcase
    info: { payex_type?: string } = {},
  ): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingDocuments = isFetching(state, 'documents');

    if (isFetchingDocuments) return;

    dispatch({ type: UPLOAD_DOCUMENT_REQUEST });

    try {
      await apiV3.payments.uploadFileToUrl({
        file,
        url: documentUrl,
        info,
      });

      return dispatch({
        type: UPLOAD_DOCUMENT_SUCCESS,
      });
    } catch (error) {
      const { name } = file;

      errorNotifier.notify(error);

      return dispatch({
        type: UPLOAD_DOCUMENT_FAILURE,
        payload: { name },
      });
    }
  };
