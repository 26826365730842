import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { formatToBrowserLocale } from 'utils/date/date';
import type { Field } from 'models';
import type { RootState } from 'reducers/types';
import { Breakdown } from '../Breakdown/Breakdown';
import { getSectionsByEntity, getSenderConfirmationDetails } from 'selectors';

const NO_VALUE = '-';
const BOOLEAN_TYPE = 'boolean';
const CAMPUS_ID = 'campus';
const EMAIL_NAME = 'email';

const putEmailAsLastElement = (fields: Field[]) => {
  let emailField;
  const sortedSection: Field[] = [];

  fields.forEach(field => {
    if (!field.name?.includes(EMAIL_NAME)) {
      sortedSection.push(field);
    } else {
      emailField = field;
    }
  });

  emailField && sortedSection.push(emailField);

  return sortedSection;
};

type SectionBreakdownProps = ConnectedProps<typeof connector>;

const SectionBreakdownComponent = ({ sections }: SectionBreakdownProps) => {
  const sectionList = sections().map(section => {
    let fields = section.fields;
    const sectionsHasEmail = fields.find(field =>
      field.name?.includes(EMAIL_NAME),
    );

    if (sectionsHasEmail) fields = putEmailAsLastElement(fields);

    const fieldList = fields.map(field => {
      if (field.hidden || field.type === BOOLEAN_TYPE) return null;
      if (field.id === CAMPUS_ID) return null;

      const isEmail = field.name?.includes(EMAIL_NAME);
      const isDate = field.format === 'date';
      const fieldClass = isEmail ? 'singleColumn' : null;
      const textValue = field.text || NO_VALUE;

      return (
        <div key={field.name} className={fieldClass}>
          <dt>{field.label}</dt>
          <dd data-hj-suppress>
            {isDate && field.text
              ? formatToBrowserLocale(field.text)
              : textValue}
          </dd>
        </div>
      );
    });

    const nullFields = (field: JSX.Element | null) => field !== null;
    const isEmptyFieldList = fieldList.filter(nullFields).length === 0;

    if (isEmptyFieldList) return null;

    return (
      <Breakdown key={section.id} id={section.id} title={section.name}>
        <dl
          id={`${section.id}-description`}
          className="FlexGrid FlexGrid--fromXSmall-2"
        >
          {fieldList}
        </dl>
      </Breakdown>
    );
  });

  return <div>{sectionList}</div>;
};

const mapStateToProps = (
  state: RootState,
  { entity }: { entity: 'offers' | 'recipient' | 'sender' },
) => ({
  sections: () => {
    if (entity === 'sender') {
      return getSenderConfirmationDetails(state);
    }

    return getSectionsByEntity(state, entity);
  },
});

const connector = connect(mapStateToProps);
const SectionBreakdown = connector(SectionBreakdownComponent);

export { SectionBreakdown };
