import React, { ChangeEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { Checkbox } from 'components/Checkbox/Checkbox';

import './Accept.scss';

type AcceptProps = {
  checked?: boolean;
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  error?: boolean | string;
  name: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Accept = ({
  checked = false,
  children,
  className,
  dataTestId = 'checkbox',
  error,
  name,
  readOnly = false,
  required = false,
  onChange,
  onBlur,
}: AcceptProps) => {
  return (
    <div
      className={classNames('FormGroup FormGroup--block', {
        'has-error': error,
      })}
    >
      <div className={classNames('Accept', className)}>
        <Checkbox
          checked={checked}
          data-testid={dataTestId}
          error={error}
          label={children}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
        />
      </div>
    </div>
  );
};

export { Accept };
