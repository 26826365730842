import React from 'react';

const ProgressBar = ({
  activeStepIndex,
  totalSteps,
}: {
  activeStepIndex: number;
  totalSteps: number;
}) => {
  const percentage = ((activeStepIndex + 1) * 100) / (totalSteps + 1);

  return (
    <div className="no-print w-full" data-testid="progressBar">
      <label htmlFor="progress" className="sr-only">
        {`Step ${activeStepIndex + 1} of ${totalSteps}`}
      </label>
      <div
        id="progress"
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuetext={`Step ${activeStepIndex + 1} of ${totalSteps}`}
        aria-label={`Progress: Step ${activeStepIndex + 1} of ${totalSteps}`}
        className="h-1 w-full bg-transparent"
      >
        <div
          className="h-full bg-[#498433] transition-all duration-200"
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
};

export { ProgressBar };
