import { isReceived, isDelivered, isCancelled } from 'selectors';
import type { RootState } from 'reducers/types';
import type { Document } from 'models';
import { LRS_DOCUMENT_TYPE, NOT_FAILED_FILE_STATUSES } from 'constants/index';

const PENDING_FILE_STATUS = 'pending';

export const getDocument = (state: RootState) => {
  const {
    entities: {
      documents: { byId },
    },
  } = state;
  const key = Object.keys(byId)[0];
  const document = byId[key] || {};

  return { ...document };
};

export const getFilesCount = (state: RootState) => {
  const {
    entities: { documentFiles },
  } = state;

  return Object.entries(documentFiles).length || 0;
};

export const documentsCollection = (state: RootState) => {
  const {
    entities: { documentsCollection },
  } = state;

  return documentsCollection;
};

export const hasDocumentsCollection = (state: RootState) => {
  const {
    entities: { documentsCollection },
  } = state;

  return Object.keys(documentsCollection).length > 0;
};

export const hasLRSInDocumentCollection = (state: RootState) => {
  if (isCancelled(state)) return false;

  const {
    entities: { documentsCollection },
  } = state;

  return documentsCollection?.documents?.some(
    d => d.type === LRS_DOCUMENT_TYPE,
  );
};

export const allRequiredFilesUploaded = (state: RootState) => {
  const hasFilesWithNotFailedStatus = (document: Document) => {
    const { files } = document;

    return files.some(file => NOT_FAILED_FILE_STATUSES.includes(file.status));
  };

  const { documents } = documentsCollection(state);
  const requiredDocuments = documents?.filter(document => document.required);

  return requiredDocuments?.every(document =>
    hasFilesWithNotFailedStatus(document),
  );
};

export const requiresDocuments = (state: RootState) => {
  const {
    entities: {
      documents: { ids },
    },
  } = state;
  if (isReceived(state) || isDelivered(state) || isCancelled(state))
    return false;

  return ids?.length > 0 || hasDocumentsCollection(state);
};

export const areFilesUploaded = (state: RootState) => {
  const { files = {} } = getDocument(state);

  return Object.keys(files).length > 0;
};

export const allFilesTyped = (state: RootState) => {
  const { anyFileNotTyped } = getDocument(state);

  return !anyFileNotTyped;
};

export const documentWithTypedFiles = (state: RootState) => {
  const { filesTypes = [] } = getDocument(state);

  return filesTypes.length > 0;
};

export const filesTypes = (state: RootState) => {
  const { filesTypes = [] } = getDocument(state);

  return filesTypes;
};

export const allFilesAnalyzed = (state: RootState) => {
  const {
    entities: {
      documentFiles: { byId, ids },
      documentsCollection,
    },
  } = state;

  const anyLegacyFileWithEmptyLinks = ids.some(
    id => byId[id].links.length === 0,
  );

  const documents = documentsCollection.documents;

  if (!documents) return !anyLegacyFileWithEmptyLinks;

  const anyPendingFile = documents.some(document => {
    const { files } = document;
    return files.some(file => file.status === PENDING_FILE_STATUS);
  });

  return !anyPendingFile;
};
