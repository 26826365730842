import type { RootState } from 'reducers/types';
import { getCurrency } from '../currencies';
import isEmpty from 'lodash/isEmpty';
import { isLoggedIn as getIsLoggedIn } from '../authentication';
import { orderHasOwner as getOrderHasOwner } from '../order';
import { isDelivered as getIsDelivered } from '../payment';
import { CashbackAgreedTransaction } from 'models/cashBack';

export const getAgreedTransactionAmount = (state: RootState) => {
  const agreedTransaction = getAgreedTransactionObject(state);

  if (isEmpty(agreedTransaction)) return 0;

  return agreedTransaction.amount;
};

export const getAgreedTransactionCurrencyObject = (state: RootState) => {
  const agreedTransaction = getAgreedTransactionObject(state);
  if (agreedTransaction?.currency) {
    return getCurrency(state, agreedTransaction.currency);
  }

  return {};
};

export const getBannerCashbackSteps = (state: RootState): 1 | 2 | 3 | null => {
  const isLoggedIn = getIsLoggedIn(state);
  const orderHasOwner = getOrderHasOwner(state);
  const isDelivered = getIsDelivered(state);

  if (isLoggedIn) {
    return orderHasOwner ? (isDelivered ? 3 : 2) : isDelivered ? null : 2;
  } else {
    return orderHasOwner ? 1 : isDelivered ? null : 1;
  }
};

const getAgreedTransactionObject = (
  state: RootState,
): CashbackAgreedTransaction | undefined => {
  const {
    entities: { cashback: { agreedTransaction } = {} },
  } = state;

  return agreedTransaction;
};
