import {
  FETCH_ORDER_BY_TOKEN_SUCCESS,
  FETCH_RECIPIENT_COUNTRIES_SUCCESS,
  FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS,
  FETCH_RECIPIENT_SUCCESS,
  RESTART,
  SELECT_RECIPIENT,
} from 'constants/index';
import type { Country, Recipient } from 'models';

type RecipientAction =
  | {
      type:
        | typeof FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS
        | typeof FETCH_RECIPIENT_SUCCESS
        | typeof SELECT_RECIPIENT;
      payload: {
        entities: {
          recipients: Record<string, Recipient>;
        };
        result: string;
      };
    }
  | {
      type: typeof FETCH_ORDER_BY_TOKEN_SUCCESS;
      payload: {
        id: string;
        token: string;
        recipient: {
          id: string;
        };
      };
    }
  | {
      type: typeof FETCH_RECIPIENT_COUNTRIES_SUCCESS;
      payload: {
        entities: {
          countries: Record<string, Country>;
        };
        result: string[];
      };
    }
  | { type: typeof RESTART };

export default (
  state: Recipient | Record<string, never> = {},
  action: RecipientAction,
) => {
  switch (action.type) {
    case FETCH_RECIPIENT_SUCCESS:
    case FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS:
    case SELECT_RECIPIENT:
      const {
        entities: { recipients },
        result,
      } = action.payload;
      return { ...state, ...recipients[result] };
    case FETCH_RECIPIENT_COUNTRIES_SUCCESS:
      const { result: countries } = action.payload;

      return { ...state, countries };
    case FETCH_ORDER_BY_TOKEN_SUCCESS:
      const {
        recipient: { id },
      } = action.payload;

      return { ...state, id };
    case RESTART:
      return {};
    default:
      return state;
  }
};
