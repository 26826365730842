import React from 'react';
import { Help } from 'components/Help/Help';
import { Contact } from 'components/Contact/Contact';
import { LanguageSelector } from 'components/LanguageSelector/LanguageSelector';
import { IsEmbedded } from 'containers/IsEmbedded/IsEmbedded';
import { Theme } from 'containers/Theme/Theme';

import './PreFooter.scss';

const PreFooter = () => (
  <Theme
    render={({ preFooter }) =>
      preFooter && (
        <IsEmbedded>
          <section className="PreFooter">
            <Contact />
            <Help />
            <LanguageSelector upward />
          </section>
        </IsEmbedded>
      )
    }
  />
);

export { PreFooter };
