import type { PayexThunkAction } from 'store/configureStore';
import {
  ENABLE_FEATURE,
  FEATURES,
  FETCH_FEATURES_REQUEST,
  FETCH_FEATURES_SUCCESS,
  FETCH_FEATURES_ERROR,
} from '../../constants';
import { featureFlags } from 'services/featureFlags/featureFlags';

export const enableFeature = (feature: string) => ({
  type: ENABLE_FEATURE,
  payload: { [feature]: true },
});

export const fetchFeatures = (flags?: string | string[]): PayexThunkAction => {
  const flagsToFetch = flags
    ? Array.isArray(flags)
      ? flags
      : [flags]
    : FEATURES;

  return dispatch => {
    dispatch({ type: FETCH_FEATURES_REQUEST });

    return featureFlags
      .fetch(flagsToFetch)
      .then(payload => dispatch({ type: FETCH_FEATURES_SUCCESS, payload }))
      .catch(() => dispatch({ type: FETCH_FEATURES_ERROR }));
  };
};
