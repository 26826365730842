import React, { useContext } from 'react';
import { OfferContext } from '../../Offer';

function Fee({ value }: { value: string }) {
  const { i18n } = useContext(OfferContext);

  return (
    <span className="Offer-fee" data-testid="offer-fee">
      {i18n.t('offers.includes_fee', { fee: value })}
    </span>
  );
}

Fee.displayName = 'Fee';

export { Fee };
