import {
  getErrors,
  getPaymentStatus,
  getCurrentCountry,
  getTotalAmount,
} from 'selectors';
import { VISIT_NAME } from 'constants/index';
import type { Tracker } from 'services/tracker/tracker';
import type { PayexThunkAction } from 'store/configureStore';
import { WindowWithRequester } from 'utils/fetchJson/fetchJson';
import {
  formatNonFXFeedbackResponsesForRequest,
  type NonFXFeedbackResponses,
} from 'utils/formatNonFXFeedbackResponsesForRequest/formatNonFXFeedbackResponsesForRequest';

const trackEvent = (
  tracker: Tracker,
  name: string,
  ownProps: Record<string, unknown>,
) => {
  const action = 'visit';
  const category = 'visit';
  const label = null;
  const value = null;
  const defaultProps = {
    action,
    category,
    label,
    value,
  };

  const props = { ...defaultProps, ...ownProps };

  return tracker.trackEvent(name, props);
};

export const trackAccountCreation =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'account creation';
    const action = 'creation';
    const category = 'payment action';
    const value = 'account_creation';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackPaymentStatus =
  (): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const label = getPaymentStatus(getState());
    const value = window.location.pathname;

    const props = { label, value };
    const name = VISIT_NAME;

    return trackEvent(tracker, name, props);
  };

export const trackPaymentStep =
  (step: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = VISIT_NAME;
    const value = step;

    return trackEvent(tracker, name, { value });
  };

export const trackDocCollector =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'visit document collection';
    const category = 'document collection';
    const value = window.location.pathname;
    const label = 'not validated';

    const props = { category, value, label };

    return trackEvent(tracker, name, props);
  };

export const trackDocCollectorFold =
  (value: string): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const name = 'Fold document collection';
    const action = 'click';
    const category = 'document collection';
    const label = getPaymentStatus(getState());

    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackDocCollectorFileUpload =
  (apiResponse: unknown): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const name = 'Upload';
    const action = 'upload';
    const category = 'document collection';
    const label = getPaymentStatus(getState());
    const value = apiResponse;

    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackDocCollectorFileDownload =
  (value: string): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const name = 'visit file';
    const category = 'document collection';
    const action = 'click';
    const label = getPaymentStatus(getState());

    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackUserFeedback =
  (rating: number, comment: string | undefined): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'submit feedback';
    const category = 'feedback';
    const label = comment;
    const action = 'submit';
    const value = rating;

    const props = { action, category, value, label };

    return trackEvent(tracker, name, props);
  };

export const trackPaymentActions =
  (value: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = `click ${value}`;
    const action = 'click';
    const category = 'payment action';

    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackPaymentOption =
  (label: string, value: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = `Payment option ${value}`;
    const action = 'click';
    const category = 'cta payment method';

    const props = { category, label, action, value };

    return trackEvent(tracker, name, props);
  };

export const trackFinishDocCollector =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const value = 'documentation finished';
    const name = value;
    const category = 'CTA';
    const action = 'click';

    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackNavigation =
  (internalUrl: string, value: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = `${internalUrl} ${value} navigation`;
    const action = 'click';
    const category = 'navigation';

    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackInitiateModal =
  (value: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click';
    const label = 'confirm funds modal';
    const name = `${action} ${value}`;

    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackConfirmModal =
  (value: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click';
    const label = 'confirm funds modal';
    const name = `${action} ${value}`;

    const props = { action, category, label, value };
    return trackEvent(tracker, name, props);
  };

export const trackCancelPaymentModal =
  (reason: string, comment: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const label = 'cancel modal';
    const action = 'click';
    const name = `${action} ${label}`;

    const props = {
      action,
      category,
      label,
      user_cancellation_reason: reason,
      user_cancellation_comment: comment,
    };

    return trackEvent(tracker, name, props);
  };

export const trackTermsOfUse =
  (link: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'terms of use';
    const action = 'click';
    const value = link;
    const name = `${action} ${link}`;

    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackBPGEvent =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'click bpg';
    const category = 'cta';
    const action = 'click';
    const value = 'bpg';

    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackFieldsErrors =
  (errorReasons: Record<string, string> = {}): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const state = getState();
    const category = 'field';
    const action = 'error';

    return getErrors(state).forEach((field: string) => {
      const name = `field error ${field} ${action}`;

      const props = {
        action,
        category,
        value: errorReasons[field] || 'yes',
        label: field,
      };
      trackEvent(tracker, name, props);
    });
  };

export const trackHelpEvent =
  (): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const paymentStatus = getPaymentStatus(getState());
    const label = paymentStatus ? `front-end payment ${paymentStatus}` : null;
    const category = 'cta';
    const action = 'click';
    const value = 'help';
    const name = `${action} ${value}`;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackSelectCountry =
  (): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const country = getCurrentCountry(getState());
    const label = 'sender_country';
    const category = 'field';
    const action = 'filled';
    const value = country.code;
    const name = label;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackFillAmount =
  (): PayexThunkAction =>
  (_dispatch, getState, { tracker }) => {
    const amount = getTotalAmount(getState());
    const label = 'amount';
    const category = 'field';
    const action = 'filled';
    const value = `${amount}`;
    const name = label;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackCallToAction =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'click dynamic cta';
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'dynamic_cta';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackNobookingCtaAction =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'click dynamic nobooking cta';
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'dynamic_nobooking_cta';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackOppPaymentRequest =
  (cardType: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'opp payment';
    const label = 'OPP';
    const action = 'pay';
    const category = 'OPP Payment';
    const value = cardType;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackOppPaymentSuccess =
  (cardType: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const label = 'OPP success';
    const action = 'successful payment';
    const category = 'OPP Payment';
    const value = cardType;
    const name = `${label} ${value}`;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackOppWalletPayment =
  (isStoredCard: boolean): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const label = 'OPP';
    const action = `pay with ${isStoredCard ? 'stored' : 'other'} card`;
    const category = 'Wallet';
    const name = `${label} ${action}`;
    const props = { action, category, label };

    return trackEvent(tracker, name, props);
  };

export const trackOppSaveCardRequest =
  (isCardSavedSuccessfully: boolean): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const label = 'OPP';
    const action = 'store a card';
    const category = 'Wallet';
    const value = isCardSavedSuccessfully ? 'Success' : 'Error';
    const name = `${label} ${action} ${value}`;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackMakeNewPaymentCancel =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'new_payment_cancel';
    const name = 'make new payment after cancel';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackRepeatPayment =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'Repeat payment';
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'repeat_payment_my_payments';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackPaymentDetailsMoreInfo =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const label = 'more info';
    const category = 'visit';
    const action = 'click on more info';
    const value = 'visit_trackingURL_moreinfo';
    const name = `${label} ${action} ${value}`;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackChangePaymentMethod =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click';
    const value = 'change_payment_method';
    const name = 'Change payment method';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackChangePaymentMethodConfirmed =
  (oldPaymentId: string, newPaymentId: string): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click';
    const value = 'change_payment_method_confirmed';
    const name = 'Change payment method confirmed';
    const props = {
      action,
      category,
      value,
      paymentid: oldPaymentId,
      new_paymentid: newPaymentId,
    };

    return trackEvent(tracker, name, props);
  };

export const trackLrsSubmission =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click';
    const value = 'fill_lrs_online';
    const name = 'LRSonline';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackUploadLrsSignature =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click option';
    const value = 'save_uploaded_signature_lrs_form';
    const name = 'save_uploaded_signature_lrs_form';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackDrawLrsSignature =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click option';
    const value = 'save_drawn_signature_lrs_form';
    const name = 'save_drawn_signature_lrs_form';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackWhyIsMyPymtPending =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'pending status';
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'check_pending_status';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackOpenPaymentGuideLink =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'payment guide';
    const category = 'cta';
    const action = 'click';
    const value = 'open_help_learn_more';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackClosePaymentGuide =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'exit payment guide';
    const action = 'click on "X" floating card';
    const category = 'cta';
    const value = 'close_payment_guide';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackRefund =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'track refund';
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'track_refund';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackRepeatPaymentError =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'repeat payment error';
    const label = null;
    const category = 'error';
    const action = 'error';
    const value = 'repeat_payment_error';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackRepeatPaymentErrorClose =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'close repeat payment error';
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'close_repeat_payment_error';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackCheckMarkup =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'check markup';
    const label = null;
    const category = 'cta';
    const action = 'click';
    const value = 'check_markup';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackSMSNotification =
  (activated: boolean): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'sms notification';
    const label = 'sms_notification';
    const category = 'cta';
    const action = 'click';
    const value = activated ? 'yes' : 'no';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackReturnToClient =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'return_to_client_site';
    const label = null;
    const category = 'cta';
    const action = 'click on return';
    const value = 'return_to_client_site';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackSharePaymentDetails =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'share_your_payment';
    const category = 'cta';
    const action = 'click on share';
    const value = 'share_your_payment';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackCopyPaymentLink =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'copy_payment_link';
    const category = 'cta';
    const action = 'click on copy link';
    const value = 'copy_payment_link';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackMissingDestination =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'no_portal_redirection';
    const category = 'navigation';
    const action = 'redirection to select institution';
    const value = document.referrer;
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackRecurringPaymentBooked =
  (interval: number): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const label = null;
    const category = 'recurring';
    const action =
      'payers who selects recurring options on PayEx and get payment booked';
    const name = 'recurring_payment_booked';
    const props = { action, category, label, value: interval };

    return trackEvent(tracker, name, props);
  };

export const trackRecurringPaymentCancelled =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const label = null;
    const category = 'recurring';
    const action =
      'payer clicks on cancel button from account to cancel recurring';
    const name = 'payer_cancel_recurring_payment';
    const props = { action, category, label };

    return trackEvent(tracker, name, props);
  };

export const trackPayWith =
  (value = 'click pay button'): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = value;
    const category = 'cta';
    const action = 'click';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackPaymentProof =
  (isSuccessful: boolean): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'payment action';
    const action = 'upload payment proof';
    const label = null;
    const name = 'payment proof uploaded';
    const value = isSuccessful ? 'yes' : 'no';
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackSubscriptionPaymentBooked =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'recurring';
    const action =
      'payers who selects subscription options on PayEx and get payment booked';
    const name = 'subscription_payment_booked';
    const value = 'subscription';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackEditDetails =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click option';
    const name = 'edit_details_review_and_confirm';
    const value = 'edit_details_review_and_confirm';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackSaveEditedDetails =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click option';
    const name = 'save_edit_details_review_and_confirm';
    const value = 'save_edit_details_review_and_confirm';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackMoreInfo =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click option';
    const name = 'see_more_info_tracking_page';
    const value = 'see_more_info_tracking_page';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackRedirection =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const action = 'redirection';
    const category = 'cta';
    const name = 'redirect_user_gateway_to_client';
    const value = 'redirect_user_gateway_to_client';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackAcceptAndContinueCardSurchargePaymentWithFee =
  ({ productType }: { productType?: string }): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action =
      'click the link to accept the fee and continue with the payment';
    const name = 'click_accept_and_continue_payment_with_fee';
    const value = 'click_accept_and_continue_payment_with_fee';
    const requester = (window as WindowWithRequester).requester;
    const props = { action, category, value, requester, productType };

    return trackEvent(tracker, name, props);
  };

export const trackCardTokenizationSuccessForCardSurchargePayment =
  ({ productType }: { productType?: string }): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'payment action';
    const action = 'tokenization';
    const name = 'successful_tokenization';
    const value = 'successful_tokenization';
    // @ts-expect-error - ignore
    const requester = window.requester;
    const props = { action, category, value, requester, productType };

    return trackEvent(tracker, name, props);
  };

export const trackChangeCountryOfCardIssuance =
  ({ productType }: { productType?: string }): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click the button to return to the select country step';
    const name = 'click_change_country_of_card_issuance';
    const value = 'click_change_country_of_card_issuance';
    const requester = (window as WindowWithRequester).requester;
    const props = { action, category, value, requester, productType };

    return trackEvent(tracker, name, props);
  };

export const trackChangePaymentMethodInCardSurchargeError =
  ({ productType }: { productType?: string }): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click the button to go to change payment method';
    const name = 'click_change_payment_method_from_error';
    const value = 'click_change_payment_method_from_error';
    const requester = (window as WindowWithRequester).requester;
    const props = { action, category, value, requester, productType };

    return trackEvent(tracker, name, props);
  };

export const trackChangeCountryOfCardIssuanceWhenBlocked =
  ({
    productType,
    country,
  }: {
    productType?: string;
    country?: string;
  }): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'click the button to go to change payment method';
    const name = 'click_change_country_of_card_issuance_because_blocking';
    const value = 'click_change_country_of_card_issuance_because_blocking';
    const requester = (window as WindowWithRequester).requester;
    const props = { action, category, value, requester, productType, country };

    return trackEvent(tracker, name, props);
  };

export const trackDisplayBannerStudentExpenses =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'display';
    const name = 'display_banner_stu_exp_payex';
    const value = 'display_banner_stu_exp_payex';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackDisplayPaymentListBanner =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'display';
    const name = 'display_banner_stu_exp_payex';
    const value = 'display_banner_stu_exp_payex_account';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackDisplayPaymentListCalculator =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'display';
    const name = 'display_banner_stu_exp_payex';
    const value = 'display_calcutor_stu_exp_payex_account';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackUserLoginWithUserAndPassword =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'display';
    const name = 'login_email';
    const value = 'login_email';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackUserLoginWithGoogle =
  (isLogin: boolean): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'cta';
    const action = 'display';
    const name = isLogin ? 'login_google' : 'signup_google';
    const value = name;
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackClickInstalmentPlansTab =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'payment action';
    const action = 'click';
    const label = null;
    const value = 'select_instalment_plans_tab';
    const name = value;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackClickSinglePaymentsTab =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const category = 'payment action';
    const action = 'click';
    const label = null;
    const value = 'select_single_payments_tab';
    const name = value;
    const props = { action, category, label, value };

    return trackEvent(tracker, name, props);
  };

export const trackSubscriptionWhatsapp =
  (activated: boolean): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'whatsapp notification';
    const category = 'cta';
    const action = 'click';
    const value = activated
      ? 'subscription_whatsapp'
      : 'de-subscription_whatsapp';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackPayInAnotherCurrency =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const action = 'click';
    const category = 'payment action';
    const name = 'click_open_nonfxoptions';
    const value = 'click_open_nonfxoptions';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackClickUseINROptions =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'click_use_INR_option';
    const category = 'payment action';
    const action = 'click';
    const value = 'click_use_INR_option';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackClickUseUSDOptions =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'click_use_USD_option';
    const category = 'payment action';
    const action = 'click';
    const value = 'click_use_USD_option';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };

export const trackClickSubmitFXFeedbackForm =
  (nonFXFeedbackResponses: NonFXFeedbackResponses): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'click_submit_FX_feedbackform';
    const category = 'payment action';
    const action = 'click';
    const value = 'click_submit_FX_feedbackform';
    const props = {
      action,
      category,
      value,
      ...formatNonFXFeedbackResponsesForRequest(nonFXFeedbackResponses),
    };

    return trackEvent(tracker, name, props);
  };

export const trackClickCloseFXFeedbackForm =
  (): PayexThunkAction =>
  (_dispatch, _getState, { tracker }) => {
    const name = 'click_close_FX_feedbackform';
    const category = 'payment action';
    const action = 'click';
    const value = 'click_close_FX_feedbackform';
    const props = { action, category, value };

    return trackEvent(tracker, name, props);
  };
