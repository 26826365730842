import {
  FETCH_PAYMENT_REFUNDS_SUCCESS,
  FETCH_PAYMENT_REFUNDS_REQUEST,
  LOGOUT_USER_SUCCESS,
} from '../../../constants';
import { combineReducers } from 'redux';
import type { Refund } from 'models';

export type Amounts = Record<
  string,
  {
    id: string;
    value: number;
    currency: string;
  }
>;

export type Currencies = Record<string, { id: string }>;

type RefundsActions =
  | {
      type: typeof FETCH_PAYMENT_REFUNDS_SUCCESS;
      payload: {
        entities: {
          amounts: Amounts;
          currencies: Currencies;
          refunds: Record<string, Refund>;
        };
        result: string[];
      };
    }
  | { type: typeof FETCH_PAYMENT_REFUNDS_REQUEST | typeof LOGOUT_USER_SUCCESS };

export const byId = (
  state: Record<string, Refund> = {},
  action: RefundsActions,
) => {
  switch (action.type) {
    case FETCH_PAYMENT_REFUNDS_SUCCESS:
      const {
        entities: { refunds },
      } = action.payload;

      return refunds;
    case FETCH_PAYMENT_REFUNDS_REQUEST:
    case LOGOUT_USER_SUCCESS:
      return {};
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: RefundsActions) => {
  switch (action.type) {
    case FETCH_PAYMENT_REFUNDS_SUCCESS:
      const { result } = action.payload;
      return result;
    case FETCH_PAYMENT_REFUNDS_REQUEST:
    case LOGOUT_USER_SUCCESS:
      return [];
    default:
      return state;
  }
};

export const amounts = (state: Amounts = {}, action: RefundsActions) => {
  switch (action.type) {
    case FETCH_PAYMENT_REFUNDS_SUCCESS:
      const {
        entities: { amounts },
      } = action.payload;

      return amounts;
    case FETCH_PAYMENT_REFUNDS_REQUEST:
    case LOGOUT_USER_SUCCESS:
      return {};
    default:
      return state;
  }
};

export const currencies = (state: Currencies = {}, action: RefundsActions) => {
  switch (action.type) {
    case FETCH_PAYMENT_REFUNDS_SUCCESS:
      const {
        entities: { currencies },
      } = action.payload;

      return currencies;
    case FETCH_PAYMENT_REFUNDS_REQUEST:
    case LOGOUT_USER_SUCCESS:
      return {};
    default:
      return state;
  }
};

export default combineReducers({ byId, ids, amounts, currencies });
