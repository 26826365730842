import React, {
  ChangeEvent,
  useState,
  KeyboardEvent,
  InputHTMLAttributes,
  useMemo,
} from 'react';
import { Field } from '@flywire/react-headlessui';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { CountryForSelect } from 'models';
import { useTranslations } from 'utils/translations/useTranslations';
import { Keys } from 'constants/keys';
import { joinPhoneNumberWithPrefix, splitPhoneNumber } from 'utils/phone/phone';
import { useInputAriaProps } from 'hooks';

import './PhoneInput.scss';

const WAIT_TIME = 250;

type PhoneInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'id' | 'onChange' | 'onBlur'
> & {
  id: string;
  countries: CountryForSelect[];
  error?: string | boolean;
  help?: string;
  label?: string;
  onBlur?: (id: string) => void;
  onChange?: (id: string, value?: string) => void;
};

const PhoneInput = ({
  countries,
  disabled = false,
  error,
  help,
  hidden,
  id,
  label,
  name,
  readOnly,
  required,
  type,
  value,
  onBlur,
  onChange,
  ...props
}: PhoneInputProps) => {
  const i18n = useTranslations();
  const dialingCodes = useMemo(
    () =>
      countries
        .filter(({ dialingCode }) => !!dialingCode)
        .map(({ dialingCode }) => dialingCode as string),
    [countries],
  );
  const phone = splitPhoneNumber(value as string, dialingCodes);
  const [prefix, setPrefix] = useState(phone.prefix);
  const [phoneNumber, setPhoneNumber] = useState(phone.phoneNumber);
  const [isFocused, setIsFocused] = useState(false);

  const { inputAriaProps } = useInputAriaProps({
    error: error ?? false,
    label: label || '',
    name: id,
    disabled,
    hint: help,
    readOnly,
    required,
  });

  const onInputKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    const isControl = evt.ctrlKey || evt.metaKey;
    const isCopy = isControl && evt.key === Keys.C;
    const isCut = isControl && evt.key === Keys.X;
    const isNumber = /\d/.test(evt.key);
    const isPaste = isControl && evt.key === Keys.V;
    const allowedChars = [
      Keys.DELETE,
      Keys.ARROW_LEFT,
      Keys.NUMPAD_0,
      Keys.NUMPAD_1,
      Keys.NUMPAD_2,
      Keys.NUMPAD_3,
      Keys.NUMPAD_4,
      Keys.NUMPAD_5,
      Keys.NUMPAD_6,
      Keys.NUMPAD_7,
      Keys.NUMPAD_8,
      Keys.NUMPAD_9,
      Keys.ARROW_RIGHT,
      Keys.SUPR,
      Keys.TAB,
    ].includes(evt.key);

    if (!isNumber && !allowedChars && !isPaste && !isCopy && !isCut) {
      evt.preventDefault();
    }
  };

  const getA11yStatusMessage = (isOpen: boolean) => {
    const isClosed = !isOpen;

    if (isClosed) {
      return i18n.t('phoneInput.results.selected', { label: prefix });
    }

    const resultCount = `${countries.length}`;

    return resultCount === '1'
      ? i18n.t('phoneInput.results.one')
      : i18n.t('phoneInput.results.many', { resultCount });
  };

  const _onBlur = () => {
    onBlur?.(id);
  };

  const onInputFocus = () => {
    setIsFocused(true);
  };
  const onInputBlur = () => {
    setIsFocused(false);
  };

  const onPrefixChange = (value: string) => {
    setPrefix(value);
    _handleChange(joinPhoneNumberWithPrefix(value, phoneNumber));
  };

  const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(ev.target.value || '');
    _handleChange(joinPhoneNumberWithPrefix(prefix, ev.target.value));
  };

  const _handleChange = (value: string) => {
    onChange?.(id, value);
  };

  return (
    <Field
      id={id}
      className={classNames('PhoneInput', `width-${prefix?.length}`)}
    >
      <div
        className={classNames(
          'PhoneInput',
          isFocused && 'is-focused',
          phoneNumber !== '' && 'has-value',
        )}
        data-testid="phoneNumberField"
      >
        <Field.Label
          className="PhoneInput-label"
          htmlFor={id}
          id={`${id}-label`}
        >
          {label}
          {required ? <span aria-hidden="true"> *</span> : null}
        </Field.Label>
        <Field.Group
          as="div"
          className={classNames('PhoneInput-field', error && 'has-error')}
          onBlur={_onBlur}
        >
          <Listbox
            value={prefix}
            onChange={onPrefixChange}
            disabled={readOnly || disabled}
          >
            {({ open }) => (
              <div
                className={classNames(
                  { 'is-searching': open },
                  'PhoneInput-menu',
                )}
              >
                <Listbox.Button
                  className="PhoneInput-menu-button"
                  data-testid="phoneInputField-prefix"
                >
                  <span>+ {prefix}</span>
                </Listbox.Button>
                <Options countries={countries} />
                <div
                  role="status"
                  aria-live="polite"
                  aria-relevant="additions text"
                  style={{
                    border: '0px',
                    height: '1px',
                    width: '1px',
                    overflow: 'hidden',
                    padding: '0px',
                  }}
                >
                  {getA11yStatusMessage(open)}
                </div>
              </div>
            )}
          </Listbox>
          <div className="PhoneInput-input">
            <Field.Input
              className="PhoneInput-input-inner"
              data-testid="phoneInputField-input"
              defaultValue={phoneNumber}
              id={id}
              name={name}
              readOnly={readOnly}
              disabled={disabled}
              type={type}
              hidden={hidden}
              pattern="[0-9]+"
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onChange={debounce(onInputChange, WAIT_TIME)}
              onKeyDown={onInputKeyDown}
              {...props}
              {...inputAriaProps}
            />
          </div>
        </Field.Group>
        {error && typeof error === 'string' && (
          <Field.Error data-testid={`${id}-error`} className="PhoneInput-error">
            {i18n.t(error)}
          </Field.Error>
        )}
        {help && (
          <Field.Hint data-testid={`${id}-hint`} className="PhoneInput-hint">
            {help}
          </Field.Hint>
        )}
      </div>
    </Field>
  );
};

const Options = ({ countries }: { countries: CountryForSelect[] }) => {
  return (
    <Listbox.Options className="PhoneInput-options">
      {countries
        .filter(({ dialingCode }) => !!dialingCode)
        .map((country, index) => (
          <Listbox.Option key={index} value={country.dialingCode}>
            {({ active }) => (
              <div
                className={classNames('PhoneInput-option', {
                  'is-active': active,
                })}
                data-testid={`dial_${country.dialingCode}`}
              >
                <span className="PhoneNumber-option-country">
                  {country.label}
                </span>
                <span className="PhoneNumber-option-dial">
                  +{country.dialingCode}
                </span>
              </div>
            )}
          </Listbox.Option>
        ))}
    </Listbox.Options>
  );
};

export { PhoneInput };
