import { RootState } from 'reducers/types';
import { getAccessToken, isFromAgents } from 'selectors/entities';

export const getKeepSession = (state: RootState) => {
  const {
    ui: {
      authentication: { keepSession },
    },
  } = state;

  return keepSession;
};

export const isAuthenticated = (state: RootState) => {
  const token = getAccessToken(state);
  const isAuthenticated = Boolean(token);

  return isAuthenticated;
};

export const canUserPerformSecureAction = (state: RootState) => {
  return isAuthenticated(state) || isFromAgents(state);
};
