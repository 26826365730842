import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from 'selectors';
import getDisplayName from 'utils/getDisplayName';
import { useFlysiteUrls } from 'hooks/useFlysiteUrls';

const WithFlysiteUrls = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const WithFlysiteUrls = (props: P) => {
    const locale = useSelector(getCurrentLocale);
    const urls = useFlysiteUrls(locale);
    return <WrappedComponent {...props} {...urls} />;
  };

  WithFlysiteUrls.displayName = `WithFlysiteUrls(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithFlysiteUrls;
};

export { WithFlysiteUrls };
