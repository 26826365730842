import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'reducers/types';
import { getCountriesForSelect } from 'selectors';
import { FieldInterface } from '../Field';
import { PhoneInput } from 'components/input/PhoneInput/PhoneInput';

type PhoneInputFieldProps = ConnectedProps<typeof connector> & {
  field: FieldInterface;
  handleBlur?: (id: string) => void;
  handleChange?: (id: string, value?: string) => void;
};

const PhoneInputFieldComponent = ({
  countries,
  field,
  handleBlur,
  handleChange,
}: PhoneInputFieldProps) => {
  const {
    defaultValue,
    error,
    help,
    hidden,
    id,
    label,
    name,
    readOnly,
    required,
    type,
  } = field;

  return (
    <PhoneInput
      value={defaultValue}
      error={error}
      help={help}
      hidden={hidden}
      id={id}
      label={label}
      name={name}
      readOnly={readOnly}
      required={required}
      type={type}
      countries={countries}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  countries: getCountriesForSelect(state),
});

const connector = connect(mapStateToProps);

const PhoneInputField = connector(PhoneInputFieldComponent);

export { PhoneInputField };
