import { combineReducers } from 'redux';
import { FETCH_DOCUMENTS_SUCCESS, DELETE_FILE_SUCCESS } from 'constants/index';
import omit from 'lodash/omit';
import type { DocumentFile } from 'models';

type DocumentFilesAction =
  | {
      type: typeof FETCH_DOCUMENTS_SUCCESS;
      payload: {
        documents?: {
          [key: string]: {
            files: Record<string, DocumentFile>;
          };
        };
      };
    }
  | {
      type: typeof DELETE_FILE_SUCCESS;
      payload: {
        fileId: string;
      };
    };

export const byId = (
  state: Record<string, DocumentFile> = {},
  action: DocumentFilesAction,
) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      const { documents = {} } = action.payload;
      const documentId = Object.keys(documents)[0];
      const files = documentId ? documents[documentId].files : {};

      return { ...state, ...files };
    case DELETE_FILE_SUCCESS:
      const { fileId } = action.payload;
      const newFiles = omit(state, [fileId]);

      return { ...newFiles };
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: DocumentFilesAction) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      const { documents = {} } = action.payload;
      const documentId = Object.keys(documents)[0];
      const files = documentId ? documents[documentId].files : {};

      return Object.keys(files);
    case DELETE_FILE_SUCCESS:
      const { fileId } = action.payload;
      const newIds = state.filter(id => id !== fileId);

      return newIds;
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
