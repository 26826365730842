import React from 'react';
import PropTypes from 'prop-types';

function Recommended({ children }: { children: React.ReactNode }) {
  return (
    <div className="Offer-recommended" data-testid="recommended">
      {children}
    </div>
  );
}

Recommended.displayName = 'Recommended';
Recommended.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Recommended };
