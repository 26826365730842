import { combineReducers } from 'redux';
import { FETCH_RECIPIENTS_SUCCESS } from '../../../constants';
import type { Recipient } from 'models';

type RecipientsActions = {
  type: typeof FETCH_RECIPIENTS_SUCCESS;
  payload: {
    entities: {
      recipients: Record<string, Recipient>;
    };
    result: string[];
  };
};

export const byId = (
  state: Record<string, Recipient> = {},
  action: RecipientsActions,
) => {
  switch (action.type) {
    case FETCH_RECIPIENTS_SUCCESS:
      const { entities: { recipients = {} } = {} } = action.payload;

      const mergedRecipients = { ...recipients, ...state };

      return mergedRecipients;
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: RecipientsActions) => {
  switch (action.type) {
    case FETCH_RECIPIENTS_SUCCESS:
      const { result: recipientIds = [] } = action.payload;

      return [...new Set([...recipientIds, ...state])];
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
