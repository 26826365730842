import { useEffect } from 'react';
import { useWizard } from 'components/Wizard/components/context';

function PrevStep() {
  const { goToPrevStep } = useWizard();
  useEffect(() => goToPrevStep(), []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
}

PrevStep.displayName = 'PrevStep';

export { PrevStep };
