import React from 'react';
import { ReactComponent as InfoIcon } from 'icons/ico-info-blue.svg';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils';
import {
  LULU_PAYMENT_OPTIONS_WITH_EDUCATION_LOAN_REDUCED_FEE,
  MUTHOOT_PAYMENT_OPTIONS_WITH_EDUCATION_LOAN_REDUCED_FEE,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_AND_SAVINGS,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_CREDILA,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_OTHER_INSTITUTIONS,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_PUBLIC_AND_PRIVATE_SECTOR,
} from 'constants/index';

import './IndiaOfferSection.scss';

const LULU_SOURCE_OF_FUNDS_EDUCATION_LOAN_INSTITUTIONS = [
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_CREDILA,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_PUBLIC_AND_PRIVATE_SECTOR,
];

const LULU_SOURCE_OF_FUNDS_EDUCATION_LOAN_SAVINGS_AND_OTHERS = [
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_AND_SAVINGS,
  OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_OTHER_INSTITUTIONS,
];

const LULU_SOURCE_OF_FUNDS_EDUCATION_LOAN = [
  ...LULU_SOURCE_OF_FUNDS_EDUCATION_LOAN_INSTITUTIONS,
  ...LULU_SOURCE_OF_FUNDS_EDUCATION_LOAN_SAVINGS_AND_OTHERS,
];

const EducationLoanNotificationMuth = () => {
  const i18n = useTranslations();

  return (
    <div
      className="IndiaOfferSection--EducationalLoan"
      dangerouslySetInnerHTML={createMarkup(
        i18n.t(
          `senderInformation.indiaOfferSection.educationLoanMuthoot.description`,
        ),
      )}
      data-testid="educationLoanInfoMuth"
    />
  );
};

const EducationLoanNotificationLulu = ({
  sourceOfFunds,
}: {
  sourceOfFunds: string;
}) => {
  const i18n = useTranslations();

  let luluMessageKey = 'savingAndOthers';

  if (
    LULU_SOURCE_OF_FUNDS_EDUCATION_LOAN_INSTITUTIONS.includes(sourceOfFunds)
  ) {
    luluMessageKey = 'institutions';
  }
  const messageKey = `senderInformation.indiaOfferSection.lulu.educationLoan.${luluMessageKey}.description`;

  return (
    <div
      className="IndiaOfferSection--EducationalLoan"
      dangerouslySetInnerHTML={createMarkup(i18n.t(messageKey))}
      data-testid="educationLoanInfoLulu"
    />
  );
};

function IndiaOfferSection({
  children,
  paymentOption,
  sourceOfFunds,
}: {
  children: React.ReactNode;
  paymentOption: string;
  sourceOfFunds: string;
}) {
  const i18n = useTranslations();

  const displayEducationLoanInfoMuth =
    sourceOfFunds === OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN &&
    MUTHOOT_PAYMENT_OPTIONS_WITH_EDUCATION_LOAN_REDUCED_FEE.includes(
      paymentOption,
    );

  const displayEducationLoanInfoLulu =
    LULU_SOURCE_OF_FUNDS_EDUCATION_LOAN.includes(sourceOfFunds) &&
    LULU_PAYMENT_OPTIONS_WITH_EDUCATION_LOAN_REDUCED_FEE.includes(
      paymentOption,
    );

  return (
    <div className="IndiaOfferSection">
      <div className="IndiaOfferSection--Header">
        <div>
          <InfoIcon aria-hidden={true} />
        </div>
        <div className="IndiaOfferSection--Content">
          <h2>{i18n.t('senderInformation.offer.title')}</h2>
          <p
            dangerouslySetInnerHTML={createMarkup(
              i18n.t('senderInformation.offer.in.description'),
            )}
          />{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.flywire.com/hc/en-us/sections/360004497359"
          >
            {i18n.t('senderInformation.offer.more')}
          </a>
        </div>
      </div>
      {children}
      {displayEducationLoanInfoMuth && <EducationLoanNotificationMuth />}
      {displayEducationLoanInfoLulu && (
        <EducationLoanNotificationLulu sourceOfFunds={sourceOfFunds} />
      )}
    </div>
  );
}

export { IndiaOfferSection };
