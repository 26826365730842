import { getPayment, getPaymentId, isFetching } from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import {
  FETCH_PROOFS_REQUEST,
  FETCH_PROOFS_SUCCESS,
  HTTPStatuses,
  FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS,
  FETCH_PROOFS_FAILURE,
  UPLOAD_PROOF_REQUEST,
  UPLOAD_PROOF_SUCCESS,
  UPLOAD_PROOF_FAILURE,
  DELETE_PROOF_FILE_SUCCESS,
  DELETE_PROOF_FILE_REQUEST,
  HttpError,
} from 'constants/index';
import { trackPaymentProof } from 'actions';
import type { PayexThunkAction } from 'store/configureStore';
import { normalizeProofs } from 'services/payments/payments';
import { isHttpErrorWithStatus } from 'utils/errors/errors';

export const fetchProofs =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingProofs = isFetching(state, 'proofs');
    const id = getPaymentId(state);

    if (isFetchingProofs) return;

    dispatch({ type: FETCH_PROOFS_REQUEST });

    const { token } = getPayment(state);
    try {
      const response = await apiV3.payments.fetchProofs({ id, token });

      return dispatch({
        type: FETCH_PROOFS_SUCCESS,
        payload: normalizeProofs(response),
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const { status } = error as HttpError;

        if (status === HTTPStatuses.NOT_FOUND)
          return dispatch({
            type: FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS,
          });
      }

      errorNotifier.notify(error);

      return dispatch({ type: FETCH_PROOFS_FAILURE });
    }
  };

export const generateProofUrl =
  (filename: string): PayexThunkAction =>
  async (_dispatch, getState, { apiV3 }) => {
    const state = getState();

    const { token } = getPayment(state);
    const id = getPaymentId(state);

    try {
      const response = await apiV3.payments.generateProofUrl({
        id,
        token,
        filename,
      });

      return response.url;
    } catch (error) {
      errorNotifier.notify(error);
    }
  };

export const uploadProof =
  (file: File, url: string): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingProofs = isFetching(state, 'proofs');

    if (isFetchingProofs) return;

    dispatch({ type: UPLOAD_PROOF_REQUEST });

    let succeed = true;

    try {
      await apiV3.payments.uploadFileToUrl({ file, url });

      dispatch({
        type: UPLOAD_PROOF_SUCCESS,
      });

      return dispatch(fetchProofs());
    } catch (error) {
      const { name } = file;
      if (isHttpErrorWithStatus(error)) {
        const _error = error as HttpError;
        const { status = '' } = _error;

        if (
          status !== HTTPStatuses.NOT_FOUND &&
          status !== HTTPStatuses.BAD_REQUEST
        ) {
          _error.message = `${UPLOAD_PROOF_FAILURE} ${_error.message}`;
          errorNotifier.notifyWithFingerprint(_error, [
            UPLOAD_PROOF_FAILURE,
            `${status}`,
          ]);
        }
      }

      succeed = false;

      return dispatch({
        type: UPLOAD_PROOF_FAILURE,
        payload: { name },
      });
    } finally {
      dispatch(trackPaymentProof(succeed));
    }
  };

export const deleteProofFile =
  (url: string, fileId: string, proofId: string): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingProofs = isFetching(state, 'proofs');

    if (isFetchingProofs) return;

    dispatch({ type: DELETE_PROOF_FILE_REQUEST });

    try {
      await apiV3.payments.deleteFile({ url });

      return dispatch({
        type: DELETE_PROOF_FILE_SUCCESS,
        payload: { fileId, proofId },
      });
    } catch (error) {
      errorNotifier.notify(error);
    }
  };
