import React from 'react';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { trackChangeCountryOfCardIssuance } from 'actions/tracker';
import { goToStep } from 'actions/ui/ui';
import { amountFrom } from 'selectors/ui/ui';
import { getSenderCountry } from 'selectors/entities';
import { Button } from 'lib/flywire-tailwind/Button';
import { Heading } from 'components/Heading/Heading';
import { Modal } from 'components/ui/Modal/Modal';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import classNames from 'classnames';
import { useTranslations } from 'utils/translations/useTranslations';
import type { Price } from 'models';

import './SurchargeModal.scss';
import { SURCHARGE_COUNTRIES_CUSTOM_WARNING } from 'constants/countries';

type PropsFromRedux = ConnectedProps<typeof connector>;
type SurchargeModalComponentProps = PropsFromRedux & {
  cardProductType: string;
  feeRate: number;
  handleAcceptSurcharge: () => void;
  handleModalClose: () => void;
  show: boolean;
};

const SurchargeModalComponent = ({
  cardProductType,
  feeRate,
  goToStep,
  handleAcceptSurcharge,
  handleModalClose,
  show,
  trackChangeCountryOfCardIssuance,
}: SurchargeModalComponentProps) => {
  const i18n = useTranslations();

  const price = useSelector(amountFrom) as Price<string>;
  const country = useSelector(getSenderCountry);
  const feeRateValue = feeRate || 0;
  const fee = price.value * feeRateValue;

  const getCountryWarning = () => {
    if (SURCHARGE_COUNTRIES_CUSTOM_WARNING.includes(country)) {
      return `surchargeModal.warning.${country.toLowerCase()}`;
    }

    return 'surchargeModal.warning';
  };

  const trackChangeCountry = () => {
    trackChangeCountryOfCardIssuance({
      productType: cardProductType,
    });
  };

  const handleChangeCountry = (
    evt: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    evt.preventDefault();
    trackChangeCountry();
    goToStep(0);
  };

  return (
    <Modal
      allowClosing={false}
      isOpen={show}
      onClose={handleModalClose}
      size="medium"
    >
      <div className="SurchargeModal" data-testid="surcharge">
        <Heading as="h2" size="large" className="fontWeight-semiBold">
          {i18n.t('surchargeModal.title')}
        </Heading>
        <div className="SurchargeModal-description">
          <p
            dangerouslySetInnerHTML={createMarkup(
              i18n.t(getCountryWarning(), {
                feePercentage: ((feeRate || 0) * 100).toFixed(2),
              }),
            )}
          />
        </div>
        <dl>
          <dt>{i18n.t('gbp.originalPaymentAmount.label')}</dt>
          <dd>
            <FormattedAmount {...price} />
          </dd>
          <dt>
            {i18n.t('gbp.internationalCardFee.label', {
              feePercentage: (feeRateValue * 100).toFixed(2),
            })}
          </dt>
          <dd>
            <FormattedAmount value={fee} currency={price.currency as string} />
          </dd>
        </dl>
        <dl>
          <dt>{i18n.t('gbp.totalPaymentAmount.label')}</dt>
          <dd className="big">
            <FormattedAmount
              value={fee + price.value}
              currency={price.currency as string}
            />
          </dd>
        </dl>
        <footer>
          <Button
            tone="primary"
            size="full"
            onClick={handleChangeCountry}
            disabled={!feeRate}
            data-testid="changeCountryBtn"
          >
            {i18n.t('surchargeModal.changeCountry')}
          </Button>
          <button
            onClick={handleAcceptSurcharge}
            className={classNames('SurchargeModal-back buttonLikeLink', {
              'SurchargeModal-back-disabled': !feeRate,
            })}
            data-testid="acceptBtn"
          >
            {i18n.t('surchargeModal.accept')}
          </button>
        </footer>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  goToStep,
  trackChangeCountryOfCardIssuance,
};

const connector = connect(null, mapDispatchToProps);
const SurchargeModal = connector(SurchargeModalComponent);

export { SurchargeModal };
