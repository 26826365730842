import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from 'icons/ico-info-blue.svg';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import type { I18n } from 'utils';

import './ChinaOfferSection.scss';

const CHINESE = 'zh-CN';

function ChinaOfferSection({
  children,
  i18n,
}: {
  children: React.ReactNode;
  i18n: I18n;
}) {
  return (
    <div className="ChinaOfferSection">
      <div className="ChinaOfferSection--Header">
        <div>
          <InfoIcon aria-hidden={true} />
        </div>
        <div className="ChinaOfferSection--Content">
          <h2>{i18n.t('senderInformation.offer.title')}</h2>
          <p
            dangerouslySetInnerHTML={createMarkup(
              i18n.t('senderInformation.offer.cn.description'),
            )}
          />{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              i18n.currentLocale === CHINESE
                ? 'https://help.flywire.com/hc/zh-cn/sections/4414481606929'
                : 'https://help.flywire.com/hc/en-us/sections/4414481606929'
            }
            data-testid="learn-more"
          >
            {i18n.t('senderInformation.offer.more')}
          </a>
        </div>
      </div>
      {children}
    </div>
  );
}

ChinaOfferSection.displayName = 'ChinaOfferSection';
ChinaOfferSection.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.shape({ t: PropTypes.func }),
};

export { ChinaOfferSection };
