import React, { PropsWithChildren } from 'react';
import { ReactComponent as InfoIcon } from 'icons/ico-info.svg';
import { ReactComponent as FilledInfoIcon } from 'icons/ico-info-blue.svg';
import classNames from 'classnames';

import './Info.scss';

function Info({
  children = null,
  className,
  ...other
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={classNames('Info', className)} {...other}>
      <span>{children}</span>
    </div>
  );
}

Info.Icon = () => {
  return <InfoIcon />;
};

Info.FilledIcon = ({ ...props }) => {
  return <FilledInfoIcon {...props} />;
};

export { Info };
