import React from 'react';

const QuestionCircle = props => {
  return (
    <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(1 1)"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M8.8 8.8a3.2 3.2 0 1 1 4.267 3.018A1.6 1.6 0 0 0 12 13.327V14.4M12 17.6a.4.4 0 1 0 0 .8.4.4 0 0 0 0-.8h0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="12" cy="12" r="12" />
      </g>
    </svg>
  );
};

export default QuestionCircle;
