import { combineReducers } from 'redux';
import { FETCH_KYC_SUCCESS } from '../../../../constants';
import type { Field } from 'models';

type KycsAction = {
  type: typeof FETCH_KYC_SUCCESS;
  payload: {
    entities: {
      fields: Record<string, Field>;
    };
  };
};

export const byId = (state: Record<string, Field> = {}, action: KycsAction) => {
  switch (action.type) {
    case FETCH_KYC_SUCCESS:
      const {
        entities: { fields = {} },
      } = action.payload;
      return fields;
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: KycsAction) => {
  switch (action.type) {
    case FETCH_KYC_SUCCESS:
      const {
        entities: { fields = {} },
      } = action.payload;
      return Object.keys(fields);
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
