import React from 'react';
import { WomanGraduate } from 'icons';
import { useTranslations } from 'utils';
import { ConnectedProps, connect } from 'react-redux';
import { getFieldValue } from 'selectors';
import type { RootState } from 'reducers/types';

import './HeaderMessage.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

const HeaderMessageComponent = ({ itemDescription }: PropsFromRedux) => {
  const i18n = useTranslations();

  if (!itemDescription) return null;

  return (
    <div className="HeaderMessage">
      <WomanGraduate className="HeaderMessage-icon" />
      <span className="HeaderMessage-prefix">{i18n.t('CAP.header')} </span>
      <strong className="HeaderMessage-name">{itemDescription}</strong>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    itemDescription: getFieldValue(state, 'item_description'),
  };
};

const connector = connect(mapStateToProps);
const HeaderMessage = connector(HeaderMessageComponent);

export { HeaderMessage };
