import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'lib/flywire-tailwind/Button';
import { Modal } from 'components/ui/Modal/Modal';
import { Heading } from 'components/Heading/Heading';
import { useFormState, useValidate } from '@flywire/react-hooks';
import { InfoCircle } from 'icons';
import { PhoneInputField } from 'components/FieldList/Field/PhoneInputField/PhoneInputField';
import { TextInputField } from 'components/FieldList/Field/TextInputField/TextInputField';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { I18n } from 'utils';
import { constraints as constraintsCV } from 'utils/clientValidate/constraints';
import { NOOP } from 'constants/index';
import type { Field } from 'models';

import './ContactInformationSection.scss';

type FormData = {
  [key: string]: string;
};

type Props = {
  fields: {
    [key: string]: Field;
  };
  i18n: I18n;
  onSubmit: () => void;
  trackEditDetails: () => void;
  trackSaveEditedDetails: () => void;
};

function ContactInformationSection({
  fields,
  i18n,
  onSubmit = NOOP,
  trackEditDetails,
  trackSaveEditedDetails,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialValues = useMemo(
    () =>
      Object.keys(fields).reduce<{ [key: string]: string }>((acc, key) => {
        const field = fields[key];
        acc[field.id] = field.defaultValue as string;
        return acc;
      }, {}),
    [fields],
  );

  const constraints = useMemo(
    () =>
      constraintsCV.generate({
        fieldOrFields: Object.keys(fields).map(key => fields[key]),
      }),
    [fields],
  );

  const { values, update, dirtyFields } = useFormState(initialValues);
  const { isValid, errors } = useValidate(values, constraints);

  function handleOpenEditDetails() {
    trackEditDetails();
    setIsModalOpen(true);
  }

  function handleOnCancel() {
    setIsModalOpen(false);
  }

  function handleOnConfirm() {
    if (Object.keys(dirtyFields).length) {
      onSubmit(values as FormData);
    }
    trackSaveEditedDetails();
    setIsModalOpen(false);
  }

  return (
    <section className="Breakdown ContactInformationSection">
      <div className="Breakdown-header" />
      <div className="Breakdown-body">
        <div className="ContactInformationSection-Info">
          <InfoCircle />
        </div>
        <div className="ContactInformationSection-Content">
          <span
            dangerouslySetInnerHTML={createMarkup(
              i18n.t('paymentConfirmation.contactDetails', {
                email: fields.email.text,
                phone: fields.phone.text,
              }),
            )}
          />{' '}
          <button
            onClick={handleOpenEditDetails}
            data-testid="editContactDetails"
          >
            {i18n.t('paymentConfirmation.contactDetails.edit')}
          </button>
        </div>
      </div>

      <Modal
        allowClosing
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="medium"
      >
        <section>
          <header>
            <Heading as="h1" size="large" className="marginBottom-lg">
              {i18n.t('paymentConfirmation.contactDetails.modal.title')}
            </Heading>
          </header>
          <div className="ContactInformationSectionModal-Content">
            <div>
              <TextInputField
                handleChange={update}
                field={{
                  ...fields.email,
                  error: errors.sender_email?.[0],
                }}
              />
              <PhoneInputField
                handleChange={update}
                field={{
                  ...fields.phone,
                  error: errors.sender_phone?.[0],
                }}
              />
            </div>
            <div className="ContactInformationSectionModal-Actions">
              <Button
                tone="secondary"
                size="auto"
                data-testid="Modal-Button--cancel"
                onClick={handleOnCancel}
              >
                {i18n.t('modal.button.cancel')}
              </Button>
              <Button
                tone="primary"
                size="auto"
                data-testid="Modal-Button--confirm"
                onClick={handleOnConfirm}
                disabled={!isValid}
              >
                {i18n.t('modal.button.save')}
              </Button>
            </div>
          </div>
        </section>
      </Modal>
    </section>
  );
}

ContactInformationSection.displayName = 'ContactInformationSection';
ContactInformationSection.propTypes = {
  fields: PropTypes.shape({
    email: PropTypes.shape({
      id: PropTypes.string,
      defaultValue: PropTypes.string,
      format: PropTypes.string,
      help: PropTypes.string,
      hint: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      pattern: PropTypes.string,
    }),
    phone: PropTypes.shape({
      id: PropTypes.string,
      defaultValue: PropTypes.string,
      format: PropTypes.string,
      help: PropTypes.string,
      hint: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      pattern: PropTypes.string,
    }),
  }),
  i18n: PropTypes.shape({ t: PropTypes.func }),
  onSubmit: PropTypes.func,
  trackEditDetails: PropTypes.func,
  trackSaveEditedDetails: PropTypes.func,
};

export { ContactInformationSection };
