import { INVALID_PARAM_ERROR, TAX_ERROR } from 'constants/errors';
import { TERMS_ACCEPTED_FIELD } from 'constants/fields';
import type { RootState } from 'reducers/types';

export const getErrors = (state: RootState) => {
  const { errors: { params = [] } = {} } = state;

  return params;
};

export const getInvalidParamErrors = (state: RootState) => {
  const { errors: { byParam = {} } = {} } = state;
  const invalidParamErrors = Object.keys(byParam).reduce((acc, key) => {
    const error = byParam[key];
    if (error.type === INVALID_PARAM_ERROR) {
      // @ts-expect-error key is just a string
      acc[key] = error;
    }
    return acc;
  }, {});

  return Object.keys(invalidParamErrors);
};

export const hasTermsError = (state: RootState) => {
  const errors = getErrors(state);

  return errors.includes(TERMS_ACCEPTED_FIELD);
};

export const isThereAnErrorRetrievingTaxes = (state: RootState) => {
  const { errors: { byParam = {} } = {} } = state;
  const offerPanNumberError = byParam.offer_pan_number;

  return offerPanNumberError?.type === TAX_ERROR;
};

export const getPaymentItemError = (state: RootState) => {
  const { byParam = {} } = state.errors;

  return byParam.payment_item;
};
