export const REDIRECT_RECIPIENTS = [
  'ARZ',
  'BAZ',
  'BUH',
  'BUL',
  'CLS',
  'DEY',
  'EAR',
  'EEW',
  'EYG',
  'GCU',
  'GFY',
  'GKZ',
  'GXR',
  'HEQ',
  'JSA',
  'LRJ',
  'OLF',
  'PBA',
  'QBR',
  'QPK',
  'RCQ',
  'RGT',
  'RUL',
  'UAI',
  'UBT',
  'UBY',
  'UHS',
  'ULF',
  'UNF',
  'UOY',
  'UVL',
  'VTF',
  'WCK',
  'XAW',
  'XBA',
  'XEK',
  'YBA',
  'YCX',
  'YFC',
  'ZNP',
];
