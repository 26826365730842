import {
  INSTRUCTION_EMBEDDED,
  INSTRUCTION_REDIRECT_URL,
  InstructionsType,
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_DELIVERED,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_INITIATED,
  PAYMENT_STATUS_ON_HOLD,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_RECEIVED,
  PAYMENT_STATUS_SENT,
  PAYMENT_STATUS_VERIFICATION,
  INSTRUCTION_LRS,
  A2_FORM,
  AGENTS_PLATFORM,
  INVALID_STUDENT_CNY_CODE,
  PAYER_NOT_SUPPORTED_CODE,
  PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE,
  PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE,
} from 'constants/index';
import { getUIPaymentStatus } from '../utils';
import {
  editPaymentDetailsEnabled,
  getOffer,
  getRecipient,
  hasChineseFields,
  hasIndianFields,
  hasInstructions,
  hasPassportFields,
  isLoggedIn,
  getInstructionsType,
  hasLRSInDocumentCollection,
  isEmbedded,
} from 'selectors';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import type { RootState } from 'reducers/types';
import type { Instruction, Item, Offer, Price } from 'models';
import { ExtraFee } from 'models';

export const getPaymentPrice = (state: RootState): Price<string> => {
  const {
    entities: {
      payment: { price },
      prices,
    },
  } = state;

  return prices[price];
};

export const getPaymentPriceCurrency = (state: RootState) => {
  const { currency: priceCurrency } = getPaymentPrice(state) || {};

  return priceCurrency;
};

export const getPaymentPurchase = (state: RootState) => {
  const {
    entities: {
      payment: { purchase },
      purchases,
    },
  } = state;

  return purchases[purchase];
};

export const getPaymentPurchaseCurrency = (state: RootState) => {
  const { currency: purchaseCurrency } = getPaymentPurchase(state) || {};

  return purchaseCurrency;
};

export const getPaymentFees = (state: RootState) => {
  const {
    entities: {
      payment: { fees: paymentFees },
      fees = {},
    },
  } = state;

  return paymentFees ? fees[paymentFees] : undefined;
};

export const getPaymentInstructions = (state: RootState) => {
  const {
    entities: {
      instructions,
      payment: { instructions: instructionIds = [] },
    },
  } = state;

  return instructionIds.map(id => instructions[id]);
};

export const getPaymentLinks = (state: RootState) => {
  const {
    entities: {
      links,
      payment: { links: linkIds = [] },
    },
  } = state;

  return linkIds.map(id => links[id]);
};

export const getPayment = (state: RootState) => {
  const {
    entities: { payment },
  } = state;
  const price = getPaymentPrice(state);
  const purchase = getPaymentPurchase(state);
  const fees = getPaymentFees(state);
  const instructions = getPaymentInstructions(state);
  const links = getPaymentLinks(state);

  return { ...payment, price, purchase, fees, instructions, links };
};

export const getPaymentId = (state: RootState) => {
  const {
    entities: {
      payment: { id },
    },
  } = state;

  return id;
};

export const getPaymentToken = (state: RootState) => {
  const {
    entities: {
      payment: { token },
    },
  } = state;

  return token;
};

export const getPaymentTaxes = (state: RootState) => {
  const {
    entities: {
      payment: { taxes = [] },
    },
  } = state;

  if (taxes.length === 0) {
    return;
  }

  const value = taxes.reduce((total, tax) => total + tax.amount, 0);
  const currency = taxes[0].currency?.code;

  return { value, currency };
};

export const getAllPaymentTaxes = (state: RootState) => {
  const {
    entities: {
      payment: { taxes = [] },
    },
  } = state;

  return taxes;
};

export const getPaymentInstruction = (state: RootState, name: string) => {
  const instructions = getPaymentInstructions(state);
  const instruction = instructions.find(i => i && i.name === name);

  return instruction || null;
};

export const getPaymentLink = (state: RootState, rel: string) => {
  const links = getPaymentLinks(state);
  const link = links.find(link => link && link.rel === rel);

  return link || null;
};

export const getPaymentStatus = (state: RootState) => {
  const recipient = getRecipient(state) || {};
  const {
    entities: { payment },
  } = state;

  return getUIPaymentStatus(recipient, payment, state);
};

export const getPaymentOffer = (state: RootState) => {
  const {
    entities: {
      payment: {
        offer: { id: offerId },
      },
    },
  } = state;

  return getOffer(state, offerId);
};

export const getPaymentOfferFromPayment = (state: RootState) => {
  const {
    entities: { payment: { offer = {} } = {} },
  } = state;

  return offer as Offer;
};

export const getPaymentOptionFromPayment = (state: RootState) => {
  const {
    entities: { payment: { offer: { paymentOption } = {} } = {} },
  } = state;

  return paymentOption;
};

export const getPaymentDueAtDate = (state: RootState) => {
  const {
    entities: {
      payment: { dueAt },
    },
  } = state;

  return dueAt;
};

export const getPaymentCancelledAtDate = (state: RootState) => {
  const {
    entities: {
      payment: { statusTransitions: { cancelledAt } = {} },
    },
  } = state;

  return cancelledAt;
};

export const getPaymentDeliveredAtDate = (state: RootState) => {
  const {
    entities: {
      payment: { statusTransitions: { deliveredAt } = {} },
    },
  } = state;

  return deliveredAt;
};

export const isOnline = (state: RootState) => {
  const instructionsType = getInstructionsType(state);
  if (instructionsType === InstructionsType.BPAY) return true;

  return getPaymentInstructions(state).some((instruction: Instruction) => {
    return (
      instruction.name === INSTRUCTION_REDIRECT_URL ||
      instruction.name === INSTRUCTION_EMBEDDED
    );
  });
};

export const isPaymentBankTransfer = (state: RootState) => {
  const { offer } = getPayment(state);

  return offer?.type === 'bank_transfer';
};

export const isPaymentBankTransferWithoutInstruction = (state: RootState) => {
  return isPaymentBankTransfer(state) && !hasInstructions(state);
};

export const isPending = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_PENDING;
};

export const isCancelled = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_CANCELLED;
};

export const isVerification = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_VERIFICATION;
};

export const isWaitingForInstructions = (state: RootState) => {
  const {
    entities: {
      payment: { status },
    },
  } = state;

  return status === PAYMENT_STATUS_INITIATED && !hasInstructions(state);
};

export const getInstructionsError = (state: RootState) => {
  const {
    entities: {
      payment: { instructionsErrors },
    },
  } = state;

  const errorKeys = instructionsErrors
    ?.filter(
      ({ reason }) =>
        reason.code === INVALID_STUDENT_CNY_CODE ||
        reason.code === PAYER_NOT_SUPPORTED_CODE,
    )
    .map(({ reason: { code } }) => code);

  if (!errorKeys?.length) {
    return undefined;
  }

  return Array.from(new Set(errorKeys));
};

export const isDelivered = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_DELIVERED;
};

export const isReceived = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_RECEIVED;
};

export const isSent = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_SENT;
};

export const isOnHold = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_ON_HOLD;
};

export const isFailed = (state: RootState) => {
  const status = getPaymentStatus(state);

  return status === PAYMENT_STATUS_FAILED;
};

export const isFromAgents = (state: RootState) => {
  const {
    entities: {
      payment: { metadata: { isAgent = false } = {} },
    },
  } = state;

  return isAgent;
};

export const metadataIsAgent = (state: RootState) => {
  const {
    entities: {
      payment: { metadata: { isAgent = false } = {} },
    },
  } = state;

  return isAgent;
};

export const getPaymentAmount = (state: RootState) => {
  const items = get(state, 'entities.payment.recipient.items', []);
  const accumulateAmount = (total: number, item: Item) => total + item.amount;

  return items.reduce(accumulateAmount, 0);
};

export const getVerificationErrorCodes = (state: RootState) => {
  const noReasons: never[] = [];
  const reasons = get(
    state,
    'entities.payment.documentVerificationFailedReasons',
    noReasons,
  );

  return reasons.map((reason: { code: string }) => reason.code);
};

export const getSenderFieldValue = (state: RootState, field: string) => {
  const {
    entities: {
      payment: { sender: { fields = [] } = {} },
    },
  } = state;

  const senderField = fields.find(f => f.id === field);
  return senderField?.value;
};

export const getSenderName = (state: RootState) => {
  const firstName = getSenderFieldValue(state, 'first_name');
  const lastName = getSenderFieldValue(state, 'last_name');

  return {
    firstName: firstName ? (firstName as string) : undefined,
    lastName: lastName ? (lastName as string) : undefined,
  };
};

export const getRecipientFieldValue = (state: RootState, field: string) => {
  const { entities } = state;
  const fields = entities.payment?.recipient?.fields || [];

  const recipientField = fields.find(f => f.id === field);
  return recipientField ? recipientField.value : null;
};

export const getItemDescription = (state: RootState) => {
  return getRecipientFieldValue(state, 'item_description');
};

export const hasPayment = (state: RootState) => {
  const {
    entities: { payment },
  } = state;

  return !isEmpty(payment);
};

const isSentPaymentEditable = (state: RootState) => {
  if (hasIndianFields(state)) return true;
  if (hasChineseFields(state)) return isLoggedIn(state) && !isOnline(state);
  if (hasPassportFields(state)) return isLoggedIn(state) && !isOnline(state);

  return false;
};

export const isPaymentEditable = (state: RootState) => {
  if (!editPaymentDetailsEnabled(state)) return false;

  if (isSent(state)) return isSentPaymentEditable(state);

  return isOnHold(state) || isPending(state) || isVerification(state);
};

export const getCallToAction = (state: RootState) => {
  const {
    entities: {
      payment: { metadata: { returnCtaName, returnCtaUrl } = {} },
    },
  } = state;

  return returnCtaName && returnCtaUrl
    ? { name: returnCtaName, url: returnCtaUrl }
    : {};
};

export const getOnHoldComment = (state: RootState) => {
  const {
    entities: {
      payment: { onHoldComment },
    },
  } = state;

  return onHoldComment;
};

export const isPaymentLRSSubmitted = (state: RootState) => {
  const {
    entities: {
      payment: { isPaymentLRSSubmitted },
    },
  } = state;

  return !!isPaymentLRSSubmitted;
};

export const shouldShowLRS = (state: RootState) => {
  if (hasLRSInDocumentCollection(state)) {
    return false;
  }

  const a2Form = getPaymentLink(state, A2_FORM);
  const lrsInstruction = getPaymentInstruction(state, INSTRUCTION_LRS);

  return !!(a2Form || lrsInstruction);
};

export const isLRSPending = (state: RootState) => {
  if (hasLRSInDocumentCollection(state)) {
    return false;
  }

  if (!shouldShowLRS(state)) return false;
  if (!isPending(state)) return false;

  return !isPaymentLRSSubmitted(state);
};

export const getPaymentTrackingLink = (state: RootState) => {
  const {
    entities: {
      payment: { returnUrl },
    },
  } = state;

  return returnUrl;
};

export const isPaymentProofNeeded = (state: RootState) => {
  const {
    entities: {
      payment: { status },
    },
  } = state;
  if (status !== PAYMENT_STATUS_INITIATED) return false;

  const hasProofLink = !!getPaymentLink(state, 'proofs');

  return hasProofLink;
};

export const isRecurringPayment = (state: RootState) => {
  const {
    entities: {
      payment: { recurring },
    },
  } = state;

  return !!recurring;
};

export const shouldShowCashbackBanner = (state: RootState) => {
  const currencyFrom = getPaymentPriceCurrency(state);
  const currencyTo = getPaymentPurchaseCurrency(state);
  const isEmbeddedPlatform = isEmbedded(state);

  const shouldShowCashbackBanner =
    PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE.includes(currencyFrom) &&
    PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE.includes(currencyTo) &&
    !isFromAgents(state);

  return shouldShowCashbackBanner && !isEmbeddedPlatform;
};

export const getRecurringDetails = (state: RootState) => {
  const { recurringDetails } = getPayment(state);
  return recurringDetails
    ? {
        total: recurringDetails.totalInstallments,
        current: recurringDetails.currentInstallment,
      }
    : undefined;
};

export const paymentHasFXFormResponses = (state: RootState) => {
  const {
    entities: {
      payment: { metadata = {} },
    },
  } = state;

  const metadataKeys = Object.keys(metadata);

  return metadataKeys.some(key => key.startsWith('fxFeedback'));
};

export const getPaymentExtraFees = (state: RootState): ExtraFee[] => {
  const {
    entities: { payment: { extraFees = [] } = {} },
  } = state;

  if (extraFees.length === 0) {
    return [];
  }

  return extraFees;
};
