import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Spinner.scss';

interface SpinnerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: React.ReactNode;
  className?: string;
}

const Spinner = ({ children, className, ...rest }: SpinnerProps) => {
  return (
    <div
      role="status"
      aria-live="polite"
      aria-busy="true"
      className={classNames('Spinner', className)}
      data-testid="spinner"
      {...rest}
    >
      <span className="sr-only">Loading...</span>
      {children}
    </div>
  );
};

Spinner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export { Spinner };
