import React from 'react';

const Success = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      stroke="currentColor"
      width="90"
      height="90"
      {...props}
    >
      <g stroke="#3FC788" strokeWidth="3" fill="none" fillRule="evenodd">
        <circle cx="45" cy="45" r="43.5" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M61.875 33.75L38.653 58.5l-10.528-9.755"
        />
      </g>
    </svg>
  );
};

export default Success;
