import {
  AUTH_LOCALSTORAGE_KEY,
  CARD_ACTIVE_PLANS_FAILURE,
  CARD_ACTIVE_PLANS_REQUEST,
  CARD_ACTIVE_PLANS_SUCCESS,
  CHANGE_CARD_ACTIVE_PLANS_FAILURE,
  CHANGE_CARD_ACTIVE_PLANS_REQUEST,
  CHANGE_CARD_ACTIVE_PLANS_SUCCESS,
  CHANGE_USER_PASSWORD_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FETCH_EXTENDED_USER_FAILURE,
  FETCH_EXTENDED_USER_REQUEST,
  FETCH_EXTENDED_USER_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  HttpError,
  HTTPStatuses,
  INTERNAL_SERVER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  REMOVE_CREDIT_CARD_FAILURE,
  REMOVE_CREDIT_CARD_REQUEST,
  REMOVE_CREDIT_CARD_SUCCESS,
  REMOVE_NEW_CREDIT_CARD_FAILURE,
  REMOVE_NEW_CREDIT_CARD_SUCCESS,
  SENDER_EMAIL_FIELD,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  USER_EXISTS_FAILURE,
  USER_EXISTS_REQUEST,
  USER_EXISTS_SUCCESS,
} from '../../../constants';
import {
  getCurrentLocale,
  isFetching,
  autoLogoutEnabled,
  getRefreshToken,
  getFieldValue,
  isImpersonatingUser,
  isLoggedIn,
  getCaptchaResponse,
} from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import { localStorage, sessionStorage } from 'utils/storage';
import { removeLocalStorageUserKey } from 'store/storage/localStorage';
import { removeCookiesAuth } from 'store/storage';
import { users } from 'repositories';
import { cookies } from 'utils/cookies/cookies';
import type { PayexThunkAction } from '../../../store/configureStore';
import { isHttpErrorWithStatus } from 'utils/errors/errors';
import { auth } from 'services/payex/auth/auth';
import { normalizeAuthPayload } from 'actions/authentication/authentication';

type UserAttributes = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export const signupUser =
  (attributes: UserAttributes): PayexThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const isFetchingUser = isFetching(state, 'user');
    const locale = getCurrentLocale(state);
    const captchaResponse = getCaptchaResponse(state);

    if (isFetchingUser) return Promise.resolve();

    dispatch({ type: CREATE_USER_REQUEST });

    const { email, password, firstName, lastName } = attributes;
    const payload = {
      captchaResponse,
      email,
      firstName,
      lastName,
      locale,
      password,
      passwordConfirmation: password,
    };

    try {
      const { user, token } = await auth.signup(payload, locale);

      const authentication = normalizeAuthPayload(token);

      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: user,
      });

      return dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { authentication, user },
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const { status } = error as HttpError;
        if (status === HTTPStatuses.INTERNAL_SERVER_ERROR) {
          dispatch({ type: INTERNAL_SERVER_FAILURE });
        }
      }

      dispatch({
        type: CREATE_USER_FAILURE,
      });
      throw error;
    }
  };

export const updateUser =
  (
    attributes: UserAttributes & {
      passwordConfirmation?: string;
      currentPassword?: string;
    },
  ): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingUser = isFetching(state, 'user');
    const locale = getCurrentLocale(state);

    if (isFetchingUser) return Promise.resolve();

    dispatch({ type: UPDATE_USER_REQUEST });

    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      currentPassword,
    } = attributes;

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
      locale,
    };

    try {
      const response = await apiV3.users.update(payload);

      return dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
      });
      throw error;
    }
  };

export const fetchUser =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingUser = isFetching(state, 'user');

    if (isFetchingUser) return;

    dispatch({ type: FETCH_USER_REQUEST });

    try {
      const response = await apiV3.users.fetchUser();
      if (!response) return dispatch({ type: FETCH_USER_FAILURE });

      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: FETCH_USER_FAILURE,
        payload: error,
      });
    }
  };

export const cleanUserData = (): PayexThunkAction => (dispatch, getState) => {
  const state = getState();
  const isLoggingOut = isFetching(state, 'logout');
  if (isLoggingOut) return Promise.resolve();

  dispatch({ type: LOGOUT_USER_REQUEST });

  localStorage.removeItem(AUTH_LOCALSTORAGE_KEY);
  sessionStorage.removeItem(AUTH_LOCALSTORAGE_KEY);
  removeCookiesAuth();
  removeLocalStorageUserKey();

  return dispatch({ type: LOGOUT_USER_SUCCESS });
};

export const cleanUnauthorizedUserData =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const isImpersonating = isImpersonatingUser(state);

    if (!isImpersonating) dispatch(cleanUserData());
  };

export const logout =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const refreshToken = getRefreshToken(state);

    dispatch(cleanUserData());

    if (!refreshToken) return Promise.resolve();

    const payload = { refresh_token: refreshToken };

    try {
      return await apiV3.authentication.revoke(payload);
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const { status } = error as HttpError;
        if (status !== HTTPStatuses.UNAUTHORIZED) errorNotifier.notify(error);
      }
    }
  };

export const autoLogout = (): PayexThunkAction => (dispatch, getState) => {
  const state = getState();
  if (!isLoggedIn(state)) return;
  if (autoLogoutEnabled(state)) {
    return dispatch(logout());
  }
};

export const resetUserPassword =
  (userEmail: string): PayexThunkAction =>
  () => {
    return auth.resetPassword({ userEmail });
  };

export const changeUserPassword =
  ({
    password,
    resetPasswordToken,
  }: {
    password: string;
    resetPasswordToken: string;
  }): PayexThunkAction =>
  async (dispatch, _getState, { apiV3 }) => {
    await apiV3.users.changePassword({
      password,
      resetPasswordToken,
    });
    return dispatch({
      type: CHANGE_USER_PASSWORD_SUCCESS,
    });
  };

export const userExists =
  (): PayexThunkAction => async (dispatch, getState) => {
    dispatch({
      type: USER_EXISTS_REQUEST,
    });

    const state = getState();
    const email = getFieldValue(state, SENDER_EMAIL_FIELD);
    const token = cookies.read('csrf');

    const response = await users.userExists({
      email: email as string,
      token: token || undefined,
    });

    dispatch({
      type: response ? USER_EXISTS_SUCCESS : USER_EXISTS_FAILURE,
    });
  };

export const fetchPaymentMethods =
  (): PayexThunkAction =>
  async (dispatch, _getState, { wallet }) => {
    dispatch({
      type: FETCH_PAYMENT_METHODS_REQUEST,
    });

    try {
      const response = await wallet.fetchPaymentMethods();

      dispatch({
        type: FETCH_PAYMENT_METHODS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: FETCH_PAYMENT_METHODS_FAILURE });
    }
  };

export const removeNewCreditCard =
  (apiConfirmationUrl: string, uuidToken: string): PayexThunkAction =>
  async (dispatch, _getState, { wallet }) => {
    dispatch({
      type: REMOVE_CREDIT_CARD_REQUEST,
    });
    return wallet
      .removeNewCreditCard({
        apiConfirmationUrl,
        uuidToken,
      })
      .then(() =>
        dispatch({
          type: REMOVE_NEW_CREDIT_CARD_SUCCESS,
        }),
      )
      .catch(() => {
        dispatch({ type: REMOVE_NEW_CREDIT_CARD_FAILURE });
      });
  };

export const removeCreditCard =
  (token: string): PayexThunkAction =>
  async (dispatch, _getState, { wallet }) => {
    dispatch({ type: REMOVE_CREDIT_CARD_REQUEST });

    try {
      const result = await wallet.removeCreditCard(token);

      dispatch({
        type: REMOVE_CREDIT_CARD_SUCCESS,
        payload: token,
      });
      return result;
    } catch (error) {
      dispatch({ type: REMOVE_CREDIT_CARD_FAILURE });
    }
  };

export const checkCardActivePlans =
  (token: string): PayexThunkAction =>
  async (dispatch, _, { recurringReceivables }) => {
    dispatch({
      type: CARD_ACTIVE_PLANS_REQUEST,
    });

    try {
      const response = await recurringReceivables.checkCardActivePlans(token);

      dispatch({
        type: CARD_ACTIVE_PLANS_SUCCESS,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CARD_ACTIVE_PLANS_FAILURE,
      });
    }
  };

type ChangeCardActivePlansParams = {
  oldToken: string;
  newToken?: string;
  confirmUrl?: string;
  confirmToken?: string;
  sessionId?: string;
};

export const changeCardActivePlans =
  (params: ChangeCardActivePlansParams): PayexThunkAction =>
  async (dispatch, _, { recurringReceivables }) => {
    dispatch({
      type: CHANGE_CARD_ACTIVE_PLANS_REQUEST,
    });

    try {
      const result = await recurringReceivables.changeCardActivePlans(params);

      dispatch({
        type: CHANGE_CARD_ACTIVE_PLANS_SUCCESS,
      });
      return result;
    } catch (error) {
      dispatch({ type: CHANGE_CARD_ACTIVE_PLANS_FAILURE });
    }
  };

export const fetchExtendedUser =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    if (!isLoggedIn(state)) return;

    const isFetchingExtendedUser = isFetching(state, 'extendedUser');
    if (isFetchingExtendedUser) return;

    dispatch({ type: FETCH_EXTENDED_USER_REQUEST });
    try {
      const extendedUserMe = await apiV3.users.fetchExtendedUser();

      return dispatch({
        type: FETCH_EXTENDED_USER_SUCCESS,
        payload: extendedUserMe,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_EXTENDED_USER_FAILURE,
        payload: error,
      });
    }
  };
