import React from 'react';
const SEPARATOR = ',';

class AddressBuilder {
  constructor() {
    this.fullAddress = '';
  }

  basicAddress(name, websiteUrl, websiteHypertext, address, trackNobookingCta) {
    this.fullAddress = (
      <>
        {name}: {address}
      </>
    );
    if (websiteUrl) {
      const weblink = (
        <a
          href={websiteUrl}
          className="Footer-website"
          target="__blank"
          onClick={trackNobookingCta}
        >
          {websiteHypertext || websiteUrl}
        </a>
      );
      this.fullAddress = (
        <>
          <p className="Footer-addressItem">
            {name}: {weblink}
          </p>
          {address}
        </>
      );
    }
    return this;
  }

  add(detail) {
    if (!detail) return this;

    this.fullAddress = (
      <>
        {this.fullAddress}
        {SEPARATOR} {detail}
      </>
    );
    return this;
  }

  render() {
    return this.fullAddress;
  }
}

export { AddressBuilder };
