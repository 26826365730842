import React from 'react';
import { Field } from 'models/field';
import { MultipleCheckbox } from 'components/Checkbox/MultipleCheckbox';

type MultipleCheckboxFieldProps = {
  field: Field;
  handleBlur?: (name: string) => void;
  handleChange: (name: string, checked: string) => void;
};

const MultipleCheckboxField = ({
  field,
  handleBlur,
  handleChange,
}: MultipleCheckboxFieldProps) => {
  const { defaultValue, error, label, id, readOnly, required, value, name } =
    field;
  const checked =
    typeof defaultValue === 'string' ? defaultValue.split(',') : [];

  return (
    <MultipleCheckbox
      error={error}
      label={label}
      name={id}
      onBlur={() => handleBlur?.(name || id)}
      onChange={({ name, checked }) => handleChange(name, checked.join(','))}
      options={value}
      disabled={readOnly}
      checked={checked}
      required={required}
    />
  );
};

export { MultipleCheckboxField };
