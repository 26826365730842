import React, { useState, useEffect, KeyboardEvent, ReactNode } from 'react';
import { Button } from 'lib/flywire-tailwind/Button';
import { ReactComponent as Spinner } from 'icons/tube-spinner.svg';

const ENTER = 13;
const SPACE = 32;

type ConfirmationProps = {
  confirmationButtonText: ReactNode;
  onConfirm: () => void | Promise<void>;
  onReject: () => void;
  rejectionButtonText: ReactNode;
};

const Confirmation: React.FC<ConfirmationProps> = ({
  confirmationButtonText,
  onConfirm,
  onReject,
  rejectionButtonText,
}) => {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  const confirm = async () => {
    setLoading(true);
    await onConfirm();

    if (mounted) setLoading(false);
  };

  const handleKeyDown = (
    evt: KeyboardEvent<HTMLButtonElement>,
    callback: () => void,
  ) => {
    if ([ENTER, SPACE].includes(evt.keyCode)) {
      callback();
    }
  };

  return (
    <div className="flex items-center justify-end gap-8">
      <Button
        tone="secondary"
        disabled={loading}
        className="min-w-36"
        data-testid="rejectButton"
        onMouseDown={onReject}
        onKeyDown={(evt: KeyboardEvent<HTMLButtonElement>) =>
          handleKeyDown(evt, onReject)
        }
      >
        {rejectionButtonText}
      </Button>
      <Button
        tone="primary"
        className="min-w-36"
        data-testid="confirmButton"
        onMouseDown={confirm}
        onKeyDown={(evt: KeyboardEvent<HTMLButtonElement>) =>
          handleKeyDown(evt, confirm)
        }
      >
        {loading ? (
          <Spinner className="h-8 w-8 text-white" data-testid="spinner" />
        ) : (
          <span>{confirmationButtonText}</span>
        )}
      </Button>
    </div>
  );
};

export { Confirmation };
