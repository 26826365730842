import { normalize } from 'normalizr';
import { decamelizeKeys } from 'humps';
import {
  ADD_ORDER_TO_USER_REQUEST,
  ADD_ORDER_TO_USER_SUCCESS,
  ADD_ORDER_TO_USER_FAILURE,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  EMPTY_ERROR_FIELDS,
  CREATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  PERMANENT_COOKIE_NAME,
  INVALID_PARAM_ERROR,
  MAINTENANCE_MODE,
  HTTPStatuses,
  RESET_ORDER,
  ADD_COUPON_REQUEST,
  ADD_COUPON_FAILURE,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_FAILURE,
  ADD_REDEEM_CASHBACK_REQUEST,
  ADD_REDEEM_CASHBACK_FAILURE,
  DELETE_REDEEM_CASHBACK_REQUEST,
  DELETE_REDEEM_CASHBACK_FAILURE,
} from 'constants/index';
import {
  getCurrentLocale,
  getFieldByEntity,
  getFieldById,
  getFieldValue,
  getItems,
  getOrder,
  getOrderOffer,
  getOrderSectionFieldValue,
  getPayouts,
  getRecipient,
  isEmbedded,
  isFetching,
  isReadOnlyOrderSectionField,
  isThereAnErrorRetrievingTaxes,
  shouldHideUserManagement,
} from 'selectors';
import { enableFeature, setFieldValue, taxErrorNotification } from 'actions';
import { cookies } from 'utils/cookies/cookies';
import isEmpty from 'lodash/isEmpty';
import schemas from 'constants/schemas';
import url from 'utils/url';
import { errorNotifier } from 'utils/errorNotifier';
import { extraFees } from 'services/payex/extraFees/extraFees';
import { COUPON_CODE } from 'constants/coupons';
import type { NormalizedEntity, RootState } from 'reducers/types';
import type { Item, Price } from 'models';
import type {
  CreateOrderRequestBody,
  OfferEntityFields,
  OrderRequestBody,
  OrderServiceError,
  RecipientEntityFields,
} from 'services/apiV3/orders/types';
import type { PayexDispatch, PayexThunkAction } from 'store/configureStore';
import type { Field, FieldEntityType } from 'models/field';
import type { Dispatch } from 'redux';

type APIError = {
  parsedBody?: { errors?: OrderServiceError[] };
  status: number;
};

const ORDER_CREATION_ENTITIES: FieldEntityType[] = ['sender', 'recipient'];

const alreadyFetchingOrder = () =>
  Promise.reject(new Error('Already fetching order...'));

const hasOrderValueChanged = (field: Field, state: RootState) => {
  const orderFieldValue = getOrderSectionFieldValue(field.id, state);

  if (!orderFieldValue) return !!field.defaultValue;

  return field.defaultValue !== orderFieldValue;
};

const editableFields = (field: Field) => !field.apiReadOnly;

const normalizeEntity = (
  fields: NormalizedEntity<Field>,
  state: RootState,
  recreateOrder = false,
) => {
  const getFieldsById = (fieldId: string) => getFieldById(state, fieldId);

  const filledFields = (field: Field) =>
    hasOrderValueChanged(field, state) || !!field.defaultValue;

  const normalizeFields = (field: Field) => ({
    id: field.name,
    value: field.defaultValue,
  });
  let normalizedFields = fields.ids.map(getFieldsById).filter(filledFields);

  if (!recreateOrder) {
    normalizedFields = normalizedFields.filter(editableFields);
  }

  const finalFields = normalizedFields.map(normalizeFields);

  return finalFields.length ? { fields: finalFields } : {};
};

export const entitiesPayload = (
  entityOrEntities: FieldEntityType | FieldEntityType[],
  state: RootState,
  isUpdate = false,
  recreateOrder = false,
) => {
  const fingerprint = cookies.read(PERMANENT_COOKIE_NAME) || undefined;
  const entities = Array.isArray(entityOrEntities)
    ? entityOrEntities
    : new Array(entityOrEntities);

  const payload = entities.reduce((acc, entity) => {
    const fields = getFieldByEntity(state, entity);
    const normalizedPayload = normalizeEntity(fields, state, recreateOrder);

    if (entity === 'sender') {
      if (!isUpdate || !isReadOnlyOrderSectionField(state, 'sender_country')) {
        const selectedCountry = getFieldValue(state, 'sender_country');
        const countryField = { id: 'country', value: selectedCountry };
        const { fields = [] } = normalizedPayload;
        normalizedPayload.fields = [...fields, countryField];
      }

      const areTermsAccepted = !!getFieldValue(state, 'are_terms_accepted');
      if (areTermsAccepted) {
        const termsField = {
          id: 'are_terms_accepted',
          value: areTermsAccepted,
        };
        normalizedPayload.fields = normalizedPayload.fields || [];
        normalizedPayload.fields.push(termsField);
      }
    }

    if (entity === 'offers') {
      const offerValue = getFieldValue(state, 'offer');
      (normalizedPayload as OfferEntityFields).id = offerValue as string;
    }

    if (entity === 'recipient') {
      const items = recreateOrder
        ? getItems(state)
        : getItems(state).filter(editableFields);

      const itemsPayload = items.map(item => {
        return { id: item.id, amount: Number(getFieldValue(state, item.id)) };
      });

      if (itemsPayload.length)
        (normalizedPayload as RecipientEntityFields).items = itemsPayload;
    }

    return {
      ...acc,
      ...{ [entity === 'offers' ? 'offer' : entity]: normalizedPayload },
      fingerprint,
    };
  }, {} as OrderRequestBody);

  const locale = getCurrentLocale(state);
  const provider = getFieldValue(state, 'provider');
  if (provider) payload.provider = provider as string;

  if (isEmbedded(state)) {
    const callbackUrl = getFieldValue(state, 'callback_url');
    const callbackVersion = getFieldValue(state, 'callback_version');

    const hasCallbackUrl = callbackUrl && (callbackUrl as string).length > 0;
    const isCallbackV2 = callbackVersion === '2';

    const statusChangeCallback = {
      id: getFieldValue(state, 'callback_id') as string,
      ...(hasCallbackUrl ? { url: callbackUrl as string } : {}),
      ...(isCallbackV2 ? { version: '2' } : {}),
    };

    payload.callback = statusChangeCallback;
  }

  return {
    ...payload,
    locale,
  };
};

const unexpectedError = (error: APIError, dispatch: PayexDispatch) => {
  dispatch({ type: CREATE_ORDER_FAILURE, payload: {} });
  throw error;
};

const paymentInformationError = (
  error: { parsedBody?: { errors?: OrderServiceError[] } },
  dispatch: PayexDispatch,
) => {
  const { parsedBody: { errors } = {} } = error;
  const payload = normalize(errors, schemas.arrayOfErrors);

  dispatch({ type: CREATE_ORDER_FAILURE, payload });
  throw error;
};

const removeInvalidFieldsFromPayload = (
  errors: OrderServiceError[] = [],
  payload: CreateOrderRequestBody,
) => {
  const newPayload = { ...payload };
  const COUNTRY_SOURCE = '/sender/fields/country';
  const { sender, recipient, offer } = newPayload;

  const omitCountry = (error: OrderServiceError) =>
    error.source !== COUNTRY_SOURCE;
  const fieldsToRemove = errors.filter(omitCountry).map(error => error.param);

  const isValid = (field: Field) => !fieldsToRemove.includes(field.id);
  const newSenderFields = sender.fields.filter(isValid);
  const newRecipientFields = recipient.fields
    ? recipient.fields.filter(isValid)
    : [];
  const newOfferFields = offer?.fields ? offer.fields.filter(isValid) : [];

  newPayload.sender.fields = newSenderFields;
  newPayload.recipient.fields = newRecipientFields;
  if (newPayload.offer && !isEmpty(offer)) {
    newPayload.offer.fields = newOfferFields;
  }

  return newPayload;
};

const createOrderRetry =
  (error: APIError, payload: CreateOrderRequestBody): PayexThunkAction =>
  (dispatch, _getState, { apiV3 }) => {
    const { parsedBody: { errors } = {} } = error;

    dispatch({
      type: EMPTY_ERROR_FIELDS,
      payload: normalize(errors, schemas.arrayOfErrors),
    });

    return apiV3.orders
      .create(removeInvalidFieldsFromPayload(errors, payload))
      .then(response => {
        dispatch({ type: CREATE_ORDER_SUCCESS, payload: response });
      })
      .catch(error => {
        unexpectedError(error, dispatch);
      });
  };

export const createOrder =
  (recreateOrder = false): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isOfferSelected = !!getFieldValue(state, 'offer');
    const orderCreationEntities = [...ORDER_CREATION_ENTITIES];
    if (isOfferSelected) orderCreationEntities.push('offers');

    const payload = entitiesPayload(
      orderCreationEntities,
      state,
      false,
      recreateOrder,
    );

    const payouts = getPayouts(state);
    if (!isEmpty(payouts)) payload.payables = payouts;

    const recipientId = 'recipient';
    if (payload.recipient) {
      payload.recipient.id = getFieldValue(state, recipientId) as string;
    }

    const callbackId = getFieldValue(state, 'callback_id');
    const callbackUrl = getFieldValue(state, 'callback_url');
    const callbackVersion = getFieldValue(state, 'callback_version');

    if (callbackId || callbackUrl || callbackVersion) {
      payload.callback = {
        ...(callbackId && { id: callbackId as string }),
        ...(callbackUrl && { url: callbackUrl as string }),
        ...(callbackVersion && { version: callbackVersion as string }),
      };
    }

    const returnUrl = getFieldValue(state, 'return_url');
    if (returnUrl) payload.return_url = returnUrl as string;
    if (recreateOrder) {
      const currentOrder = getOrder(state);
      if (currentOrder?.metadata) {
        const { trackingUrl, ...restMetadata } = currentOrder.metadata;
        payload.metadata = decamelizeKeys(restMetadata) as Record<
          string,
          string
        >;
      }
    }

    dispatch({ type: CREATE_ORDER_REQUEST });

    return apiV3.orders
      .create(payload)
      .then(response =>
        dispatch({ type: CREATE_ORDER_SUCCESS, payload: response }),
      )
      .catch((error: APIError) => {
        if (error.status === 503) {
          return dispatch(enableFeature(MAINTENANCE_MODE));
        }

        const { parsedBody: { errors } = {} } = error;
        const isValidationError = (error: OrderServiceError) =>
          error.type === INVALID_PARAM_ERROR;
        const validationErrors = errors?.filter(isValidationError);
        const hasNotValidationErrors = validationErrors?.length === 0;

        const isRecipient = (error: OrderServiceError) =>
          error.param === 'recipient';
        const recipientError = validationErrors?.find(isRecipient);
        if (recipientError) {
          dispatch({ type: CREATE_ORDER_FAILURE, payload: recipientError });
          throw error;
        }

        const paymentInformationFields = ['country', 'items', 'amount'];
        const isPaymentInformation = (error: OrderServiceError) =>
          error.param && paymentInformationFields.includes(error.param);
        const paymentInformationErrors =
          validationErrors?.filter(isPaymentInformation);

        const hasPaymentInformationErrors = !!paymentInformationErrors?.length;

        if (hasNotValidationErrors) return unexpectedError(error, dispatch);

        if (hasPaymentInformationErrors)
          return paymentInformationError(error, dispatch);

        return dispatch(createOrderRetry(error, payload));
      });
  };

export const recreateOrder = (): PayexThunkAction => dispatch => {
  dispatch(setFieldValue('offer', ''));
  return dispatch(createOrder(true));
};

const returnUrl = (id: string, token: string, hideUserManagement: boolean) => {
  const domain = url.getCurrentUrl();
  let trackingUrl = `${domain}/tracking/${id}?token=${token}`;

  if (hideUserManagement === true)
    trackingUrl = `${trackingUrl}&hideUserManagement=true`;

  return trackingUrl;
};

export const updateOrderMetadata =
  (metadata: Record<string, string>): PayexThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const provider = getFieldValue(state, 'provider');

    return dispatch(
      updateOrder({
        ...(provider && { provider: provider as string }),
        fingerprint: cookies.read(PERMANENT_COOKIE_NAME) || undefined,
        metadata,
      }),
    );
  };

export const addExtraFees =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const order = getOrder(state);
    const offer = getOrderOffer(state);

    const currency = (offer.price as Price).currency;

    order.price = {
      currency: { code: currency },
    } as Price;

    const requestBody = decamelizeKeys(order) as Record<string, string>;

    dispatch({ type: UPDATE_ORDER_REQUEST });

    extraFees
      .add(requestBody)
      .then(response => {
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response });
      })
      .catch(error => {
        const { parsedBody: { errors: validationErrors = [] } = {} } = error;

        dispatch({
          type: UPDATE_ORDER_FAILURE,
          payload: normalize(validationErrors, schemas.arrayOfErrors),
        });
      });
  };

const updateOrder =
  (payload: Partial<OrderRequestBody>): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingOrder = isFetching(state, 'order');

    if (isFetchingOrder) return alreadyFetchingOrder();

    dispatch({ type: UPDATE_ORDER_REQUEST });

    const { id, token } = getOrder(state);
    const hideUserManagement = shouldHideUserManagement(state);
    payload.return_url = returnUrl(id, token, hideUserManagement);
    payload.metadata = {
      ...payload.metadata,
      tracking_url: returnUrl(id, token, hideUserManagement),
    };
    const recipient = getRecipient(state);

    return apiV3.orders
      .update({ orderId: id, token, ...payload, recipientId: recipient?.id })
      .then(response =>
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response }),
      )
      .catch(error => {
        if (error.status === 503) {
          return dispatch(enableFeature(MAINTENANCE_MODE));
        }

        const { parsedBody: { errors: validationErrors = [] } = {} } = error;

        error.message = `${UPDATE_ORDER_FAILURE} ${error.message}`;
        if (error.status >= HTTPStatuses.INTERNAL_SERVER_ERROR) {
          errorNotifier.notifyWithFingerprint(error, [
            UPDATE_ORDER_FAILURE,
            error.status,
          ]);
        }

        dispatch({
          type: UPDATE_ORDER_FAILURE,
          payload: normalize(validationErrors, schemas.arrayOfErrors),
        });

        if (isThereAnErrorRetrievingTaxes(getState())) {
          dispatch(taxErrorNotification());
        }

        throw error;
      });
  };

export const updateOrderWithEntities =
  (entities: FieldEntityType | FieldEntityType[]): PayexThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const payload = entitiesPayload(entities, state, true);

    return dispatch(updateOrder(payload));
  };

const paymentInformationPayload = (state: RootState) => {
  const locale = getCurrentLocale(state);

  const fingerprint = cookies.read(PERMANENT_COOKIE_NAME);

  const sender: { fields: { id: string; value: string }[] } = {
    fields: [],
  };
  if (!isReadOnlyOrderSectionField(state, 'sender_country')) {
    const selectedCountry = getFieldValue(state, 'sender_country') as string;
    sender.fields.push({ id: 'country', value: selectedCountry });
  }

  const formatItem = (item: Item) => {
    const { id } = item;
    const amount = Number(getFieldValue(state, item.id));

    return { id, amount };
  };
  const items = getItems(state);
  const itemsPayload = items.filter(editableFields).map(formatItem);
  const recipient = { items: itemsPayload };

  return { sender, recipient, locale, fingerprint };
};

export const updateOrderWithPaymentInformation =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const payload = paymentInformationPayload(state);

    return dispatch(updateOrder(payload));
  };

export const addOrderToUser =
  (): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const order = getOrder(state);
    const { id: orderId, token } = order;

    dispatch({ type: ADD_ORDER_TO_USER_REQUEST });

    return apiV3.orders
      .addOrderToUser({ orderId, token })
      .then(payload => dispatch({ type: ADD_ORDER_TO_USER_SUCCESS, payload }))
      .then(response => response.payload)
      .catch(error => {
        error.message = `${ADD_ORDER_TO_USER_FAILURE} ${error.message}`;
        if (error.status !== HTTPStatuses.UNPROCESSABLE_ENTITY) {
          errorNotifier.notifyWithFingerprint(error, [
            ADD_ORDER_TO_USER_FAILURE,
            error.status,
          ]);
        }
      });
  };

export const resetOrder = (): PayexThunkAction => dispatch => {
  dispatch({
    type: RESET_ORDER,
  });
};

export const addCoupon =
  (couponCode: string): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingOrder = isFetching(state, 'order');

    if (isFetchingOrder) return alreadyFetchingOrder();

    dispatch({ type: ADD_COUPON_REQUEST });

    const { id, token } = getOrder(state);

    return apiV3.orders
      .addCoupon({
        orderId: id,
        token,
        couponCode,
      })
      .then(response => {
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response });
        dispatch(setFieldValue(COUPON_CODE, couponCode));
      })
      .catch(error => {
        dispatch({ type: ADD_COUPON_FAILURE });

        error.message = `${ADD_COUPON_FAILURE} ${error.message}`;
        if (error.status >= HTTPStatuses.UNPROCESSABLE_ENTITY) {
          errorNotifier.notifyWithFingerprint(error, [
            ADD_COUPON_FAILURE,
            error.status,
          ]);
        }

        throw error;
      });
  };

export const deleteCoupon =
  (couponCode: string): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingOrder = isFetching(state, 'order');

    if (isFetchingOrder) return alreadyFetchingOrder();

    dispatch({ type: DELETE_COUPON_REQUEST });

    const { id, token } = getOrder(state);

    return apiV3.orders
      .deleteCoupon({
        orderId: id,
        token,
        couponCode,
      })
      .then(response => {
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response });
        dispatch(setFieldValue(COUPON_CODE, ''));
      })
      .catch(error => {
        dispatch({ type: DELETE_COUPON_FAILURE });

        error.message = `${DELETE_COUPON_FAILURE} ${error.message}`;
        if (error.status >= HTTPStatuses.UNPROCESSABLE_ENTITY) {
          errorNotifier.notifyWithFingerprint(error, [
            DELETE_COUPON_FAILURE,
            error.status,
          ]);
        }

        throw error;
      });
  };

export const addRedeemCashback =
  (): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingOrder = isFetching(state, 'order');

    if (isFetchingOrder) return alreadyFetchingOrder();

    dispatch({ type: ADD_REDEEM_CASHBACK_REQUEST });

    const { id, token } = getOrder(state);

    return apiV3.orders
      .addRedeemCashback({
        orderId: id,
        token,
      })
      .then(response => {
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response });
      })
      .catch(error => {
        dispatch({ type: ADD_REDEEM_CASHBACK_FAILURE });

        error.message = `${ADD_REDEEM_CASHBACK_FAILURE} ${error.message}`;
        if (error.status >= HTTPStatuses.UNPROCESSABLE_ENTITY) {
          errorNotifier.notifyWithFingerprint(error, [
            ADD_REDEEM_CASHBACK_FAILURE,
            error.status,
          ]);
        }
      });
  };

export const deleteRedeemCashback =
  (): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingOrder = isFetching(state, 'order');

    if (isFetchingOrder) return alreadyFetchingOrder();

    dispatch({ type: DELETE_REDEEM_CASHBACK_REQUEST });

    const { id, token } = getOrder(state);

    return apiV3.orders
      .deleteRedeemCashback({
        orderId: id,
        token,
      })
      .then(response => {
        dispatch({ type: UPDATE_ORDER_SUCCESS, payload: response });
      })
      .catch(error => {
        dispatch({ type: DELETE_REDEEM_CASHBACK_FAILURE });

        error.message = `${DELETE_REDEEM_CASHBACK_FAILURE} ${error.message}`;
        if (error.status >= HTTPStatuses.UNPROCESSABLE_ENTITY) {
          errorNotifier.notifyWithFingerprint(error, [
            DELETE_REDEEM_CASHBACK_FAILURE,
            error.status,
          ]);
        }
      });
  };
