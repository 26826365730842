import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { repository } from '../../services/tokenization/repository/repository';

function useCardTokenizationInstructions(opts = {}) {
  const mutation = useCallback((attrs = {}) => {
    return repository.cards.createInstructions(attrs);
  }, []);

  return useMutation(mutation, opts);
}

export default useCardTokenizationInstructions;
