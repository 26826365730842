import { getCountryCurrency, getFieldValue, getRecipient } from '../../index';
import type { RootState } from 'reducers/types';

const NO_CURRENCY = {};

const getCurrencyObject = (state: RootState) => {
  const {
    entities: { currencies },
  } = state;

  return currencies;
};

export const getCurrency = (state: RootState, id: string) => {
  const { byId = {} } = getCurrencyObject(state);

  return byId[id] || NO_CURRENCY;
};

export const getCurrentCurrency = (state: RootState) => {
  const recipient = getRecipient(state);
  return getCurrency(state, recipient.currency as string);
};

export const getLocalCurrencyCode = (state: RootState) => {
  const countryCode = getFieldValue(state, 'sender_country');
  const localCurrencyCode = getCountryCurrency(state, countryCode as string);

  return localCurrencyCode;
};

export const getLocalCurrency = (state: RootState) => {
  const localCurrencyCode = getLocalCurrencyCode(state);

  return getCurrency(state, localCurrencyCode);
};
