import * as Sentry from '@sentry/react';
import { BLACKLISTED_SENTRY_ERRORS } from '../constants';

function isMuted(error?: string | Error | null | unknown) {
  if (!error) return false;
  const errorMessage =
    typeof error === 'object' && 'message' in error ? error.message : error;
  return BLACKLISTED_SENTRY_ERRORS.includes(errorMessage as string);
}

function isGtagError(event: Sentry.Event) {
  const values = event?.exception?.values;

  if (values?.length) {
    const [{ stacktrace }] = values;

    if (
      stacktrace?.frames?.length &&
      stacktrace?.frames[0].filename?.includes('gtag')
    ) {
      return true;
    }
  }

  return false;
}

function isHotjarError(event: Sentry.Event) {
  const values = event?.exception?.values;

  if (values?.length) {
    const [{ stacktrace }] = values;

    if (
      stacktrace?.frames?.length &&
      stacktrace?.frames[0].filename?.includes('hotjar')
    ) {
      return true;
    }
  }

  return false;
}

function getMostRecentFetch(event: Sentry.Event) {
  const { breadcrumbs } = event;
  const isFetch = (breadcrumb: Sentry.Breadcrumb) =>
    breadcrumb.category === 'fetch';

  return breadcrumbs?.slice().reverse().find(isFetch);
}

export const beforeSend = (event: Sentry.Event, hint: Sentry.EventHint) => {
  if (isMuted(hint?.originalException)) return null;

  if (isGtagError(event)) return null;

  if (isHotjarError(event)) return null;

  const mostRecentFetch = getMostRecentFetch(event);

  if (mostRecentFetch && mostRecentFetch.data) {
    const { status_code: statusCode, url } = mostRecentFetch.data;
    const firstURLDirectory = url.split('/')[1];

    if (statusCode === 404 || statusCode === 422) {
      const fingerprint = [firstURLDirectory, statusCode];
      return { ...event, fingerprint };
    }
  }

  return event;
};
