import { ChangeEvent, FocusEvent, HTMLProps, RefObject, useState } from 'react';

export type InputProps = {
  className?: string;
  error?: string | boolean;
  forwardRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  floatingLabel?: boolean;
  hint?: string;
  label: string;
  name: string;
  value: string;
  onBlur?: (name: string) => void;
  onChange: (name: string, value: string) => void;
  onFocus?: (name: string, value: string | null) => void;
} & Omit<
  HTMLProps<HTMLInputElement | HTMLTextAreaElement>,
  'onBlur' | 'onChange' | 'onFocus' | 'value' | 'name'
>;

export const useInput = ({
  disabled = false,
  readOnly = false,
  value = '',
  onChange,
  onBlur,
  onFocus,
}: InputProps) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name } = e.target;
    setIsFocused(false);

    onBlur?.(name);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    onChange(name, value);
    setFieldValue(value);
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (disabled || readOnly) return false;

    const { name, value } = e.target;

    onFocus?.(name, value || null);

    setIsFocused(true);
  };

  return { handleBlur, handleChange, handleFocus, fieldValue, isFocused };
};
