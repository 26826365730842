import {
  FETCH_SENDER_SUCCESS,
  FETCH_SENDER_REQUEST,
  FETCH_SENDER_FAILURE,
  SET_DEFAULT_FIELD_VALUES,
  UPDATE_SENDER_EMAIL,
} from 'constants/index';
import {
  getCurrentLocale,
  isFetching,
  isBankTransferOfferSelected,
  isPayingFromIndia,
  isLuluOfferSelected,
  getCurrentCountryCode,
} from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import type { PayexThunkAction } from 'store/configureStore';
import { normalizeSenderFields } from 'services/sender';

export const fetchSender =
  (): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingSender = isFetching(state, 'sender');

    if (isFetchingSender) return Promise.resolve();

    dispatch({
      type: FETCH_SENDER_REQUEST,
    });

    const country = getCurrentCountryCode(state) as string;
    const locale = getCurrentLocale(state);
    const isBankTransfer = isBankTransferOfferSelected(state);
    const isFromIndia = isPayingFromIndia(state);
    const isFromLulu = isLuluOfferSelected(state);

    return apiV3.senders
      .fetch({ country, locale, isBankTransfer, isFromIndia, isFromLulu })
      .then(({ fields = [] }) => {
        const payload = normalizeSenderFields(fields);

        dispatch({
          type: FETCH_SENDER_SUCCESS,
          payload,
        });

        dispatch({
          type: SET_DEFAULT_FIELD_VALUES,
          payload: payload,
        });
      })
      .catch(error => {
        errorNotifier.notify(error);

        dispatch({ type: FETCH_SENDER_FAILURE });
      });
  };

export const updateSenderEmail =
  (email: string): PayexThunkAction =>
  dispatch =>
    dispatch({ type: UPDATE_SENDER_EMAIL, payload: email });
