import React from 'react';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { Option } from 'models/option';
import { VisuallyHidden } from '../../../../../../ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils';

type Props = {
  handleChange: (value: string) => void;
  required: boolean;
  selectedOption: string;
  options: Option[];
  placeholder: string;
  name: string;
  error?: string;
  dataTestId?: string;
};

const QuestionsAutocomplete = ({
  handleChange,
  options,
  placeholder,
  required,
  selectedOption,
  name,
  error,
  dataTestId,
}: Props) => {
  const i18n = useTranslations();
  return (
    <Autocomplete
      className="FeedbackQuestionsModal-Autocomplete"
      name={name}
      onChange={handleChange}
      onBlur={() => {}}
      options={options}
      data-testid={dataTestId}
      selected={selectedOption}
      required={required}
      error={error}
    >
      <Autocomplete.Input data-testid={dataTestId} placeholder={placeholder} />
      <Autocomplete.Options />
      {error && typeof error === 'string' && (
        <Autocomplete.Error className="FormGroup-feedback">
          <VisuallyHidden as="span">{placeholder}</VisuallyHidden>
          {i18n.t(error)}
        </Autocomplete.Error>
      )}
    </Autocomplete>
  );
};

export { QuestionsAutocomplete };
