import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components/Heading/Heading';
import classNames from 'classnames';

import './Box.scss';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
}

const Box = ({ children, className, title, ...other }: BoxProps) => {
  const boxClassname = classNames('Box', className);

  return (
    <div className={boxClassname} {...other}>
      {title && (
        <Heading as="h4" size="small" className="marginBottom-lg">
          {title}
        </Heading>
      )}
      <div className="Box-content">{children}</div>
    </div>
  );
};

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

export { Box };
