import { useEffect } from 'react';

interface MyWindow extends Window {
  [x: string]: unknown;
}

function GoogleTagManager({ siteId }: { siteId: string }) {
  useEffect(() => {
    (function (w: MyWindow, d: Document, s: string, l: string, i: string) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

      const f = d.getElementsByTagName(s)[0];
      const scriptId = 'gtm-script-' + i;

      if (!d.getElementById(scriptId)) {
        const j = d.createElement(s) as HTMLScriptElement;
        j.id = scriptId;
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        if (f.parentNode) f.parentNode.insertBefore(j, f);
      }
    })(window as MyWindow, document, 'script', 'dataLayer', siteId);

    const body = document.querySelector('body');
    const noscriptId = 'gtm-noscript-' + siteId;
    if (!document.getElementById(noscriptId)) {
      const noscript = document.createElement('noscript');
      noscript.id = noscriptId;
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${siteId}"
      height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager"></iframe>`;
      body?.appendChild(noscript);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export { GoogleTagManager };
