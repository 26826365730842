import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrder, isLoggedIn, getPayment } from 'selectors';
import { Navigate } from 'react-router-dom';

const NO_DELAY = 0;

class RedirectToTrackingComponent extends Component {
  static propTypes = {
    delay: PropTypes.number,
    isLoggedIn: PropTypes.bool.isRequired,
    order: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
  };

  static defaultProps = {
    delay: NO_DELAY,
  };

  state = { redirect: false };

  componentDidMount() {
    const { delay } = this.props;
    const timeout = setTimeout(() => this.setState({ redirect: true }), delay);

    this.setState({ timeout });
  }

  componentWillUnmount() {
    const { timeout } = this.state;

    clearTimeout(timeout);
  }

  render() {
    const {
      isLoggedIn,
      order: { id: orderId, token },
      payment: { id: paymentId },
    } = this.props;

    const { redirect } = this.state;

    if (isLoggedIn) {
      return redirect && paymentId && <Navigate to={`/payment/${paymentId}`} />;
    }

    return (
      redirect &&
      orderId && <Navigate to={`/tracking/${orderId}?token=${token}`} />
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
  order: getOrder(state),
  payment: getPayment(state),
});

const RedirectToTracking = connect(
  mapStateToProps,
  null,
)(RedirectToTrackingComponent);

export { RedirectToTracking };
