import React from 'react';
import { useTranslations } from 'utils';

import './TransactionError.scss';

type TransactionErrorProps = {
  className?: string;
  errorKey?: string;
  handleOnRetry: () => void;
};

const TransactionError = ({
  className,
  errorKey = 'recurring.payment.error.description',
  handleOnRetry,
}: TransactionErrorProps) => {
  const i18n = useTranslations();
  return (
    <div role="status" aria-live="polite" className={className}>
      <div className="TransactionError">
        <h3 className="failed" aria-describedby="failureMessage">
          {i18n.t('recurring.payment.error.title')}
        </h3>
        <p id="failureMessage">{i18n.t(errorKey)}</p>
        <div>
          <button onClick={handleOnRetry} className="CardOnFile-back">
            {i18n.t('recurring.payment.error.retry')}
          </button>
        </div>
      </div>
    </div>
  );
};

export { TransactionError };
