export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const FETCH_PUBLIC_KEY_FAILURE = 'FETCH_PUBLIC_KEY_FAILURE';
export const FETCH_PUBLIC_KEY_REQUEST = 'FETCH_PUBLIC_KEY_REQUEST';
export const FETCH_PUBLIC_KEY_SUCCESS = 'FETCH_PUBLIC_KEY_SUCCESS';
export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';
export const GO_TO_PREV_STEP = 'GO_TO_PREV_STEP';
export const GO_TO_STEP = 'GO_TO_STEP';
export const HIDE_USER_MANAGEMENT = 'HIDE_USER_MANAGEMENT';
export const RESTART = 'RESTART';
export const SET_FIELDS_VALUES = 'SET_FIELDS_VALUES';
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_PREFILLED_FIELDS = 'SET_PREFILLED_FIELDS';
export const SET_READONLY_FIELDS = 'SET_READONLY_FIELDS';
export const UI_NOTIFICATION_NEW = 'UI_NOTIFICATION_NEW';
export const UI_NOTIFICATION_REMOVE = 'UI_NOTIFICATION_REMOVE';
export const SET_EMBEDDED_EXPERIENCE = 'SET_EMBEDDED_EXPERIENCE';
export const UNSET_EMBEDDED_EXPERIENCE = 'UNSET_EMBEDDED_EXPERIENCE';
export const SET_GATEWAY_ORIGIN = 'SET_GATEWAY_ORIGIN';
export const SET_GATEWAY_SOURCE = 'SET_GATEWAY_SOURCE';
export const SHOW_OFFLINE_INSTRUCTIONS = 'SHOW_OFFLINE_INSTRUCTIONS';
export const SET_DOC_COLLECTOR_FINISHED = 'SET_DOC_COLLECTOR_FINISHED';
export const SET_PREFILLED_REJECT_OR_CONFIRM =
  'SET_PREFILLED_REJECT_OR_CONFIRM';
export const RESET_FIELDS_VALUES = 'RESET_FIELDS_VALUES';
export const SET_RECEIVE_SMS_NOTIFICATION = 'SET_RECEIVE_SMS_NOTIFICATION';
export const SET_RECEIVE_WHATSAPP_NOTIFICATION =
  'SET_RECEIVE_WHATSAPP_NOTIFICATION';
export const HIDE_CANCEL_PAYMENT_OPTION = 'HIDE_CANCEL_PAYMENT_OPTION';
export const SHOW_CANCEL_PAYMENT_OPTION = 'SHOW_CANCEL_PAYMENT_OPTION';
export const SET_SHOULD_AUTO_LOAD_ONLINE_SCRIPT =
  'SET_SHOULD_AUTO_LOAD_ONLINE_SCRIPT';
export const SET_KEEP_SESSION = 'SET_KEEP_SESSION';
export const MOBILE_BANNER_CLOSED_PAYMENT_ID =
  'MOBILE_BANNER_CLOSED_PAYMENT_ID';
export const FETCH_EMBEDDED = 'FETCH_EMBEDDED';
export const SET_REDIRECT_TO_REFERRER = 'SET_REDIRECT_TO_REFERRER';
export const SET_PAYMENT_PROCESS = 'SET_PAYMENT_PROCESS';
export const UNSET_PAYMENT_PROCESS = 'UNSET_PAYMENT_PROCESS';
export const SET_TRACKING_PAGE = 'SET_TRACKING_PAGE';
export const UNSET_TRACKING_PAGE = 'UNSET_TRACKING_PAGE';
export const SET_THEME = 'SET_THEME';
export const EXCHANGE_CURRENCY_REQUEST = 'EXCHANGE_CURRENCY_REQUEST';
export const EXCHANGE_CURRENCY_SUCCESS = 'EXCHANGE_CURRENCY_SUCCESS';
export const CHINA_COUNTRY_CODE = 'CN';
export const US_COUNTRY_CODE = 'US';
export const IN_COUNTRY_CODE = 'IN';
export const CANADA_COUNTRY_CODE = 'CA';
export const SET_CHAT_VISIBLE = 'SET_CHAT_VISIBLE';
export const SET_REPEAT_PAYMENT_EXPERIENCE = 'SET_REPEAT_PAYMENT_EXPERIENCE';
export const RESET_INVALID_SMS_ATTEMPT = 'RESET_INVALID_SMS_ATTEMPT';
export const SET_MAX_AMOUNT = 'SET_MAX_AMOUNT';
export const CONFIRM_TAX_MODAL = 'CONFIRM_TAX_MODAL';
export const CLOSE_DUPLICATED_TAX_PAYMENT_MODAL =
  'CLOSE_DUPLICATED_TAX_PAYMENT_MODAL';
export const CLEAN_UI_FIELD_VALUES = 'CLEAN_UI_FIELD_VALUES';
export const SAVED_SUCCESSFULLY = 'SAVE_SUCCESS';
export const REMMITANCE_BANNER_ELIGIBLE_COUNTRIES = ['US', 'GB', 'CA', 'AU'];
export const USD_CURRENCY_CODE = 'USD';
export const INR_CURRENCY_CODE = 'INR';
export const PAYEX_PLATFORM = 'payex';
export const MOBILE_PLATFORM = 'mobile';
export const INVOICING_PLATFORM = 'invoicing';
export const WIDGET_PLATFORM = 'widget';
export const AGENTS_PLATFORM = 'agents';
export const GATEWAY_PLATFORM = 'gateway';
export const EMBEDDED_PLATFORM = 'embedded';
export const VIETNAM_COUNTRY_CODE = 'VN';
export const NOTIFICATION_LOGIN_REQUIRED =
  'notifications.action_requires_login_or_signup';
