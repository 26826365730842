import React, { useEffect } from 'react';
import { LegacyRecurringPaymentOptionsContainer } from 'containers/PaymentOptions/components/LegacyRecurringPaymentOptions/LegacyRecurringPaymentOptions';
import { HolidayBanner } from 'containers/PaymentOptions/components/HolidayBanner/HolidayBanner';
import { BestPriceGuaranteed } from './components/BestPriceGuaranteed/BestPriceGuaranteed';
import { ChineseBanner } from './components/ChineseBanner/ChineseBanner';
import { FeeDisclosure } from './components/FeeDisclosure/FeeDisclosure';
import { SinglePaymentOptions } from './components/SinglePaymentOptions/SinglePaymentOptions';
import { Step } from './components/Step/Step';
import {
  PAYMENT_METHOD,
  CAPTCHA_VALIDATION_RESPONSE_TOKEN,
  US_COUNTRY_CODE,
} from 'constants/index';
import { useTranslations } from 'utils';
import { Tabs } from 'components/Tabs/Tabs';
import { connect, ConnectedProps } from 'react-redux';
import {
  fetchOffersFromOrderIfNeeded,
  fetchSender,
  setCaptchaResponse,
  trackClickInstalmentPlansTab,
  trackClickSinglePaymentsTab,
} from 'actions';
import type { RootState } from 'reducers/types';
import {
  getLegacyRecurringOffers,
  getOrderAmount,
  getRecipient,
  getRecipientCurrency,
  getRecurringOffers,
  getSenderCountry,
  getSenderCurrency,
  getSingleOffers,
  isCAP,
  isEmbedded,
  isPayingFromChina,
  isRecipientBPG,
  isSelectedOfferLegacyRecurring,
} from 'selectors';
import { RecurringPayment } from './components/RecurringPayment/RecurringPayment';
import { FeeDisclosure as CAPFeeDisclosure } from 'components/CAP/FeeDisclosure/FeeDisclosure';
import { IsCAP } from 'components/CAP/IsCAP/IsCAP';
import { IsNotCAP } from 'components/CAP/IsNotCAP/IsNotCAP';
import useCapFee from 'hooks/useCapFee';
import { formatAmount } from 'utils/money';
import isEmpty from 'lodash/isEmpty';
import './PaymentOptions.scss';
import { CouponBanner } from './components/CouponBanner/CouponBanner';

const SINGLE_TAB_INDEX = 0;
const RECURRING_TAB_INDEX = 1;

type PaymentOptionsProps = ConnectedProps<typeof connector>;

function PaymentOptionsComponent({
  amount,
  country,
  currency,
  hasBestPriceGuaranteed = false,
  hasLegacyRecurringOptions = false,
  hasRecurringOptions = false,
  hasSingleOptions = false,
  isCAP,
  isEmbedded = false,
  isPayingFromChina = false,
  isSelectedOfferLegacyRecurring = false,
  recipient,
  recipientCurrency,
  fetchSender,
  fetchOffers,
  trackClickRecurringsTab,
  trackClickSingleTab,
  setCaptchaResponse,
}: PaymentOptionsProps) {
  const i18n = useTranslations();
  const { currentLocale: locale } = i18n;
  const { id: recipientId } = recipient;
  const { Tab, TabList, TabPanel, TabPanels } = Tabs;

  const {
    refetch: capFeeRefetch,
    data: capFeeData,
    isError: capFeeIsError,
    isFetched: capFeeIsFetched,
  } = useCapFee({
    currency: currency?.code,
  });

  useEffect(() => {
    fetchSender();
  }, [fetchSender]);

  useEffect(() => {
    if (isCAP && !isEmpty(currency)) {
      capFeeRefetch();
    }
  }, [isCAP, currency, capFeeRefetch]);

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      fetchOffers();
    }

    return () => {
      didCancel = true;
    };
  }, [amount, country, recipientId, locale, fetchOffers]);

  if (isEmbedded) {
    setCaptchaResponse(CAPTCHA_VALIDATION_RESPONSE_TOKEN);
  }

  function renderOptions() {
    if (!hasSingleOptions && hasRecurringOptions) {
      return <RecurringPayment />;
    }

    if (!hasSingleOptions && hasLegacyRecurringOptions) {
      return <LegacyRecurringPaymentOptionsContainer />;
    }

    if (
      hasSingleOptions &&
      (hasLegacyRecurringOptions || hasRecurringOptions)
    ) {
      return (
        <Tabs
          className="customTabs"
          defaultActiveIndex={
            isSelectedOfferLegacyRecurring
              ? RECURRING_TAB_INDEX
              : SINGLE_TAB_INDEX
          }
        >
          <TabList>
            <Tab
              data-testid="single-payment-offers"
              onClick={trackClickSingleTab}
            >
              {i18n.t('paymentOptions.singlePayment')}
              {hasRecurringOptions && (
                <div className="SubText" data-testid="single-payment-subtext">
                  {i18n.t('paymentOptions.singlePayment.subtext', {
                    formatedAmount: formatAmount(amount, recipientCurrency),
                  })}
                </div>
              )}
            </Tab>
            <Tab
              data-testid="recurring-payment-offers"
              onClick={trackClickRecurringsTab}
            >
              {i18n.t(
                hasRecurringOptions
                  ? 'paymentOptions.paymentPlan'
                  : 'paymentOptions.recurringPayment',
              )}
              {hasRecurringOptions && (
                <div
                  className="SubText"
                  data-testid="recurring-payment-subtext"
                >
                  {i18n.t('paymentOptions.paymentPlan.subtext', {
                    formatedAmount: formatAmount(amount, recipientCurrency),
                  })}
                </div>
              )}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <StandardPaymentOptions isPayingFromChina={isPayingFromChina} />
            </TabPanel>
            <TabPanel>
              {hasRecurringOptions ? (
                <RecurringPayment />
              ) : (
                <LegacyRecurringPaymentOptionsContainer />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      );
    }

    return <StandardPaymentOptions isPayingFromChina={isPayingFromChina} />;
  }

  return (
    <Step i18n={i18n} stepName={PAYMENT_METHOD}>
      <CouponBanner />
      {hasBestPriceGuaranteed && (
        <div className="PaymentOptions-bpg">
          <BestPriceGuaranteed />
        </div>
      )}
      {renderOptions()}

      <IsCAP>
        {!capFeeIsError &&
          capFeeIsFetched &&
          !isEmpty(currency) &&
          country !== US_COUNTRY_CODE && (
            <CAPFeeDisclosure fee={formatAmount(capFeeData?.fee, currency)} />
          )}
      </IsCAP>
    </Step>
  );
}

PaymentOptionsComponent.SinglePaymentOptions = SinglePaymentOptions;
PaymentOptionsComponent.LegacyRecurringPaymentOptionsContainer =
  LegacyRecurringPaymentOptionsContainer;

const mapStateToProps = (state: RootState) => {
  const legacyRecurringOptions = getLegacyRecurringOffers(state);
  const singleOptions = getSingleOffers(state);
  const recurringOffers = getRecurringOffers(state);

  return {
    amount: getOrderAmount(state),
    country: getSenderCountry(state),
    currency: getSenderCurrency(state),
    hasBestPriceGuaranteed: isRecipientBPG(state),
    hasLegacyRecurringOptions: legacyRecurringOptions.length > 0,
    hasRecurringOptions: recurringOffers.length > 0,
    hasSingleOptions: singleOptions.length > 0,
    isEmbedded: isEmbedded(state),
    isPayingFromChina: isPayingFromChina(state),
    isSelectedOfferLegacyRecurring: isSelectedOfferLegacyRecurring(state),
    recipient: getRecipient(state),
    recipientCurrency: getRecipientCurrency(state),
    isCAP: isCAP(state),
  };
};

const mapDispatchToProps = {
  fetchOffers: fetchOffersFromOrderIfNeeded,
  fetchSender: fetchSender,
  setCaptchaResponse: setCaptchaResponse,
  trackClickRecurringsTab: trackClickInstalmentPlansTab,
  trackClickSingleTab: trackClickSinglePaymentsTab,
};

type StandardPaymentOptionsProps = {
  isPayingFromChina: boolean;
};

function StandardPaymentOptions({
  isPayingFromChina,
}: StandardPaymentOptionsProps) {
  const feeComponent = isPayingFromChina && <FeeDisclosure />;

  return (
    <>
      <HolidayBanner />
      <ChineseBanner />
      <SinglePaymentOptions />
      <IsNotCAP>{feeComponent}</IsNotCAP>
    </>
  );
}

StandardPaymentOptions.displayName = 'PaymentOptions';

const connector = connect(mapStateToProps, mapDispatchToProps);
const PaymentOptions = connector(PaymentOptionsComponent);

export { PaymentOptions };
