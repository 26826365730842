import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Accept } from 'components/Accept/Accept';
import { trackTermsOfUse } from 'actions';
import { WithFlysiteUrls } from 'components/HOC/WithFlysiteUrls/WithFlysiteUrls';
import { useTranslations } from 'utils/translations/useTranslations';

function TermsAndConditionsComponent({
  checked,
  error,
  name,
  onChange,
  privacyUrl,
  required,
  termsUrl,
  trackTermsOfUse,
}) {
  const i18n = useTranslations();

  const termsOfUseLink = (
    <a
      className="Link"
      href={termsUrl}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="termsOfUseLink"
      onClick={() => trackTermsOfUse('terms of use')}
    >
      {i18n.t('termsAndConditions.terms_of_use')}
    </a>
  );

  const privacyPolicyLink = (
    <a
      className="Link"
      href={privacyUrl}
      target="_blank"
      data-testid="privacyPolicyLink"
      rel="noopener noreferrer"
      onClick={() => trackTermsOfUse('privacy policy')}
    >
      {i18n.t('termsAndConditions.privacy_policy')}
    </a>
  );

  return (
    <Accept
      checked={checked}
      error={error && i18n.t('termsAndConditions.error')}
      onChange={onChange}
      name={name}
      required={required}
    >
      {i18n.t('termsAndConditions.terms_read')} {termsOfUseLink}{' '}
      {i18n.t('termsAndConditions.and')} {privacyPolicyLink}
    </Accept>
  );
}

TermsAndConditionsComponent.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  privacyUrl: PropTypes.string,
  required: PropTypes.bool,
  termsUrl: PropTypes.string,
  trackTermsOfUse: PropTypes.func,
};

const mapDispatchToProps = {
  trackTermsOfUse,
};

const TermsAndConditions = connect(
  null,
  mapDispatchToProps,
)(WithFlysiteUrls(WithFlysiteUrls(TermsAndConditionsComponent)));

export { TermsAndConditions };
