import { normalize } from 'normalizr';
import {
  FETCH_OFFER_FAILURE,
  FETCH_OFFER_REQUEST,
  FETCH_OFFER_SUCCESS,
  FETCH_REPEAT_PAYMENT_OFFER,
  HTTPStatuses,
  SET_DEFAULT_FIELD_VALUES,
} from 'constants/index';
import {
  getCurrentLocale,
  getRecipient,
  getPayment,
  getPaymentOption,
  getSenderCountry,
  getSenderFieldValue,
  getTotalAmount,
  isFetching,
} from 'selectors';
import schemas from 'constants/schemas';
import { errorNotifier } from 'utils/errorNotifier';
import { RecipientsService } from '../../../services/apiV3/recipients/recipients';
import type { PayexThunkAction } from 'store/configureStore';

export const fetchOfferForRepeatPayment =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const { id: recipientId } = getRecipient(state);
    const countryValue = getSenderFieldValue(state, 'country');
    const amount = getTotalAmount(state);

    const paymentOption = getPaymentOption(state);

    const locale = getCurrentLocale(state);

    const country = countryValue ? (countryValue as string) : '';

    const offers = await (apiV3.recipients as RecipientsService).fetchOffers({
      recipientId,
      country,
      amount,
      locale,
    });

    const payload = offers.find(o => o.paymentOption === paymentOption) || {};

    dispatch({ type: FETCH_REPEAT_PAYMENT_OFFER, payload });
  };

export const fetchOfferFromOrder =
  (id: string): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const { id: recipientId } = getRecipient(state);
    const country = getSenderCountry(state);

    const isFetchingOffer = isFetching(state, 'offer');
    if (isFetchingOffer) return Promise.resolve();

    dispatch({
      type: FETCH_OFFER_REQUEST,
    });

    const locale = getCurrentLocale(state);

    return (apiV3.recipients as RecipientsService)
      .fetchOffer({ id, recipientId, country, locale })
      .then(response => {
        const payload = normalize(response, schemas.offer);

        dispatch({
          type: FETCH_OFFER_SUCCESS,
          payload,
        });

        dispatch({
          type: SET_DEFAULT_FIELD_VALUES,
          payload,
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_OFFER_FAILURE });

        const { status } = error;

        if (status !== HTTPStatuses.NOT_FOUND) {
          error.message = `${FETCH_OFFER_FAILURE} ${error.message}`;
          errorNotifier.notifyWithFingerprint(error, [
            FETCH_OFFER_FAILURE,
            status,
          ]);
        }
      });
  };

export const fetchOfferFromCurrentPayment =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const {
      offer: { id },
    } = getPayment(state);

    dispatch(fetchOfferFromPayment(id));
  };

export const fetchOfferFromPayment =
  (id: string): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const { id: recipientId } = getRecipient(state);
    const countryValue = getSenderFieldValue(state, 'country');
    const isFetchingOffer = isFetching(state, 'offer');
    if (isFetchingOffer) return Promise.resolve();

    const country = countryValue ? (countryValue as string) : '';

    dispatch({
      type: FETCH_OFFER_REQUEST,
    });

    const locale = getCurrentLocale(state);

    return (apiV3.recipients as RecipientsService)
      .fetchOffer({ id, recipientId, country, locale })
      .then(response => {
        const payload = normalize(response, schemas.offer);

        dispatch({
          type: FETCH_OFFER_SUCCESS,
          payload,
        });

        dispatch({
          type: SET_DEFAULT_FIELD_VALUES,
          payload,
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_OFFER_FAILURE });
        const { status } = error;

        if (status !== HTTPStatuses.NOT_FOUND) {
          error.message = `${FETCH_OFFER_FAILURE} ${error.message}`;
          errorNotifier.notifyWithFingerprint(error, [
            FETCH_OFFER_FAILURE,
            status,
          ]);
        }
      });
  };
