import {
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_DELIVERED,
  PAYMENT_STATUS_INITIATED,
  PAYMENT_STATUS_GUARANTEED,
  PAYMENT_STATUS_PROCESSING,
  PAYMENT_STATUS_FAILED,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_RECEIVED,
  PAYMENT_STATUS_SENT,
  PAYMENT_STATUS_CAP_RECEIVED,
  RECIPIENTS_WITH_GUARANTEED_TO_DELIVERED_STATUS,
} from 'constants/index';
import {
  getPayment,
  getRecipient,
  isCardSurchargePayment,
  isCardSurchargeFlow,
  isRecipientCAP,
} from 'selectors';
import type { Payment, Recipient, SelectedRecipient } from 'models';
import type { RootState } from 'reducers/types';

const paymentStatusMapper = {
  [PAYMENT_STATUS_CANCELLED as string]: PAYMENT_STATUS_CANCELLED,
  [PAYMENT_STATUS_DELIVERED as string]: PAYMENT_STATUS_DELIVERED,
  [PAYMENT_STATUS_INITIATED as string]: PAYMENT_STATUS_PENDING,
  [PAYMENT_STATUS_GUARANTEED as string]: PAYMENT_STATUS_RECEIVED,
  [PAYMENT_STATUS_PROCESSING as string]: PAYMENT_STATUS_SENT,
  [PAYMENT_STATUS_FAILED as string]: PAYMENT_STATUS_FAILED,
};

export const getUIPaymentStatus = (
  recipient: Recipient | SelectedRecipient,
  payment: Payment,
  state: RootState,
) => {
  const { status, senderIndicatedSentAt } = payment;
  const APIStatus = paymentStatusMapper[status];
  const forCardSurcharge = payment.offer
    ? isCardSurchargeFlow(payment.offer)
    : isCardSurchargePayment(state);

  if (isInitiated(status) && senderIndicatedSentAt) return PAYMENT_STATUS_SENT;
  if (
    (isRecipientWithGuaranteedToDeliveredStatus(recipient) ||
      forCardSurcharge) &&
    isGuaranteed(status)
  )
    return PAYMENT_STATUS_DELIVERED;
  if (isRecipientCAP(recipient) && isReceived(status))
    return PAYMENT_STATUS_CAP_RECEIVED;

  return APIStatus || status;
};

export const getUIStatusFromPolledStatus = (
  status: string,
  state: RootState,
) => {
  const recipient = getRecipient(state);
  const payment = getPayment(state);
  payment.status = status;

  return getUIPaymentStatus(recipient, payment, state);
};

const isGuaranteed = (status: string) => status === PAYMENT_STATUS_GUARANTEED;
const isInitiated = (status: string) => status === PAYMENT_STATUS_INITIATED;
const isReceived = (status: string) => status === PAYMENT_STATUS_RECEIVED;
const isRecipientWithGuaranteedToDeliveredStatus = (
  recipient: Recipient | SelectedRecipient,
) => RECIPIENTS_WITH_GUARANTEED_TO_DELIVERED_STATUS.includes(recipient.id);
