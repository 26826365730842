import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'utils';

const PrinciplesList = ({
  password,
  principles: initialPrinciples,
  onChange = () => {},
}) => {
  const [principles, setPrinciples] = useState([]);
  const principlesValid = principles.every(principle => principle.isValid);
  const i18n = useTranslations();

  useEffect(() => {
    setPrinciples(
      initialPrinciples.map(principle => ({
        ...principle,
        isValid: !!principle.predicate(password),
      })),
    );
  }, [initialPrinciples, password]);

  useEffect(() => {
    onChange({ result: principlesValid });
  }, [principlesValid, onChange]);

  const principleProps = principle => {
    return {
      'aria-checked': principle.isValid,
      'data-testid': principle.key,
      ...(password && !principle.isValid && { className: 'text-danger' }),
      ...(password && principle.isValid && { className: 'text-success' }),
      role: 'radio',
      tabIndex: '-1',
    };
  };

  return (
    <ul
      aria-labelledby="password"
      className="PrinciplesList"
      data-testid="password-hints"
      id="password-hints"
      role="radiogroup"
    >
      {principles.map(principle => (
        <li key={principle.key} {...principleProps(principle)}>
          {i18n.t(principle.key)}
        </li>
      ))}
    </ul>
  );
};

PrinciplesList.defaultProps = {
  password: '',
  principles: [],
};

PrinciplesList.propTypes = {
  onChange: PropTypes.func,
  password: PropTypes.string,
  principles: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      predicate: PropTypes.func,
    }),
  ),
};

export { PrinciplesList };
