import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { HALF_A_SECOND, ONE_SECOND_IN_MILISECONDS } from 'constants/timers';
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils/translations/useTranslations';
import './Notification.scss';

const NotificationAnnounce = ({ message }: { message: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, HALF_A_SECOND);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <VisuallyHidden as="span">{message}</VisuallyHidden>,
    document.getElementById('payex-announcements')!,
  );
};

type NotificationProps = {
  as?: React.ElementType;
  message: string;
  onClose: () => void;
  opts?: Record<string, string>;
  type: 'error' | 'info' | 'success';
};

const Notification = ({
  as: Element = 'div',
  message,
  onClose,
  opts,
  type,
}: NotificationProps) => {
  const i18n = useTranslations();
  const [isAnnounced, setIsAnnounced] = useState(false);

  useEffect(() => {
    const timer = window.setTimeout(
      () => setIsAnnounced(true),
      ONE_SECOND_IN_MILISECONDS,
    );
    return () => window.clearTimeout(timer);
  }, []);

  return (
    <>
      <Element className="Notification-Wrapper">
        <div className={`Notification Notification--${type}`}>
          <div
            aria-atomic="true"
            aria-live="off"
            className="Notification-message"
            dangerouslySetInnerHTML={createMarkup(i18n.t(message, opts))}
            role="status"
            tabIndex={0}
          />
          <button onClick={onClose} aria-label="Close">
            <CloseIcon aria-hidden />
          </button>
        </div>
      </Element>

      {isAnnounced ? null : (
        <NotificationAnnounce
          message={`${i18n.t('notifications.ay11.title')}, ${i18n.t(
            message,
            opts,
          )}`}
        />
      )}
    </>
  );
};

export { Notification };
