import fetchJson from 'utils/fetchJson';
import fetch from 'services/apiV3/fetch';
import { API_URL_V3 } from 'constants/index';
import { FieldValue } from 'models/field';

export type KycResponse = {
  token?: string;
  fields?: { label: string; value: string }[];
  uploadUrl?: string;
};

export const kycs = {
  fetch: ({ id }: { id: string }) => {
    const path = `${API_URL_V3}/kycs/${id}`;

    return fetchJson<KycResponse>(path);
  },

  fetchByReference: (reference: string) => {
    const path = `${API_URL_V3}/kycs/reference/${reference}`;

    return fetchJson<KycResponse>(path);
  },

  uploadInfo: (
    id: string,
    fields: {
      label: string;
      value: FieldValue;
    }[],
  ) => {
    const path = `${API_URL_V3}/kycs/${id}`;
    const body = JSON.stringify({ fields });

    const options = { method: 'PATCH', body };

    return fetchJson<void>(path, options);
  },

  uploadDocument: (file: File, documentUrl: string) => {
    const body = new FormData();
    body.append('file', file);

    const path = documentUrl;
    const options = { method: 'POST', body };

    return fetch<void>(path, options);
  },
};
