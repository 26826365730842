import React, { useState, Fragment, useRef } from 'react';
import { Listbox } from '@headlessui/react';
import { NOOP } from '../../constants/actions';
import { Option as OptionModel } from '../../models/option';
import { Option } from './Option/Option';
import { ReactComponent as Chevron } from 'icons/chevron.svg';
import classNames from 'classnames';

const LEFT = 'Left';
const RIGHT = 'Right';

export type GetA11yStatusMessageProps = {
  isOpen: boolean;
  options: OptionModel[];
  selectedOption: Option | Record<PropertyKey, never>;
};

export type DropdownProps = {
  className?: string;
  defaultValue: string;
  getA11yStatusMessage: (props: GetA11yStatusMessageProps) => string | unknown;
  name?: string;
  onChange?: (locale: string) => void;
  options: Array<OptionModel>;
  upward?: boolean;
  render?: (props: { selectedLabel?: string }) => React.ReactNode;
};

const sameValue = (value: string, otherValue: string) =>
  value.toUpperCase() === otherValue.toUpperCase();

const getA11yStatusMsg = ({
  isOpen,
  options,
  selectedOption,
}: GetA11yStatusMessageProps) => {
  const optionsClosed = !isOpen;

  if (optionsClosed) {
    return selectedOption ? `You have selected ${selectedOption.label}` : '';
  }

  const optionCount = options.length;

  if (optionCount === 0) {
    return 'No options are available';
  }

  return `${optionCount} ${
    optionCount === 1 ? 'option is' : 'options are'
  } available, use up and down arrow keys to navigate. Press Enter key to select or Escape key to cancel.`;
};

export const Dropdown = ({
  className = '',
  defaultValue,
  getA11yStatusMessage = getA11yStatusMsg,
  name = 'dropdown',
  onChange = NOOP,
  options,
  upward = false,
  render,
}: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState(
    getSelectedOption(defaultValue).value,
  );
  const [lineUp, setLineUp] = useState(RIGHT);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);
  const getOptionsExceptSelected = () => {
    const notSelected = (option: OptionModel) =>
      !sameValue(option.value, selectedValue);

    return options.filter(notSelected);
  };

  function getSelectedOption(currentValue: string) {
    const selected = (option: OptionModel) =>
      sameValue(option.value, currentValue);

    return options.find(selected) || options[0];
  }

  const getSelectedLabel = () => {
    const selected = (option: OptionModel) =>
      sameValue(option.value, selectedValue);
    const selectedOption = options.find(selected);

    if (selectedOption) return selectedOption.label;
    if (options.length > 0) return options[0].label;
  };

  const handleOnChange = (value: string) => {
    setSelectedValue(value);

    onChange(value);
  };

  const selectLineUp = () => {
    if (!dropdownRef.current || !optionsRef.current) return;

    const dropdownlabelEnd = dropdownRef.current.getBoundingClientRect().right;
    const optionsWidth = optionsRef.current.clientWidth;
    const screenWidth = document.documentElement.clientWidth;
    const spaceToTheRight = screenWidth - dropdownlabelEnd - optionsWidth;
    const spaceToTheLeft = dropdownlabelEnd - optionsWidth;
    const isLinedLeft = spaceToTheRight < 0 && spaceToTheLeft > spaceToTheRight;

    return isLinedLeft ? LEFT : RIGHT;
  };

  const getA11yStatus = (isOpen: boolean) => {
    return getA11yStatusMessage({
      isOpen,
      options: getOptionsExceptSelected(),
      selectedOption: getSelectedOption(selectedValue),
    });
  };

  return (
    <Listbox value={selectedValue} onChange={handleOnChange}>
      {({ open }) => (
        <div
          className={classNames('group relative inline-block', className)}
          ref={dropdownRef}
          data-testid="dropdownContainer"
        >
          <Listbox.Button
            aria-label={getSelectedLabel()}
            aria-haspopup="listbox"
            className="inline-flex w-full cursor-pointer items-center justify-center gap-x-2 bg-transparent tracking-normal text-gray-950"
            aria-expanded={open}
            onClick={() => setLineUp(selectLineUp() || RIGHT)}
            data-testid="dropdownButton"
          >
            {render
              ? render({ selectedLabel: getSelectedLabel() })
              : getSelectedLabel()}
            <Chevron
              className={classNames('h-2.5 w-2.5 text-gray-950', {
                'rotate-180': open,
              })}
              aria-hidden
            />
          </Listbox.Button>

          <Listbox.Options
            className={classNames(
              'dropdown-menu absolute right-0 z-10 mt-2 origin-top-right list-none rounded py-3 ring-1 ring-black ring-opacity-5 focus:outline-none',
              upward
                ? 'w-56 bg-white shadow-md'
                : 'w-full bg-gray-200 md:w-56 md:bg-white md:shadow-md',
              {
                'bottom-full mb-2 mt-0 origin-bottom-right':
                  upward && lineUp === RIGHT,
              },
              {
                'bottom-full left-3/4 mb-2 mt-0 origin-bottom-left':
                  upward && lineUp === LEFT,
              },
            )}
            id={`${name}-options`}
            ref={optionsRef}
          >
            {getOptionsExceptSelected().map((option, index) => (
              <Listbox.Option
                key={`option-${index}`}
                value={option.value}
                as={Fragment}
              >
                {({ active }) => (
                  <Option
                    data-label={option.label}
                    id={`${name}-option-${index}`}
                    isActive={active}
                    key={`option-${index}`}
                    {...option}
                  />
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
          <div
            id={`${name}-a11y-status-message`}
            role="status"
            aria-live="polite"
            aria-relevant="additions text"
            className="sr-only"
          >
            {getA11yStatus(open)}
          </div>
        </div>
      )}
    </Listbox>
  );
};
