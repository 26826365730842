import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { trackHeapEvent } from 'actions/analytics/heapAgent/heapAgent';
import {
  createOrder,
  fetchUserPayments,
  setFieldValue,
  setValidationErrors,
  trackFieldsErrors,
  trackFillAmount,
  trackSelectCountry,
  updateOrderWithPaymentInformation,
} from 'actions';
import {
  entityHasErrors,
  getCurrentCurrency,
  getErrors,
  getFieldById,
  getFieldValue,
  getItemById,
  getItemCount,
  getMaxAmount,
  getRecipient,
  getRecipientCountriesForSelect,
  getTotalAmount,
  hasOrder,
  isApiReadOnly,
  isEmbedded,
  isReadOnly,
  isRecurringRecipient,
  isSingleItemEnabled,
} from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import { clientValidate } from 'utils/clientValidate/clientValidate';
import { WithTranslations } from 'components/HOC/WithTranslations/WithTranslations';
import { WithFocusOnError } from 'components/HOC/WithFocusOnError/WithFocusOnError';
import { Heading } from 'components/Heading/Heading';
import classNames from 'classnames';
import { PaymentItems } from 'components/PaymentItems/PaymentItems';
import { Navigation } from 'components/Navigation/Navigation';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import { Fetching } from 'components/Fetching/Fetching';
import { InvalidRecipient } from 'components/InvalidRecipient/InvalidRecipient';
import { CountrySelection } from './CountrySelection/CountrySelection';
import { formatAmount } from 'utils/money';
import { TermsAndConditions } from 'components/TermsAndConditions/TermsAndConditions';
import { PaymentItemSelector } from 'components/PaymentItemSelector/PaymentItemSelector';
import { useWizard } from 'components/Wizard/components/context';
import {
  AMOUNT_FIELD,
  PAYMENT_ITEM,
  SENDER_COUNTRY_FIELD,
  TERMS_ACCEPTED_FIELD,
} from 'constants/fields';
import { SET_PAYMENT } from 'constants/index';
import { IsCAP } from 'components/CAP/IsCAP/IsCAP';
import { Testimony } from 'components/CAP/Testimony/Testimony';
import type { RootState } from 'reducers/types';
import type { Field } from 'models';
import { I18n } from 'utils';

import './PaymentInformation.scss';

const noop = function () {};

type PropsFromRedux = ConnectedProps<typeof connector>;

type PaymentInformationProps = PropsFromRedux & {
  focusOnFirstError: () => void;
  i18n: I18n;
};

const PaymentInformationComponent = ({
  amount,
  areTermsAccepted,
  countries,
  country,
  createOrder,
  currency,
  focusOnFirstError,
  getErrors,
  getFieldValue,
  hasOrder,
  i18n,
  isEmbedded,
  isReadOnly,
  isRecipientInvalid,
  isRecurringRecipient,
  isSingleItemEnabled,
  itemCount,
  maxAmount,
  recipient,
  selectedItem,
  setFieldValue,
  setValidationErrors,
  totalAmount,
  trackFieldsErrors,
  trackFillAmount,
  trackHeapEvent,
  trackSelectCountry,
  updateOrderWithPaymentInformation,
}: PaymentInformationProps) => {
  const wizard = useWizard();

  const constraints = {
    [AMOUNT_FIELD]: {
      numericality: {
        greaterThan: 0,
        ...(maxAmount ? { lessThanOrEqualTo: maxAmount } : {}),
        notGreaterThan: `^${i18n.t('form.errors.required')}`,
        notLessThanOrEqualTo: `^${i18n.t(
          'paymentInformation.error.maxAmountExceeded',
          { amount: formatAmount(maxAmount, currency) },
        )}`,
      },
    },
    [SENDER_COUNTRY_FIELD]: {
      presence: {
        allowEmpty: false,
        message: `^${i18n.t('form.errors.required')}`,
      },
    },
    ...(isSingleItemEnabled && {
      [PAYMENT_ITEM]: {
        presence: {
          allowEmpty: false,
          message: `^${i18n.t('form.errors.required')}`,
        },
      },
    }),
    ...(isEmbedded && {
      [TERMS_ACCEPTED_FIELD]: {
        inclusion: {
          within: [true],
          message: `^${i18n.t('termsAndConditions.error')}`,
        },
      },
    }),
  };

  const handleChange = (name: string, value) => {
    setFieldValue(name, value);
  };

  const handlePaymentItemsChange = (name: string, value) => {
    setFieldValue(name, value);
    if (name !== AMOUNT_FIELD) setFieldValue(AMOUNT_FIELD, totalAmount());
  };

  const handleSelectedPaymentItemChange = (name, value) => {
    setFieldValue(value, totalAmount());
    setFieldValue(PAYMENT_ITEM, value);
  };

  const handleTermsChange = (termsValue: boolean) => {
    setFieldValue(TERMS_ACCEPTED_FIELD, termsValue);
  };

  const validateFields = async (
    fields: Field[],
    constraints: Record<string, unknown>,
  ) => {
    try {
      await clientValidate.async(fields, constraints);
    } catch (errors) {
      setValidationErrors(errors, ['amount']);
      trackFieldsErrors();
      throw errors;
    }
  };

  const handleNextClick = () => {
    const amount = totalAmount();
    const senderCountry = getFieldValue(SENDER_COUNTRY_FIELD);
    const fieldsToValidate = {
      amount,
      sender_country: senderCountry,
      [TERMS_ACCEPTED_FIELD]: areTermsAccepted,
      [PAYMENT_ITEM]: selectedItem?.id,
    };
    const orderAction = hasOrder
      ? updateOrderWithPaymentInformation
      : createOrder;

    const trackEvents = () => {
      trackFillAmount();
      trackSelectCountry();
      trackHeapEvent('country_selected', {
        country: getFieldValue(SENDER_COUNTRY_FIELD),
        amount: getFieldValue('amount'),
      });
    };

    return validateFields(fieldsToValidate, constraints)
      .then(orderAction)
      .then(trackEvents)
      .then(wizard.goToNextStep)
      .catch(focusOnFirstError);
  };

  const handleBlur = (name: string) => {
    const value = getFieldValue(name);
    const fieldConstraints = { [name]: constraints[name] };

    validateFields({ [name]: value }, fieldConstraints).catch(noop);
  };

  const handlePaymentItemsBlur = () => {
    const amountConstraints = { [AMOUNT_FIELD]: constraints[AMOUNT_FIELD] };

    validateFields({ [AMOUNT_FIELD]: totalAmount() }, amountConstraints).catch(
      noop,
    );
  };

  const computeFormattedTenThousandString = () => {
    const config = { ...currency, symbol: null };

    if (!currency.subunitToUnit) {
      errorNotifier.notify(
        new Error('Currency should not have a null subunitToUnit'),
      );
      return null;
    }

    const tenThousand = 10000 * currency.subunitToUnit;

    return formatAmount(tenThousand, config);
  };

  const getAmountHint = () => {
    const { code, pluralName } = currency;
    const { name } = recipient;

    const formattedAmount = computeFormattedTenThousandString();

    return formattedAmount
      ? i18n.t('paymentInformation.amount.formatHint', {
          formattedAmount,
          currency: code,
          pluralCurrency: pluralName,
        })
      : i18n.t('paymentInformation.amount.hint', {
          recipient: name,
          currency: pluralName,
        });
  };

  if (isRecipientInvalid) return <InvalidRecipient />;

  const navigation = (
    <Navigation
      hidePrev
      onClickNext={handleNextClick}
      errors={getErrors()}
      nextStepName={i18n.t('stepsList.offers')}
    />
  );

  const hasOneItem = itemCount === 1;
  const useMultiColumn = hasOneItem && !isSingleItemEnabled;

  return (
    <>
      <Fetching entity="order" />
      <PaymentStep
        navigation={navigation}
        stepName={SET_PAYMENT}
        title={i18n.t('paymentInformation.title')}
      >
        <div
          className={classNames('FlexGrid FlexGrid--fromMedium-2', {
            multiColumn: useMultiColumn,
          })}
        >
          <div className="PaymentInformation-column">
            <Heading
              as="h2"
              size="medium"
              className="PaymentInformation-heading"
              id="paymentInformation-country-msg"
            >
              {i18n.t('paymentInformation.country.title')}
            </Heading>
            <div className="PaymentInformation-input">
              <CountrySelection
                error={country.error}
                label={i18n.t('country.label')}
                name="sender_country"
                onBlur={handleBlur}
                onChange={handleChange}
                countries={countries}
                readOnly={isReadOnly('sender_country')}
                required
                value={country.defaultValue}
                data-testid="countrySelection"
              />
            </div>
          </div>
          <div className="PaymentInformation-column">
            {isSingleItemEnabled ? (
              <PaymentItemSelector
                amountHint={getAmountHint()}
                amountError={amount.error}
                onAmountBlur={handlePaymentItemsBlur}
                onAmountChange={handlePaymentItemsChange}
                onPaymentItemBlur={handleBlur}
                onPaymentItemChange={handleSelectedPaymentItemChange}
              />
            ) : (
              <>
                <Heading
                  as="h2"
                  size="medium"
                  className="PaymentInformation-heading"
                  id="paymentInformation-amount-msg"
                >
                  {i18n.t('paymentInformation.amount.title', {
                    recipient: recipient.name,
                  })}
                </Heading>
                <div className="PaymentInformation-input">
                  <PaymentItems
                    error={amount.error}
                    onBlur={handlePaymentItemsBlur}
                    onChange={handlePaymentItemsChange}
                    hint={getAmountHint()}
                  />
                </div>
              </>
            )}
            {isRecurringRecipient && (
              <div className="my-6 flex items-center gap-2.5 rounded border bg-gray-200 p-3 text-xs">
                <div className="inline-flex items-center rounded-sm bg-blue-600 px-1.5 py-0.5 font-medium uppercase text-white ring-1 ring-blue-600">
                  {i18n.t(
                    'paymentInformation.itemSelection.recurring.disclaimer.label',
                  )}
                </div>
                <div className="text-gray-700">
                  {i18n.t(
                    'paymentInformation.itemSelection.recurring.disclaimer.body',
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {isEmbedded && (
          <div className="marginTop-lg" data-testid="termsAndConditions">
            <TermsAndConditions
              defaultChecked={areTermsAccepted}
              onChange={handleTermsChange}
            />
          </div>
        )}
      </PaymentStep>

      <IsCAP>
        <Testimony />
      </IsCAP>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    amount: getFieldById(state, 'amount'),
    areTermsAccepted: !!getFieldValue(state, 'are_terms_accepted'),
    countries: getRecipientCountriesForSelect(state),
    country: getFieldById(state, 'sender_country'),
    currency: getCurrentCurrency(state),
    getErrors: () => getErrors(state),
    getFieldValue: (name: string) => getFieldValue(state, name),
    hasOrder: hasOrder(state),
    isEmbedded: isEmbedded(state),
    isReadOnly: (name: string) =>
      isReadOnly(state, name) || isApiReadOnly(state, name),
    isRecipientInvalid: entityHasErrors(state, 'recipient'),
    isRecurringRecipient: isRecurringRecipient(state),
    isSingleItemEnabled: isSingleItemEnabled(state),
    itemCount: getItemCount(state),
    itemDescription: getFieldValue(state, 'item_description'),
    maxAmount: getMaxAmount(state),
    recipient: getRecipient(state),
    selectedItem: getItemById(state, getFieldValue(state, PAYMENT_ITEM)),
    totalAmount: () => getTotalAmount(state),
  };
};

const mapDispatchToProps = {
  createOrder,
  fetchUserPayments,
  setFieldValue,
  setValidationErrors,
  trackFieldsErrors,
  trackFillAmount,
  trackHeapEvent,
  trackSelectCountry,
  updateOrderWithPaymentInformation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const PaymentInformation = connector(
  WithFocusOnError(WithTranslations(PaymentInformationComponent)),
);

export { PaymentInformation };
