import { SupportedLocalesKeys } from 'utils';
import {
  bestPriceGuaranteeUrl,
  contactUrl,
  cookiePolicyUrl,
  flysiteUrl,
  privacyUrl,
  recurringTermsUrl,
  supportUrl,
  termsUrl,
} from 'utils/flysiteUrls/flysiteUrls';

export const useFlysiteUrls = (locale: SupportedLocalesKeys) => {
  const urls = {
    bestPriceGuaranteeUrl: bestPriceGuaranteeUrl(locale),
    contactUrl: contactUrl(locale),
    cookiePolicyUrl: cookiePolicyUrl(locale),
    flysiteUrl: flysiteUrl(locale),
    privacyUrl: privacyUrl(locale),
    recurringTermsUrl: recurringTermsUrl(locale),
    supportUrl: supportUrl(locale),
    termsUrl: termsUrl(locale),
  };

  return urls;
};
