import { decamelizeKeys } from 'humps';
import fetchJson from 'utils/fetchJson';

export type CreatePreOrderPayload = {
  currencyFrom: string;
  currencyTo: string;
  productType: string;
  remitter?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    pinCode?: string;
    city?: string;
    state?: string;
    country?: string;
  };
  beneficiary?: {
    student?: {
      firstName?: string;
      lastName?: string;
    };
  };
};

export type CreatePreOrderResponse = {
  url: string;
};

export type PreOrdersService = {
  create: (payload: CreatePreOrderPayload) => Promise<CreatePreOrderResponse>;
};

const preOrders: PreOrdersService = {
  create: (payload: CreatePreOrderPayload) => {
    const path = `${process.env.STUDENT_EXPENSES_URL}/preorders`;
    const body = JSON.stringify(decamelizeKeys(payload));

    return fetchJson(path, { method: 'POST', body });
  },
};

export default preOrders;
