import { RootState } from 'reducers/types';

export const getExtendedUser = (state: RootState) => {
  const { entities: { extendedUser } = {} } = state;

  return extendedUser;
};

export const isUserManagedByAgent = (state: RootState): boolean => {
  const extendedUser = getExtendedUser(state);

  return extendedUser?.isFromAgent ?? false;
};

export const userIsARecurrentPayer = (state: RootState) => {
  const extendedUser = getExtendedUser(state);

  return extendedUser?.isRecurrentPayer ?? false;
};
