import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'components/input/TextInput/TextInput';
import { PrinciplesList } from './PrinciplesList/PrinciplesList';

import './PasswordInput.scss';

const MIN_LENGTH_REGEXP = /^.{8,}/;
const DIGIT_REGEXP = /\d+/;
const UPPER_AND_LOWER_CASE_REGEXP = /(?=.*[a-z])(?=.*[A-Z])/;
const SPECIAL_CHARS_REGEXP = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;
const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach(fn => fn && fn(...args));
const PRINCIPLES = [
  {
    key: 'password.principles.length',
    predicate: password => password.match(MIN_LENGTH_REGEXP),
  },
  {
    key: 'password.principles.upper_and_lower_case',
    predicate: password => password.match(UPPER_AND_LOWER_CASE_REGEXP),
  },
  {
    key: 'password.principles.special_chars',
    predicate: password => password.match(SPECIAL_CHARS_REGEXP),
  },
  {
    key: 'password.principles.digit',
    predicate: password => password.match(DIGIT_REGEXP),
  },
];

function PasswordInput({
  principles = PRINCIPLES,
  onChange = () => {},
  error,
  ...rest
}) {
  const [password, setPassword] = React.useState();
  const [isValid, setIsValid] = React.useState(true);

  return (
    <div className="PasswordInput">
      <TextInput
        {...rest}
        aria-describedby="password-hints"
        type="password"
        onChange={callAll(onChange, (_name, value) => setPassword(value))}
        error={error || (password && !isValid)}
        data-testid="password"
      />
      <PrinciplesList
        password={password}
        principles={principles}
        onChange={({ result }) => setIsValid(result)}
      />
    </div>
  );
}

PasswordInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  password: PropTypes.string,
  principles: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      predicate: PropTypes.func,
    }),
  ),
};

export { PasswordInput, PRINCIPLES };
