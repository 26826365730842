import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autoLogout } from 'actions';
import { hasGatewayOrigin } from 'selectors';
import { Spinner } from 'components/Spinner/Spinner';
import type { RootState } from 'reducers/types';

class AutologoutComponent extends React.Component {
  static propTypes = {
    autoLogout: PropTypes.func.isRequired,
    children: PropTypes.node,
    hasGatewayOrigin: PropTypes.bool.isRequired,
  };

  static defaultProps = { location: { search: '' } };

  state = { isFetching: true, isRecipientInvalid: false };

  async componentDidMount() {
    const { autoLogout, hasGatewayOrigin } = this.props;

    if (hasGatewayOrigin) await autoLogout();

    this.setState({ isFetching: false });
  }

  render() {
    const { children } = this.props;
    const { isFetching } = this.state;

    return isFetching ? <Spinner /> : children;
  }
}

const mapStateToProps = (state: RootState) => ({
  hasGatewayOrigin: hasGatewayOrigin(state),
});

const mapDispatchToProps = { autoLogout };

const Autologout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutologoutComponent);

export { Autologout };
