import {
  FETCH_ORDER_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  HTTPStatuses,
  HttpError,
} from '../../../constants/index';
import { getOrder, isFetching } from '../../../selectors';
import { errorNotifier } from 'utils/errorNotifier';
import type { OrderService } from '../../../services/apiV3/orders/orders';
import type { PayexThunkAction } from 'store/configureStore';
import { isHttpErrorWithStatus } from 'utils/errors/errors';

type FetchOrderParameters = {
  orderId: string;
  token: string;
};

export const fetchOrder =
  ({ orderId, token }: FetchOrderParameters): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const order = getOrder(state);
    if (order && order.id) return Promise.resolve(order);

    const isFetchingOrder = isFetching(state, 'order');
    if (isFetchingOrder) return Promise.resolve();

    dispatch({ type: FETCH_ORDER_REQUEST });

    return (apiV3.orders as OrderService)
      .fetch({ orderId, token })
      .then(response =>
        dispatch({ type: FETCH_ORDER_SUCCESS, payload: response }),
      )
      .catch((error: Error) => {
        if (isHttpErrorWithStatus(error)) {
          const { status = '' } = error as HttpError;

          dispatch({ type: FETCH_ORDER_FAILURE });

          if (status !== HTTPStatuses.NOT_FOUND) {
            error.message = `${FETCH_ORDER_FAILURE} ${error.message}`;
            errorNotifier.notifyWithFingerprint(error, [
              FETCH_ORDER_FAILURE,
              `${status}`,
            ]);
          }
        }
      });
  };
