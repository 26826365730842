import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accept } from 'components/Accept/Accept';
import { WithFlysiteUrls } from 'components/HOC/WithFlysiteUrls/WithFlysiteUrls';
import { getCurrentLocale, hasTermsError, isOfferAstropay } from 'selectors';
import { setTermsErrors, trackTermsOfUse } from 'actions';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils/translations/useTranslations';
import type { FetchResponse } from 'utils/fetchJson/fetchJson';
import type { RootState } from 'reducers/types';

type TermsAndConditionsProps = {
  defaultChecked?: boolean;
  hasTermsError: boolean;
  isOfferAstropay?: boolean;
  isRecurring?: boolean;
  onChange?: (termsValue: boolean) => void;
  privacyUrl: string;
  recurringTermsUrl: string;
  setTermsErrors: () => void;
  termsUrl: string;
  trackTermsOfUse: (link: string) => Promise<FetchResponse>;
};

function TermsAndConditionsComponent({
  defaultChecked,
  hasTermsError,
  isOfferAstropay,
  isRecurring = false,
  onChange = () => {},
  privacyUrl,
  recurringTermsUrl,
  setTermsErrors,
  termsUrl,
  trackTermsOfUse,
}: TermsAndConditionsProps) {
  const i18n = useTranslations();
  const checked = defaultChecked || false;
  const [areTermsAccepted, setAreTermsAccepted] = useState(checked);

  const handleTermsChange = () =>
    areTermsAccepted ? rejectTerms() : acceptTerms();

  const acceptTerms = () => triggerTerms();

  const rejectTerms = () => {
    triggerTerms();
    setTermsErrors();
  };

  const triggerTerms = () => {
    setAreTermsAccepted(!areTermsAccepted);
    onChange(!areTermsAccepted);
  };

  const termsOfUse = (
    <a
      className="Link"
      href={termsUrl}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="termsOfUseLink"
      onClick={() => trackTermsOfUse('terms of use')}
    >
      {i18n.t('termsAndConditions.terms_of_use')}
    </a>
  );

  const recurringTermsOfUse = (
    <a
      className="Link"
      href={recurringTermsUrl}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="recurringTermsOfUseLink"
      onClick={() => trackTermsOfUse('recurring terms of use')}
    >
      {i18n.t('termsAndConditions.recurring_terms_of_use')}
    </a>
  );

  const privacyPolicy = (
    <a
      className="Link"
      href={privacyUrl}
      target="_blank"
      data-testid="privacyPolicyLink"
      rel="noopener noreferrer"
      onClick={() => trackTermsOfUse('privacy policy')}
    >
      {i18n.t('termsAndConditions.privacy_policy')}
    </a>
  );

  const astropayTC = (
    <span
      data-testid="astropay-terms"
      dangerouslySetInnerHTML={createMarkup(
        i18n.t('termsAndConditions.partners.astropay'),
      )}
    />
  );

  const recurringAppendix = (
    <>
      {i18n.t('termsAndConditions.comma_and')} {recurringTermsOfUse}
    </>
  );

  return (
    <Accept
      checked={areTermsAccepted}
      error={hasTermsError && i18n.t('termsAndConditions.error')}
      name="terms-conditions"
      onChange={handleTermsChange}
      required
    >
      {i18n.t('termsAndConditions.terms_read')} {termsOfUse}{' '}
      {i18n.t('termsAndConditions.and')} {privacyPolicy}
      {isRecurring && recurringAppendix}
      {isOfferAstropay && astropayTC}
    </Accept>
  );
}

TermsAndConditionsComponent.propTypes = {
  defaultChecked: PropTypes.bool,
  hasTermsError: PropTypes.bool.isRequired,
  isOfferAstropay: PropTypes.bool,
  isRecurring: PropTypes.bool,
  onChange: PropTypes.func,
  privacyUrl: PropTypes.string.isRequired,
  recurringTermsUrl: PropTypes.string.isRequired,
  setTermsErrors: PropTypes.func.isRequired,
  termsUrl: PropTypes.string.isRequired,
  trackTermsOfUse: PropTypes.func.isRequired,
};

const mapStateToProps = (state: RootState) => ({
  hasTermsError: hasTermsError(state),
  isOfferAstropay: isOfferAstropay(state),
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {
  setTermsErrors,
  trackTermsOfUse,
};

const TermsAndConditions = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithFlysiteUrls(TermsAndConditionsComponent));

export { TermsAndConditions };
