import { PaymentWithEntities } from 'models/payment';

const callbackPayment = (payment: PaymentWithEntities) => {
  const {
    value,
    currency: { code },
  } = payment.price;
  const formattedPrice = { value, currency: code };

  const formattedPayment = {
    reference: payment.id,
    price: formattedPrice,
    status: payment.status,
    instructions: null,
    instructionsType: null,
    trackingNumber: null,
  };

  return formattedPayment;
};

export { callbackPayment };
