import { getUIPaymentStatus } from '../utils/payment/payment';
import { getRecipientById } from '../recipients/recipients';
import { getRecipientId, isRecipientCAP } from '../recipient/recipient';
import { PaymentWithEntities } from 'models/payment';
import {
  PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE,
  PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_GIC,
  PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE,
  PAYMENT_STATUS_DELIVERED,
} from 'constants/payment';
import { Price } from 'models/price';
import { Currency } from 'models/currency';
import { Purchase } from 'models/purchase';
import type { RootState } from 'reducers/types';

export const getPayments = (state: RootState) => {
  const {
    entities: { payments },
  } = state;

  return payments.map(payment => {
    const { id, purchase, recipient, createdAt, offer } = payment;
    const UIStatus = getUIPaymentStatus(recipient, payment, state);
    let recipientWithAllInfo = getRecipientById(state, recipient.id);

    if (isRecipientCAP(recipient)) {
      const { name, metadata } = recipientWithAllInfo || {};
      recipientWithAllInfo = { ...recipient, name, metadata };
    }

    return {
      id: id,
      status: UIStatus,
      purchase: purchase,
      recipient: recipientWithAllInfo,
      createdAt: createdAt,
      offer: offer,
      hasRefunds: !!getPaymentLink(payment, 'refunds'),
    };
  });
};

export const getRecipientsIds = (state: RootState) => {
  const {
    entities: { payments },
  } = state;
  const ids = payments.map(payment => payment.recipient.id);
  const idsWithoutDuplicates = Array.from(new Set(ids));

  return idsWithoutDuplicates;
};

export const getPaymentById = (state: RootState, id: string) => {
  const {
    entities: { payments },
  } = state;

  return payments.find(payment => payment.id === id);
};

export const getLastMatchingPaymentFromRecipient = (state: RootState) => {
  const recipientId = getRecipientId(state);

  const {
    entities: { payments },
  } = state;

  return payments.find(payment => payment.recipient.id === recipientId);
};

const getPaymentLink = (payment: PaymentWithEntities, linkRel: string) => {
  return payment.links && payment.links.find(link => link.rel === linkRel);
};

export const canDuplicatePayment = (state: RootState, paymentId: string) => {
  const payment = getPayments(state).find(({ id }) => id === paymentId);

  if (!payment) {
    return false;
  }

  const {
    recipient: {
      metadata: {
        gatewayOnly = { enabled: false },
        clientPrepopulated = false,
        onlyRecurring = false,
      } = {},
    } = {},
    status,
  } = payment;

  if (gatewayOnly.enabled || clientPrepopulated || onlyRecurring) return false;

  return status === PAYMENT_STATUS_DELIVERED;
};

const lastRemittancePayments = (state: RootState) => {
  const {
    entities: { payments },
  } = state;
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  return payments.filter(
    payment => new Date(payment.createdAt) > threeMonthsAgo,
  );
};

export const arePaymentsEligibleForRemittance = (state: RootState) => {
  const lastPayments = lastRemittancePayments(state);

  return lastPayments.some(payment => {
    return (
      PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE.includes(
        ((payment.price as Price)?.currency as Currency)?.code,
      ) &&
      PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE.includes(
        ((payment.purchase as Purchase)?.currency as Currency)?.code,
      )
    );
  });
};

export const arePaymentsEligibleForGIC = (state: RootState) => {
  const lastPayments = lastRemittancePayments(state);

  return lastPayments.some(payment => {
    return (
      PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE.includes(
        ((payment.price as Price)?.currency as Currency)?.code,
      ) &&
      PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_GIC.includes(
        ((payment.purchase as Purchase)?.currency as Currency)?.code,
      )
    );
  });
};

export default getPayments;
