import React, { useEffect, useCallback } from 'react';
import { cookies } from 'utils/cookies/cookies';
import geolocation from 'services/geolocation';
import { PERMANENT_COOKIE_NAME, NOOP } from 'constants/index';
import { Nothing } from 'components/Nothing/Nothing';

declare global {
  interface Window {
    adaSettings: {
      adaReadyCallback: () => void;
    };
    adaEmbed: {
      setMetaFields: (metaFields: Record<string, string>) => void;
    };
  }
}

type AdaProps = {
  onLoad: () => void;
  onShow: () => void;
};

function Ada({ onLoad = NOOP, onShow = NOOP }: AdaProps) {
  const handleScriptLoad = useCallback(async () => {
    const fingerprint = cookies.read(PERMANENT_COOKIE_NAME) || '';
    const location = await geolocation.fetch();
    window.adaEmbed.setMetaFields({
      current_country: location.countryCode,
      fingerprint: fingerprint,
    });
    onLoad();
  }, [onLoad]);

  async function injectSnippet() {
    window.adaSettings = {
      adaReadyCallback: () => {
        onShow();
      },
    };

    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('id', '__ada');
    script.onload = handleScriptLoad;
    script.setAttribute('data-handle', 'flywire');
    script.setAttribute('src', 'https://static.ada.support/embed2.js');

    document.body.appendChild(script);
  }

  useEffect(() => {
    injectSnippet();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Nothing />;
}

export { Ada };
