import { schema } from 'normalizr';
import { API_URL_V3 } from './config';
import { errorNotifier } from 'utils/errorNotifier';
import createEntity from 'factories/createEntity';
import { Error, Link, Field } from 'models';

const normalizeDomain = (link: Link) => {
  const newHref = API_URL_V3 + link.href.split(API_URL_V3)[1];
  return { ...link, href: newHref };
};

const currencySchema = new schema.Entity('currencies', undefined, {
  idAttribute: 'code',
});

const sectionSchema = new schema.Entity('sections', undefined, {
  mergeStrategy: (entityA, entityB) => ({
    ...entityA,
    ...entityB,
    fields: [...(entityA.fields || []), ...(entityB.fields || [])],
  }),
});

const fieldsProcessStrategy = (
  field: Field,
  parent: { [key: string]: unknown } & { sections: string[] },
  key: string,
  entity = '',
) => {
  const updatedField = {
    ...field,
    name: field.id,
    id: entity !== 'recipient' ? `${entity}_${field.id}` : field.id,
  };

  // field.id = entity !== 'recipient' ? `${entity}_${field.id}` : field.id;

  const { viewOptions, ...restField } = updatedField;

  const sectionId =
    entity !== 'sender' && entity !== 'recipient'
      ? `${entity}_${viewOptions?.sectionIdentifier}`
      : viewOptions?.sectionIdentifier;

  const section = {
    id: sectionId,
    name: viewOptions?.sectionName,
    description: viewOptions?.sectionDescription,
    fields: [updatedField.id],
  };

  if (
    Object.prototype.hasOwnProperty.call(parent, 'sections') &&
    !parent.sections.includes(sectionId as string)
  ) {
    parent.sections.push(sectionId as string);
  }

  return { ...restField, ...viewOptions, section };
};

const offerFieldsSchema = new schema.Entity(
  'fields',
  {
    section: sectionSchema,
  },
  {
    processStrategy: (field, parent, key) => {
      const entity = 'offer';
      return fieldsProcessStrategy(field, parent, key, entity);
    },
    idAttribute: value => {
      return `offer_${value.id}`;
    },
  },
);

const fieldSchema = new schema.Entity(
  'fields',
  {
    section: sectionSchema,
  },
  {
    processStrategy: (field, parent, key) => {
      const entity = parent.entity || 'sender';
      return fieldsProcessStrategy(field, parent, key, entity);
    },
    idAttribute: (value, parent) => {
      const entity = parent.entity || 'sender';
      return entity !== 'recipient' ? `${entity}_${value.id}` : value.id;
    },
  },
);

const KYCFieldsProcessStrategy = (field: Field) => {
  field.id = field.label || '';
  field.name = field.label;
  field.type = 'string';
  field.required = true;
  return field;
};

const KYCFieldSchema = new schema.Entity(
  'fields',
  {},
  {
    idAttribute: 'id',
    mergeStrategy: (entityA, entityB) => ({
      ...entityA,
      ...entityB,
      fields: [...(entityA.fields || []), ...(entityB.fields || [])],
    }),
    processStrategy: KYCFieldsProcessStrategy,
  },
);

const itemSchema = new schema.Entity('items', undefined, {
  processStrategy: item => {
    return { ...item, name: 'items' };
  },
});

const priceSchema = new schema.Entity('prices', {
  currency: currencySchema,
});

const offerSchema = new schema.Entity(
  'offers',
  {
    price: priceSchema,
    fields: [offerFieldsSchema],
  },
  {
    processStrategy: offer => {
      const { price = {} } = offer;
      const hasFields = Object.prototype.hasOwnProperty.call(offer, 'fields');
      const newPrice = createEntity(price);

      return {
        ...offer,
        price: newPrice,
        entity: 'offer',
        ...(hasFields && { sections: [] }),
      };
    },
  },
);

const recipientSchema = new schema.Entity(
  'recipients',
  {
    currency: currencySchema,
    fields: [fieldSchema],
    items: [itemSchema],
  },
  {
    processStrategy: recipient => {
      return { ...recipient, sections: [], entity: 'recipient' };
    },
  },
);

const recipientsArrayItemSchema = new schema.Entity('recipients', undefined, {
  idAttribute: 'id',
});

const countrySchema = new schema.Entity(
  'countries',
  {
    currency: currencySchema,
  },
  {
    idAttribute: 'code',
  },
);

const filesSchema = (name: string) =>
  new schema.Entity(name, undefined, {
    idAttribute: 'fileId',
    processStrategy: file => {
      const { links } = file;

      const newLinks = links.map((link: Link) => {
        if (link.method === 'GET' && link.rel === 'download') {
          return normalizeDomain(link);
        }

        if (link.method === 'DELETE' && link.rel === 'delete') {
          return normalizeDomain(link);
        }

        return link;
      });

      return {
        ...file,
        links: newLinks,
      };
    },
  });

const proofFilesSchema = filesSchema('proofFiles');

const proofSchema = new schema.Entity(
  'proofs',
  {
    files: [proofFilesSchema],
  },
  {
    idAttribute: 'proofId',
  },
);

const purchaseSchema = new schema.Entity('purchases', {
  currency: currencySchema,
});

const feeSchema = new schema.Entity('fees', {
  currency: currencySchema,
});

const instructionSchema = new schema.Entity('instructions', undefined, {
  idAttribute: 'name',
});

const linkSchema = new schema.Entity('links', undefined, {
  idAttribute: 'rel',
});

const paymentSchema = new schema.Entity(
  'payments',
  {
    price: priceSchema,
    purchase: purchaseSchema,
    fees: feeSchema,
    instructions: [instructionSchema],
    links: [linkSchema],
  },
  {
    processStrategy: payment => {
      const { price, purchase, fees, links = [] } = payment;
      const newLinks = links.map((link: Link) => {
        if (
          link.method === 'POST' ||
          (link.method === 'GET' && link.rel === 'sms_notification')
        ) {
          return normalizeDomain(link);
        }

        return link;
      });

      return {
        ...payment,
        price: createEntity(price),
        purchase: createEntity(purchase),
        fees: createEntity(fees),
        links: newLinks,
      };
    },
  },
);

const amountSchema = new schema.Entity('amounts', {
  currency: currencySchema,
});

const refundSchema = new schema.Entity(
  'refunds',
  {
    amount: amountSchema,
    amountTo: amountSchema,
  },
  {
    processStrategy: refund => {
      const { amount, amountTo } = refund;
      return {
        ...refund,
        amount: createEntity(amount),
        amountTo: createEntity(amountTo),
      };
    },
  },
);

const errorSchema = new schema.Entity('errors', undefined, {
  idAttribute: error => {
    const { source } = error;
    let entity;

    try {
      entity = source.split('/')[1];
    } catch (e) {
      errorNotifier.notify(source);
    }

    const isAmountError = (error: Error) => error.source === '/recipient/items';
    const isPayableAmountError = (error: Error) =>
      error.source === '/payables' && error.param === 'amount';
    const fieldId =
      entity !== 'recipient' ? `${entity}_${error.param}` : error.param;

    return isAmountError(error) || isPayableAmountError(error)
      ? 'amount'
      : fieldId;
  },
});

const smsNotificationSchema = new schema.Entity('smsNotification', undefined, {
  idAttribute: 'id',
});

export default {
  arrayOfCountries: [countrySchema],
  arrayOfProofs: [proofSchema],
  arrayOfErrors: [errorSchema],
  arrayOfFields: [fieldSchema],
  arrayOfKYCFields: [KYCFieldSchema],
  arrayOfOffers: [offerSchema],
  arrayOfRecipients: [recipientsArrayItemSchema],
  arrayOfRefunds: [refundSchema],
  offer: offerSchema,
  payment: paymentSchema,
  recipient: recipientSchema,
  smsNotification: smsNotificationSchema,
};
