import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'icons/close-icon.svg';
import FlywireIcon from 'icons/FlywireIcon';

import './Popover.scss';

const Popover = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  children,
}: {
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  subtitle?: string;
  children: ReactNode | ReactNode[];
}) => {
  return (
    <Wrapper isOpen={isOpen}>
      <CloseButton handleClose={handleClose} />
      <Title text={title} />
      <Subtitle text={subtitle} />
      <Content>{children}</Content>
    </Wrapper>
  );
};

function Wrapper({
  children,
  isOpen,
}: {
  isOpen: boolean;
  children?: ReactNode | ReactNode[];
}) {
  const TooltipClassNames = classNames('PopoverWrapper', {
    'PopoverWrapper--hidden': !isOpen,
  });

  return (
    <div className={`${TooltipClassNames}`}>
      <div className="PopoverContainer">{children}</div>
    </div>
  );
}

const CloseButton = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <button onClick={handleClose} className="PopoverClose" aria-label="Close">
      <CloseIcon aria-hidden="true" />
    </button>
  );
};

function Content({
  children,
  className,
}: {
  children: ReactNode | ReactNode[];
  className?: string;
}) {
  return <div className={className}>{children}</div>;
}

function Subtitle({ text }: { text?: string }) {
  return <div className="PopoverSubtitle">{text}</div>;
}

function Title({ text }: { text?: string }) {
  return (
    <div className="PopoverTitle">
      <FlywireIcon className="PopoverIcon" />
      {text}
    </div>
  );
}

Popover.Wrapper = Wrapper;
Popover.CloseButton = CloseButton;
Popover.Title = Title;
Popover.Subtitle = Subtitle;
Popover.Content = Content;

export { Popover };
