import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isChatVisible } from 'selectors';
import { Recipient } from 'models';
import { AddressBuilder } from './AddressBuilder';
import { Powered } from './Powered/Powered';
import { CookiesBanner } from 'components/CookiesBanner/CookiesBanner';
import wcagBadge from 'icons/wcag.svg';
import type { RootState } from 'reducers/types';

import './Footer.scss';

const renderAddress = (
  {
    name,
    address,
    address2,
    state,
    city,
    zip,
    websiteUrl,
    websiteHypertext,
  }: Pick<
    Recipient,
    'name' | 'address' | 'address2' | 'state' | 'city' | 'zip'
  > & { websiteUrl: string; websiteHypertext: string },
  trackNobookingCta: boolean,
) => {
  return new AddressBuilder()
    .basicAddress(
      name,
      websiteUrl,
      websiteHypertext,
      address,
      trackNobookingCta,
    )
    .add(address2)
    .add(city)
    .add(state)
    .add(zip)
    .render();
};

const FooterComponent = ({
  footer,
  isChatVisible,
  recipient,
  showAddress,
  trackNobookingCta,
}: {
  footer: boolean;
  isChatVisible: boolean;
  recipient: Recipient;
  showAddress: boolean;
  trackNobookingCta: () => void;
}) => {
  const contentClassName = classNames('Footer-content', {
    'with-chat': isChatVisible,
  });

  return (
    footer && (
      <>
        <footer className="Footer" data-testid="footer">
          <div className={contentClassName}>
            {showAddress && (
              <div className="Footer-address">
                {renderAddress(recipient, trackNobookingCta)}
              </div>
            )}
            <div className="Footer-copyright">
              <Powered />
              <p className="Footer-copyrightMessage">
                Copyright ©Flywire. 2009-{new Date().getFullYear()} All rights
                reserved.
                <br />
                Flywire is a trademark of Flywire Corporation.
              </p>
              <a
                href="https://certifications.onlineada.com/flywire-payex-q2-2024-certification"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={wcagBadge}
                  alt="Certified WCAG 2.1 AA Conformant"
                  className="Footer-certification"
                />
              </a>
            </div>
          </div>
        </footer>
        <CookiesBanner />
      </>
    )
  );
};

FooterComponent.defaultProps = {
  trackNobookingCta: () => {},
  recipient: {
    address: '',
    address2: '',
  },
  footer: true,
  showAddress: false,
};

const mapStateToProps = (state: RootState) => ({
  isChatVisible: isChatVisible(state),
});

const Footer = connect(mapStateToProps)(FooterComponent);

export { Footer };
