import {
  EXCHANGE_CURRENCY_REQUEST,
  EXCHANGE_CURRENCY_SUCCESS,
  CHINA_COUNTRY_CODE,
} from 'constants/index';
import {
  getCurrentCurrency,
  getTotalAmount,
  getCurrentCountryCode,
} from 'selectors';
import { exchange } from 'services/exchange/exchange';
import type { PayexThunkAction } from 'store/configureStore';

export const exchangeCurrency =
  (): PayexThunkAction => async (dispatch, getState) => {
    const state = getState();
    const countryCode = getCurrentCountryCode(state);
    if (countryCode !== CHINA_COUNTRY_CODE) return;

    dispatch({
      type: EXCHANGE_CURRENCY_REQUEST,
    });

    const { code: currencyFrom } = getCurrentCurrency(state);
    const totalAmount = getTotalAmount(state);

    const response = await exchange.fetch({
      totalAmount,
      currencyTo: 'USD',
      currencyFrom,
    });

    dispatch({
      type: EXCHANGE_CURRENCY_SUCCESS,
      payload: response,
    });
  };
