import { useEffect } from 'react';
import { META_MARKETING_ID, META_MARKETING_ENVS } from 'constants/index';
const META_PRIMARY_ID = 'meta-marketing-primary';
const META_SECONDARY_ID = 'meta-marketing-secondary';

function MetaMarketing() {
  useEffect(() => {
    const injectScript = (id: string) => {
      const head = document.querySelector('head');
      if (!head) return;

      const hasScriptPrimaryScript = !!document.getElementById(META_PRIMARY_ID);
      if (!hasScriptPrimaryScript) {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.id = META_PRIMARY_ID;
        script.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${id}');
          fbq('track', 'PageView');
        `;

        head.appendChild(script);
      }

      const hasScriptSecondaryScript =
        !!document.getElementById(META_SECONDARY_ID);
      if (!hasScriptSecondaryScript) {
        const noscript = document.createElement('noscript');
        noscript.id = META_SECONDARY_ID;
        noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1" />`;

        head.appendChild(noscript);
      }
    };

    const initialize = () => {
      if (!document) {
        return;
      }

      injectScript(META_MARKETING_ID);
    };

    const isGoogleEnabledEnv = META_MARKETING_ENVS.includes(
      window.location.hostname,
    );

    if (isGoogleEnabledEnv) {
      initialize();
    }
  }, []);

  return null;
}

export { MetaMarketing };
