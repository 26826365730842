import { useEffect } from 'react';
import { GOOGLE_MARKETING_ID, GOOGLE_MARKETING_ENVS } from 'constants/index';
const GTAG_PRIMARY_ID = 'google-gtag-primary';
const GTAG_SECONDARY_ID = 'google-gtag-secondary';

function GoogleMarketing() {
  useEffect(() => {
    const injectScript = (id: string) => {
      const head = document.querySelector('head');
      if (!head) return;

      const hasScriptPrimaryScript = !!document.getElementById(GTAG_PRIMARY_ID);
      if (!hasScriptPrimaryScript) {
        const gtag = document.createElement('script');
        gtag.setAttribute('async', 'true');
        gtag.id = GTAG_PRIMARY_ID;
        gtag.setAttribute(
          'src',
          `https://www.googletagmanager.com/gtag/js?id=${id}`,
        );

        head.appendChild(gtag);
      }

      const hasScriptSecondaryScript =
        !!document.getElementById(GTAG_SECONDARY_ID);
      if (!hasScriptSecondaryScript) {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.id = GTAG_SECONDARY_ID;
        script.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${id}');
        `;

        head.appendChild(script);
      }
    };

    const initialize = () => {
      if (!document) {
        return;
      }

      injectScript(GOOGLE_MARKETING_ID);
    };

    const isGoogleEnabledEnv = GOOGLE_MARKETING_ENVS.includes(
      window.location.hostname,
    );

    if (isGoogleEnabledEnv) {
      initialize();
    }
  }, []);

  return null;
}

export { GoogleMarketing };
