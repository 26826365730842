import { combineReducers } from 'redux';
import authentication from './authentication';
import { exchange } from './exchange';
import modals from './modals';
import pagination from './pagination';
import captcha from './captcha';
import {
  apiReadOnlyFields,
  attemptedInvalidSMSNumber,
  autoLoadOnlineScript,
  createPaymentFailure,
  currentStepIndex,
  docCollectorFinished,
  duplicatedPayment,
  duplicatedPANNumber,
  embedded,
  fieldValues,
  gatewayOrigin,
  gatewaySource,
  hideCancelPaymentOption,
  hideUserManagement,
  isChatVisible,
  isDocumentsFetchFailure,
  isImpersonatingUser,
  isPaymentProcess,
  isRepeatingPayment,
  isTrackingPage,
  lastPaymentFromSameRecipient,
  locale,
  maxAmount,
  notifications,
  paymentsFromIndia,
  prefilledFields,
  prefilledRejectOrConfirm,
  readOnlyFields,
  receiveSMSNotification,
  receiveWhatsappNotification,
  redirectToReferrer,
  refundKYCProcessCompleted,
  showOfflineInstructions,
  taxServiceUnavailable,
  theme,
  userExists,
  validRefundKYCToken,
} from './ui';

export default combineReducers({
  apiReadOnlyFields,
  attemptedInvalidSMSNumber,
  authentication,
  autoLoadOnlineScript,
  createPaymentFailure,
  currentStepIndex,
  docCollectorFinished,
  duplicatedPayment,
  duplicatedPANNumber,
  embedded,
  exchange,
  fieldValues,
  gatewayOrigin,
  gatewaySource,
  hideCancelPaymentOption,
  hideUserManagement,
  isChatVisible,
  isDocumentsFetchFailure,
  isImpersonatingUser,
  isPaymentProcess,
  isRepeatingPayment,
  isTrackingPage,
  lastPaymentFromSameRecipient,
  locale,
  maxAmount,
  modals,
  notifications,
  pagination,
  paymentsFromIndia,
  prefilledFields,
  prefilledRejectOrConfirm,
  readOnlyFields,
  receiveSMSNotification,
  receiveWhatsappNotification,
  redirectToReferrer,
  refundKYCProcessCompleted,
  showOfflineInstructions,
  taxServiceUnavailable,
  theme,
  userExists,
  validRefundKYCToken,
  captcha,
});
