import fetch from 'services/apiV3/fetch';
import { RECURRING_RECEIVABLES_URL } from 'constants/config';
import type {
  CreateRecurringParams,
  FetchRecurringReceivablesResponse,
  GetRemainingBalanceParams,
  GetRemainingBalanceResponse,
  PaginationParams,
  PayRemainingBalanceParams,
} from './types';
import { cookies } from 'utils/cookies/cookies';
import { PERMANENT_COOKIE_NAME } from 'constants/cookies';
import { Payment } from '../../../models';

export const recurringReceivables = {
  fetch: ({ perPage = 5, page = 1 }: PaginationParams = {}) => {
    const options = { method: 'GET' };

    return fetch<FetchRecurringReceivablesResponse>(
      `${RECURRING_RECEIVABLES_URL}/details?per_page=${perPage}&page=${page}`,
      options,
    );
  },

  cancel: (id: string) => {
    const url = `${RECURRING_RECEIVABLES_URL}/${id}/cancellation`;
    const options = { method: 'POST', body: JSON.stringify({}) };

    return fetch(url, options);
  },

  create: ({
    apiConfirmationURL,
    cardToken,
    fingerprint = '',
    isFromGateway,
    locale = 'EN',
    numberOfInstallments,
    orderId,
    orderToken,
    uuidToken,
    sessionId,
    nonAutomated,
  }: CreateRecurringParams) => {
    const url = `${RECURRING_RECEIVABLES_URL}?locale=${locale}&fingerprint=${fingerprint}`;
    const body = JSON.stringify({
      number_of_installments: numberOfInstallments,
      order_id: orderId,
      order_token: orderToken,
      non_automated: nonAutomated,
      ...(apiConfirmationURL && {
        api_confirmation_url: apiConfirmationURL,
      }),
      ...(cardToken && { token: cardToken }),
      ...(uuidToken && { uuid_token: uuidToken }),
      ...(sessionId && { session_id: sessionId }),
      ...(isFromGateway && { is_from_gateway: isFromGateway }),
    });

    const options = { method: 'POST', body };
    return fetch(url, options);
  },

  checkCardActivePlans: async (token: string) => {
    const url = `${RECURRING_RECEIVABLES_URL}/active_plans_search`;
    const body = JSON.stringify({
      token,
    });

    const options = { method: 'POST', body };
    return await fetch(url, options);
  },

  changeCardActivePlans: ({
    oldToken,
    newToken,
    confirmUrl,
    confirmToken,
    sessionId,
  }: {
    oldToken: string;
    newToken?: string;
    confirmUrl?: string;
    confirmToken?: string;
    sessionId?: string;
  }) => {
    const url = `${RECURRING_RECEIVABLES_URL}/active_plans`;
    const body = JSON.stringify({
      old_token: oldToken,
      ...(newToken && { new_token: newToken }),
      ...(confirmUrl && { confirm_url: confirmUrl }),
      ...(confirmToken && { confirm_token: confirmToken }),
      ...(sessionId && { session_id: sessionId }),
    });

    const options = { method: 'POST', body };
    return fetch(url, options);
  },

  getRemainingBalance: ({ id, locale }: GetRemainingBalanceParams) => {
    const fingerprint = cookies.read(PERMANENT_COOKIE_NAME);

    const url = `${RECURRING_RECEIVABLES_URL}/${id}/remaining_balance?locale=${locale}&fingerprint=${fingerprint}`;

    return fetch<GetRemainingBalanceResponse>(url, { method: 'GET' });
  },

  payRemainingBalance: ({
    id,
    frozenOfferId,
    chargeIntent,
    manualPayment = false,
  }: PayRemainingBalanceParams): Promise<Payment> => {
    const url = `${RECURRING_RECEIVABLES_URL}/${id}/pay_balance`;

    let params = {
      frozen_offer_id: frozenOfferId,
      manual_payment: manualPayment,
    };

    if (chargeIntent) {
      const {
        apiConfirmationUrl,
        mandateId,
        paymentMethodToken,
        uuidToken,
        sessionId,
      } = chargeIntent;
      params = {
        ...params,
        ...(apiConfirmationUrl && {
          api_confirmation_url: apiConfirmationUrl,
        }),
        ...(mandateId && { mandate_id: mandateId }),
        ...(paymentMethodToken && {
          payment_method_token: paymentMethodToken,
        }),
        ...(uuidToken && { uuid_token: uuidToken }),
        ...(sessionId && { session_id: sessionId }),
      };
    }

    const options = {
      method: 'POST',
      body: JSON.stringify(params),
    };
    return fetch(url, options);
  },
};
