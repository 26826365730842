import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import defaultLogo from './defaultLogo.svg';

import './DefaultLogo.scss';

const DEFAULT_SIZE = 'medium';

const DefaultLogo = ({ size, className }) => {
  const logoSizeClass = `DefaultLogo--${size}`;
  const logoClassName = classNames(
    'DefaultLogo',
    { [logoSizeClass]: !!size },
    className,
  );

  return <img className={logoClassName} src={defaultLogo} alt="Flywire" />;
};

DefaultLogo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

DefaultLogo.defaultProps = {
  size: DEFAULT_SIZE,
};

export { DefaultLogo };
