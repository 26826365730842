export const CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST';
export const CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS';
export const CANCEL_PAYMENT_FAILURE = 'CANCEL_PAYMENT_FAILURE';
export const CLOSE_CANCEL_PAYMENT_MODAL = 'CLOSE_CANCEL_PAYMENT_MODAL';
export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'UPDATE_PAYMENT_FAILURE';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';
export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS =
  'FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const FETCH_PROOFS_REQUEST = 'FETCH_PROOFS_REQUEST';
export const FETCH_PROOFS_SUCCESS = 'FETCH_PROOFS_SUCCESS';
export const FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS =
  'FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS';
export const FETCH_PROOFS_FAILURE = 'FETCH_PROOFS_FAILURE';
export const FETCH_PAYMENT_FAILURE = 'FETCH_PAYMENT_FAILURE';
export const FETCH_PAYMENT_REQUEST = 'FETCH_PAYMENT_REQUEST';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_SMS_NOTIFICATION_REQUEST = 'FETCH_SMS_NOTIFICATION_REQUEST';
export const FETCH_SMS_NOTIFICATION_SUCCESS = 'FETCH_SMS_NOTIFICATION_SUCCESS';
export const FETCH_SMS_NOTIFICATION_FAILURE = 'FETCH_SMS_NOTIFICATION_FAILURE';
export const FETCH_REPEAT_PAYMENT_OFFER = 'FETCH_REPEAT_PAYMENT_OFFER';
export const FETCH_USER_PAYMENT_FAILURE = 'FETCH_USER_PAYMENT_FAILURE';
export const FETCH_USER_PAYMENT_REQUEST = 'FETCH_USER_PAYMENT_REQUEST';
export const FETCH_USER_PAYMENT_SUCCESS = 'FETCH_USER_PAYMENT_SUCCESS';
export const FETCH_LOGGED_USER_PAYMENT_REQUEST =
  'FETCH_LOGGED_USER_PAYMENT_REQUEST';
export const FETCH_LOGGED_USER_PAYMENT_SUCCESS =
  'FETCH_LOGGED_USER_PAYMENT_SUCCESS';
export const FETCH_LOGGED_USER_PAYMENT_FAILURE =
  'FETCH_LOGGED_USER_PAYMENT_FAILURE';
export const FETCH_USER_PAYMENTS_FAILURE = 'FETCH_USER_PAYMENTS_FAILURE';
export const FETCH_USER_PAYMENTS_REQUEST = 'FETCH_USER_PAYMENTS_REQUEST';
export const FETCH_USER_PAYMENTS_SUCCESS = 'FETCH_USER_PAYMENTS_SUCCESS';
export const INITIATE_PAYMENT_REQUEST = 'INITIATE_PAYMENT_REQUEST';
export const INITIATE_PAYMENT_SUCCESS = 'INITIATE_PAYMENT_SUCCESS';
export const PAYMENT_STATUS_CANCELLED = 'cancelled';
export const PAYMENT_STATUS_DELIVERED = 'delivered';
export const PAYMENT_STATUS_INITIATED = 'initiated';
export const PAYMENT_STATUS_GUARANTEED = 'guaranteed';
export const PAYMENT_STATUS_PROCESSING = 'processing';
export const PAYMENT_STATUS_FAILED = 'failed';
export const PAYMENT_STATUS_ON_HOLD = 'on_hold';
export const PAYMENT_STATUS_PENDING = 'pending';
export const PAYMENT_STATUS_RECEIVED = 'received';
export const PAYMENT_STATUS_UPDATED = 'updated';
export const PAYMENT_STATUS_SENT = 'sent';
export const PAYMENT_STATUS_CAP_RECEIVED = 'cap-received';
export const PAYMENT_STATUS_VERIFICATION = 'verification';
export const SELECT_PAYMENT = 'SELECT_PAYMENT';
export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';
export const FETCH_PAYMENT_LRS_REQUEST = 'FETCH_PAYMENT_LRS_REQUEST';
export const FETCH_PAYMENT_LRS_SUCCESS = 'FETCH_PAYMENT_LRS_SUCCESS';
export const FETCH_PAYMENT_LRS_FAILURE = 'FETCH_PAYMENT_LRS_FAILURE';
export const DELETE_PAYMENT_LRS_REQUEST = 'DELETE_PAYMENT_LRS_REQUEST';
export const DELETE_PAYMENT_LRS_SUCCESS = 'DELETE_PAYMENT_LRS_SUCCESS';
export const DELETE_PAYMENT_LRS_FAILURE = 'DELETE_PAYMENT_LRS_FAILURE';
export const UPLOAD_LRS_INFO_REQUEST = 'UPLOAD_LRS_INFO_REQUEST';
export const UPLOAD_LRS_INFO_SUCCESS = 'UPLOAD_LRS_INFO_SUCCESS';
export const UPLOAD_LRS_INFO_FAILURE = 'UPLOAD_LRS_INFO_FAILURE';
export const UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT =
  'UPLOAD_LRS_SIGNATURE_FILE_BAD_FORMAT';
export const CREATE_PAYMENT_FROM_ORDER_REQUEST =
  'CREATE_PAYMENT_FROM_ORDER_REQUEST';
export const CREATE_PAYMENT_FROM_ORDER_SUCCESS =
  'CREATE_PAYMENT_FROM_ORDER_SUCCESS';
export const CREATE_PAYMENT_FROM_ORDER_FAILURE =
  'CREATE_PAYMENT_FROM_ORDER_FAILURE';
export const PAYMENT_PROOF_COMPLETED = 'PAYMENT_PROOF_COMPLETED';
export const OPEN_PAYMENT_PROOF_MODAL = 'OPEN_PAYMENT_PROOF_MODAL';
export const CLOSE_PAYMENT_PROOF_MODAL = 'CLOSE_PAYMENT_PROOF_MODAL';
export const PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE = ['INR'];
export const PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE = [
  'USD',
  'CAD',
  'GBP',
  'AUD',
  'EUR',
];

export const PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_GIC = ['CAD'];
export const PAYER_NOT_SUPPORTED_CODE = '067';
export const INVALID_STUDENT_CNY_CODE = '072';
