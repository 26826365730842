import { combineReducers } from 'redux';
import {
  ENABLE_SENDER_SMS_NOTIFICATION_SUCCESS,
  FETCH_SMS_NOTIFICATION_SUCCESS,
  UPDATE_SMS_NOTIFICATION_SUCCESS,
} from '../../../constants';
import type { SMSNotification } from 'models';

type SMSNotificationAction = {
  type:
    | typeof ENABLE_SENDER_SMS_NOTIFICATION_SUCCESS
    | typeof FETCH_SMS_NOTIFICATION_SUCCESS
    | typeof UPDATE_SMS_NOTIFICATION_SUCCESS;
  payload: {
    entities: {
      smsNotification: Record<string, SMSNotification>;
    };
  };
};

export const byId = (
  state: Record<string, SMSNotification> = {},
  action: SMSNotificationAction,
) => {
  switch (action.type) {
    case ENABLE_SENDER_SMS_NOTIFICATION_SUCCESS:
    case FETCH_SMS_NOTIFICATION_SUCCESS:
    case UPDATE_SMS_NOTIFICATION_SUCCESS:
      const {
        entities: { smsNotification = {} },
      } = action.payload;
      return { ...state, ...smsNotification };
    default:
      return state;
  }
};

export default combineReducers({ byId });
