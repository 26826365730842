import React from 'react';
import { useTranslations } from 'utils/translations/useTranslations';

import './SkipToContent.scss';

const SkipToContent = ({ contentId }: { contentId: string }) => {
  const i18n = useTranslations();

  return (
    <a
      className="SkipToContent"
      aria-label={i18n.t('skipToContent') as string}
      href={`#${contentId}`}
    >
      {i18n.t('skipToContent')}
    </a>
  );
};

export { SkipToContent };
