import { LRS_URL, LRS_PREVIEW_URL } from 'constants/index';
import { PreviousPayment } from 'models';
import fetchJson from 'utils/fetchJson';

type UploadLrsInfoRequestBody = {
  paymentId: string;
  paymentToken: string;
  bankAccountNumber: string;
  studentFullName: string;
  studentID: string;
  transactions: PreviousPayment[];
  signatureImage: string;
  senderEmail?: string;
  partner?: string;
  documentation?: string;
  passport?: string;
  admissionLetter?: string;
  idVerificationNeeded: boolean;
  isStudentPaying: boolean;
};

type UploadLrsRequestBody = {
  paymentId: string;
  paymentToken: string;
  bankAccountNumber: string;
  studentFullName: string;
  studentID: string;
  transactions: PreviousPayment[];
  signatureImage: string;
};

type PreviewFormRequestBody = {
  paymentId: string;
  paymentToken: string;
  bankAccountNumber?: string;
  studentFullName?: string;
  studentID?: string;
  transactions?: PreviousPayment[];
};

export const lrs = {
  uploadInfo: (payload: UploadLrsInfoRequestBody) => {
    const body = JSON.stringify({
      payment_id: payload.paymentId,
      payment_token: payload.paymentToken,
      bank_account_number: payload.bankAccountNumber,
      student_full_name: payload.studentFullName,
      student_id: payload.studentID,
      transactions: parseTransactions(payload.transactions),
      signature_image: payload.signatureImage,
      sender_email: payload.senderEmail,
      partner: payload.partner,
      document: payload.documentation,
      passport: payload.passport,
      admission_letter: payload.admissionLetter,
      id_verification_needed: payload.idVerificationNeeded,
      is_student_paying: payload.isStudentPaying || false,
    });

    return fetchJson<void>(LRS_URL, { method: 'POST', body });
  },

  upload: (payload: UploadLrsRequestBody) => {
    const body = JSON.stringify({
      payment_id: payload.paymentId,
      payment_token: payload.paymentToken,
      bank_account_number: payload.bankAccountNumber,
      student_full_name: payload.studentFullName,
      student_id: payload.studentID,
      transactions: parseTransactions(payload.transactions),
      signature_image: payload.signatureImage,
    });

    return fetchJson<void>(`${LRS_URL}/upload`, { method: 'POST', body });
  },

  previewForm: (payload: PreviewFormRequestBody) => {
    const body = JSON.stringify({
      payment_id: payload.paymentId,
      payment_token: payload.paymentToken,
      bank_account_number: payload.bankAccountNumber,
      student_full_name: payload.studentFullName,
      student_id: payload.studentID,
      transactions: parseTransactions(payload.transactions),
    });

    return fetch(LRS_PREVIEW_URL, { method: 'POST', body }).then(response =>
      response.arrayBuffer(),
    );
  },

  fetch: ({
    paymentId,
    paymentToken,
  }: {
    paymentId: string;
    paymentToken: string;
  }) => {
    const url = `${LRS_URL}/${paymentId}?payment_token=${paymentToken}`;

    return fetchJson<void>(url);
  },

  delete: ({
    paymentId,
    paymentToken,
  }: {
    paymentId: string;
    paymentToken: string;
  }) => {
    const options = { method: 'DELETE' };

    const url = `${LRS_URL}/${paymentId}?payment_token=${paymentToken}`;

    return fetch(url, options);
  },
};

const parseTransactions = (transactions: PreviousPayment[] = []) => {
  if (!transactions.length) return null;

  return transactions.map(({ adBranch, ...transaction }) => ({
    ...transaction,
    address: adBranch,
  }));
};
