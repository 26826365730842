import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'reducers/types';
import { isFetching } from 'selectors';

interface OwnProps {
  children?: React.ReactNode;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type IsEmbeddedFetchedProps = OwnProps & PropsFromRedux;

const IsEmbeddedFetchedComponent = ({
  children = null,
  isFetching = false,
}: IsEmbeddedFetchedProps) => {
  return isFetching ? null : children;
};

const mapStateToProps = (state: RootState) => ({
  isFetching: isFetching(state, 'embedded'),
});

const connector = connect(mapStateToProps);
const IsEmbeddedFetched = connector(IsEmbeddedFetchedComponent);

export { IsEmbeddedFetched };
