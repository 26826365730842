import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { getNotifications } from 'selectors';
import { Notification } from './Notification/Notification';
import { removeNotification } from 'actions';
import {
  ONE_SECOND_IN_MILISECONDS,
  THREE_SECONDS_IN_MILISECONDS,
} from 'constants/timers';
import type { RootState } from 'reducers/types';

import './Notifications.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;
type NotificationWithTimerProps = {
  id: string;
  isPaused: boolean;
  index: number;
  message: string;
  removeNotification: (id: string) => void;
  type: 'error' | 'info' | 'success';
};

const NotificationWithTimer = ({
  id,
  isPaused,
  index,
  message,
  removeNotification,
  type,
}: NotificationWithTimerProps) => {
  const [timeRemaining, setTimeRemaining] = useState(
    THREE_SECONDS_IN_MILISECONDS,
  );
  const delay = index * 25;

  useEffect(() => {
    if (!isPaused && timeRemaining > 0) {
      const timerId = setTimeout(() => {
        setTimeRemaining(timeRemaining - (ONE_SECOND_IN_MILISECONDS + delay));
      }, ONE_SECOND_IN_MILISECONDS + delay);
      return () => clearTimeout(timerId);
    } else if (timeRemaining <= 0) {
      removeNotification(id);
    }
  }, [delay, isPaused, timeRemaining, id, removeNotification]);

  useEffect(() => {
    if (isPaused) {
      setTimeRemaining(THREE_SECONDS_IN_MILISECONDS);
    }
  }, [isPaused]);

  return (
    <Notification
      as="li"
      message={message}
      type={type}
      onClose={() => removeNotification(id)}
    />
  );
};

const NotificationsComponent = ({
  notifications = [],
  removeNotification,
}: PropsFromRedux) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleCloseNotification = (id: string) => {
    removeNotification(id);
  };

  if (notifications.length === 0) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      role="region"
      aria-label="Notifications"
      className="Notifications"
      onBlur={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      tabIndex={-1}
    >
      <ol tabIndex={-1}>
        {notifications.map((notification, index) => (
          <NotificationWithTimer
            {...notification}
            index={notifications.length - 1 - index}
            isPaused={isHovered}
            key={notification.id}
            removeNotification={handleCloseNotification}
          />
        ))}
      </ol>
    </div>,
    document.getElementById('payex-notifications')!,
  );
};

const mapStateToProps = (state: RootState) => {
  const notifications = getNotifications(state);

  return { notifications };
};

const mapDispatchToProps = {
  removeNotification,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Notifications = connector(NotificationsComponent);

export { Notifications };
