import {
  getRecipient,
  getRecipientCurrencyCode,
  isPaymentProcessWithNobookingCta,
  getOffer,
  getCountryCurrency,
  getCurrency,
  findSingleOffer,
  isEmbedded,
} from 'selectors';
import get from 'lodash/get';
import type { RootState } from 'reducers/types';
import { Field, Item, Tax } from 'models';
import isEmpty from 'lodash/isEmpty';
import {
  PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE,
  PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE,
  USD_CURRENCY_CODE,
} from 'constants/index';
import {
  CASHBACK_MIN_AMOUNT_FROM,
  REDEEM_CASHBACK_FEE_CATEGORY,
  REDEEM_CASHBACK_FEE_SUBCATEGORY,
} from 'constants/cashback';
import {
  COUPON_FEE_CATEGORY,
  COUPON_FEE_SUBCATEGORY_PREFIX,
} from 'constants/coupons';
import type { ExtraFee } from 'models/extraFee';

const INDIAN_TAXES_REFERENCES = ['gst_tax', 'tcs_tax', 'indian_tax'];

export const getOrder = (state: RootState) => {
  const {
    entities: { order },
  } = state;
  return order;
};

export const getOrderOffer = (state: RootState) => {
  const offerId = get(state, 'entities.order.offer.id');
  return getOffer(state, offerId);
};

export const hasOrder = (state: RootState) => {
  const {
    entities: { order },
  } = state;

  return !!order?.id;
};

export const getPaymentOption = (state: RootState): string => {
  const {
    entities: { order: { offer: { paymentOption = '' } = {} } = {} },
  } = state;

  return paymentOption;
};

export const orderHasOwner = (state: RootState) => {
  const {
    entities: {
      order: { hasOwner },
    },
  } = state;
  return hasOwner || false;
};

export const getOrderAmount = (state: RootState) => {
  const {
    entities: {
      order: { recipient: { items = [] } = {} },
    },
  } = state;
  const accumulateAmount = (total: number, item: Item) => total + item.amount;

  return items.reduce(accumulateAmount, 0);
};

export const getSenderCountry = (state: RootState) => {
  const {
    entities: {
      order: { sender: { fields = [] } = {} },
    },
  } = state;
  const countryField = fields.find(field => field.id === 'country');

  return countryField?.value ? (countryField.value as string) : '';
};

export const getSenderCurrency = (state: RootState) => {
  const DEFAULT_CURRENCY = USD_CURRENCY_CODE;
  const country = getSenderCountry(state);
  let currency;
  if (country) {
    const currencyCode = getCountryCurrency(state, country as string);
    currency = getCurrency(state, currencyCode);
    if (isEmpty(currency)) {
      currency = getCurrency(state, DEFAULT_CURRENCY);
    }
  }

  return currency;
};

export const getSenderField = (state: RootState, field: string) => {
  const {
    entities: { order: { sender: { fields = [] } = {} } = {} },
  } = state;

  const senderField = fields.find(f => f.id === field);
  if (senderField) return senderField.value;
};

export const getOrderReturnUrl = (state: RootState) => {
  const {
    entities: { order: { returnUrl = '' } = {} },
  } = state;

  return returnUrl;
};

export const paymentIsFromAgents = (state: RootState) => {
  const {
    entities: { order },
  } = state;

  return order?.provider === 'agents';
};

export const getNobookingCtaUrl = (state: RootState) => {
  const { website = '' } = getRecipient(state);
  const {
    entities: {
      order: { metadata: { returnNobookingCta } = {} },
    },
  } = state;

  return isPaymentProcessWithNobookingCta(state) ? returnNobookingCta : website;
};

export const getNobookingCtaName = (state: RootState) => {
  const { website = '' } = getRecipient(state);
  const {
    entities: {
      order: { metadata: { returnNobookingCta, returnNobookingCtaName } = {} },
    },
  } = state;

  return isPaymentProcessWithNobookingCta(state)
    ? returnNobookingCtaName || returnNobookingCta
    : website;
};

export const isReadOnlyOrderSectionField = (
  state: RootState,
  field: string,
) => {
  const {
    entities: { order = {} },
  } = state;

  const [sectionId, ...rest] = field.split('_');
  const fieldId = rest.join('_');

  const sectionFields = get(order, `${sectionId}.fields`) || [];
  if (!sectionFields) return false;

  const sectionField = (sectionFields as Field[]).find(
    sectionField => sectionField.id === fieldId,
  );
  if (!sectionField) return false;

  return !!sectionField.readOnly;
};

export const getOrderSectionFieldValue = (field: string, state: RootState) => {
  const {
    entities: { order = {} },
  } = state;

  const [sectionId, ...rest] = field.split('_');
  const fieldId = rest.join('_');

  const sectionFields = get(order, `${sectionId}.fields`) || [];
  if (!sectionFields) return null;

  const sectionField = (sectionFields as Field[]).find(
    sectionField => sectionField.id === fieldId,
  );
  if (!sectionField) return null;

  return sectionField.value;
};

export const getTaxes = (state: RootState): Tax[] => {
  const {
    entities: { order: { offer: { taxes = [] } = {} } = {} },
  } = state;

  if (taxes.length === 0) {
    return [];
  }

  return taxes;
};

export const hasIndianTaxes = (state: RootState): boolean => {
  const {
    entities: { order: { offer: { taxes = [] } = {} } = {} },
  } = state;

  const filteredTaxes = taxes.filter((tax: Tax) =>
    INDIAN_TAXES_REFERENCES.includes(tax.id),
  );

  if (filteredTaxes.length === 0) {
    return false;
  }

  return true;
};

export const getOrderCallToAction = (state: RootState) => {
  const {
    entities: {
      order: { metadata: { returnCtaName, returnCtaUrl } = {} },
    },
  } = state;

  return returnCtaName && returnCtaUrl
    ? { name: returnCtaName, url: returnCtaUrl }
    : {};
};

export const getOfferId = (state: RootState): string => {
  const {
    entities: { order: { offer: { id = '' } = {} } = {} },
  } = state;

  return id;
};

export const shouldShowCashback = (state: RootState) => {
  const {
    entities: { prices = {} },
  } = state;

  const currencyTo = getRecipientCurrencyCode(state);
  const offerId = getOfferId(state);
  const offer = findSingleOffer(state, offerId);
  const isEmbeddedPlatform = isEmbedded(state);

  if (!offer || !currencyTo) {
    return false;
  }
  const currencyFrom = prices[offer.price]?.currency || '';

  const shouldShowCashback =
    PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE.includes(currencyFrom) &&
    PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE.includes(
      currencyTo as string,
    );

  return shouldShowCashback && !isEmbeddedPlatform;
};

export const hasAmountToGenerateCashback = (state: RootState) => {
  const {
    entities: { prices = {} },
  } = state;

  const offerId = getOfferId(state);
  const offer = findSingleOffer(state, offerId);
  if (!offer) {
    return false;
  }

  const currencyFrom = prices[offer.price]?.value || 0;
  return currencyFrom >= CASHBACK_MIN_AMOUNT_FROM;
};

export const getExtraFees = (state: RootState): ExtraFee[] => {
  const {
    entities: { order: { extraFees = [] } = {} },
  } = state;

  if (extraFees.length === 0) {
    return [];
  }

  return extraFees;
};

export const isCouponApplied = (state: RootState) => {
  const {
    entities: { order: { extraFees } = {} },
  } = state;

  if (!extraFees) return false;

  return extraFees.some(
    fee =>
      fee.category === COUPON_FEE_CATEGORY &&
      fee.subcategory.startsWith(COUPON_FEE_SUBCATEGORY_PREFIX),
  );
};

export const isRedeemCashbackApplied = (state: RootState) => {
  const {
    entities: { order: { extraFees } = {} },
  } = state;

  if (!extraFees) return false;

  return extraFees.some(
    fee =>
      fee.category === REDEEM_CASHBACK_FEE_CATEGORY &&
      fee.subcategory.startsWith(REDEEM_CASHBACK_FEE_SUBCATEGORY),
  );
};

export const getIndianPayerFromMetadata = (state: RootState) => {
  const {
    entities: {
      order: { metadata: { fxFeedbackPayerValue: payer } = {} },
    },
  } = state;

  return payer;
};
