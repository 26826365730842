import React from 'react';

const ExclamationCircle = props => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0a7 7 0 1 1 0 14A7 7 0 0 1 7 0zm1 9H6v2h2V9zm0-6H6v5h2V3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ExclamationCircle;
