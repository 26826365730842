export const OFFER_ENTITY = 'offers';
export const BANK_TRANSFER = 'bank_transfer';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';
export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_LEGACY_RECURRING_OFFERS_SUCCESS =
  'FETCH_LEGACY_RECURRING_OFFERS_SUCCESS';
export const FETCH_RECURRING_OFFERS_REQUEST = 'FETCH_RECURRING_OFFERS_REQUEST';
export const FETCH_RECURRING_OFFERS_SUCCESS = 'FETCH_RECURRING_OFFERS_SUCCESS';
export const FETCH_RECURRING_OFFERS_FAILURE = 'FETCH_RECURRING_OFFERS_FAILURE';
export const FETCH_OFFER_FAILURE = 'FETCH_OFFER_FAILURE';
export const FETCH_OFFER_REQUEST = 'FETCH_OFFER_REQUEST';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const RESET_SELECTED_OFFER = 'RESET_SELECTED_OFFER';
export const LULU_EMAIL = 'flywiredocuments@in.luluforex.com';
export const MUTHOOT_EMAIL = 'flywiredocuments@muthootgroup.com';
export const QR_PAYMENT_METHOD = ['wechat_pay'];
export const BPAY_PAYMENT_METHOD = ['bpay'];
export const EMAIL_PAYMENT_METHOD = ['interac_request_for_payment'];
export const MUTHOOT_PAYMENT_OPTION = 'MUTHOOT_INR';
export const MUTHOOT_INR_USD_CLIENTS = 'MUTHOOT_INR_USD_CLIENTS';
export const DBIN_PAYMENT_OPTION = 'bank_transfer_db_india';
export const PT_CITY_THB_PAYMENT_OPTION = 'pt_bank_transfer_thb_citi';
export const LULU_MUTHOOTPAIR_PAYMENT_OPTION = 'LULU_INR_muthootpair';
export const LULU_INR = 'LULU_INR';
export const LULU_INR_USDCLIENTS = 'LULU_INR_USDCLIENTS';
export const LULU_PAYMENT_OPTIONS = [
  'LULU_INR',
  'LULU_INR_USDCLIENTS',
  DBIN_PAYMENT_OPTION,
  LULU_MUTHOOTPAIR_PAYMENT_OPTION,
];
export const ASTROPAY_PAYMENT_OPTIONS = [
  'astropay_br_boleto',
  'astropay_br_card_no_advance',
  'astropay_br_card',
  'astropay_br_online',
  'astropay_br_card_installment_no_advance',
  'astropay_br_card_installment',
];
export const PAYMENT_OPTIONS_WITH_OWN_IDS = [
  'KSNET',
  'db_bank_thb',
  'LULU_INR',
  'bank_transfer_db_india',
  'MUTHOOT_INR',
  'dlocal_mxn_spei',
  'LULU_INR_Large_Payments',
  'LULU_INR_USDCLIENTS',
  'pt_bank_transfer_krw2',
  LULU_MUTHOOTPAIR_PAYMENT_OPTION,
];

export const INDIA_PAYMENT_OPTIONS_WITH_CUSTOM_SECTION = [
  'LULU_INR',
  'LULU_INR_USDCLIENTS',
  'bank_transfer_db_india',
  'MUTHOOT_INR',
  'cashfree_inr_netbanking',
  LULU_MUTHOOTPAIR_PAYMENT_OPTION,
];

export const LULU_PAYMENT_OPTIONS_WITH_EDUCATION_LOAN_REDUCED_FEE = [
  LULU_INR,
  LULU_INR_USDCLIENTS,
  LULU_MUTHOOTPAIR_PAYMENT_OPTION,
];
export const MUTHOOT_PAYMENT_OPTIONS_WITH_EDUCATION_LOAN_REDUCED_FEE = [
  MUTHOOT_PAYMENT_OPTION,
  MUTHOOT_INR_USD_CLIENTS,
];

export const CHINA_PAYMENT_OPTIONS_WITH_CUSTOM_SECTION = [
  'fuiou_cny_tuition',
  'fuiou_cny_tuition_nodoccollection',
  'bank_transfer_llp_btc',
  'bank_transfer_lianlianpay_edu',
  'bank_transfer_lianlianpay_edu_large_payments',
  'bank_transfer_upi_edu',
  'bank_transfer_upi_edu_large_payments',
];
export const INTERAC_PAYMENT_OPTION_WITH_CUSTOM_SECTION =
  'interac_request_for_payment';
export enum InstructionsType {
  EMPTY = 'EMPTY',
  QR = 'QR',
  EMAIL = 'EMAIL',
  REDIRECT = 'REDIRECT',
  SCRIPT = 'SCRIPT',
  BPAY = 'BPAY',
}
export const INDIAN_PARTNER_LULU = 'Lulu Forex';
export const INDIAN_PARTNER_GSTIN_LULU = '32AABCL8839J1Z9';
export const INDIAN_PARTNER_MUTHOOT = 'Muthoot Forex';
export const INDIAN_PARTNER_GSTIN_MUTHOOT = '32AADCM3244L1ZL';
export const OPP_UNIONPAY_CAMPAIGN = [
  'opp_unionpay_debit_campaign',
  'opp_unionpay_debit_campaign_usd_settlement',
];
export const RECOMMENDED_OFFERS_FOR_INDIA = [
  'LULU_INR',
  'LULU_INR_USDCLIENTS',
  'MUTHOOT_INR',
  'MUTHOOT_INR_USD_CLIENTS',
  'bank_transfer_db_india',
];
export const SBI_CAMPAIGN_OPTIONS = [
  'bank_transfer_sbi_student_loan',
  'sbi_el',
];
