import { PAYEX_URL } from 'constants/config';
import { decamelizeKeys } from 'humps';
import type {
  LogInPayload,
  SignupRequestBody,
  SignupResponseBody,
  LogInResponse,
  RefreshTokenPayload,
  ResetPasswordPayload,
} from './types';
import fetchJson from 'utils/fetchJson';

export const auth = {
  signup: (data: SignupRequestBody, locale: string) => {
    const path = `${PAYEX_URL}/auth/signup?locale=${locale}`;

    const options = {
      method: 'POST',
      body: JSON.stringify(decamelizeKeys(data)),
    };

    return fetchJson<SignupResponseBody>(path, options);
  },
  logIn: ({ ...payload }: LogInPayload) => {
    const path = `${PAYEX_URL}/auth/token`;
    const body = JSON.stringify(payload);

    return fetchJson<LogInResponse>(path, { method: 'POST', body });
  },
  refreshToken: ({ ...payload }: RefreshTokenPayload) => {
    const path = `${PAYEX_URL}/auth/token`;
    const body = JSON.stringify(payload);

    return fetchJson<LogInResponse>(path, { method: 'POST', body });
  },
  resetPassword: ({ userEmail }: ResetPasswordPayload) => {
    const path = `${PAYEX_URL}/auth/reset_password`;
    const body = JSON.stringify({
      email: userEmail,
    });

    const options = {
      method: 'POST',
      body,
    };

    return fetchJson(path, options);
  },
};
