import { combineReducers } from 'redux';
import {
  FETCH_USER_PAYMENTS_FAILURE,
  FETCH_USER_PAYMENTS_SUCCESS,
} from 'constants/index';
import type { Link, Payment } from 'models';

type PaymentsActions =
  | {
      type: typeof FETCH_USER_PAYMENTS_FAILURE;
    }
  | {
      type: typeof FETCH_USER_PAYMENTS_SUCCESS;
      payload?: {
        links: Link[];
        payments: Payment[];
        totalEntries: number;
        totalPages: number;
      };
    };

const NO_LINKS: never[] = [];
const INITIAL_ENTRIES = 0;
const INITIAL_PAGES = 0;

const prev = (state = false, action: PaymentsActions) => {
  switch (action.type) {
    case FETCH_USER_PAYMENTS_SUCCESS: {
      const links = action.payload?.links || NO_LINKS;

      return links.some((link: Link) => link.rel === 'prev');
    }

    case FETCH_USER_PAYMENTS_FAILURE: {
      return false;
    }

    default: {
      return state;
    }
  }
};

const next = (state = false, action: PaymentsActions) => {
  switch (action.type) {
    case FETCH_USER_PAYMENTS_SUCCESS: {
      const links = action.payload?.links || NO_LINKS;

      return links.some((link: Link) => link.rel === 'next');
    }

    case FETCH_USER_PAYMENTS_FAILURE: {
      return false;
    }

    default: {
      return state;
    }
  }
};

const totalEntries = (state = INITIAL_ENTRIES, action: PaymentsActions) => {
  switch (action.type) {
    case FETCH_USER_PAYMENTS_SUCCESS: {
      return action.payload?.totalEntries || state;
    }

    case FETCH_USER_PAYMENTS_FAILURE: {
      return INITIAL_ENTRIES;
    }

    default: {
      return state;
    }
  }
};

const totalPages = (state = INITIAL_PAGES, action: PaymentsActions) => {
  switch (action.type) {
    case FETCH_USER_PAYMENTS_SUCCESS: {
      return action.payload?.totalPages || state;
    }

    case FETCH_USER_PAYMENTS_FAILURE: {
      return INITIAL_PAGES;
    }

    default: {
      return state;
    }
  }
};

export default combineReducers({
  next,
  prev,
  totalEntries,
  totalPages,
});
