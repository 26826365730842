import React from 'react';

const InfoCircle = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7 0a7 7 0 1 1 0 14A7 7 0 0 1 7 0Zm1 6H6v5h2V6Zm0-3H6v2h2V3Z"
      />
    </svg>
  );
};

export default InfoCircle;
