import {
  CONFIRM_TAX_MODAL,
  CLOSE_DUPLICATED_TAX_PAYMENT_MODAL,
  OPEN_PAYMENT_PROOF_MODAL,
  CLOSE_PAYMENT_PROOF_MODAL,
  CLOSE_CANCEL_PAYMENT_MODAL,
  OPEN_MODAL_REMAINING_BALANCE_PAYMENT_CREATED,
  CLOSE_MODAL_REMAINING_BALANCE_PAYMENT_CREATED,
} from 'constants/index';

export const confirmTax = () => ({
  type: CONFIRM_TAX_MODAL,
  payload: true,
});

export const closeDuplicatedTaxPaymentModal = () => ({
  type: CLOSE_DUPLICATED_TAX_PAYMENT_MODAL,
});

export const openPaymentProofModal = () => ({
  type: OPEN_PAYMENT_PROOF_MODAL,
});

export const closePaymentProofModal = () => ({
  type: CLOSE_PAYMENT_PROOF_MODAL,
});

export const closePaymentCancellationFailedModal = () => ({
  type: CLOSE_CANCEL_PAYMENT_MODAL,
});

export const openPaymentCreatedModal = () => ({
  type: OPEN_MODAL_REMAINING_BALANCE_PAYMENT_CREATED,
});

export const closePaymentCreatedModal = () => ({
  type: CLOSE_MODAL_REMAINING_BALANCE_PAYMENT_CREATED,
});
