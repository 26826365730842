import { combineReducers } from 'redux';
import * as types from '../../constants';

type IsFetchingAction = {
  type: string;
  payload?: boolean;
};

export const countries = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_COUNTRIES_REQUEST:
      return true;
    case types.FETCH_COUNTRIES_SUCCESS:
    case types.FETCH_COUNTRIES_FAILURE:
      return false;
    default:
      return state;
  }
};

export const recipient = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_RECIPIENT_REQUEST:
    case types.FETCH_RECIPIENT_COUNTRIES_REQUEST:
    case types.FETCH_RECIPIENT_FROM_PAYMENT_REQUEST:
      return true;
    case types.FETCH_RECIPIENT_SUCCESS:
    case types.FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS:
    case types.FETCH_RECIPIENT_FAILURE:
    case types.FETCH_RECIPIENT_FROM_PAYMENT_FAILURE:
    case types.FETCH_RECIPIENT_COUNTRIES_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const recipients = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_RECIPIENTS_REQUEST:
      return true;
    case types.FETCH_RECIPIENTS_SUCCESS:
    case types.FETCH_RECIPIENTS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const sender = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_SENDER_REQUEST:
      return true;
    case types.FETCH_SENDER_SUCCESS:
    case types.FETCH_SENDER_FAILURE:
      return false;
    default:
      return state;
  }
};

export const validateFields = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.VALIDATE_FIELDS_REQUEST:
      return true;
    case types.VALIDATE_FIELDS_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const offers = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_OFFERS_REQUEST:
      return true;
    case types.FETCH_OFFERS_SUCCESS:
    case types.FETCH_OFFERS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const recurringOffers = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_RECURRING_OFFERS_REQUEST:
      return true;
    case types.FETCH_RECURRING_OFFERS_SUCCESS:
    case types.FETCH_RECURRING_OFFERS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const offer = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_OFFER_REQUEST:
      return true;
    case types.FETCH_OFFER_SUCCESS:
    case types.FETCH_OFFER_FAILURE:
      return false;
    default:
      return state;
  }
};

export const payment = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.CREATE_PAYMENT_REQUEST:
    case types.FETCH_PAYMENT_REQUEST:
    case types.FETCH_USER_PAYMENT_REQUEST:
    case types.FETCH_LOGGED_USER_PAYMENT_REQUEST:
    case types.UPDATE_PAYMENT_REQUEST:
    case types.UPLOAD_LRS_INFO_REQUEST:
      return true;
    case types.CREATE_PAYMENT_SUCCESS:
    case types.FETCH_PAYMENT_SUCCESS:
    case types.FETCH_USER_PAYMENT_SUCCESS:
    case types.FETCH_LOGGED_USER_PAYMENT_SUCCESS:
    case types.FETCH_PAYMENT_FAILURE:
    case types.CREATE_PAYMENT_FAILURE:
    case types.FETCH_USER_PAYMENT_FAILURE:
    case types.FETCH_LOGGED_USER_PAYMENT_FAILURE:
    case types.UPDATE_PAYMENT_SUCCESS:
    case types.UPDATE_PAYMENT_FAILURE:
    case types.UPLOAD_LRS_INFO_SUCCESS:
    case types.UPLOAD_LRS_INFO_FAILURE:
      return false;
    default:
      return state;
  }
};

export const payments = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_USER_PAYMENTS_REQUEST:
      return true;
    case types.FETCH_USER_PAYMENTS_SUCCESS:
    case types.FETCH_USER_PAYMENTS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const createPayment = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.CREATE_PAYMENT_REQUEST:
      return true;
    case types.CREATE_PAYMENT_SUCCESS:
    case types.CREATE_PAYMENT_FAILURE:
      return false;
    default:
      return state;
  }
};

export const order = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.CREATE_ORDER_REQUEST:
    case types.FETCH_ORDER_REQUEST:
    case types.UPDATE_ORDER_REQUEST:
    case types.FETCH_ORDER_OWNERSHIP_REQUEST:
    case types.ADD_COUPON_REQUEST:
    case types.DELETE_COUPON_REQUEST:
    case types.ADD_REDEEM_CASHBACK_REQUEST:
    case types.DELETE_REDEEM_CASHBACK_REQUEST:
      return true;
    case types.CREATE_ORDER_FAILURE:
    case types.CREATE_ORDER_SUCCESS:
    case types.FETCH_ORDER_FAILURE:
    case types.FETCH_ORDER_SUCCESS:
    case types.UPDATE_ORDER_FAILURE:
    case types.UPDATE_ORDER_SUCCESS:
    case types.FETCH_ORDER_OWNERSHIP_FAILURE:
    case types.FETCH_ORDER_OWNERSHIP_SUCCESS:
    case types.ADD_COUPON_FAILURE:
    case types.DELETE_COUPON_FAILURE:
    case types.ADD_REDEEM_CASHBACK_FAILURE:
    case types.DELETE_REDEEM_CASHBACK_FAILURE:
      return false;
    default:
      return state;
  }
};

export const publicKey = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_PUBLIC_KEY_REQUEST:
      return true;
    case types.FETCH_PUBLIC_KEY_FAILURE:
    case types.FETCH_PUBLIC_KEY_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const paymentManagement = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.CANCEL_PAYMENT_REQUEST:
    case types.INITIATE_PAYMENT_REQUEST:
      return true;
    case types.CANCEL_PAYMENT_SUCCESS:
    case types.CANCEL_PAYMENT_FAILURE:
    case types.INITIATE_PAYMENT_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const authentication = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.LOGIN_USER_REQUEST:
      return true;
    case types.LOGIN_USER_FAILURE:
    case types.LOGIN_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const logout = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.LOGOUT_USER_REQUEST:
      return true;
    case types.LOGIN_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const user = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.CREATE_USER_REQUEST:
    case types.UPDATE_USER_REQUEST:
    case types.FETCH_USER_REQUEST:
    case types.FETCH_PAYMENT_METHODS_REQUEST:
    case types.REMOVE_CREDIT_CARD_REQUEST:
    case types.CARD_ACTIVE_PLANS_REQUEST:
    case types.CHANGE_CARD_ACTIVE_PLANS_REQUEST:
    case types.SAVE_CARD_REQUEST:
      return true;
    case types.CREATE_USER_FAILURE:
    case types.UPDATE_USER_FAILURE:
    case types.FETCH_USER_FAILURE:
    case types.FETCH_PAYMENT_METHODS_FAILURE:
    case types.REMOVE_CREDIT_CARD_FAILURE:
    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
    case types.FETCH_USER_SUCCESS:
    case types.FETCH_PAYMENT_METHODS_SUCCESS:
    case types.REMOVE_CREDIT_CARD_SUCCESS:
    case types.CARD_ACTIVE_PLANS_SUCCESS:
    case types.CARD_ACTIVE_PLANS_FAILURE:
    case types.CHANGE_CARD_ACTIVE_PLANS_SUCCESS:
    case types.CHANGE_CARD_ACTIVE_PLANS_FAILURE:
    case types.SAVE_CARD_SUCCESS:
    case types.SAVE_CARD_FAILURE:
    case types.REMOVE_NEW_CREDIT_CARD_SUCCESS:
    case types.REMOVE_NEW_CREDIT_CARD_FAILURE:
      return false;
    default:
      return state;
  }
};

export const geolocation = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.UPDATE_IP_COUNTRY_CODE_REQUEST:
      return true;
    case types.UPDATE_IP_COUNTRY_CODE_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const proofs = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_PROOFS_REQUEST:
    case types.DELETE_PROOF_FILE_REQUEST:
      return true;
    case types.FETCH_PROOFS_SUCCESS:
    case types.DELETE_PROOF_FILE_SUCCESS:
    case types.FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS:
    case types.FETCH_PROOFS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const documents = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_DOCUMENTS_REQUEST:
    case types.DELETE_FILE_REQUEST:
      return true;
    case types.FETCH_DOCUMENTS_SUCCESS:
    case types.FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS:
    case types.FETCH_DOCUMENTS_FAILURE:
    case types.DELETE_FILE_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const embedded = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_EMBEDDED:
      return action.payload;
    default:
      return state;
  }
};

export const refunds = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_REFUNDS_REQUEST:
      return true;
    case types.FETCH_PAYMENT_REFUNDS_SUCCESS:
    case types.FETCH_PAYMENT_REFUNDS_FAILURE:
      return false;
    default:
      return state;
  }
};

export const createPaymentFromOrder = (
  state = false,
  action: IsFetchingAction,
) => {
  switch (action.type) {
    case types.CREATE_PAYMENT_FROM_ORDER_REQUEST:
      return true;
    case types.CREATE_PAYMENT_FROM_ORDER_SUCCESS:
    case types.CREATE_PAYMENT_FROM_ORDER_FAILURE:
      return false;
    default:
      return state;
  }
};

export const recurringReceivables = (
  state = false,
  action: IsFetchingAction,
) => {
  switch (action.type) {
    case types.FETCH_USER_RECURRING_RECEIVABLES_REQUEST:
    case types.CREATE_RECURRING_RECEIVABLE_REQUEST:
      return true;
    case types.FETCH_USER_RECURRING_RECEIVABLES_SUCCESS:
    case types.FETCH_USER_RECURRING_RECEIVABLES_FAILURE:
    case types.FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_SUCCESS:
    case types.FETCH_USER_RECURRING_RECEIVABLES_SILENTLY_FAILURE:
    case types.CREATE_RECURRING_RECEIVABLE_SUCCESS:
    case types.CREATE_RECURRING_RECEIVABLE_FAILURE:
      return false;
    default:
      return state;
  }
};

export const recurringReceivableCancellation = (
  state = false,
  action: IsFetchingAction,
) => {
  switch (action.type) {
    case types.CANCEL_RECURRING_RECEIVABLE_REQUEST:
      return true;
    case types.CANCEL_RECURRING_RECEIVABLE_SUCCESS:
    case types.CANCEL_RECURRING_RECEIVABLE_FAILURE:
      return false;
    default:
      return state;
  }
};

export const trackingSetupData = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.FETCH_TRACKING_SETUP_DATA_REQUEST:
      return true;
    case types.FETCH_TRACKING_SETUP_DATA_SUCCESS:
    case types.FETCH_TRACKING_SETUP_DATA_FAILURE:
      return false;
    default:
      return state;
  }
};

export const refundKycDocument = (state = false, action: IsFetchingAction) => {
  switch (action.type) {
    case types.UPLOAD_REFUND_KYC_DOCUMENT_REQUEST:
      return true;
    case types.UPLOAD_REFUND_KYC_DOCUMENT_SUCCESS:
    case types.UPLOAD_REFUND_KYC_DOCUMENT_FAILURE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  authentication,
  countries,
  createPayment,
  createPaymentFromOrder,
  documents,
  embedded,
  geolocation,
  offer,
  offers,
  order,
  payment,
  payments,
  paymentManagement,
  proofs,
  publicKey,
  recipient,
  recipients,
  recurringOffers,
  recurringReceivableCancellation,
  recurringReceivables,
  refundKycDocument,
  refunds,
  sender,
  trackingSetupData,
  user,
  validateFields,
});
