import store from 'store/configureStore';
import fetchJson from 'utils/fetchJson';
import { PAYEX_URL, HTTPStatuses } from 'constants/index';
import { getUIStatusFromPolledStatus } from 'selectors';

export interface PollingService {
  pollPaymentStatus: (
    paymentId: string,
  ) => Promise<{ status: string | number } | undefined>;
  pollPaymentDocumentsAnalyzed: (
    paymentId: string,
  ) => Promise<Record<string, string>>;
  pollPlanStatus: (planId: string) => Promise<Record<string, string | number>>;
}

export const polling = {
  pollPaymentStatus: async (paymentId: string) => {
    if (store && store.get()) {
      const state = store.get()?.getState();
      const path = `${PAYEX_URL}/polling/payment_status/${paymentId}`;
      try {
        const response = await fetchJson<{ status: string }>(path);
        response.status = getUIStatusFromPolledStatus(response.status, state);

        return response;
      } catch (error) {
        return { status: HTTPStatuses.NOT_FOUND };
      }
    }
  },
  pollPaymentDocumentsAnalyzed: async (paymentId: string) => {
    const path = `${PAYEX_URL}/polling/document_analysis/${paymentId}`;
    return await fetchJson<Record<string, string>>(path);
  },
  pollPlanStatus: async (planId: string) => {
    const path = `${PAYEX_URL}/polling/plan_status/${planId}`;
    try {
      return await fetchJson<Record<string, string | number>>(path);
    } catch (error) {
      return { status: HTTPStatuses.NOT_FOUND };
    }
  },
};
