export const FETCH_KYC_REQUEST = 'FETCH_KYC_REQUEST';
export const FETCH_KYC_SUCCESS = 'FETCH_KYC_SUCCESS';
export const FETCH_KYC_FAILURE = 'FETCH_KYC_FAILURE';
export const UPLOAD_REFUND_KYC_DOCUMENT_REQUEST =
  'UPLOAD_REFUND_KYC_DOCUMENT_REQUEST';
export const UPLOAD_REFUND_KYC_DOCUMENT_SUCCESS =
  'UPLOAD_REFUND_KYC_DOCUMENT_SUCCESS';
export const UPLOAD_REFUND_KYC_DOCUMENT_FAILURE =
  'UPLOAD_REFUND_KYC_DOCUMENT_FAILURE';
export const REFUND_KYC_FIELDS_MISSING = 'REFUND_KYC_FIELDS_MISSING';
