import { getFieldById } from 'selectors';
import type { RootState } from 'reducers/types';
import type { Field, Section } from 'models';

export type SectionName = 'offers' | 'recipient' | 'sender';

const populatedFields = (state: RootState, fields: string[]) =>
  fields.map(id => getFieldById(state, id));

const isSectionPrefilled = (fields: Field[]) => {
  const requiredFields = fields.filter(field => field.required);

  if (requiredFields.length === 0) return true;
  return requiredFields.every(field => field.apiReadOnly || field.prefilled);
};

export const getSectionsByEntities = (
  state: RootState,
  entities: SectionName[],
) => {
  const sections = entities.reduce((accumulator, entity: SectionName) => {
    const sections = getSectionsByEntity(state, entity);
    return accumulator.concat(sections);
  }, [] as Section[]);

  return mergeDuplicatedSections(sections);
};

export const getSectionsByEntity = (state: RootState, entity: SectionName) => {
  const {
    entities: {
      sections: {
        [entity]: { byId: sectionsById, ids: sectionIds },
      },
    },
  } = state;

  return sectionIds.map(id => {
    const section = { ...sectionsById[id] };

    const fields = populatedFields(state, section.fields as string[]);
    const prefilled = isSectionPrefilled(fields);

    return { ...section, fields, prefilled } as Section;
  });
};

const mergeDuplicatedSections = (sections: Section[]) => {
  return sections.reduce((previousSections: Section[], section: Section) => {
    const repeatedSection = (previousSection: Section) =>
      previousSection.name === section.name;
    const previousSection = previousSections.find(repeatedSection);

    if (previousSection) {
      previousSection.fields = [...previousSection.fields, ...section.fields];
      previousSection.prefilled = isSectionPrefilled(section.fields as Field[]);
    } else {
      previousSections.push(section);
    }

    return previousSections;
  }, []);
};

export const getSenderConfirmationDetails = (state: RootState) => {
  const senderSections = getSectionsByEntity(state, 'sender');
  const offerSections = getSectionsByEntity(state, 'offers');

  senderSections.forEach(senderSection => {
    const foundSection = offerSections.find(offerSection =>
      offerSection.id.includes(senderSection.id),
    );

    if (!foundSection) {
      return false;
    }

    const offerFields = foundSection.fields;
    senderSection.fields = senderSection.fields.concat(offerFields);
  });

  return senderSections;
};
