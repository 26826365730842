export const isHttpErrorWithStatus = (maybeError: unknown) =>
  typeof maybeError === 'object' &&
  maybeError !== null &&
  'status' in maybeError;

export const isHttpErrorWithParsedBody = (maybeError: unknown) =>
  typeof maybeError === 'object' &&
  maybeError !== null &&
  'parsedBody' in maybeError;

export const isHttpError = (maybeError: unknown) =>
  typeof maybeError === 'object' &&
  maybeError !== null &&
  'status' in maybeError &&
  'parsedBody' in maybeError;
