import clientValidate from 'validate.js';
import XRegExp from 'xregexp';
import date from 'utils/date';
import { errorNotifier } from 'utils/errorNotifier';

const dateFormat = 'MM/DD/YYYY';
const dateRegExp = /^\d{2}\/\d{2}\/\d{4}$/;

clientValidate.extend(clientValidate.validators.datetime, {
  parse: function (value: string) {
    if (!value) {
      return false;
    }

    if (!dateRegExp.test(value)) {
      return true;
    }

    return date.utc(value, dateFormat);
  },
  format: function (value: string) {
    return date.utcFormat(value, dateFormat);
  },
});

interface CustomFormatOptions {
  pattern: string;
  message?: string;
}

clientValidate.validators.customFormat = (
  value: string,
  options: CustomFormatOptions,
) => {
  if (!value) {
    return;
  }

  try {
    const { pattern } = options;
    const ignoreCase = pattern.includes('(?i)');
    const patternStr = pattern
      .replace(/\\A/i, '^')
      .replace(/\\Z/i, '$')
      .replace(/\(\?i\)/g, '');

    const regExp = XRegExp(patternStr, ignoreCase ? 'i' : undefined);
    const message = options.message || '^is invalid';

    if (regExp.test(value)) {
      return;
    }

    return message;
  } catch (error) {
    errorNotifier.notify(error);
  }
};

export { clientValidate };
