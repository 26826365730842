import apiV3 from 'services/apiV3';

export const UserRepository = {
  async userExists({ email, token = '' }: { email: string; token?: string }) {
    try {
      const { users } = apiV3;

      await users.userExists({ email, token });

      return true;
    } catch (error) {
      return false;
    }
  },
};
