import React from 'react';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils';

import './AlipayBanner.scss';

const MORE_INFO_URL =
  ' https://help.flywire.com/hc/zh-cn/sections/360004458900-%E6%94%AF%E4%BB%98%E5%AE%9D-%E5%85%B15%E7%AF%87%E6%96%87%E7%AB%A0-';

function AlipayBanner() {
  const i18n = useTranslations();

  return (
    <div className="AlipayBanner" data-testid="alipayBanner">
      <div className="AlipayBanner-content">
        <div className="AlipayBanner-title">
          {i18n.t('senderInformation.alipay.alert.title')}
        </div>
        <div
          className="AlipayBanner-description"
          dangerouslySetInnerHTML={createMarkup(
            i18n.t('senderInformation.alipay.alert.description', {
              url: MORE_INFO_URL,
            }),
          )}
        />
      </div>
    </div>
  );
}

AlipayBanner.displayName = 'AlipayBanner';

export { AlipayBanner };
