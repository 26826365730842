import React, { ButtonHTMLAttributes, LegacyRef } from 'react';

const NOOP = () => {};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  forwardRef?: LegacyRef<HTMLButtonElement>;
}

/**
 * @deprecated Please use Tailwind Button
 */
const Button = ({
  children,
  onClick = NOOP,
  type = 'button',
  forwardRef,
  ...other
}: ButtonProps) => {
  return (
    <button
      className="Button"
      onClick={onClick}
      type={type}
      ref={forwardRef}
      {...other}
    >
      {children || 'Submit'}
    </button>
  );
};

export { Button };
