export const FETCH_RECIPIENT_REQUEST = 'FETCH_RECIPIENT_REQUEST';
export const FETCH_RECIPIENT_FROM_PAYMENT_REQUEST =
  'FETCH_RECIPIENT_FROM_PAYMENT_REQUEST';
export const FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS =
  'FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS';
export const FETCH_RECIPIENT_FROM_PAYMENT_FAILURE =
  'FETCH_RECIPIENT_FROM_PAYMENT_FAILURE';
export const FETCH_RECIPIENT_SUCCESS = 'FETCH_RECIPIENT_SUCCESS';
export const FETCH_RECIPIENT_FAILURE = 'FETCH_RECIPIENT_FAILURE';
export const FETCH_RECIPIENTS_REQUEST = 'FETCH_RECIPIENTS_REQUEST';
export const FETCH_RECIPIENTS_SUCCESS = 'FETCH_RECIPIENTS_SUCCESS';
export const FETCH_RECIPIENTS_FAILURE = 'FETCH_RECIPIENTS_FAILURE';
export const RECIPIENT_ENTITY = 'recipient';
export const SELECT_RECIPIENT = 'SELECT_RECIPIENT';
export const FETCH_RECIPIENT_COUNTRIES_REQUEST =
  'FETCH_RECIPIENT_COUNTRIES_REQUEST';
export const FETCH_RECIPIENT_COUNTRIES_SUCCESS =
  'FETCH_RECIPIENT_COUNTRIES_SUCCESS';
export const RECIPIENTS_WITH_NO_SHARE_PAYMENT = ['UVL', 'UNF'];
export const RECIPIENT_CAP = 'CAP';
export const WHITELISTED_RECIPIENTS_FOR_PO_BOX = ['LGQ', 'IIQ', 'VSK', 'QNM'];
