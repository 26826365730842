import {
  CREATE_PAYMENT_SUCCESS,
  FETCH_PAYMENT_SUCCESS,
  SELECT_PAYMENT,
} from 'constants/index';
import type { Fee } from 'models';

type FeesAction = {
  type:
    | typeof CREATE_PAYMENT_SUCCESS
    | typeof FETCH_PAYMENT_SUCCESS
    | typeof SELECT_PAYMENT;
  payload: {
    entities: {
      fees: Record<string, Fee>;
    };
  };
};

export default (state: Record<string, Fee> = {}, action: FeesAction) => {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
    case FETCH_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
      const {
        entities: { fees = {} },
      } = action.payload;
      return { ...state, ...fees };
    default:
      return state;
  }
};
