import * as FIELDS_ACTION_TYPES from 'constants/fields';
import * as GEOLOCATION_ACTION_TYPES from 'constants/geolocation';
import * as KYC_ACTION_TYPES from 'constants/kycs';
import * as OFFERS_ACTION_TYPES from 'constants/offers';
import * as ORDERS_ACTION_TYPES from 'constants/orders';
import * as PAYMENT_ACTION_TYPES from 'constants/payment';
import * as RECIPIENTS_ACTION_TYPES from 'constants/recipients';
import * as SMS_NOTIFICATION_ACTION_TYPES from 'constants/smsNotifications';
import * as UI_ACTION_TYPES from 'constants/ui';
import * as USER_ACTION_TYPES from 'constants/user';
import { CREATE_RECURRING_RECEIVABLE_SUCCESS } from 'constants/recurringReceivables';
import { DEFAULT_LOCALE } from 'constants/config';
import {
  DUPLICATED_PAN_PARAM,
  DUPLICATED_PAYMENT,
  DUPLICATED_PAYMENT_ERROR,
  GENERIC_ERROR,
  INBOUND_PROCESSOR_ERROR,
  TAXES_SERVICE_ERROR,
} from 'constants/errors';
import { cleanEmptyKeys } from 'utils/cleanEmptyKeys/cleanEmptyKeys';
import {
  getDefaultFieldValuesFromRecipient,
  getFieldValuesFromPayload,
  getReadOnlyFieldsFromPayload,
  getReadOnlyFieldsFromRecipient,
} from './utils';
import omit from 'lodash/omit';
import type {
  Currency,
  Error,
  Field,
  FieldValue,
  Link,
  Notification,
  OfferWithEntities,
  Payment,
  Price,
  Purchase,
  Recipient,
  Sender,
} from 'models';

const initialState = {
  apiReadOnlyFields: [],
  attemptedInvalidSMSNumber: false,
  autoLoadOnlineScript: true,
  createPaymentFailure: null,
  docCollectorFinished: true,
  duplicatedPayment: false,
  embedded: false,
  gatewayOrigin: false,
  gatewaySource: null,
  hideCancelPaymentOption: false,
  hideUserManagement: false,
  isChatVisible: false,
  isDocumentsFetchFailure: false,
  isImpersonatingUser: false,
  isPaymentProcess: false,
  isRepeatingPayment: false,
  isTrackingPage: false,
  lastPaymentFromSameRecipient: null,
  locale: DEFAULT_LOCALE,
  maxAmount: null,
  notifications: [],
  paymentsFromIndia: false,
  prefilledFields: [],
  prefilledRejectOrConfirm: false,
  readOnlyFields: [],
  receiveSMSNotification: false,
  receiveWhatsappNotification: null,
  recipient: null,
  redirectToReferrer: true,
  refundKYCProcessCompleted: false,
  showOfflineInstructions: false,
  stepIndex: 0,
  taxServiceUnavailable: false,
  theme: {},
  userExists: false,
  validRefundKYCToken: true,
};

type CurrentStepIndexAction =
  | {
      type: typeof UI_ACTION_TYPES.GO_TO_STEP;
      payload: {
        step: number;
      };
    }
  | {
      type:
        | typeof UI_ACTION_TYPES.RESTART
        | typeof UI_ACTION_TYPES.GO_TO_NEXT_STEP
        | typeof UI_ACTION_TYPES.GO_TO_PREV_STEP;
    };

export const currentStepIndex = (
  state = initialState.stepIndex,
  action: CurrentStepIndexAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.GO_TO_PREV_STEP: {
      const newState = state - 1;
      const defaultStepIndex = initialState.stepIndex;
      return newState < defaultStepIndex ? defaultStepIndex : newState;
    }
    case UI_ACTION_TYPES.GO_TO_NEXT_STEP:
      return state + 1;
    case UI_ACTION_TYPES.GO_TO_STEP:
      return action.payload.step;
    case UI_ACTION_TYPES.RESTART:
      return initialState.stepIndex;
    default:
      return state;
  }
};

type LocaleAction = {
  type: typeof UI_ACTION_TYPES.SET_LOCALE;
  payload: {
    locale: string;
  };
};

export const locale = (state = initialState.locale, action: LocaleAction) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_LOCALE:
      return action.payload.locale;
    default:
      return state;
  }
};

type IsRepeatingPaymentAction = {
  type: typeof UI_ACTION_TYPES.SET_REPEAT_PAYMENT_EXPERIENCE;
  payload: boolean;
};

export const isRepeatingPayment = (
  state = initialState.isRepeatingPayment,
  action: IsRepeatingPaymentAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_REPEAT_PAYMENT_EXPERIENCE:
      return action.payload;
    default:
      return state;
  }
};

type AttemptedInvalidSMSNumberAction = {
  type:
    | typeof SMS_NOTIFICATION_ACTION_TYPES.ENABLE_SENDER_SMS_NOTIFICATION_FAILURE
    | typeof UI_ACTION_TYPES.RESET_INVALID_SMS_ATTEMPT;
};

export const attemptedInvalidSMSNumber = (
  state = initialState.attemptedInvalidSMSNumber,
  action: AttemptedInvalidSMSNumberAction,
) => {
  switch (action.type) {
    case SMS_NOTIFICATION_ACTION_TYPES.ENABLE_SENDER_SMS_NOTIFICATION_FAILURE:
      return true;
    case UI_ACTION_TYPES.RESET_INVALID_SMS_ATTEMPT:
      return false;
    default:
      return state;
  }
};

type FieldValuesAction =
  | {
      type:
        | typeof OFFERS_ACTION_TYPES.RESET_SELECTED_OFFER
        | typeof UI_ACTION_TYPES.CLEAN_UI_FIELD_VALUES
        | typeof UI_ACTION_TYPES.RESTART;
    }
  | {
      type: typeof UI_ACTION_TYPES.SET_FIELD_VALUE;
      payload: {
        name: string;
        value: string;
      };
    }
  | {
      type: typeof UI_ACTION_TYPES.SET_FIELDS_VALUES;
      payload: {
        fields: Record<string, string | number>;
      };
    }
  | {
      type: typeof ORDERS_ACTION_TYPES.EMPTY_ERROR_FIELDS;
      payload: {
        entities: {
          errors: Record<string, Error>;
        };
        result: string[];
      };
    }
  | {
      type: typeof UI_ACTION_TYPES.CLEAR_FIELDS;
      payload: string[];
    }
  | {
      type: typeof RECIPIENTS_ACTION_TYPES.FETCH_RECIPIENT_SUCCESS;
      payload: {
        entities: {
          recipients: Record<string, Recipient>;
        };
        result: string;
      };
    }
  | {
      type: typeof OFFERS_ACTION_TYPES.FETCH_OFFERS_SUCCESS;
      payload: {
        offerCodes: string[];
      };
    }
  | {
      type: typeof OFFERS_ACTION_TYPES.FETCH_LEGACY_RECURRING_OFFERS_SUCCESS;
      payload: {
        offerCodes: string[];
      };
    }
  | {
      type:
        | typeof OFFERS_ACTION_TYPES.FETCH_OFFER_SUCCESS
        | typeof FIELDS_ACTION_TYPES.SET_DEFAULT_FIELD_VALUES;
      payload: {
        entities: {
          fields: Record<string, Field>;
        };
      };
    }
  | {
      type: typeof PAYMENT_ACTION_TYPES.FETCH_PAYMENT_SUCCESS;
      payload: {
        entities: {
          payments: Record<string, Payment>;
        };
        result: string;
      };
    }
  | {
      type: typeof PAYMENT_ACTION_TYPES.FETCH_LOGGED_USER_PAYMENT_SUCCESS;
      payload: Payment;
    }
  | {
      type:
        | typeof UI_ACTION_TYPES.RESET_FIELDS_VALUES
        | typeof ORDERS_ACTION_TYPES.FETCH_ORDER_SUCCESS;
      payload: {
        callback?: {
          id: string;
          url: string;
        };
        offer?: OfferWithEntities;
        provider?: string;
        recipient?: Recipient;
        sender?: Sender;
      };
    }
  | {
      type: typeof GEOLOCATION_ACTION_TYPES.SET_SENDER_COUNTRY_FIELD;
      payload: {
        countryCode: string;
      };
    }
  | {
      type: typeof ORDERS_ACTION_TYPES.FETCH_ORDER_BY_TOKEN_SUCCESS;
      payload: {
        sender: Sender;
        recipient: Recipient;
      };
    };

export const fieldValues = (
  state: Record<string, FieldValue> = {
    recipient: initialState.recipient,
  },
  action: FieldValuesAction,
) => {
  const nextState = { ...state };
  const EMPTY_STATE = {};

  switch (action.type) {
    case UI_ACTION_TYPES.SET_FIELDS_VALUES: {
      const { fields } = action.payload;
      const fieldsWithValue = cleanEmptyKeys(fields);

      // eslint-disable-next-line no-prototype-builtins
      if (fieldsWithValue.hasOwnProperty('amount')) {
        fieldsWithValue.amount = Number.parseInt(
          fieldsWithValue.amount as string,
        );
      }

      return { ...state, ...fieldsWithValue };
    }
    case ORDERS_ACTION_TYPES.EMPTY_ERROR_FIELDS: {
      const { errors } = action.payload.entities;

      const emptyFields: Record<string, unknown> = {};
      const errorKeys = Object.keys(errors);

      errorKeys.forEach(key => {
        const emptyValue = key === 'amount' ? 0 : '';
        emptyFields[key] = emptyValue;
      });

      return { ...state, ...emptyFields };
    }
    case ORDERS_ACTION_TYPES.FETCH_ORDER_SUCCESS:
    case UI_ACTION_TYPES.RESET_FIELDS_VALUES:
      return { ...state, ...getFieldValuesFromPayload(action.payload) };
    case PAYMENT_ACTION_TYPES.FETCH_PAYMENT_SUCCESS: {
      const {
        entities: { payments },
        result,
      } = action.payload;

      const payment = payments[result];

      return { ...state, ...getFieldValuesFromPayload(payment) };
    }
    case PAYMENT_ACTION_TYPES.FETCH_LOGGED_USER_PAYMENT_SUCCESS: {
      const payment = action.payload;

      return { ...state, ...getFieldValuesFromPayload(payment) };
    }
    case UI_ACTION_TYPES.SET_FIELD_VALUE: {
      let { name, value }: { name: string; value: string | number } =
        action.payload;
      if (name === 'amount') {
        value = Number.parseInt(value);
      }
      return { ...state, [name]: value };
    }
    case RECIPIENTS_ACTION_TYPES.FETCH_RECIPIENT_SUCCESS: {
      const recipientFieldValues = {
        recipient: action.payload.result,
        ...getDefaultFieldValuesFromRecipient(action.payload),
      };
      return { ...state, ...recipientFieldValues };
    }
    case FIELDS_ACTION_TYPES.SET_DEFAULT_FIELD_VALUES:
    case OFFERS_ACTION_TYPES.FETCH_OFFER_SUCCESS: {
      const {
        entities: { fields = {} },
      } = action.payload;
      return Object.keys(fields).reduce((acc, key) => {
        const { hidden, value } = fields[key];
        if (!hidden || value === undefined) return acc;
        return { ...acc, [key]: value };
      }, nextState);
    }
    case UI_ACTION_TYPES.CLEAR_FIELDS:
      return omit(state, action.payload);
    case OFFERS_ACTION_TYPES.RESET_SELECTED_OFFER:
      const { offer, interval, recurringSinglePaymentOption, ...rest } = state;

      return rest;
    case UI_ACTION_TYPES.RESTART:
    case UI_ACTION_TYPES.CLEAN_UI_FIELD_VALUES:
      return EMPTY_STATE;
    case GEOLOCATION_ACTION_TYPES.SET_SENDER_COUNTRY_FIELD: {
      const senderCountry = state[FIELDS_ACTION_TYPES.SENDER_COUNTRY_FIELD];
      const { countryCode } = action.payload;

      return !senderCountry && countryCode
        ? { ...state, [FIELDS_ACTION_TYPES.SENDER_COUNTRY_FIELD]: countryCode }
        : state;
    }
    case ORDERS_ACTION_TYPES.FETCH_ORDER_BY_TOKEN_SUCCESS:
      const COUNTRY_FIELD_ID = 'country';
      const {
        sender: { fields: senderFields },
        recipient: { items, id: recipientId },
      } = action.payload;

      const { value: senderCountry } =
        senderFields.find(
          ({ id }: { id: string }) => id === COUNTRY_FIELD_ID,
        ) || {};

      const amount = items.reduce(
        (acc: number, { amount }: { amount: number }) => acc + amount,
        0,
      );

      return {
        ...state,
        [FIELDS_ACTION_TYPES.SENDER_COUNTRY_FIELD]: senderCountry,
        amount,
        recipient: recipientId,
      };
    default:
      return state;
  }
};

type ReadOnlyFieldsAction =
  | {
      type: typeof UI_ACTION_TYPES.RESTART;
    }
  | {
      type: typeof UI_ACTION_TYPES.SET_READONLY_FIELDS;
      payload: string[];
    }
  | {
      type: typeof FIELDS_ACTION_TYPES.VALIDATE_FIELDS_SUCCESS;
      payload: {
        errorParams: string[];
        errors: Record<string, Error>;
      };
    }
  | {
      type: typeof FIELDS_ACTION_TYPES.UPDATE_READ_ONLY_FIELDS;
      payload: Record<string, string[]>;
    }
  | {
      type: typeof ORDERS_ACTION_TYPES.UPDATE_ORDER_FAILURE;
      payload: {
        result: string[];
      };
    }
  | {
      type: typeof RECIPIENTS_ACTION_TYPES.FETCH_RECIPIENT_SUCCESS;
      payload: {
        entities: {
          recipients: Record<string, Recipient>;
        };
        result: string;
      };
    };

export const readOnlyFields = (
  state: string[] = initialState.readOnlyFields,
  action: ReadOnlyFieldsAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_READONLY_FIELDS:
      return [...state, ...action.payload];
    case FIELDS_ACTION_TYPES.VALIDATE_FIELDS_SUCCESS: {
      const { errorParams } = action.payload;
      return state.filter(field => !errorParams.includes(field));
    }
    case FIELDS_ACTION_TYPES.UPDATE_READ_ONLY_FIELDS: {
      const fields = Object.keys(action.payload);

      return state.filter(field => !fields.includes(field));
    }
    case ORDERS_ACTION_TYPES.UPDATE_ORDER_FAILURE: {
      const { result } = action.payload;

      return state.filter(field => !result.includes(field));
    }
    case UI_ACTION_TYPES.RESTART:
      return initialState.readOnlyFields;
    case RECIPIENTS_ACTION_TYPES.FETCH_RECIPIENT_SUCCESS: {
      const recipientReadOnlydFields = getReadOnlyFieldsFromRecipient(
        action.payload,
      );
      return state.concat(recipientReadOnlydFields);
    }
    default:
      return state;
  }
};

type ThemeAction =
  | {
      type: typeof UI_ACTION_TYPES.SET_THEME;
      payload: Record<string, string>;
    }
  | {
      type: typeof UI_ACTION_TYPES.UNSET_EMBEDDED_EXPERIENCE;
    };

export const theme = (
  state: Record<string, string> = initialState.theme,
  action: ThemeAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_THEME:
      return { ...state, ...action.payload };
    case UI_ACTION_TYPES.UNSET_EMBEDDED_EXPERIENCE:
      return {};
    default:
      return state;
  }
};

type ApiReadOnlyFieldsAction = {
  type: typeof ORDERS_ACTION_TYPES.FETCH_ORDER_SUCCESS;
  payload: {
    callback?: {
      id: string;
      url: string;
    };
    offer?: OfferWithEntities;
    provider?: string;
    recipient?: Recipient;
    sender?: Sender;
  };
};

export const apiReadOnlyFields = (
  state: string[] = initialState.apiReadOnlyFields,
  action: ApiReadOnlyFieldsAction,
) => {
  switch (action.type) {
    case ORDERS_ACTION_TYPES.FETCH_ORDER_SUCCESS:
      return [...state, ...getReadOnlyFieldsFromPayload(action.payload)];
    default:
      return state;
  }
};

type PrefilledFieldsAction =
  | {
      type:
        | typeof UI_ACTION_TYPES.SET_PREFILLED_FIELDS
        | typeof UI_ACTION_TYPES.RESTART;
      payload: string[];
    }
  | {
      type: typeof FIELDS_ACTION_TYPES.VALIDATE_FIELDS_SUCCESS;
      payload: {
        errorParams: string[];
        errors: Record<string, Error>;
      };
    }
  | {
      type: typeof ORDERS_ACTION_TYPES.EMPTY_ERROR_FIELDS;
      payload: {
        entities: {
          errors: Record<string, Error>;
        };
        result: string[];
      };
    };

export const prefilledFields = (
  state: string[] = initialState.prefilledFields,
  action: PrefilledFieldsAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_PREFILLED_FIELDS:
      return action.payload;
    case FIELDS_ACTION_TYPES.VALIDATE_FIELDS_SUCCESS: {
      const { errorParams } = action.payload;
      return state.filter(field => !errorParams.includes(field));
    }
    case ORDERS_ACTION_TYPES.EMPTY_ERROR_FIELDS: {
      const { result } = action.payload;
      const removeErrorFields = (field: string) => !result.includes(field);

      return state.filter(removeErrorFields);
    }
    case UI_ACTION_TYPES.RESTART:
      return initialState.prefilledFields;
    default:
      return state;
  }
};

type NotificationsAction =
  | {
      type: typeof UI_ACTION_TYPES.UI_NOTIFICATION_NEW;
      payload: Notification;
    }
  | {
      type: typeof UI_ACTION_TYPES.UI_NOTIFICATION_REMOVE;
      payload: string;
    };

export const notifications = (
  state: Notification[] = initialState.notifications,
  action: NotificationsAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.UI_NOTIFICATION_NEW:
      return [action.payload, ...state];
    case UI_ACTION_TYPES.UI_NOTIFICATION_REMOVE:
      return state.filter((n: Notification) => n.id !== action.payload);
    default:
      return state;
  }
};

type EmbeddedAction = {
  type:
    | typeof UI_ACTION_TYPES.SET_EMBEDDED_EXPERIENCE
    | typeof UI_ACTION_TYPES.UNSET_EMBEDDED_EXPERIENCE;
};

export const embedded = (
  state = initialState.embedded,
  action: EmbeddedAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_EMBEDDED_EXPERIENCE:
      return true;
    case UI_ACTION_TYPES.UNSET_EMBEDDED_EXPERIENCE:
      return false;
    default:
      return state;
  }
};

type GatewayOriginAction = {
  type: typeof UI_ACTION_TYPES.SET_GATEWAY_ORIGIN;
};

export const gatewayOrigin = (
  state = initialState.gatewayOrigin,
  action: GatewayOriginAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_GATEWAY_ORIGIN:
      return true;
    default:
      return state;
  }
};

type GatewaySourceAction = {
  type: typeof UI_ACTION_TYPES.SET_GATEWAY_SOURCE;
  payload: string;
};

export const gatewaySource = (
  state: string | null = initialState.gatewaySource,
  action: GatewaySourceAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_GATEWAY_SOURCE:
      return action.payload;
    default:
      return state;
  }
};

type ShowOfflineInstructionsAction = {
  type: typeof UI_ACTION_TYPES.SHOW_OFFLINE_INSTRUCTIONS;
};

export const showOfflineInstructions = (
  state = initialState.showOfflineInstructions,
  action: ShowOfflineInstructionsAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SHOW_OFFLINE_INSTRUCTIONS:
      return true;
    default:
      return state;
  }
};

type DocCollectorFinishedAction = {
  type: typeof UI_ACTION_TYPES.SET_DOC_COLLECTOR_FINISHED;
  payload: {
    finished: boolean;
  };
};

export const docCollectorFinished = (
  state = initialState.docCollectorFinished,
  action: DocCollectorFinishedAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_DOC_COLLECTOR_FINISHED: {
      const {
        payload: { finished },
      } = action;

      return finished;
    }
    default:
      return state;
  }
};

type PrefilledRejectOrConfirmAction = {
  type: typeof UI_ACTION_TYPES.SET_PREFILLED_REJECT_OR_CONFIRM;
  payload: {
    status: boolean;
  };
};

export const prefilledRejectOrConfirm = (
  state = initialState.prefilledRejectOrConfirm,
  action: PrefilledRejectOrConfirmAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_PREFILLED_REJECT_OR_CONFIRM: {
      const {
        payload: { status },
      } = action;

      return status;
    }
    default:
      return state;
  }
};

type ReceiveSMSNotificationAction =
  | {
      type: typeof UI_ACTION_TYPES.SET_RECEIVE_SMS_NOTIFICATION;
      payload: {
        receiveSMSNotification: boolean;
      };
    }
  | {
      type: typeof SMS_NOTIFICATION_ACTION_TYPES.RESET_RECEIVE_SMS_NOTIFICATIONS;
    };

export const receiveSMSNotification = (
  state = initialState.receiveSMSNotification,
  action: ReceiveSMSNotificationAction,
) => {
  switch (action.type) {
    case SMS_NOTIFICATION_ACTION_TYPES.RESET_RECEIVE_SMS_NOTIFICATIONS:
      return false;
    case UI_ACTION_TYPES.SET_RECEIVE_SMS_NOTIFICATION: {
      const { receiveSMSNotification } = action.payload;

      return receiveSMSNotification;
    }
    default:
      return state;
  }
};

type ReceiveWhatsappNotificationAction = {
  type: typeof UI_ACTION_TYPES.SET_RECEIVE_WHATSAPP_NOTIFICATION;
  payload: {
    receiveWhatsappNotification: boolean | null;
  };
};

export const receiveWhatsappNotification = (
  state = initialState.receiveWhatsappNotification,
  action: ReceiveWhatsappNotificationAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_RECEIVE_WHATSAPP_NOTIFICATION: {
      const { receiveWhatsappNotification } = action.payload;

      return receiveWhatsappNotification;
    }
    default:
      return state;
  }
};

type DuplicatedPaymentAction = {
  type: typeof PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE;
  payload: {
    errors: Error[];
  };
};

export const duplicatedPayment = (
  state = initialState.duplicatedPayment,
  action: DuplicatedPaymentAction,
) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE: {
      const { errors } = action.payload;

      const error = errors.find(error => error.type === DUPLICATED_PAYMENT);

      return !!error;
    }
    default:
      return state;
  }
};

type DuplicatedPANNumberAction = {
  type: typeof PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE;
  payload: {
    errors: Error[];
  };
};

export const duplicatedPANNumber = (
  state = initialState.duplicatedPayment,
  action: DuplicatedPANNumberAction,
) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE: {
      const { errors } = action.payload;

      const error = errors.find(
        error =>
          error.type === DUPLICATED_PAYMENT_ERROR &&
          error.param === DUPLICATED_PAN_PARAM,
      );

      return !!error;
    }
    default:
      return state;
  }
};

type TaxServiceUnavailableAction =
  | {
      type: typeof PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE;
      payload: {
        errors: Error[];
      };
    }
  | {
      type: typeof UI_ACTION_TYPES.GO_TO_STEP;
    };

export const taxServiceUnavailable = (
  state = initialState.taxServiceUnavailable,
  action: TaxServiceUnavailableAction,
) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE: {
      const { errors } = action.payload;

      const error = errors.find(error => error.type === TAXES_SERVICE_ERROR);

      return !!error;
    }
    case UI_ACTION_TYPES.GO_TO_STEP: {
      return initialState.taxServiceUnavailable;
    }
    default:
      return state;
  }
};

type CreatePaymentFailureAction =
  | {
      type: typeof PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE;
      payload: {
        errors: Error[];
      };
    }
  | {
      type:
        | typeof PAYMENT_ACTION_TYPES.CREATE_PAYMENT_REQUEST
        | typeof ORDERS_ACTION_TYPES.UPDATE_ORDER_SUCCESS;
    };

export const createPaymentFailure = (
  state: string | null = initialState.createPaymentFailure,
  action: CreatePaymentFailureAction,
) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.CREATE_PAYMENT_FAILURE: {
      const { errors = [] } = action.payload;

      const error = errors.find(
        error => error.type === INBOUND_PROCESSOR_ERROR,
      );

      return error ? error.message : GENERIC_ERROR;
    }
    case PAYMENT_ACTION_TYPES.CREATE_PAYMENT_REQUEST:
    case ORDERS_ACTION_TYPES.UPDATE_ORDER_SUCCESS:
      return initialState.createPaymentFailure;
    default:
      return state;
  }
};

type LastPaymentFromSameRecipientAction =
  | {
      type: typeof PAYMENT_ACTION_TYPES.FETCH_USER_PAYMENT_SUCCESS;
      payload: Payment;
    }
  | {
      type: typeof USER_ACTION_TYPES.LOGOUT_USER_SUCCESS;
    };

export const lastPaymentFromSameRecipient = (
  state: Payment | null = initialState.lastPaymentFromSameRecipient,
  action: LastPaymentFromSameRecipientAction,
) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.FETCH_USER_PAYMENT_SUCCESS:
      return action.payload;
    case USER_ACTION_TYPES.LOGOUT_USER_SUCCESS:
      return initialState.lastPaymentFromSameRecipient;
    default:
      return state;
  }
};

type HideUserManagementAction = {
  type: typeof UI_ACTION_TYPES.HIDE_USER_MANAGEMENT;
};

export const hideUserManagement = (
  state = initialState.hideUserManagement,
  action: HideUserManagementAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.HIDE_USER_MANAGEMENT:
      return true;
    default:
      return state;
  }
};

type RefundKYCProcessCompletedAction = {
  type:
    | typeof KYC_ACTION_TYPES.UPLOAD_REFUND_KYC_DOCUMENT_SUCCESS
    | typeof KYC_ACTION_TYPES.FETCH_KYC_SUCCESS;
};

export const refundKYCProcessCompleted = (
  state = initialState.refundKYCProcessCompleted,
  action: RefundKYCProcessCompletedAction,
) => {
  switch (action.type) {
    case KYC_ACTION_TYPES.UPLOAD_REFUND_KYC_DOCUMENT_SUCCESS:
      return true;
    case KYC_ACTION_TYPES.FETCH_KYC_SUCCESS:
      return initialState.refundKYCProcessCompleted;
    default:
      return state;
  }
};

type ValidRefundKYCTokenAction = {
  type: typeof KYC_ACTION_TYPES.FETCH_KYC_FAILURE;
};

export const validRefundKYCToken = (
  state = initialState.validRefundKYCToken,
  action: ValidRefundKYCTokenAction,
) => {
  switch (action.type) {
    case KYC_ACTION_TYPES.FETCH_KYC_FAILURE:
      return false;
    default:
      return state;
  }
};

type HideCancelPaymentOptionAction = {
  type:
    | typeof PAYMENT_ACTION_TYPES.CREATE_PAYMENT_REQUEST
    | typeof PAYMENT_ACTION_TYPES.FETCH_PAYMENT_REQUEST
    | typeof UI_ACTION_TYPES.HIDE_CANCEL_PAYMENT_OPTION
    | typeof UI_ACTION_TYPES.SHOW_CANCEL_PAYMENT_OPTION;
};

export const hideCancelPaymentOption = (
  state = initialState.hideCancelPaymentOption,
  action: HideCancelPaymentOptionAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.HIDE_CANCEL_PAYMENT_OPTION:
      return true;
    case UI_ACTION_TYPES.SHOW_CANCEL_PAYMENT_OPTION:
    case PAYMENT_ACTION_TYPES.CREATE_PAYMENT_REQUEST:
    case PAYMENT_ACTION_TYPES.FETCH_PAYMENT_REQUEST:
      return false;
    default:
      return state;
  }
};

type AutoLoadOnlineScriptAction = {
  type: typeof UI_ACTION_TYPES.SET_SHOULD_AUTO_LOAD_ONLINE_SCRIPT;
  payload: boolean;
};

export const autoLoadOnlineScript = (
  state = initialState.autoLoadOnlineScript,
  action: AutoLoadOnlineScriptAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_SHOULD_AUTO_LOAD_ONLINE_SCRIPT:
      return action.payload;
    default:
      return state;
  }
};

type RedirectToReferrerAction =
  | {
      type: typeof UI_ACTION_TYPES.SET_REDIRECT_TO_REFERRER;
      payload: boolean;
    }
  | {
      type: typeof USER_ACTION_TYPES.LOGOUT_USER_SUCCESS;
    };

export const redirectToReferrer = (
  state = initialState.redirectToReferrer,
  action: RedirectToReferrerAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_REDIRECT_TO_REFERRER:
      return action.payload;
    case USER_ACTION_TYPES.LOGOUT_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

type IsPaymentProcessAction = {
  type:
    | typeof PAYMENT_ACTION_TYPES.CREATE_PAYMENT_SUCCESS
    | typeof UI_ACTION_TYPES.SET_PAYMENT_PROCESS
    | typeof UI_ACTION_TYPES.UNSET_PAYMENT_PROCESS
    | typeof CREATE_RECURRING_RECEIVABLE_SUCCESS;
};

export const isPaymentProcess = (
  state = initialState.isPaymentProcess,
  action: IsPaymentProcessAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_PAYMENT_PROCESS:
      return true;
    case UI_ACTION_TYPES.UNSET_PAYMENT_PROCESS:
    case PAYMENT_ACTION_TYPES.CREATE_PAYMENT_SUCCESS:
    case CREATE_RECURRING_RECEIVABLE_SUCCESS:
      return false;
    default:
      return state;
  }
};

type IsTrackingPageAction = {
  type:
    | typeof UI_ACTION_TYPES.SET_TRACKING_PAGE
    | typeof UI_ACTION_TYPES.UNSET_TRACKING_PAGE;
};

export const isTrackingPage = (
  state = initialState.isTrackingPage,
  action: IsTrackingPageAction,
) => {
  switch (action.type) {
    case UI_ACTION_TYPES.SET_TRACKING_PAGE:
      return true;
    case UI_ACTION_TYPES.UNSET_TRACKING_PAGE:
      return false;
    default:
      return state;
  }
};

type UserExistsAction = {
  type:
    | typeof USER_ACTION_TYPES.USER_EXISTS_FAILURE
    | typeof USER_ACTION_TYPES.USER_EXISTS_SUCCESS;
};

export const userExists = (
  state = initialState.userExists,
  action: UserExistsAction,
) => {
  switch (action.type) {
    case USER_ACTION_TYPES.USER_EXISTS_SUCCESS:
      return true;
    case USER_ACTION_TYPES.USER_EXISTS_FAILURE:
      return false;
    default:
      return state;
  }
};

type IsChatVisibleAction = {
  type: typeof UI_ACTION_TYPES.SET_CHAT_VISIBLE;
};

export const isChatVisible = (
  state = initialState.isChatVisible,
  action: IsChatVisibleAction,
) => {
  if (action.type === UI_ACTION_TYPES.SET_CHAT_VISIBLE) return true;
  return state;
};

type IsImpersonatingUserAction = {
  type:
    | typeof USER_ACTION_TYPES.IMPERSONATE_USER_SUCCESS
    | typeof USER_ACTION_TYPES.LOGOUT_USER_SUCCESS;
};

export const isImpersonatingUser = (
  state = initialState.isImpersonatingUser,
  action: IsImpersonatingUserAction,
) => {
  switch (action.type) {
    case USER_ACTION_TYPES.IMPERSONATE_USER_SUCCESS:
      return true;
    case USER_ACTION_TYPES.LOGOUT_USER_SUCCESS:
      return false;
    default:
      return state;
  }
};

type IsDocumentsFetchFailureAction = {
  type:
    | typeof PAYMENT_ACTION_TYPES.FETCH_DOCUMENTS_FAILURE
    | typeof PAYMENT_ACTION_TYPES.FETCH_DOCUMENTS_SUCCESS
    | typeof PAYMENT_ACTION_TYPES.FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS;
};

export const isDocumentsFetchFailure = (
  state = initialState.isDocumentsFetchFailure,
  action: IsDocumentsFetchFailureAction,
) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.FETCH_DOCUMENTS_FAILURE:
      return true;
    case PAYMENT_ACTION_TYPES.FETCH_DOCUMENTS_SUCCESS:
    case PAYMENT_ACTION_TYPES.FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS:
      return false;
    default:
      return state;
  }
};

type MaxAmountAction = {
  type: typeof UI_ACTION_TYPES.SET_MAX_AMOUNT;
  payload: number;
};

export const maxAmount = (
  state: number | null = initialState.maxAmount,
  action: MaxAmountAction,
) => {
  if (action.type === UI_ACTION_TYPES.SET_MAX_AMOUNT) {
    return action.payload;
  } else {
    return state;
  }
};

type PaymentsToIndiaAction =
  | {
      type: typeof PAYMENT_ACTION_TYPES.FETCH_USER_PAYMENTS_SUCCESS;
      payload?: {
        links: Link[];
        payments: Payment[];
        totalEntries: number;
        totalPages: number;
      };
    }
  | {
      type: typeof USER_ACTION_TYPES.LOGOUT_USER_SUCCESS;
    };

export const paymentsFromIndia = (
  state: boolean = initialState.paymentsFromIndia,
  action: PaymentsToIndiaAction,
) => {
  switch (action.type) {
    case PAYMENT_ACTION_TYPES.FETCH_USER_PAYMENTS_SUCCESS:
      const payments = action.payload?.payments || [];
      const links = action.payload?.links || [];
      let hasPayments = false;
      if (
        links.some(obj => obj.rel === 'self' && obj.href.includes('page=1'))
      ) {
        hasPayments = payments.some(payment => {
          return (
            PAYMENT_ACTION_TYPES.PAYMENT_CURRENCIES_FROM_ELIGIBLE_FOR_REMITTANCE.includes(
              ((payment.price as Price)?.currency as Currency)?.code,
            ) &&
            PAYMENT_ACTION_TYPES.PAYMENT_CURRENCIES_TO_ELIGIBLE_FOR_REMITTANCE.includes(
              ((payment.purchase as Purchase)?.currency as Currency)?.code,
            )
          );
        });
      }
      return hasPayments ? true : state;
    case USER_ACTION_TYPES.LOGOUT_USER_SUCCESS:
      return initialState.paymentsFromIndia;
    default:
      return state;
  }
};
