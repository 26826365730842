export const fileFormatter = {
  toBase64: (file: File): Promise<string> => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = fileFormatter.cleanBase64(reader.result);
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }

        resolve(encoded);
      };
    });
  },

  cleanBase64: (base64: string | ArrayBuffer | null) =>
    typeof base64 === 'string'
      ? base64?.replace(/^data:(.*;base64,)?/, '').trim()
      : '',
};
