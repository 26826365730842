/* eslint-disable camelcase */
import {
  CardInstructionsAttributes,
  BankAccountInstructionsAttributes,
} from '../models/models';

export type CardInstructionsCallParams = {
  action_button?: string;
  currencyCode?: string;
  country?: string;
  locale?: string;
  payor_id_prefix?: string;
  theme?: string;
  recipient_id?: string;
};

export type BankAccountInstructionsCallParams = {
  country?: string;
  locale?: string;
  recipient_id?: string;
};

type Mapper = {
  cards: {
    mapToBackEnd: (
      params: CardInstructionsAttributes,
    ) => CardInstructionsCallParams;
  };

  bankAccounts: {
    mapToBackEnd: (
      params: BankAccountInstructionsAttributes,
    ) => BankAccountInstructionsCallParams;
  };
};

const mapper: Mapper = {
  cards: {
    mapToBackEnd: params => {
      const {
        actionButton,
        currencyCode,
        payorIdPrefix,
        payorIdType,
        recipientId,
        ...rest
      } = params;

      return {
        action_button: actionButton,
        currency_code: currencyCode,
        payor_id_prefix: payorIdPrefix,
        payor_id_type: payorIdType,
        recipient_id: recipientId,
        ...rest,
      };
    },
  },

  bankAccounts: {
    mapToBackEnd: params => {
      const { recipientId, ...rest } = params;

      return {
        recipient_id: recipientId,
        ...rest,
      };
    },
  },
};

export default mapper;
