import React, { MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import {
  WithFlysiteUrls,
  WithFlysiteUrlsProps,
} from 'components/HOC/WithFlysiteUrls/WithFlysiteUrls';
import { useTranslations } from 'utils';

interface ContactProps extends WithFlysiteUrlsProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const ContactComponent: React.FunctionComponent<ContactProps> = ({
  contactUrl,
  onClick,
}) => {
  const i18n = useTranslations();
  const handleClick: MouseEventHandler<HTMLAnchorElement> = evt => {
    onClick?.(evt);
  };

  return (
    <a
      href={contactUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {i18n.t('step.aside.contact')}
    </a>
  );
};

ContactComponent.propTypes = {
  contactUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const Contact = WithFlysiteUrls(ContactComponent);

export { Contact };
