/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck too hard to type
import React from 'react';

export default function (factory: () => Promise<unknown>, retries = 2) {
  const moduleImport =
    !isNaN(retries) && retries > 0 ? retryLazy(factory, retries) : factory;

  const Component = React.lazy(moduleImport);
  Component.preload = moduleImport;

  return Component;
}

export function retryLazy(factory: () => Promise<unknown>, retries = 2) {
  let count = 1;
  const getModule = () =>
    new Promise((resolve, reject) => {
      factory()
        .then((module: unknown) => resolve(module))
        .catch((error: unknown) => {
          if (count > retries) {
            return reject(error);
          }
          return resolve(count++ && getModule());
        });
    });
  return () => getModule();
}
