import { useEffect } from 'react';
import { useWizard } from 'components/Wizard/components/context';

function NextStep() {
  const { goToNextStep } = useWizard();
  useEffect(() => goToNextStep(), []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
}

NextStep.displayName = 'NextStep';

export { NextStep };
