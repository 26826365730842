import React, { forwardRef, useEffect, useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {
  HCAPTCHA_CN_API_HOST,
  HCAPTCHA_CN_ASSET_HOST,
  HCAPTCHA_CN_ENDPOINT,
  HCAPTCHA_CN_IMG_HOST,
  HCAPTCHA_CN_REPORT_API,
} from 'constants/captcha';
import { CHINA_COUNTRY_CODE } from 'constants/ui';
import { captchaKey } from 'utils/captcha/captcha';
import geolocation from 'services/geolocation/geolocation';
import { usePeertransferFlags } from 'hooks/usePeertransferFlags/usePeertransferFlags';
import { FF_CAPTCHA } from 'constants/features';

const chinaHosts = {
  apihost: HCAPTCHA_CN_API_HOST,
  endpoint: HCAPTCHA_CN_ENDPOINT,
  assethost: HCAPTCHA_CN_ASSET_HOST,
  imghost: HCAPTCHA_CN_IMG_HOST,
  reportapi: HCAPTCHA_CN_REPORT_API,
};

const isChina = (countryCode?: string) =>
  countryCode?.toUpperCase() === CHINA_COUNTRY_CODE;

export type CaptchaProps = {
  countryCode?: string;
  onVerify: (token: string) => Promise<void>;
};

export type CaptchaRef = HCaptcha;

export const Captcha = forwardRef<HCaptcha, CaptchaProps>(
  ({ onVerify }, ref) => {
    const [countryCode, setCountryCode] = useState<string | undefined>();

    const { data: captchaFlags } = usePeertransferFlags(FF_CAPTCHA);

    const isCaptchaEnabled = captchaFlags?.[FF_CAPTCHA];

    useEffect(() => {
      const getCountryCode = async () => {
        try {
          const location = await geolocation.fetch();
          setCountryCode(location.countryCode || '');
        } catch {
          setCountryCode('');
        }
      };

      isCaptchaEnabled && getCountryCode();
    }, [isCaptchaEnabled]);

    if (!isCaptchaEnabled || countryCode === undefined) return null;

    const chinaProps = { ...(isChina(countryCode) && chinaHosts) };

    return (
      <HCaptcha
        ref={ref}
        size="invisible"
        sitekey={captchaKey}
        onVerify={onVerify}
        {...chinaProps}
      />
    );
  },
);
