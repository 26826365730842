import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { trackPaymentStep } from 'actions';
import { Heading } from 'components/Heading/Heading';
import { Content } from 'components/Layouts/components/Content/Content';

import './PaymentStep.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;
type PaymentStepProps = PropsFromRedux & {
  alert?: React.ReactNode;
  ariaLabel?: string;
  children: React.ReactNode;
  navigation: React.ReactNode;
  stepName: string;
  title?: string;
};

const PaymentStepComponent = ({
  alert,
  ariaLabel,
  children,
  navigation,
  stepName,
  title,
  trackPaymentStep,
}: PaymentStepProps) => {
  useEffect(() => {
    trackPaymentStep(stepName);
  }, [stepName, trackPaymentStep]);

  return (
    <section className="PaymentStep">
      <div className="PaymentStep-alert">{alert}</div>
      <Content>
        <header className="PaymentStep-header no-print">
          {title && (
            <Heading
              as="h1"
              size="huge"
              className="margin-0"
              id="Page-title"
              aria-label={ariaLabel || title}
              data-testid="paymentStepHeader"
            >
              {title}
            </Heading>
          )}
        </header>
        <div className="PaymentStep-content">{children}</div>
      </Content>
      <div className="PaymentStep-footer">{navigation}</div>
    </section>
  );
};

const mapDispatchToProps = {
  trackPaymentStep,
};

const connector = connect(null, mapDispatchToProps);
const PaymentStep = connector(PaymentStepComponent);

export { PaymentStep };
