import React, { useState } from 'react';
import { useTranslations } from 'utils';
import { formatNonFXFeedbackResponsesForRequest } from 'utils/formatNonFXFeedbackResponsesForRequest/formatNonFXFeedbackResponsesForRequest';
import { useDispatch } from 'react-redux';
import { Modal } from 'components/ui/Modal/Modal';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'lib/flywire-tailwind/Button';
import { QuestionsAutocomplete } from './components/QuestionsAutocomplete';
import {
  bankOptions,
  dealerOptions,
  nonBankingOptions,
  usdPaymentReasonsOptions,
  payerOptions,
  sourceOfFundsOptions,
} from './data/data';
import { TextInput } from 'components/input/TextInput/TextInput';
import { clientValidate } from 'utils/clientValidate/clientValidate';
import { WithFocusOnError } from 'components/HOC/WithFocusOnError/WithFocusOnError';
import {
  trackClickCloseFXFeedbackForm,
  trackClickSubmitFXFeedbackForm,
} from 'actions/tracker/tracker';
import { keysEnum } from './data/constants';
import { updateOrderMetadata } from 'actions/entities/orders/orders';
import type { Option } from 'models/option';

import './FeedBackQuestionsModal.scss';

type FeedbackQuestionsModalComponentProps = {
  closeModal: () => void;
  focusOnFirstError: () => void;
  isOpen: boolean;
  onSubmit: () => void;
};

const NonBankingSelector = ({
  handleChange,
  selectedOption,
  error,
}: {
  handleChange: (value: string) => void;
  selectedOption: string;
  error?: string;
}) => {
  return (
    <QuestionsAutocomplete
      error={error}
      name="non_banking_selector"
      handleChange={handleChange}
      required={true}
      dataTestId="non_banking_selector_testId"
      placeholder={
        keysEnum[
          'nonFxPaymentInfo.feedbackQuestions.nonBankingOptions.placeholder'
        ]
      }
      options={nonBankingOptions}
      selectedOption={selectedOption}
    />
  );
};

const DealerSelector = ({
  error,
  handleChange,
  selectedOption,
}: {
  handleChange: (value: string) => void;
  selectedOption: string;
  error?: string;
}) => {
  return (
    <QuestionsAutocomplete
      error={error}
      name="dealer_selector"
      dataTestId="dealer_selector_testId"
      handleChange={handleChange}
      required={true}
      placeholder={
        keysEnum[
          'nonFxPaymentInfo.feedbackQuestions.dealerIIOptions.placeholder'
        ]
      }
      options={dealerOptions}
      selectedOption={selectedOption}
    />
  );
};

const BankSelector = ({
  error,
  handleChange,
  selectedOption,
}: {
  handleChange: (value: string) => void;
  selectedOption: string;
  error?: string;
}) => {
  return (
    <QuestionsAutocomplete
      error={error}
      dataTestId="bank_selector_testId"
      name="bank_selector"
      handleChange={handleChange}
      required={true}
      placeholder={
        keysEnum['nonFxPaymentInfo.feedbackQuestions.bankOptions.placeholder']
      }
      options={bankOptions}
      selectedOption={selectedOption}
    />
  );
};

const USDPaymentReasonSelector = ({
  handleChange,
  selectedOption,
  error,
  options,
}: {
  handleChange: (value: string) => void;
  selectedOption: string;
  error?: string;
  options: Option[];
}) => {
  return (
    <QuestionsAutocomplete
      dataTestId="usd_payment_reason_selector_testId"
      name="usd_payment_reason_selector"
      handleChange={handleChange}
      error={error}
      required={true}
      placeholder={
        keysEnum[
          'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.placeholder'
        ]
      }
      options={options}
      selectedOption={selectedOption}
    />
  );
};

const SourceOfFundsSelector = ({
  handleChange,
  selectedOption,
  error,
  options,
}: {
  handleChange: (value: string) => void;
  selectedOption: string;
  error?: string;
  options: Option[];
}) => {
  return (
    <QuestionsAutocomplete
      error={error}
      dataTestId="source_of_funds_selector_testId"
      name="source_of_funds_selector"
      handleChange={handleChange}
      required={true}
      placeholder={
        keysEnum[
          'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.placeholder'
        ]
      }
      options={options}
      selectedOption={selectedOption}
    />
  );
};

const PayerSelector = ({
  handleChange,
  selectedOption,
  error,
  options,
}: {
  handleChange: (value: string) => void;
  selectedOption: string;
  error: string;
  options: Option[];
}) => {
  return (
    <QuestionsAutocomplete
      dataTestId="payer_selector_testId"
      name="payer_selector"
      handleChange={handleChange}
      required={true}
      error={error}
      placeholder={
        keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.placeholder']
      }
      options={options}
      selectedOption={selectedOption}
    />
  );
};

const FeedbackQuestionsModalComponent = ({
  isOpen,
  closeModal,
  focusOnFirstError,
  onSubmit,
}: FeedbackQuestionsModalComponentProps) => {
  const i18n = useTranslations();
  const defaultValues = {
    payer: '',
    sourceOfFunds: '',
    usdReason: '',
    dealer: '',
    bank: '',
    agent: '',
    nonBanking: '',
    usdBetterRate: '',
    usdOther: '',
  };

  const [fieldsValues, setFieldsValues] = useState(defaultValues);
  const [fieldsErrors, setFieldsErrors] = useState(defaultValues);

  const dispatch = useDispatch();

  const handleOnchange = (fieldName: string, value: string) => {
    if (fieldName === 'payer' && payer !== value) {
      setFieldsValues({
        ...fieldsValues,
        dealer: '',
        agent: '',
        bank: '',
        nonBanking: '',
        payer: value,
      });
      setFieldsErrors({
        ...fieldsErrors,
        dealer: '',
        agent: '',
        bank: '',
        nonBanking: '',
        payer: '',
      });
    } else if (fieldName === 'usdReason' && usdReason !== value) {
      setFieldsValues({
        ...fieldsValues,
        usdBetterRate: '',
        usdOther: '',
        usdReason: value,
      });
      setFieldsErrors({
        ...fieldsErrors,
        usdBetterRate: '',
        usdOther: '',
        usdReason: '',
      });
    } else {
      setFieldsValues({ ...fieldsValues, [fieldName]: value });
      setFieldsErrors({ ...fieldsErrors, [fieldName]: '' });
    }
  };

  const {
    payer,
    sourceOfFunds,
    dealer,
    agent,
    usdReason,
    bank,
    nonBanking,
    usdBetterRate,
    usdOther,
  } = fieldsValues;
  const {
    payer: payerError,
    sourceOfFunds: sourceOfFundsOptionError,
    dealer: dealerOptionError,
    agent: agentOptionError,
    usdReason: usdReasonOptionError,
    bank: bankOptionError,
    nonBanking: nonBankingOptionError,
    usdBetterRate: usdBetterRateOError,
    usdOther: usdOtherError,
  } = fieldsErrors;

  const required = {
    presence: {
      allowEmpty: false,
      message: `^${i18n.t('form.errors.required')}`,
    },
  };

  const constraints = {
    payer_selector: {
      ...required,
    },
    source_of_funds_selector: {
      ...required,
    },
    usd_payment_reason_selector: {
      ...required,
    },
    ...(payer === 'agent' && {
      agent_option: {
        ...required,
      },
    }),
    ...(payer === 'dealer' && {
      dealer_selector: {
        ...required,
      },
    }),
    ...(payer === 'bank' && {
      bank_selector: {
        ...required,
      },
    }),
    ...(payer === 'nonBanking' && {
      non_banking_selector: {
        ...required,
      },
    }),
    ...(usdReason === 'usdBetterRate' && {
      usd_better_rate: {
        ...required,
      },
    }),
    ...(usdReason === 'usdOther' && {
      usd_other: {
        ...required,
      },
    }),
  };

  const validate = async () => {
    try {
      await clientValidate.async(
        {
          payer_selector: payer,
          source_of_funds_selector: sourceOfFunds,
          usd_payment_reason_selector: usdReason,
          ...(payer === 'dealer' && {
            dealer_selector: dealer,
          }),
          ...(payer === 'agent' && {
            agent_option: agent,
          }),
          ...(payer === 'bank' && {
            bank_selector: bank,
          }),
          ...(payer === 'nonBanking' && {
            non_banking_selector: nonBanking,
          }),
          ...(usdReason === 'usdBetterRate' && {
            usd_better_rate: usdBetterRate,
          }),
          ...(usdReason === 'usdOther' && {
            usd_other: usdOther,
          }),
        },
        constraints,
      );
    } catch (errors) {
      const _errors = errors as Record<string, string[]>;

      const newStateErrors = {} as typeof defaultValues;
      if (_errors.payer_selector) {
        newStateErrors.payer = _errors.payer_selector[0];
      }
      if (_errors.source_of_funds_selector) {
        newStateErrors.sourceOfFunds = _errors.source_of_funds_selector[0];
      }

      if (_errors.usd_payment_reason_selector) {
        newStateErrors.usdReason = _errors.usd_payment_reason_selector[0];
      }

      if (_errors.agent_option) {
        newStateErrors.agent = _errors.agent_option[0];
      }

      if (_errors.dealer_selector) {
        newStateErrors.dealer = _errors.dealer_selector[0];
      }

      if (_errors.bank_selector) {
        newStateErrors.bank = _errors.bank_selector[0];
      }

      if (_errors.non_banking_selector) {
        newStateErrors.nonBanking = _errors.non_banking_selector[0];
      }

      if (_errors.usd_better_rate) {
        newStateErrors.usdBetterRate = _errors.usd_better_rate[0];
      }

      if (_errors.usd_other) {
        newStateErrors.usdOther = _errors.usd_other[0];
      }

      setFieldsErrors({ ...fieldsErrors, ...newStateErrors });
      throw errors;
    }
  };

  const onClose = () => {
    dispatch(trackClickCloseFXFeedbackForm());
    closeModal();
  };

  const optionLabel = (options: Option[], value: string) => {
    return options.find(option => {
      return option.value === value;
    });
  };

  const onSubmitClick = async () => {
    validate()
      .then(async () => {
        const nonFXFeedbackFormResponses = {
          payer: optionLabel(payerOptions, payer)?.label as string,
          sourceOfFunds: optionLabel(sourceOfFundsOptions, sourceOfFunds)
            ?.label as string,
          usdPaymentReason: optionLabel(usdPaymentReasonsOptions, usdReason)
            ?.label as string,
          ...(dealer && { dealer }),
          ...(agent && { agent }),
          ...(bank && { bank }),
          ...(nonBanking && { nonBankingInstitution: nonBanking }),
          ...(usdBetterRate && { usdBetterRate }),
          ...(usdOther && { usdOther }),
        };

        dispatch(trackClickSubmitFXFeedbackForm(nonFXFeedbackFormResponses));

        const metadata = {
          ...formatNonFXFeedbackResponsesForRequest({
            ...nonFXFeedbackFormResponses,
            payerValue: payer,
          }),
        };
        await dispatch(updateOrderMetadata(metadata));

        onSubmit();
      })
      .catch(focusOnFirstError);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      className="FeedbackQuestions-Modal"
    >
      <div>
        <Heading as="h5" size="large" className="fontWeight-semiBold">
          {keysEnum['nonFxPaymentInfo.feedbackQuestions.title']}
        </Heading>
        <p className="text-sm">
          {keysEnum['nonFxPaymentInfo.feedbackQuestions.description']}
        </p>
      </div>
      <div className="FeedbackQuestions-Modal-body">
        <div className="FeedbackQuestions-Payer">
          <PayerSelector
            error={payerError}
            handleChange={value => handleOnchange('payer', value)}
            selectedOption={payer}
            options={payerOptions}
          />
        </div>
        {payer === 'agent' && (
          <div>
            <TextInput
              label={
                keysEnum[
                  'nonFxPaymentInfo.feedbackQuestions.agentOptions.placeholder'
                ]
              }
              name="agent_option"
              required={true}
              value={''}
              error={agentOptionError}
              data-testid="payer_agent"
              onChange={(_, value) => handleOnchange('agent', value)}
            />
          </div>
        )}
        {payer === 'dealer' && (
          <div>
            <DealerSelector
              error={dealerOptionError}
              handleChange={value => handleOnchange('dealer', value)}
              selectedOption={dealer}
            />
          </div>
        )}
        {payer === 'bank' && (
          <div className="FeedbackQuestions-BankOptions">
            <BankSelector
              error={bankOptionError}
              handleChange={value => handleOnchange('bank', value)}
              selectedOption={bank}
            />
          </div>
        )}
        {payer === 'nonBanking' && (
          <div className="FeedbackQuestions-NoBankingOptions">
            <NonBankingSelector
              error={nonBankingOptionError}
              handleChange={value => handleOnchange('nonBanking', value)}
              selectedOption={nonBanking}
            />
          </div>
        )}
        <div className="FeedbackQuestions-SourceOfFunds">
          <SourceOfFundsSelector
            error={sourceOfFundsOptionError}
            handleChange={value => handleOnchange('sourceOfFunds', value)}
            selectedOption={sourceOfFunds}
            options={sourceOfFundsOptions}
          />
        </div>
        <div className="FeedbackQuestions-Reasons">
          <USDPaymentReasonSelector
            options={usdPaymentReasonsOptions}
            error={usdReasonOptionError}
            handleChange={value => handleOnchange('usdReason', value)}
            selectedOption={usdReason}
          />
        </div>
        {usdReason === 'usdBetterRate' && (
          <div>
            <TextInput
              label={
                keysEnum[
                  'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.answer.placeholder'
                ]
              }
              name="usd_better_rate"
              required={true}
              value={usdBetterRate}
              error={usdBetterRateOError}
              data-testid="usdReason_better_rate"
              onChange={(_, value) => handleOnchange('usdBetterRate', value)}
            />
          </div>
        )}
        {usdReason === 'usdOther' && (
          <div>
            <TextInput
              label={
                keysEnum[
                  'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.answer.placeholder'
                ]
              }
              name="usd_other"
              required={true}
              value={usdOther}
              error={usdOtherError}
              data-testid="usdReason_other"
              onChange={(_, value) => handleOnchange('usdOther', value)}
            />
          </div>
        )}
      </div>
      <div className="FeedbackQuestions-Modal-footer">
        <Button
          tone="primary"
          size="full"
          onClick={onSubmitClick}
          data-testid="submitQuestions"
        >
          {keysEnum['nonFxPaymentInfo.feedbackQuestions.button.label']}
        </Button>
      </div>
    </Modal>
  );
};

const FeedbackQuestionsModal = WithFocusOnError(
  FeedbackQuestionsModalComponent,
);

export { FeedbackQuestionsModal };
