import {
  CREATE_USER_SUCCESS,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  REMOVE_CREDIT_CARD_SUCCESS,
  UPDATE_USER_SUCCESS,
} from '../../../constants';
import type { PaymentMethod, User } from 'models';

type UserAction =
  | {
      type:
        | typeof CREATE_USER_SUCCESS
        | typeof FETCH_PAYMENT_METHODS_SUCCESS
        | typeof FETCH_USER_SUCCESS
        | typeof UPDATE_USER_SUCCESS;
      payload: User;
    }
  | {
      type: typeof FETCH_PAYMENT_METHODS_SUCCESS;
      payload: PaymentMethod[];
    }
  | {
      type: typeof REMOVE_CREDIT_CARD_SUCCESS;
      payload: string;
    }
  | {
      type: typeof LOGOUT_USER_SUCCESS;
    };

const user = (state: User | Record<string, never> = {}, action: UserAction) => {
  switch (action.type) {
    case CREATE_USER_SUCCESS:
    case FETCH_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return { ...state, ...action.payload };
    case LOGOUT_USER_SUCCESS:
      return {};
    case FETCH_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        paymentMethods: action.payload,
      };
    }
    case REMOVE_CREDIT_CARD_SUCCESS: {
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          paymentMethod => paymentMethod.token !== action.payload,
        ),
      };
    }
    default:
      return state;
  }
};

export default user;
