import React, { Children, isValidElement, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WizardContext, WizardContextType } from '../context';
import { Step } from '../Step/Step';
import { Steps } from '../Steps/Steps';

const FIRST_STEP = 0;

type WizardProps = {
  activeStepIndex?: number;
  baseUrl: string;
  children: React.ReactNode;
  onStepChanged: (navigationState: {
    activeStepIndex: number;
    step: {
      id: string;
    };
  }) => void;
  onWizardFinished: () => void;
};

function Wizard({
  activeStepIndex: defaultActiveStepIndex,
  baseUrl,
  children,
  onStepChanged = () => {},
  onWizardFinished = () => {},
}: WizardProps) {
  const navigate = useNavigate();
  const [steps, setSteps] = useState([]);
  const [initialPathSet, setInitialPathSet] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(
    defaultActiveStepIndex || FIRST_STEP,
  );

  function goToStep(index: number) {
    const isLastStap = index < FIRST_STEP || index > steps.length - 1;

    if (isLastStap) {
      onWizardFinished();
      return;
    }

    navigate(`${baseUrl}/${steps[index].id}`);

    if (defaultActiveStepIndex === undefined) {
      setActiveStepIndex(index);
    }

    onStepChanged({
      activeStepIndex: index,
      step: steps[index],
    });
  }

  useEffect(
    function setStepsFromChildren() {
      const newSteps = [];

      Children.forEach(children, child => {
        if (
          isValidElement(child) &&
          (child.type === Steps || child.type.displayName === 'Steps')
        ) {
          const {
            props: { children: grandchildren },
          } = child;

          Children.forEach(grandchildren, child => {
            if (
              isValidElement(child) &&
              (child.type === Step || child.type.displayName === 'Step')
            ) {
              const {
                props: { id },
              } = child;
              newSteps.push({ id });
            }
          });
        }
      });

      setSteps(newSteps);
    },
    [children],
  );

  useEffect(() => {
    const handleBackButtonEvent = (evt: PopStateEvent) => {
      evt.preventDefault();

      const path = window.location.pathname.replace(`${baseUrl}/`, '');
      const newStepIndex = steps.findIndex(step => step.id === path);

      if (newStepIndex !== activeStepIndex) {
        if (defaultActiveStepIndex === undefined) {
          setActiveStepIndex(newStepIndex);
        }

        onStepChanged({
          activeStepIndex: newStepIndex,
          step: steps[newStepIndex],
        });
      }
    };

    window.addEventListener('popstate', handleBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
    };
  }, [steps, activeStepIndex, baseUrl, onStepChanged, defaultActiveStepIndex]);

  useEffect(() => {
    function initializeRoutePath() {
      if (steps.length <= 0 || initialPathSet) {
        return;
      }

      const { id } = steps[activeStepIndex];
      navigate(`${baseUrl}/${id}`, { replace: true });
      setInitialPathSet(true);
    }

    initializeRoutePath();
  }, [steps, activeStepIndex, baseUrl, navigate, initialPathSet]);

  useEffect(() => {
    if (defaultActiveStepIndex !== undefined) {
      setActiveStepIndex(defaultActiveStepIndex);
    }
  }, [defaultActiveStepIndex]);

  const value: WizardContextType = {
    activeStepIndex,
    goToNextStep: () => goToStep(activeStepIndex + 1),
    goToPrevStep: () => goToStep(activeStepIndex - 1),
    goToStep,
    totalSteps: steps.length,
  };

  return (
    <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
  );
}

export { Wizard };
