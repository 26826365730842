import { combineReducers } from 'redux';
import {
  FETCH_RECIPIENT_SUCCESS,
  FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS,
} from 'constants/index';
import type { Item } from 'models';

type ItemsAction = {
  type:
    | typeof FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS
    | typeof FETCH_RECIPIENT_SUCCESS;
  payload: {
    entities: {
      items: Record<string, Item>;
    };
  };
};

export const byId = (state: Record<string, Item> = {}, action: ItemsAction) => {
  switch (action.type) {
    case FETCH_RECIPIENT_SUCCESS:
    case FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS:
      const {
        entities: { items = {} },
      } = action.payload;
      return items;
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: ItemsAction) => {
  switch (action.type) {
    case FETCH_RECIPIENT_SUCCESS:
    case FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS:
      const {
        entities: { items = {} },
      } = action.payload;
      return Object.keys(items);
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
