/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck Done in PAAR-4488
import { WALLET_URL } from 'constants/config';
import fetch from 'services/apiV3/fetch';

const UserService = {
  fetchPaymentMethods: () => {
    const options = { method: 'GET' };
    const url = `${WALLET_URL}/payment_methods`;

    return fetch(url, options);
  },

  removeCreditCard: (token: string) => {
    const options = { method: 'DELETE' };

    const url = `${WALLET_URL}/${token}`;

    return fetch(url, options);
  },

  removeNewCreditCard: ({
    apiConfirmationUrl,
    uuidToken,
  }: {
    apiConfirmationUrl: string;
    uuidToken: string;
  }) => {
    const path = `${WALLET_URL}/remove_new_card`;
    const body = JSON.stringify({
      api_confirmation_url: apiConfirmationUrl,
      uuid_token: uuidToken,
    });
    const options = {
      method: 'POST',
      body,
    };

    return fetch(path, options);
  },
};

export default UserService;
