import { ENABLE_FEATURE, FETCH_FEATURES_SUCCESS } from '../../constants';

type FeaturesAction = {
  type: typeof ENABLE_FEATURE | typeof FETCH_FEATURES_SUCCESS;
  payload: Record<string, boolean>;
};

export default (
  state: Record<string, boolean> = {},
  action: FeaturesAction,
) => {
  switch (action.type) {
    case ENABLE_FEATURE:
    case FETCH_FEATURES_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
