import React from 'react';

const WomanGraduate = props => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.723 6C17.723 7.39239 17.1699 8.72774 16.1853 9.71231C15.2007 10.6969 13.8654 11.25 12.473 11.25C11.0806 11.25 9.74525 10.6969 8.76068 9.71231C7.77612 8.72774 7.22299 7.39239 7.22299 6V0.75H17.723V6Z"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.22299 0.75H23.723"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22299 5.25H17.723"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.72299 0.75V8.25"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.39999 14.804L12.473 18.75L17.545 14.804"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.946 23.2499C22.7558 20.6022 21.5697 18.1249 19.6266 16.3164C17.6835 14.5079 15.1275 13.5024 12.473 13.5024C9.81849 13.5024 7.2625 14.5079 5.31938 16.3164C3.37626 18.1249 2.19024 20.6022 2 23.2499"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.27599 6.75C7.27599 6.75 7.22299 10.5 4.22299 12"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.669 6.75C17.669 6.75 17.723 10.5 20.723 12"
        stroke="#1274C4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WomanGraduate;
