import {
  CREATE_PAYMENT_SUCCESS,
  FETCH_PAYMENT_SUCCESS,
  SELECT_PAYMENT,
} from '../../../constants';
import type { Purchase } from 'models';

type PurchasesAction = {
  type:
    | typeof CREATE_PAYMENT_SUCCESS
    | typeof FETCH_PAYMENT_SUCCESS
    | typeof SELECT_PAYMENT;
  payload: {
    entities: {
      purchases: Record<string, Purchase>;
    };
  };
};

export default (
  state: Record<string, Purchase> = {},
  action: PurchasesAction,
) => {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
    case FETCH_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
      const {
        entities: { purchases = {} },
      } = action.payload;
      return { ...state, ...purchases };
    default:
      return state;
  }
};
