import { EU_COUNTRY_CODES, IN_COUNTRY_CODE } from '../../constants';
import type { RootState } from 'reducers/types';

export const isEUMemberAccess = (state: RootState) => {
  const {
    geolocation: { countryCode },
  } = state;

  return EU_COUNTRY_CODES.includes(countryCode);
};

export const getCountryCode = (state: RootState) => {
  const { geolocation: { countryCode = null } = {} } = state;

  return countryCode;
};

export const isIndiaIpAddress = (state: RootState) => {
  const countryCode = getCountryCode(state);

  if (!countryCode) {
    return false;
  }

  return countryCode.toUpperCase() === IN_COUNTRY_CODE;
};
