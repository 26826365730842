import get from 'lodash/get';
import omit from 'lodash/omit';
import { decodeObject } from 'utils/encoding';

type WindowWithXprops = typeof window & {
  xprops: unknown;
};

export const EmbeddedRepository = {
  isEnabled() {
    return (window as WindowWithXprops).xprops !== undefined;
  },

  getProps() {
    const xprops = (window as WindowWithXprops).xprops;
    if (!xprops) return {};
    return decodeObject(omit(xprops, 'uid'));
  },

  getProp(prop: string, defaultValue?: string): unknown {
    return get(this.getProps(), prop, defaultValue);
  },
};

export default EmbeddedRepository;
