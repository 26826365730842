import React, {
  ReactNode,
  useState,
  cloneElement,
  isValidElement,
} from 'react';
import { useTranslations } from 'utils';

import './Navbar.scss';

type NavbarProps = { children: ReactNode };

const Navbar = ({ children }: NavbarProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const i18n = useTranslations();

  function handleButtonClick() {
    setIsOpen(isOpen => !isOpen);
    document.body.classList.toggle('Navbar-open');
  }

  function handleCloseClick() {
    document.body.classList.remove('Navbar-open');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChildClick(child: React.ReactElement, args: any[]) {
    if (child.props.onClick && typeof child.props.onClick === 'function') {
      child.props.onClick(...args);
    }

    handleCloseClick();
  }

  const renderMenuItem = (child: React.ReactNode, index: number) => {
    if (isValidElement(child)) {
      const childElement = child as React.ReactElement;

      return (
        <li className="Navbar-menu-item" key={index}>
          {cloneElement(childElement, {
            onClick: (...args: any[]) => handleChildClick(childElement, args), // eslint-disable-line @typescript-eslint/no-explicit-any
          })}
        </li>
      );
    }

    return null;
  };

  const menuItems = Array.isArray(children)
    ? children.map(renderMenuItem)
    : children;
  return (
    <nav className="Navbar" aria-label={i18n.t('navbar.label') as string}>
      <section className="Navbar-header">
        <h3 className="Navbar-title">{i18n.t('navbar.menu')}</h3>
        <button
          data-testid="OpenMobileMenu"
          className="Navbar-button"
          onClick={handleButtonClick}
          aria-labelledby="navbar-button-text"
          aria-expanded={isOpen}
          type="button"
        >
          <span className="Navbar-button-icon" />
          <span id="navbar-button-text" className="Navbar-button-text">
            {isOpen ? i18n.t('navbar.close') : i18n.t('navbar.menu')}
          </span>
        </button>
      </section>

      <ul className="Navbar-menu">{menuItems}</ul>
    </nav>
  );
};

export { Navbar };
