import React, { PropsWithChildren } from 'react';

import './Alert.scss';

type AlertProps = {
  id?: string;
  type?: 'danger' | 'warning' | 'info' | 'success';
  role?: string;
};

export function Alert({
  children,
  type = 'info',
  role = 'alert',
  ...other
}: PropsWithChildren<AlertProps>) {
  return (
    <div className={`Alert Alert--${type}`} role={role} {...other}>
      {children}
    </div>
  );
}
