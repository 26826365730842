import { getFieldValue, isReadOnly, isApiReadOnly } from '../../index';
import type { RootState } from 'reducers/types';
import { PaymentItem } from 'models';

export const getItemById = (state: RootState, id: string): PaymentItem => {
  const { entities: { items: { byId = {} } = {} } = {} } = state;
  const defaultValue = getFieldValue(state, id);
  const readOnly = isReadOnly(state, id);
  const apiReadOnly = isApiReadOnly(state, id);
  const item = byId[id];

  return { ...item, defaultValue, readOnly, apiReadOnly };
};

export const getItems = (state: RootState) => {
  const {
    entities: {
      items: { ids },
    },
  } = state;

  return ids.map(id => getItemById(state, id));
};

export const getItemsIds = (state: RootState) => {
  const {
    entities: { items: { ids = [] } = {} },
  } = state;

  return ids;
};

export const getItemCount = (state: RootState) => {
  const {
    entities: { items: { ids = [] } = {} },
  } = state;

  return ids.length;
};

export const hasMultipleItems = (state: RootState) => {
  return getItemCount(state) > 0;
};
