import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/ui/Modal/Modal';
import { Heading } from 'components/Heading/Heading';
import { Confirmation } from 'components/Confirmation/Confirmation';
import { createMarkup } from 'utils/createMarkup/createMarkup';

const NOOP = () => {};

const PrefillModal = ({
  body,
  i18n,
  isOpen = false,
  onClose = NOOP,
  onConfirm = NOOP,
  onReject = NOOP,
  title,
}) => (
  <Modal size="medium" isOpen={isOpen} onClose={onClose}>
    <Heading as="h3" size="large">
      {title}
    </Heading>
    <p className="type-regular" dangerouslySetInnerHTML={createMarkup(body)} />
    <Confirmation
      confirmationButtonText={i18n.t('senderInformation.confirmButtonText')}
      rejectionButtonText={i18n.t('senderInformation.rejectButtonText')}
      onConfirm={onConfirm}
      onReject={onReject}
    />
  </Modal>
);

PrefillModal.displayName = 'PrefillModal';
PrefillModal.propTypes = {
  body: PropTypes.string.isRequired,
  i18n: PropTypes.shape({ t: PropTypes.func }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export { PrefillModal };
