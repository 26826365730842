import { USER_LANGUAGE_URL } from 'constants/config';
import fetch from 'utils/fetchJson';

const userLanguage = {
  update: (senderEmail: string, locale: string) => {
    const payload = {
      user: {
        email: senderEmail,
        language: locale,
      },
    };
    const body = JSON.stringify(payload);
    const options = { body, method: 'PUT' };

    return fetch(USER_LANGUAGE_URL, options);
  },
};

export default userLanguage;
