import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPaymentMethods,
  newNotification,
  removeNewCreditCard,
} from 'actions';
import { Button } from 'lib/flywire-tailwind/Button';
import { getAccessToken, getCountriesForSelect, getCountry } from 'selectors';
import { useTranslations } from 'utils';
import { RootState } from 'reducers/types';
import {
  CardTokenizationEvent,
  CardInstructionsAttributes,
} from 'services/tokenization/models/models';
import { CountrySelection } from 'components/PaymentInformation/CountrySelection/CountrySelection';
import classNames from 'classnames';
import { CreditCardTokenization } from 'components/CreditCards/components/CreditCardTokenization/CreditCardTokenization';

import './AddCardForm.scss';

type AddCardFormProps = {
  className?: string;
  modalContext?: boolean;
  onSuccess?: (tokenizationResult: CardTokenizationEvent) => void;
  onError?: () => void;
  onCancel?: () => void;
  onCountryChange?: (country: string) => void;
};

function AddCardForm({
  className,
  modalContext = false,
  onSuccess,
  onError,
  onCancel,
  onCountryChange,
}: AddCardFormProps) {
  const i18n = useTranslations();
  const [cardCountry, setCardCountry] = useState('');
  const dispatch = useDispatch();
  const countries = useSelector(getCountriesForSelect);
  const accessToken = useSelector(getAccessToken);
  const { currency } = useSelector((state: RootState) =>
    getCountry(state, cardCountry),
  );

  const handleCardCountryChange = (_name: string, value: string) => {
    setCardCountry(value);
    onCountryChange?.(value);
  };

  const onSuccessAddCard = async (
    tokenizationResult?: CardTokenizationEvent,
  ) => {
    if (tokenizationResult) {
      const country = tokenizationResult.cardDetails.country;
      if (cardCountry !== country) {
        await dispatch(
          removeNewCreditCard(
            tokenizationResult.apiConfirmationURL as string,
            tokenizationResult.uuidToken as string,
          ),
        );
        dispatch(
          newNotification({
            message: 'notifications.user_card_save_error.country_mismatch',
            type: 'error',
          }),
        );
      } else {
        dispatch(
          newNotification({
            message: 'notifications.user_card_added_successfully',
          }),
        );
        await onSuccess?.(tokenizationResult);
        await dispatch(fetchPaymentMethods());
      }
    }
  };

  const onErrorAddCard = () => {
    onError?.();
    dispatch(
      newNotification({
        message: 'notifications.user_card_save_error',
        type: 'error',
      }),
    );
  };

  const attributes = useMemo(
    () =>
      ({
        accessToken,
        locale: i18n.localeCode,
        actionButton: modalContext ? 'confirm' : 'save',
        payorIdType: 'user',
        country: cardCountry,
        theme: modalContext ? undefined : 'payex_gbp',
        currencyCode: currency,
      } as CardInstructionsAttributes),
    [accessToken, cardCountry, currency, i18n.localeCode, modalContext],
  );

  return (
    <>
      <div
        data-testid="addCardForm"
        className={classNames('AddCardForm', className)}
      >
        <div className="AddCardForm-countrySelect">
          <CountrySelection
            label={i18n.t('profile.cards.countrySelect.label') as string}
            name="card_country"
            onChange={handleCardCountryChange}
            countries={countries}
            hint={i18n.t('profile.cards.countrySelect.hint')}
            required
            value={cardCountry}
            data-testid="countrySelection"
          />
        </div>
        {cardCountry && (
          <div className="AddCardForm-content">
            <CreditCardTokenization
              className={classNames(
                modalContext
                  ? 'CreditCardTokenization-Modal-WithCountry'
                  : 'CreditCardTokenization',
              )}
              instructionsAttributes={attributes}
              showDisclaimer={!modalContext}
              onSuccess={onSuccessAddCard}
              onError={onErrorAddCard}
            />
          </div>
        )}
        {onCancel && (
          <div className="AddCardForm-actions">
            <Button
              tone="secondary"
              className="w-full md:w-36"
              onClick={onCancel}
            >
              {i18n.t('profile.payment_methods.cancel_add_card')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export { AddCardForm };
