import { SET_KEEP_SESSION } from '../../../constants';

type AuthenticationAction = {
  type: typeof SET_KEEP_SESSION;
  payload: {
    keepSession: boolean;
  };
};

export const keepSession = (
  state = { keepSession: false },
  action: AuthenticationAction,
) => {
  switch (action.type) {
    case SET_KEEP_SESSION:
      const { keepSession } = action.payload;
      return { ...state, keepSession };
    default:
      return state;
  }
};

export default keepSession;
