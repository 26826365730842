import * as Sentry from '@sentry/react';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { beforeSend } from './sentry-filter';
import {
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  IGNORE_SENTRY_ERRORS,
} from '../constants';

Sentry.init({
  dsn: SENTRY_DSN,
  release: process.env.RELEASE,
  environment: SENTRY_ENVIRONMENT,
  maxBreadcrumbs: 50,
  normalizeDepth: 6,
  ignoreErrors: IGNORE_SENTRY_ERRORS,
  integrations: [
    // @ts-expect-error library types mismatch
    new DedupeIntegration(),
    // @ts-expect-error library types mismatch
    new BrowserTracing({
      // @ts-expect-error library types mismatch
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      beforeNavigate: context => {
        return {
          ...context,
          name: location.pathname
            .replace(/\/tracking\/(.+)/g, '/tracking/:tracking_id')
            .replace(/\/orders\/(.+)/g, '/orders/:order_id')
            .replace(/\/payment\/(.+)/g, '/payment/:payment_id')
            .replace(/\/kyc\/(.+)/g, '/kyc/:kyc_id'),
        };
      },
    }),
  ],
  enabled:
    SENTRY_ENVIRONMENT !== '$ENVIRONMENT' && SENTRY_ENVIRONMENT !== 'local',
  tracesSampleRate: 0.2,
  beforeBreadcrumb(breadcrumb) {
    return breadcrumb.category === 'console' ? null : breadcrumb;
  },
  beforeSend,
});
