import errorNotifier from 'utils/errorNotifier';
import { Children, isValidElement } from 'react';
import some from 'lodash/some';
export const rtlLocales = ['ar'];

// FYI this type name is used in a Support RegEx to extract the locales
export type SupportedLocalesKeys =
  | 'en'
  | 'zh-CN'
  | 'es-ES'
  | 'ko'
  | 'ja'
  | 'fr-FR'
  | 'it-IT'
  | 'pt-PT'
  | 'de-DE'
  | 'id'
  | 'vi'
  | 'ar';

export type Translations = Record<string, string | Record<string, string>>;
type Locales = { [key in SupportedLocalesKeys]: string };

export const locales: Locales = {
  en: 'EN',
  'zh-CN': 'zh-CN',
  'es-ES': 'es-ES',
  ko: 'ko',
  ja: 'ja',
  'fr-FR': 'fr-FR',
  'it-IT': 'it-IT',
  'pt-PT': 'pt-PT',
  'de-DE': 'de-DE',
  id: 'id',
  vi: 'vi',
  ar: 'ar',
};

export const cmsLocales = [
  'en',
  'zh-CN',
  'es-ES',
  'ko',
  'ja',
  'fr-FR',
  'pt-PT',
];

export const localeCodes = Object.keys(locales);
export const extractLocaleCode = (locale: string) => locale.split('-')[0];
export const getKeyFromLocaleCode = (code: string) =>
  localeCodes.find(c => c.startsWith(code));
export const isRtlLocale = (locale: string) =>
  rtlLocales.includes(extractLocaleCode(locale));

export type I18n = {
  t: (
    key: string,
    opts?: {
      [key: string]: string;
    },
  ) => string;
  currentLocale: SupportedLocalesKeys;
  localeCode: string;
  isRtl: boolean;
  setLocale: (locale: SupportedLocalesKeys) => void;
};

export const isValidLocale = (locale: string) => {
  return Object.keys(locales).includes(locale);
};

function isNotAKey(key: string | number) {
  if (!key) return true;
  if (typeof key !== 'string') return true;
  if (key.includes(' ')) return true;

  return false;
}

export function translate(
  key: string,
  attrs: Record<string, string | JSX.Element> = {},
  keys: Translations,
  fallbackKeys: Translations,
): string {
  try {
    if (isNotAKey(key)) {
      return key;
    }

    if (
      (!Object.prototype.hasOwnProperty.call(keys, key) ||
        !Object.prototype.hasOwnProperty.call(fallbackKeys, key)) &&
      process.env.NODE_ENV === 'development'
    ) {
      console.info(`[translation] '${key}' key not found!`);
    }

    const translation = keys[key] || fallbackKeys[key];

    if (translation === undefined) {
      return key;
    }

    const optionsRegex = /%{([a-zA-Z0-9]+)}/g;
    const hasReactValues = some(Object.values(attrs), o => isValidElement(o));

    if (hasReactValues) {
      const chunks = (translation as string)
        .split(optionsRegex)
        .map((chunk: string) => {
          return attrs[chunk] || chunk;
        });

      return Children.toArray(chunks).filter(el => !!el) as unknown as string;
    }

    return (translation as string).replace(
      optionsRegex,
      (str, match) => (attrs[match] as string) || '',
    );
  } catch (error) {
    errorNotifier.notify(error, { key });
    return key;
  }
}
