import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Field, useDatePicker } from '@flywire/react-headlessui';
import classNames from 'classnames';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils/translations/useTranslations';

import './DatePicker.scss';

export const DATE_PATTERN = 'd{1,2}/d{1,2}/d{4}';

type DatePickerProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur'
> & {
  dateFormat?: string;
  datePattern?: string;
  error?: string | boolean;
  hint?: string;
  label?: string;
  locale?: string;
  readOnly?: boolean;
  onChange?: (name: string, value?: string) => void;
  onBlur?: (name: string) => void;
};

const DatePicker = ({
  id = 'datePicker',
  name = 'datePicker',
  dateFormat,
  datePattern = DATE_PATTERN,
  disabled = false,
  label,
  readOnly = false,
  error,
  hint,
  onChange,
  onBlur,
  value,
  ...props
}: DatePickerProps) => {
  const [isReadOnly, setIsReadOnly] = useState(readOnly);
  const datePickerProps = useDatePicker({
    dateFormat,
    name,
    value,
    onBlur,
    onChange,
  });

  useEffect(() => {
    if (readOnly && error) {
      setIsReadOnly(false);
    }
  }, [readOnly, error]);

  const i18n = useTranslations();

  return (
    <Field className={classNames('DatePicker', error && 'has-error')}>
      {label && (
        <Field.Label
          id={`${id}-label`}
          htmlFor={id}
          className="DatePicker-label"
        >
          {label}
        </Field.Label>
      )}
      <Field.Input
        {...props}
        id={id}
        name={name}
        type="date"
        className={classNames(error && 'DatePicker-input-error')}
        role="textbox"
        pattern={datePattern}
        disabled={disabled}
        readOnly={isReadOnly}
        data-testid={`${id}-input`}
        {...datePickerProps}
      />
      {error && typeof error === 'string' && (
        <Field.Error data-testid={`${name}-error`} className="DatePicker-error">
          <VisuallyHidden as="span">{label}</VisuallyHidden>
          {i18n.t(error)}
        </Field.Error>
      )}
      {hint && (
        <Field.Hint data-testid={`${name}-hint`} className="DatePicker-hint">
          {hint}
        </Field.Hint>
      )}
    </Field>
  );
};

export { DatePicker };
