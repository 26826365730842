import React, { useState } from 'react';
import { useTranslations } from 'utils';
import { TextInput } from 'components/input/TextInput/TextInput';
import { Button } from 'components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { isFetching, isPayingFromChina, isPayingFromIndia } from 'selectors';
import { useFormState, useValidate } from '@flywire/react-hooks';
import { addCoupon } from 'actions';
import { RootState } from 'reducers/types';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from 'icons/ico-info-new.svg';
import './Coupon.scss';

const Coupon = () => {
  const i18n = useTranslations();
  const dispatch = useDispatch();
  const { values, update } = useFormState({ couponCode: '' });
  const { isValid } = useValidate(values, {
    couponCode: { presence: { allowEmpty: false } },
  });
  const [serverError, setServerError] = useState('');

  const payingFromChina = useSelector(isPayingFromChina);
  const payingFromIndia = useSelector(isPayingFromIndia);
  const orderIsFetching = useSelector((state: RootState) =>
    isFetching(state, 'order'),
  );

  const handleChange = (name: string, value: string) => {
    update(name, value);
  };
  const handleClick = async () => {
    const { couponCode } = values;
    try {
      await dispatch(addCoupon(couponCode as string));
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = error => {
    if (error.status === 422)
      setServerError(i18n.t('coupon.serverError.invalid'));
    else setServerError(i18n.t('coupon.serverError.unable'));
  };

  const tooltipText = payingFromChina
    ? i18n.t('coupon.tooltip.text.cn')
    : payingFromIndia
    ? i18n.t('coupon.tooltip.text.in')
    : null;

  if (orderIsFetching) return null;

  return (
    <div className="Coupon-container" data-testid="Coupon">
      {tooltipText && (
        <div className="Coupon-tooltip" data-testid="CouponTooltip">
          {i18n.t('coupon.tooltip.label')}
          <Tooltip
            data-testid="CouponTooltipContent"
            defaultMessage={tooltipText}
          >
            <InfoIcon height="14" width="14" aria-hidden="true" />
          </Tooltip>
        </div>
      )}
      <div className="Coupon-box" data-testid="CouponBox">
        <TextInput
          label={i18n.t('coupon.input.label')}
          name="couponCode"
          className="Coupon-box-input"
          onChange={handleChange}
          value={values.couponCode as string}
          data-testid="couponInput"
          error={serverError}
          disabled={orderIsFetching}
        />
        <Button
          type="button"
          className="Button Button--block"
          onClick={handleClick}
          data-testid="couponButton"
          disabled={!isValid || orderIsFetching}
        >
          {i18n.t('coupon.button.text')}
        </Button>
      </div>
    </div>
  );
};

export { Coupon };
