import {
  HCAPTCHA_SITE_KEY_DEV,
  HCAPTCHA_SITE_KEY_PROD,
  PROD_DOMAINS_FOR_CAPTCHA,
} from 'constants/index';

const SURCHARGE_TEST_PORTAL_FOR_GBP = 'RTS';
const REDIRECTION_TEST_PORTAL_FOR_GBP = 'WCK';
const LEGACY_RECURRING_TEST_PORTAL = 'TSL';
const LEGACY_SUBSCRIPTION_TEST_PORTAL = 'DON';
const SURCHARGE_TEST_PORTAL_FOR_CAD = 'TNT';

const TEST_PORTALS = [
  'PTU',
  'ARA',
  'DTS',
  LEGACY_RECURRING_TEST_PORTAL,
  LEGACY_SUBSCRIPTION_TEST_PORTAL,
  REDIRECTION_TEST_PORTAL_FOR_GBP,
  SURCHARGE_TEST_PORTAL_FOR_GBP,
  SURCHARGE_TEST_PORTAL_FOR_CAD,
];
const hostName = window.location.hostname;
const domainName = hostName.substring(
  hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
);

const TEST_LOGIN_USERNAMES = [
  'testing234@flywire.com',
  'testing456@flywire.com',
  'mctest4@test.com',
  'testing123@flywire.com',
  'testing389@flywire.com',
];

const TEST_SIGNUP_USERNAME_REGEX = /^test_.*@test\.com$/;
export const captchaKey = PROD_DOMAINS_FOR_CAPTCHA.includes(domainName)
  ? HCAPTCHA_SITE_KEY_PROD
  : HCAPTCHA_SITE_KEY_DEV;

export const shouldShowCaptcha = (recipientId: string) => {
  const isTestPortal = TEST_PORTALS.includes(recipientId);
  const isProdHost = PROD_DOMAINS_FOR_CAPTCHA.includes(domainName);

  if (isProdHost) {
    return true;
  }

  return !isTestPortal;
};

export const shouldShowCaptchaInLogin = (username: string) => {
  const isTestUser = TEST_LOGIN_USERNAMES.includes(username);
  const isProdHost = PROD_DOMAINS_FOR_CAPTCHA.includes(domainName);

  if (isProdHost) {
    return true;
  }

  return !isTestUser;
};

export const shouldShowCaptchaInSignup = (username: string) => {
  const isTestUser = username.match(TEST_SIGNUP_USERNAME_REGEX);
  const isProdHost = PROD_DOMAINS_FOR_CAPTCHA.includes(domainName);

  if (isProdHost) {
    return true;
  }

  return !isTestUser;
};
