import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from 'icons/ico-info-blue.svg';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { I18n } from 'utils';

import './InteracOfferSection.scss';

function InteracOfferSection({
  children,
  i18n,
}: {
  children: React.ReactNode;
  i18n: I18n;
}) {
  return (
    <div className="InteracOfferSection">
      <div className="InteracOfferSection--Header">
        <div>
          <InfoIcon aria-hidden={true} />
        </div>
        <div className="InteracOfferSection--Content">
          <h2>{i18n.t('senderInformation.offer.interac.title')}</h2>
          <p
            dangerouslySetInnerHTML={createMarkup(
              i18n.t('senderInformation.offer.interac.description'),
            )}
          />{' '}
        </div>
      </div>
      {children}
    </div>
  );
}

InteracOfferSection.displayName = 'InteracOfferSection';
InteracOfferSection.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.shape({ t: PropTypes.func }),
};

export { InteracOfferSection };
