/* eslint-disable @typescript-eslint/no-explicit-any */
import rs from 'jsrsasign';

export const validate = (
  content: string,
  signature: string,
  publicKeyString: string,
) => {
  if (!content) {
    return false;
  }

  const publicKey = rs.KEYUTIL.getKey(publicKeyString);

  // @ts-expect-error not sure how to fix this
  return publicKey.verify(content, signature);
};

export const stringToHex = (string: string) => {
  let hex = '';
  for (let i = 0, length = string.length; i < length; i++) {
    hex += string.charCodeAt(i).toString(16);
  }

  return hex;
};

export const hexToString = (hexx: string) => {
  const hex = hexx.toString();
  let str = '';
  for (let i = 0, length = hex.length; i < length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }

  return decodeURIComponent(escape(str));
};
