import { SET_CAPTCHA_RESPONSE } from 'constants/index';

type CaptchaAction = {
  type: 'SET_CAPTCHA_RESPONSE';
  payload: {
    response?: null;
  };
};

const initialState = {
  response: '',
};

type State = {
  response: string;
};

const captcha = (state: State = initialState, action: CaptchaAction) => {
  switch (action.type) {
    case SET_CAPTCHA_RESPONSE:
      const { response } = action.payload;
      return { response };
    default:
      return state;
  }
};

export default captcha;
