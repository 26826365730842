import React from 'react';
import getDisplayName from 'utils/getDisplayName';

export type WithFocusOnErrorProps = {
  focusOnFirstError: () => void;
};

const WithFocusOnError = <T extends WithFocusOnErrorProps>(
  WrappedComponent: React.ComponentType<T>,
) => {
  const ERROR_CLASS = '.has-error';
  const FOCUSABLE_ELEMENTS = 'input, textarea';
  const WAIT_TIME = 150;

  const WithFocusOnError = (props: Omit<T, keyof WithFocusOnErrorProps>) => (
    <WrappedComponent {...(props as T)} focusOnFirstError={focusOnFirstError} />
  );

  WithFocusOnError.displayName = `WithFocusOnError(${getDisplayName(
    WrappedComponent,
  )})`;

  const focusOnFirstError = () => {
    const firstErrorNode = document.querySelector(ERROR_CLASS);

    if (!firstErrorNode) return;

    try {
      firstErrorNode.scrollIntoView({
        block: 'center',
      });

      const firstFocusableElement =
        firstErrorNode.querySelector(FOCUSABLE_ELEMENTS);

      if (firstFocusableElement) {
        const focusableElement = firstFocusableElement as HTMLInputElement;
        setTimeout(() => {
          (focusableElement as HTMLInputElement).focus({
            preventScroll: false,
          });

          focusableElement.select();
        }, WAIT_TIME);
      }
    } catch (e) {
      firstErrorNode.scrollIntoView();
    }
  };

  return WithFocusOnError;
};

export { WithFocusOnError };
