export const splitPhoneNumber = (
  phoneValue?: string,
  dialingCodes: string[] = [],
) => {
  if (!phoneValue || phoneValue === '' || !dialingCodes.length) {
    return {
      phoneNumber: '',
      prefix: '',
    };
  }

  const onlyDigits = removeNonDigits(phoneValue);
  const prefix = getDialingCodeFromPhone(onlyDigits, dialingCodes) || '';

  const phoneNumber = prefix ? onlyDigits.replace(prefix, '') : onlyDigits;

  return {
    phoneNumber,
    prefix,
  };
};

export const joinPhoneNumberWithPrefix = (
  prefix: string,
  phoneNumber: string,
) => {
  const phoneNumberFormatted = removeNonDigits(phoneNumber);
  return prefix ? `+${prefix} ${phoneNumberFormatted}` : phoneNumberFormatted;
};

const getDialingCodeFromPhone = (
  phoneNumber: string,
  dialingCodes: string[] = [],
) => {
  const selectedCountries = dialingCodes
    .filter(dialingCode => phoneNumber.startsWith(dialingCode))
    .sort(compareDialingCodes);

  return selectedCountries.length ? selectedCountries[0] : undefined;
};

const removeNonDigits = (value: string) => value.replace(/[^\d]/g, '');

const compareDialingCodes = (dialingCode1: string, dialingCode2: string) => {
  const length1 = (dialingCode1 && dialingCode1.length) || 0;
  const length2 = (dialingCode2 && dialingCode2.length) || 0;

  if (length1 > length2) return -1;
  if (length1 < length2) return 1;

  return 0;
};
