export const INTEGRATED_CLIENTS = [
  'CAP',
  'AON',
  'EBS',
  'NTR',
  'OFC',
  'SLT',
  'UQU',
  'WAD',
  'ULP',
  'QUT',
  'CUQ',
  'LOY',
  'SFU',
  'ECS',
  'CRH',
  'CCO',
  'ILL',
  'SLP',
  'ASU',
  'APD',
  'UOR',
  'OSC',
  'SCH',
  'UKS',
  'LIV',
  'NOR',
  'OCM',
  'CBU',
  'USC',
  'STB',
  'UTD',
  'ROC',
  'LAI',
  'UKL',
  'NYT',
  'BES',
  'UMA',
  'UMN',
  'MIA',
  'YGS',
  'LPI',
  'NWU',
  'USB',
  'LAM',
  'PSB',
  'SBC',
  'EFI',
  'GSE',
  'NOA',
  'CHI',
  'GLA',
  'UBT',
  'SUB',
  'MNV',
  'USO',
  'TAM',
  'NYH',
  'NCS',
  'CMR',
  'EXS',
  'IIA',
  'RIS',
  'WFU',
  'DUK',
  'GAM',
  'SCC',
  'DPU',
  'LSF',
  'BND',
  'UUL',
  'UCD',
  'NUC',
  'HUA',
  'ULR',
  'SHD',
  'BRW',
  'GJS',
  'EIC',
  'USF',
  'RPI',
  'MCP',
  'DHM',
  'CWR',
  'PMP',
  'MIU',
  'LCE',
  'CQU',
  'BAB',
  'SBY',
  'AUW',
  'TNK',
  'GSU',
  'USQ',
  'TUK',
  'WDU',
  'MRL',
  'DUU',
  'KYU',
  'RPA',
  'VAS',
  'UON',
  'TTU',
  'ROH',
  'UCB',
  'UNT',
  'CPP',
  'MEN',
  'KHC',
  'LUL',
  'UOO',
  'URU',
  'WES',
  'CLM',
  'LSL',
  'RIC',
  'KAG',
  'UTO',
  'UNH',
  'ECS',
  'MAU',
  'LIN',
  'IHU',
  'LBD',
  'LIV',
  'SKA',
  'UIM',
  'UCN',
  'EVA',
  'CMI',
  'SDU',
  'OLE',
  'PMO',
  'ORE',
  'ADN',
  'NWM',
  'UUR',
  'TWH',
  'SOU',
  'UAR',
  'TVA',
  'KCB',
  'KTF',
  'TRW',
  'ADL',
  'UMD',
  'YVU',
  'GIF',
  'FLR',
  'MCO',
  'IIM',
  'MUR',
  'WEL',
  'ATU',
  'BRA',
  'CRN',
  'ULU',
  'KCO',
  'NQA',
  'SRS',
  'SRB',
  'JFO',
  'IFL',
  'NCS',
  'WHE',
  'UAP',
  'CRK',
  'TIP',
  'TFS',
  'FLE',
  'BGS',
  'SOC',
  'MAA',
  'OXF',
  'OHU',
  'KSE',
  'FHS',
  'WCO',
  'DUL',
  'PDA',
  'KCL',
  'UVD',
  'IHH',
  'KAL',
  'KLO',
  'ESB',
  'HWS',
  'OTI',
  'KNT',
  'RSS',
  'ACE',
  'BMT',
  'SLK',
  'KAN',
  'ELB',
  'REE',
  'CYY',
  'REA',
  'BYH',
  'FST',
  'KDE',
  'KSU',
  'KSG',
  'AMD',
  'LNC',
  'MIO',
  'PRS',
  'MFC',
  'STK',
  'SIC',
  'PPD',
  'LMU',
  'ERL',
  'BGZ',
  'NAU',
  'NSS',
  'RPS',
  'LSY',
  'DEN',
  'UOA',
  'IDP',
  'FLT',
  'WHS',
  'CDL',
  'WAF',
  'FAY',
  'ARV',
  'KNO',
  'BCM',
  'LYC',
  'BLT',
  'UTK',
  'EDC',
  'LUA',
  'SRL',
  'NYA',
  'SAB',
  'TMS',
  'SOD',
  'ALT',
  'BYS',
  'MHA',
  'SSA',
  'UUG',
  'WEL',
  'KGB',
  'BSE',
  'AMH',
  'SGB',
  'MCR',
  'STJ',
  'BGG',
  'SLI',
  'KLA',
  'WNS',
  'RWU',
  'VLC',
  'PRO',
  'YHC',
  'MCC',
  'STV',
  'JSC',
  'KAY',
  'SAV',
  'CAF',
  'DSL',
  'HPA',
  'OAF',
  'WAG',
  'KAB',
  'WDN',
  'NHS',
  'KDU',
  'SNS',
  'DYC',
  'HMP',
  'KUW',
  'JWU',
  'REC',
  'FRD',
  'MSD',
  'COA',
  'SBM',
  'MIC',
  'PRT',
  'WHT',
  'FUS',
  'ECM',
  'BHS',
  'KLI',
  'CDU',
  'LCC',
  'MSL',
  'IUM',
  'IFN',
  'BRG',
  'IDI',
  'MOR',
  'HHC',
  'MPP',
  'TVP',
  'ULS',
  'UAN',
  'UCS',
  'KBN',
  'AKR',
  'OCU',
  'AEC',
  'WHE',
  'UMO',
  'VCL',
  'EOE',
  'OWU',
  'MCD',
  'ROE',
  'UIO',
  'BOL',
  'KEY',
  'SAS',
  'RFI',
  'JTC',
  'LTB',
  'OES',
  'FUR',
  'ILW',
  'CCE',
  'SJA',
  'LSC',
  'CUR',
  'SJC',
  'TYU',
  'CDW',
  'IHE',
  'KLO',
  'KNY',
  'LNF',
  'KAG',
  'PIE',
  'CBO',
  'JUS',
  'SHA',
  'ACO',
  'LUE',
  'SNY',
  'TIE',
  'NYP',
  'IUN',
  'EFG',
  'GHS',
  'ETH',
  'LPZ',
  'JOU',
  'SKS',
  'LVE',
  'RNY',
  'ASA',
  'LPC',
  'LRM',
  'UNE',
  'HMT',
  'PUF',
  'MGS',
  'EFU',
  'GAC',
  'GPS',
  'RAD',
  'KUT',
  'SSP',
  'IHG',
  'TUF',
  'EFS',
  'MNR',
  'OBL',
  'SIT',
  'CUP',
  'IEC',
  'KAU',
  'CXC',
  'SJA',
  'MES',
  'MWA',
  'HLU',
  'KNT',
  'UAP',
  'NER',
  'CIE',
  'CHE',
  'ENT',
  'MOA',
  'FDT',
  'JCC',
  'RDU',
  'SJC',
  'FOR',
  'STW',
  'EPE',
  'SBG',
  'KAY',
  'GRT',
  'SSF',
  'SJF',
  'VCS',
  'PUI',
  'KAL',
  'CHH',
  'REU',
  'KBN',
  'GNV',
  'CAD',
  'VPS',
  'SUS',
  'LJC',
  'WEB',
  'SGV',
  'UUC',
  'SLH',
  'GPR',
  'CKP',
  'GHA',
  'HLG',
  'HNU',
  'CHC',
  'KAN',
  'SRA',
  'PUI',
  'BAC',
  'LSA',
  'KNS',
  'UDN',
  'LCT',
  'LSD',
  'EMS',
  'BON',
  'EST',
  'TRN',
  'RMH',
  'ULG',
  'EXR',
  'GED',
  'KSN',
  'WOS',
  'IKT',
  'RIS',
  'NCH',
  'MBA',
  'TPW',
  'CPV',
  'KCC',
  'ROG',
  'CPE',
  'SUM',
  'NCZ',
  'CTX',
  'WHD',
  'OOI',
  'MRR',
  'KBO',
  'KAN',
  'BJU',
  'FUP',
  'KEC',
  'AUC',
  'HSS',
  'CCU',
  'SGC',
  'BNC',
  'TAM',
  'FTL',
  'KAB',
  'CSA',
  'LYL',
  'SJE',
  'CLN',
  'SNZ',
  'GCB',
  'DFW',
  'WHS',
  'EMM',
  'STU',
  'TER',
  'RGB',
  'BAT',
  'AIE',
  'SAL',
  'LOM',
  'BIG',
  'WPI',
  'GHS',
  'BUX',
  'LBK',
  'SOB',
  'NWS',
  'LWN',
  'FCD',
  'LFE',
  'TUC',
  'DOA',
  'IAF',
  'MOE',
  'HOU',
  'RIV',
  'PYE',
  'KYG',
  'CCB',
  'LFL',
  'ITQ',
  'HEA',
  'ULS',
  'KUW',
  'CLR',
  'SDM',
  'LLN',
  'NMH',
  'PAS',
  'FCS',
  'ITL',
  'WHY',
  'ROD',
  'FIC',
  'HRF',
  'FTP',
  'SXC',
  'DIG',
  'LLH',
  'FUP',
  'CZC',
  'CHM',
  'FAF',
  'SLU',
  'ABL',
  'URN',
  'WBH',
  'IAA',
  'OCI',
  'LNY',
  'KAT',
  'LSC',
  'UCP',
  'SLA',
  'LCK',
  'KNE',
  'SCX',
  'IWH',
  'KZD',
  'IKL',
  'TOL',
  'AVT',
  'LOR',
  'ESX',
  'BCE',
  'UBT',
  'RWD',
  'EBK',
  'PNG',
  'EPC',
  'LLM',
  'GGC',
  'TCM',
  'BAP',
  'CRL',
  'FIC',
  'LFW',
  'MUS',
  'CVS',
  'HSD',
  'UVA',
  'TSE',
  'EVO',
  'HBP',
  'GCA',
  'SOF',
  'PIT',
  'RMA',
  'LRA',
  'HLA',
  'HCD',
  'EHV',
  'HLY',
  'LVS',
  'UOT',
  'GIA',
  'EJO',
  'IAU',
  'UAH',
  'DOT',
  'REN',
  'UKA',
  'RBS',
  'HUV',
  'UNM',
  'HRS',
  'HGV',
  'PSS',
  'CGS',
  'KCE',
  'EHU',
  'ISD',
  'HOC',
  'MUU',
  'MIM',
  'EHJ',
  'IAE',
  'EME',
  'YNH',
  'IAM',
  'CVC',
  'SPK',
  'DEV',
  'EJC',
];
