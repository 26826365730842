import React, { Fragment, Suspense } from 'react';
import {
  GOOGLE_ANALYTICS_ID,
  GOOGLE_TAG_MANAGER_ENVS,
  GOOGLE_TAG_MANAGER_ID,
} from '../../constants/config';
import { GoogleAnalytics } from './GoogleAnalytics/GoogleAnalytics';
import { GoogleMarketing } from './GoogleMarketing/GoogleMarketing';
import { GoogleTagManager } from './GoogleTagManager/GoogleTagManager';
import { Hotjar } from './Hotjar/Hotjar';
import { MetaMarketing } from './MetaMarketing/MetaMarketing';
import { USE_TAG_MANAGER_FEATURE } from '../../constants/features';
import { useFeatureFlags } from 'hooks';

const Analytics = () => {
  const useTagManager = useFeatureFlags(USE_TAG_MANAGER_FEATURE);
  const isProdEnv = GOOGLE_TAG_MANAGER_ENVS.includes(window.location.hostname);

  if (!isProdEnv) {
    return null;
  }

  return (
    <Suspense fallback={<Fragment></Fragment>}>
      {useTagManager ? (
        <GoogleTagManager siteId={GOOGLE_TAG_MANAGER_ID} />
      ) : (
        <>
          <GoogleAnalytics siteId={GOOGLE_ANALYTICS_ID} />
          <GoogleMarketing />
          <MetaMarketing />
        </>
      )}
      <Hotjar />
    </Suspense>
  );
};

export { Analytics };
