import {
  CHINESE_FIELD_ID,
  CHINESE_FIELD_NAME,
  STUDENT_PASSPORT_FIELD_ID,
  PAYER_PASSPORT_FIELD_ID,
  PAN_NUMBER_FIELD,
  PAN_NAME_FIELD,
  SENDER_COUNTRY_FIELD,
  INVALID_PARAM_ERROR,
} from 'constants/index';
import {
  getFieldValue,
  getCountry,
  isReadOnly,
  isApiReadOnly,
  isPrefilled,
} from 'selectors';
import isEmpty from 'lodash/isEmpty';
import type { RootState } from 'reducers/types';
import type { Field, FieldEntityType, FieldValue } from 'models';

const CHINESE_FIELDS = [
  `offer_${CHINESE_FIELD_ID}`,
  `offer_${CHINESE_FIELD_NAME}`,
];
const INDIAN_FIELDS = [`offer_${PAN_NUMBER_FIELD}`, `offer_${PAN_NAME_FIELD}`];
const PASSPORT_FIELDS = [STUDENT_PASSPORT_FIELD_ID, PAYER_PASSPORT_FIELD_ID];

const getFieldError = (state: RootState, id: string) => {
  const { errors: { byParam = {} } = {} } = state;
  const { type, message } = byParam[id] || {};

  if (type !== INVALID_PARAM_ERROR) {
    return;
  }

  return message;
};

const getFieldText = (
  field: Field,
  defaultValue: FieldValue,
  state: RootState,
) => {
  const { value, type, format } = field;
  const fieldIsASelect = type === 'string' && Array.isArray(value);
  const fieldIsAMultiselect = type === 'array' && Array.isArray(value);
  const fieldIsACountry = format === 'country';

  switch (true) {
    case fieldIsASelect:
      const selectedValue =
        value.find(({ value }) => value === defaultValue) || {};
      return selectedValue.label;
    case fieldIsAMultiselect:
      const values = (defaultValue as string).split(',');
      const selectedValues = value.filter(({ value }) =>
        values.includes(value),
      );
      return selectedValues.map(v => v.label).join(', ');
    case fieldIsACountry:
      const country = getCountry(state, defaultValue as string);
      return country ? country.name : '';
    default:
      return defaultValue;
  }
};

export const getFieldById = (state: RootState, id: string): Field => {
  const {
    entities: {
      fields: {
        sender: { byId: senderFieldsById } = {},
        offers: { byId: offerFieldsById } = {},
        recipient: { byId: recipientFieldsById } = {},
      },
    },
  } = state;
  const field =
    (senderFieldsById && senderFieldsById[id]) ||
    (offerFieldsById && offerFieldsById[id]) ||
    (recipientFieldsById && recipientFieldsById[id]);

  const defaultValue = getFieldValue(state, id);

  const error = getFieldError(state, id);
  const isValid = error == null;
  const apiReadOnly = isApiReadOnly(state, id);
  const isSenderCountryPrefilled =
    id === SENDER_COUNTRY_FIELD && !isEmpty(defaultValue);
  const prefilled = isPrefilled(state, id) || isSenderCountryPrefilled;
  const text = field ? getFieldText(field, defaultValue, state) : '';
  const readOnly = isReadOnly(state, id) || apiReadOnly;

  return {
    id,
    ...(field || {}),
    defaultValue:
      field?.type === 'boolean'
        ? getBooleanDefaultValue(defaultValue)
        : defaultValue,
    error,
    isValid,
    readOnly,
    apiReadOnly,
    prefilled,
    text,
  };
};

export const getOfferFields = (state: RootState) => {
  const entity = getFieldByEntity(state, 'offers') || { ids: [] };
  const { ids } = entity;

  return ids.map(id => getFieldById(state, id));
};

export const getFieldByEntity = (state: RootState, entity: FieldEntityType) => {
  const { entities: { fields } = {} } = state;

  return fields?.[entity] || { ids: [], byId: {} };
};

export const getHiddenFieldIds = (state: RootState) => {
  const { byId: senderFieldsById } = getFieldByEntity(state, 'offers') || {};
  const { byId: offerFieldsById } = getFieldByEntity(state, 'sender') || {};
  const { byId: recipientFieldsById } =
    getFieldByEntity(state, 'recipient') || {};

  const fields = {
    ...offerFieldsById,
    ...senderFieldsById,
    ...recipientFieldsById,
  };
  const fieldIds = Object.keys(fields);

  const hiddenFieldIds = fieldIds.reduce((acc, id) => {
    if (fields[id].hidden) return [...acc, id];
    return acc;
  }, [] as string[]);

  return hiddenFieldIds;
};

export const getKYCFields = (state: RootState) => {
  const entity = getFieldByEntity(state, 'kycs');
  const ids = entity?.ids;

  return ids?.map(id => entity?.byId[id]) || [];
};

export const hasChineseFields = (state: RootState) =>
  hasOfferFields(CHINESE_FIELDS, state);
export const hasIndianFields = (state: RootState) =>
  hasOfferFields(INDIAN_FIELDS, state);
export const hasPassportFields = (state: RootState) =>
  hasRecipientFields(PASSPORT_FIELDS, state);

const hasOfferFields = (fieldsToSearch: string[], state: RootState) => {
  return hasEntityFields(state, 'offers', fieldsToSearch);
};

const hasRecipientFields = (fieldsToSearch: string[], state: RootState) => {
  return hasEntityFields(state, 'recipient', fieldsToSearch);
};

const hasEntityFields = (
  state: RootState,
  entity: FieldEntityType,
  fieldsToSearch: string[],
) => {
  const { ids = [] } = getFieldByEntity(state, entity);
  const isFound = (field: string) => fieldsToSearch.includes(field);

  return ids.some(isFound);
};

const getBooleanDefaultValue = (value: string | boolean | number) => {
  return typeof value === 'boolean' ? value : value === 'true';
};
