import { newNotification } from 'actions';
import * as ACTION_TYPES from '../../../constants';
import { Middleware } from 'redux';
import { RootState } from 'reducers/types';

const isError = (type: string) => type.includes('FAILURE');

export const UINotifications: Middleware<unknown, RootState> =
  store => next => action => {
    const { type, payload } = action;
    const listenedActions = [
      ACTION_TYPES.CANCEL_PAYMENT_SUCCESS,
      ACTION_TYPES.CANCEL_RECURRING_RECEIVABLE_FAILURE,
      ACTION_TYPES.CANCEL_RECURRING_RECEIVABLE_SUCCESS,
      ACTION_TYPES.CHANGE_USER_PASSWORD_SUCCESS,
      ACTION_TYPES.DELETE_FILE_SUCCESS,
      ACTION_TYPES.DELETE_PROOF_FILE_SUCCESS,
      ACTION_TYPES.INTERNAL_SERVER_FAILURE,
      ACTION_TYPES.REMOVE_CREDIT_CARD_FAILURE,
      ACTION_TYPES.REMOVE_CREDIT_CARD_SUCCESS,
      ACTION_TYPES.SAVE_CARD_SUCCESS,
      ACTION_TYPES.SAVED_SUCCESSFULLY,
      ACTION_TYPES.UPDATE_PAYMENT_SUCCESS,
      ACTION_TYPES.UPDATE_SMS_NOTIFICATION_SUCCESS,
      ACTION_TYPES.UPDATE_USER_SUCCESS,
      ACTION_TYPES.UPLOAD_DOCUMENT_FAILURE,
      ACTION_TYPES.UPLOAD_DOCUMENT_MAX_FILES_FAILURE,
      ACTION_TYPES.UPLOAD_LRS_INFO_SUCCESS,
      ACTION_TYPES.UPLOAD_PROOF_SUCCESS,
      ACTION_TYPES.UPLOAD_REFUND_KYC_DOCUMENT_FAILURE,
      ACTION_TYPES.CREATE_RECURRING_RECEIVABLE_SUCCESS,
    ];

    if (listenedActions.includes(type)) {
      const message = `notifications.${type.toLowerCase()}`;
      let notificationType: 'success' | 'error' | 'info' = 'success';

      if (isError(type)) {
        notificationType = 'error';
      }

      store.dispatch(
        newNotification({ message, opts: payload, type: notificationType }),
      );
    }

    return next(action);
  };

export default UINotifications;
