import React from 'react';
import { useTranslations } from 'utils';

import './RequiredFieldLabel.scss';

const RequiredFieldLabel = () => {
  const i18n = useTranslations();

  return (
    <div className="RequiredFieldLabel" aria-hidden>
      {i18n.t('requiredField.label')}
    </div>
  );
};

export { RequiredFieldLabel };
