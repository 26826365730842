import React from 'react';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils';
import { InfoCircle } from 'icons';

import './FeeDisclosure.scss';

function FeeDisclosure({ fee }: { fee: string }) {
  const i18n = useTranslations();

  return (
    <div className="CapFeeDisclosure">
      <p className="CapFeeDisclosure-content">
        <InfoCircle className="CapFeeDisclosure-icon" />
        <span
          dangerouslySetInnerHTML={createMarkup(
            i18n.t('CAPFeeDisclosure.message', { fee }),
          )}
        />
      </p>
    </div>
  );
}

export { FeeDisclosure };
