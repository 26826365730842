export const BLACKLISTED_SENTRY_ERRORS = [
  'Can not register with opener: no bridge found in opener',
  'Failed to fetch',
  'Load failed',
  'NetworkError when attempting to fetch resource.',
  'cancelled',
  'cancelado',
  '已取消',
  'отменено',
  'annulé',
  'キャンセルしました',
  'annullato',
  'Abgebrochen',
  'geannuleerd',
  'dibatalkan',
  'đã hủy',
  'anulat',
  '취소됨',
  '网络连接已中断。',
  'Đã mất kết nối mạng.',
  'Сетевое соединение потеряно.',
  'privateSpecialRepair is not defined',
  'window.bannerNight is not a function',
  "Can't find variable: WeixinJSBridge",
];
export const IGNORE_SENTRY_ERRORS = [
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
  'Illegal invocation',
  'Loading chunk',
  'Loading CSS chunk',
  'Failed to fetch',
  'Load failed',
  'NetworkError when attempting to fetch resource.',
  'cancelled',
  'zaloJSV2',
  'Weixin',
  'FETCH_PAYMENT_FAILURE Invalid order id (undefined) or token (undefined) when fetching order payment',
  // Related to New Relic script
  "Cannot read property 'sameOrigin' of undefined",
  "Cannot read properties of undefined (reading 'sameOrigin')",
  "undefined is not an object (evaluating 't.sameOrigin')",
  'Embed startup failed. This is likely because one or all frame components failed to connect. Please check your internet connection.',
  // Related to Hotjar
  "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
  // ADA
  'NotSupportedError: The operation is not supported.',
  // Related to Hotjar
  'c/hotjar',
  /**
   * Thrown when firefox prevents an add-on from referencing a DOM element that has been removed.
   * This can also be filtered by enabling the browser extension inbound filter
   */
  "TypeError: can't access dead object",
  /**
   * React internal error thrown when something outside react modifies the DOM
   * This is usually because of a browser extension or Chrome's built-in translate
   */
  "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
];
export const TAX_ERROR = 'tax_error';
export const INVALID_PARAM_ERROR = 'invalid_param';
export const DUPLICATED_PAYMENT = 'duplicated_payment';
export const DUPLICATED_PAYMENT_ERROR = 'duplicated_payment_error'; // Used for indian tax and duplicated pan number error
export const DUPLICATED_PAN_PARAM = 'pan_number'; // Used for duplicated pan number error
export const TAXES_SERVICE_ERROR = 'taxes_service_error';
export const INBOUND_PROCESSOR_ERROR = 'inbound_processor_error';
export const GENERIC_ERROR = 'generic_error';
export const VERIFICATION_ERROR_CODES = [
  'invalid_payer_or_student',
  'student_name_or_id_mismatch',
  'student_name_or_id_missing_in_document',
  'payment_amount_missing_in_document',
  'school_name_missing_in_document',
  'student_visa_missing',
  'payment_amount_mismatch',
  'student_non_chinese_resident',
  'student_admission_proof_missing',
  'payer_passport_missing',
  'student_visa_expired',
  'invalid_id',
];
export const VERIFICATION_FALLBACK_ERROR_CODE = 'unknown_reason';
export const CARD_CHARGE_ERRORS = [
  'balance',
  'declined',
  'failed',
  'generic',
] as const;
export const PAYMENT_FETCH_ERROR = 'payment fetch error';
