import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EmbeddedStyle } from './EmbeddedStyle/EmbeddedStyle';
import { Chat } from 'components/Chat/Chat';
import { embedded } from 'repositories';
import logger from 'services/logger';
import {
  fetchEmbedded,
  fetchOrder,
  hideUserManagement,
  setEmbedded,
  setGatewayOrigin,
  setFieldValue,
  setFieldsValues,
  setReadOnlyFields,
  setRecipient,
  setPrefilledFields,
  exchangeLoginToken,
  setTheme,
  setPayouts,
  setMaxAmount,
  fetchSender,
} from 'actions';
import { cleanEmptyKeys } from 'utils/cleanEmptyKeys/cleanEmptyKeys';

const SENDER_COUNTRY_FIELD = 'sender_country';

class EmbeddedSetupComponent extends Component {
  static propTypes = {
    exchangeLoginToken: PropTypes.func.isRequired,
    fetchEmbedded: PropTypes.func.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    fetchSender: PropTypes.func.isRequired,
    hideUserManagement: PropTypes.func.isRequired,
    setEmbedded: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldsValues: PropTypes.func.isRequired,
    setGatewayOrigin: PropTypes.func.isRequired,
    setMaxAmount: PropTypes.func.isRequired,
    setPayouts: PropTypes.func.isRequired,
    setPrefilledFields: PropTypes.func.isRequired,
    setReadOnlyFields: PropTypes.func.isRequired,
    setRecipient: PropTypes.func.isRequired,
    setTheme: PropTypes.func.isRequired,
  };

  state = {
    isFetching: true,
  };

  async componentDidMount() {
    const {
      hideUserManagement,
      setEmbedded,
      setFieldValue,
      setGatewayOrigin,
      setPayouts,
      setRecipient,
      setMaxAmount,
      fetchEmbedded,
      fetchSender,
    } = this.props;

    this.logEmbeddedProps();

    const {
      country,
      destination,
      env,
      hideUserManagement: shouldHideUserManagement,
      locale,
      onPaymentCreated,
      loginToken,
      orderId,
      orderToken,
      payables: payouts,
      read_only: readOnly = '',
      max_amount: maxAmount,
      theme,
      token,
      ...embeddedFields
    } = embedded.getProps();

    fetchEmbedded(true);
    setEmbedded();
    setGatewayOrigin();

    if (maxAmount) setMaxAmount(maxAmount);
    if (shouldHideUserManagement === true) hideUserManagement();
    this.setTheme(theme);
    this.exchangeLoginToken(loginToken);

    const toArray = object => Object.values(object);

    this.extractPrefilledFields(embeddedFields);

    if (token) {
      await this.fetchOrderByToken(token);
    } else {
      if (payouts) setPayouts(toArray(payouts));
      setRecipient(destination);
      this.setReadOnly(readOnly);
      await this.setFields(embeddedFields);

      if (country) {
        await setFieldValue(SENDER_COUNTRY_FIELD, country);
      }

      await fetchSender();

      await this.fetchOrder(orderId, orderToken);
    }

    this.finishFetching();
  }

  setReadOnly(readOnly) {
    const { setReadOnlyFields } = this.props;
    const fields = readOnly.split(/\s*,\s*/);

    setReadOnlyFields(fields);
  }

  finishFetching() {
    const { fetchEmbedded } = this.props;

    this.setState({ isFetching: false });
    fetchEmbedded(false);
  }

  setTheme(theme) {
    const { setTheme } = this.props;

    setTheme(theme);
  }

  setFields(embeddedFields) {
    const { setFieldsValues } = this.props;

    return setFieldsValues(embeddedFields);
  }

  extractPrefilledFields(embeddedFields) {
    const { setPrefilledFields } = this.props;
    const embeddedFieldsWithValues = cleanEmptyKeys(embeddedFields);
    const prefilledFields = Object.keys(embeddedFieldsWithValues);

    setPrefilledFields(prefilledFields);
  }

  logEmbeddedProps() {
    logger.log({
      description: 'embedded parameters',
      parameters: embedded.getProps(),
    });
  }

  exchangeLoginToken(loginToken) {
    const { exchangeLoginToken } = this.props;

    if (loginToken) {
      exchangeLoginToken(loginToken);
    }
  }

  async fetchOrder(orderId, orderToken) {
    if (!orderId || !orderToken) return;

    const { fetchOrder } = this.props;

    await fetchOrder({ orderId, token: orderToken });
  }

  async fetchOrderByToken(token) {
    if (!token) return;

    const { fetchOrder } = this.props;

    await fetchOrder({ token });
  }

  render() {
    const { isFetching } = this.state;

    if (isFetching) return null;

    return (
      <>
        <Chat />
        <EmbeddedStyle />
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchEmbedded,
  fetchOrder,
  fetchSender,
  exchangeLoginToken,
  hideUserManagement,
  setEmbedded,
  setGatewayOrigin,
  setFieldValue,
  setFieldsValues,
  setReadOnlyFields,
  setRecipient,
  setPayouts,
  setPrefilledFields,
  setTheme,
  setMaxAmount,
};

const EmbeddedSetup = connect(null, mapDispatchToProps)(EmbeddedSetupComponent);

export { EmbeddedSetup };
