import { ReactNode, ReactElement } from 'react';

interface StepProps {
  id: string;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Step({ id, children }: StepProps): ReactElement | null {
  return children as ReactElement;
}

Step.displayName = 'Step';
export { Step };
