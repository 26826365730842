import { PROMOTIONS_URL } from 'constants/config';
import fetch from 'utils/fetchJson';

export const promotions = {
  subscribe: (senderEmail: string) => {
    const payload = {
      email: senderEmail,
    };
    const body = JSON.stringify(payload);
    const options = { body, method: 'POST' };

    return fetch<void>(PROMOTIONS_URL, options);
  },
};
