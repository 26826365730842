import React, { useEffect, useState } from 'react';
import {
  BankAccountTokenizationEvent,
  Instructions,
} from 'services/tokenization/models/models';
import { Spinner } from 'components/Spinner/Spinner';
import { Steps } from '../../hooks/useSteps';
import {
  CreateRecurringBody,
  useDirectDebitPaymentContext,
} from '../../hooks/useDirectDebitPaymentContext';
import { useGenericEvent, useBankAccountTokenizationInstructions } from 'hooks';

import './Pay.scss';

function Pay() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    directDebitWizardNavigation,
    createRecurringReceivable,
    instructionsAttributes,
  } = useDirectDebitPaymentContext();
  const [instructions, setInstructions] = useState<Instructions>();

  const { mutate: createInstructions } = useBankAccountTokenizationInstructions(
    {
      onSuccess: (instructions: Instructions) => {
        setInstructions(instructions);
      },
      onError: () => {
        directDebitWizardNavigation.go(Steps.Error);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const { eventData } = useGenericEvent(data => {
    setIsLoading(false);

    return new BankAccountTokenizationEvent(data);
  }, 'bank_account_tokenization');

  useEffect(() => {
    setIsLoading(true);
    createInstructions(instructionsAttributes);
  }, [createInstructions, instructionsAttributes]);

  useEffect(() => {
    const createRecurring = async (attrs: CreateRecurringBody) => {
      try {
        setIsLoading(true);
        await createRecurringReceivable(attrs);
        directDebitWizardNavigation.go(Steps.Success);
      } catch (err) {
        console.error(err);
        directDebitWizardNavigation.go(Steps.Error);
      } finally {
        setIsLoading(false);
      }
    };

    if (eventData?.isValid) {
      createRecurring({
        apiConfirmationURL: eventData.data.confirmationUrl,
        uuidToken: eventData.data.uuidToken,
      });
    } else if (eventData?.isInvalid) {
      directDebitWizardNavigation.go(Steps.Error);
    }
  }, [eventData, directDebitWizardNavigation, createRecurringReceivable]);

  return (
    <div className="PayDirectDebit">
      {isLoading ? (
        <Spinner className="PayRecurring-spinner" />
      ) : instructions?.hostedFormUrl ? (
        <iframe
          title="FormInstructions"
          src={instructions.hostedFormUrl}
          data-testid="BankAccountTokenization-instructions"
          onLoad={() => {
            setIsLoading(false);
          }}
        />
      ) : null}
    </div>
  );
}

export { Pay };
