import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';

interface Option {
  value: string | number;
  label: string;
}

interface Props {
  className?: string;
  selectedValue: string | number;
  values: Option[];
}

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ className, selectedValue, values, ...other }, ref) => {
    const options = useMemo(
      () =>
        values.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )),
      [values],
    );

    return (
      <select
        className={classNames('Select', className)}
        ref={ref}
        value={selectedValue}
        {...other}
      >
        {options}
      </select>
    );
  },
);

Select.displayName = 'Select';

export { Select };
