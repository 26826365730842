import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes as Switch, useSearchParams } from 'react-router-dom';
import uuid from 'uuid';
import { Spinner } from 'components/Spinner/Spinner';
import { AuthRoute } from './AuthRoute/AuthRoute';
import { PrefillOrderAndPay } from 'components/PrefillOrderAndPay/PrefillOrderAndPay';
import { IsEmbeddedFetched } from 'containers/IsEmbeddedFetched/IsEmbeddedFetched';
import { PaymentProcess } from 'containers/PaymentProcess/PaymentProcess';
import { enableFeature, fetchIPCountryCode, fetchFeatures } from 'actions';
import {
  CAP_DUPLICATED_ROUTE,
  CASHBACK_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  GATEWAY_DUPLICATED_ROUTE,
  IMPERSONATE_ROUTE,
  KYC_DETAILS_ROUTE,
  LOGIN_ROUTE,
  ORDER_DETAIL_ROUTE,
  ORDER_TRACKING_ROUTE,
  PAY_ROUTE,
  PAY_STEP_ROUTE,
  PAYMENT_DETAILS_ROUTE,
  PAYMENTS_ROUTE,
  PROFILE_ROUTE,
  PROFILE_SECTION_ROUTE,
  RECURRING_RECEIVABLES_ROUTE,
  REFUND_ROUTE,
  REQUEST_RESET_PASSWORD_ROUTE,
  ROOT_ROUTE,
  SIGN_UP_ROUTE,
  WIDGET_ROUTE,
} from 'constants/routes';
import lazyLoadReact from 'utils/lazyLoadReact';
import { sessionStorage } from 'utils/storage';
import { SESSION_ID_NAME } from 'constants/index';
import { useMaintenanceMode } from 'hooks/useMaintenanceMode/useMaintenanceMode';
import { useIsImpersonatingUser } from 'hooks/isImpersonatingUser/isImpersonatingUser';
import { RedirectToPay } from './RedirectToPay/RedirectToPay';

const CAPDuplicatedPage = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "DuplicatedPage" */ 'components/CAP/DuplicatedPage/DuplicatedPage'
  ).then(module => ({
    default: module.DuplicatedPage,
  })),
);
const CashBack = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "CashBack" */ 'components/CashBack/CashBack'
  ).then(module => ({
    default: module.CashBack,
  })),
);
const ChangePasswordPage = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "ChangePasswordPage" */ 'components/ChangePasswordPage/ChangePasswordPage'
  ).then(module => ({
    default: module.ChangePasswordPage,
  })),
);
const ForgotPasswordPage = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "ForgotPasswordPage" */ 'components/ForgotPasswordPage/ForgotPasswordPage'
  ).then(module => ({
    default: module.ForgotPasswordPage,
  })),
);
const GatewayDuplicatedPage = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "GatewayDuplicatedPage" */ 'components/GatewayDuplicatedPage/GatewayDuplicatedPage'
  ).then(module => ({
    default: module.GatewayDuplicatedPage,
  })),
);
const KYCSetup = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "KYCSetup" */ 'components/KYCSetup/KYCSetup'
  ).then(module => ({
    default: module.KYCSetup,
  })),
);
const LoginPage = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "LoginPage" */ 'components/LoginPage/LoginPage'
  ).then(module => ({
    default: module.LoginPage,
  })),
);
const MaintenancePage = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "MaintenancePage" */ 'components/MaintenancePage/MaintenancePage'
  ).then(module => ({
    default: module.MaintenancePage,
  })),
);
const NotFound = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "NotFound" */ 'components/NotFound/NotFound'
  ).then(module => ({
    default: module.NotFound,
  })),
);
const PaymentDetailSetup = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "PaymentDetailSetup" */ 'components/PaymentDetailSetup/PaymentDetailSetup'
  ).then(module => ({
    default: module.PaymentDetailSetup,
  })),
);
const Profile = lazyLoadReact(() =>
  import(/* webpackChunkName: "Profile" */ 'components/Profile/Profile').then(
    module => ({
      default: module.Profile,
    }),
  ),
);
const PaymentRefunds = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "PaymentRefunds" */ './PaymentRefunds/PaymentRefunds'
  ).then(module => ({
    default: module.PaymentRefunds,
  })),
);
const SignUpPage = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "SignUpPage" */ 'components/SignUpPage/SignUpPage'
  ).then(module => ({
    default: module.SignUpPage,
  })),
);
const TrackingSetup = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "TrackingSetup" */ 'components/TrackingSetup/TrackingSetup'
  ).then(module => ({
    default: module.TrackingSetup,
  })),
);
const WidgetSetup = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "WidgetSetup" */ 'components/WidgetSetup/WidgetSetup'
  ).then(module => ({
    default: module.WidgetSetup,
  })),
);
const Impersonate = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "Impersonate" */ 'containers/Impersonate/Impersonate'
  ).then(module => ({
    default: module.Impersonate,
  })),
);
const PaymentList = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "PaymentList" */ 'containers/PaymentList/PaymentList'
  ).then(module => ({
    default: module.PaymentListContainer,
  })),
);
const RecurringReceivables = lazyLoadReact(() =>
  import(
    /* webpackChunkName: "RecurringReceivables" */ 'containers/RecurringReceivables/RecurringReceivables'
  ).then(module => ({
    default: module.RecurringReceivables,
  })),
);

function Routes() {
  const inMaintenanceMode = useMaintenanceMode();
  const isImpersonatingUser = useIsImpersonatingUser();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const features = searchParams.get('features');
    sessionStorage.setItem(SESSION_ID_NAME, uuid.v4());
    dispatch(fetchFeatures());

    if (features) {
      features.split(',').forEach((feature: string) => {
        dispatch(enableFeature(feature));
      });
    }

    dispatch(fetchIPCountryCode());
  }, [dispatch, searchParams]);

  return (
    <Suspense fallback={<Spinner />}>
      {inMaintenanceMode ? (
        <MaintenancePage />
      ) : (
        <Switch>
          <Route path={ROOT_ROUTE} element={<RedirectToPay />} />
          {[PAY_ROUTE, PAY_STEP_ROUTE].map(path => (
            <Route
              key={path}
              path={path}
              element={
                <IsEmbeddedFetched>
                  <PaymentProcess />
                </IsEmbeddedFetched>
              }
            />
          ))}

          <Route path={ORDER_DETAIL_ROUTE} element={<PrefillOrderAndPay />} />
          <Route path={ORDER_TRACKING_ROUTE} element={<TrackingSetup />} />
          <Route path={LOGIN_ROUTE} element={<LoginPage />} />
          <Route
            path={REQUEST_RESET_PASSWORD_ROUTE}
            element={<ForgotPasswordPage />}
          />

          <Route
            path={CHANGE_PASSWORD_ROUTE}
            element={<ChangePasswordPage />}
          />

          <Route path={SIGN_UP_ROUTE} element={<SignUpPage />} />
          <Route path={CAP_DUPLICATED_ROUTE} element={<CAPDuplicatedPage />} />

          <Route
            path={GATEWAY_DUPLICATED_ROUTE}
            element={<GatewayDuplicatedPage />}
          />

          <Route path={WIDGET_ROUTE} element={<WidgetSetup />} />
          <Route path={KYC_DETAILS_ROUTE} element={<KYCSetup />} />
          <Route
            path={PAYMENTS_ROUTE}
            element={
              <AuthRoute>
                <PaymentList />
              </AuthRoute>
            }
          />

          <Route
            path={CASHBACK_ROUTE}
            element={
              <AuthRoute>
                <CashBack />
              </AuthRoute>
            }
          />

          <Route
            path={REFUND_ROUTE}
            element={
              <AuthRoute>
                <PaymentRefunds />
              </AuthRoute>
            }
          />

          <Route
            path={RECURRING_RECEIVABLES_ROUTE}
            element={
              <AuthRoute>
                <RecurringReceivables />
              </AuthRoute>
            }
          />

          {isImpersonatingUser ? null : (
            <Route
              path={PAYMENT_DETAILS_ROUTE}
              element={
                <AuthRoute>
                  <PaymentDetailSetup />
                </AuthRoute>
              }
            />
          )}

          <Route
            path={PROFILE_SECTION_ROUTE}
            element={
              <AuthRoute>
                <Profile />
              </AuthRoute>
            }
          />

          <Route
            path={PROFILE_ROUTE}
            element={
              <AuthRoute>
                <Profile />
              </AuthRoute>
            }
          />

          <Route path={IMPERSONATE_ROUTE} element={<Impersonate />} />
          <Route path="*" element={<NotFound />} />
        </Switch>
      )}
    </Suspense>
  );
}

export { Routes };
