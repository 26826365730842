import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useStep } from '@flywire/react-hooks';
import { useSteps, Steps } from './useSteps';
import type { Price, Step } from 'models';
import { CardInstructionsAttributes } from 'services/tokenization/models/models';
import { useRecurringOffer } from './useRecurringOffer';
import { useRecurringOfferPrice } from './useRecurringOfferPrice';
import { useTranslations } from 'utils';

export type Navigation = {
  next: () => void;
  prev: () => void;
  go: (nextStep: number | string) => void;
};

export type CreateRecurringBody =
  | {
      apiConfirmationURL?: never;
      cardToken: string;
      uuidToken?: never;
      sessionId?: never;
    }
  | {
      apiConfirmationURL: string;
      cardToken?: never;
      uuidToken: string;
      sessionId?: never;
    }
  | {
      apiConfirmationURL: string;
      sessionId: string;
      cardToken?: never;
      uuidToken?: never;
    };

interface CardPaymentContextType {
  accessToken: string;
  cardWizardNavigation?: Navigation;
  children: React.ReactNode;
  country: string;
  newNotification: ({ message }: { message: string }) => void;
  createRecurringReceivable: ({
    apiConfirmationURL,
    cardToken,
    uuidToken,
    sessionId,
  }: CreateRecurringBody) => void;
  paymentWizardNavigation: Navigation;
  recipientId: string;
}

export interface CardPaymentProviderType extends CardPaymentContextType {
  cardWizardNavigation: Navigation;
  currentStep: Step<Steps>;
  index: number;
  instructionsAttributes?: CardInstructionsAttributes;
  steps: Array<Step<Steps>>;
  errorKey: string;
  setErrorKey: Dispatch<SetStateAction<string>>;
}

const CardPaymentContext = createContext<CardPaymentProviderType>(
  {} as CardPaymentProviderType,
);

function CardPaymentProvider({
  accessToken,
  cardWizardNavigation,
  children,
  country,
  paymentWizardNavigation,
  recipientId,
  createRecurringReceivable,
  newNotification,
}: CardPaymentContextType) {
  const [errorKey, setErrorKey] = useState(
    'recurring.payment.error.description',
  );
  const i18n = useTranslations();
  const steps = useSteps();
  const { index, navigation } = useStep({
    initialStep: 0,
    steps,
  });

  const { offer } = useRecurringOffer();
  const { value: offerValue, currency: currencyId } = offer.price as Price;
  const { payButtonLabel, currencyCode } = useRecurringOfferPrice(
    offerValue,
    currencyId as string,
  );

  const instructionsAttributes = useMemo(
    () =>
      ({
        accessToken,
        actionButton: payButtonLabel,
        country,
        locale: i18n.localeCode,
        payorIdType: 'user',
        theme: 'payex_gbp',
        recipientId,
        currencyCode,
      } as CardInstructionsAttributes),
    [
      accessToken,
      country,
      currencyCode,
      i18n.localeCode,
      payButtonLabel,
      recipientId,
    ],
  );

  const value = useMemo(
    () => ({
      accessToken,
      cardWizardNavigation: cardWizardNavigation || navigation,
      children,
      country,
      currentStep: steps[index],
      index,
      instructionsAttributes,
      paymentWizardNavigation,
      recipientId,
      steps,
      createRecurringReceivable,
      newNotification,
      errorKey,
      setErrorKey,
    }),
    [
      accessToken,
      cardWizardNavigation,
      navigation,
      children,
      country,
      steps,
      index,
      instructionsAttributes,
      paymentWizardNavigation,
      recipientId,
      createRecurringReceivable,
      newNotification,
      errorKey,
    ],
  );

  return (
    <CardPaymentContext.Provider value={value}>
      {children}
    </CardPaymentContext.Provider>
  );
}

function useCardPaymentContext() {
  const context = useContext(CardPaymentContext);

  if (context === undefined) {
    throw new Error(
      'useCardPaymentContext must be used within a CardPaymentProvider',
    );
  }

  return context;
}

export { CardPaymentProvider, useCardPaymentContext };
