import {
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_TRACKING_SETUP_DATA_REQUEST,
  FETCH_TRACKING_SETUP_DATA_SUCCESS,
  FETCH_TRACKING_SETUP_DATA_FAILURE,
  HttpError,
} from 'constants/index';
import {
  getCurrentLocale,
  getPayment,
  getPaymentId,
  isFetching,
} from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import { HTTPStatuses } from '../../constants';
import type { PayexThunkAction } from 'store/configureStore';
import { isHttpErrorWithStatus } from 'utils/errors/errors';

export const fetchDocuments =
  (): PayexThunkAction =>
  async (dispatch, getState, { payex }) => {
    const state = getState();
    const isFetchingDocuments = isFetching(state, 'documents');
    const paymentId = getPaymentId(state);

    if (!paymentId || isFetchingDocuments) {
      return;
    }

    dispatch({ type: FETCH_DOCUMENTS_REQUEST });

    const { token } = getPayment(state);
    const locale = getCurrentLocale(state);
    try {
      const response = await payex.trackingPage.fetch({
        paymentId,
        token,
        locale,
      });

      return dispatch({
        type: FETCH_DOCUMENTS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const { status } = error as HttpError;

        if (status === HTTPStatuses.NOT_FOUND)
          return dispatch({
            type: FETCH_DOCUMENTS_SUCCESS_WITHOUT_RESULTS,
          });
      }

      errorNotifier.notify(error);

      return dispatch({ type: FETCH_DOCUMENTS_FAILURE });
    }
  };

export const fetchTrackingSetupData =
  (orderId: string, orderToken: string): PayexThunkAction =>
  async (dispatch, getState, { payex }) => {
    const state = getState();
    const isFetchingData = isFetching(state, 'trackingSetupData');

    if (isFetchingData) return;

    dispatch({ type: FETCH_TRACKING_SETUP_DATA_REQUEST });

    try {
      const response = await payex.trackingPage.data(orderId, orderToken);

      dispatch({
        type: FETCH_TRACKING_SETUP_DATA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const _error = error as HttpError;
        const { status = '' } = _error;
        if (status !== HTTPStatuses.NOT_FOUND) {
          _error.message = `${FETCH_TRACKING_SETUP_DATA_FAILURE} ${_error.message}`;
          errorNotifier.notifyWithFingerprint(_error, [
            FETCH_TRACKING_SETUP_DATA_FAILURE,
            `${status}`,
          ]);
        }
      }

      return dispatch({
        type: FETCH_TRACKING_SETUP_DATA_FAILURE,
      });
    }
  };
