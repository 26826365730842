import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { getAccessToken } from 'selectors/entities/authentication/authentication';
import { getRecipientId } from 'selectors/entities/recipient/recipient';
import { getCurrentLocale } from 'selectors/ui/ui';
import logger from 'services/logger/logger';
import {
  sessions,
  type CheckoutSessionType,
  type CreateSessionParams,
} from 'services/sessions/sessions';

type UseCreateCheckoutSessionProps = {
  paymentMethodToken?: string;
  sessionType: CheckoutSessionType;
};

const useCreateCheckoutSession = ({
  paymentMethodToken,
  sessionType,
}: UseCreateCheckoutSessionProps) => {
  const accessToken = useSelector(getAccessToken);
  const locale = useSelector(getCurrentLocale);
  const recipientId = useSelector(getRecipientId);
  const [sessionId, setSessionId] = useState<string>('');
  const [createSessionError, setCreateSessionError] = useState<unknown>();

  const { mutateAsync: createCheckoutSession } = useMutation(
    [`checkoutCreateSession`],
    async (params: CreateSessionParams) => sessions.create(params),
  );

  const createSession = useCallback(() => {
    return createCheckoutSession(
      { accessToken, locale, paymentMethodToken, recipientId, sessionType },
      { onSuccess: ({ id }) => setSessionId(id) },
    );
  }, [
    accessToken,
    createCheckoutSession,
    locale,
    paymentMethodToken,
    recipientId,
    sessionType,
  ]);

  useEffect(() => {
    async function createCheckoutSession() {
      try {
        logger.log({
          description: '[PAAR-5331] Creating session on component render',
          parameters: {
            locale,
            recipientId,
            sessionType,
          },
        });
        await createSession();
      } catch (error) {
        setCreateSessionError(error);
      }
    }

    createCheckoutSession();
  }, [createSession]); // eslint-disable-line react-hooks/exhaustive-deps

  return { createSession, createSessionError, sessionId };
};

export { useCreateCheckoutSession };
