import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTheme } from 'selectors';
import type { RootState } from 'reducers/types';

const ThemeComponent = ({ render, brandColor, chat, footer, preFooter }) => {
  const state = { brandColor, chat, footer, preFooter };
  return render(state);
};

ThemeComponent.defaultProps = {
  chat: true,
  footer: true,
  preFooter: true,
};

ThemeComponent.propTypes = {
  brandColor: PropTypes.string,
  chat: PropTypes.bool,
  footer: PropTypes.bool,
  preFooter: PropTypes.bool,
};

const mapStateToProps = (state: RootState) => {
  const themeProps = getTheme(state);
  return themeProps;
};

const Theme = connect(mapStateToProps)(ThemeComponent);

export { Theme };
