import type { RootState } from 'reducers/types';
import { useSelector } from 'react-redux';
import { getCurrency, getSchedule } from 'selectors';
import { formatAmount } from 'utils/money';
import { useTranslations } from 'utils';

function useRecurringOfferPrice(value: number, currencyId: string) {
  const i18n = useTranslations();
  const currency = useSelector((state: RootState) =>
    getCurrency(state, currencyId),
  );
  const schedule = useSelector(getSchedule);

  const amount = formatAmount(value, currency);

  const payButtonLabel =
    schedule === 'pay_now'
      ? i18n.t('cardPayment.pay', {
          amount,
        })
      : i18n.t('cardPayment.createPlan');

  return { payButtonLabel, currencyCode: currency.code };
}

export { useRecurringOfferPrice };
