import { FETCH_OFFER_SUCCESS } from 'constants/index';
import type { Offer } from 'models';

type OfferAction = {
  type: typeof FETCH_OFFER_SUCCESS;
  payload: {
    entities: {
      offers: Record<string, Offer>;
    };
    result: string;
  };
};

const offer = (
  state: Offer | Record<string, never> = {},
  action: OfferAction,
) => {
  switch (action.type) {
    case FETCH_OFFER_SUCCESS: {
      const {
        entities: { offers },
        result,
      } = action.payload;

      return { ...state, ...offers[result] };
    }
    default: {
      return state;
    }
  }
};

export default offer;
