import { getCurrency } from 'selectors';
import { RECIPIENT_CAP, REDIRECT_RECIPIENTS } from 'constants/index';
import type { RootState } from 'reducers/types';
import type {
  Currency,
  Recipient,
  RecipientMetadata,
  SelectedRecipient,
} from 'models';
import { sortCountriesByLabel } from 'utils/countries/countries';

export const getRecipient = (state: RootState) => {
  return state.entities?.recipient;
};

export const getRecipientId = (state: RootState) => {
  return getRecipient(state)?.id;
};

export const getRecipientSubdomain = (state: RootState) => {
  const { subdomain } = getRecipient(state);

  return subdomain;
};

export const getRecipientName = (state: RootState) => {
  const { name } = getRecipient(state);

  return name;
};

export const isRecipientBPG = (state: RootState) => {
  const { metadata: { priceGuarantee = false } = {} } = getRecipient(state);

  return priceGuarantee;
};

export const gatewayOnly = (state: RootState) => {
  const {
    metadata: { gatewayOnly } = {
      gatewayOnly: { enabled: false, redirectUrl: '' },
    },
  } = getRecipient(state);

  return gatewayOnly;
};

export const isRecipientClientPrepopulated = (state: RootState) => {
  const { metadata: { clientPrepopulated = false } = {} } = getRecipient(state);

  return clientPrepopulated;
};

export const getWidgetMessage = (state: RootState) => {
  const { metadata: { widgetMessage = '' } = {} } = getRecipient(state);

  return widgetMessage;
};

export const autoLogoutEnabled = (state: RootState) => {
  const { metadata: { autoLogout = false } = {} } = getRecipient(state);

  return autoLogout;
};

export const editPaymentDetailsEnabled = (state: RootState) => {
  const { metadata: { disableEditPaymentDetails = false } = {} } =
    getRecipient(state);

  return !disableEditPaymentDetails;
};

export const getReturnUrl = (state: RootState) => {
  const { metadata: { returnUrl = {} } = {} } = getRecipient(state);

  return returnUrl;
};

export const isRecipientActive = (state: RootState) => {
  const { metadata: { active = true } = {} } = getRecipient(state);

  return active;
};

export const getRecipientCountry = (state: RootState) => {
  const NO_COUNTRY = {};
  const {
    entities: {
      countries: { byId = {} } = {},
      recipient: { country } = {},
    } = {},
  } = state;

  return byId[country || ''] || NO_COUNTRY;
};

export const getRecipientCountries = (state: RootState) => {
  const {
    entities: {
      recipient: { countries = [] },
    },
  } = state;
  const {
    entities: {
      countries: { byId },
    },
  } = state;

  return countries.map(id => byId[id]);
};

export const getRecipientCountriesForSelect = (state: RootState) => {
  const countries = getRecipientCountries(state);

  const countriesForSelect = countries.map(({ name: label, code: value }) => ({
    label,
    value,
  }));

  return sortCountriesByLabel(countriesForSelect);
};

export const isCAP = (state: RootState) => {
  const recipient = getRecipient(state);
  return isRecipientCAP(recipient);
};

export const isRecipientCAP = ({ id }: Recipient | SelectedRecipient) => {
  return id === RECIPIENT_CAP;
};

export const isRedirectRecipient = (state: RootState) => {
  const recipient = getRecipient(state);
  const { id } = recipient;

  return REDIRECT_RECIPIENTS.includes(id);
};

export const getPaymentGroup = (state: RootState) => {
  const { metadata: { paymentGroup } = {} } = getRecipient(state);

  return paymentGroup;
};

export const isBusinessRecipient = (state: RootState) => {
  const paymentGroup = getPaymentGroup(state);

  return paymentGroup === 'BIZ';
};

export const isEducationClient = (state: RootState) => {
  const paymentGroup = getPaymentGroup(state);
  const educationPaymentGroups = ['ITP', 'TTP', 'ESL'];

  return paymentGroup && educationPaymentGroups.includes(paymentGroup);
};

export const getDefaultAmountForPayers = (state: RootState) => {
  const recipient = getRecipient(state);
  const { metadata: { defaultAmountForPayers } = {} } = recipient;

  return defaultAmountForPayers;
};

const getMetadata = (state: RootState) => {
  const recipient = getRecipient(state);
  const { metadata = {} as RecipientMetadata } = recipient;

  return metadata;
};

export const canChangePaymentMethod = (state: RootState) => {
  const metadata = getMetadata(state);
  const { allowChangePaymentMethod = true } = metadata;

  return allowChangePaymentMethod;
};

export const getRecipientCurrency = (state: RootState) => {
  const recipient = getRecipient(state);
  const { currency } = recipient;

  return currency ? getCurrency(state, currency as string) : ({} as Currency);
};

export const localPaymentsAllowed = (state: RootState) => {
  const {
    entities: {
      recipient: { country = '', countries = [] },
    },
  } = state;

  return countries.includes(country);
};

export const isSingleItemEnabled = (state: RootState) => {
  const { onlyAllowToPayOneItem = false } = getMetadata(state);

  return onlyAllowToPayOneItem;
};

export const isRecurringRecipient = (state: RootState) => {
  const { lightRecurringEnabled = false } = getMetadata(state);

  return lightRecurringEnabled;
};

export const supportsOnlyRecurringOffers = (state: RootState) => {
  const { metadata } = getRecipient(state);

  return !!metadata?.onlyRecurring;
};

export const getRecipientCurrencyCode = (state: RootState) => {
  const {
    entities: { recipient: { currency } = {} },
  } = state;

  return currency;
};
