import apiV3 from 'services/apiV3';
import schemas from 'constants/schemas';
import { normalizeCountries } from 'services/countries/countries';
import { Country } from 'models/country';
import { normalizeResponse } from 'utils/normalize/normalize';
import { Recipient } from 'models/recipient';
import { Currency, Field, Item, Section } from 'models';

export const fetch = async ({ id, locale }: { id: string; locale: string }) => {
  const recipient = await apiV3.recipients.fetch({ id, locale });

  return normalizeRecipient(recipient);
};

export const fetchCountries = async ({
  id,
  locale,
}: {
  id: string;
  locale: string;
}) => {
  const countries = await apiV3.recipients.fetchCountries({
    recipientId: id,
    locale,
  });

  return normalizeRecipientCountries(countries);
};

export const normalizeRecipientCountries = (
  countries: Pick<Country, 'name' | 'code'>[],
) => normalizeCountries<Pick<Country, 'name' | 'code'>>(countries);

export const normalizeRecipient = (
  recipient: Recipient,
): {
  entities: {
    currencies: Record<string, Currency>;
    sections: Record<string, Section>;
    fields: Record<string, Field>;
    items: Record<string, Item>;
    recipients: Record<string, Recipient>;
  };
  result: string;
} => normalizeResponse(recipient, schemas.recipient);

export const normalizeRecipients = (
  recipients: Recipient[],
): {
  entities: {
    currencies: Record<string, Currency>;
    sections: Record<string, Section>;
    fields: Record<string, Field>;
    items: Record<string, Item>;
    recipients: Record<string, Recipient>;
  };
  result: string[];
} => normalizeResponse(recipients, schemas.arrayOfRecipients);
