import React from 'react';
import classNames from 'classnames';
import { ConnectedProps, connect } from 'react-redux';
import { setFieldValue } from 'actions';
import {
  getFieldValue,
  hasTermsError,
  isBusinessRecipient,
  isRecurringOfferSelected,
} from 'selectors';
import { PROMOTIONS_OPT_IN_FIELD, TERMS_ACCEPTED_FIELD } from 'constants/index';
import { TermsAndConditions } from 'components/TermsAndConditions/TermsAndConditions';
import { PromotionsOptIn } from 'components/PromotionsOptIn/PromotionsOptIn';
import type { RootState } from 'reducers/types';

import './SenderInformationAcceptGroup.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

function SenderInformationAcceptGroupComponent({
  areTermsAccepted,
  hasTermsError,
  isBusinessRecipient,
  isPromotionsOptedIn,
  isRecurring,
  setFieldValue,
}: PropsFromRedux) {
  function handleTermsChange(termsValue: boolean) {
    setFieldValue(TERMS_ACCEPTED_FIELD, termsValue);
  }

  function handlePromotionsOptInChange() {
    setFieldValue(PROMOTIONS_OPT_IN_FIELD, !isPromotionsOptedIn);
  }

  return (
    <div
      className={classNames({
        'has-terms-error': hasTermsError,
        AcceptGroup: !isBusinessRecipient,
      })}
    >
      {!isBusinessRecipient && (
        <PromotionsOptIn
          defaultChecked={isPromotionsOptedIn}
          onChange={handlePromotionsOptInChange}
        />
      )}
      <TermsAndConditions
        data-testid="termsAndConditions"
        defaultChecked={areTermsAccepted}
        onChange={handleTermsChange}
        isRecurring={isRecurring}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  areTermsAccepted: !!getFieldValue(state, TERMS_ACCEPTED_FIELD),
  hasTermsError: hasTermsError(state),
  isBusinessRecipient: isBusinessRecipient(state),
  isPromotionsOptedIn: !!getFieldValue(state, PROMOTIONS_OPT_IN_FIELD),
  isRecurring: isRecurringOfferSelected(state),
});

const mapDispatchToProps = {
  setFieldValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SenderInformationAcceptGroup = connector(
  SenderInformationAcceptGroupComponent,
);

export { SenderInformationAcceptGroup };
