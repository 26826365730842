import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';

interface ModalProps {
  allowClosing?: boolean;
  children: React.ReactNode;
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  size?: string;
}

const Modal = ({
  allowClosing = true,
  children,
  className = '',
  isOpen: defaultIsOpen = false,
  onClose = () => null,
  onOpen = () => null,
  size = 'small',
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const handleOnClose = useCallback(() => {
    onClose();
    setIsOpen(false);
  }, [onClose]);

  const handleOnOpen = useCallback(() => {
    onOpen();
    setIsOpen(true);
  }, [onOpen]);

  useEffect(() => {
    if ((defaultIsOpen && isOpen) || defaultIsOpen !== isOpen) {
      defaultIsOpen ? handleOnOpen() : handleOnClose();
    }
  }, [defaultIsOpen, isOpen, handleOnOpen, handleOnClose]);

  return (
    <Dialog
      className="Modal-container"
      open={isOpen}
      onClose={() => (allowClosing ? handleOnClose() : () => {})}
    >
      <div
        className={classNames(`Modal Modal--${size}`, className)}
        data-testid="Modal"
      >
        <Dialog.Panel className="Modal-dialog">
          {allowClosing && (
            <button
              className="Modal-closeButton"
              type="button"
              onClick={handleOnClose}
              aria-label="Close"
            >
              <span className="Icon Icon--close Icon--xs margin-0" />
            </button>
          )}
          <div className="Modal-content">{children}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export { Modal };
