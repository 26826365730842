import { getLocalCurrency, getCurrency, getFieldById } from '../../index';
import {
  getInstructionByName,
  getOrderOffer,
  getPaymentInstruction,
  getPaymentOffer,
  getPaymentOfferFromPayment,
  getSelectedOffer,
  isEmbedded,
} from 'selectors';
import {
  BPAY_PAYMENT_METHOD,
  INSTRUCTION_LRS,
  InstructionsType,
  LULU_EMAIL,
  MUTHOOT_EMAIL,
  QR_PAYMENT_METHOD,
  EMAIL_PAYMENT_METHOD,
  INDIAN_PARTNER_LULU,
  INDIAN_PARTNER_GSTIN_LULU,
  INDIAN_PARTNER_MUTHOOT,
  INDIAN_PARTNER_GSTIN_MUTHOOT,
} from 'constants/index';
import get from 'lodash/get';
import { InstallmentBuilder } from 'domain/recurringReceivables/builders';
import type { RootState } from 'reducers/types';
import type {
  Field,
  LegacyRecurringOffer,
  LegacyRecurringOfferWithEntities,
  Offer,
  OfferWithIds,
  Price,
} from 'models';

const NO_FIELDS: Field[] = [];

export const getOffer = (state: RootState, id: string) => {
  const offer = findOffer(state, id);

  if (!offer) return {} as Offer;

  return buildOfferWithEntities(state, offer);
};

export const getOfferName = (state: RootState, id: string) => {
  const offer = findOffer(state, id);

  return offer ? offer.name : '';
};

export const isLegacyRecurring = (
  offer: Offer,
): offer is LegacyRecurringOffer => {
  if ((offer as LegacyRecurringOffer).recurring) {
    return true;
  }
  return false;
};

export const isLegacyInstallment = (offer: Offer) => {
  return isLegacyRecurring(offer) && offer?.recurring?.type === 'fixed';
};

export const isLegacySubscription = (offer: Offer) => {
  if (!offer) return false;
  return isLegacyRecurring(offer) && offer?.recurring?.type === 'monthly';
};

export const getLocalOffers = (state: RootState) => {
  const offers = getSingleOffers(state);
  const { code: localCurrency } = getLocalCurrency(state);

  return offers.filter(({ price }) => {
    return price.currency === localCurrency;
  });
};

export const getForeignOffers = (state: RootState) => {
  const offers = getSingleOffers(state);
  const { code: localCurrency } = getLocalCurrency(state);

  return offers.filter(({ price }) => {
    return price.currency !== localCurrency;
  });
};

export const getLocalAndForeignSingleOffers = (state: RootState) => {
  return [...getLocalOffers(state), ...getForeignOffers(state)];
};

type LegacyRecurringOffersByInterval = Omit<
  LegacyRecurringOfferWithEntities,
  'recurring'
> & {
  type: 'fixed' | 'monthly';
  interval?: number;
};

export const getLegacyRecurringOffers = (state: RootState) => {
  const legacyRecurringOffers = getAllLegacyRecurringOffers(state);

  const splitByIntervals = (
    offer: LegacyRecurringOfferWithEntities,
  ): LegacyRecurringOffersByInterval | LegacyRecurringOffersByInterval[] => {
    const {
      id,
      recurring: { interval = [], type },
      price,
      ...restOffer
    } = offer;
    const { value, currency: currencyCode, ...restPrice } = price;

    if (isLegacySubscription(offer)) {
      return {
        ...restOffer,
        price,
        type,
        id,
      };
    }

    const currency = getCurrency(state, currencyCode);

    return interval.map(intvl => {
      const fee = new InstallmentBuilder({
        amount: value,
        interval: intvl,
        subunitToUnit: currency.subunitToUnit,
        unitsToRound: currency.unitsToRound,
      }).fee;

      return {
        ...restOffer,
        price: {
          value: fee,
          currency: currencyCode,
          ...restPrice,
        },
        type: 'fixed',
        interval: intvl,
        id: `${id}#${intvl}`,
      } as LegacyRecurringOffersByInterval;
    });
  };
  return ([] as LegacyRecurringOffersByInterval[]).concat(
    ...legacyRecurringOffers.map(splitByIntervals),
  );
};

export const isLulu = (state: RootState) => {
  const { value = '' } = getPaymentInstruction(state, INSTRUCTION_LRS) || {};
  return value.includes(LULU_EMAIL);
};

export const isMuthoot = (state: RootState) => {
  const { value = '' } = getPaymentInstruction(state, INSTRUCTION_LRS) || {};
  return value.includes(MUTHOOT_EMAIL);
};

const INDIAN_PARTNERS = ['lulu', 'muthoot', 'cashfree'];
const INDIAN_PARTNERS_DATA: Record<
  string,
  { indianPartner: string; indianPartnerGstin: string }
> = {
  lulu: {
    indianPartner: INDIAN_PARTNER_LULU,
    indianPartnerGstin: INDIAN_PARTNER_GSTIN_LULU,
  },
  muthoot: {
    indianPartner: INDIAN_PARTNER_MUTHOOT,
    indianPartnerGstin: INDIAN_PARTNER_GSTIN_MUTHOOT,
  },
  cashfree: {
    indianPartner: INDIAN_PARTNER_MUTHOOT,
    indianPartnerGstin: INDIAN_PARTNER_GSTIN_MUTHOOT,
  },
};

const NO_INDIAN_PARTNER_DATA = {};

export const indianPartnerData = (state: RootState) => {
  const { paymentOption } = getOrderOffer(state);

  if (!paymentOption) return NO_INDIAN_PARTNER_DATA;

  const paymentOptionLower = paymentOption.toLowerCase();
  const partner = INDIAN_PARTNERS.find(partner =>
    paymentOptionLower.includes(partner),
  );

  if (partner) return INDIAN_PARTNERS_DATA[partner];

  return NO_INDIAN_PARTNER_DATA;
};

export const isUsingDocCollectorLRS = (state: RootState) => {
  const { paymentOption } = getPaymentOfferFromPayment(state);

  if (!paymentOption) return false;

  return paymentOption.toUpperCase().includes('CASHFREE');
};

export const isLuluOfferSelected = (state: RootState) => {
  const { paymentOption } = getOrderOffer(state);

  if (!paymentOption) return false;

  return paymentOption.toUpperCase().includes('LULU');
};

export const isCashfreeOfferSelected = (state: RootState) => {
  const { paymentOption } = getOrderOffer(state);

  if (!paymentOption) return false;

  return paymentOption.toUpperCase().includes('CASHFREE');
};

export const isBankTransferOfferSelected = (state: RootState) => {
  const offer = getSelectedOffer(state);

  return offer?.paymentMethod === 'bank_transfer';
};

export const isDirectDebitOfferSelected = (state: RootState) => {
  const offer = getSelectedOffer(state);

  return offer?.paymentMethod === 'direct_debit';
};

export const isOppOfferSelected = (state: RootState) => {
  const paymentOffer = getPaymentOffer(state);
  return /opp/i.test(paymentOffer.paymentOption);
};

export const getRepeatPaymentOffer = (state: RootState) => {
  return get(state, 'entities.repeatPaymentOffer');
};

export const shouldShowEURateDisclaimer = (state: RootState) => {
  const {
    entities: {
      offers: { byId: offers, ids },
    },
  } = state;

  const hasMarkupRate = (id: string) => !!offers[id].markupRate;
  return ids.some(hasMarkupRate);
};

export function forceRedirectInNewWindow(state: RootState) {
  const paymentIsEmbedded = isEmbedded(state);
  const offer = getSelectedOffer(state);
  const redirectUrl = getInstructionByName(state, 'redirect_url') || {};
  const instructionsTypes =
    getInstructionByName(state, 'type')?.value.split(',') || [];

  if (!('value' in redirectUrl)) return false;

  if (paymentIsEmbedded && !instructionsTypes.includes('embedded')) return true;

  return (
    paymentIsEmbedded &&
    ((['astropay_br_boleto'].includes(offer?.paymentOption) &&
      offer?.paymentMethod === 'bank_transfer') ||
      (offer?.paymentOption === 'dlocal_cop_pse' &&
        offer?.paymentMethod === 'online'))
  );
}

export function getInstructionsType(state: RootState) {
  const { paymentMethodInternalName } = getPaymentOfferFromPayment(state);
  const redirectURL = getInstructionByName(state, 'redirect_url');
  const embeddedScript = getInstructionByName(state, 'embedded_script');
  const instructionsTypes =
    getInstructionByName(state, 'type')?.value.split(',') || [];

  if (QR_PAYMENT_METHOD.includes(paymentMethodInternalName))
    return InstructionsType.QR;

  if (EMAIL_PAYMENT_METHOD.includes(paymentMethodInternalName))
    return InstructionsType.EMAIL;

  if (BPAY_PAYMENT_METHOD.includes(paymentMethodInternalName))
    return InstructionsType.BPAY;

  if (redirectURL && !embeddedScript) return InstructionsType.REDIRECT;
  if (instructionsTypes.includes('script')) return InstructionsType.SCRIPT;

  return InstructionsType.EMPTY;
}

export const getSingleOffers = (state: RootState) => {
  const {
    entities: { offers: { ids } = {} },
  } = state;

  return ids?.map(id => getSingleOffer(state, id)) || [];
};

export const getRecurringOffers = (state: RootState) => {
  const {
    entities: { recurringOffers: { ids } = {} },
  } = state;

  return ids?.map(id => getRecurringOffer(state, id)) || [];
};

export const getRecurringOffer = (state: RootState, id: string) => {
  const {
    entities: {
      recurringOffers: { byId },
    },
  } = state;

  return buildOfferWithEntities(state, byId[id]);
};

export const findOffer = (state: RootState, id: string) => {
  const {
    entities: {
      offers: { byId: singleOffersById } = {},
      legacyRecurringOffers: { byId: recurringOffersById } = {},
    },
  } = state;

  return recurringOffersById?.[id]
    ? recurringOffersById[id]
    : singleOffersById?.[id];
};

export const findSingleOffer = (state: RootState, id: string) => {
  const {
    entities: { offers: { byId: singleOffersById } = {} },
  } = state;

  return singleOffersById?.[id];
};

export const findLegacyRecurringOffer = (state: RootState, id: string) => {
  const {
    entities: {
      legacyRecurringOffers: { byId: legacyRecurringOffersById } = {},
    },
  } = state;

  return legacyRecurringOffersById?.[id];
};

export const findRecurringOffer = (state: RootState, id: string) => {
  const {
    entities: { recurringOffers: { byId: recurringOffersById } = {} },
  } = state;

  return recurringOffersById?.[id];
};

const getFee = (offer: Offer, price: Price) => {
  const { currency } = price;
  const { fees: value } = offer;

  return { currency, value };
};

const getAllLegacyRecurringOffers = (state: RootState) => {
  const {
    entities: {
      legacyRecurringOffers: { ids },
    },
  } = state;

  return ids.map(id => getLegacyRecurringOffer(state, id));
};

const getSingleOffer = (state: RootState, id: string) => {
  const {
    entities: {
      offers: { byId },
    },
  } = state;

  return buildOfferWithEntities(state, byId[id]);
};

const getLegacyRecurringOffer = (state: RootState, id: string) => {
  const {
    entities: {
      legacyRecurringOffers: { byId },
    },
  } = state;

  return buildOfferWithEntities(
    state,
    byId[id],
  ) as LegacyRecurringOfferWithEntities;
};

const buildOfferWithEntities = (state: RootState, offer: OfferWithIds) => {
  const {
    entities: { prices },
  } = state;

  const price = prices[offer.price];
  const fee = getFee(offer, price);
  const fields = (offer.fields || NO_FIELDS).map(field =>
    getFieldById(state, field),
  );

  return { ...offer, price, fee, fields };
};

export const getRecurringOffersMetadata = (state: RootState) => {
  return state.entities.recurringOffers.metadata;
};
