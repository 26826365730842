import {
  FETCH_PAYMENT_REFUNDS_REQUEST,
  FETCH_PAYMENT_REFUNDS_SUCCESS,
  FETCH_PAYMENT_REFUNDS_FAILURE,
  FETCH_KYC_TOKEN_REQUEST,
  FETCH_KYC_TOKEN_SUCCESS,
  FETCH_KYC_TOKEN_FAILURE,
} from 'constants/refunds';
import { getKyc } from 'selectors/entities/refunds/refunds';
import { isFetching } from 'selectors/ui/ui';
import { fetchRecipients } from 'actions/index';
import errorNotifier from 'utils/errorNotifier';
import { normalize } from 'normalizr';
import schemas from 'constants/schemas';
import { HTTPStatuses, HttpError } from 'constants/http';
import type { PayexThunkAction } from 'store/configureStore';
import { isHttpErrorWithStatus } from 'utils/errors/errors';

export const fetchPaymentRefunds =
  (paymentId: string): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingRefunds = isFetching(state, 'refunds');

    if (isFetchingRefunds) return;

    dispatch({ type: FETCH_PAYMENT_REFUNDS_REQUEST });

    try {
      const response = await apiV3.payments.fetchPaymentRefunds({ paymentId });
      const { refunds = [] } = response;
      const uniqueRecipientIds = [
        ...new Set(
          refunds
            .map(refund => refund?.sender?.id)
            .filter(recipient => !!recipient),
        ),
      ].filter(Boolean);

      await dispatch(fetchRecipients(uniqueRecipientIds as string[]));
      return dispatch({
        type: FETCH_PAYMENT_REFUNDS_SUCCESS,
        payload: normalize(refunds, schemas.arrayOfRefunds),
      });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const _error = error as HttpError;
        const { status = '' } = _error as HttpError;

        if (
          status !== HTTPStatuses.NOT_FOUND &&
          status !== HTTPStatuses.BAD_REQUEST
        ) {
          _error.message = `${FETCH_PAYMENT_REFUNDS_FAILURE} ${_error.message}`;
          errorNotifier.notifyWithFingerprint(_error, [
            FETCH_PAYMENT_REFUNDS_FAILURE,
            `${_error.status}`,
          ]);
        }
      }
      return dispatch({ type: FETCH_PAYMENT_REFUNDS_FAILURE });
    }
  };

export const fetchKycToken =
  (kycReference: string): PayexThunkAction =>
  async (dispatch, _, { apiV3 }) => {
    dispatch({ type: FETCH_KYC_TOKEN_REQUEST });

    try {
      const { token } = await apiV3.kycs.fetchByReference(kycReference);
      return dispatch({ type: FETCH_KYC_TOKEN_SUCCESS, payload: token });
    } catch (e) {
      return dispatch({ type: FETCH_KYC_TOKEN_FAILURE });
    }
  };

export const fetchRefundsAndKycToken =
  (paymentId: string): PayexThunkAction =>
  async (dispatch, getState) => {
    await dispatch(fetchPaymentRefunds(paymentId));
    const state = getState();
    const kyc = getKyc(state);

    if (kyc && kyc.reference) {
      return dispatch(fetchKycToken(kyc.reference));
    }
  };
