import { decamelizeKeys } from 'humps';
import fetch from 'services/apiV3/fetch';
import { PAYEX_URL } from 'constants/index';
import { Payment } from 'models';
import {
  FetchByIdRequestParams,
  PaymentNotificationRequestParams,
  PaymentNotificationResponse,
} from './types';

export type RepeatPaymentPayload = {
  id: string;
  paymentToken: string;
  items: {
    id: string;
    amount: number;
  }[];
  fingerprint: string;
  locale: string;
};

export type ChangePaymentMethodPayload = {
  paymentId: string;
  paymentToken: string;
  orderId: string;
  orderToken: string;
  fingerprint: string;
  locale: string;
};

export type PaymentsService = {
  repeatPayment: (payload: RepeatPaymentPayload) => Promise<Payment>;
  changePaymentMethod: (
    payload: ChangePaymentMethodPayload,
  ) => Promise<Payment>;
  fetchById: (params: FetchByIdRequestParams) => Promise<Payment>;
  fetchNotification: (
    params: FetchByIdRequestParams,
  ) => Promise<PaymentNotificationResponse>;
  updateNotification: (
    payload: PaymentNotificationRequestParams,
  ) => Promise<PaymentNotificationResponse>;
};

export const payments: PaymentsService = {
  repeatPayment: ({ id, paymentToken, items, fingerprint, locale }) => {
    const path = `${PAYEX_URL}/${id}/repeat_payment`;
    const payload = { paymentToken, items, fingerprint, locale };
    const body = JSON.stringify(decamelizeKeys(payload));
    const options = { method: 'POST', body };
    return fetch(path, options);
  },
  changePaymentMethod: ({
    paymentId,
    paymentToken,
    orderId,
    orderToken,
    fingerprint,
    locale,
  }) => {
    const path = `${PAYEX_URL}/${paymentId}/change_payment_method`;
    const payload = {
      orderId,
      orderToken,
      paymentToken,
      fingerprint,
      locale,
    };
    const body = JSON.stringify(decamelizeKeys(payload));
    const options = { method: 'POST', body };

    return fetch(path, options);
  },
  fetchById: ({ id, locale }) => {
    const path = `${PAYEX_URL}/payments/${id}?locale=${locale}`;
    const options = {
      method: 'GET',
    };

    return fetch(path, options);
  },
  fetchNotification: ({ id, token }) => {
    const path = `${PAYEX_URL}/payments/${id}/notifications?token=${token}`;
    const options = {
      method: 'GET',
    };

    return fetch(path, options);
  },
  updateNotification: ({ id, active, number, token }) => {
    const path = `${PAYEX_URL}/payments/${id}/notifications?token=${token}`;
    const payload = { active, number };
    const body = JSON.stringify(payload);
    const options = { method: 'POST', body };

    return fetch(path, options);
  },
};
