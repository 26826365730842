import { useEffect } from 'react';
import { HOTJAR_APP_ID, HOTJAR_ENVS } from 'constants/index';

const DEFAULT_SCRIPT_ID = 'hotjar';

function Hotjar() {
  useEffect(() => {
    const getScript = (id: string | number) => `
    (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${id},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;

    const initialize = (id: string | number) => {
      if (!id) {
        throw new Error('You need to provide your Hotjar Tracking Code.');
      }

      if (!document) {
        return;
      }

      const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);

      if (hasScript) {
        return;
      }

      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.innerHTML = getScript(id);
      script.id = DEFAULT_SCRIPT_ID;
      script.async = true;

      document.body.appendChild(script);
    };

    const isHotjarEnabledEnv = HOTJAR_ENVS.includes(window.location.hostname);

    if (isHotjarEnabledEnv) {
      initialize(HOTJAR_APP_ID);
    }
  }, []);

  return null;
}

export { Hotjar };
