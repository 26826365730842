import { connect } from 'react-redux';
import {
  getRecipient,
  hasFooter,
  getNobookingCtaUrl,
  getNobookingCtaName,
  showAddressInFooter,
} from 'selectors';
import { trackNobookingCtaAction } from 'actions';
import { Footer } from 'components/Footer/Footer';
import type { RootState } from 'reducers/types';

const mapStateToProps = (appState: RootState) => {
  const { name, address, address2, state, city, zip } = getRecipient(appState);

  const footer = hasFooter(appState);
  const showAddress = showAddressInFooter(appState);

  return {
    recipient: {
      name,
      address,
      address2,
      state,
      city,
      zip,
      websiteUrl: getNobookingCtaUrl(appState),
      websiteHypertext: getNobookingCtaName(appState),
    },
    showAddress,
    footer,
  };
};

const mapDispatchToProps = {
  trackNobookingCta: trackNobookingCtaAction,
};

const FooterWithAddress = connect(mapStateToProps, mapDispatchToProps)(Footer);

export { FooterWithAddress };
