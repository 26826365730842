import { PayexThunkAction } from 'store/configureStore';
import { DEFAULT_LOCALE, SET_LOCALE } from '../../constants';
import { isValidLocale } from '../../utils/translations';
import { getFieldValue, isTrackingPage } from 'selectors';

export const setLocale =
  (locale: string): PayexThunkAction =>
  async (dispatch, getState, { userLanguage }) => {
    const state = getState();
    const isValid = isValidLocale(locale);

    const senderEmail = getFieldValue(state, 'sender_email');
    if (isTrackingPage(state)) {
      userLanguage.update(senderEmail as string, locale);
    }

    dispatch({
      type: SET_LOCALE,
      payload: { locale: isValid ? locale : DEFAULT_LOCALE },
    });
  };
