import { useMutation, UseMutationResult } from 'react-query';
import { cardOnFile } from 'services/cardOnFile/cardOnFile';
import type {
  ChargeAttrs,
  ChargeResponse,
} from 'services/cardOnFile/cardOnFile';
import { CARD_CHARGE_ERRORS } from 'constants/errors';

export type ChargeError = (typeof CARD_CHARGE_ERRORS)[number];
export type ChargeErrorResponse = Error & {
  status?: number;
  parsedBody: {
    status: ChargeError;
  };
};

const useCharge = (
  onChargeError: (error: ChargeError) => void,
): UseMutationResult<ChargeResponse, ChargeErrorResponse, ChargeAttrs> =>
  useMutation<ChargeResponse, ChargeErrorResponse, ChargeAttrs>(
    attrs => cardOnFile.charge(attrs),
    {
      onError: error => {
        onChargeError(error.parsedBody.status);
      },
    },
  );

export { useCharge };
