import React from 'react';
import { CardElement, PayerElement } from '@flywire/internal-react-elements';
import { Heading } from 'components/Heading/Heading';
import { Box } from 'components/Box/Box';
import { Button } from 'lib/flywire-tailwind/Button';
import { Spinner } from 'components/Spinner/Spinner';
import { useTranslations } from 'utils/translations/useTranslations';

import '@flywire/internal-react-elements/styles.css';
import './CardForm.scss';

const CardFormContent = ({
  isLoading,
  onSubmit,
}: {
  isLoading: boolean;
  onSubmit: () => void;
}) => {
  const i18n = useTranslations();

  return (
    <div className="payexCardForm">
      <div className="payexCardForm-content">
        <Heading as="h2" size="large" className="fontWeight-semiBold">
          {i18n.t('cardForm.title')}
        </Heading>
        <Box>
          {isLoading ? (
            <Spinner
              className="payexCardForm-spinner"
              data-testid="cardFormSpinner"
            />
          ) : (
            <div className="payexCardForm-fields">
              <PayerElement className="payexCardForm-payer">
                <PayerElement.FirstName className="payexCardForm-firstName" />
                <PayerElement.LastName />
              </PayerElement>
              <CardElement.CardNumber className="payexCardForm-cardNumber" />
              <div className="payexCardForm-cardDetails">
                <CardElement.ExpiryDate />
                <CardElement.CVV />
              </div>
              <Button
                tone="primary"
                size="full"
                onClick={onSubmit}
                data-testid="cardFormSubmitBtn"
              >
                {i18n.t('cardForm.pay')}
              </Button>
            </div>
          )}
        </Box>
        <div className="payexCardForm-disclaimer" role="alert">
          {i18n.t('cardForm.disclaimer')}
        </div>
      </div>
    </div>
  );
};

export { CardFormContent };
