import React from 'react';
import { useWizard } from 'components/Wizard/components/context';
import { ConnectedProps, connect } from 'react-redux';
import {
  getCurrency,
  getLegacyRecurringOffers,
  isLoggedIn,
  isReadOnly,
  isFetching,
} from 'selectors';
import {
  clearOfferFields,
  fetchOfferFromOrder,
  fetchPaymentsRecipient,
  fetchUserPayments,
  setFieldsValues,
  trackCheckMarkup,
  trackPaymentOption,
  updateOrderWithEntities,
} from 'actions';
import { LegacyRecurringPaymentOptions as LegacyRecurringOptions } from 'components/PaymentOptions/components/LegacyRecurringPaymentOptions/LegacyRecurringPaymentOptions';
import { Nothing } from 'components/Nothing/Nothing';
import { SpinnerOverlay } from 'components/SpinnerOverlay/SpinnerOverlay';
import { IS_LEGACY_RECURRING_FIELD } from 'constants/fields';
import type { RootState } from 'reducers/types';

const OFFER_FIELD = 'offer';
const INTERVAL_FIELD = 'interval';
const OFFERS_ENTITY = 'offers';

type LegacyRecurringPaymentOptionsProps = ConnectedProps<typeof connector>;

function LegacyRecurringPaymentOptionsContainerComponent({
  clearOfferFields,
  fetchOffer,
  fetchPaymentsRecipient,
  fetchUserPayments,
  getCurrency,
  isFetchingOffers = false,
  isFetchingOrder = false,
  isLoggedIn,
  options = [],
  readonly,
  setFieldsValues,
  trackCheckMarkup,
  trackPaymentOption,
  updateOrderWithEntities,
}: LegacyRecurringPaymentOptionsProps) {
  const [alreadySelected, setAlreadySelected] = React.useState(false);
  const [selectingOffer, setSelectingOffer] = React.useState(false);
  const { goToNextStep } = useWizard();

  async function selectOption(idWithInterval: string) {
    if (readonly || alreadySelected) {
      return;
    }

    const [id, interval] = idWithInterval.split('#');

    setSelectingOffer(true);
    setAlreadySelected(true);
    await clearOfferFields();

    await Promise.all([
      setFieldsValues({
        [OFFER_FIELD]: id,
        ...(interval && { [INTERVAL_FIELD]: interval }),
        [IS_LEGACY_RECURRING_FIELD]: true,
      }),
      fetchOffer(id),
    ]);

    setSelectingOffer(false);
    updateOrderWithEntities(OFFERS_ENTITY).then(goToNextStep);
  }

  async function onUserLogIn() {
    await fetchUserPayments();
    await fetchPaymentsRecipient();
  }

  return (
    <>
      {isFetchingOffers || isFetchingOrder || selectingOffer ? (
        <SpinnerOverlay />
      ) : (
        <Nothing />
      )}
      {isFetchingOffers ? (
        <Nothing />
      ) : (
        <LegacyRecurringOptions
          getCurrency={getCurrency}
          isLoggedIn={isLoggedIn}
          onUserLogIn={onUserLogIn}
          onOptionSelect={selectOption}
          options={options}
          readonly={readonly}
          trackCheckMarkup={trackCheckMarkup}
          trackPaymentOption={trackPaymentOption}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  getCurrency: (code: string) => getCurrency(state, code),
  isFetchingOffers: isFetching(state, 'offers'),
  isFetchingOrder: isFetching(state, 'order'),
  isLoggedIn: isLoggedIn(state),
  options: getLegacyRecurringOffers(state),
  readonly: isReadOnly(state, 'offer'),
});

const mapDispatchToProps = {
  clearOfferFields,
  fetchOffer: fetchOfferFromOrder,
  fetchPaymentsRecipient,
  fetchUserPayments,
  setFieldsValues,
  trackCheckMarkup,
  trackPaymentOption,
  updateOrderWithEntities,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LegacyRecurringPaymentOptionsContainer = connector(
  LegacyRecurringPaymentOptionsContainerComponent,
);

export { LegacyRecurringPaymentOptionsContainer };
