import { HEAP_APP_ID, HEAP_ENVS } from '../../constants';
import { Heap } from './Heap/Heap';

const isHeapEnabledEnv = HEAP_ENVS.includes(window.location.hostname);

const AnalyticsHeap = () => {
  if (isHeapEnabledEnv) {
    Heap.initialize(HEAP_APP_ID);
  }

  return null;
};

export { AnalyticsHeap };
