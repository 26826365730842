import { ReactNode } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { isCAP } from 'selectors';
import type { RootState } from 'reducers/types';

type PropsFromRedux = ConnectedProps<typeof connector>;

type IsNotCAPComponentProps = PropsFromRedux & {
  children: ReactNode;
};

const IsNotCAPComponent = ({
  children = null,
  isCAP = false,
}: IsNotCAPComponentProps) => (!isCAP ? children : null);

const mapStateToProps = (state: RootState) => ({
  isCAP: isCAP(state),
});

const connector = connect(mapStateToProps);
const IsNotCAP = connector(IsNotCAPComponent);

export { IsNotCAP };
