import React, { useContext } from 'react';

export type WizardContextType = {
  activeStepIndex: number;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  goToStep: (index: number) => void;
  totalSteps: number;
};

const defaultContext: WizardContextType = {
  activeStepIndex: 0,
  goToNextStep: () => {},
  goToPrevStep: () => {},
  goToStep: () => {},
  totalSteps: 0,
};

const WizardContext = React.createContext<WizardContextType>(defaultContext);

function useWizard() {
  const context = useContext(WizardContext);

  if (context === undefined) {
    throw new Error('useWizard must be used withinh a WizardProvider');
  }

  return context;
}

export { WizardContext, useWizard };
