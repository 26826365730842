import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTranslations } from 'utils';
import { getOrder, getOrderCallToAction } from 'selectors/entities/order/order';
import { RedirectCountdown } from 'components/PaymentActions/Redirector/RedirectCountdown/RedirectCountdown';
import { Box } from 'components/Box/Box';
import type { RootState } from 'reducers/types';

import './Success.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

const SuccessComponent = ({ order, returnCTA }: PropsFromRedux) => {
  const i18n = useTranslations();

  const { id, token } = order;
  const hasReturnCTAData = 'url' in returnCTA;

  const hasToRedirectToCTA = hasReturnCTAData;

  if (hasToRedirectToCTA) {
    return (
      <Box>
        <RedirectCountdown />
      </Box>
    );
  }

  if (id && token) {
    return <Navigate to={`/tracking/${id}?token=${token}`} />;
  }

  return (
    <div className="CardOnFile-Success" data-testid="success">
      <h1>{i18n.t('checkout.cardOnFile.success.title')}</h1>
      <p>{i18n.t('checkout.cardOnFile.success.description')}</p>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  order: getOrder(state),
  returnCTA: getOrderCallToAction(state),
});

const connector = connect(mapStateToProps);
const Success = connector(SuccessComponent);

export { Success };
