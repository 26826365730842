import { useMutation } from 'react-query';
import { CheckoutSession } from 'models';
import { CreateSessionParams, sessions } from 'services/sessions/sessions';

type Params = {
  onSuccess: (session: CheckoutSession) => void;
  onMutate?: () => void;
  onError?: () => void;
  onSettled?: () => void;
};

const useCreateCheckoutSessions = (options: Params) => {
  const { mutate: createCheckoutSession } = useMutation(
    [`checkoutCreateSession`],
    async (params: CreateSessionParams) => sessions.create(params),
    options,
  );

  return {
    createCheckoutSession,
  };
};

export { useCreateCheckoutSessions };
