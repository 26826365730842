import { connect } from 'react-redux';
import { getCurrency } from 'selectors';
import { formatAmount } from 'utils/money';
import { Currency } from 'models';
import { Round } from './Round/Round';
import type { RootState } from 'reducers/types';

export type FormattedAmountProps = {
  getCurrency: (code: string) => Currency;
  currency: string;
  render?: (formattedAmount: { formattedAmount: string }) => React.ReactNode;
  value: number;
};

const FormattedAmountComponent = ({
  getCurrency,
  currency,
  render,
  value,
}: FormattedAmountProps) => {
  const currencyObject = getCurrency(currency);
  if (!currencyObject) return null;
  const formattedAmount = formatAmount(value, currencyObject);

  return render && typeof render === 'function'
    ? render({ formattedAmount })
    : formattedAmount;
};

FormattedAmountComponent.Round = Round;

const mapStateToProps = (state: RootState) => ({
  getCurrency: (code: string) => getCurrency(state, code),
});

const FormattedAmount = connect(mapStateToProps)(FormattedAmountComponent);

export { FormattedAmount };
