import React, { useEffect } from 'react';
import {
  useCardTokenizationContext,
  useCardTokenizationInstructions,
  useCardTokenizationEvent,
} from 'hooks';
import {
  Instructions,
  TokenizationFormError,
} from 'services/tokenization/models/models';
import { useTranslations } from '../../utils';
import { Spinner } from 'components/Spinner/Spinner';
import classNames from 'classnames';

import './CardTokenizationForm.scss';

type CardTokenizationFormProps = {
  showDisclaimer?: boolean;
};

function CardTokenizationForm({
  showDisclaimer = true,
}: CardTokenizationFormProps) {
  const {
    instructions,
    instructionsAttributes,
    isLoading,
    onError,
    onSuccess,
    setError,
    setInstructions,
    setIsLoading,
    setTokenizationResult,
  } = useCardTokenizationContext();

  const { tokenizationResult } = useCardTokenizationEvent();
  const { mutate: createInstructions } = useCardTokenizationInstructions({
    onSuccess: setInstructions,
    onError: () => {
      setIsLoading(false);
      setError(new TokenizationFormError({ status: 'failed' }));
      onError();
    },
  });
  const i18n = useTranslations();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setInstructions({} as Instructions);
    createInstructions(instructionsAttributes);
  }, [instructionsAttributes, createInstructions, setIsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!tokenizationResult) {
      return;
    }
    setIsLoading(false);
    if (tokenizationResult.cardDetails.isSuccess) {
      setTokenizationResult(tokenizationResult);
      onSuccess(tokenizationResult);
      return;
    }

    setError(new TokenizationFormError({ status: 'failed' }));
    onError();
  }, [
    onError,
    onSuccess,
    setError,
    setIsLoading,
    setTokenizationResult,
    tokenizationResult,
  ]);

  const shouldShowDisclaimer = () => !isLoading && showDisclaimer;

  return (
    <div className="CardTokenizationForm">
      {isLoading && <Spinner className="CardTokenizationForm-spinner" />}
      <div
        className={classNames('CardTokenizationForm-instructions', {
          'CardTokenizationForm-hidden': isLoading,
        })}
      >
        {instructions?.hostedFormUrl && (
          <iframe
            title="FormInstructions"
            src={instructions.hostedFormUrl}
            data-testid="CardTokenization-instructions"
            onLoad={() => {
              setIsLoading(false);
            }}
          />
        )}
      </div>
      {shouldShowDisclaimer() && (
        <div
          className="CardTokenizationForm-disclaimer"
          data-testid="CardTokenizationForm-disclaimer"
          role="alert"
        >
          {i18n.t('gbp.card.instructions.disclaimer')}
        </div>
      )}
    </div>
  );
}

export { CardTokenizationForm };
