import apiV3 from 'services/apiV3';
import schemas from 'constants/schemas';
import { normalizeResponse } from 'utils/normalize/normalize';
import { Currency } from 'models/currency';
import { Price } from 'models/price';
import { Purchase } from 'models/purchase';
import { Fee } from 'models/fee';
import { Instruction } from 'models/instruction';
import { Link } from 'models/link';
import { ProofResponseBody } from 'services/apiV3/payments/types';
import type { File, Proof } from 'models';
import { Payment } from 'models/payment';

export type CreatePaymentPayload = {
  callback?: (payment: Payment) => unknown;
  orderId: string;
  token: string;
  locale: string;
  hideUserManagement: boolean;
  recurring?:
    | {
        type?: 'fixed' | 'monthly' | 'subscription';
        interval?: number;
      }
    | boolean;
  captchaResponse: string;
  platform: string;
  recipient: string;
  whatsappNotification?: boolean;
};

export const payments = {
  create: async ({
    orderId,
    token,
    locale,
    hideUserManagement = false,
    callback,
    recurring,
    captchaResponse,
    platform,
    recipient,
    whatsappNotification,
  }: CreatePaymentPayload) => {
    const params = {
      orderId,
      token,
      locale,
      hideUserManagement,
      recurring,
      captchaResponse,
      platform,
      recipient,
      whatsappNotification,
    };
    const payment = await apiV3.payments.create(params);

    if (callback) callback(payment);

    return normalizePayment(payment);
  },
};

export const normalizePayment = (
  payment: Payment,
): {
  entities: {
    currencies: Record<string, Currency>;
    prices: Record<string, Price>;
    purchases: Record<string, Purchase>;
    fees: Record<string, Fee>;
    instructions: Record<string, Instruction>;
    links: Record<string, Link>;
    payments: Record<string, Payment>;
  };
  result: string;
} => normalizeResponse(payment, schemas.payment);

export const normalizeProofs = (
  proof: ProofResponseBody,
): {
  entities?: {
    proofs: Record<string, Proof>;
    proofFiles: Record<string, File>;
  };
  result?: string[];
} => {
  return proof.length
    ? normalizeResponse<{
        proofs: Record<string, Proof>;
        proofFiles: Record<string, File>;
      }>(proof, schemas.arrayOfProofs)
    : {};
};

export const normalizeErrors = (
  errors: { source?: string; param?: string }[],
): {
  entities?: {
    errors: Record<string, Error>;
  };
  result?: string[];
} => (errors.length ? normalizeResponse(errors, schemas.arrayOfErrors) : {});
