import React from 'react';
import PropTypes from 'prop-types';
import date from 'utils/date';
import { I18n } from 'utils';

const SubscriptionBreakdown = ({ i18n }: { i18n: I18n }) => (
  <div
    id="payer_information-description"
    className="FlexGrid FlexGrid--fromXSmall-2"
  >
    <div>
      <dt>{i18n.t('paymentInformationBreakdown.subscription.interval')}</dt>
      <dd data-hj-suppress data-testid="interval">
        {i18n.t('paymentInformationBreakdown.subscription.monthly')}
      </dd>
    </div>

    <div>
      <dt>
        {i18n.t('paymentInformationBreakdown.subscription.nextPaymentDate')}
      </dt>
      <dd data-hj-suppress data-testid="subscription-next-payment">
        {date.future(1, 'M', 'L')}
      </dd>
    </div>
  </div>
);

SubscriptionBreakdown.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }),
};

export { SubscriptionBreakdown };
