import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Navbar } from 'components/Navbar/Navbar';
import { LanguageSelector } from 'components/LanguageSelector/LanguageSelector';
import { MyAccountMenu } from 'components/MyAccountMenu/MyAccountMenu';
import { Login } from 'components/Login/Login';
import { Ribbon } from 'components/Ribbon/Ribbon';
import { Help } from 'components/Help/Help';
import { NewPayment } from 'components/NewPayment/NewPayment';
import { Logo } from 'containers/Logo/Logo';
import { getRecipient, isEmbedded, shouldHideUserManagement } from 'selectors';
import { SkipToContent } from 'components/SkipToContent/SkipToContent';
import type { RootState } from 'reducers/types';

import './Header.scss';

type HeaderProps = ConnectedProps<typeof connector> & {
  onLoginSuccess?: () => void;
  showFlywireLogo?: boolean;
  showLoginMenu?: boolean;
  showNewPayment?: boolean;
};

const HeaderComponent = ({
  embedded = false,
  onLoginSuccess,
  recipient,
  shouldHideUserManagement = false,
  showFlywireLogo = false,
  showLoginMenu = true,
  showNewPayment = false,
}: HeaderProps) => {
  if (embedded) return null;

  const { logoUrl, name } = recipient;
  const renderLoginMenu = !shouldHideUserManagement && showLoginMenu;

  return (
    <header className="Header" data-testid="header">
      <div className="Header-content">
        <SkipToContent contentId="content" />
        <div className="Header-logo">
          {showFlywireLogo && <Logo />}
          {logoUrl && !showFlywireLogo && (
            <img
              alt={name}
              className="Client-logo"
              src={logoUrl}
              data-testid="recipientLogo"
            />
          )}
        </div>
        <div className="Header-menu">
          {showNewPayment && (
            <NewPayment
              tone="secondary"
              className="hidden w-16 min-w-28 px-2.5 py-2 text-xs md:block"
            />
          )}
          <Navbar>
            <Help />
            {renderLoginMenu && (
              <Login onLoginSuccess={onLoginSuccess} data-testid="loginMenu" />
            )}
            {!shouldHideUserManagement && <MyAccountMenu />}
            <LanguageSelector />
          </Navbar>
        </div>
      </div>
      <Ribbon />
    </header>
  );
};

const mapStateToProps = (state: RootState) => ({
  embedded: isEmbedded(state),
  recipient: getRecipient(state),
  shouldHideUserManagement: shouldHideUserManagement(state),
});

const connector = connect(mapStateToProps);
const Header = connector(HeaderComponent);

export { Header };
