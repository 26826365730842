import isEmpty from 'lodash/isEmpty';
import { Field } from 'models/field';

const constraints = {
  generate({
    fieldOrFields = [],
    invalidMsg = 'is invalid',
    requiredMsg = 'is required',
  }: {
    fieldOrFields: Field | Field[];
    invalidMsg: string;
    requiredMsg: string;
  }) {
    const fields = Array.isArray(fieldOrFields)
      ? fieldOrFields
      : new Array(fieldOrFields);

    return fields.reduce((constraints, field) => {
      const { format, id, pattern, patternMessage, required, type } = field;
      const nullValidator = {};
      const invalidMessage = `^${
        isEmpty(patternMessage) ? invalidMsg : patternMessage
      }`;

      const patternValidator = {
        customFormat: {
          pattern,
          message: invalidMessage,
        },
      };

      const presenceValidator = {
        presence: {
          message: `^${requiredMsg}`,
          allowEmpty: false,
        },
      };
      const dateValidator = {
        date: {
          message: invalidMessage,
        },
      };
      const checkboxValidator = {
        presence: {
          allowEmpty: false,
          message: `^${requiredMsg}`,
        },
        inclusion: {
          within: [true],
          message: `^${requiredMsg}`,
        },
      };

      const fieldConstraints = {
        ...(pattern ? patternValidator : nullValidator),
        ...(required ? presenceValidator : nullValidator),
        ...(format === 'date' ? dateValidator : nullValidator),
        ...(type === 'boolean' && required ? checkboxValidator : nullValidator),
      };

      return { ...constraints, [id]: fieldConstraints };
    }, {});
  },
};

export { constraints };
