import { getRecipientName } from 'selectors';
import { heapAgent } from 'services/analytics/heapAgent/heapAgent';
import type { PayexThunkAction } from 'store/configureStore';

const initializeHeapAgent = () => () => {
  heapAgent.initialize();
};

const trackHeapSelectedPaymentOption =
  (): PayexThunkAction => (_, getState) => {
    const recipientName = getRecipientName(getState());
    const metadata = { recipient_name: recipientName };

    heapAgent.trackEvent('Selected payment option', metadata);
  };

const trackHeapEvent =
  (name: string, metadata: Record<string, unknown>): PayexThunkAction =>
  () => {
    heapAgent.trackEvent(name, metadata);
  };

export { initializeHeapAgent, trackHeapEvent, trackHeapSelectedPaymentOption };
