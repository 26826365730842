import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components/Spinner/Spinner';
import classNames from 'classnames';

import './SpinnerOverlay.scss';

const SpinnerOverlay = ({ message, children, className }) => {
  const spinnerOverlayClassName = classNames('SpinnerOverlay', {
    [className]: !!className,
  });

  return (
    <div className={spinnerOverlayClassName}>
      <Spinner />
      {message && <span className="SpinnerOverlay-message">{message}</span>}
      {children}
    </div>
  );
};

SpinnerOverlay.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  message: PropTypes.string,
};

export { SpinnerOverlay };
