export const ADD_ORDER_TO_USER_REQUEST = 'ADD_ORDER_TO_USER_REQUEST';
export const ADD_ORDER_TO_USER_SUCCESS = 'ADD_ORDER_TO_USER_SUCCESS';
export const ADD_ORDER_TO_USER_FAILURE = 'ADD_ORDER_TO_USER_FAILURE';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const EMPTY_ERROR_FIELDS = 'EMPTY_ERROR_FIELDS';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const FETCH_ORDER_OWNERSHIP_FAILURE = 'FETCH_ORDER_OWNERSHIP_FAILURE';
export const FETCH_ORDER_OWNERSHIP_REQUEST = 'FETCH_ORDER_OWNERSHIP_REQUEST';
export const FETCH_ORDER_OWNERSHIP_SUCCESS = 'FETCH_ORDER_OWNERSHIP_SUCCESS';
export const FETCH_ORDER_BY_TOKEN_REQUEST = 'FETCH_ORDER_BY_TOKEN_REQUEST';
export const FETCH_ORDER_BY_TOKEN_SUCCESS = 'FETCH_ORDER_BY_TOKEN_SUCCESS';
export const FETCH_ORDER_BY_TOKEN_FAILURE = 'FETCH_ORDER_BY_TOKEN_FAILURE';
export const UPDATE_ORDER_TEMPLATE = 'UPDATE_ORDER_TEMPLATE';
export const RESET_ORDER = 'RESET_ORDER';
export const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST';
export const ADD_COUPON_FAILURE = 'ADD_COUPON_FAILURE';
export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';
export const ADD_REDEEM_CASHBACK_REQUEST = 'ADD_REDEEM_CASHBACK_REQUEST';
export const ADD_REDEEM_CASHBACK_FAILURE = 'ADD_REDEEM_CASHBACK_FAILURE';
export const DELETE_REDEEM_CASHBACK_REQUEST = 'DELETE_REDEEM_CASHBACK_REQUEST';
export const DELETE_REDEEM_CASHBACK_FAILURE = 'DELETE_REDEEM_CASHBACK_FAILURE';
