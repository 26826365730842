import {
  CANCEL_PAYMENT_SUCCESS,
  CREATE_PAYMENT_SUCCESS,
  SELECT_PAYMENT,
  FETCH_PAYMENT_SUCCESS,
} from '../../../constants';
import type { Instruction } from 'models';

type InstructionAction =
  | { type: typeof CANCEL_PAYMENT_SUCCESS }
  | {
      type:
        | typeof CREATE_PAYMENT_SUCCESS
        | typeof FETCH_PAYMENT_SUCCESS
        | typeof SELECT_PAYMENT;
      payload: {
        entities: {
          instructions: Record<string, Instruction>;
        };
      };
    };
export default (
  state: Record<string, Instruction> = {},
  action: InstructionAction,
) => {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
    case FETCH_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
      const {
        entities: { instructions = {} },
      } = action.payload;

      return instructions;
    case CANCEL_PAYMENT_SUCCESS:
      return {};
    default:
      return state;
  }
};
