import 'whatwg-fetch';
import { TRACKER_URL, LOGGER_URL } from '../../constants';
import defaultStore from 'store/configureStore';
import {
  getCountryCode,
  getCurrentLocale,
  getEmail,
  getGatewaySource,
  getPaymentId,
  getRecipientId,
  getPlatform,
} from 'selectors';
import { cookies } from 'utils/cookies/cookies';
import { sessionStorage } from 'utils/storage';
import { PERMANENT_COOKIE_NAME, SESSION_ID_NAME } from 'constants/index';

export type Tracker = {
  configure: (store: typeof defaultStore) => void;
  trackEvent: (
    event: string,
    payload: Record<string, unknown>,
  ) => Promise<void | Response | undefined>;
  sendEvent: (
    data: Record<string, unknown>,
  ) => Promise<void | Response | undefined>;
  logCookiesDisabled: (data: Record<string, unknown>) => Promise<Response>;
};

let configuredStore: typeof defaultStore | null = null;

const tracker: Tracker = {
  configure: function (store: typeof defaultStore) {
    configuredStore = store;
  },

  trackEvent: (event, payload) => {
    const store = configuredStore || defaultStore;
    const state = store?.get()?.getState();

    const sourceUrl = window.location.href;
    const browserLanguage = getCurrentLocale(state);
    const country = getCountryCode(state);
    const recipient = getRecipientId(state);
    const userAccount = getEmail(state);
    const userAgent = navigator.userAgent;
    const referrer = document.referrer;
    const platform = getPlatform(state);
    const gatewaySource = getGatewaySource(state);
    const paymentid = getPaymentId(state) || null;

    return tracker.sendEvent({
      event,
      peer_tracker_id: cookies.read(PERMANENT_COOKIE_NAME),
      session_id: sessionStorage.getItem(SESSION_ID_NAME),
      source_url: sourceUrl,
      browser_language: browserLanguage,
      country,
      portal_code: recipient,
      user_account: userAccount,
      user_agent: userAgent,
      referrer,
      platform,
      gateway_source: gatewaySource,
      paymentid,
      ...payload,
    });
  },

  sendEvent: data => {
    return fetch(TRACKER_URL, getFetchOptions(data))
      .then(() => {
        if (!navigator.cookieEnabled) {
          return tracker.logCookiesDisabled(data);
        }
      })
      .catch(() => {});
  },

  logCookiesDisabled: data => {
    const payload = { ...data, cookies_enabled: false };
    return fetch(LOGGER_URL, getFetchOptions(payload));
  },
};

const getFetchOptions = (payload: Record<string, unknown>) => {
  const body = JSON.stringify(payload);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const method = 'POST';

  return { body, headers, method };
};

export default tracker;
