import { combineReducers } from 'redux';
import {
  CLOSE_PAYMENT_PROOF_MODAL,
  OPEN_PAYMENT_PROOF_MODAL,
  PAYMENT_PROOF_COMPLETED,
} from 'constants/index';

type PaymentProofAction = {
  type:
    | typeof CLOSE_PAYMENT_PROOF_MODAL
    | typeof OPEN_PAYMENT_PROOF_MODAL
    | typeof PAYMENT_PROOF_COMPLETED;
};

export const isVisible = (state = false, action: PaymentProofAction) => {
  switch (action.type) {
    case CLOSE_PAYMENT_PROOF_MODAL:
    case PAYMENT_PROOF_COMPLETED:
      return false;
    case OPEN_PAYMENT_PROOF_MODAL:
      return true;
    default:
      return state;
  }
};

export default combineReducers({ isVisible });
