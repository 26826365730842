import React, { useEffect } from 'react';
import { setFieldValue } from 'actions';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { PaymentItem } from 'models/item';
import { Currency } from 'models/currency';
import { connect } from 'react-redux';
import {
  getCurrentCurrency,
  getDefaultAmountForPayers,
  getItems,
  getTotalAmount,
} from 'selectors';
import { useTranslations } from 'utils';
import { MoneyInput } from 'components/FieldList/Field/MoneyInputField/MoneyInput';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import type { RootState } from 'reducers/types';

import './PaymentItems.scss';

const WAIT_TIME = 250;

type PropsFromRedux = {
  currency: Currency;
  items: PaymentItem[];
  totalAmount: number;
  recipientDefaultAmount?: number | null;
};

type PaymentItemsProps = {
  error?: string;
  hideTotalAmount?: boolean;
  hint?: React.ReactNode;
  onBlur?: (name: string, value: number) => void;
  onChange?: (name: string, value: number) => void;
  setFieldValue?: (
    id: string,
    value?: string | number | boolean | null,
  ) => void;
};

export const PaymentItemsComponent: React.FunctionComponent<
  PropsFromRedux & PaymentItemsProps
> = ({
  currency,
  items,
  totalAmount,
  recipientDefaultAmount,
  hideTotalAmount = false,
  error,
  hint,
  onBlur,
  onChange,
  setFieldValue,
}) => {
  const i18n = useTranslations();

  useEffect(() => {
    const firstItem = items[0];
    const firstItemValue = firstItem?.defaultValue || recipientDefaultAmount;

    if (recipientDefaultAmount) {
      setFieldValue?.(firstItem.id, firstItemValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getItemDefaultValue = (itemIndex: number, item: PaymentItem) => {
    const { defaultValue: itemDefaultValue } = item;

    if (itemIndex > 0) return itemDefaultValue;

    const defaultValue = itemDefaultValue || recipientDefaultAmount;
    return defaultValue && defaultValue.toString();
  };

  const commonProps = ({ id, readOnly, apiReadOnly, label }: PaymentItem) => ({
    currencySymbol: currency.symbol,
    decimalMark: currency.decimalMark,
    label: label,
    maxLength: 12,
    name: id,
    readOnly: readOnly || apiReadOnly,
    subunitToUnit: currency.subunitToUnit,
    symbolFirst: currency.symbolFirst,
    thousandsSeparator: currency.thousandsSeparator,
    onBlur: onBlur,
    onChange: onChange ? debounce(onChange, WAIT_TIME) : undefined,
  });

  if (items.length === 1) {
    const singlePaymentItem = items.map((item, index) => (
      <MoneyInput
        key={item.id}
        {...commonProps(item)}
        error={error}
        help={hint ? (hint as string) : undefined}
        aria-describedby="paymentInformation-amount-msg"
        value={getItemDefaultValue(index, item) as number}
        required
      />
    ));
    return <div>{singlePaymentItem}</div>;
  }

  const multiplePaymentItems = items.map((item, index) => {
    return (
      <MoneyInput
        aria-describedby="paymentInformation-amount-msg paymentItems-error-msg paymentItems-hint-msg"
        key={item.id}
        {...commonProps(item)}
        error={error ? '' : undefined}
        value={getItemDefaultValue(index, item) as number}
        required
      />
    );
  });

  return (
    <div className="PaymentItems">
      <p className="PaymentItems-hint">{i18n.t('paymentItems.hint')}</p>
      {multiplePaymentItems}
      {!hideTotalAmount && (
        <div
          className={classNames('PaymentItems-total', { 'has-error': error })}
          aria-labelledby="paymentItems-hint-msg"
          aria-describedby="paymentItems-error-msg"
          role="status"
        >
          <span className="PaymentItems-totalTitle">
            {i18n.t('paymentItems.total')}
          </span>
          <span className="PaymentItems-totalAmount">
            <FormattedAmount value={totalAmount} currency={currency.code} />
          </span>
        </div>
      )}
      <p
        className="PaymentItems-error"
        id="paymentItems-error-msg"
        aria-live="off"
      >
        {error && typeof error === 'string' ? error : null}
      </p>
      {!error && (
        <p className="FormGroup-hint" id="paymentItems-hint-msg">
          {hint}
        </p>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setFieldValue,
};

const mapStateToProps = (state: RootState) => ({
  currency: getCurrentCurrency(state),
  items: getItems(state),
  totalAmount: getTotalAmount(state),
  recipientDefaultAmount: getDefaultAmountForPayers(state),
});

const PaymentItems = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentItemsComponent);

export { PaymentItems };
