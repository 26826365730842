import React from 'react';

const Transfer = props => {
  return (
    <svg width="93" height="83" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <g
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        >
          <path d="M27.25 67.75H65.5M31.75 45.25v22.5M40.75 45.25v22.5M52 45.25v22.5M61 45.25v22.5M67.75 45.25H25l19.725-12.963a2.799 2.799 0 0 1 3.3 0L67.75 45.25Z" />
          <path d="M15.211 62.041C2.067 53.238-3.201 36.489 2.537 21.747 8.275 7.005 23.48-1.776 39.117.623c15.636 2.4 27.51 15.334 28.564 31.118" />
          <path d="M18.532 38.479a78.96 78.96 0 0 1 .696-17.784 48.81 48.81 0 0 1 6.639-19.458M.325 31.75H18.25M5.446 16h57.108M3.058 47.5H16M42.133 1.237a51.168 51.168 0 0 1 6.936 21.6" />
        </g>
        <circle fill="currentColor" opacity=".1" cx="51.5" cy="41.5" r="39.5" />
      </g>
    </svg>
  );
};

export default Transfer;
