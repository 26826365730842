import fetch from '../fetch';
import fetchJson from '../../../utils/fetchJson';
import { API_URL_V3 } from '../../../constants';
import type {
  CreateUserRequestBody,
  ExtendedMeResponse,
  UpdateUserRequestBody,
  User,
} from './types';

export const users = {
  create: ({ locale = 'EN', ...attributes }: CreateUserRequestBody) => {
    const path = `${API_URL_V3}/users?locale=${locale}`;
    const body = JSON.stringify(attributes);

    return fetchJson<User>(path, { method: 'POST', body });
  },

  update: ({ locale = 'EN', ...attributes }: UpdateUserRequestBody) => {
    const path = `${API_URL_V3}/users/me?locale=${locale}`;
    const body = JSON.stringify(attributes);

    return fetch<User>(path, { method: 'PATCH', body });
  },

  fetchUser: () => {
    const path = `${API_URL_V3}/users/me`;
    const options = {
      method: 'GET',
    };

    return fetch<User>(path, options);
  },

  fetchExtendedUser: () => {
    const path = `${API_URL_V3}/users/extended_me`;
    const options = {
      method: 'GET',
    };

    return fetch<ExtendedMeResponse>(path, options);
  },

  changePassword: ({
    password,
    resetPasswordToken,
  }: {
    password: string;
    resetPasswordToken: string;
  }) => {
    const path = `${API_URL_V3}/users/password`;
    const body = JSON.stringify({
      password: password,
      reset_password_token: resetPasswordToken,
    });

    const options = {
      method: 'PUT',
      body,
    };

    return fetch<void>(path, options);
  },

  userExists: ({ email, token = '' }: { email: string; token?: string }) => {
    const path = `${API_URL_V3}/users/email/${email}`;
    const options = {
      method: 'HEAD',
      headers: {
        'X-Proxy-Authorization': `Bearer ${token}`,
      },
    };

    return fetchJson<void>(path, options);
  },
};
