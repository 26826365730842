import { useQuery } from 'react-query';
import { featureFlags } from 'services/featureFlags/featureFlags';
import type { FeatureFlag } from 'constants/features';

const DEFAULT_REFETCH_INTERVAL = 30000;

type Options = {
  enabled?: boolean;
  refetchInterval?: number | false;
  refetchOnWindowFocus?: boolean;
};

const defaultOptions: Options = {
  enabled: true,
  refetchInterval: DEFAULT_REFETCH_INTERVAL,
  refetchOnWindowFocus: true,
};

export const usePeertransferFlags = (
  flags: FeatureFlag[] | FeatureFlag,
  options?: {
    enabled?: boolean;
    refetchInterval?: number | false;
    refetchOnWindowFocus?: boolean;
  },
) => {
  return useQuery(
    flags,
    (): Promise<Record<FeatureFlag, boolean>> => {
      return featureFlags.fetch(Array.isArray(flags) ? flags : [flags], false);
    },
    {
      ...(options ? { ...defaultOptions, ...options } : defaultOptions),
      notifyOnChangeProps: ['data', 'error'],
    },
  );
};
