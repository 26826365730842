export const UPDATE_SMS_NOTIFICATION_REQUEST =
  'UPDATE_SMS_NOTIFICATION_REQUEST';
export const UPDATE_SMS_NOTIFICATION_SUCCESS =
  'UPDATE_SMS_NOTIFICATION_SUCCESS';
export const UPDATE_SMS_NOTIFICATION_FAILURE =
  'UPDATE_SMS_NOTIFICATION_FAILURE';
export const RESET_RECEIVE_SMS_NOTIFICATIONS =
  'RESET_RECEIVE_SMS_NOTIFICATIONS';
export const ENABLE_SENDER_SMS_NOTIFICATION_REQUEST =
  'ENABLE_SENDER_SMS_NOTIFICATION_REQUEST';
export const ENABLE_SENDER_SMS_NOTIFICATION_SUCCESS =
  'ENABLE_SENDER_SMS_NOTIFICATION_SUCCESS';
export const ENABLE_SENDER_SMS_NOTIFICATION_FAILURE =
  'ENABLE_SENDER_SMS_NOTIFICATION_FAILURE';
