import React, { InputHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { useInputAriaProps } from 'hooks';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils/translations/useTranslations';

type CheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'label' | 'name'
> & {
  className?: string;
  error?: string | boolean;
  label?: string | ReactNode;
  name: string;
};

const Checkbox = ({
  className,
  id,
  name,
  error,
  label,
  required,
  disabled,
  readOnly,
  ...otherProps
}: CheckboxProps) => {
  const idOrName = id || name;
  const { inputAriaProps } = useInputAriaProps({
    disabled,
    error: getError(error),
    label: idOrName,
    name: idOrName,
    readOnly: disabled || readOnly,
    required,
  });

  const i18n = useTranslations();

  return (
    <label
      htmlFor={idOrName}
      className={classNames('Checkbox', className, {
        'has-error': error,
      })}
    >
      <input
        className="Checkbox-input"
        disabled={disabled || readOnly}
        id={idOrName}
        name={name}
        type="checkbox"
        {...inputAriaProps}
        {...otherProps}
      />
      <span className="Checkbox-label" id={`${idOrName}-label`}>
        {label}
      </span>
      {typeof error === 'string' ? (
        <div
          className="FormGroup-feedback"
          id={`${idOrName}-error`}
          aria-live="off"
          role="alert"
          data-testid={`${idOrName}-error-msg`}
        >
          <>
            <VisuallyHidden as="span">{label}</VisuallyHidden>
            {i18n.t(error)}
          </>
        </div>
      ) : null}
    </label>
  );
};

const getError = (error?: string | boolean) => {
  if (!error) return undefined;
  return typeof error === 'boolean' ? '' : error;
};

export { Checkbox };
