import React, { FunctionComponent } from 'react';
import { Field, FieldInterface } from './Field/Field';

type FieldListProps = {
  fields?: FieldInterface[];
};

const FieldList: FunctionComponent<FieldListProps> = ({ fields = [] }) => {
  const renderField = (field: FieldInterface) => (
    <Field field={field} key={field.id} />
  );

  const list = fields.map(renderField);

  return <div className="FlexGrid FlexGrid--fromMedium-2">{list}</div>;
};

export { FieldList };
