import React, {
  PropsWithChildren,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from 'react';
import {
  CardNewMandate,
  CardNewMandateError,
  CardNewMandateEvent,
  CardNewMandateRef,
} from '@flywire/internal-react-elements';
import classNames from 'classnames';
import { FLYWIRE_JS_ENVIRONMENT } from 'constants/config';
import type { CardPaymentMethod, CheckoutSession } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardInstructionsAttributes,
  CardTokenizationEvent,
} from 'services/tokenization/models/models';
import { useTranslations } from 'utils';
import { newNotification } from '../../actions';
import { useCreateCheckoutSessions } from 'hooks/useCheckoutSessions/useCreateCheckoutSession';
import { AddCardForm as AddCardWithCountryForm } from 'components/Profile/UserPaymentMethods/AddCardForm/AddCardForm';
import { CreditCardListItem } from './components/CreditCardListItem/CreditCardListItem';
import { CreditCardTokenization } from './components/CreditCardTokenization/CreditCardTokenization';
import { CreditCardsProvider, useCreditCards } from './hooks/useCreditCards';
import { Spinner } from 'components/Spinner/Spinner';
import logger from 'services/logger';
import errorNotifier from 'utils/errorNotifier';
import { getAccessToken } from 'selectors/entities/authentication/authentication';
import { Button } from 'lib/flywire-tailwind/Button';

import './CreditCards.scss';

type AddCardFormProps = {
  isModal?: boolean;
  onError?: () => void;
  onSuccess: (tokenizationResult: CardTokenizationEvent) => void;
} & (
  | {
      instructionsAttributes?: never;
      withCountrySelection: true;
      onCountryChange?: (country: string) => void;
    }
  | {
      instructionsAttributes: CardInstructionsAttributes;
      withCountrySelection?: never;
      onCountryChange?: never;
    }
);

const AddCardForm = ({
  isModal = false,
  instructionsAttributes,
  withCountrySelection,
  onCountryChange,
  onError = () => {
    // do nothing
  },
  onSuccess,
}: AddCardFormProps) => {
  const { showTokenizationForm, cards, setShowTokenizationForm } =
    useCreditCards();

  const onAddCardError = () => {
    isModal && setShowTokenizationForm(false);
    onError?.();
  };

  const onAddCardSuccess = (tokenizationResult: CardTokenizationEvent) => {
    setShowTokenizationForm(false);
    onSuccess?.(tokenizationResult);
  };

  return showTokenizationForm ? (
    withCountrySelection ? (
      <AddCardWithCountryForm
        onSuccess={onAddCardSuccess}
        onError={onAddCardError}
        onCountryChange={onCountryChange}
        modalContext={isModal}
      />
    ) : (
      <CreditCardTokenization
        className={classNames(
          !!cards.length && !isModal && 'CreditCardTokenization',
          isModal && 'CreditCardTokenization-Modal',
        )}
        instructionsAttributes={instructionsAttributes}
        onSuccess={onAddCardSuccess}
        onError={onError}
        showDisclaimer={false}
      />
    )
  ) : null;
};

type CreditCardListProps = {
  currency?: string;
  recipientId: string;
  selectedCard?: CardPaymentMethod;
  onEventCVV?: (event: CardNewMandateEvent) => void;
  onErrorCVV?: (error?: CardNewMandateError) => void;
  onSelectedCardChange: (card?: CardPaymentMethod) => void;
  onSuccessCVV: (
    data: { confirm: { url: string; method: string } },
    sessionId: string,
  ) => void;
};

const CreditCardList = ({
  currency,
  recipientId,
  selectedCard,
  onEventCVV,
  onErrorCVV,
  onSelectedCardChange,
  onSuccessCVV,
}: CreditCardListProps) => {
  const {
    cards,
    cvvRef,
    hasAddForm,
    isCVVLoading,
    isSubmitting,
    showTokenizationForm,
    setCvvEvent,
    setIsCVVLoading,
    setIsSubmitting,
    setShowTokenizationForm,
  } = useCreditCards();

  const dispatch = useDispatch();
  const i18n = useTranslations();
  const accessToken = useSelector(getAccessToken);
  const [sessionId, setSessionId] = useState('');

  const onSuccessCreateSession = (session: CheckoutSession) => {
    setSessionId(session.id);
  };

  const errorNotification = () => {
    dispatch(
      newNotification({
        message: 'notifications.error_card_selection_validation',
        type: 'error',
      }),
    );
  };

  const { createCheckoutSession } = useCreateCheckoutSessions({
    onSuccess: onSuccessCreateSession,
    onError: () => {
      onErrorCVV?.();
    },
  });

  const createNewSession = (paymentMethodToken: string) => {
    setIsCVVLoading(true);
    createCheckoutSession({
      accessToken,
      locale: i18n.currentLocale,
      paymentMethodToken,
      recipientId,
      sessionType: 'new_mandate',
    });
  };

  const _selectedCard = showTokenizationForm
    ? selectedCard
    : selectedCard || cards[0];

  useEffect(() => {
    if (_selectedCard) {
      createNewSession(_selectedCard.token);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!cards.length) return null;

  const onSuccessCVVValidation = (data: {
    confirm: { url: string; method: string };
  }) => {
    setIsSubmitting(false);
    onSuccessCVV(data, sessionId);
  };

  const onErrorCVVValidation = async (error: CardNewMandateError) => {
    setIsSubmitting(false);
    if (selectedCard) {
      if (error.type === 'internal_error') {
        errorNotifier.notify(error);
      }
    }
    onErrorCVV?.(error);
    error.type !== 'not_found' && errorNotification();
  };

  const onCardChange = (card?: CardPaymentMethod) => {
    onSelectedCardChange(card);
    if (card) {
      createNewSession(card.token);
    }
  };

  const handleSelectedCardChange = (card?: CardPaymentMethod) => {
    if (card) {
      onCardChange(card);
      setShowTokenizationForm(false);
    } else {
      setShowTokenizationForm(true);
      onCardChange();
    }
  };

  const onEvent = (event: CardNewMandateEvent) => {
    if (event.type === 'challenge_required') {
      setIsSubmitting(false);
    }
    setCvvEvent(event);
    onEventCVV?.(event);
    logger.log({
      description: 'CVV event launched',
      parameters: event,
    });
  };

  const showSpinner = isSubmitting || isCVVLoading;

  return (
    <div className="CreditCards-List">
      <CardNewMandate
        onStartLoading={() => setIsCVVLoading(true)}
        onEndLoading={() => setIsCVVLoading(false)}
        onSuccess={onSuccessCVVValidation}
        onError={onErrorCVVValidation}
        sessionId={sessionId}
        environment={FLYWIRE_JS_ENVIRONMENT}
        ref={cvvRef}
        onEvent={onEvent}
        currency={currency}
      >
        {showSpinner ? (
          <Spinner className="CreditCards-List-spinner" />
        ) : (
          <ul data-testid="creditCardList">
            {cards.map((card, index) => (
              <CreditCardListItem
                card={card}
                key={`${card.details.lastFour}-${index}`}
                isCvvLoading={isCVVLoading}
                isSelected={_selectedCard?.token === card.token}
                onSelect={() => handleSelectedCardChange(card)}
              />
            ))}
            {hasAddForm && (
              <CreditCardListItem
                key="addCard"
                onSelect={() => handleSelectedCardChange()}
                isSelected={showTokenizationForm}
              />
            )}
          </ul>
        )}
      </CardNewMandate>
    </div>
  );
};

const Disclaimer = () => {
  const i18n = useTranslations();
  return (
    <p data-testid="disclaimer" className="CreditCards-Disclaimer">
      {i18n.t('recurring.payment.action.pay.description')}
    </p>
  );
};

type PayButtonProps = {
  isLoading?: boolean;
  payButtonLabel: string;
};

const Actions = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { showTokenizationForm, cvvEvent } = useCreditCards();

  const showPayButton =
    !showTokenizationForm && cvvEvent?.type !== 'challenge_required';

  return showPayButton ? (
    <div className={classNames(className, 'CreditCards-Actions')}>
      {children}
    </div>
  ) : null;
};

const PayButton = ({ isLoading = false, payButtonLabel }: PayButtonProps) => {
  const { cvvRef, isCVVLoading, setIsSubmitting } = useCreditCards();
  const dispatch = useDispatch();

  const _onPay = () => {
    if (cvvRef.current) {
      setIsSubmitting(true);
      cvvRef.current.submit();
    } else {
      dispatch(
        newNotification({
          message: 'notifications.error_card_selection_validation',
          type: 'error',
        }),
      );
    }
  };

  return (
    <Button
      tone="primary"
      size="full"
      onClick={_onPay}
      disabled={isLoading || isCVVLoading}
    >
      {payButtonLabel}
    </Button>
  );
};

type CreditCardsProps = {
  cards?: CardPaymentMethod[];
  cvvRef?: RefObject<CardNewMandateRef>;
};

const CreditCards = ({
  children,
  ...props
}: PropsWithChildren<CreditCardsProps>) => {
  return <CreditCardsProvider {...props}>{children}</CreditCardsProvider>;
};

CreditCards.AddCardForm = AddCardForm;
CreditCards.CardList = CreditCardList;
CreditCards.Disclaimer = Disclaimer;
CreditCards.Actions = Actions;
CreditCards.PayButton = PayButton;

export { CreditCards };
