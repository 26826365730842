import React, { FunctionComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { setFieldValue, validateSectionField } from 'actions';
import {
  isCAP,
  isPaymentProcess,
  hasAmountToGenerateCashback,
  shouldShowCashback,
} from 'selectors';
import { AutocompleteField } from './AutocompleteField/AutocompleteField';
import { CheckboxField } from './CheckboxField/CheckboxField';
import { CountryField } from './CountryField/CountryField';
import { DatepickerField } from './DatepickerField/DatepickerField';
import { HiddenField } from './HiddenField/HiddenField';
import { MoneyInputField } from './MoneyInputField/MoneyInputField';
import { MultipleCheckboxField } from './MultipleCheckboxField/MultipleCheckboxField';
import { PhoneInputField } from './PhoneInputField/PhoneInputField';
import { SenderAddressInputField } from './SenderAdressInputField/SenderAddressInputField';
import { TextInputField } from './TextInputField/TextInputField';
import { SenderEmailInputField } from './SenderEmailInputField/SenderEmailInputField';
import { SenderPhoneInputField } from './SenderPhoneInputField/SenderPhoneInputField';
import {
  SENDER_COUNTRY_FIELD,
  SENDER_PHONE_FIELD,
  SENDER_EMAIL_FIELD,
  SENDER_ADDRESS_FIELD,
} from 'constants/index';
import { RootState } from 'reducers/types';
import logger from 'services/logger';
import { CashbackSenderEmailInputField } from 'components/CashBack/CashbackSenderEmailInputField/CashbackSenderEmailInputField';
import { CAPSenderEmailInputField } from 'components/CAP/CAPSenderEmailInputField/CAPSenderEmailInputField';

type FieldValueType = {
  label: string;
  value: string;
};

interface FieldInterface {
  autoComplete?: string;
  defaultValue: string;
  error?: string | boolean;
  format: string;
  help?: string;
  hidden: boolean;
  hint?: string;
  id: string;
  internalAlias: string;
  label: string;
  name: string;
  pattern?: string;
  patternMessage?: string;
  placeholder: string;
  position: number;
  readOnly?: boolean;
  required: boolean;
  section: string;
  type: string;
  value: string | Array<FieldValueType>;
}

type PropsFromRedux = {
  isPaymentProcess: boolean;
  setFieldValue: (name: string, value: number | string) => void;
  validateSectionField: (name: string) => void;
  isCap: boolean;
  shouldShowCashback: boolean;
  hasAmountToGenerateCashback: boolean;
};

type FieldProps = {
  field: FieldInterface;
} & PropsFromRedux;

const FieldComponent: FunctionComponent<FieldProps> = ({
  field,
  isPaymentProcess,
  setFieldValue,
  validateSectionField,
  isCap,
  shouldShowCashback,
  hasAmountToGenerateCashback,
}) => {
  const handleBlur = (name: string) => {
    return validateSectionField(name);
  };

  const handleChange = (name: string, value: string | number) => {
    setFieldValue(name, value);
  };

  const isSenderEmailField = (field: FieldInterface) => {
    return (
      field.type === 'string' &&
      field.id === SENDER_EMAIL_FIELD &&
      isPaymentProcess
    );
  };

  const isSenderAddressField = (field: FieldInterface) => {
    return field.id === SENDER_ADDRESS_FIELD;
  };

  let FieldComponent: ReactNode = null;

  if (field.hidden) {
    FieldComponent = HiddenField;
  } else if (field.type === 'boolean') {
    FieldComponent = CheckboxField;
  } else if (field.type === 'string' && Array.isArray(field.value)) {
    if (field.id === SENDER_COUNTRY_FIELD) return null;
    FieldComponent = AutocompleteField;
  } else if (field.type === 'array' && Array.isArray(field.value)) {
    FieldComponent = MultipleCheckboxField;
  } else if (isSenderEmailField(field)) {
    FieldComponent = SenderEmailInputField;
    if (isCap) {
      FieldComponent = CAPSenderEmailInputField;
    } else if (shouldShowCashback && hasAmountToGenerateCashback) {
      FieldComponent = CashbackSenderEmailInputField;
    }
  } else if (isSenderAddressField(field)) {
    FieldComponent = SenderAddressInputField;
  } else if (field.type === 'string' && field.format === 'money') {
    logger.log({
      description: 'Field with type string and format money',
      parameters: field,
    });
    FieldComponent = MoneyInputField;
  } else if (field.type === 'string' && field.format === 'date') {
    FieldComponent = DatepickerField;
  } else if (field.type === 'string' && field.format === 'phone') {
    FieldComponent = PhoneInputField;

    if (field.id === SENDER_PHONE_FIELD && isPaymentProcess)
      FieldComponent = SenderPhoneInputField;
  } else if (field.format === 'country') {
    FieldComponent = CountryField;
  } else if (field.type === 'string') {
    FieldComponent = TextInputField;
  }

  return FieldComponent !== null ? (
    <FieldComponent
      field={field}
      handleBlur={handleBlur}
      handleChange={handleChange}
    />
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  isPaymentProcess: isPaymentProcess(state),
  isCap: isCAP(state),
  shouldShowCashback: shouldShowCashback(state),
  hasAmountToGenerateCashback: hasAmountToGenerateCashback(state),
});

const mapDispatchToProps = {
  setFieldValue,
  validateSectionField,
};

const Field = connect(mapStateToProps, mapDispatchToProps)(FieldComponent);

export { Field, FieldInterface, FieldValueType };
