import date from 'utils/date';
import fetchJson from 'utils/fetchJson';
import store from 'store/configureStore';
import { isLoggedIn, getAccessToken, getExpiresIn } from 'selectors';
import { refreshToken, cleanUnauthorizedUserData } from 'actions';
import { HTTPStatuses } from 'constants/index';
import { RootState } from 'reducers/types';
import { FetchOptions } from 'utils/fetchJson/fetchJson';
import moment from 'moment';

const fetch = async <ReturnType = void>(url: string, options: FetchOptions) => {
  if (store && store.get()) {
    const state = store.get()?.getState();

    if (isLoggedIn(state)) {
      const authHeaders = await getAuthHeaders(state);
      options.headers = {
        ...(authHeaders.Authorization && authHeaders),
        ...(options.headers && options.headers),
      };
    }
  }

  return fetchJson<ReturnType>(url, options).catch(handleFetchError);
};

const handleFetchError = (error: { status?: number } = {}) => {
  const { status } = error;

  if (store && store.get()) {
    const dispatch = store.get()?.dispatch;

    if (status === HTTPStatuses.UNAUTHORIZED)
      // @ts-expect-error store/actions are not properly typed
      dispatch?.(cleanUnauthorizedUserData());
  }

  throw error;
};

const getAuthHeaders = async (state: RootState) => {
  try {
    const accessToken = isTokenExpired(state)
      ? await getNewToken()
      : getAccessToken(state);

    return { Authorization: `Bearer ${accessToken}` };
  } catch (e) {
    const dispatch = store.get()?.dispatch;
    // @ts-expect-error actions/store are not properly typed
    dispatch?.(cleanUnauthorizedUserData());

    return {};
  }
};

const isTokenExpired = (state: RootState) => {
  const expiresIn = getExpiresIn(state);
  if (!expiresIn) return false;

  const oneMinuteFromNow = date.future(
    1,
    date.MINUTES as moment.DurationInputArg2,
  );
  const expirationDate = date.fromUnix(expiresIn);

  return date.isBefore(expirationDate, oneMinuteFromNow);
};

const getNewToken = async () => {
  // @ts-expect-error actions/store are not properly typed
  const { payload } = await store.get()?.dispatch?.(refreshToken());
  const { accessToken } = payload;

  return accessToken;
};

export default fetch;
