import React from 'react';
import { connect } from 'react-redux';
import { getRecipientName } from 'selectors';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useCountdown } from 'hooks';
import { Success as SuccessIcon } from 'icons';
import { RedirectToReturnCTA } from '../RedirectToReturnCTA/RedirectToReturnCTA';
import {
  ONE_SECOND_IN_MILISECONDS,
  THREE_SECONDS_IN_MILISECONDS,
} from 'constants/timers';
import { useTranslations } from 'utils';
import type { RootState } from 'reducers/types';

import './RedirectCountdown.scss';

function RedirectCountdownComponent({
  recipientName,
}: {
  recipientName: string;
}) {
  const { timeLeft, isDone } = useCountdown(THREE_SECONDS_IN_MILISECONDS);

  const i18n = useTranslations();

  return (
    <>
      <div className="RedirectCountdown">
        <SuccessIcon className="RedirectCountdown-icon" />
        <h2 className="RedirectCountdown-title">
          {i18n.t('redirectCountdown.payment.completed')}
        </h2>
        <div className="RedirectCountdown-message">
          {i18n.t('redirectCountdown.redirectingMessage', {
            recipient: recipientName,
          })}
        </div>
        <span aria-hidden className="RedirectCountdown-status">{`${i18n.t(
          'redirectCountdown.countdown',
        )} ${timeLeft / ONE_SECOND_IN_MILISECONDS}...`}</span>
        <VisuallyHidden as="span" role="alert">
          {i18n.t('redirectCountdown.hiddenRedirectingMessage')}
        </VisuallyHidden>
      </div>
      {isDone && <RedirectToReturnCTA />}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  recipientName: getRecipientName(state),
});

const RedirectCountdown = connect(mapStateToProps)(RedirectCountdownComponent);

export { RedirectCountdown };
