import { combineReducers } from 'redux';
import {
  FETCH_EXTENDED_USER_FAILURE,
  FETCH_EXTENDED_USER_SUCCESS,
} from 'constants/index';

type ExtendedUserAction =
  | {
      type: typeof FETCH_EXTENDED_USER_FAILURE;
    }
  | {
      type: typeof FETCH_EXTENDED_USER_SUCCESS;
      payload: {
        fromAgent: boolean;
        recurrentPayer: boolean;
      };
    };

export const isFromAgent = (state = false, action: ExtendedUserAction) => {
  switch (action.type) {
    case FETCH_EXTENDED_USER_SUCCESS: {
      if (state) return state;

      try {
        return action.payload.fromAgent;
      } catch (err) {
        return state;
      }
    }
    case FETCH_EXTENDED_USER_FAILURE:
      return false;
    default: {
      return state;
    }
  }
};

export const isRecurrentPayer = (state = false, action: ExtendedUserAction) => {
  switch (action.type) {
    case FETCH_EXTENDED_USER_SUCCESS: {
      if (state) return state;

      try {
        return action.payload.recurrentPayer;
      } catch (err) {
        return state;
      }
    }
    case FETCH_EXTENDED_USER_FAILURE:
      return false;
    default: {
      return state;
    }
  }
};

export default combineReducers({ isFromAgent, isRecurrentPayer });
