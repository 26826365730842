export const keysEnum = {
  'nonFxPaymentInfo.title.question':
    'By choosing this option, you are missing several benefits. Are you sure you want to continue?',
  'nonFxPaymentInfo.title':
    'Benefits of using Indian Rupee (INR) payment options',
  'nonFxPaymentInfo.info1':
    'INR payment options are the most SECURE and FASTEST way to pay with Flywire',
  'nonFxPaymentInfo.info2':
    'Typical delivery within <b>1 business day*</b> vs 5 business days for USD options',
  'nonFxPaymentInfo.info3':
    'Full regulatory and tax compliance ensured by Flywire and its authorized partners',
  'nonFxPaymentInfo.info4': 'Faster processing of refunds',
  'nonFxPaymentInfo.info5':
    'Opportunity to participate in Flywire Rewards program',
  'nonFxPaymentInfo.button.INROption.label': 'Switch to INR payment option',
  'nonFxPaymentInfo.bottom.note':
    '*post receipt of funds and document verification',
  'nonFxPaymentInfo.bottom.button.continue.label':
    'Continue with USD payment option',
  'nonFxPaymentInfo.feedbackQuestions.button.label': 'Submit',
  'nonFxPaymentInfo.authentication.notification':
    'You need to log in or create an account to make a US Dollar payment',
  'nonFxPaymentInfo.feedbackQuestions.title':
    'Answer a few questions to proceed with this payment method.',
  'nonFxPaymentInfo.feedbackQuestions.description':
    'Providing incorrect information may lead to delays in the payment process.',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.placeholder':
    'Who (Individual or Entity) is creating this payment on behalf of the student?',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.student':
    'I am the Student myself',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.familyMember':
    'Parent or Family member',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.agent': 'Education Agent',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.dealer':
    'Authorised Dealer II',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.bank':
    'Public or Private Sector Bank',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.nonBanking':
    'Non Banking Financial Company (NBFC)',
  'nonFxPaymentInfo.feedbackQuestions.payerOptions.other':
    'Other loan providers',
  'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.placeholder':
    'Select the source of funds for this payment',
  'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.savings': 'Savings',
  'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.loanCredila':
    'Education Loan from Credila',
  'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.bankLoans':
    'Education Loan from Public and Private Sector Banks',
  'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.otherInstitutions':
    'Education Loan from other financial institutions',
  'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.savingsAndLoans':
    'Combination of savings and education loan',
  'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.placeholder':
    'I am interested in making a US dollar payment because:',
  'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.betterFXRate':
    'I am getting a better FX rate from:',
  'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.usdBankAccount':
    'I have a US dollar bank account',
  'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.anotherProvider':
    'I prefer to use another provider',
  'nonFxPaymentInfo.feedbackQuestions.dealerIIOptions.placeholder':
    'Please select the Authorised Dealer II',
  'nonFxPaymentInfo.feedbackQuestions.bankOptions.placeholder':
    'Please select your Public or Private Sector Bank',
  'nonFxPaymentInfo.feedbackQuestions.agentOptions.placeholder':
    'Enter the name of the Education Agent',
  'nonFxPaymentInfo.feedbackQuestions.nonBankingOptions.placeholder':
    'Please select Non Banking Financial Company',
  'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.answer.placeholder':
    'Enter the name of the provider',
};
