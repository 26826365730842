import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEmail, getFieldValue } from 'selectors';
import { PERMANENT_COOKIE_NAME } from 'constants/index';
import { cookies } from 'utils/cookies/cookies';
import url from 'utils/url';
import type { RootState } from 'reducers/types';

const PRODUCTION_KEY = '65960b63de';
const DEVELOPMENT_KEY = '437424ee7b';
const IMPERSONATE_DOMAIN_MATCHER =
  /https:\/\/(demo.)?operations.flywire.(lol|com)\//;

class SiftComponent extends PureComponent {
  static propTypes = {
    senderEmail: PropTypes.string,
    userEmail: PropTypes.string,
  };

  state = {
    isScriptInjected: false,
  };

  componentDidMount() {
    if (!this.isImpersonate()) {
      this.injectSnippet();
      this.setState({ isScriptInjected: true });
    }
  }

  componentDidUpdate() {
    if (this.isImpersonate()) {
      return;
    }

    const { userEmail, senderEmail } = this.props;

    const userId = userEmail || senderEmail;

    if (this.isScriptInjected()) window._sift.push(['_setUserId', userId]);
  }

  isImpersonate() {
    const referrer = document.referrer;
    return IMPERSONATE_DOMAIN_MATCHER.test(referrer);
  }

  getKey() {
    const origin = url.getCurrentUrl();
    const isDemo = /demo\./.test(origin);
    const isStaging = /flywire.(lol|cc)/.test(origin);
    const isEnvProduction =
      process.env.NODE_ENV === 'production' && !isDemo && !isStaging;

    return isEnvProduction ? PRODUCTION_KEY : DEVELOPMENT_KEY;
  }

  injectSnippet() {
    const script = document.createElement('script');
    const fingerprint = cookies.read(PERMANENT_COOKIE_NAME) || '';
    const { userEmail, senderEmail } = this.props;
    const accountKey = this.getKey();

    // eslint-disable-next-line no-useless-escape
    const sessionIdValidChars = /[^a-zA-Z0-9\=\.\-\_\+\@:\&\^\%\!\$]/g;
    const sessionId = fingerprint.replace(sessionIdValidChars, '$');

    const userEmailVar = userEmail
      ? `_sift.push(['_setUserId', '${userEmail}']);`
      : `_sift.push(['_setUserId', '${senderEmail}']);`;

    script.innerHTML = `
    var _sift = window._sift = window._sift || [];
    _sift.push(['_setAccount', '${accountKey}']);
    ${userEmailVar}
    _sift.push(['_setSessionId', '${sessionId}']);
    _sift.push(['_trackPageview']);
    (function() {
        function ls() {
          var e = document.createElement('script');
          e.src = 'https://cdn.siftscience.com/s.js';
          document.body.appendChild(e);
        }
        if (window.attachEvent) {
          window.attachEvent('onload', ls);
        } else {
          window.addEventListener('load', ls, false);
        }
      })();
    `;

    document.body.appendChild(script);
  }

  isScriptInjected() {
    const { isScriptInjected } = this.state;

    return isScriptInjected && window._sift;
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  userEmail: getEmail(state),
  senderEmail: getFieldValue(state, 'sender_email'),
});

const Sift = connect(mapStateToProps)(SiftComponent);

export { Sift };
