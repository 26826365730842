import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { NOOP } from '../../../constants/actions';

type OptionProps = {
  id: string;
  isActive?: boolean;
  label?: number | string;
  onClick?: (value: string) => void;
  value: string;
};

export const Option = forwardRef<HTMLLIElement, OptionProps>(
  (
    { id, isActive = false, label, onClick = NOOP, value, ...otherProps },
    ref,
  ) => {
    const optionProps = {
      'aria-label': label,
      'aria-selected': isActive,
      'data-label': label,
      className: classNames(
        'block py-2.5 px-5 text-sm hover:bg-gray-50 text-gray-950',
        { 'md:bg-gray-100 bg-gray-50': isActive },
      ),
      label,
      onClick,
      role: 'option',
      tabIndex: -1,
      value,
      id,
      ref,
      ...otherProps,
    };

    return <li {...optionProps}>{label}</li>;
  },
);
