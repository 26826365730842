import { Order } from 'models';

type MapperAttrs = {
  apiConfirmationURL: string;
  order: Order;
  payorId: string;
  uuidToken: string;
};

const mapper = {
  mapToBackEnd: function (attrs: MapperAttrs) {
    const { apiConfirmationURL, payorId, uuidToken, ...rest } = attrs;

    return {
      api_confirmation_url: apiConfirmationURL,
      payor_id: payorId,
      uuid_token: uuidToken,
      ...rest,
    };
  },
};

export default mapper;
