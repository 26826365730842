import React from 'react';

const Bank = props => {
  return (
    <svg width="22" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="currentColor"
        strokeWidth="1.111"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.667 9.462H6v10.154H2.667zM9.333 9.462h3.333v10.154H9.333zM16 9.462h3.333v10.154H16z" />
        <path d="M21 23H1v-2.538a.84.84 0 0 1 .833-.847h18.334a.84.84 0 0 1 .833.847V23ZM21 6.036v2.57a.845.845 0 0 1-.833.856H1.833A.845.845 0 0 1 1 8.605V6.036l9.628-4.946c.234-.12.51-.12.744 0L21 6.036ZM1 6.077h20" />
      </g>
    </svg>
  );
};

export default Bank;
