import React, { RefObject } from 'react';
import { InputProps, useInput, useInputAriaProps } from 'hooks';
import { Field } from '@flywire/react-headlessui';
import classNames from 'classnames';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils/translations/useTranslations';

import '../Input.scss';

const TextareaInput = ({
  'aria-describedby': ariaDescribedBy,
  className,
  disabled = false,
  error,
  floatingLabel = true,
  forwardRef,
  hint = '',
  label,
  name,
  id = name,
  onBlur,
  onChange,
  onFocus,
  readOnly = false,
  required,
  value = '',
  ...props
}: InputProps) => {
  const { fieldValue, isFocused, handleBlur, handleChange, handleFocus } =
    useInput({
      disabled,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      readOnly,
      value,
    });
  const { inputAriaProps } = useInputAriaProps({
    'aria-describedby': ariaDescribedBy,
    disabled,
    error,
    hint,
    label,
    name,
    readOnly,
    required,
  });
  const i18n = useTranslations();

  const hasValue = !!fieldValue;

  return (
    <Field
      id={id}
      className={classNames('InputGroup TextInput', className, {
        'has-error': error,
        'InputGroup--floatingLabel': floatingLabel,
        'has-value': hasValue,
        'is-disabled': disabled,
        'is-focused': isFocused,
        'is-readOnly': readOnly,
      })}
    >
      <Field.Textarea
        {...props}
        className="Textarea"
        disabled={disabled}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        readOnly={readOnly}
        ref={forwardRef as RefObject<HTMLTextAreaElement>}
        value={fieldValue}
        {...inputAriaProps}
      />
      {label && (
        <Field.Label htmlFor={name} id={`${name}-label`} className="Label">
          <>
            {label}
            {required && <span aria-hidden> *</span>}
          </>
        </Field.Label>
      )}
      {hint && (
        <Field.Hint className="InputGroup-hint" data-testid={`${name}-hint`}>
          <span data-testid={`${name}-hint-msg`} id={`${name}-hint-msg`}>
            {hint}{' '}
          </span>
        </Field.Hint>
      )}
      {error && typeof error === 'string' && (
        <Field.Error
          className="InputGroup-feedback"
          data-testid={`${name}-error`}
        >
          <VisuallyHidden as="span">{label}</VisuallyHidden>
          <span data-testid={`${name}-error-msg`} id={`${name}-error-msg`}>
            {i18n.t(error)}
          </span>
        </Field.Error>
      )}
    </Field>
  );
};

export { TextareaInput };
