import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'lib/flywire-tailwind/Button';
import { Modal } from 'components/ui/Modal/Modal';
import { Heading } from 'components/Heading/Heading';
import { ENTER, SPACE, NOOP } from 'constants/index';

const DuplicatedTaxPaymentModal = ({
  i18n,
  isOpen = false,
  onClose = NOOP,
}) => (
  <Modal size="medium" isOpen={isOpen} className="TaxModal" onClose={onClose}>
    <Heading as="h3" size="large">
      {i18n.t('duplicatedTaxPaymentModal.title')}
    </Heading>
    <p className="type-regular">{i18n.t('duplicatedTaxPaymentModal.text')}</p>
    <footer>
      <Button
        tone="primary"
        data-testid="confirmButton"
        onMouseDown={onClose}
        onKeyDown={evt => [ENTER, SPACE].includes(evt.keyCode) && onClose()}
      >
        {i18n.t('modal.button.accept')}
      </Button>
    </footer>
  </Modal>
);

DuplicatedTaxPaymentModal.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export { DuplicatedTaxPaymentModal };
