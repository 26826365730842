import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmbedded } from 'selectors';
import type { RootState } from 'reducers/types';

const IsEmbeddedComponent = ({ children, isEmbedded }) => {
  return isEmbedded ? children : null;
};

IsEmbeddedComponent.propTypes = {
  children: PropTypes.node,
  isEmbedded: PropTypes.bool,
};

IsEmbeddedComponent.defaultProps = {
  children: null,
  isEmbedded: false,
};

const mapStateToProps = (state: RootState) => ({
  isEmbedded: isEmbedded(state),
});

const IsEmbedded = connect(mapStateToProps)(IsEmbeddedComponent);

export { IsEmbedded };
