import { useState, useEffect, useRef } from 'react';
import { ONE_SECOND_IN_MILISECONDS } from 'constants/timers';

function useCountdown(
  time: number,
  { interval = ONE_SECOND_IN_MILISECONDS } = {},
) {
  const timer = useRef(null);
  const [timeLeft, setTimeLeft] = useState(time);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    timer.current = setInterval(
      () => setTimeLeft((timeLeft: number) => timeLeft - interval),
      interval,
    );

    return () => {
      clearInterval(timer.current);
    };
  }, [interval]);

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsDone(true);
      clearInterval(timer.current);
    }
  }, [timeLeft]);

  return { timeLeft, isDone };
}

export default useCountdown;
