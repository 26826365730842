import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, ConnectedProps } from 'react-redux';
import {
  closeDuplicatedTaxPaymentModal,
  setTermsErrors,
  setValidationErrors,
  setFieldsValues,
  validateSections,
  setPrefilledRejectOrConfirm,
  trackFieldsErrors,
  updateOrderWithEntities,
  validateSenderAddress,
  confirmTax,
} from 'actions';
import {
  getInvalidParamErrors,
  getFieldValue,
  getLastPaymentFromSameRecipient,
  getNonPrefillableFields,
  getRecipient,
  getSectionsByEntities,
  isDuplicatedTaxPaymentModalOpen,
  hasGatewayOrigin,
  isEmbedded,
  shouldOpenTaxModal,
  shouldShowPrefillForm,
  getSelectedOffer,
  shouldShowCashback,
  sourceOfFundsIndia,
} from 'selectors';
import {
  CHINA_PAYMENT_OPTIONS_WITH_CUSTOM_SECTION,
  INDIA_PAYMENT_OPTIONS_WITH_CUSTOM_SECTION,
  INTERAC_PAYMENT_OPTION_WITH_CUSTOM_SECTION,
  OFFER_ENTITY,
  PAYER_FORM,
  SENDER_ENTITY,
  TERMS_ACCEPTED_FIELD,
} from 'constants/index';
import { Navigation } from 'components/Navigation/Navigation';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import { Section } from 'components/SectionList/Section/Section';
import { Fetching } from 'components/Fetching/Fetching';
import { RequiredFieldLabel } from 'components/RequiredFieldLabel/RequiredFieldLabel';
import { WithTranslations } from 'components/HOC/WithTranslations/WithTranslations';
import { WithFocusOnError } from 'components/HOC/WithFocusOnError/WithFocusOnError';
import { Info } from 'components/Alert/Info/Info';
import { TaxModalContainer as TaxModal } from 'containers/Modals/TaxModal/TaxModal';
import { ChinaOfferSection } from './components/ChinaOfferSection/ChinaOfferSection';
import { DuplicatedTaxPaymentModal } from './components/DuplicatedTaxPaymentModal/DuplicatedTaxPaymentModal';
import { IndiaOfferSection } from './components/IndiaOfferSection/IndiaOfferSection';
import { InteracOfferSection } from './components/InteracOfferSection/InteracOfferSection';
import { NextStep } from './components/NextStep/NextStep';
import { PrefillModal } from './components/PrefillModal/PrefillModal';
import { PrevStep } from './components/PrevStep/PrevStep';
import { SenderInformationAcceptGroup } from './components/SenderInformationAcceptGroup/SenderInformationAcceptGroup';
import type { I18n } from 'utils';
import type { RootState } from 'reducers/types';

type PropsFromRedux = ConnectedProps<typeof connector>;
type SenderInformationProps = PropsFromRedux & {
  i18n: I18n;
};

class SenderInformationComponent extends Component<SenderInformationProps> {
  state = {
    goToNextStep: false,
    goToPrevStep: false,
    isDuplicatedTaxPaymentModalOpen: this.props.isDuplicatedTaxPaymentModalOpen,
    isTaxModalOpen: false,
    shouldOpenTaxModal: this.props.shouldOpenTaxModal,
  };

  static getDerivedStateFromProps(props, state) {
    if (
      state.shouldOpenTaxModal !== props.shouldOpenTaxModal ||
      state.isDuplicatedTaxPaymentModalOpen !==
        props.isDuplicatedTaxPaymentModalOpen
    ) {
      return {
        shouldOpenTaxModal: props.shouldOpenTaxModal,
        isDuplicatedTaxPaymentModalOpen: props.isDuplicatedTaxPaymentModalOpen,
      };
    }
    return null;
  }

  handleClickPrev = () => {
    this.setState({ goToPrevStep: true });
  };

  goAhead = async () => {
    const {
      updateOrderWithEntities,
      focusOnFirstError,
      setPrefilledRejectOrConfirm,
    } = this.props;

    if (await this.validate()) {
      setPrefilledRejectOrConfirm(true);
      await updateOrderWithEntities([SENDER_ENTITY, OFFER_ENTITY])
        .then(() => {
          this.setState({ goToNextStep: true });
        })
        .catch(() => {
          this.validate();
          focusOnFirstError();
        });
    } else {
      focusOnFirstError();
    }
  };

  handleClickNext = async () => {
    const {
      updateOrderWithEntities,
      focusOnFirstError,
      setPrefilledRejectOrConfirm,
    } = this.props;

    if (await this.validate()) {
      setPrefilledRejectOrConfirm(true);
      await updateOrderWithEntities([SENDER_ENTITY, OFFER_ENTITY])
        .then(() => {
          if (this.props.shouldOpenTaxModal) {
            return this.setState({ isTaxModalOpen: true });
          } else {
            this.setState({ goToNextStep: true });
          }
        })
        .catch(() => {
          this.validate();
          focusOnFirstError();
        });
    } else {
      focusOnFirstError();
    }
  };

  handleOnCloseTaxModal = async () => {
    this.setState({ isTaxModalOpen: false });
    this.setState({ goToNextStep: true });
  };

  validate = async () => {
    const { sections, validateSections, validateSenderAddress } = this.props;
    const isSectionValid = await validateSections(
      Object.keys(sections).map(id => sections[id]),
      true,
    );
    const areTermsValid = this.validateTerms();
    const isSenderAddressValid = await validateSenderAddress();

    return isSectionValid && areTermsValid && isSenderAddressValid;
  };

  validateTerms = () => {
    const { areTermsAccepted, setTermsErrors } = this.props;
    if (!areTermsAccepted) setTermsErrors();
    return areTermsAccepted;
  };

  prefillForm = () => {
    const { lastPaymentFromSameRecipient = {}, nonPrefillableFields } =
      this.props;

    const offer = lastPaymentFromSameRecipient.offer || {};
    const recipient = lastPaymentFromSameRecipient.recipient || {};
    const sender = lastPaymentFromSameRecipient.sender || {};
    const { fields: offerFields = [] } = offer;
    const { fields: recipientFields = [] } = recipient;
    const { fields: senderFields = [] } = sender;
    const { setFieldsValues } = this.props;
    const offerValues = offerFields.reduce((acc, value) => {
      return { ...acc, [`offer_${value.id}`]: value.value };
    }, {});

    const recipientValues = recipientFields.reduce((acc, value) => {
      return { ...acc, [value.id]: value.value };
    }, {});

    const senderValues = senderFields.reduce((acc, value) => {
      if (value.id === 'country') return acc;

      return { ...acc, [`sender_${value.id}`]: value.value };
    }, {});

    let fieldValues = { ...offerValues, ...recipientValues, ...senderValues };

    nonPrefillableFields.forEach(id => {
      const { [id]: found, ...rest } = fieldValues;
      fieldValues = rest;
    });

    setFieldsValues(fieldValues);

    this.closeModal();
  };

  closeModal = () => {
    const { setPrefilledRejectOrConfirm } = this.props;

    setPrefilledRejectOrConfirm(true);
  };

  get confirmationBody() {
    const { hasGatewayOrigin, recipient, i18n } = this.props;

    return hasGatewayOrigin
      ? i18n.t('senderInformation.prefillFormBody.integrated', {
          institution: recipient.name,
        })
      : i18n.t('senderInformation.prefillFormBody');
  }

  render() {
    const handleModalOnClose = this.closeModal;
    const handleOnConfirm = this.prefillForm;
    const handleOnReject = this.closeModal;
    const {
      isDuplicatedTaxPaymentModalOpen,
      isTaxModalOpen,
      shouldOpenTaxModal,
      goToNextStep,
      goToPrevStep,
    } = this.state;

    const {
      closeDuplicatedTaxPaymentModal,
      errors,
      i18n,
      isEmbedded,
      isPrefillModalOpen,
      recipient,
      sections,
      offer: { paymentOption },
      sourceOfFundsIndia,
    } = this.props;

    if (goToPrevStep) {
      return <PrevStep />;
    }

    if (!shouldOpenTaxModal && goToNextStep) {
      return <NextStep />;
    }

    return (
      <React.Fragment key={isPrefillModalOpen}>
        {isPrefillModalOpen && (
          <PrefillModal
            title={i18n.t('senderInformation.prefillFormTitle', {
              institution: recipient.name,
            })}
            body={this.confirmationBody}
            i18n={i18n}
            isOpen
            onClose={handleModalOnClose}
            onConfirm={handleOnConfirm}
            onReject={handleOnReject}
            institution={recipient.name}
          />
        )}
        <TaxModal
          isOpen={isTaxModalOpen}
          onClose={this.handleOnCloseTaxModal}
        />
        <DuplicatedTaxPaymentModal
          i18n={i18n}
          isOpen={isDuplicatedTaxPaymentModalOpen}
          onClose={closeDuplicatedTaxPaymentModal}
        />
        <Fetching entity="order" />
        <PaymentStep
          navigation={
            <Navigation
              onClickPrev={this.handleClickPrev}
              onClickNext={this.handleClickNext}
              errors={errors}
              previousStepName={i18n.t('stepsList.offers')}
              nextStepName={i18n.t('stepsList.recipient')}
            />
          }
          stepName={PAYER_FORM}
          title={i18n.t('senderInformation.title') as string}
        >
          <Info className="flex justify-center">
            <Info.FilledIcon
              height="14"
              width="14"
              className="marginRight-sm"
            />
            {i18n.t('senderInformation.infoAlert')}
          </Info>
          <RequiredFieldLabel />
          {Object.keys(sections).map(id => {
            const { name, ...sectionWithoutName } = sections[id];

            if (
              id === OFFER_ENTITY &&
              INDIA_PAYMENT_OPTIONS_WITH_CUSTOM_SECTION.includes(paymentOption)
            ) {
              return (
                <IndiaOfferSection
                  key={id}
                  paymentOption={paymentOption}
                  sourceOfFunds={sourceOfFundsIndia as string}
                >
                  <Section {...sectionWithoutName} />
                </IndiaOfferSection>
              );
            }

            if (
              id === OFFER_ENTITY &&
              CHINA_PAYMENT_OPTIONS_WITH_CUSTOM_SECTION.includes(paymentOption)
            ) {
              return (
                <ChinaOfferSection key={id} i18n={i18n}>
                  <Section {...sectionWithoutName} />
                </ChinaOfferSection>
              );
            }

            if (
              id === OFFER_ENTITY &&
              paymentOption?.includes(
                INTERAC_PAYMENT_OPTION_WITH_CUSTOM_SECTION,
              )
            ) {
              return (
                <InteracOfferSection key={id} i18n={i18n}>
                  <Section {...sectionWithoutName} />
                </InteracOfferSection>
              );
            }

            return <Section key={id} {...sectionWithoutName} />;
          })}

          {!isEmbedded && <SenderInformationAcceptGroup />}
        </PaymentStep>
      </React.Fragment>
    );
  }
}

SenderInformationComponent.propTypes = {
  areTermsAccepted: PropTypes.bool.isRequired,
  closeDuplicatedTaxPaymentModal: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  focusOnFirstError: PropTypes.func.isRequired,
  hasGatewayOrigin: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  isDuplicatedTaxPaymentModalOpen: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  isPrefillModalOpen: PropTypes.bool.isRequired,
  lastPaymentFromSameRecipient: PropTypes.object,
  nonPrefillableFields: PropTypes.array,
  offer: PropTypes.shape({ paymentOption: PropTypes.string }),
  recipient: PropTypes.object,
  sections: PropTypes.shape({
    [SENDER_ENTITY]: PropTypes.object,
    [OFFER_ENTITY]: PropTypes.object,
  }),
  setFieldsValues: PropTypes.func,
  setPrefilledRejectOrConfirm: PropTypes.func.isRequired,
  setTermsErrors: PropTypes.func.isRequired,
  setValidationErrors: PropTypes.func.isRequired,
  shouldOpenTaxModal: PropTypes.bool,
  signupUser: PropTypes.func,
  sourceOfFundsIndia: PropTypes.string,
  trackFieldsErrors: PropTypes.func.isRequired,
  updateOrderWithEntities: PropTypes.func.isRequired,
  validateSections: PropTypes.func.isRequired,
  validateSenderAddress: PropTypes.func.isRequired,
};

SenderInformationComponent.defaultProps = {
  isPrefillModalOpen: false,
  nonPrefillableFields: [],
  offer: {},
  sections: {},
};

const mapStateToProps = (state: RootState) => {
  const senderSection = getSectionsByEntities(state, [SENDER_ENTITY])?.[0];
  const offerSection = getSectionsByEntities(state, [OFFER_ENTITY])?.[0];

  return {
    areTermsAccepted: !!getFieldValue(state, TERMS_ACCEPTED_FIELD),
    errors: getInvalidParamErrors(state),
    hasGatewayOrigin: hasGatewayOrigin(state),
    isDuplicatedTaxPaymentModalOpen: isDuplicatedTaxPaymentModalOpen(state),
    isEmbedded: isEmbedded(state),
    isPrefillModalOpen: shouldShowPrefillForm(state),
    shouldOpenTaxModal: shouldOpenTaxModal(state),
    shouldShowCashback: shouldShowCashback(state),
    lastPaymentFromSameRecipient: getLastPaymentFromSameRecipient(state),
    nonPrefillableFields: getNonPrefillableFields(state),
    offer: getSelectedOffer(state),
    recipient: getRecipient(state),
    sourceOfFundsIndia: sourceOfFundsIndia(state),
    sections: {
      ...(senderSection && { [SENDER_ENTITY]: senderSection }),
      ...(offerSection && { [OFFER_ENTITY]: offerSection }),
    },
  };
};

const mapDispatchToProps = {
  closeDuplicatedTaxPaymentModal,
  confirmTax,
  setFieldsValues,
  setPrefilledRejectOrConfirm,
  setTermsErrors,
  setValidationErrors,
  trackFieldsErrors,
  updateOrderWithEntities,
  validateSections,
  validateSenderAddress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SenderInformation = connector(
  WithFocusOnError(WithTranslations(SenderInformationComponent)),
);

export { SenderInformation };
