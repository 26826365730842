import React from 'react';
import { useWizard } from 'components/Wizard/components/context';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import { Navigation } from 'components/Navigation/Navigation';
import { NOOP } from 'constants/index';
import type { I18n } from 'utils';

type StepProps = {
  children: React.ReactNode;
  i18n: I18n;
  stepName: string;
};

function Step({ children, i18n, stepName }: StepProps) {
  const { goToPrevStep = NOOP } = useWizard();
  const navigation = (
    <Navigation
      hideNext
      onClickPrev={goToPrevStep}
      previousStepName={i18n.t('stepsList.payment')}
    />
  );

  return (
    <PaymentStep
      navigation={navigation}
      stepName={stepName}
      title={i18n.t('paymentOptions.title') as string}
    >
      {children}
    </PaymentStep>
  );
}

Step.displayName = 'Step';

export { Step };
