import React, { ReactNode } from 'react';

const DEFAULT_SIZE = 'huge' as const;
const SUPPORTED_SIZES = ['huge', 'large', 'medium', 'small'] as const;
const DEFAULT_TAG = 'h1' as const;
const SUPPORTED_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

type HeadingProps = {
  id?: string;
  children?: ReactNode;
  as?: (typeof SUPPORTED_TAGS)[number];
  size?: (typeof SUPPORTED_SIZES)[number];
  className?: string;
};

const Heading = ({
  children,
  as = DEFAULT_TAG,
  size = DEFAULT_SIZE,
  className,
  ...props
}: HeadingProps) => {
  const Element = as;
  if (!SUPPORTED_TAGS.includes(Element)) {
    throw new Error('Unsupported type');
  }

  const joinedClassNames = joinClassNames(size, className);

  return (
    <Element className={joinedClassNames} {...props}>
      {children}
    </Element>
  );
};

const joinClassNames = (size: string, className?: string) => {
  let headingClasses = `Heading Heading--${size}`;

  if (className) {
    headingClasses += ` ${className}`;
  }

  return headingClasses;
};

export { Heading };
