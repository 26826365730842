import React from 'react';

const FlywireIcon = props => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.95131 8.20248L8.13336 7.1236H1.46622L1.74035 5.40814H8.4033L8.5328 4.58826C8.91415 2.19965 10.5161 0.824768 12.9455 0.828131H14.2804L14.0185 2.50028L12.7676 2.49776C11.4163 2.49649 10.6443 3.16502 10.4005 4.65679L10.281 5.40814H12.8576L12.5843 7.1236H10.0111L8.6068 15.4326C8.22418 17.8195 6.59786 19.186 4.15418 19.1831L4.01417 19.1822L4.29924 17.3709L4.39048 17.3701C5.76536 17.3718 6.50536 16.7898 6.74839 15.3056L7.66077 9.91668H0.984375L1.25851 8.20206H7.95131V8.20248Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FlywireIcon;
