import fetchJson from 'utils/fetchJson';
import { LOGGER_URL } from 'constants/index';

export default {
  log: async ({
    description,
    parameters,
  }: {
    description: string;
    parameters?: Record<string, unknown>;
  }) => {
    const path = `${LOGGER_URL}`;
    const body = JSON.stringify({ description, parameters });

    const options = { body, method: 'POST' };

    try {
      await fetchJson(path, options);
    } catch (error) {
      console.error(error);
    }
  },
};
