import React, { useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IsProduction } from 'containers/IsProduction/IsProduction';
import { Ada } from './Ada/Ada';
import { Keys, NOOP } from 'constants/index';
import { chatEnabled } from 'selectors';
import { setChatVisible } from 'actions';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import tooltipIcon from 'icons/tooltip-information.svg';
import { useTranslations } from 'utils';
import type { RootState } from 'reducers/types';

import './Chat.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

const ChatComponent = ({
  enabled = true,
  setChatVisible = NOOP,
}: PropsFromRedux) => {
  const i18n = useTranslations();
  const chatRef = useRef(null);
  const [showTooltipButton, setShowTooltipButton] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  function handleOnLoad() {
    setChatVisible();
    setShowTooltipButton(true);
  }

  function handleOnShow() {
    chatRef.current.innerHTML = i18n.t('iframe.disclaimer');
  }

  const openTooltip = () => {
    setShowTooltip(true);
  };

  const closeTooltip = () => {
    setShowTooltip(false);
  };

  const handleOnKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === Keys.ESC) {
      closeTooltip();
    }
  };

  if (!enabled) return null;

  return (
    <IsProduction>
      <VisuallyHidden aria-live="polite" forwardRef={chatRef} />
      <div data-testid="chat">
        {showTooltipButton ? (
          <button
            onMouseOver={openTooltip}
            onMouseLeave={closeTooltip}
            onFocus={openTooltip}
            onBlur={closeTooltip}
            onKeyDown={handleOnKeyDown}
            tabIndex={0}
            className={'Chat-tooltipInfo buttonLikeLink'}
            aria-label={i18n.t('chat.tooltip.message') as string}
          >
            <img src={tooltipIcon} alt="" role="none" />
            {showTooltip ? (
              <div className={'Chat-tooltip'} data-testid="tooltip">
                {i18n.t('chat.tooltip.message')}
              </div>
            ) : null}
          </button>
        ) : null}

        <Ada onLoad={handleOnLoad} onShow={handleOnShow} />
      </div>
    </IsProduction>
  );
};

const mapStateToProps = (state: RootState) => ({
  enabled: chatEnabled(state),
});

const mapDispatchToProps = {
  setChatVisible,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Chat = connector(ChatComponent);

export { Chat };
