import React from 'react';
import { Logo } from 'containers/Logo/Logo';
import { WithFlysiteUrls } from 'components/HOC/WithFlysiteUrls/WithFlysiteUrls';
import './Powered.scss';

const PoweredComponent = ({ flysiteUrl }) => (
  <div className="Powered">
    <p className="Powered-text">Powered by</p>
    <a href={flysiteUrl} rel="noopener noreferrer" target="_blank">
      <Logo className="Powered-logo" size="small" />
    </a>
  </div>
);

const Powered = WithFlysiteUrls(PoweredComponent);

export { Powered };
