import React, { useState } from 'react';
import { Popover } from 'components/Popover/Popover';
import { TextInputField } from '../../FieldList/Field/TextInputField/TextInputField';
import { userExists } from 'actions';
import { isEmbedded, isLoggedIn, isUserCreated } from 'selectors';
import { RootState } from 'reducers/types';
import { ConnectedProps, connect } from 'react-redux';
import {
  FieldInterface,
  FieldValueType,
} from 'components/FieldList/Field/Field';
import { useTranslations } from 'utils';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { Button } from 'lib/flywire-tailwind/Button';
import { UserAccessModal } from 'components/UserAccessModal/UserAccessModal';
import { ReactComponent as BannerStar } from '../assets/bannerStar.svg';
import { CASHBACK_EARN_PERCENTAGE } from 'constants/cashback';
import './CashbackSenderEmailInputField.scss';
interface CashbackSenderEmailInputFieldType extends FieldInterface {
  value: Array<FieldValueType>;
}

type CashbackSenderEmailInputFieldProps = ConnectedProps<typeof connector> & {
  field: CashbackSenderEmailInputFieldType;
  handleChange: (name: string, value: string) => void;
  handleBlur: (name: string) => boolean;
};

function CashbackSenderEmailInputFieldComponent({
  field,
  handleChange,
  handleBlur,
  userExists,
  isEmbedded,
  isLoggedIn,
  isUserCreated,
}: CashbackSenderEmailInputFieldProps) {
  const [isPopoverOpen, setPopoverOpen] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const closePopover = () => setPopoverOpen(false);
  const openPopover = () => setPopoverOpen(true);

  const validateEmail = async (name: string) => {
    const isValid = await handleBlur(name);
    if (isValid) await userExists();
    setIsValidEmail(isValid);
  };

  const changeEmail = (name: string, value: string) => {
    setIsValidEmail(false);
    openPopover();
    handleChange(name, value);
  };
  const shouldShowPopover = isValidEmail && !isEmbedded && !isLoggedIn;

  return (
    <aside
      data-testid="cashback-sender-email-input-field"
      className="CashbackSenderEmailInputField"
    >
      <TextInputField
        handleBlur={validateEmail}
        handleChange={changeEmail}
        field={field}
      />
      {shouldShowPopover && (
        <PopoverContent
          handleClose={closePopover}
          isOpen={isPopoverOpen}
          isUserCreated={isUserCreated}
        />
      )}
    </aside>
  );
}

function PopoverContent({
  handleClose,
  isOpen,
  isUserCreated,
}: {
  handleClose: () => void;
  isOpen: boolean;
  isUserCreated: boolean;
}) {
  const [showUserAccessModal, setShowUserAccessModal] = useState(false);
  const [showSignupTab, setShowSignupTab] = useState(false);
  const openModalLogin = () => {
    setShowSignupTab(false);
    setShowUserAccessModal(true);
  };

  const openModalSignup = () => {
    setShowSignupTab(true);
    setShowUserAccessModal(true);
  };

  const closeUserAccessModal = () => setShowUserAccessModal(false);

  const handleLoginSuccess = async () => {
    closeUserAccessModal();
  };

  const i18n = useTranslations();
  const titleDefault = isUserCreated
    ? (i18n.t('senderInformation.cashback.login.title') as string)
    : (i18n.t('senderInformation.cashback.singup.title') as string);
  const subtitleDefault = isUserCreated
    ? (i18n.t('senderInformation.cashback.login.subtitle') as string)
    : (i18n.t('senderInformation.cashback.singup.subtitle') as string);
  const actionButton = isUserCreated
    ? (i18n.t('userAccessModal.login') as string)
    : (i18n.t('userAccessModal.signup') as string);
  return (
    <>
      <Popover.Wrapper isOpen={isOpen}>
        <div className="rewardsInfoStarIconBg"></div>
        <BannerStar
          className="rewardsInfoStarIcon"
          data-testid="rewards-star-icon"
        />
        <Popover.CloseButton handleClose={handleClose} />
        <Popover.Content className="PopoverContent">
          <div className="PopoverContent-loginSignup">
            <Popover.Title text={titleDefault} />
            <Popover.Subtitle text={subtitleDefault} />
            <Button
              tone="primary"
              className="min-w-full md:min-w-40"
              onClick={isUserCreated ? openModalLogin : openModalSignup}
              data-testid="cashback-sender-email-input-button-login-signup"
              aria-label={'login-signup-button'}
            >
              {i18n.t(actionButton)}
            </Button>
          </div>
          <div className="PopoverContent-rewardsInfo">
            <p
              dangerouslySetInnerHTML={createMarkup(
                i18n.t('senderInformation.cashback.earnRewardsMessage', {
                  amount: `${CASHBACK_EARN_PERCENTAGE}`,
                }) as string,
              )}
            />
          </div>
        </Popover.Content>
      </Popover.Wrapper>

      <UserAccessModal
        isOpen={showUserAccessModal}
        showSignupTab={showSignupTab}
        onClose={closeUserAccessModal}
        onLoginSuccess={handleLoginSuccess}
        onSignUpSuccess={handleLoginSuccess}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  isEmbedded: isEmbedded(state),
  isLoggedIn: isLoggedIn(state),
  isUserCreated: isUserCreated(state),
});

const mapDispatchToProps = {
  userExists,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CashbackSenderEmailInputField = connector(
  CashbackSenderEmailInputFieldComponent,
);

export { CashbackSenderEmailInputField };
