import React from 'react';
import { Header } from 'components/Header/Header';
import { FooterWithAddress } from 'containers/FooterWithAddress/FooterWithAddress';
import { RedirectButton } from './RedirectButton/RedirectButton';
import { Heading } from 'components/Heading/Heading';
import { useTranslations } from 'utils';

import './GatewayOnly.scss';

type GatewayOnlyProps = {
  clientName: string;
  url: string;
};

const GatewayOnly = ({ clientName, url }: GatewayOnlyProps) => {
  const i18n = useTranslations();

  return (
    <div className="Site">
      <Header />
      <main className="App GatewayOnly">
        <section className="Step">
          <div className="Step-body">
            <header className="Step-header no-print">
              <Heading
                as="h1"
                size="huge"
                className="marginBottom-xxl"
                data-testid="stepTitle"
              >
                {i18n.t('gatewayOnly.header', { clientName })}
              </Heading>
              <RedirectButton url={url} />
            </header>
          </div>
        </section>
      </main>
      <FooterWithAddress />
    </div>
  );
};

export { GatewayOnly };
