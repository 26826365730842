import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { shouldShowEURateDisclaimer } from 'selectors';
import { EURateDisclaimer } from 'components/PaymentOptions/components/SinglePaymentOptions/components/EURateDisclaimer/EURateDisclaimer';
import { WithTranslations } from 'components/HOC/WithTranslations/WithTranslations';

function Wrapper({ isVisible, i18n }) {
  if (!isVisible) {
    return null;
  }

  return <EURateDisclaimer i18n={i18n} />;
}

Wrapper.displayName = 'EURateDisclaimerContainer';
Wrapper.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }),
  isVisible: PropTypes.bool,
};

const mapStateToProps = state => ({
  isVisible: shouldShowEURateDisclaimer(state),
});

const EURateDisclaimerContainer = connect(mapStateToProps)(
  WithTranslations(Wrapper),
);

export { EURateDisclaimerContainer };
