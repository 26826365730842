export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGIN_EXPIRATION_IN_MINUTES = 30;
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const EXCHANGE_LOGIN_TOKEN_SUCCESS = 'EXCHANGE_LOGIN_TOKEN_SUCCESS';
export const AUTH_LOCALSTORAGE_KEY = 'authentication';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const USER_CREDENTIALS_VERIFICATION_REQUEST =
  'USER_CREDENTIALS_VERIFICATION_REQUEST';
export const USER_CREDENTIALS_VERIFICATION_SUCCESS =
  'USER_CREDENTIALS_VERIFICATION_SUCCESS';
export const USER_CREDENTIALS_VERIFICATION_FAILURE =
  'USER_CREDENTIALS_VERIFICATION_FAILURE';
export const USER_EXISTS_REQUEST = 'USER_EXISTS_REQUEST';
export const USER_EXISTS_SUCCESS = 'USER_EXISTS_SUCCESS';
export const USER_EXISTS_FAILURE = 'USER_EXISTS_FAILURE';
export const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';
export const REMOVE_CREDIT_CARD_REQUEST = 'REMOVE_CREDIT_CARD_REQUEST';
export const REMOVE_CREDIT_CARD_SUCCESS = 'REMOVE_CREDIT_CARD_SUCCESS';
export const REMOVE_CREDIT_CARD_FAILURE = 'REMOVE_CREDIT_CARD_FAILURE';
export const CARD_ACTIVE_PLANS_REQUEST = 'CARD_ACTIVE_PLANS_REQUEST';
export const CARD_ACTIVE_PLANS_SUCCESS = 'CARD_ACTIVE_PLANS_SUCCESS';
export const CARD_ACTIVE_PLANS_FAILURE = 'CARD_ACTIVE_PLANS_FAILURE';
export const CHANGE_CARD_ACTIVE_PLANS_REQUEST =
  'CHANGE_CARD_ACTIVE_PLANS_REQUEST';
export const CHANGE_CARD_ACTIVE_PLANS_SUCCESS =
  'CHANGE_CARD_ACTIVE_PLANS_SUCCESS';
export const CHANGE_CARD_ACTIVE_PLANS_FAILURE =
  'CHANGE_CARD_ACTIVE_PLANS_FAILURE';
export const SAVE_CARD_REQUEST = 'SAVE_CARD_REQUEST';
export const SAVE_CARD_SUCCESS = 'SAVE_CARD_SUCCESS';
export const SAVE_CARD_FAILURE = 'SAVE_CARD_FAILURE';
export const IMPERSONATE_USER_SUCCESS = 'IMPERSONATE_USER_SUCCESS';
export const IMPERSONATE_FAKE_REFRESH_TOKEN = 'impersonation';
export const IMPERSONATE_EXPIRATION_IN_SECONDS = 1800;
export const FETCH_EXTENDED_USER_REQUEST = 'FETCH_EXTENDED_USER_REQUEST';
export const FETCH_EXTENDED_USER_SUCCESS = 'FETCH_EXTENDED_USER_SUCCESS';
export const FETCH_EXTENDED_USER_FAILURE = 'FETCH_EXTENDED_USER_FAILURE';
export const REMOVE_NEW_CREDIT_CARD_SUCCESS = 'REMOVE_NEW_CREDIT_CARD_SUCCESS';
export const REMOVE_NEW_CREDIT_CARD_FAILURE = 'REMOVE_NEW_CREDIT_CARD_FAILURE';
