import React, { useEffect } from 'react';
import {
  FF_COUPONS_CN,
  FF_COUPONS_IN,
  FF_REDEEM_CASHBACK,
} from 'constants/index';
import { usePeertransferFlags } from 'hooks/usePeertransferFlags/usePeertransferFlags';
import { useDispatch, useSelector } from 'react-redux';

import {
  isCouponApplied,
  getCouponCode,
  isPayingFromChina,
  isPayingFromIndia,
  isRedeemCashbackApplied,
  shouldShowCoupon,
  shouldShowRedeemCashback,
  getOrder,
  isLoggedIn,
} from 'selectors';
import { Coupon } from '../Coupon/Coupon';
import { RedeemCashback } from '../RedeemCashback/RedeemCashback';
import { deleteCoupon, deleteRedeemCashback } from 'actions';
import { useCalculateRedeemCashback } from 'hooks/useRedeemCashback/useCalculateRedeemCashback';

const Discounts = () => {
  const dispatch = useDispatch();
  const { id: orderId, token } = useSelector(getOrder);
  const payingFromChina = useSelector(isPayingFromChina);
  const payingFromIndia = useSelector(isPayingFromIndia);
  const showCoupon = useSelector(shouldShowCoupon);
  const couponApplied = useSelector(isCouponApplied);
  const showRedeemCashback = useSelector(shouldShowRedeemCashback);
  const redeemCashbackApplied = useSelector(isRedeemCashbackApplied);
  const couponCode = useSelector(getCouponCode);
  const loggedIn = useSelector(isLoggedIn);

  const { data: featureFlags } = usePeertransferFlags([
    FF_COUPONS_CN,
    FF_COUPONS_IN,
    FF_REDEEM_CASHBACK,
  ]);

  const { data: calculateRedeemCashback } = useCalculateRedeemCashback(
    {
      orderId,
      token,
    },
    { enabled: showRedeemCashback },
  );

  const {
    availableRedeemAmount = 0,
    walletAmount = 0,
    bonusAmount,
  } = calculateRedeemCashback ?? {};

  const isRedeemCashbackEnabled =
    featureFlags?.[FF_REDEEM_CASHBACK] &&
    showRedeemCashback &&
    availableRedeemAmount > 0;

  const isCouponsEnabled =
    ((payingFromChina && featureFlags?.[FF_COUPONS_CN]) ||
      (payingFromIndia && featureFlags?.[FF_COUPONS_IN])) &&
    showCoupon &&
    !couponApplied &&
    !isRedeemCashbackEnabled;

  useEffect(() => {
    if (couponApplied) {
      dispatch(deleteCoupon(couponCode as string));
    }

    if (redeemCashbackApplied) {
      dispatch(deleteRedeemCashback());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isRedeemCashbackEnabled && couponApplied) {
      dispatch(deleteCoupon(couponCode as string));
    }
  }, [couponApplied, isRedeemCashbackEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loggedIn && redeemCashbackApplied) {
      dispatch(deleteRedeemCashback());
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div data-testid="Discounts">
      {isCouponsEnabled && <Coupon />}
      {isRedeemCashbackEnabled && (
        <RedeemCashback
          availableRedeemAmount={availableRedeemAmount}
          walletAmount={walletAmount}
          bonusAmount={bonusAmount}
        />
      )}
    </div>
  );
};

export { Discounts };
