import React from 'react';
import { useTranslations } from 'utils';

import './CouponBanner.scss';
import { Info } from 'components/Alert/Info/Info';
import { ReactComponent as InfoIcon } from 'icons/ico-info-current-color.svg';
import { useSelector } from 'react-redux';
import { isPayingFromChina } from 'selectors';
import { usePeertransferFlags } from 'hooks/usePeertransferFlags/usePeertransferFlags';
import { FF_COUPONS_CN } from 'constants/index';

const CouponBanner = () => {
  const i18n = useTranslations();
  const payingFromChina = useSelector(isPayingFromChina);

  const { data: couponFlags } = usePeertransferFlags(FF_COUPONS_CN);
  const isCouponsEnabled = couponFlags?.[FF_COUPONS_CN];
  if (!payingFromChina || !isCouponsEnabled) return null;

  return (
    <Info className="CouponBanner" data-testid="CouponBanner">
      <InfoIcon />
      {i18n.t('coupon.banner.text')}
    </Info>
  );
};

export { CouponBanner };
