import { RootState } from 'reducers/types';

export const getLRSIdVerificationNeeded = (state: RootState) => {
  const {
    entities: {
      lrs: { idVerificationNeeded },
    },
  } = state;

  return idVerificationNeeded;
};

export const getLRSPreviousPayments = (state: RootState) => {
  const {
    entities: {
      lrs: { previousPayments },
    },
  } = state;

  return previousPayments;
};

export const lrsSignatureUploadFailed = (state: RootState) => {
  const {
    entities: {
      lrs: { signatureUploadFailed },
    },
  } = state;

  return !!signatureUploadFailed;
};
