import isEmpty from 'lodash/isEmpty';
import { RootState } from 'reducers/types';

export const getInstructionByName = (state: RootState, name: string) => {
  const { entities: { instructions } = {} } = state;

  return instructions?.[name];
};

export const hasInstructions = (state: RootState) => {
  const {
    entities: { instructions },
  } = state;

  return !isEmpty(instructions);
};
