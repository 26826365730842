import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WithFlysiteUrls } from 'components/HOC/WithFlysiteUrls/WithFlysiteUrls';
import { trackBPGEvent } from 'actions';
import { ReactComponent as LaurelIcon } from './laurel-icon.svg';
import { ReactComponent as LinkIcon } from 'icons/link-icon.svg';
import { useTranslations } from 'utils';

import './BestPriceGuaranteed.scss';

const BestPriceGuaranteedComponent = ({
  bestPriceGuaranteeUrl,
  trackBPGEvent,
}) => {
  const i18n = useTranslations();

  return (
    <a
      href={bestPriceGuaranteeUrl}
      onClick={trackBPGEvent}
      data-testid="BestPriceGuaranteed"
      className="BestPriceGuaranteed"
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className="BestPriceGuaranteed-badge">
        <LaurelIcon
          className="BestPriceGuaranteed-icon min-h-16 min-w-16 text-gray-50 md:text-white"
          aria-hidden
        />
      </div>
      <div className="BestPriceGuaranteed-content">
        <h2 className="BestPriceGuaranteed-title">
          {i18n.t('bestPriceGuaranteed.title')}
        </h2>
        <p className="BestPriceGuaranteed-description">
          {i18n.t('bestPriceGuaranteed.description')}
        </p>
      </div>
      <LinkIcon
        className="BestPriceGuaranteed-linkIcon"
        alt="External Link Icon"
      />
    </a>
  );
};

BestPriceGuaranteedComponent.propTypes = {
  bestPriceGuaranteeUrl: PropTypes.string.isRequired,
  trackBPGEvent: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  trackBPGEvent,
};

const BestPriceGuaranteed = WithFlysiteUrls(
  connect(null, mapDispatchToProps)(BestPriceGuaranteedComponent),
);

export { BestPriceGuaranteed };
