import React from 'react';
import { Alert } from 'components/Alert/Alert';
import { useTranslations } from 'utils';

function NoLocalOptions() {
  const i18n = useTranslations();
  return (
    <div className="PaymentOptions-noLocalOptions">
      <Alert>{i18n.t('paymentOptions.noLocalOffers.description')}</Alert>
    </div>
  );
}

export { NoLocalOptions };
