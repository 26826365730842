import { useEffect, useState } from 'react';

function useGenericEvent<T>(
  createEvent: (data: unknown) => T,
  eventType: string,
) {
  const [eventData, setEventData] = useState<T | undefined>();

  useEffect(() => {
    const handleEvent = (event: MessageEvent) => {
      try {
        const eventData = JSON.parse(event.data);

        if (eventData.action === eventType && eventData.sender === 'flywire') {
          setEventData(createEvent(eventData));
        }
      } catch (error) {}
    };

    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [createEvent, eventType]);

  return { eventData };
}

export default useGenericEvent;
