export const RECIPIENTS_WITH_GUARANTEED_TO_DELIVERED_STATUS = [
  'CAP',
  'LOL',
  'FSP',
  'FCA',
  'ITE',
  'BEK',
  'KOR',
  'UUI',
  'ONT',
  'ODV',
  'ODS',
  'OQA',
  'OQS',
  'OUT',
  'OUS',
  'MUB',
  'MSX',
  'RUP',
  'LCX',
  'ZGR',
  'MBO',
  'CEO',
  'ASM',
  'HLF',
  'BFO',
  'ILP',
  'LEP',
  'XTE',
  'MIG',
  'QDD',
  'CDX',
  'QYT',
  'RAP',
  'CDY',
  'GPI',
  'CII',
  'MPO',
  'RCN',
  'VVC',
  'CCW',
  'QCD',
  'CGG',
  'CGH',
  'VDA',
  'AWM',
  'ANT',
  'REV',
  'RVD',
  'RCY',
  'RVS',
  'RVC',
  'RVN',
  'RVH',
  'VVD',
  'VCW',
  'VVY',
  'VCV',
  'VVQ',
  'VPC',
  'CDX',
  'CDO',
  'CDJ',
  'CDK',
  'CCX',
  'QCM',
  'QCL',
  'QCP',
  'QCA',
  'CDQ',
  'CDR',
  'BRH',
  'CDH',
  'CGQ',
  'CGV',
  'CGJ',
  'CZH',
  'CWH',
  'CZD',
  'VRD',
  'VDE',
  'QUY',
  'ZCD',
  'LCA',
  'LBG',
  'LCM',
  'LMW',
  'LBM',
  'BNZ',
  'LBO',
  'LCP',
  'LBJ',
  'MMD',
  'MUP',
  'GLP',
  'XBP',
  'MLE',
];
