import type { RootState } from 'reducers/types';

export const getAccessToken = (state: RootState) => {
  const {
    entities: {
      authentication: { accessToken },
    },
  } = state;

  return accessToken;
};

export const getExpiresIn = (state: RootState) => {
  const {
    entities: {
      authentication: { expiresIn },
    },
  } = state;

  return expiresIn;
};

export const getTokenType = (state: RootState) => {
  const {
    entities: {
      authentication: { tokenType },
    },
  } = state;

  return tokenType;
};

export const getRefreshToken = (state: RootState) => {
  const {
    entities: {
      authentication: { refreshToken },
    },
  } = state;

  return refreshToken;
};

export const isLoggedIn = (state: RootState) => {
  return !!getAccessToken(state);
};
