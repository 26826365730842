import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SpinnerOverlay } from 'components/SpinnerOverlay/SpinnerOverlay';
import { RedirectToTracking } from 'components/RedirectToTracking/RedirectToTracking';
import getDisplayName from 'utils/getDisplayName';
import { hasOrder, hasPayment, isPaymentProcess } from 'selectors';
import { WithTranslations } from '../WithTranslations/WithTranslations';
import type { RootState } from 'reducers/types';

const FIVE_SECONDS_IN_MS = 5000;

const WithPaymentCompletedRedirection = WrappedComponent => {
  const WithPaymentCompletedRedirection = ({
    hasOrder,
    hasPayment,
    isInPaymentProcess,
    i18n,
    ...props
  }) => {
    const isPaymentCompleted = hasPayment && hasOrder;
    const shouldRedirectToTracking = isPaymentCompleted && isInPaymentProcess;

    if (!shouldRedirectToTracking) return <WrappedComponent {...props} />;

    return (
      <SpinnerOverlay
        message={i18n.t(
          'paymentInformation.paymentAlreadyCompletedRedirection.message',
        )}
        className="SpinnerOverlay-transparent"
      >
        <RedirectToTracking delay={FIVE_SECONDS_IN_MS} />
      </SpinnerOverlay>
    );
  };

  WithPaymentCompletedRedirection.propTypes = {
    hasOrder: PropTypes.bool,
    hasPayment: PropTypes.bool,
    i18n: PropTypes.object.isRequired,
    isInPaymentProcess: PropTypes.bool,
  };

  WithPaymentCompletedRedirection.displayName = `WithPaymentCompletedRedirection(${getDisplayName(
    WrappedComponent,
  )})`;

  const mapStateToProps = (state: RootState) => ({
    isInPaymentProcess: isPaymentProcess(state),
    hasOrder: hasOrder(state),
    hasPayment: hasPayment(state),
  });

  return connect(mapStateToProps)(
    WithTranslations(WithPaymentCompletedRedirection),
  );
};

export { WithPaymentCompletedRedirection };
