import { RecurringSelectedOffer } from 'models';
import { useSelector } from 'react-redux';
import { getSchedule, getSelectedOffer } from 'selectors';

function useRecurringOffer() {
  const offer = useSelector(getSelectedOffer);
  const schedule = useSelector(getSchedule);

  return { offer: offer as RecurringSelectedOffer, schedule };
}

export { useRecurringOffer };
