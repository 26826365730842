import React from 'react';
import PropTypes from 'prop-types';

function Body({ children }) {
  return (
    <div className="Offer-body">
      <div className="Offer-summary">{children}</div>
    </div>
  );
}

Body.displayName = 'Body';
Body.propTypes = {
  children: PropTypes.node,
};

export { Body };
