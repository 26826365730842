import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tab } from './Tab/Tab';
import { TabList } from './TabList/TabList';
import { TabPanel } from './TabPanel/TabPanel';
import { TabPanels } from './TabPanels/TabPanels';

const Context = createContext();

function Tabs({
  id = 'tabs',
  children,
  className,
  defaultActiveIndex = 0,
  onChange = () => {},
}) {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const tabClassName = classNames('Tabs', className);

  return (
    <Context.Provider
      value={{
        activeIndex,
        id,
        onChange,
        onSelectTab: setActiveIndex,
      }}
    >
      <div className={tabClassName}>{children}</div>
    </Context.Provider>
  );
}

Tabs.Tab = Tab;
Tabs.TabList = TabList;
Tabs.TabPanel = TabPanel;
Tabs.TabPanels = TabPanels;

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultActiveIndex: PropTypes.number,
  id: PropTypes.string,
  onChange: PropTypes.func,
};

export { Context, Tabs };
