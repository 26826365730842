/* eslint-disable @typescript-eslint/no-explicit-any */
import defaultStore from 'store/configureStore';
import {
  getCurrentLocale,
  getCountryCode,
  getPaymentId,
  getRecipientId,
  getPlatform,
} from 'selectors';
import { sessionStorage } from 'utils/storage';
import { cookies } from 'utils/cookies/cookies';
import {
  PERMANENT_COOKIE_NAME,
  SESSION_ID_NAME,
  HEAP_ENVS,
  LOGGER_URL,
} from 'constants/index';
import type { Store } from 'redux';
import type { RootState } from 'reducers/types';

type WindowWithHeap = typeof window & { heap: Heap };

let configuredStore: any = null;

const getFetchOptions = (payload: Record<string, unknown>) => {
  const body = JSON.stringify(payload);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const method = 'POST';

  return { body, headers, method };
};

const log = (
  id: string,
  userProperties?: Record<string, unknown>,
  errorDetails?: unknown,
) => {
  fetch(
    LOGGER_URL,
    getFetchOptions({
      id: `PAYEX_${id}`,
      userProperties,
      errorDetails,
      cookiesEnabled: navigator.cookieEnabled,
    }),
  );
};

const getUserProperties = (state?: RootState) => {
  let userProperties = {};

  if (state) {
    userProperties = {
      browser_language: getCurrentLocale(state),
      country: getCountryCode(state),
      portal_code: getRecipientId(state),
      platform: getPlatform(state),
      paymentid: getPaymentId(state) || null,
    };
  }

  return {
    ...userProperties,
    peer_tracker_id: cookies.read(PERMANENT_COOKIE_NAME),
    session_id: sessionStorage.getItem(SESSION_ID_NAME),
    source_url: window.location.href,
    user_agent: navigator.userAgent,
    referrer: document.referrer,
  };
};

interface Heap {
  track: (event: string, properties?: Record<string, unknown>) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Record<string, unknown>) => void;
  addEventProperties: (properties: Record<string, unknown>) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

const heapAgent = {
  configure: function (store: Store) {
    configuredStore = store;
  },

  initialize: () => {
    try {
      const store = configuredStore || defaultStore;
      const state = store.get()?.getState() ?? {};

      if (!isEnabled) return;

      console.debug('Initializing Heap agent');

      const heap = (window as WindowWithHeap).heap;

      if (typeof heap !== 'undefined' && heap) {
        log('[PAAR-4486] Heap initialization starts', { heap: typeof heap });
        const attrs = {
          peer_tracker_id: cookies.read(PERMANENT_COOKIE_NAME),
          session_id: sessionStorage.getItem(SESSION_ID_NAME),
          source_url: window.location.href,
          browser_language: getCurrentLocale(state),
          country: getCountryCode(state),
          portal_code: getRecipientId(state),
          user_agent: navigator.userAgent,
          referrer: document.referrer,
          platform: getPlatform(state),
          paymentid: getPaymentId(state) || null,
        };

        attrs.peer_tracker_id && heap.identify(attrs.peer_tracker_id);
        heap.addUserProperties(attrs);
        log(
          '[PAAR-4486] Heap initialization completed.',
          getUserProperties(state),
        );

        console.debug('Heap agent initialized.');
      } else {
        console.debug('Heap agent not found.');
        log('[PAAR-4486] Heap not found', getUserProperties(state));
      }
    } catch (error) {
      console.debug('Cannot initialize Heap agent', error);
      log(
        '[PAAR-4486] Heap not initialized',
        getUserProperties(),
        error instanceof Error ? (error as Error).message : error,
      );
    }
  },

  trackEvent: (eventName: string, metadata: Record<string, unknown>) => {
    try {
      const store = configuredStore || defaultStore;
      const state = store.get()?.getState() ?? {};

      console.debug(`Tracking heap event ${eventName}`);

      const heap = (window as WindowWithHeap).heap;

      if (typeof heap !== 'undefined' && heap) {
        const eventBody = {
          id: cookies.read(PERMANENT_COOKIE_NAME),
          account_id: getRecipientId(state),
          ...metadata,
        };

        console.debug('Tracking heap event with body', eventBody);

        heap.track(eventName, eventBody);
      } else {
        console.error('Cannot track event. Heap not found.');
        log(
          '[PAAR-4486] Cannot track event, heap not found',
          getUserProperties(state),
        );
      }
    } catch (e) {
      console.error('Error sending Heap custom event', e);
      log(
        '[PAAR-4486] Error sending heap custom event',
        undefined,
        e instanceof Error ? (e as Error).message : e,
      );
    }
  },
};

const isEnabled = HEAP_ENVS.includes(window.location.hostname);

export { heapAgent };
