import date from 'utils/date';
import isEmpty from 'lodash/isEmpty';
import { refreshToken, logout } from 'actions';
import { isLoggedIn, getRefreshToken, isEmbedded } from 'selectors';
import {
  AUTH_LOCALSTORAGE_KEY,
  LOGIN_EXPIRATION_IN_MINUTES,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_REQUEST,
  REFRESH_TOKEN,
  IMPERSONATE_USER_SUCCESS,
} from '../../../constants';
import { localStorage } from 'utils/storage';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { RootState } from 'reducers/types';
import { PayexDispatch } from 'store/configureStore';
import { getStoredRefreshToken } from '../../storage';

type AccessToken = {
  accessToken: string;
  idleTimeout: string;
};

type Store = MiddlewareAPI<PayexDispatch, RootState>;

const Authentication: Middleware<unknown, RootState> =
  store => next => async action => {
    const accessToken = getUserAuthentication();

    if (isLoginSuccess(action)) setAccessToken(action);
    if (shouldLogOut(store, action, accessToken))
      // @ts-expect-error for some reason we cannot type the action
      await store.dispatch(logout());
    // @ts-expect-error for some reason we cannot type the action
    if (isLoggedInOnAnotherTab(store, action)) store.dispatch(refreshToken());

    updateExpiration(accessToken);
    return next(action);
  };

const getUserAuthentication = () => {
  const userAuthentication = localStorage.getItem(AUTH_LOCALSTORAGE_KEY);

  if (isEmpty(userAuthentication)) return {};

  return JSON.parse(userAuthentication as string);
};

const expiration = () => {
  return date.future(
    LOGIN_EXPIRATION_IN_MINUTES,
    date.MINUTES as moment.DurationInputArg2,
  );
};

const setAccessToken = (action: AnyAction) => {
  const {
    payload: { accessToken },
  } = action;
  const token = JSON.stringify({
    accessToken,
    idleTimeout: expiration(),
  });

  localStorage.setItem(AUTH_LOCALSTORAGE_KEY, token);
};

const isExpired = (accessToken?: AccessToken) => {
  if (isEmpty(accessToken)) return false;
  const currentDate = date.now();
  const expirationDate = accessToken.idleTimeout;
  return date.isBefore(expirationDate, currentDate);
};

const isLoginSuccess = (action: AnyAction) =>
  action.type === (LOGIN_USER_SUCCESS || IMPERSONATE_USER_SUCCESS);

const updateExpiration = (accessToken?: AccessToken) => {
  if (!isEmpty(accessToken)) {
    accessToken.idleTimeout = expiration();
    localStorage.setItem(AUTH_LOCALSTORAGE_KEY, JSON.stringify(accessToken));
  }
};

const hasBeenLoggedOutPreviously = (state: RootState, action: AnyAction) => {
  if (action.type === LOGOUT_USER_SUCCESS) return false;
  if (action.type === LOGOUT_USER_REQUEST) return false;

  const hasStoredRefreshToken = !!getStoredRefreshToken();
  const hasRefreshToken = getRefreshToken(state);
  const loggedIn = isLoggedIn(state);

  return !!(!hasStoredRefreshToken && (hasRefreshToken || loggedIn));
};

const shouldLogOut = (
  store: Store,
  action: AnyAction,
  accessToken: AccessToken,
) => {
  const state = store.getState();
  if (isEmbedded(state)) {
    return false;
  }

  return (
    hasBeenLoggedOutPreviously(state, action) ||
    (!isLogoutSuccess(action) && isExpired(accessToken))
  );
};

const isLoggedInOnAnotherTab = (store: Store, action: AnyAction) => {
  if (action.type === REFRESH_TOKEN) return false;

  const state = store.getState();
  const hasRefreshToken = getRefreshToken(state);
  const isNotLoggedIn = !isLoggedIn(state);

  return hasRefreshToken && isNotLoggedIn;
};

const isLogoutSuccess = ({ type }: { type: string }) => {
  const logoutActions = [LOGOUT_USER_SUCCESS, LOGOUT_USER_REQUEST];
  return logoutActions.includes(type);
};

export { Authentication };
