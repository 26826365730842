import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSelectedOfferFees } from 'selectors';
import type { RootState } from 'reducers/types';

const SelectedOfferFeesComponent = ({ fees, render }) => {
  const { value, currency } = fees;

  return render({ value, currency });
};

SelectedOfferFeesComponent.propTypes = {
  fees: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
};

const mapStateToProps = (state: RootState) => ({
  fees: getSelectedOfferFees(state),
});

const SelectedOfferFees = connect(mapStateToProps)(SelectedOfferFeesComponent);

export { SelectedOfferFees };
