import { createMapper } from './mappers/create';
import fetchJson from 'utils/fetchJson';
import { CheckoutSession } from 'models';

export type CheckoutSessionType =
  | 'new_mandate'
  | 'card_tokenization_for_installment'
  | 'card_tokenization_for_one_off';

export type CreateSessionPayload = {
  sessionType: CheckoutSessionType;
  recipientId?: string;
  paymentMethodToken?: string;
};

export type CreateSessionParams = CreateSessionPayload & {
  accessToken?: string;
  locale: string;
};

type SessionsService = {
  create: (params: CreateSessionParams) => Promise<CheckoutSession>;
};

export const sessions: SessionsService = {
  create: async ({ accessToken, locale, ...rest }) => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(createMapper.mapToBackend(rest)),
    };
    const options = {} as Record<string, unknown>;

    if (accessToken) {
      options.headers = { Authorization: `Bearer ${accessToken}` };
    }

    return fetchJson(`/sessions?locale=${locale}`, {
      ...requestOptions,
      ...options,
    });
  },
};
