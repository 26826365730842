import fetchJson from 'utils/fetchJson';
import { PAYEX_URL } from 'constants/index';
import type { Order } from 'models';

type FetchParams = {
  paymentId: string;
  token: string;
  locale: string;
};

type TrackingPageData = {
  order: Order;
};

export type TrackingPage = {
  fetch: ({
    paymentId,
    token,
    locale,
  }: FetchParams) => Promise<Record<string, unknown>>;
  data: (orderId: string, orderToken: string) => Promise<TrackingPageData>;
};

const trackingPage: TrackingPage = {
  fetch: ({ paymentId, token, locale }) => {
    const url = `${PAYEX_URL}/tracking_page/${paymentId}?token=${token}&locale=${locale}`;

    return fetchJson(url);
  },
  data: (orderId, orderToken) => {
    const url = `${PAYEX_URL}/tracking_page/data/${orderId}?token=${orderToken}`;

    return fetchJson(url);
  },
};

export default trackingPage;
