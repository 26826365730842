import React, { ChangeEvent, useState } from 'react';
import { Accept } from 'components/Accept/Accept';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { Field } from 'models';

type CheckboxFieldProps = {
  field: Field;
  handleBlur?: (name: string) => void;
  handleChange?: (name: string, checked: boolean) => void;
};

const CheckboxField = ({
  field,
  handleBlur,
  handleChange,
}: CheckboxFieldProps) => {
  const { defaultValue, error, label, id, readOnly, required } = field;

  const [checked, setChecked] = useState(!!defaultValue);

  const checkboxChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setChecked(checked);
    handleChange?.(id, checked);
  };

  return (
    <Accept
      required={required}
      checked={checked}
      error={error}
      name={id}
      onBlur={() => handleBlur?.(id)}
      onChange={checkboxChange}
      readOnly={readOnly}
    >
      <span dangerouslySetInnerHTML={createMarkup(label)} />
    </Accept>
  );
};

export { CheckboxField };
