import trackingPage from './trackingPage';
import { auth } from './auth/auth';
import { extraFees } from './extraFees/extraFees';
import { offers } from './offers/offers';
import { payments } from './payments/payments';
import { polling } from './polling/polling';

export const payex = {
  auth,
  extraFees,
  offers,
  payments,
  polling,
  trackingPage,
};

export type PayexService = typeof payex;

export default { trackingPage, payments, polling, offers };
