import React, { useState, Children, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'icons';
import classNames from 'classnames';

import './Carousel.scss';

const Carousel = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const numChildren = React.Children.count(children);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentIndex(prevIndex => {
      const nextIndex = prevIndex + 1;
      return nextIndex < Children.count(children) ? nextIndex : prevIndex;
    });
  };

  const goToPrevSlide = () => {
    setCurrentIndex(prevIndex => {
      const nextIndex = prevIndex - 1;
      return nextIndex >= 0 ? nextIndex : prevIndex;
    });
  };

  const isAtBeginning = currentIndex === 0;
  const isAtEnd = currentIndex === Children.count(children) - 1;

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.key === 'ArrowRight' && !isAtEnd) {
        goToNextSlide();
      } else if (event.key === 'ArrowLeft' && !isAtBeginning) {
        goToPrevSlide();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentIndex, isAtBeginning, isAtEnd]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames('Carousel', className)}>
      <div className="Carousel-slideContainer">
        <div
          className="Carousel-slides"
          style={{
            transform: `translateX(${-currentIndex * 100}%)`,
            transition: 'transform 0.5s cubic-bezier(0.26, 1.45, 0.45, 1.07)',
          }}
        >
          {Children.map(children, (child, index) => (
            <div
              className="Carousel-slide"
              aria-hidden={index !== currentIndex}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      <div className="Carousel-controls">
        <div className="Carousel-indicators">
          {[...Array(numChildren)].map((_, index) => (
            <button
              key={`small-button-${index}`}
              className={
                index === currentIndex
                  ? 'Carousel-indicator active'
                  : 'Carousel-indicator'
              }
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
        <div className="Carousel-buttons">
          <button
            onClick={goToPrevSlide}
            className={classNames({ disabled: isAtBeginning })}
            data-testid="prevBotton"
          >
            <ChevronLeft />
          </button>
          <button
            onClick={goToNextSlide}
            className={classNames({ disabled: isAtEnd })}
            data-testid="nextBotton"
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

Carousel.displayName = 'Carousel';

export { Carousel };
