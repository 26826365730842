import { RootState } from 'reducers/types';
import { getRecipientById } from '../recipients';
import { getUIRefundStatus } from '../utils';
import has from 'lodash/has';

const ALLOWED_KYC_STATUSES = ['requested', 'invalid', 'received', 'passed'];
const CLOSED_REFUND_STATUSES = ['cancelled', 'finished'];

export const getRefunds = (state: RootState) => {
  const {
    entities: {
      refunds: { byId, ids },
    },
  } = state;

  return ids.map(id => {
    const refund = byId[id];
    const {
      sender: { id: senderId },
    } = refund;
    const recipientWithAllInfo = getRecipientById(state, senderId);
    const UIStatus = getUIRefundStatus(refund);

    return {
      ...refund,
      status: UIStatus,
      sender: recipientWithAllInfo,
      amount: getAmountById(state, refund.amount),
      amountTo: getAmountById(state, refund.amountTo),
    };
  });
};

export const getAmountById = (state: RootState, id: string) => {
  const {
    entities: {
      refunds: { amounts },
    },
  } = state;

  const amount = amounts[id];
  return {
    ...amount,
    currency: getCurrencyById(state, amount.currency),
  };
};

export const getCurrencyById = (state: RootState, id: string) => {
  const {
    entities: {
      refunds: { currencies },
    },
  } = state;

  return currencies[id];
};

export const getKyc = (state: RootState) => {
  const openedRefunds = getOpenedRefunds(state);
  const refund = openedRefunds.find(refund => has(refund, 'kyc'));

  if (!refund) return null;

  const { kyc } = refund;
  if (!ALLOWED_KYC_STATUSES.includes(kyc.status)) return null;

  const { id: refundId } = refund;
  return { ...kyc, ...getKycEntity(state), refundId };
};

const getKycEntity = (state: RootState) => {
  const {
    entities: { kyc },
  } = state;

  return kyc;
};

const getOpenedRefunds = (state: RootState) => {
  const refunds = getRefunds(state);

  return refunds.filter(
    refund => !CLOSED_REFUND_STATUSES.includes(refund.status),
  );
};
