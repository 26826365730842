import React, { ChangeEvent, useEffect } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { setReceiveWhatsappNotification } from 'actions';
import { shouldReceiveWhatsappNotification } from 'selectors';
import { useTranslations } from 'utils';
import { ReactComponent as WhatsappIcon } from 'icons/ico-whatsapp.svg';

const CheckWhatsappNotification = () => {
  const i18n = useTranslations();
  const dispatch = useDispatch();
  const receiveWhatsappNotification = useSelector(
    shouldReceiveWhatsappNotification,
  );

  const handleCheckboxChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    dispatch(setReceiveWhatsappNotification(checked));
  };

  useEffect(() => {
    dispatch(setReceiveWhatsappNotification(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex" data-testid="checkWhatsappNotification">
      <Checkbox
        name="receiveWhatsappNotification"
        label=<div>
          <span className="mr-2">
            {i18n.t(
              'senderInformation.receiveWhatsappNotification.checkboxText',
            )}
          </span>
          <WhatsappIcon />
        </div>
        checked={receiveWhatsappNotification}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

export { CheckWhatsappNotification };
