import {
  SENDER_ADDRESS_FIELD,
  SENDER_COUNTRY_FIELD,
  WHITELISTED_COUNTRIES_FOR_PO_BOX,
  WHITELISTED_RECIPIENTS_FOR_PO_BOX,
} from 'constants/index';
import { clientValidate } from 'utils/clientValidate/clientValidate';
import { constraints } from 'utils/clientValidate/constraints';
import { getFieldById, getFieldValue, getRecipientId } from 'selectors';
import { setValidationErrors, trackFieldsErrors } from 'actions';
import isEmpty from 'lodash/isEmpty';
import type { Field, FieldValue, Section } from 'models';
import type { PayexThunkAction } from 'store/configureStore';

const generateConstraints = ({
  fieldOrFields,
}: {
  fieldOrFields: Field[] | Field;
}) => {
  return constraints.generate({
    fieldOrFields,
    invalidMsg: 'form.errors.invalid',
    requiredMsg: 'form.errors.required',
  });
};

export const validateSections =
  (sections: Section<Field>[], trackReasons = false): PayexThunkAction =>
  async dispatch => {
    const fields = sections.reduce(
      (fields, section) => fields.concat(section.fields),
      [] as Field[],
    );

    const fieldValues = fields.reduce((fieldValues, { id, defaultValue }) => {
      return defaultValue
        ? {
            ...fieldValues,
            [id]: defaultValue,
          }
        : fieldValues;
    }, {} as Record<string, FieldValue>);

    const fieldsConstraints = generateConstraints({ fieldOrFields: fields });

    try {
      await clientValidate.async(fieldValues, fieldsConstraints);

      const invalidFields = fields.filter(
        field => (field as Field).required && !(field as Field).isValid,
      );

      if (!isEmpty(invalidFields) && trackReasons) {
        throw invalidFields.reduce((acc, { id, error }) => {
          return { ...acc, [id]: [error] };
        }, {});
      }
      return true;
    } catch (errors) {
      let requiredFieldsErrors = {};
      const _errors = errors as Record<string, unknown>;

      if (trackReasons) {
        requiredFieldsErrors = Object.keys(_errors).reduce(
          (acc, id) => ({
            ...acc,
            [id]: fieldValues[id] ? 'Error' : 'Empty field',
          }),
          {} as Record<string, string>,
        );
      }
      dispatch(setValidationErrors(_errors));
      dispatch(trackFieldsErrors(requiredFieldsErrors));
      return false;
    }
  };

export const validateSectionField =
  (id: string): PayexThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const field = getFieldById(state, id);

    const fieldConstraints = generateConstraints({ fieldOrFields: field });

    try {
      await clientValidate.async(
        { [id]: field.defaultValue },
        fieldConstraints,
      );
      return true;
    } catch (errors) {
      dispatch(setValidationErrors(errors as Record<string, unknown>));
      return false;
    }
  };

const isRecipientWhitelisted = (recipient: string) => {
  return WHITELISTED_RECIPIENTS_FOR_PO_BOX.includes(recipient);
};

const isCountryWhitelisted = (country: string) => {
  return WHITELISTED_COUNTRIES_FOR_PO_BOX.includes(country);
};

const isPoBox = (address: string) => {
  const poBoxPattern =
    /^(((\d*\s?)?((p[. ]?o[. ]?)|(unit\s*\d*\s*box)|(post office box)|(office\s?box)|(p[. ]?o[. ]?\s?box)|(box)|(p[. ]?o[. ]?b[. ]?))(\s?\d*)?))(($)|(\s.*$))/gim;
  return poBoxPattern.test(address);
};

export const validateSenderAddress =
  (): PayexThunkAction => async (dispatch, getState) => {
    const state = getState();
    const value = getFieldValue(state, SENDER_ADDRESS_FIELD) as string;
    const country = getFieldValue(state, SENDER_COUNTRY_FIELD) as string;
    const recipient = getRecipientId(state);

    if (
      isPoBox(value) &&
      !isCountryWhitelisted(country) &&
      !isRecipientWhitelisted(recipient)
    ) {
      const errors = {
        [SENDER_ADDRESS_FIELD]: ['errors.po_box_not_accepted'],
      };
      dispatch(setValidationErrors(errors));
      return false;
    }

    return true;
  };
