import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrency } from 'selectors';
import { FormattedAmount } from '../FormattedAmount';
import type { RootState } from 'reducers/types';

function RoundComponent({ getCurrency, currency, value, ...rest }) {
  const { unitsToRound, subunitToUnit } = getCurrency(currency);
  const roundedSubunits = unitsToRound * subunitToUnit;
  const roundedValue = Math.round(value * roundedSubunits) / roundedSubunits;

  return (
    <FormattedAmount
      getCurrency={getCurrency}
      currency={currency}
      value={roundedValue}
      {...rest}
    />
  );
}

RoundComponent.propTypes = {
  currency: PropTypes.string.isRequired,
  getCurrency: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state: RootState) => ({
  getCurrency: code => getCurrency(state, code),
});

const Round = connect(mapStateToProps)(RoundComponent);

export { Round };
