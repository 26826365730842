import React from 'react';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import { RecurringSelectedOffer } from 'models';
import { useSelector } from 'react-redux';
import { getCurrentCurrency, getSelectedOffer } from 'selectors';
import { useTranslations } from 'utils';
import date from 'utils/date';

function useInstallments() {
  const offer = useSelector(getSelectedOffer) as RecurringSelectedOffer;
  const currency = useSelector(getCurrentCurrency);
  const installments = offer.installments || [];
  const sortedInstallments = [...installments].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  const total = sortedInstallments.reduce((acc, installment) => {
    return acc + installment.amount;
  }, 0);

  return { installments, total, currency };
}

function RecurringBreakdown() {
  const i18n = useTranslations();
  const { installments, currency, total } = useInstallments();

  return (
    <div
      id="payer_information-description"
      className="FlexGrid FlexGrid--fromXSmall-2"
    >
      <div>
        <dt>{i18n.t('recurringReceivableBreakdown.numberOfInstallments')}</dt>
        <dd data-hj-suppress data-testid="interval">
          {installments.length}
        </dd>
      </div>

      <div>
        <dt>{i18n.t('recurringReceivableBreakdown.totalAmount')}</dt>
        <dd
          data-hj-suppress
          className="recurringReceivableBreakdown-amount"
          data-testid="amount"
        >
          <FormattedAmount value={total} currency={currency.code} />
        </dd>
      </div>

      <div>
        <dt>{i18n.t('recurringReceivableBreakdown.first')}</dt>
        <dd data-hj-suppress data-testid="starts">
          {date.formatToBrowserLocale(installments[0].date)}
        </dd>
      </div>

      <div>
        <dt>{i18n.t('recurringReceivableBreakdown.last')}</dt>
        <dd data-hj-suppress data-testid="ends">
          {date.formatToBrowserLocale(
            installments[installments.length - 1].date,
          )}
        </dd>
      </div>
    </div>
  );
}

export { RecurringBreakdown };
