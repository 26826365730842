import type { RootState } from 'reducers/types';

export const shouldOpenTaxModal = (state: RootState) => {
  return state.ui.modals.tax.isVisible && !state.ui.modals.tax.isConfirmed;
};

export const isDuplicatedTaxPaymentModalOpen = (state: RootState) => {
  return state.ui.modals.duplicatedTaxPayment.isVisible;
};

export const isPaymentProofModalOpen = (state: RootState) => {
  return state.ui.modals.paymentProof.isVisible;
};

export const isCacellationFailedModalOpen = (state: RootState) => {
  return state.ui.modals.cancellationFailed.isVisible;
};

export const isPaymentCreatedModalOpen = (state: RootState) => {
  return state.ui.modals.remainingBalancePaymentCreated.isVisible;
};
