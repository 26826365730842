import { combineReducers } from 'redux';
import {
  CANCEL_PAYMENT_FAILURE,
  CLOSE_CANCEL_PAYMENT_MODAL,
} from 'constants/index';

type CancellationFailedAction = {
  type: typeof CANCEL_PAYMENT_FAILURE | typeof CLOSE_CANCEL_PAYMENT_MODAL;
};

export const isVisible = (state = false, action: CancellationFailedAction) => {
  switch (action.type) {
    case CLOSE_CANCEL_PAYMENT_MODAL:
      return false;
    case CANCEL_PAYMENT_FAILURE:
      return true;
    default:
      return state;
  }
};

export default combineReducers({ isVisible });
