import { useSelector } from 'react-redux';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { MAINTENANCE_MODE } from 'constants/index';
import { getRecipientId } from 'selectors';

function useMaintenanceMode() {
  const isFeatureEnabled = useFeatureFlags(MAINTENANCE_MODE);
  const recipientId = useSelector(getRecipientId);

  return isFeatureEnabled && recipientId !== undefined && recipientId !== 'PTU';
}

export { useMaintenanceMode };
