/* eslint-disable camelcase */
import { FEEDBACK_ENDPOINT_URL } from 'constants/index';
import { SupportedLocalesKeys } from 'utils';

type User = {
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
  language: SupportedLocalesKeys;
};

type FeedbackPayload = {
  value: number;
  comment?: string;
  payment: string;
  user: User;
  recipient_name?: string;
};

export const feedback = {
  send: (payload: FeedbackPayload) => {
    const isEnvProduction = process.env.NODE_ENV === 'production';
    if (!isEnvProduction) return;

    sendFeedbackToEndpoint(payload);
  },
};

const sendFeedbackToEndpoint = ({ comment = '', ...props }) => {
  const method = 'POST';
  const payload = { ...props, comment };
  const body = JSON.stringify(payload);
  const options = { body, method };

  fetch(FEEDBACK_ENDPOINT_URL, options);
};
