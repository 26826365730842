import { SET_PAYOUTS } from 'constants/index';
import type { Payout } from 'models';

type PayoutsAction = {
  type: typeof SET_PAYOUTS;
  payload: Payout[];
};

export const payouts = (state: Payout[] = [], action: PayoutsAction) => {
  switch (action.type) {
    case SET_PAYOUTS:
      const { payload: payouts } = action;

      return payouts;
    default:
      return state;
  }
};

export default payouts;
