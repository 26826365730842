import React, { useEffect, useState } from 'react';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';
import { useTranslations } from 'utils/translations/useTranslations';
import type { CountryForSelect } from 'models';

const EMPTY_VALUE = '';

type CountrySelectionProps = {
  countries: CountryForSelect[];
  value: string;
  label: string;
  name: string;
  error?: string | boolean;
  hint?: string;
  onBlur?: (name: string) => void;
  onChange: (name: string, value: string) => void;
  'data-testid': string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
};

function CountrySelection({
  countries,
  value,
  label,
  name,
  error,
  hint,
  'data-testid': dataTestid,
  onBlur,
  onChange,
  required,
  readOnly,
  disabled,
}: CountrySelectionProps) {
  const [selectedValue, setSelectedValue] = useState(value);
  const [readOnlyState, setReadOnlyState] = useState(!!readOnly);

  const autocompleteHandleChange = (value: string) => {
    setSelectedValue(value);
    onChange(name, value);
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    const found = countries.find((country: CountryForSelect) => {
      if (country.value !== value) {
        return false;
      }

      return true;
    });

    if (found) {
      autocompleteHandleChange(value);
      return;
    }

    setReadOnlyState(false);
    autocompleteHandleChange(EMPTY_VALUE);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const i18n = useTranslations();

  return (
    <Autocomplete
      name={name}
      onChange={autocompleteHandleChange}
      onBlur={() => onBlur?.(name)}
      options={countries}
      data-testid={dataTestid}
      selected={selectedValue}
      error={error}
      hint={hint}
      required={!!required}
      readOnly={readOnlyState}
      disabled={!!disabled}
    >
      <div>
        <Autocomplete.Input data-testid={dataTestid} placeholder={label} />
        <Autocomplete.Options />
      </div>
      {hint && (
        <Autocomplete.Hint className="FormGroup-hint">{hint}</Autocomplete.Hint>
      )}
      {error && typeof error === 'string' && (
        <Autocomplete.Error className="FormGroup-feedback">
          <VisuallyHidden as="span">{label}</VisuallyHidden>
          {i18n.t(error)}
        </Autocomplete.Error>
      )}
    </Autocomplete>
  );
}

export { CountrySelection };
