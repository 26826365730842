import React from 'react';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { FieldInterface } from '../Field';

type DatepickerFieldProps = {
  field: FieldInterface;
  handleChange: (name: string, value?: string) => void;
  handleBlur: (name: string) => void;
};

const DatepickerField = ({
  field,
  handleBlur,
  handleChange,
}: DatepickerFieldProps) => {
  const { defaultValue, error, help, label, id, readOnly, required } = field;

  return (
    <DatePicker
      id={id}
      error={error}
      hint={help}
      label={label}
      name={id}
      onBlur={handleBlur}
      onChange={handleChange}
      readOnly={readOnly}
      required={required}
      value={defaultValue}
    />
  );
};

export { DatepickerField };
