import React, {
  FocusEventHandler,
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Field, useMoneyInput } from '@flywire/react-headlessui';
import classNames from 'classnames';
import { useTranslations } from 'utils/translations/useTranslations';
import { VisuallyHidden } from 'components/ui/VisuallyHidden/VisuallyHidden';

import './MoneyInput.scss';

type MoneyInputProps = {
  currencySymbol?: string;
  decimalMark?: string;
  error?: string | boolean;
  help?: string;
  label?: string;
  name: string;
  subunitToUnit?: number;
  symbolFirst?: boolean;
  thousandsSeparator?: string;
  value?: number;
  onChange?: (name: string, value: number) => void;
  onBlur?: (name: string, value: number) => void;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onBlur' | 'onChange' | 'value' | 'name'
>;

const MoneyInput: FunctionComponent<MoneyInputProps> = ({
  autoComplete = 'off',
  currencySymbol = '$',
  decimalMark = '.',
  disabled = false,
  error,
  help,
  label,
  maxLength = 10,
  name,
  id = name,
  placeholder = '0.0',
  readOnly = false,
  required,
  subunitToUnit = 100,
  symbolFirst = true,
  thousandsSeparator = ',',
  value,
  onChange,
  onBlur,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [amount, setAmount] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const isEnabled = !disabled && !readOnly;

  const toCents = (value: number) => {
    const decimals = subunitToUnit > 0 ? Math.log10(subunitToUnit) : 0;
    return Number.parseInt((value * subunitToUnit).toFixed(decimals));
  };

  const fromCents = (value?: number) => {
    return value !== undefined ? value / subunitToUnit : undefined;
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = evt => {
    if (isEnabled) {
      const {
        target,
        target: { value },
      } = evt;

      target.setSelectionRange(0, value.length);
      setIsFocused(true);
    }
  };

  const handleValueChange = (value: number) => {
    onChange?.(name, toCents(value));
  };

  const handleValueBlur = (value: number) => {
    const valueInCents = toCents(value);
    setIsFocused(false);
    setAmount(valueInCents);
    onBlur?.(name, valueInCents);
  };

  const { inputProps } = useMoneyInput({
    ...props,
    autoComplete,
    decimalMark,
    disabled,
    required,
    thousandsSeparator,
    subunitToUnit,
    initialValue: fromCents(amount),
    onFocus: handleFocus,
    onValueBlur: handleValueBlur,
    onValueChange: handleValueChange,
  });

  const renderSymbol = () => (
    <div
      className="MoneyInput-item MoneyInput-currency"
      data-testid={`${name}-currency`}
    >
      {currencySymbol}
    </div>
  );

  const handleLabelClick = () => {
    if (isEnabled && inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    !isFocused && amount !== 0 && setAmount(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const i18n = useTranslations();

  return (
    <Field
      id={id}
      className={classNames('MoneyInput', {
        'MoneyInput-focused': isFocused,
        'MoneyInput-hasValue': placeholder || amount !== undefined,
      })}
    >
      <Field.Label
        htmlFor={name}
        className={classNames(
          'MoneyInput-label',
          symbolFirst ? 'MoneyInput-label-prefix' : 'MoneyInput-label-suffix',
        )}
        onClick={handleLabelClick}
      >
        <>
          {label}
          {required && <span aria-hidden> *</span>}
        </>
      </Field.Label>
      <div className="MoneyInput-group">
        {symbolFirst && renderSymbol()}
        <Field.Input
          ref={inputRef}
          className={classNames(
            'MoneyInput-item MoneyInput-input',
            error !== undefined && 'MoneyInput-input-error',
          )}
          aria-invalid={!!error}
          aria-required={required}
          placeholder={placeholder}
          id={id}
          name={name}
          maxLength={maxLength}
          readOnly={readOnly}
          required={required}
          data-testid={`MoneyInput-${name}`}
          {...props}
          {...inputProps}
        />
        {!symbolFirst && renderSymbol()}
      </div>
      {error && typeof error === 'string' && (
        <Field.Error data-testid={`${name}-error`} className="MoneyInput-error">
          <VisuallyHidden as="span">{label}</VisuallyHidden>
          {i18n.t(error)}
        </Field.Error>
      )}
      {help && (
        <Field.Hint data-testid={`${name}-hint`} className="MoneyInput-hint">
          {help}
        </Field.Hint>
      )}
    </Field>
  );
};

export { MoneyInput, MoneyInputProps };
