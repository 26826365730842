import { errorNotifier } from 'utils/errorNotifier';
import crypto from 'utils/crypto';
import type { Instruction } from 'models';

function isValid(
  embeddedScript: Instruction,
  scriptSignature: Instruction,
  publicKey: string,
) {
  if (!publicKey) return false;

  return crypto.validate(
    embeddedScript.value,
    scriptSignature.value,
    publicKey,
  ) as boolean;
}

export function decryptScript(
  embeddedScript: Instruction,
  scriptSignature: Instruction,
  publicKey: string,
) {
  let parsed: { js: string };

  try {
    const plainEmbeddedScript = crypto.hexToString(embeddedScript.value);
    parsed = JSON.parse(plainEmbeddedScript);
  } catch (error) {
    errorNotifier.notify(error);
    return;
  }

  const { js: script } = parsed;
  const validScript = isValid(embeddedScript, scriptSignature, publicKey);

  if (validScript) return script;
}
