import type { RootState } from 'reducers/types';

export const greaterThan10kUSD = (state: RootState) => {
  const {
    ui: {
      exchange: { greaterThan10kUSD },
    },
  } = state;

  return greaterThan10kUSD;
};
