export const AMOUNT_FIELD = 'amount';
export const CAMPUS_FIELD = 'campus';
export const CHINESE_FIELD_ID = 'cny_id_number';
export const CHINESE_FIELD_NAME = 'cny_id_name';
export const ENCRYPTED_FIELDS = [
  'cny_id_number',
  'payer_passport_id',
  'student_cny_id_number',
  'student_passport_id',
];
export const INTERVAL_FIELD = 'interval';
export const IS_RECURRING_FIELD = 'isRecurring';
export const SCHEDULE_FIELD = 'schedule';
export const IS_LEGACY_RECURRING_FIELD = 'isLegacyRecurring';
export const OFFER_FIELD = 'offer';
export const OFFER_READ_ONLY_FIELDS = [
  'cny_id_name',
  'cny_id_number',
  'pan_name',
  'pan_number',
  'payer_passport_id',
  'payer_relationship',
  'student_passport_id',
];
export const OFFER_RELATIONSHIP_STUDENT = 'Self (student)';
export const OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN = 'Educational Loan';
export const OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_CREDILA =
  'education_loan_credila';
export const OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_PUBLIC_AND_PRIVATE_SECTOR =
  'education_loan_bank';
export const OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_AND_SAVINGS =
  'education_loan_and_savings';
export const OFFER_SOURCE_OF_FUNDS_EDUCATION_LOAN_OTHER_INSTITUTIONS =
  'education_loan_other';
export const PAN_NAME_FIELD = 'pan_name';
export const PAN_NUMBER_FIELD = 'pan_number';
export const PAYER_PASSPORT_FIELD_ID = 'payer_passport_id';
export const PAYMENT_ITEM = 'payment_item';
export const PROMOTIONS_OPT_IN_FIELD = 'promotions_opt_in';
export const RESET_VALIDATION_ERRORS = 'RESET_VALIDATION_ERRORS';
export const RECIPIENT_FIELD = 'recipient';
export const SENDER_ADDRESS_FIELD = 'sender_address1';
export const SENDER_COUNTRY_FIELD = 'sender_country';
export const SENDER_EMAIL_FIELD = 'sender_email';
export const SENDER_FIRST_NAME_FIELD = 'sender_first_name';
export const SENDER_LAST_NAME_FIELD = 'sender_last_name';
export const SENDER_PHONE_FIELD = 'sender_phone';
export const SET_DEFAULT_FIELD_VALUES = 'SET_DEFAULT_FIELD_VALUES';
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const STUDENT_CHINESE_FIELD_ID = 'student_cny_id_number';
export const STUDENT_FIRST_NAME = 'student_first_name';
export const STUDENT_ID = 'student_id';
export const STUDENT_LAST_NAME = 'student_last_name';
export const STUDENT_MIDDLE_NAME = 'student_middle_name';
export const STUDENT_PASSPORT_FIELD_ID = 'student_passport_id';
export const STUDENT_CNY_ID_NUMBER = 'student_cny_id_number';
export const TERMS_ACCEPTED_FIELD = 'are_terms_accepted';
export const TERMS_FIELD = 'terms';
export const UPDATE_READ_ONLY_FIELDS = 'UPDATE_READ_ONLY_FIELDS';
export const VALIDATE_FIELDS_REQUEST = 'VALIDATE_FIELDS_REQUEST';
export const VALIDATE_FIELDS_SUCCESS = 'VALIDATE_FIELDS_SUCCESS';
