import React from 'react';
import { Header } from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import { Heading } from 'components/Heading/Heading';
import { ReactComponent as RemoveCircle } from 'icons/remove-circle.svg';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils';

const InvalidRecipient = () => {
  const i18n = useTranslations();

  return (
    <div className="Site">
      <Header />
      <section className="ErrorPage">
        <RemoveCircle />

        <Heading as="h1" size="huge" className="ErrorPage-title">
          {i18n.t('invalidRecipient.title')}
        </Heading>

        <p className="fontSize-lg marginBottom-sm">
          <span className="marginRight-xs">
            {i18n.t('invalidRecipient.alert.title')}
          </span>
          <span
            dangerouslySetInnerHTML={createMarkup(
              i18n.t('invalidRecipient.alert.text'),
            )}
          />
        </p>
      </section>

      <Footer />
    </div>
  );
};

export { InvalidRecipient };
