import {
  EXCHANGE_LOGIN_TOKEN_SUCCESS,
  IMPERSONATE_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  REFRESH_TOKEN,
  RECOVER_REFRESH_TOKEN,
} from '../../../constants';
import type { Authentication } from 'models';

type AuthenticationAction =
  | { type: typeof LOGOUT_USER_SUCCESS }
  | {
      type: typeof EXCHANGE_LOGIN_TOKEN_SUCCESS | typeof RECOVER_REFRESH_TOKEN;
      payload: { refreshToken: string };
    }
  | {
      type: typeof REFRESH_TOKEN;
      payload: {
        accessToken: string;
        expiresIn: number;
      };
    }
  | {
      type: typeof IMPERSONATE_USER_SUCCESS | typeof LOGIN_USER_SUCCESS;
      payload: {
        authentication: Authentication;
      };
    };

const authentication = (
  state: Authentication = {},
  action: AuthenticationAction,
) => {
  switch (action.type) {
    case REFRESH_TOKEN:
    case EXCHANGE_LOGIN_TOKEN_SUCCESS:
    case RECOVER_REFRESH_TOKEN:
      const { payload } = action;
      return { ...state, ...payload };
    case LOGIN_USER_SUCCESS:
    case IMPERSONATE_USER_SUCCESS:
      const {
        payload: { authentication },
      } = action;
      return { ...state, ...authentication };
    case LOGOUT_USER_SUCCESS:
      return {};
    default:
      return state;
  }
};

export default authentication;
