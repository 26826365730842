import React, { useEffect, useState } from 'react';
import { useWizard } from 'components/Wizard/components/context';
import { ConnectedProps, connect } from 'react-redux';
import {
  getCurrency,
  getOrderAmount,
  getRecipient,
  getRecurringOffers,
  getRecurringOffersMetadata,
  getSenderCountry,
  isFetching as isFetchingSelector,
  isLoggedIn,
  isReadOnly,
} from 'selectors';
import {
  clearOfferFields,
  fetchOfferFromOrder,
  fetchOffersFromOrderIfNeeded,
  fetchPaymentsRecipient,
  fetchRecurringOffersFromOrder,
  fetchUserPayments,
  setFieldsValues,
  trackCheckMarkup,
  trackPaymentOption,
  updateOrderWithEntities,
} from 'actions';
import { SpinnerOverlay } from 'components/SpinnerOverlay/SpinnerOverlay';
import { UserAccessModal } from 'components/UserAccessModal/UserAccessModal';
import { OptionList } from 'components/PaymentOptions/components/OptionList/OptionList';
import { RootState } from 'reducers/types';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils';
import { Autocomplete } from 'components/Autocomplete/Autocomplete';
import { NoOptions } from 'components/PaymentOptions/components/LegacyRecurringPaymentOptions/components/NoOptions/NoOptions';
import { IS_RECURRING_FIELD, SCHEDULE_FIELD } from 'constants/index';
import type { RecurringOffersMetadata } from 'models';

import './RecurringPayment.scss';

const OFFER_FIELD = 'offer';
const INTERVAL_FIELD = 'interval';
const OFFERS_ENTITY = 'offers';

type RecurringPaymentProps = ConnectedProps<typeof connector> & {
  metadata?: RecurringOffersMetadata;
};

function RecurringPaymentComponent({
  amount,
  clearOfferFields,
  country,
  fetchOffer,
  fetchOffers,
  fetchPaymentsRecipient,
  fetchRecurringOffersFromOrder,
  fetchUserPayments,
  getCurrency,
  isFetching,
  isLoggedIn,
  metadata,
  options = [],
  readonly,
  recipient,
  setFieldsValues,
  trackCheckMarkup,
  trackPaymentOption,
  updateOrderWithEntities,
}: RecurringPaymentProps) {
  const i18n = useTranslations();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    numberOfInstallments = 0,
    configuredInstallments: installments = [],
    schedule,
    numberOfInstallmentsType,
  } = metadata || {};

  const selectedInstallment = `${numberOfInstallments}`;

  const [selectedOptionId, setSelectedOptionId] = useState<string>();
  const [alreadySelected, setAlreadySelected] = useState(false);
  const [selectingOffer, setSelectingOffer] = useState(false);
  const { goToNextStep } = useWizard();
  const noOptions = options.length === 0;
  const installmentOptions = installments.map(installment => ({
    label: `${installment}`,
    value: `${installment}`,
  }));

  const _options = options.map(option => ({
    ...option,
    interval: Number(selectedInstallment),
    chargeDay: schedule === 'pay_now' ? undefined : parseInt(schedule, 10),
  }));

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      fetchOffers();
    }

    return () => {
      didCancel = true;
    };
  }, [amount, country, recipient.id, i18n.currentLocale, fetchOffers]);

  async function selectOption(id: string) {
    if (readonly || alreadySelected) {
      return;
    }

    setSelectingOffer(true);
    setAlreadySelected(true);
    await clearOfferFields();

    await Promise.all([
      setFieldsValues({
        [OFFER_FIELD]: id,
        [INTERVAL_FIELD]: selectedInstallment,
        [IS_RECURRING_FIELD]: true,
        [SCHEDULE_FIELD]: schedule,
      }),
      fetchOffer(id),
    ]);

    setSelectingOffer(false);
    updateOrderWithEntities(OFFERS_ENTITY).then(goToNextStep);
  }

  async function onUserLogIn() {
    await Promise.all([fetchUserPayments(), fetchPaymentsRecipient()]);
  }

  function closeModal() {
    setIsModalVisible(false);
  }

  function handleOnLoginSuccess() {
    onUserLogIn();
    closeModal();
    selectedOptionId && selectOption(selectedOptionId);
  }

  function handleOnSignUpSuccess() {
    closeModal();
    selectedOptionId && selectOption(selectedOptionId);
  }

  const onOptionSelect = (id: string) => {
    if (!isLoggedIn) {
      setSelectedOptionId(id);
      setIsModalVisible(true);
      return;
    }

    selectOption(id);
  };

  const onInstallmentChange = (value: string) => {
    fetchRecurringOffersFromOrder({ installments: Number(value) });
  };

  return (
    <>
      {isFetching || selectingOffer ? (
        <SpinnerOverlay />
      ) : noOptions ? (
        <NoOptions />
      ) : (
        <div className="PaymentOptions">
          <UserAccessModal
            isOpen={isModalVisible}
            onClose={closeModal}
            onLoginSuccess={handleOnLoginSuccess}
            onSignUpSuccess={handleOnSignUpSuccess}
          />
          <div>
            {numberOfInstallmentsType !== 'regressive' && (
              <h2 className="PaymentPlan-title">
                {i18n.t('paymentPlan.title')}
              </h2>
            )}
            <p
              className="PaymentPlan-description"
              dangerouslySetInnerHTML={createMarkup(
                i18n.t('paymentPlan.description'),
              )}
            />

            {installments.length > 1 &&
              numberOfInstallmentsType !== 'regressive' && (
                <Autocomplete
                  className="PaymentPlan-installments"
                  name="installments"
                  onChange={onInstallmentChange}
                  options={installmentOptions}
                  data-testid="installmentsAutocomplete"
                  selected={selectedInstallment}
                  required={true}
                >
                  <Autocomplete.Input
                    data-testid="installmentsDropdown"
                    placeholder={
                      i18n.t('paymentPlan.installments.label') as string
                    }
                  />
                  <Autocomplete.Options />
                </Autocomplete>
              )}
          </div>
          <div>
            <OptionList
              a11yMessage={i18n.t('paymentOptions.localOptions') as string}
              getCurrency={getCurrency}
              i18n={i18n}
              onOptionSelect={onOptionSelect}
              options={_options}
              readonly
              trackCheckMarkup={trackCheckMarkup}
              trackPaymentOption={trackPaymentOption}
            />
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  amount: getOrderAmount(state),
  country: getSenderCountry(state),
  getCurrency: (code: string) => getCurrency(state, code),
  isFetching:
    isFetchingSelector(state, 'offers') || isFetchingSelector(state, 'order'),
  isLoggedIn: isLoggedIn(state),
  options: getRecurringOffers(state),
  readonly: isReadOnly(state, 'offer'),
  recipient: getRecipient(state),
  metadata: getRecurringOffersMetadata(state),
});

const mapDispatchToProps = {
  clearOfferFields,
  fetchOffer: fetchOfferFromOrder,
  fetchOffers: fetchOffersFromOrderIfNeeded,
  fetchPaymentsRecipient,
  fetchUserPayments,
  setFieldsValues,
  trackCheckMarkup,
  trackPaymentOption,
  updateOrderWithEntities,
  fetchRecurringOffersFromOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const RecurringPayment = connector(RecurringPaymentComponent);

export { RecurringPayment };
