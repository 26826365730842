import { Middleware } from 'redux';
import { errorNotifier } from 'utils/errorNotifier';
import {
  FETCH_USER_PAYMENTS_FAILURE,
  FETCH_USER_FAILURE,
  HTTPStatuses,
} from 'constants/index';
import { RootState } from 'reducers/types';

const isUnauthorized = (error: { status: HTTPStatuses } | undefined) =>
  error?.status === HTTPStatuses.UNAUTHORIZED;

const isReportable = (actionType: string) => {
  switch (actionType) {
    case FETCH_USER_PAYMENTS_FAILURE:
    case FETCH_USER_FAILURE:
      return true;
    default:
      return false;
  }
};

const ErrorLogger: Middleware<unknown, RootState> = () => next => action => {
  const { type, payload } = action;
  const shouldNotifyError = isReportable(type) && !isUnauthorized(payload);

  if (shouldNotifyError && payload) {
    errorNotifier.notifyWithFingerprint(payload as Error, [
      type,
      payload.message,
    ]);
  }

  return next(action);
};

export { ErrorLogger };
