import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { trackChangePaymentMethodInCardSurchargeError } from 'actions/tracker/tracker';
import { goToStep } from 'actions/ui/ui';
import { getCurrentLocale } from 'selectors';
import { useTranslations } from 'utils/translations/useTranslations';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { supportUrl } from 'utils/flysiteUrls/flysiteUrls';
import type { ChargeError } from './useCharge';
import type { RootState } from 'reducers/types';

import './Error.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;
type ErrorProps = PropsFromRedux & {
  cardProductType: string;
  error: ChargeError | 'session';
  onRetry: () => void;
};

function ErrorComponent({
  cardProductType,
  error,
  goToStep,
  onRetry,
  locale,
  trackChangePaymentMethodInCardSurchargeError,
}: ErrorProps) {
  const i18n = useTranslations();

  function handleOnRetry(evt: React.MouseEvent<HTMLElement>) {
    evt.preventDefault();
    onRetry();
  }

  function handleChangePaymentMethod(evt: React.MouseEvent<HTMLElement>) {
    evt.preventDefault();
    trackChangePaymentMethodInCardSurchargeError({
      productType: cardProductType,
    });
    goToStep(1);
  }

  const sessionError = error === 'session';

  return (
    <div role="status" aria-live="polite">
      <div className="payexCardFormError">
        <h3 className="failed" tabIndex={0} aria-describedby="failureMessage">
          {i18n.t('cardForm.charge.error.title')}
        </h3>
        {sessionError ? (
          <p
            className="type-regular paddingH-xxl"
            dangerouslySetInnerHTML={createMarkup(
              i18n.t('cardForm.charge.error.session', {
                contactUrl: supportUrl(locale),
              }),
            )}
          />
        ) : (
          <>
            <p id="failureMessage">
              {i18n.t(`cardForm.charge.error.${error}`)}
            </p>
            <div className="Error-links">
              <div className="Error-link">
                <a href="/retry" onClick={handleOnRetry} data-testid="retryBtn">
                  {i18n.t('cardForm.charge.error.retry')}
                </a>
              </div>
              <div>{i18n.t('cardForm.error.or')}</div>
              <div className="Error-link">
                <a
                  href="/change_payment_method"
                  onClick={handleChangePaymentMethod}
                >
                  {i18n.t('cardForm.error.changePaymentMethod')}
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {
  goToStep,
  trackChangePaymentMethodInCardSurchargeError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Error = connector(ErrorComponent);

export { Error };
