import { useQuery } from 'react-query';
import { orders } from 'services/apiV3/orders/orders';

export const useCalculateRedeemCashback = (
  { orderId, token }: { orderId: string; token: string },
  opts = {},
) => {
  return useQuery(
    ['calculateRedeemCashback'],
    () => orders.calculateRedeemCashback({ orderId, token }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      ...opts,
    },
  );
};
