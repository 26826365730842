import { combineReducers } from 'redux';
import {
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  GO_TO_PREV_STEP,
  GO_TO_STEP,
  REFUND_KYC_FIELDS_MISSING,
  RESET_VALIDATION_ERRORS,
  RESTART,
  SET_FIELD_VALUE,
  SET_LOCALE,
  SET_VALIDATION_ERRORS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  UPDATE_PAYMENT_SUCCESS,
  VALIDATE_FIELDS_SUCCESS,
} from 'constants/index';
import type { Error } from 'models';

type ErrorsAction =
  | {
      type: typeof VALIDATE_FIELDS_SUCCESS;
      payload: {
        errorParams: string[];
        errors: Record<string, Error>;
      };
    }
  | {
      type: typeof SET_FIELD_VALUE;
      payload: {
        name: string;
        value: string;
      };
    }
  | {
      type:
        | typeof CREATE_ORDER_FAILURE
        | typeof UPDATE_ORDER_FAILURE
        | typeof UPDATE_PAYMENT_FAILURE;
      payload: {
        entities: {
          errors: Record<string, Error>;
        };
        result: string[];
      };
    }
  | {
      type: typeof REFUND_KYC_FIELDS_MISSING;
      payload: {
        fields: string[];
      };
    }
  | {
      type: typeof SET_VALIDATION_ERRORS;
      payload: {
        [key: string]: string[];
      };
    }
  | {
      type:
        | typeof CREATE_ORDER_SUCCESS
        | typeof GO_TO_PREV_STEP
        | typeof GO_TO_STEP
        | typeof RESET_VALIDATION_ERRORS
        | typeof RESTART
        | typeof SET_LOCALE
        | typeof UPDATE_ORDER_SUCCESS
        | typeof UPDATE_PAYMENT_SUCCESS;
    };

export const byParam = (
  state: Record<string, Error> = {},
  action: ErrorsAction,
) => {
  const newState = { ...state };

  switch (action.type) {
    case SET_FIELD_VALUE:
      delete newState[action.payload.name];
      return newState;
    case VALIDATE_FIELDS_SUCCESS:
      return { ...state, ...action.payload.errors };
    case CREATE_ORDER_FAILURE:
    case UPDATE_ORDER_FAILURE:
      const { entities: { errors } = {} } = action.payload;
      return { ...state, ...errors };
    case SET_VALIDATION_ERRORS:
      Object.keys(action.payload).forEach(name => {
        newState[name] = {
          message: action.payload[name][0],
          param: name,
          type: 'invalid_param',
        };
      });
      return newState;
    case CREATE_ORDER_SUCCESS:
    case RESTART:
    case RESET_VALIDATION_ERRORS:
    case SET_LOCALE:
    case GO_TO_PREV_STEP:
    case GO_TO_STEP:
    case UPDATE_ORDER_SUCCESS:
    case UPDATE_PAYMENT_SUCCESS:
      return {};
    default:
      return state;
  }
};

export const params = (state: string[] = [], action: ErrorsAction) => {
  let newState: string[];

  switch (action.type) {
    case SET_FIELD_VALUE:
      newState = [...state];
      const position = newState.indexOf(action.payload.name);
      if (position !== -1) newState.splice(position, 1);
      return newState;
    case CREATE_ORDER_FAILURE:
    case UPDATE_ORDER_FAILURE:
      const { result = [] } = action.payload;
      return [...new Set([...state, ...result])];
    case SET_VALIDATION_ERRORS:
      newState = [...state];
      Object.keys(action.payload).forEach(name => {
        if (newState.indexOf(name) === -1) {
          newState.push(name);
        }
      });
      return newState;
    case VALIDATE_FIELDS_SUCCESS:
      return [...state, ...action.payload.errorParams];
    case REFUND_KYC_FIELDS_MISSING:
      return action.payload.fields;
    case CREATE_ORDER_SUCCESS:
    case RESTART:
    case RESET_VALIDATION_ERRORS:
    case SET_LOCALE:
    case GO_TO_PREV_STEP:
    case GO_TO_STEP:
    case UPDATE_ORDER_SUCCESS:
    case UPDATE_PAYMENT_SUCCESS:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  byParam,
  params,
});
