import React from 'react';
import { Error } from '../steps/Error/Error';
import { Success } from '../steps/Success/Success';
import { Pay } from '../steps/Pay/Pay';
import { Step } from 'models/navigation';

enum Steps {
  Error = 'error',
  Success = 'success',
  Pay = 'pay',
}

const steps: Step<Steps>[] = [
  { id: Steps.Pay, element: <Pay /> },
  { id: Steps.Success, element: <Success /> },
  { id: Steps.Error, element: <Error /> },
];

function useSteps() {
  return steps;
}

export { useSteps, Steps };
