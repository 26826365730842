import { SENDER_COUNTRY_FIELD } from '../../constants';
import type {
  Field,
  FieldValue,
  Item,
  Payment,
  Recipient,
  Sender,
  OfferWithEntities,
} from 'models';

type PayloadWithCallback = {
  callback?: {
    id: string;
    url: string;
  };
  offer?: OfferWithEntities;
  provider?: string;
  recipient?: Recipient;
  sender?: Sender;
};

type Payload = PayloadWithCallback | Payment;

type CallbackValuesFromProps =
  | {
      id: string;
      url: string;
      version?: number;
    }
  | Record<string, never>;

const getFieldValuesFromPayload = (payload: Payload) => {
  const flatten = (elements: (Field | Item)[] = [], entity?: string) => {
    return elements.reduce((acc: Record<string, FieldValue>, element) => {
      const elementId: string = entity ? `${entity}_${element.id}` : element.id;
      const elementValue = (element as Field).value || (element as Item).amount;
      acc[elementId] = elementValue;
      return acc;
    }, {});
  };

  const callbackValuesFrom = ({
    id,
    url,
    version,
  }: CallbackValuesFromProps) => {
    if (!id || !url) return {};

    return { callback_id: id, callback_url: url, callback_version: version };
  };

  const {
    sender: { fields: senderFields = [] } = {},
    recipient: {
      fields: recipientFields = [],
      id: recipientId,
      items: recipientItems = [],
    } = {},
    offer: { id: offerId, fields: offerFields } = {},
    provider,
  } = payload;

  const callback = (payload as PayloadWithCallback).callback || {};

  return {
    ...flatten(senderFields, 'sender'),
    ...flatten(recipientFields),
    ...flatten(recipientItems),
    ...flatten(offerFields as Field[], 'offer'),
    offer: offerId,
    recipient: recipientId,
    provider,
    ...callbackValuesFrom(callback),
  };
};

const getReadOnlyFieldsFromPayload = (payload: Payload) => {
  const readOnly = (elements: (Field | Item)[], entity?: string) => {
    const readOnlyFields = (element: Field | Item) => element.readOnly;
    const elementId = (element: Field | Item) =>
      entity ? `${entity}_${element.id}` : element.id;

    return elements.filter(readOnlyFields).map(elementId);
  };

  const {
    sender: { fields: senderFields = [] } = {},
    recipient: {
      fields: recipientFields = [],
      items: recipientItems = [],
    } = {},
  } = payload;

  return [
    ...readOnly(senderFields, 'sender'),
    ...readOnly(recipientFields),
    ...readOnly(recipientItems),
  ];
};

type GetDefaultFieldValuesFromRecipientPayload = {
  entities: {
    recipients: Record<string, Recipient>;
  };
  result: string;
};

const getDefaultFieldValuesFromRecipient = (
  payload: GetDefaultFieldValuesFromRecipientPayload,
) => {
  const { entities: { recipients = {} } = {}, result } = payload;
  const recipient = recipients[result];

  const { metadata: { defaultPayerCountry } = {} } = recipient;
  if (!defaultPayerCountry) return {};

  return {
    [SENDER_COUNTRY_FIELD]: defaultPayerCountry,
  };
};

type GetReadOnlyFieldsFromRecipientPayload = {
  entities: {
    recipients: Record<string, Recipient>;
  };
  result: string;
};

const getReadOnlyFieldsFromRecipient = (
  payload: GetReadOnlyFieldsFromRecipientPayload,
) => {
  const { result, entities: { recipients = {} } = {} } = payload;
  const recipient = recipients[result];

  const { metadata: { defaultAmountForPayersReadonly } = {} } = recipient;
  if (!defaultAmountForPayersReadonly) return [];

  return ['amount'];
};

export {
  getDefaultFieldValuesFromRecipient,
  getFieldValuesFromPayload,
  getReadOnlyFieldsFromPayload,
  getReadOnlyFieldsFromRecipient,
};
