import { sessionStorage } from 'utils/storage';
import { AUTHENTICATION_STORAGE_NAME } from '../../constants';
import {
  getAccessToken,
  getExpiresIn,
  getTokenType,
  isImpersonatingUser,
} from 'selectors';
import { RootState } from 'reducers/types';

export const loadStateFromSessionStorage = () => {
  try {
    const serializedAccessToken = sessionStorage.getItem(
      AUTHENTICATION_STORAGE_NAME,
    );

    if (serializedAccessToken === null) {
      return undefined;
    }

    const { impersonation = false, ...accessTokenDetails } = JSON.parse(
      serializedAccessToken,
    );
    return {
      entities: { authentication: { ...accessTokenDetails } },
      ui: { isImpersonatingUser: impersonation },
    };
  } catch (err) {
    return undefined;
  }
};

export const saveStateToSessionStorage = (state: RootState) => {
  try {
    const serializableState = {
      accessToken: getAccessToken(state),
      expiresIn: getExpiresIn(state),
      tokenType: getTokenType(state),
      impersonation: isImpersonatingUser(state) || false,
    };

    sessionStorage.setItem(
      AUTHENTICATION_STORAGE_NAME,
      JSON.stringify(serializableState),
    );
  } catch (e) {}
};
