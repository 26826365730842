import type { RootState } from 'reducers/types';
import { CAMELIZED_FEATURES } from '../../constants';

type Features = (typeof CAMELIZED_FEATURES)[number];

export const getFeatures = (state: RootState) => {
  const { features = {} } = state;

  return features;
};

export const getFeature = (state: RootState, feature: Features) => {
  const features = getFeatures(state);

  return Object.prototype.hasOwnProperty.call(features, feature)
    ? features[feature]
    : false;
};
