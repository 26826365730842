import React from 'react';

export const GoogleIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M23.766 12.278c0-.816-.066-1.636-.207-2.438H12.24v4.62h6.482a5.554 5.554 0 0 1-2.399 3.647v2.999h3.867c2.27-2.09 3.576-5.177 3.576-8.828Z"
          fill="#4285F4"
        />
        <path
          d="M12.24 24.001c3.237 0 5.966-1.062 7.955-2.897l-3.867-2.998c-1.076.732-2.465 1.146-4.083 1.146-3.131 0-5.786-2.112-6.738-4.951h-3.99v3.09a12.002 12.002 0 0 0 10.723 6.61Z"
          fill="#34A853"
        />
        <path
          d="M5.503 14.299a7.188 7.188 0 0 1 0-4.595v-3.09H1.516a12.01 12.01 0 0 0 0 10.776l3.987-3.091Z"
          fill="#FBBC04"
        />
        <path
          d="M12.24 4.75a6.52 6.52 0 0 1 4.603 1.799l3.427-3.426A11.533 11.533 0 0 0 12.24 0 11.998 11.998 0 0 0 1.517 6.615l3.986 3.09C6.45 6.863 9.109 4.75 12.24 4.75Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
