import { DELETE_FILE_SUCCESS, FETCH_DOCUMENTS_SUCCESS } from 'constants/index';
import type { DocumentsCollection } from 'models';

type DocumentsCollectionAction =
  | {
      type: typeof FETCH_DOCUMENTS_SUCCESS;
      payload: {
        documentsCollections: DocumentsCollection;
      };
    }
  | {
      type: typeof DELETE_FILE_SUCCESS;
      payload: {
        fileId: string;
      };
    };

export default (
  state: DocumentsCollection | Record<string, never> = {},
  action: DocumentsCollectionAction,
) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      const { documentsCollections } = action.payload;

      return { ...documentsCollections };
    case DELETE_FILE_SUCCESS:
      const { fileId } = action.payload;
      const { documents } = state;

      if (!documents) return state;

      const newDocuments = documents.map(document => {
        const { files } = document;

        document.files = files.filter(file => file.id !== fileId);

        return document;
      });

      return { ...state, documents: newDocuments };
    default:
      return state;
  }
};
