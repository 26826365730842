import { combineReducers } from 'redux';
import {
  FETCH_OFFERS_FAILURE,
  FETCH_LEGACY_RECURRING_OFFERS_SUCCESS,
  RESET_SELECTED_OFFER,
  SET_LOCALE,
} from '../../../constants';
import type { LegacyRecurringOfferWithIds } from 'models';

type RecurringOffersAction =
  | {
      type: typeof FETCH_LEGACY_RECURRING_OFFERS_SUCCESS;
      payload: {
        entities: {
          offers?: Record<string, LegacyRecurringOfferWithIds>;
        };
        result: string[];
      };
    }
  | { type: typeof FETCH_OFFERS_FAILURE }
  | { type: typeof RESET_SELECTED_OFFER | typeof SET_LOCALE };

export const byId = (state = {}, action: RecurringOffersAction) => {
  switch (action.type) {
    case FETCH_LEGACY_RECURRING_OFFERS_SUCCESS:
      const { entities: { offers = {} } = {} } = action.payload;

      return offers;
    case FETCH_OFFERS_FAILURE:
      return {};
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: RecurringOffersAction) => {
  switch (action.type) {
    case FETCH_LEGACY_RECURRING_OFFERS_SUCCESS:
      const { result: offerIds = [] } = action.payload;
      return offerIds;
    case FETCH_OFFERS_FAILURE:
      return [];
    default:
      return state;
  }
};

export const didInvalidate = (state = false, action: RecurringOffersAction) => {
  switch (action.type) {
    case RESET_SELECTED_OFFER:
    case SET_LOCALE:
    case FETCH_OFFERS_FAILURE:
      return true;
    case FETCH_LEGACY_RECURRING_OFFERS_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const legacyRecurringOffers = combineReducers({
  byId,
  ids,
  didInvalidate,
});
