import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'components/ui/Modal/Modal';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'lib/flywire-tailwind/Button';
import { ReactComponent as Checkmark } from 'icons/kyc-green.svg';
import { trackClickUseINROptions, trackClickUseUSDOptions } from 'actions';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { keysEnum } from '../FeedbackQuestionsModal/data/constants';

import './NonFxInfoModal.scss';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  onContinueWithForeignOptions: () => void;
};

export const NonFxInfoModal = ({
  isOpen,
  onContinueWithForeignOptions,
  closeModal,
}: Props) => {
  const dispatch = useDispatch();

  const continueWithForeignOptions = () => {
    dispatch(trackClickUseUSDOptions());
    onContinueWithForeignOptions();
  };

  const continueWithLocalOptions = () => {
    dispatch(trackClickUseINROptions());
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      size="medium"
      className="NonFxInfo-Modal"
    >
      <div className="text-center text-2xl">
        <Heading as="h5" size="large" className="fontWeight-semiBold">
          {keysEnum['nonFxPaymentInfo.title.question']}
        </Heading>
        <p className="text-center text-sm font-medium text-[#1B2733]">
          {keysEnum['nonFxPaymentInfo.title']}
        </p>
      </div>
      <div className="text-sm">
        <ul className="flex list-none flex-col gap-4">
          <li className="flex gap-2">
            <Checkmark className="mt-1" />
            <span className="inline-block flex-1">
              {keysEnum['nonFxPaymentInfo.info1']}
            </span>
          </li>
          <li className="flex gap-2">
            <Checkmark className="mt-1" />
            <span
              className="inline-block flex-1"
              dangerouslySetInnerHTML={createMarkup(
                keysEnum['nonFxPaymentInfo.info2'],
              )}
            />
          </li>
          <li className="flex gap-2">
            <Checkmark className="mt-1" />
            <span className="inline-block flex-1">
              {keysEnum['nonFxPaymentInfo.info3']}
            </span>
          </li>
          <li className="flex gap-2">
            <Checkmark className="mt-1" />
            <span className="inline-block flex-1">
              {keysEnum['nonFxPaymentInfo.info4']}
            </span>
          </li>
          <li className="flex gap-2">
            <Checkmark className="mt-1" />
            <span className="inline-block flex-1">
              {keysEnum['nonFxPaymentInfo.info5']}
            </span>
          </li>
        </ul>
      </div>
      <div className="mt-6 text-center">
        <Button
          tone="primary"
          size="full"
          onClick={continueWithLocalOptions}
          data-testid="continueWithLocal"
        >
          {keysEnum['nonFxPaymentInfo.button.INROption.label']}
        </Button>
        <button
          className="buttonLikeLink mb-2 mt-6"
          role="link"
          onClick={continueWithForeignOptions}
          data-testid="continueWithForeignOptions"
        >
          {keysEnum['nonFxPaymentInfo.bottom.button.continue.label']}
        </button>
        <div className="text-[14px] text-[#5a687b]">
          {keysEnum['nonFxPaymentInfo.bottom.note']}
        </div>
      </div>
    </Modal>
  );
};
