import {
  addOrderToUser,
  newNotification,
  setReceiveSMSNotification,
  trackSMSNotification,
} from 'actions';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { UserAccessModal } from 'components/UserAccessModal/UserAccessModal';
import { NOTIFICATION_LOGIN_REQUIRED } from 'constants/ui';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  canUserPerformSecureAction,
  shouldReceiveSMSNotification,
} from 'selectors';
import { useTranslations } from 'utils';

const CheckSMSNotification = () => {
  const i18n = useTranslations();
  const dispatch = useDispatch();
  const receiveSMSNotification = useSelector(shouldReceiveSMSNotification);
  const [isChecked, setIsChecked] = useState(receiveSMSNotification);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const canPerformSecureActions = useSelector(canUserPerformSecureAction);

  const handleCheckboxChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(checked);
    dispatch(setReceiveSMSNotification(checked));
    dispatch(trackSMSNotification(checked));
  };

  const afterAuthAction = async () => {
    dispatch(addOrderToUser());
    setIsModalOpen(false);

    handleCheckboxChange({
      target: { checked: !receiveSMSNotification },
    } as ChangeEvent<HTMLInputElement>);
  };

  const openModalAndNotify = () => {
    setIsModalOpen(true);
    dispatch(
      newNotification({
        message: NOTIFICATION_LOGIN_REQUIRED,
        type: 'success',
      }),
    );
  };

  return (
    <>
      <UserAccessModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onLoginSuccess={afterAuthAction}
        onSignUpSuccess={afterAuthAction}
      />
      <Checkbox
        name="receiveTextNotification"
        label={i18n.t('senderInformation.receiveTextNotification.checkboxText')}
        checked={isChecked}
        onChange={evt =>
          canPerformSecureActions || isChecked
            ? handleCheckboxChange(evt)
            : openModalAndNotify()
        }
      />
    </>
  );
};

export { CheckSMSNotification };
