import MobileDetect from 'mobile-detect';

const MobileDetector = {
  isMobile() {
    const md = new MobileDetect(window.navigator.userAgent);
    return !!md.mobile();
  },
};

export default MobileDetector;
