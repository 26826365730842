import * as ACTION_TYPES from 'constants/index';
import {
  INTERVAL_FIELD,
  IS_RECURRING_FIELD,
  IS_LEGACY_RECURRING_FIELD,
} from 'constants/index';
import {
  getFieldByEntity,
  getPayment,
  getPaymentOffer,
  isRepeatingPayment,
  isOnline,
} from 'selectors';
import createEntity from 'factories/createEntity';
import type { FieldValue, Notification } from 'models';
import { PayexThunkAction } from 'store/configureStore';

export const goToNextStep = () => ({
  type: ACTION_TYPES.GO_TO_NEXT_STEP,
});

export const goToPrevStep = () => ({
  type: ACTION_TYPES.GO_TO_PREV_STEP,
});

export const goToStep = (step: number) => ({
  type: ACTION_TYPES.GO_TO_STEP,
  payload: { step },
});

export const setFieldsValues = (fields: Record<string, FieldValue>) => ({
  type: ACTION_TYPES.SET_FIELDS_VALUES,
  payload: { fields },
});

export const savedSuccessfully = () => ({
  type: ACTION_TYPES.SAVED_SUCCESSFULLY,
});

export const setFieldValue =
  (name: string, value: FieldValue): PayexThunkAction =>
  (dispatch, getState) => {
    const changeInPaymentInformation =
      name === 'sender_country' || name === 'amount';

    const state = getState();

    if (changeInPaymentInformation && !isRepeatingPayment(state)) {
      dispatch({
        type: ACTION_TYPES.RESET_SELECTED_OFFER,
      });
    }

    return dispatch({
      type: ACTION_TYPES.SET_FIELD_VALUE,
      payload: { name, value },
    });
  };

export const resetSelectedOffer = (): PayexThunkAction => dispatch => {
  dispatch({
    type: ACTION_TYPES.RESET_SELECTED_OFFER,
  });
};

export const resetFieldsValues =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const payload = getPayment(state);

    return dispatch({
      type: ACTION_TYPES.RESET_FIELDS_VALUES,
      payload,
    });
  };

export const cleanUIFieldValues = () => ({
  type: ACTION_TYPES.CLEAN_UI_FIELD_VALUES,
});

export const setRepeatPaymentExperience =
  (isEnabled: boolean): PayexThunkAction =>
  dispatch => {
    return dispatch({
      type: ACTION_TYPES.SET_REPEAT_PAYMENT_EXPERIENCE,
      payload: isEnabled,
    });
  };

export const setRecipient = (recipient: string) =>
  setFieldValue('recipient', recipient);

export const setReadOnlyFields = (fields: string[]) => ({
  type: ACTION_TYPES.SET_READONLY_FIELDS,
  payload: fields,
});

export const setPrefilledFields = (fields: string[]) => ({
  type: ACTION_TYPES.SET_PREFILLED_FIELDS,
  payload: fields,
});

export const clearOfferFields =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const { ids = [] } = getFieldByEntity(state, 'offers');

    dispatch({
      type: ACTION_TYPES.CLEAR_FIELDS,
      payload: [
        ...ids,
        INTERVAL_FIELD,
        IS_RECURRING_FIELD,
        IS_LEGACY_RECURRING_FIELD,
      ],
    });
  };

export const restart = () => ({
  type: ACTION_TYPES.RESTART,
});

export const newNotification = ({
  message,
  opts,
  type = 'success',
}: Pick<Notification, 'message' | 'opts'> & {
  type?: 'error' | 'info' | 'success';
}) => ({
  type: ACTION_TYPES.UI_NOTIFICATION_NEW,
  payload: createEntity({ message, opts, type }),
});

export const removeNotification = (id: string) => ({
  type: ACTION_TYPES.UI_NOTIFICATION_REMOVE,
  payload: id,
});

export const setEmbedded = () => ({
  type: ACTION_TYPES.SET_EMBEDDED_EXPERIENCE,
});

export const unsetEmbedded = () => ({
  type: ACTION_TYPES.UNSET_EMBEDDED_EXPERIENCE,
});

export const setGatewayOrigin = () => ({
  type: ACTION_TYPES.SET_GATEWAY_ORIGIN,
});

export const setGatewaySource = (source: string) => ({
  type: ACTION_TYPES.SET_GATEWAY_SOURCE,
  payload: source,
});

export const showOfflineInstructions = () => ({
  type: ACTION_TYPES.SHOW_OFFLINE_INSTRUCTIONS,
});

export const setDocCollectorFinished = (finished: boolean) => ({
  type: ACTION_TYPES.SET_DOC_COLLECTOR_FINISHED,
  payload: { finished },
});

export const setPrefilledRejectOrConfirm = (status: boolean) => ({
  type: ACTION_TYPES.SET_PREFILLED_REJECT_OR_CONFIRM,
  payload: { status },
});

export const hideUserManagement = () => ({
  type: ACTION_TYPES.HIDE_USER_MANAGEMENT,
});

export const setReceiveSMSNotification = (receiveSMSNotification: boolean) => ({
  type: ACTION_TYPES.SET_RECEIVE_SMS_NOTIFICATION,
  payload: { receiveSMSNotification },
});

export const setReceiveWhatsappNotification = (
  receiveWhatsappNotification: boolean | null,
) => ({
  type: ACTION_TYPES.SET_RECEIVE_WHATSAPP_NOTIFICATION,
  payload: { receiveWhatsappNotification },
});

export const showCancelPaymentOption = () => ({
  type: ACTION_TYPES.SHOW_CANCEL_PAYMENT_OPTION,
});

export const hideCancelPaymentOption = () => ({
  type: ACTION_TYPES.HIDE_CANCEL_PAYMENT_OPTION,
});

export const fetchEmbedded = (fetching: boolean) => ({
  type: ACTION_TYPES.FETCH_EMBEDDED,
  payload: fetching,
});

export const setShouldAutoLoadOnlineScript =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();
    const paymentOffer = getPaymentOffer(state);

    if (!paymentOffer || !isOnline(state)) {
      return dispatch({
        type: ACTION_TYPES.SET_SHOULD_AUTO_LOAD_ONLINE_SCRIPT,
        payload: false,
      });
    }

    const isWorldpayOffer = /^worldpay/i.test(paymentOffer.paymentOption);
    const isWorldpayUnionpayOffer = /^worldpay.*unionpay/i.test(
      paymentOffer.paymentOption,
    );

    const isWorldpayWechatOffer = /^worldpay.*wechat/i.test(
      paymentOffer.paymentOption,
    );

    const shouldAutoLoadOnlineScript =
      !isWorldpayOffer || isWorldpayUnionpayOffer || isWorldpayWechatOffer;

    return dispatch({
      type: ACTION_TYPES.SET_SHOULD_AUTO_LOAD_ONLINE_SCRIPT,
      payload: shouldAutoLoadOnlineScript,
    });
  };

export const setRedirectToReferrer = (redirectToReferrer: boolean) => ({
  type: ACTION_TYPES.SET_REDIRECT_TO_REFERRER,
  payload: { redirectToReferrer },
});

export const setPaymentProcess = () => ({
  type: ACTION_TYPES.SET_PAYMENT_PROCESS,
});

export const unsetPaymentProcess = () => ({
  type: ACTION_TYPES.UNSET_PAYMENT_PROCESS,
});

export const setTheme = (theme: Record<string, string>) => ({
  type: ACTION_TYPES.SET_THEME,
  payload: theme,
});

export const setTrackingPage = () => ({
  type: ACTION_TYPES.SET_TRACKING_PAGE,
});

export const unsetTrackingPage = () => ({
  type: ACTION_TYPES.UNSET_TRACKING_PAGE,
});

export const setChatVisible = () => ({
  type: ACTION_TYPES.SET_CHAT_VISIBLE,
});

export const resetInvalidSMSAttempt = () => ({
  type: ACTION_TYPES.RESET_INVALID_SMS_ATTEMPT,
});

export const setMaxAmount = (amount: number) => ({
  type: ACTION_TYPES.SET_MAX_AMOUNT,
  payload: amount,
});
