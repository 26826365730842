import React from 'react';
import { twMerge } from 'tailwind-merge';

function cx(...classes: (string | undefined)[]) {
  return twMerge(classes.filter(Boolean));
}

interface ButtonStyleProps {
  shape?: 'square' | 'rounded' | 'circular';
  size?: 'auto' | 'full';
  tone?: 'primary' | 'secondary' | 'error';
}

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonStyleProps {}

const baseClasses =
  'cursor-pointer items-center justify-center whitespace-nowrap text-wrap px-4 py-3 text-center text-base normal-case shadow-sm transition-all hover:no-underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2';

const shapeClasses: Record<NonNullable<ButtonStyleProps['shape']>, string> = {
  circular: 'rounded-full',
  rounded: 'rounded',
  square: 'rounded-none',
};

const sizeClasses: Record<NonNullable<ButtonStyleProps['size']>, string> = {
  auto: 'inline-flex min-w-36 flex-shrink-0 flex-grow-0 px-6 text-base',
  full: 'flex w-full flex-grow text-base',
};

const toneClasses: Record<NonNullable<ButtonStyleProps['tone']>, string> = {
  primary:
    'bg-blue-600 text-white hover:bg-blue-700 hover:text-white focus-visible:outline-black active:bg-blue-800 disabled:bg-blue-600/50',
  secondary:
    'border border-gray-300 bg-white text-blue-600 hover:!bg-gray-50 hover:text-blue-600 focus-visible:border-white focus-visible:outline-black active:!bg-gray-300 disabled:bg-white disabled:text-blue-50',
  error:
    'bg-red-600 text-white hover:bg-red-700 hover:text-white focus-visible:outline-black active:bg-red-800 disabled:bg-red-600/50',
};

export const useButtonClasses = ({
  shape = 'rounded',
  size = 'auto',
  tone = 'primary',
  className,
}: ButtonStyleProps & { className?: string }) => {
  return cx(
    baseClasses,
    shapeClasses[shape],
    sizeClasses[size],
    toneClasses[tone],
    className,
  );
};

export const Button = ({
  shape = 'rounded',
  size = 'auto',
  tone = 'primary',
  className,
  ...restProps
}: ButtonProps & React.ComponentPropsWithoutRef<'button'>) => {
  const classes = useButtonClasses({ shape, size, tone, className });

  return (
    <button type="button" {...restProps} className={classes} data-tone={tone} />
  );
};
