import React, { useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ErrorCounter } from './ErrorCounter/ErrorCounter';
import { trackNavigation } from 'actions';
import { useTranslations } from 'utils';
import { ReactComponent as NextIcon } from 'icons/next.svg';
import { ReactComponent as PrevIcon } from 'icons/prev.svg';
import { Button } from 'lib/flywire-tailwind/Button';

import './Navigation.scss';

const TRACKER_NEXT_VALUE = 'next';
const TRACKER_PREVIOUS_VALUE = 'previous';

type PropsFromRedux = ConnectedProps<typeof connector>;
type NavigationProps = PropsFromRedux & {
  errors?: string[];
  hideNext?: boolean;
  hidePrev?: boolean;
  nextLabel?: string;
  nextStepName?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  previousStepName?: string;
};

function NavigationComponent({
  errors = [],
  hideNext,
  hidePrev,
  nextLabel,
  nextStepName,
  onClickNext,
  onClickPrev,
  previousStepName,
  trackNavigation,
}: NavigationProps) {
  const previousErrors = useRef();

  useEffect(() => {
    previousErrors.current = errors;
  });

  const i18n = useTranslations();
  const hasErrors = errors.length > 0;
  const stepErrorMessage = (errors: string[]) => {
    return errors.length === 1
      ? i18n.t('step.invalid.errors.one')
      : i18n.t('step.invalid.errors.other');
  };

  const handleOnClick = (trackerValue, callback) => {
    trackNavigation(window.location.pathname, trackerValue);
    callback?.();
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex-1">
        {!hidePrev && (
          <Button
            className="group flex h-12 min-w-20 items-center justify-center gap-2 md:h-auto md:min-w-36"
            role="button"
            tone="secondary"
            onClick={() => handleOnClick(TRACKER_PREVIOUS_VALUE, onClickPrev)}
            aria-label={i18n.t('navigation.label.prev', { previousStepName })}
            data-testid="previous"
          >
            <PrevIcon
              className="h-auto w-4 transition-all group-hover:-translate-x-2"
              aria-hidden="true"
            />
            <span className="hidden text-base md:block">
              {i18n.t('navigation.prev')}
            </span>
          </Button>
        )}
      </div>
      <div className="flex flex-1 justify-end">
        {!hideNext && (
          <Button
            className="group min-w-36"
            role="button"
            tone={hasErrors ? 'error' : 'primary'}
            onClick={() => handleOnClick(TRACKER_NEXT_VALUE, onClickNext)}
            aria-label={
              hasErrors
                ? stepErrorMessage(errors)
                : i18n.t('navigation.label.next', { nextStepName })
            }
            aria-describedby={hasErrors ? 'error-count' : undefined}
            data-testid="next"
          >
            <div className="Navigation-slider">
              {hasErrors ? (
                <span
                  id="error-count"
                  className="Navigation-slide Navigation-slide--error"
                >
                  <ErrorCounter count={errors.length} />{' '}
                  <span className="Navigation-errorText">
                    {errors.length === 1
                      ? i18n.t('navigation.error.one')
                      : i18n.t('navigation.error.other')}
                  </span>
                </span>
              ) : (
                <span className="flex items-center justify-center gap-2 text-base">
                  {nextLabel || i18n.t('navigation.next')}
                  <NextIcon
                    className="h-auto w-4 transition-all group-hover:translate-x-2"
                    aria-hidden="true"
                  />
                </span>
              )}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  trackNavigation,
};

const connector = connect(null, mapDispatchToProps);
const Navigation = connector(NavigationComponent);

export { Navigation };
