import React, { useContext } from 'react';
import classNames from 'classnames';
import { useToggle } from '@flywire/react-hooks';
import { OfferContext } from '../../Offer';
import { ReactComponent as ArrowDownIcon } from 'icons/arrowDown.svg';
import { ReactComponent as InfoIcon } from 'icons/ico-info-blue.svg';
import { NOOP } from 'constants/index';

type MoreInfoProps = {
  children: React.ReactNode;
  initialOpen?: boolean;
  onToggle: () => void;
  trackMoreInfo?: () => void;
};

function MoreInfo({
  children,
  initialOpen,
  onToggle = NOOP,
  trackMoreInfo = NOOP,
}: MoreInfoProps) {
  const [isOpen, toggleIsOpen] = useToggle(initialOpen);
  const { id, i18n, name } = useContext(OfferContext);

  function handleOnClick() {
    trackMoreInfo();
    toggleIsOpen();
    onToggle();
  }

  return (
    <div className="Offer-footer">
      <div className="Offer-toggle">
        <button
          aria-controls={`offer-${id}-description`}
          aria-expanded={isOpen}
          aria-label={`${i18n.t('offers.importantInfo')} ${name}`}
          className={classNames('Offer-toggleButton', {
            'is-open': isOpen,
          })}
          onClick={handleOnClick}
          data-testid="offer-more-info"
          id={`offer-${id}-description-toggler`}
        >
          <InfoIcon
            height="12"
            width="12"
            className="marginRight-sm"
            aria-hidden="true"
          />
          {i18n.t('offers.importantInfo')}
          <ArrowDownIcon
            className="marginLeft-sm Offer-toggleIcon"
            aria-hidden="true"
          />
        </button>

        <div
          id={`offer-${id}-description`}
          className={classNames('Offer-toggleContent', {
            hidden: !isOpen,
          })}
          aria-labelledby={`offer-${id}-description-toggler`}
          aria-hidden={!isOpen}
          data-testid="content"
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export { MoreInfo };
