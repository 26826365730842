import {
  TranslationContext,
  type TranslationContextInterface,
} from 'contexts/TranslationContext/TranslationContext';
import React from 'react';
import {
  I18n,
  extractLocaleCode,
  isRtlLocale,
  translate,
} from 'utils/translations';

const wrapped = (
  Component: typeof React.Component,
  props: Record<string, unknown>,
  context: TranslationContextInterface,
) => {
  const { keys, fallbackKeys } = context;
  const t = (
    key: string,
    attrs: Record<string, string | JSX.Element> = {},
  ): string => {
    return translate(key, attrs, keys, fallbackKeys);
  };
  const i18n: I18n = {
    t,
    currentLocale: context.locale,
    localeCode: extractLocaleCode(context.locale),
    isRtl: isRtlLocale(context.locale),
    setLocale: context.setLocale,
  };
  return <Component {...props} i18n={i18n} />;
};

/**
 * @deprecated Please use useTranslations
 */
const WithTranslations = (WrappedComponent: typeof React.Component) => {
  const WithTranslations = (props: Record<string, unknown>) => {
    return (
      <TranslationContext.Consumer>
        {i18n => wrapped(WrappedComponent, props, i18n)}
      </TranslationContext.Consumer>
    );
  };

  return WithTranslations;
};

export { WithTranslations };
