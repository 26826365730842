import { BankAccountPaymentMethod, CardPaymentMethod, User } from 'models';
import { RootState } from 'reducers/types';

export const getEmail = (state: RootState) => {
  const {
    entities: {
      user: { email = null },
    },
  } = state;

  return email;
};

export const getUser = (state: RootState): User => {
  const {
    entities: { user },
  } = state;

  return user;
};

export const getCreditCards = (state: RootState) => {
  const { paymentMethods = [] } = getUser(state);

  return paymentMethods.filter(pm => pm.type === 'card') as CardPaymentMethod[];
};

export const getBankAccounts = (state: RootState) => {
  const { paymentMethods = [] } = getUser(state);

  return paymentMethods.filter(
    pm => pm.type === 'bank_account',
  ) as BankAccountPaymentMethod[];
};

export const getPaymentMethods = (state: RootState) => {
  const { paymentMethods } = getUser(state);

  return paymentMethods || [];
};
