import React from 'react';
import { MoneyInput } from './MoneyInput';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { getCurrentCurrency } from 'selectors';
import { Currency } from 'models';
import { FieldInterface } from '../Field';
import { RootState } from 'reducers/types';

const WAIT_TIME = 250;

type MoneyInputFieldProps = {
  currency: Currency;
  field: FieldInterface;
  handleBlur: (name: string, value: number) => void;
  handleChange: (name: string, value: number) => void;
};

const MoneyInputFieldComponent = ({
  currency,
  field,
  handleBlur,
  handleChange,
}: MoneyInputFieldProps) => {
  const {
    autoComplete = 'off',
    defaultValue,
    error,
    help,
    label,
    id,
    readOnly,
    required,
  } = field;
  const {
    decimalMark,
    subunitToUnit,
    symbol,
    symbolFirst,
    thousandsSeparator,
  } = currency;

  return (
    <MoneyInput
      aria-required={required}
      autoComplete={autoComplete}
      required={required}
      currencySymbol={symbol}
      decimalMark={decimalMark}
      error={error}
      help={help}
      label={label}
      maxLength={12}
      name={id}
      onBlur={handleBlur}
      onChange={debounce(handleChange, WAIT_TIME)}
      readOnly={readOnly}
      subunitToUnit={subunitToUnit}
      symbolFirst={symbolFirst}
      thousandsSeparator={thousandsSeparator}
      value={defaultValue ? Number.parseInt(defaultValue) : undefined}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  currency: getCurrentCurrency(state),
});

const MoneyInputField = connect(mapStateToProps)(MoneyInputFieldComponent);

export { MoneyInputField };
