import * as Sentry from '@sentry/react';

export const errorNotifier = {
  notify: async (err: unknown, context = {}) => {
    /* eslint-disable */
    if (process.env.NODE_ENV === 'development') {
      window.console && console.error && console.error(err);
    }

    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(err, { extra: context });
    }
    /* eslint-enable */
  },
  notifyWithFingerprint: async (
    err: Error,
    fingerprint: string[],
    context = {},
  ) => {
    /* eslint-disable */
    if (process.env.NODE_ENV === 'development') {
      window.console && console.error && console.error(err);
    }

    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(err, { fingerprint, extra: context });
    }
    /* eslint-enable */
  },
};
