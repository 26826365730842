import React from 'react';
import PropTypes from 'prop-types';
import { TextareaInput } from 'components/input/TextareaInput/TextareaInput';
import { TextInput } from 'components/input/TextInput/TextInput';
import debounce from 'lodash/debounce';
import { NOOP } from 'constants/index';

const WAIT_TIME = 250;

const TextInputField = ({ field, handleBlur = NOOP, handleChange = NOOP }) => {
  const {
    autoComplete = 'off',
    defaultValue,
    error,
    format,
    hint,
    id,
    label,
    readOnly,
    required,
  } = field;

  if (format === 'text') {
    return (
      <TextareaInput
        autoComplete={autoComplete}
        error={error}
        hint={hint}
        label={label}
        name={id}
        onBlur={name => setTimeout(() => handleBlur(name), WAIT_TIME)}
        onChange={debounce(handleChange, WAIT_TIME)}
        readOnly={readOnly}
        value={defaultValue}
        required={required}
      />
    );
  }

  return (
    <TextInput
      error={error}
      hint={hint}
      label={label}
      name={id}
      onBlur={name => setTimeout(() => handleBlur(name), WAIT_TIME)}
      onChange={debounce(handleChange, WAIT_TIME)}
      readOnly={readOnly}
      value={defaultValue}
      required={required}
    />
  );
};

TextInputField.propTypes = {
  field: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
};

export { TextInputField };
