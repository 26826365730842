import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import flywireFeiHui from './flywireFeiHui.svg';

import './ChineseLogo.scss';

const DEFAULT_SIZE = 'medium';

const ChineseLogo = ({ size, className }) => {
  const logoSizeClass = `ChineseLogo--${size}`;
  const logoClassName = classNames(
    'ChineseLogo',
    { [logoSizeClass]: !!size },
    className,
  );

  return (
    <img className={logoClassName} src={flywireFeiHui} alt="Flywire Fei Hui" />
  );
};

ChineseLogo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

ChineseLogo.defaultProps = {
  size: DEFAULT_SIZE,
};

export { ChineseLogo };
