import schemas from 'constants/schemas';
import { Field } from 'models/field';
import { normalizeResponse } from 'utils/normalize/normalize';

export const normalizeKycFields = (
  kycFields: { label: string; value: string }[],
): {
  entities?: {
    fields: Record<string, Field>;
  };
  result?: string[];
} => {
  return kycFields.length
    ? normalizeResponse<{
        fields: Record<string, Field>;
      }>(kycFields, schemas.arrayOfKYCFields)
    : {};
};
