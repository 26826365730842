type Option = {
  label: string;
  value: string;
};

const arrayKey = (arr: Option[]) => {
  return arr
    .slice(0, 10)
    .map((o: Option) => o.label)
    .join('-');
};

export { arrayKey };
