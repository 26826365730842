import * as ACTION_TYPES from 'constants/smsNotifications';
import { getLinkById, isFetching, getFieldValue } from 'selectors';
import type { PayexThunkAction } from 'store/configureStore';
import type { RootState } from 'reducers/types';
import { normalizeSmsNotifications } from 'services/smsNotifications/smsNotifications';
import { SET_FIELD_VALUE } from 'constants/ui';
import { updatePayment } from '../payments';

export const enableSenderSMSNotification =
  (): PayexThunkAction =>
  async (dispatch, getState, { smsNotifications }) => {
    const state = getState();

    const number = getFieldValue(state, 'sender_phone');

    dispatch({ type: ACTION_TYPES.RESET_RECEIVE_SMS_NOTIFICATIONS });

    const isFetchingPaymentActions = isFetching(state, 'paymentActions');
    if (isFetchingPaymentActions) return;

    const url = getUpdateSMSNotificationUrl(state);
    if (!url) return;

    dispatch({ type: ACTION_TYPES.ENABLE_SENDER_SMS_NOTIFICATION_REQUEST });

    try {
      const response = await smsNotifications.enable({
        number: number as string,
        url,
      });

      return dispatch({
        type: ACTION_TYPES.ENABLE_SENDER_SMS_NOTIFICATION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.ENABLE_SENDER_SMS_NOTIFICATION_FAILURE });

      throw error;
    }
  };

export const updateSMSNotification =
  ({ number, active }: { number: string; active: boolean }): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const isFetchingPaymentActions = isFetching(state, 'paymentActions');
    if (isFetchingPaymentActions) return;

    const url = getUpdateSMSNotificationUrl(state);
    if (!url) return;

    dispatch({ type: ACTION_TYPES.UPDATE_SMS_NOTIFICATION_REQUEST });

    try {
      const response = await apiV3.payments.updateSMSNotification({
        active,
        number,
        url,
      });

      if (active) {
        await dispatch({
          type: SET_FIELD_VALUE,
          payload: { name: 'sender_phone', value: number },
        });
        await dispatch(updatePayment());
      }

      return dispatch({
        type: ACTION_TYPES.UPDATE_SMS_NOTIFICATION_SUCCESS,
        payload: normalizeSmsNotifications(response),
      });
    } catch (error) {
      dispatch({ type: ACTION_TYPES.UPDATE_SMS_NOTIFICATION_FAILURE });

      throw error;
    }
  };

const getUpdateSMSNotificationUrl = (state: RootState) => {
  const link = getLinkById(state, 'sms_notification');
  if (!link) return;

  const { href } = link;
  return href;
};
