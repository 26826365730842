import {
  CANCEL_PAYMENT_SUCCESS,
  CREATE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_LRS_FAILURE,
  DELETE_PAYMENT_LRS_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_PAYMENT_LRS_FAILURE,
  FETCH_PAYMENT_LRS_SUCCESS,
  FETCH_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_SUCCESS,
  RESTART,
  SELECT_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_SENDER_EMAIL,
  UPLOAD_DOCUMENT_SUCCESS,
} from '../../../constants';
import { UPLOAD_LRS_INFO_SUCCESS } from 'constants/index';
import merge from 'lodash/merge';
import pull from 'lodash/pull';
import without from 'lodash/without';
import type { DocumentLegacy, Field, Payment } from 'models';

type PaymentAction =
  | {
      type:
        | typeof SELECT_PAYMENT
        | typeof CREATE_PAYMENT_SUCCESS
        | typeof FETCH_PAYMENT_SUCCESS;
      payload: {
        entities: {
          payments: Record<string, Payment>;
        };
        result: string;
      };
    }
  | {
      type: typeof UPDATE_PAYMENT_SUCCESS;
      payload: Pick<Payment, 'sender' | 'offer' | 'recipient'>;
    }
  | {
      type: typeof CANCEL_PAYMENT_SUCCESS;
      payload: {
        id: string;
        cancelledAt: string;
      };
    }
  | {
      type: typeof FETCH_DOCUMENTS_SUCCESS;
      payload: {
        documents?: Record<string, DocumentLegacy>;
        recurring?: boolean;
      };
    }
  | {
      type: typeof INITIATE_PAYMENT_SUCCESS;
      payload: {
        id: string;
        paymentInitiatedAt: string;
      };
    }
  | {
      type: typeof UPLOAD_DOCUMENT_SUCCESS;
      payload: DocumentLegacy;
    }
  | {
      type: typeof UPDATE_SENDER_EMAIL;
      payload: string;
    }
  | { type: typeof FETCH_PAYMENT_LRS_FAILURE }
  | { type: typeof FETCH_PAYMENT_LRS_SUCCESS }
  | { type: typeof DELETE_PAYMENT_LRS_FAILURE }
  | { type: typeof DELETE_PAYMENT_LRS_SUCCESS }
  | { type: typeof UPLOAD_LRS_INFO_SUCCESS }
  | { type: typeof RESTART };

const payment = (
  state: Payment | Record<string, never> = {},
  action: PaymentAction,
) => {
  switch (action.type) {
    case CANCEL_PAYMENT_SUCCESS:
      const { cancelledAt } = action.payload;

      const links = without(
        state.links,
        'initiate',
        'cancellation',
        'sms_notification',
      );

      return {
        ...state,
        instructions: [],
        links,
        status: 'cancelled',
        statusTransitions: { cancelledAt },
      };
    case CREATE_PAYMENT_SUCCESS:
    case FETCH_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
      const {
        entities: { payments },
        result,
      } = action.payload;
      return payments[result];
    case UPDATE_PAYMENT_SUCCESS:
      return { ...state, ...action.payload };
    case INITIATE_PAYMENT_SUCCESS:
      const senderIndicatedSentAt = action.payload.paymentInitiatedAt;
      const newInitiatedState = merge({}, state, { senderIndicatedSentAt });
      pull(newInitiatedState.links, 'initiate', 'cancellation');

      return newInitiatedState;
    case RESTART:
      return {};
    case FETCH_DOCUMENTS_SUCCESS:
      const { documents, recurring } = action.payload;
      return { ...state, documents, recurring };
    case UPLOAD_DOCUMENT_SUCCESS:
      const uploadedDocument = [action.payload];

      return { ...state, documents: uploadedDocument };
    case FETCH_PAYMENT_LRS_SUCCESS:
    case UPLOAD_LRS_INFO_SUCCESS:
      return { ...state, isPaymentLRSSubmitted: true };
    case FETCH_PAYMENT_LRS_FAILURE:
    case DELETE_PAYMENT_LRS_SUCCESS:
    case DELETE_PAYMENT_LRS_FAILURE:
      return { ...state, isPaymentLRSSubmitted: false };
    case UPDATE_SENDER_EMAIL:
      const { sender } = state;
      const value = action.payload;
      const isEmail = ({ id }: { id: string }) => id === 'email';
      const updateEmail = (field: Field) =>
        isEmail(field) ? { ...field, value } : field;
      const fields = (sender?.fields || []).map(updateEmail);
      const _sender = sender ? { ...sender, fields } : { fields };

      return { ...state, sender: _sender };
    default:
      return state;
  }
};

export default payment;
