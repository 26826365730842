import fetch from 'services/apiV3/fetch';
import { API_URL_V3 } from 'constants/index';
import type {
  CreatePaymentRequestBody,
  DeleteFileRequestParams,
  DeleteFileResponse,
  FetchProofsRequestParams,
  FetchSmsNotificationRequestParams,
  FetchUserPaymentsRequestParams,
  FetchUserPaymentsResponse,
  GenerateFilenameUrlRequestParams,
  GenerateFilenameResponse,
  ProofResponseBody,
  SMSNotificationResponse,
  UpdatePaymentRequestBody,
  UpdateSMSNotificationRequestParams,
  FetchFileUploadURLRequestParams,
  UploadFileToUrlRequestParams,
  FetchPaymentRefundsRequestParams,
  PaymentRefundsResponse,
} from './types';
import { Payment } from 'models/payment';

export interface PaymentsService {
  create: (payload: CreatePaymentRequestBody) => Promise<Payment>;
  fetchUserPayments: (
    payload: FetchUserPaymentsRequestParams,
  ) => Promise<FetchUserPaymentsResponse>;
  fetchSMSNotification: (
    payload: FetchSmsNotificationRequestParams,
  ) => Promise<SMSNotificationResponse>;
  update: (payload: UpdatePaymentRequestBody) => Promise<Payment>;
  updateSMSNotification: (
    payload: UpdateSMSNotificationRequestParams,
  ) => Promise<SMSNotificationResponse>;
  deleteFile: (payload: DeleteFileRequestParams) => Promise<DeleteFileResponse>;
  fetchProofs: (
    payload: FetchProofsRequestParams,
  ) => Promise<ProofResponseBody>;
  generateProofUrl: (
    payload: GenerateFilenameUrlRequestParams,
  ) => Promise<GenerateFilenameResponse>;
  generateDocumentUrl: (
    payload: GenerateFilenameUrlRequestParams,
  ) => Promise<GenerateFilenameResponse>;
  fetchFileUploadURL: (
    payload: FetchFileUploadURLRequestParams,
  ) => Promise<{ url?: string }>;
  fetchPaymentRefunds: (
    payload: FetchPaymentRefundsRequestParams,
  ) => Promise<PaymentRefundsResponse>;
  uploadFileToUrl: (payload: UploadFileToUrlRequestParams) => Promise<void>;
}

export const payments: PaymentsService = {
  create: ({
    orderId,
    token,
    locale = 'EN',
    hideUserManagement = false,
    recurring,
    captchaResponse,
    platform,
    recipient,
    whatsappNotification,
  }) => {
    const queryParams = [
      `token=${token}`,
      `locale=${locale}`,
      ...(hideUserManagement ? ['hideUserManagement=true'] : []),
      ...(recipient === 'PTU' ? ['avoid_maintenance=true'] : []),
    ].join('&');
    const path = `${API_URL_V3}/orders/${orderId}/payments?${queryParams}`;
    const body = JSON.stringify({
      ...{ captcha_response: captchaResponse },
      ...(recurring && { recurring }),
      platform,
      recipient,
      ...(whatsappNotification != null && {
        whatsapp_notification: whatsappNotification,
      }),
    });
    const options = { method: 'POST', body };

    return fetch(path, options);
  },

  fetchUserPayments: ({ perPage = 5, page = 1 } = {}) => {
    const path = `${API_URL_V3}/payments?per_page=${perPage}&page=${
      page < 1 ? 1 : page
    }`;
    const options = {
      method: 'GET',
    };

    return fetch(path, options);
  },

  fetchSMSNotification: ({ url }) => {
    const options = { method: 'GET' };

    return fetch(url, options);
  },

  update: ({ id, token, ...payload }) => {
    const path = `${API_URL_V3}/payments/${id}?token=${token}`;
    const body = JSON.stringify(payload);
    const options = {
      method: 'PATCH',
      body,
    };

    return fetch(path, options);
  },

  updateSMSNotification: ({ number, active, url }) => {
    let options;

    switch (active) {
      case true:
        const payload = { number };
        const body = JSON.stringify(payload);
        options = { method: 'POST', body };
        break;
      case false:
        options = { method: 'DELETE' };
        break;
    }

    return fetch(url, options);
  },

  generateDocumentUrl: ({ id, token, filename }) => {
    const path = `${API_URL_V3}/payments/${id}/documents/upload_url?token=${token}`;
    const payload = { filename };
    const body = JSON.stringify(payload);
    const options = { method: 'POST', body };

    return fetch(path, options);
  },

  fetchFileUploadURL: ({ documentUploadURL }) => {
    const options = { method: 'POST' };

    return fetch(documentUploadURL, options);
  },

  deleteFile: ({ url }) => {
    const path = url;
    const options = { method: 'DELETE' };

    return fetch(path, options);
  },

  fetchProofs: ({ id, token }) => {
    const path = `${API_URL_V3}/payments/${id}/proofs?token=${token}`;
    const options = { method: 'GET' };

    return fetch(path, options);
  },

  generateProofUrl: ({ id, token, filename }) => {
    const path = `${API_URL_V3}/payments/${id}/proofs/upload_url?token=${token}`;
    const payload = { filename };
    const body = JSON.stringify(payload);
    const options = { method: 'POST', body };

    return fetch(path, options);
  },

  uploadFileToUrl: ({ file, url, info }) => {
    const body = new FormData();
    body.append('file', file);
    body.append('info', JSON.stringify(info));

    const path = url;
    const options = { method: 'POST', body };

    return fetch(path, options);
  },

  fetchPaymentRefunds: ({ paymentId }) => {
    const path = `${API_URL_V3}/payments/${paymentId}/refunds`;
    const options = { method: 'GET' };

    return fetch(path, options);
  },
};
