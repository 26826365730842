import React, { useState } from 'react';
import { Steps } from '../../hooks/useSteps';
import {
  CreateRecurringBody,
  useCardPaymentContext,
} from '../../hooks/useCardPaymentContext';
import { useCreditCards } from 'hooks/useCreditCards';
import { useRecurringOffer } from '../../hooks/useRecurringOffer';
import { useTranslations } from 'utils';
import { useRecurringOfferPrice } from '../../hooks/useRecurringOfferPrice';
import { Info } from 'components/Alert/Info/Info';
import { Spinner } from 'components/Spinner/Spinner';
import { formatToBrowserLocale } from 'utils/date/date';
import {
  CardTokenizationEvent,
  CardInstructionsAttributes,
} from 'services/tokenization/models/models';
import { CreditCards } from 'components/CreditCards/CreditCards';
import { Heading } from 'components/Heading/Heading';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getRecipientId } from 'selectors';
import { CardNewMandateError } from '@flywire/internal-react-elements';
import { usePeertransferFlags } from 'hooks/usePeertransferFlags/usePeertransferFlags';
import { FF_USER_WALLET } from 'constants/features';
import type { CardPaymentMethod, Price } from 'models';

import './Pay.scss';

function Pay() {
  const { data: flags, isLoading: isLoadingFlags } = usePeertransferFlags(
    FF_USER_WALLET,
    { refetchOnWindowFocus: false },
  );

  const i18n = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const { creditCards, hasCreditCards } = useCreditCards();
  const {
    cardWizardNavigation,
    createRecurringReceivable,
    instructionsAttributes,
    setErrorKey,
  } = useCardPaymentContext();
  const { offer, schedule } = useRecurringOffer();
  const { value, currency: currencyId } = offer.price as Price;

  const { payButtonLabel, currencyCode } = useRecurringOfferPrice(
    value,
    currencyId as string,
  );

  const [selectedCreditCard, setSelectedCreditCard] = useState<
    CardPaymentMethod | undefined
  >(creditCards[0]);

  const recipientId = useSelector(getRecipientId);

  const onTokenizationSuccess = ({
    uuidToken,
    apiConfirmationURL,
  }: CardTokenizationEvent) => {
    if (!apiConfirmationURL || !uuidToken) {
      return;
    }
    createRecurring({
      uuidToken,
      apiConfirmationURL,
    });
  };

  const onTokenizationError = () => {
    cardWizardNavigation.go(Steps.Error);
  };

  const createRecurring = async (body: CreateRecurringBody) => {
    setIsLoading(true);

    try {
      await createRecurringReceivable(body);

      setIsLoading(false);
      cardWizardNavigation.go(Steps.Success);
    } catch (error) {
      setIsLoading(false);
      cardWizardNavigation.go(Steps.Error);
    }
  };

  const onSuccessCVV = (
    data: { confirm: { url: string; method: string } },
    sessionId: string,
  ) => {
    if (selectedCreditCard) {
      createRecurring({
        apiConfirmationURL: data?.confirm?.url,
        sessionId: sessionId,
      });
    }
  };

  const onErrorCVV = async (error?: CardNewMandateError) => {
    setIsLoading(false);
    if (error?.type !== 'invalid_cvv') {
      if (!error || error.type === 'not_found') {
        setErrorKey('notifications.internal_server_failure');
      }
      cardWizardNavigation.go(Steps.Error);
    }
  };

  const onSelectedCardChange = (card?: CardPaymentMethod) => {
    setSelectedCreditCard(card);
  };

  const enableUserWallet = !!flags?.[FF_USER_WALLET];

  const showSpinner = isLoading || isLoadingFlags;

  return (
    <div className="PayRecurring">
      {showSpinner ? (
        <Spinner className="PayRecurring-spinner" />
      ) : (
        <>
          {schedule !== 'pay_now' && (
            <Info className={classNames(!hasCreditCards && 'Info-NoCards')}>
              <Info.FilledIcon />
              <span className="Info-text">
                {i18n.t('cardPayment.payLaterInfo', {
                  date: formatToBrowserLocale(offer.installments[0].date),
                })}
              </span>
            </Info>
          )}

          <Heading as="h2" size="large" className="fontWeight-semiBold">
            {i18n.t('cardPayment.payer.title')}
          </Heading>

          <CreditCards cards={creditCards}>
            {enableUserWallet && hasCreditCards && (
              <CreditCards.CardList
                currency={currencyCode}
                onErrorCVV={onErrorCVV}
                onSelectedCardChange={onSelectedCardChange}
                onSuccessCVV={onSuccessCVV}
                recipientId={recipientId}
                selectedCard={selectedCreditCard}
              />
            )}
            <CreditCards.AddCardForm
              instructionsAttributes={
                instructionsAttributes as CardInstructionsAttributes
              }
              onSuccess={onTokenizationSuccess}
              onError={onTokenizationError}
            />
            <CreditCards.Actions>
              <CreditCards.PayButton
                isLoading={isLoading}
                payButtonLabel={payButtonLabel as string}
              />
            </CreditCards.Actions>
            <CreditCards.Disclaimer />
          </CreditCards>
        </>
      )}
    </div>
  );
}

export { Pay };
