export const UPLOAD_DOCUMENT_MAX_FILES_FAILURE =
  'UPLOAD_DOCUMENT_MAX_FILES_FAILURE';
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';
export const NOT_FAILED_FILE_STATUSES = ['available', 'pending'];
export const LRS_DOCUMENT_TYPE = 'LRS';
export const INDIAN_PAYMENT_OPTIONS_IN_USD = [
  'pt_bank_transfer_usd_india_nonfx',
  'pt_bank_transfer_usd_india_nonfx_low',
  'pt_bank_transfer_usd_india_nonfx_high',
];
