import fetchJson from '../../../utils/fetchJson';
import { API_URL_V3 } from '../../../constants';
import { Country } from 'models/country';

export const countries = {
  fetch: ({ locale = 'EN' }) => {
    const path = `${API_URL_V3}/countries?locale=${locale}`;

    return fetchJson<Country[]>(path);
  },
};
