import React, {
  ChangeEvent,
  FormEvent,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Heading } from 'components/Heading/Heading';
import { TextInput } from 'components/input/TextInput/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { Fetching } from 'components/Fetching/Fetching';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { TermsAndConditions } from './components/TermsAndConditions/TermsAndConditions';
import {
  WithFocusOnError,
  WithFocusOnErrorProps,
} from 'components/HOC/WithFocusOnError/WithFocusOnError';
import { useFormState, useValidate } from '@flywire/react-hooks';
import { getConstraints } from './getConstraints';
import { useTranslations } from 'utils';
import { RootState } from 'reducers/types';
import { RequiredFieldLabel } from 'components/RequiredFieldLabel/RequiredFieldLabel';
import { SocialSignIn } from 'components/SocialSignIn/SocialSignIn';
import { isHttpErrorWithParsedBody } from 'utils/errors/errors';
import { getFieldValue } from 'selectors/ui/ui';
import {
  SENDER_EMAIL_FIELD,
  SENDER_FIRST_NAME_FIELD,
  SENDER_LAST_NAME_FIELD,
} from 'constants/fields';
import { HttpError } from 'constants/http';
import { Captcha, CaptchaRef } from 'components/thirdParty/Captcha/Captcha';
import { shouldShowCaptchaInSignup } from 'utils/captcha/captcha';
import { setCaptchaResponse } from 'actions/ui/captcha/captcha';
import { newNotification } from 'actions/ui/ui';
import { signupUser, trackAccountCreation } from 'actions';
import { Button } from 'lib/flywire-tailwind/Button';

import './UserSignup.scss';

const WAIT_TIME = 125;

type UserSignupProps = WithFocusOnErrorProps & {
  firstInputRef?: React.MutableRefObject<HTMLInputElement>;
  handleLoginClick?: () => void;
  onSignUpSuccess?: () => void;
};

function UserSignup({
  focusOnFirstError,
  handleLoginClick,
  onSignUpSuccess,
  firstInputRef,
}: UserSignupProps) {
  const i18n = useTranslations();
  const dispatch = useDispatch();
  const captchaRef = useRef<CaptchaRef>(null);
  const initialEmail = useSelector((state: RootState) =>
    getFieldValue(state, SENDER_EMAIL_FIELD),
  );
  const initialFirstName = useSelector((state: RootState) =>
    getFieldValue(state, SENDER_FIRST_NAME_FIELD),
  );
  const initialLastName = useSelector((state: RootState) =>
    getFieldValue(state, SENDER_LAST_NAME_FIELD),
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [serverError, setServerErrors] = useState<Record<string, string>>({});
  const constraints = useMemo(() => getConstraints({ i18n }), [i18n]);
  const { values, update, dirtyFields } = useFormState({
    terms: false,
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail,
  });

  const {
    isValid,
    errors: clientErrors,
    validate: clientValidate,
  } = useValidate(values, constraints);

  function handleOnChange(name: string, value?: string | boolean | number) {
    update(name, value);
  }

  function moveFocusToFirstError() {
    setTimeout(focusOnFirstError, WAIT_TIME);
  }

  async function handleOnSubmit(evt: FormEvent) {
    evt.preventDefault();

    setIsFormSubmitted(true);
    clientValidate();

    if (!isValid) {
      return moveFocusToFirstError();
    }

    if (captchaRef.current) {
      captchaRef.current.execute();
    } else {
      handleSignup();
    }
  }

  const handleSignup = async () => {
    const email = values.email as string;
    const password = values.password as string;
    const firstName = values.firstName as string;
    const lastName = values.lastName as string;

    try {
      await dispatch(signupUser({ firstName, lastName, email, password }));

      onSignUpSuccess?.();
      dispatch(trackAccountCreation?.());
    } catch (error) {
      if (isHttpErrorWithParsedBody(error)) {
        const { parsedBody } = error as HttpError;
        const errors = parsedBody?.errors
          ? Array.isArray(parsedBody.errors)
            ? parsedBody.errors
            : []
          : [];

        if (errors.length) {
          const parsedErrors = errors?.reduce(
            (
              acc: Record<string, string>,
              value: { param: string; message: string },
            ) => ({ ...acc, [value.param]: [value.message] }),
            {},
          );

          setServerErrors(parsedErrors);
          moveFocusToFirstError();
        } else {
          handleError();
        }
      } else {
        handleError();
      }
    }
  };

  const handleSignupWithCaptcha = async (token: string) => {
    if (token) {
      dispatch(setCaptchaResponse(token));
      handleSignup();
    } else {
      handleError();
    }
  };

  const handleError = () => {
    dispatch(
      newNotification({
        message: 'userAccessModal.signup.createUser.error',
        type: 'error',
      }),
    );
  };

  function errorOn(attr: string) {
    return (
      (dirtyFields[attr] || isFormSubmitted) &&
      (clientErrors[attr]?.[0] || serverError[attr]?.[0])
    );
  }

  const showCaptcha = shouldShowCaptchaInSignup(values.email as string);

  return (
    <>
      <Fetching entity="user" />

      <Heading
        as="h2"
        size="large"
        className="textAlign-center marginBottom-xxl"
        id="Page-title"
      >
        {i18n.t('userSignup.title')}
      </Heading>

      <SocialSignIn
        onSuccess={() => onSignUpSuccess?.()}
        onError={() =>
          dispatch(
            newNotification({
              message: 'notifications.internal_server_failure',
              type: 'error',
            }),
          )
        }
        isLogin={false}
      />

      <form className="UserSignup" onSubmit={handleOnSubmit} noValidate>
        <RequiredFieldLabel />
        <TextInput
          autoFocus
          data-testid="firstName"
          error={errorOn('firstName')}
          floatingLabel
          label={i18n.t('userSignup.firstName.label') as string}
          name="firstName"
          onChange={handleOnChange}
          required
          value={values?.firstName as string}
          forwardRef={firstInputRef}
        />
        <TextInput
          data-testid="lastName"
          error={errorOn('lastName')}
          floatingLabel
          label={i18n.t('userSignup.lastName.label') as string}
          name="lastName"
          onChange={handleOnChange}
          required
          value={values?.lastName as string}
        />
        <TextInput
          data-testid="email"
          error={errorOn('email')}
          floatingLabel
          label={i18n.t('userSignup.email.label') as string}
          name="email"
          onChange={handleOnChange}
          required
          value={values?.email as string}
        />
        <TextInput
          data-testid="confirmationEmail"
          error={errorOn('confirmationEmail')}
          floatingLabel
          label={i18n.t('userSignup.repeat_email.label') as string}
          name="confirmationEmail"
          onChange={handleOnChange}
          required
          value={values?.confirmationEmail as string}
        />
        <PasswordInput
          data-testid="password"
          error={errorOn('password')}
          floatingLabel
          label={i18n.t('userSignup.password.label')}
          name="password"
          onChange={handleOnChange}
          required
        />
        <input
          data-testid="repeatPassword"
          required
          name="repeatPassword"
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            handleOnChange(evt.target.name, evt.target.checked)
          }
          type="password"
          className="Input--hidden"
        />
        <TermsAndConditions
          error={errorOn('terms')}
          name="terms"
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            handleOnChange(evt.target.name, evt.target.checked)
          }
          required
          checked={values?.terms}
        />
        <Button
          tone="primary"
          size="full"
          type="submit"
          className="mt-10"
          data-testid="signUpButton"
        >
          {i18n.t('userSignup.submit')}
        </Button>
      </form>
      <div className="UserSignup-question">
        {i18n.t('userSignup.already.user')}
      </div>
      <div className="UserSignup-link">
        <button
          onClick={handleLoginClick}
          type="button"
          role="link"
          data-testid="login"
        >
          {i18n.t('userSignup.login.link')}
        </button>
      </div>
      {showCaptcha && (
        <Captcha ref={captchaRef} onVerify={handleSignupWithCaptcha} />
      )}
    </>
  );
}
const UserSignupWithFocustOnError = WithFocusOnError(UserSignup);

export { UserSignupWithFocustOnError as UserSignup };
