export const FETCH_PAYMENT_REFUNDS_REQUEST = 'FETCH_PAYMENT_REFUNDS_REQUEST';
export const FETCH_PAYMENT_REFUNDS_SUCCESS = 'FETCH_PAYMENT_REFUNDS_SUCCESS';
export const FETCH_PAYMENT_REFUNDS_FAILURE = 'FETCH_PAYMENT_REFUNDS_FAILURE';
export const REFUND_STATUS_INITIATED = 'initiated';
export const REFUND_STATUS_RECEIVED = 'received';
export const REFUND_STATUS_FINISHED = 'finished';
export const REFUND_STATUS_ADVANCED = 'advanced';
export const REFUND_STATUS_CANCELLED = 'cancelled';
export const REFUND_STATUS_RETURN_TO_CLIENT = 'returned';
export const FETCH_KYC_TOKEN_REQUEST = 'FETCH_KYC_TOKEN_REQUEST';
export const FETCH_KYC_TOKEN_SUCCESS = 'FETCH_KYC_TOKEN_SUCCESS';
export const FETCH_KYC_TOKEN_FAILURE = 'FETCH_KYC_TOKEN_FAILURE';
