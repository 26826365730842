import React, { PropsWithChildren } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { getAccessToken, getCurrentLocale } from 'selectors';
import { useTranslations } from 'utils';
import { useButtonClasses } from 'lib/flywire-tailwind/Button';
import type { RootState } from 'reducers/types';
import { SELECT_INSTITUTION_PATH } from 'constants/index';

type NewPaymentProps = ConnectedProps<typeof connector> & {
  className?: string;
  size?: 'full' | 'auto';
  tone?: 'primary' | 'secondary';
};

const NewPaymentComponent = ({
  accessToken,
  children,
  className,
  locale,
  size = 'auto',
  tone = 'primary',
}: PropsWithChildren<NewPaymentProps>) => {
  const i18n = useTranslations();
  const classes = useButtonClasses({
    tone,
    size,
    className,
  });

  if (!accessToken) return null;

  return (
    <a
      className={classes}
      href={`${SELECT_INSTITUTION_PATH}?language=${locale}`}
      data-testid="new-payment-button"
    >
      {children || i18n.t('newPayment')}
    </a>
  );
};

const mapStateToProps = (state: RootState) => ({
  accessToken: getAccessToken(state),
  locale: getCurrentLocale(state),
});

const connector = connect(mapStateToProps);
const NewPayment = connector(NewPaymentComponent);

export { NewPayment };
