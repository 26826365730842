import apiV3 from 'services/apiV3';
import schemas from 'constants/schemas';
import { normalizeResponse } from 'utils/normalize/normalize';
import { Field } from 'models/field';
import { Section } from 'models/section';

export const fetch = async ({
  country,
  locale,
}: {
  country: string;
  locale: string;
}) => {
  const sender = await apiV3.senders.fetch({ country, locale });

  return normalizeSenderFields(sender.fields);
};

export const normalizeSenderFields = (
  fields: Field[] = [],
): {
  entities?: {
    fields: Record<string, Field>;
    sections: Record<string, Section>;
  };
  result?: string[];
} => {
  return fields.length
    ? normalizeResponse<{
        fields: Record<string, Field>;
        sections: Record<string, Section>;
      }>(fields, schemas.arrayOfFields)
    : {};
};
