import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InstallmentBuilder } from 'domain/recurringReceivables/builders';
import {
  amountFrom as getAmountFrom,
  amountTo as getAmountTo,
  getRecurringInterval,
  getCurrency,
} from 'selectors';
import { useTranslations } from 'utils/translations/useTranslations';
import type { RootState } from 'reducers/types';

function Wrapper({ amountFrom, amountTo, interval, render = () => null }) {
  const { currency: currencyFrom } = amountFrom;
  const { currency: currencyTo } = amountTo;

  const i18n = useTranslations();

  const installment = new InstallmentBuilder({
    amount: amountFrom.value,
    interval,
    subunitToUnit: currencyFrom.subunitToUnit,
    unitsToRound: currencyFrom.unitsToRound,
  });

  return render({
    amountFrom: amountFrom.value,
    amountTo: amountTo.value,
    fee: installment.fee,
    currencyFrom: currencyFrom.code,
    currencyTo: currencyTo.code,
    ends: installment.ends?.toLocaleDateString(),
    i18n,
    interval,
    starts: installment.starts?.toLocaleDateString(),
  });
}

Wrapper.displayName = 'RecurringReceivableWrapper';
Wrapper.propTypes = {
  amountFrom: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.shape({
      code: PropTypes.string,
      subunitToUnit: PropTypes.number,
      unitsToRound: PropTypes.number,
    }),
  }),
  amountTo: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.shape({
      code: PropTypes.string,
      subunitToUnit: PropTypes.number,
      unitsToRound: PropTypes.number,
    }),
  }),
  children: PropTypes.node,
  interval: PropTypes.number,
};

const mapStateToProps = (state: RootState) => {
  const amountFrom = getAmountFrom(state);
  const currencyFrom = getCurrency(state, amountFrom.currency);
  const amountTo = getAmountTo(state);
  const currencyTo = getCurrency(state, amountTo.currency);
  const interval = getRecurringInterval(state);

  return {
    amountFrom: { ...amountFrom, currency: currencyFrom },
    amountTo: { ...amountTo, currency: currencyTo },
    interval,
  };
};

const RecurringReceivable = connect(mapStateToProps)(Wrapper);

export { RecurringReceivable };
