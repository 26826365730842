import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getPaymentSteps, getCurrentStepIndex, isEmbedded } from 'selectors';

import { StepList } from 'components/StepList/StepList';
import type { RootState } from 'reducers/types';

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentStepListComponent = ({
  activeStepIndex,
  steps,
  isEmbedded,
}: PropsFromRedux) => {
  if (isEmbedded) return null;

  return (
    <StepList activeStepIndex={activeStepIndex} totalSteps={steps.length} />
  );
};

const mapStateToProps = (state: RootState) => ({
  activeStepIndex: getCurrentStepIndex(state),
  isEmbedded: isEmbedded(state),
  steps: getPaymentSteps(state),
});

const connector = connect(mapStateToProps);
const PaymentStepList = connector(PaymentStepListComponent);

export { PaymentStepList };
