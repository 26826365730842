import { combineReducers } from 'redux';
import {
  DELETE_PROOF_FILE_SUCCESS,
  FETCH_PROOFS_FAILURE,
  FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS,
  FETCH_PROOFS_SUCCESS,
} from 'constants/index';
import type { Proof } from 'models';

type ProofsAction =
  | {
      type: typeof FETCH_PROOFS_SUCCESS;
      payload: {
        entities: {
          proofs: Record<string, Proof>;
        };
        result: string[];
      };
    }
  | {
      type: typeof DELETE_PROOF_FILE_SUCCESS;
      payload: {
        fileId: string;
        proofId: string;
      };
    }
  | {
      type:
        | typeof FETCH_PROOFS_FAILURE
        | typeof FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS;
    };

export const byId = (
  state: Record<string, Proof> = {},
  action: ProofsAction,
) => {
  switch (action.type) {
    case FETCH_PROOFS_SUCCESS: {
      const {
        entities: { proofs },
      } = action.payload;

      return proofs;
    }
    case DELETE_PROOF_FILE_SUCCESS: {
      const { proofId, fileId } = action.payload;
      const proof = state[proofId];
      const files = proof.files;
      const newFiles = files.filter(f => f !== fileId);
      const newProof = { ...proof, files: newFiles };

      return { [proofId]: newProof };
    }
    case FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS:
    case FETCH_PROOFS_FAILURE:
      return {};
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: ProofsAction) => {
  switch (action.type) {
    case FETCH_PROOFS_SUCCESS: {
      const { result } = action.payload;
      return result;
    }
    case FETCH_PROOFS_SUCCESS_WITHOUT_RESULTS:
    case FETCH_PROOFS_FAILURE:
      return [];
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
