import { useSelector } from 'react-redux';
import type { RootState } from '../../reducers/types';

function useFeatureFlags(flag?: string) {
  const featureFlags = useSelector((state: RootState) => state.features);

  return flag ? featureFlags[flag] || false : featureFlags;
}

export default useFeatureFlags;
