import React from 'react';
import PropTypes from 'prop-types';

function Price({ children, value }) {
  return (
    <h4 className="Offer-price" data-testid="offer-price">
      {children || value}
    </h4>
  );
}

Price.displayName = 'Price';
Price.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

export { Price };
