import { combineReducers } from 'redux';
import { FETCH_SENDER_SUCCESS } from '../../../../constants';
import type { Field } from 'models';

type SenderAction = {
  type: typeof FETCH_SENDER_SUCCESS;
  payload: {
    entities: {
      fields: Record<string, Field>;
    };
    result: string[];
  };
};

export const byId = (
  state: Record<string, Field> = {},
  action: SenderAction,
) => {
  switch (action.type) {
    case FETCH_SENDER_SUCCESS:
      const {
        entities: { fields = {} },
      } = action.payload;
      if (Object.keys(fields).includes('sender_country')) {
        fields.sender_country.format = 'country';
      }

      return fields;
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: SenderAction) => {
  switch (action.type) {
    case FETCH_SENDER_SUCCESS:
      const { result: fieldIds } = action.payload;
      return fieldIds;
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
