import fetchJson from 'utils/fetchJson';
import { API_URL_V3 } from 'constants/index';
import { Country, Field, LegacyRecurring, Price, Recipient } from 'models';

type Logo = {
  name: string;
  links: {
    original: string;
    thumb: string;
  };
};

export type GetRecipientOfferResponse = {
  id: string;
  name: string;
  description: string;
  extraTerms: string;
  paymentOption: string;
  paymentMethod: string;
  paymentMethodProcessor: string;
  price: Price;
  fees: number;
  logo?: Logo;
  logos?: Logo[];
  help?: {
    title: string;
    content: string;
  }[];
  fields: Field[];
  recurring?: LegacyRecurring;
};

export interface RecipientsService {
  fetch: (payload: { id: string; locale: string }) => Promise<Recipient>;
  fetchBySubdomain: (payload: {
    subdomain: string;
    locale: string;
  }) => Promise<Recipient>;
  fetchOffers: (payload: {
    recipientId: string;
    country: string;
    amount: number;
    fingerprint?: string;
    locale: string;
  }) => Promise<GetRecipientOfferResponse[]>;
  fetchOffer: (payload: {
    recipientId: string;
    country: string;
    id: string;
    locale: string;
  }) => Promise<GetRecipientOfferResponse>;
  fetchCountries: (payload: {
    recipientId: string;
    locale?: string;
  }) => Promise<Pick<Country, 'code' | 'name'>[]>;
}

export const recipients: RecipientsService = {
  fetch: ({ id, locale }) => {
    const path = `${API_URL_V3}/recipients/${id}?locale=${locale}`;

    return fetchJson(path);
  },

  fetchBySubdomain: ({ subdomain, locale }) => {
    const path = `${API_URL_V3}/recipients/subdomain/${subdomain}?locale=${locale}`;

    return fetchJson(path);
  },

  fetchOffers: ({ recipientId, country, amount, fingerprint = '', locale }) => {
    if (!amount && amount <= 0) {
      return Promise.resolve([]);
    }

    const path = `${API_URL_V3}/recipients/${recipientId}/countries/${country}/offers?amount=${amount}&fingerprint=${fingerprint}&locale=${locale}`;

    return fetchJson(path);
  },

  fetchOffer: ({ recipientId, country, id, locale }) => {
    const path = `${API_URL_V3}/recipients/${recipientId}/countries/${country}/offers/${id}?locale=${locale}`;

    return fetchJson(path);
  },

  fetchCountries: ({ recipientId, locale = 'EN' }) => {
    const path = `${API_URL_V3}/recipients/${recipientId}/countries?locale=${locale}`;

    return fetchJson(path);
  },
};
