import {
  FETCH_PROOFS_SUCCESS,
  DELETE_PROOF_FILE_SUCCESS,
} from 'constants/index';
import omit from 'lodash/omit';
import type { DocumentFile } from 'models';

type ProofFilesAction =
  | {
      type: typeof FETCH_PROOFS_SUCCESS;
      payload: {
        entities: {
          proofFiles: Record<string, DocumentFile>;
        };
      };
    }
  | {
      type: typeof DELETE_PROOF_FILE_SUCCESS;
      payload: {
        fileId: string;
      };
    };

export default (
  state: Record<string, DocumentFile> = {},
  action: ProofFilesAction,
) => {
  switch (action.type) {
    case FETCH_PROOFS_SUCCESS:
      const {
        entities: { proofFiles },
      } = action.payload;

      return { ...state, ...proofFiles };
    case DELETE_PROOF_FILE_SUCCESS:
      const { fileId } = action.payload;
      const newFiles = omit(state, [fileId]);

      return { ...newFiles };
    default:
      return state;
  }
};
