import { localStorage } from 'utils/storage';
import unset from 'lodash/unset';
import {
  getUser,
  getFeatures,
  isEmbedded,
  getKeepSession,
  getFieldValue,
  getTheme,
} from 'selectors';
import { RootState } from 'reducers/types';

const KEY = '__payex_state';

export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(KEY);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveStateToLocalStorage = (state: RootState) => {
  try {
    const serializableState = {
      entities: {
        user: getUser(state),
      },
      features: getFeatures(state),
      ui: {
        embedded: isEmbedded(state),
        authentication: { keepSession: getKeepSession(state) },
        fieldValues: {
          recipient: getFieldValue(state, 'recipient'),
        },
        theme: getTheme(state),
      },
    };

    localStorage.setItem(KEY, JSON.stringify(serializableState));
  } catch (e) {}
};

export const removeLocalStorageUserKey = () => {
  const state = loadStateFromLocalStorage();
  unset(state, 'entities.user');

  localStorage.setItem(KEY, JSON.stringify(state));
};
