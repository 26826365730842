import React from 'react';
import demoRibbonImg from './ribbon_demo.png';
import stagingRibbonImg from './ribbon_staging.png';

import './Ribbon.scss';

const DemoRibbon = () => (
  <div className="ribbon">
    <img src={demoRibbonImg} alt="demo" />
  </div>
);

const StagingRibbon = () => (
  <div className="ribbon">
    <img src={stagingRibbonImg} alt="staging" />
  </div>
);

const Ribbon = () => {
  const origin = document.location.origin;
  const isDemo = /demo\./.test(origin);
  const isStaging = /flywire.(lol|cc)/.test(origin);

  if (isDemo) {
    return <DemoRibbon />;
  }

  if (isStaging) {
    return <StagingRibbon />;
  }

  return null;
};

export { Ribbon };
