import React from 'react';
import PropTypes from 'prop-types';
import bankIcon from 'icons/bank-transfer.svg';
import creditCardIcon from 'icons/credit-card.svg';

const DEFAULT_LOGO_ALT = '';

const PaymentMethodLogo = ({ paymentMethod, logo }) => {
  const { name } = logo;
  const logoAlt = name || DEFAULT_LOGO_ALT;

  const defaultSource =
    paymentMethod === 'bank_transfer' ? bankIcon : creditCardIcon;
  const { links: { original: source } = {} } = logo;

  const logoSource = source || defaultSource;

  return (
    <span className="Offer-icon">
      <img src={logoSource} alt={logoAlt} data-testid="paymentMethodLogo" />
    </span>
  );
};

PaymentMethodLogo.propTypes = {
  logo: PropTypes.object,
  paymentMethod: PropTypes.string,
};

PaymentMethodLogo.defaultProps = {
  logo: {},
};

export { PaymentMethodLogo };
