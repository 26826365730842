import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'components/Alert/Alert';

function NoOptions({ i18n }) {
  return (
    <Alert>
      <strong>{i18n.t('paymentOptions.noOffers.title')}</strong>{' '}
      {i18n.t('paymentOptions.noOffers.description')}
    </Alert>
  );
}

NoOptions.displayName = 'NoOptions';
NoOptions.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }),
};

export { NoOptions };
