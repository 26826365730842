import React from 'react';
import { useSelector } from 'react-redux';
import { isPayingFromChina, isPayingFromVietnam } from 'selectors';
import { usePeertransferFlags } from 'hooks/usePeertransferFlags/usePeertransferFlags';
import { FF_HOLIDAY_BANNER_CN, FF_HOLIDAY_BANNER_VN } from 'constants/features';

import './HolidayBanner.scss';

function Vietnam() {
  return (
    <div className="HolidayBanner">
      <div className="HolidayBanner-content">
        <div className="HolidayBanner-title">Quan trọng:</div>
        <p className="HolidayBanner-description">
          Xin lưu ý rằng khoảng thời gian từ ngày{' '}
          <b>29 tháng 4 đến hết ngày 1 tháng 5 năm 2024</b> là thời gian nghỉ lễ
          tại Việt Nam. Do đó, các khoản thanh toán chuyển khoản ngân hàng nhận
          được trong thời gian nghỉ lễ này sẽ chỉ được xử lý và trạng thái thanh
          toán sẽ chỉ được cập nhật vào hoặc sau ngày <b>2 tháng 5 năm 2024</b>.
          <br />
          Chúng tôi khuyên bạn nên lập kế hoạch thanh toán trước để tránh bất kỳ
          sự chậm trễ nào trong việc thanh toán của bạn. Chúng tôi xin lỗi nếu
          có bất kỳ sự bất tiện nào gây ra trong khoảng thời gian này và cảm ơn
          sự kiên nhẫn của bạn. Vui lòng truy cập{' '}
          <a
            target="_blank"
            href="https://help.flywire.com/hc/vi"
            rel="noreferrer"
          >
            Trung tâm trợ giúp & hỗ trợ
          </a>{' '}
          của chúng tôi và liên hệ với nhóm hỗ trợ nếu bạn có bất kỳ câu hỏi
          nào!
          <br />
          Chúng tôi chúc bạn một kỳ nghỉ tốt đẹp!
        </p>
      </div>
    </div>
  );
}

function China() {
  return (
    <div className="HolidayBanner">
      <div className="HolidayBanner-content">
        <div className="HolidayBanner-title">重要提醒:</div>
        <p className="HolidayBanner-description">
          请知悉，
          <b>因 2024年6月8日至 2024年6月10日*为*端午节</b>
          ,在此期间您所支付的款项（包含*6月7日*）将仅会在 <b>
            2024年6月11日
          </b>{' '}
          或之后开始处理，并更新付款状态。
          <br />
          您可合理安排您的付款计划，以避免出现费用缴纳的延误。由此造成的不便，我们深表歉意，并感谢您的耐心等待。如您在节假日期间有关于付款的问题，您仍可通过“
          <a
            target="_blank"
            href="https://help.flywire.com/hc/zh-cn"
            rel="noreferrer"
          >
            帮助页面
          </a>
          ”尝试与我们联系咨询！
          <br />
          飞汇在此祝您假期愉快！
        </p>
      </div>
    </div>
  );
}

China.displayName = 'HolidayBanner.China';
Vietnam.displayName = 'HolidayBanner.Vietnam';

const REFETCH_TIMEOUT = 60000;

function HolidayBanner() {
  const fromChina = useSelector(isPayingFromChina);
  const fromVietnam = useSelector(isPayingFromVietnam);

  const shouldDisplayHolidayBanner = fromChina || fromVietnam;

  const { data: flags } = usePeertransferFlags(
    [FF_HOLIDAY_BANNER_CN, FF_HOLIDAY_BANNER_VN],
    {
      refetchInterval: REFETCH_TIMEOUT,
      enabled: shouldDisplayHolidayBanner,
    },
  );

  if (!flags) {
    return null;
  }

  const showChineseBanner = fromChina && flags[FF_HOLIDAY_BANNER_CN];
  const showVietnameseBanner = fromVietnam && flags[FF_HOLIDAY_BANNER_VN];

  return (
    <>
      {showChineseBanner && <China />}
      {showVietnameseBanner && <Vietnam />}
    </>
  );
}

export { HolidayBanner };
