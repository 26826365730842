import React from 'react';
import PropTypes from 'prop-types';

function Content({ children }) {
  return <div className="Offer-text">{children}</div>;
}

Content.displayName = 'Content';
Content.propTypes = {
  children: PropTypes.node,
};

export { Content };
