import { useContext, useMemo } from 'react';
import { TranslationContext } from 'contexts/TranslationContext/TranslationContext';
import {
  I18n,
  extractLocaleCode,
  isRtlLocale,
  translate,
} from './translations';

function useTranslations(): I18n {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }

  const { keys, fallbackKeys } = context;
  const t = useMemo(
    () =>
      function (
        key: string,
        attrs: Record<string, string | JSX.Element> = {},
      ): string {
        return translate(key, attrs, keys, fallbackKeys);
      },
    [keys, fallbackKeys],
  );

  return {
    t,
    currentLocale: context.locale,
    localeCode: extractLocaleCode(context.locale),
    isRtl: isRtlLocale(context.locale),
    setLocale: context.setLocale,
  };
}

export { useTranslations };
