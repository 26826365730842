import { combineReducers } from 'redux';
import { FETCH_SENDER_SUCCESS } from '../../../../constants';
import type { Section } from 'models';

type SenderAction = {
  type: typeof FETCH_SENDER_SUCCESS;
  payload: {
    entities: {
      sections: Record<string, Section>;
    };
  };
};

export const byId = (
  state: Record<string, Section> = {},
  action: SenderAction,
) => {
  switch (action.type) {
    case FETCH_SENDER_SUCCESS:
      const {
        entities: { sections = {} },
      } = action.payload;
      return sections;
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: SenderAction) => {
  switch (action.type) {
    case FETCH_SENDER_SUCCESS:
      const {
        entities: { sections = {} },
      } = action.payload;
      return Object.keys(sections);
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
