import {
  CREATE_ORDER_FAILURE,
  FETCH_PAYMENT_FAILURE,
  FETCH_PAYMENT_REQUEST,
  FETCH_RECIPIENT_FAILURE,
  FETCH_RECIPIENT_FROM_PAYMENT_FAILURE,
} from 'constants/index';

type ErrorsAction =
  | {
      type: typeof CREATE_ORDER_FAILURE;
      payload: {
        param?: string;
      };
    }
  | {
      type:
        | typeof FETCH_PAYMENT_FAILURE
        | typeof FETCH_PAYMENT_REQUEST
        | typeof FETCH_RECIPIENT_FAILURE
        | typeof FETCH_RECIPIENT_FROM_PAYMENT_FAILURE;
      payload?: string[];
    };

const PAYMENT = 'payment';

export const errors = (state: string[] = [], action: ErrorsAction) => {
  switch (action.type) {
    case FETCH_RECIPIENT_FAILURE:
    case FETCH_RECIPIENT_FROM_PAYMENT_FAILURE:
      return [...state, 'recipient'];
    case FETCH_PAYMENT_FAILURE:
      return [...state, PAYMENT];
    case FETCH_PAYMENT_REQUEST:
      return state.filter(err => err !== PAYMENT);
    case CREATE_ORDER_FAILURE:
      const { payload = {} } = action;
      const { param = '' } = payload;

      return param === 'recipient' ? [...state, 'recipient'] : state;
    default:
      return state;
  }
};

export default errors;
