import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DEFAULT_LOCALE, SELECT_INSTITUTION_PATH } from '../../constants';
import { getCurrentLocale } from 'selectors';
import { trackMissingDestination } from 'actions';

const RedirectToSelectInstitutionComponent = ({
  locale,
  trackMissingDestination,
}) => {
  trackMissingDestination();
  window.location.assign(`${SELECT_INSTITUTION_PATH}?language=${locale}`);
  return null;
};

RedirectToSelectInstitutionComponent.propTypes = {
  locale: PropTypes.string,
  trackMissingDestination: PropTypes.func,
};

RedirectToSelectInstitutionComponent.defaultProps = {
  locale: DEFAULT_LOCALE,
  trackMissingDestination: () => {},
};

const mapStateToProps = state => ({
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {
  trackMissingDestination,
};

const RedirectToSelectInstitution = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RedirectToSelectInstitutionComponent);

export { RedirectToSelectInstitution };
