import React from 'react';

const Phone = props => {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m13.701 20.223.011.007a4.918 4.918 0 0 0 6.12-.671l.688-.689a1.64 1.64 0 0 0 0-2.318l-2.9-2.9a1.64 1.64 0 0 0-2.319 0h0a1.64 1.64 0 0 1-2.318 0L8.346 9.017a1.64 1.64 0 0 1 0-2.318h0a1.64 1.64 0 0 0 0-2.32L5.448 1.481a1.64 1.64 0 0 0-2.319 0l-.687.687A4.92 4.92 0 0 0 1.77 8.29l.007.011a44.367 44.367 0 0 0 11.924 11.923h0Z"
        stroke="currentColor"
        strokeWidth="1.25"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Phone;
