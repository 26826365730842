import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrderCallToAction } from 'selectors';
import { trackRedirection } from 'actions';
import type { RootState } from 'reducers/types';

interface RedirectToReturnCTAProps {
  returnCTA: {
    url: string;
  };
  trackRedirection: () => void;
}

export const RedirectToReturnCTAComponent = ({
  returnCTA,
  trackRedirection,
}: RedirectToReturnCTAProps) => {
  const { url } = returnCTA;

  useEffect(() => {
    window.location.assign(url);
    trackRedirection();
  });

  return null;
};

RedirectToReturnCTAComponent.propTypes = {
  returnCTA: PropTypes.object.isRequired,
  trackRedirection: PropTypes.func.isRequired,
};
const mapStateToProps = (state: RootState) => ({
  returnCTA: getOrderCallToAction(state),
});

const mapDispatchToProps = {
  trackRedirection,
};

const RedirectToReturnCTA = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RedirectToReturnCTAComponent);

export { RedirectToReturnCTA };
