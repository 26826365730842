import React, { useState } from 'react';
import { useSelector } from 'react-redux';

type Entity = 'payments' | 'recurringReceivables';

type UsePaginationProps = {
  initialPage?: number;
  entity: Entity;
  perPage?: number;
};

type PageData = {
  next: boolean;
  prev: boolean;
  totalEntries: number;
  totalPages: number;
};

type RootState = {
  ui: {
    pagination: {
      payments: PageData;
      recurringReceivables: PageData;
    };
  };
};

function usePagination({
  initialPage = 1,
  entity,
  perPage = 5,
}: UsePaginationProps) {
  const [currentPage, setCurrentPage] = useState(
    initialPage < 1 ? 1 : initialPage,
  );

  const { totalEntries, totalPages } = useSelector(
    (state: RootState) => state.ui.pagination[entity],
  );

  const hasNextPage = currentPage < totalPages;
  const hasPreviousPage = currentPage > 1;
  const previousPage = currentPage < 1 ? 1 : currentPage - 1;
  const nextPage = currentPage === totalPages ? totalPages : currentPage + 1;
  const handlers = React.useMemo(
    () => ({
      setPreviousPage: () => setCurrentPage(previousPage),
      setNextPage: () => setCurrentPage(nextPage),
    }),
    [previousPage, nextPage],
  );

  return {
    currentPage,
    hasNextPage,
    hasPreviousPage,
    nextPage,
    previousPage,
    totalEntries,
    totalPages,
    perPage,
    ...handlers,
  };
}

export default usePagination;
