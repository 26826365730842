import fetchJson from 'utils/fetchJson';
import { EXCHANGE_URL } from 'constants/index';

export const exchange = {
  fetch: ({
    totalAmount,
    currencyFrom,
    currencyTo,
  }: {
    totalAmount: number;
    currencyFrom: string;
    currencyTo: string;
  }) => {
    const url = `${EXCHANGE_URL}?amount=${totalAmount}&to=${currencyTo}&from=${currencyFrom}`;

    return fetchJson<{ greaterThan10kUSD: boolean }>(url);
  },
};
