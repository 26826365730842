import {
  REFUND_STATUS_INITIATED,
  REFUND_STATUS_RECEIVED,
  REFUND_STATUS_FINISHED,
  REFUND_STATUS_ADVANCED,
  REFUND_STATUS_CANCELLED,
  REFUND_STATUS_RETURN_TO_CLIENT,
} from 'constants/index';
import { Refund } from 'models';

const refundStatusMapper: Record<string, string> = {
  [REFUND_STATUS_INITIATED]: REFUND_STATUS_INITIATED,
  [REFUND_STATUS_RECEIVED]: REFUND_STATUS_RECEIVED,
  [REFUND_STATUS_FINISHED]: REFUND_STATUS_FINISHED,
  [REFUND_STATUS_ADVANCED]: REFUND_STATUS_FINISHED,
  [REFUND_STATUS_CANCELLED]: REFUND_STATUS_CANCELLED,
  [REFUND_STATUS_RETURN_TO_CLIENT]: REFUND_STATUS_CANCELLED,
};

export const getUIRefundStatus = (refund: Refund) => {
  const { status } = refund;

  return refundStatusMapper[status];
};
