import type { RootState } from 'reducers/types';

export const getRecurringReceivables = (state: RootState) => {
  const {
    entities: { recurringReceivables },
  } = state;

  return recurringReceivables;
};

export const getRecurringReceivableCountry = (
  state: RootState,
  recurringReceivableId: string,
) => {
  const {
    entities: { recurringReceivables },
  } = state;

  const recurringReceivable = recurringReceivables.find(
    ({ id }) => id === recurringReceivableId,
  );

  return recurringReceivable?.country;
};
