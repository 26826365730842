const BrowserRepository = {
  getFlywireSubdomain() {
    const { host } = window.location;
    const match = host.match(/(flywire.*)/) || [];

    return match[0];
  },
};

export default BrowserRepository;
