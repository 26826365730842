import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useWizard } from 'components/Wizard/components/context';
import { Payment } from './components/Payment/Payment';
import {
  useCardPaymentContext,
  CardPaymentProvider,
} from './components/Payment/hooks/useCardPaymentContext';
import { Navigation } from 'components/Navigation/Navigation';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import {
  STEP_RECURRING_CARD_PAYMENT,
  SENDER_COUNTRY_FIELD,
  RECIPIENT_FIELD,
} from 'constants/index';
import { createRecurringReceivable, newNotification } from 'actions';
import { getAccessToken, getFieldValues } from 'selectors';
import { useTranslations } from 'utils';
import { RootState } from 'reducers/types';
import { Steps } from './components/Payment/hooks/useSteps';

type CardPaymentProps = ConnectedProps<typeof connector>;

const EMPTY_STRING = '';

function CardPaymentWizard() {
  const { currentStep } = useCardPaymentContext();
  const i18n = useTranslations();
  const { goToPrevStep } = useWizard();
  const navigation = (
    <Navigation
      hideNext
      onClickPrev={goToPrevStep}
      nextStepName={i18n.t('stepsList.track') as string}
    />
  );

  return (
    <PaymentStep
      navigation={navigation}
      stepName={STEP_RECURRING_CARD_PAYMENT}
      title={
        [Steps.Success, Steps.Error].includes(currentStep.id)
          ? EMPTY_STRING
          : (i18n.t('stepsList.pay') as string)
      }
    >
      <Payment />
    </PaymentStep>
  );
}

export function CardPaymentComponent({
  accessToken,
  country,
  recipientId,
  createRecurringReceivable,
  newNotification,
}: CardPaymentProps) {
  const { goToNextStep, goToPrevStep, goToStep } = useWizard();

  return (
    <CardPaymentProvider
      accessToken={accessToken as string}
      createRecurringReceivable={createRecurringReceivable}
      country={country as string}
      newNotification={newNotification}
      recipientId={recipientId as string}
      paymentWizardNavigation={{
        next: goToNextStep,
        prev: goToPrevStep,
        go: goToStep,
      }}
    >
      <CardPaymentWizard />
    </CardPaymentProvider>
  );
}

const mapStateToProps = (state: RootState) => {
  const { [SENDER_COUNTRY_FIELD]: country, [RECIPIENT_FIELD]: recipientId } =
    getFieldValues(state);
  const accessToken = getAccessToken(state);

  return { accessToken, country, recipientId };
};

const mapDispatchToProps = {
  createRecurringReceivable,
  newNotification,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
const CardPayment = connector(CardPaymentComponent);

export { CardPayment };
