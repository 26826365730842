import React from 'react';
import { ReactComponent as StarIcon } from 'icons/ico-star.svg';

function Label({ value }: { value: string }) {
  return (
    <div className="Offer-label">
      <span>
        <StarIcon />
        {value}
      </span>
    </div>
  );
}

Label.displayName = 'Label';

export { Label };
