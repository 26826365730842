import moment from 'moment';
import { rtlLocales } from '../translations';
import { DEFAULT_LOCALE } from '../../constants';

const MINUTES = 'minutes';

const shortMonthDateFormat = (date: moment.MomentInput, locale = 'en') => {
  moment.locale(locale);

  return moment(date).format('ll');
};

const fromUnix = (date: number) => moment.unix(date).format();

const now = () => {
  const currentLocale = moment.locale();

  if (rtlLocales.includes(currentLocale)) {
    const clonedDate = moment();

    return clonedDate.locale(DEFAULT_LOCALE).format();
  }

  return moment().format();
};

const utc = (
  date: moment.MomentInput,
  format: moment.MomentFormatSpecification,
) => moment.utc(date, format);

const utcFormat = (date: moment.MomentInput, format: string) =>
  moment.utc(date).format(format);

const prettyLocale = (date: moment.MomentInput, locale = 'en') => {
  moment.locale(locale);
  return moment(date).format('LL');
};

const future = (
  period: moment.DurationInputArg1,
  fraction: moment.DurationInputArg2,
  format = undefined,
) => moment().add(period, fraction).format(format);

const isBefore = (
  date: moment.MomentInput,
  dateToCompare: moment.MomentInput,
) => moment(date).isBefore(dateToCompare);

const addMonths = (
  date: moment.MomentInput,
  unit: moment.DurationInputArg1,
) => {
  return moment(date).add(unit, 'M').toDate();
};

const getCardinalNumber = (day: number, locale = 'en') => {
  moment.locale(locale);
  return moment.localeData().ordinal(day);
};

export const formatToBrowserLocale = (date: string) => {
  const locale = navigator.language;

  return new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export default {
  addMonths,
  formatToBrowserLocale,
  fromUnix,
  future,
  getCardinalNumber,
  isBefore,
  MINUTES,
  now,
  prettyLocale,
  shortMonthDateFormat,
  utc,
  utcFormat,
};
