import {
  INTERNAL_SERVER_FAILURE,
  REFRESH_TOKEN,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  USER_CREDENTIALS_VERIFICATION_REQUEST,
  USER_CREDENTIALS_VERIFICATION_SUCCESS,
  USER_CREDENTIALS_VERIFICATION_FAILURE,
  EXCHANGE_LOGIN_TOKEN_SUCCESS,
  IMPERSONATE_USER_SUCCESS,
  IMPERSONATE_FAKE_REFRESH_TOKEN,
  IMPERSONATE_EXPIRATION_IN_SECONDS,
  RECOVER_REFRESH_TOKEN,
  FORCE_AUTH_CHECK,
  HTTPStatuses,
  HttpError,
} from '../../constants';
import {
  isFetching,
  getRefreshToken,
  isLoggedIn,
  getCaptchaResponse,
} from 'selectors';
import { payex, type PayexService } from 'services/payex';
import type {
  PayexDispatch,
  PayexGetState,
  PayexThunkAction,
} from 'store/configureStore';
import { errorNotifier } from 'utils/errorNotifier';
import { isHttpErrorWithStatus } from 'utils/errors/errors';
import { getStoredRefreshToken } from '../../store/storage';
import { cleanUserData } from 'actions/entities/user/user';

const USER_NEEDS_TO_CHANGE_PASSWORD = 'user_needs_to_change_password';

type LoginResponse = {
  accessToken: string;
  expiresIn: number;
  refreshToken?: string;
  tokenType: string;
  updatePassword?: boolean;
};

interface LogInStrategy {
  call: (
    payex: PayexService,
    captchaResponse?: string,
  ) => Promise<LoginResponse>;
}

type PasswordStrategyOpts = {
  username: string;
  password: string;
};

const passwordStrategy = (user: PasswordStrategyOpts): LogInStrategy => {
  return {
    call(
      payex: PayexService,
      captchaResponse?: string,
    ): Promise<LoginResponse> {
      return payex.auth.logIn({
        grant_type: 'password',
        ...user,
        ...(captchaResponse && { captcha_response: captchaResponse }),
      });
    },
  };
};

type GoogleStrategyOpts = {
  authCode: string;
};

const googleStrategy = (opts: GoogleStrategyOpts): LogInStrategy => {
  return {
    call(
      payex: PayexService,
      captchaResponse?: string,
    ): Promise<LoginResponse> {
      return payex.auth.logIn({
        grant_type: 'google_oauth',
        auth_code: opts.authCode,
        ...(captchaResponse && { captcha_response: captchaResponse }),
      });
    },
  };
};

const logInUser: (args: {
  payex: typeof payex;
  dispatch: PayexDispatch;
  getState: PayexGetState;
  loginStrategy: LogInStrategy;
  actions: Record<string, string>;
  dispatchPayload?: boolean;
}) => Promise<unknown> = async ({
  loginStrategy,
  payex,
  dispatch,
  getState,
  actions,
  dispatchPayload = false,
}) => {
  const state = getState();
  const isFetchingAuthentication = isFetching(state, 'authentication');
  const { requestAction, successAction, failureAction } = actions;

  if (isFetchingAuthentication) return Promise.resolve();

  dispatch({ type: requestAction });
  const captchaResponse = getCaptchaResponse(state);

  try {
    const response = await loginStrategy.call(payex, captchaResponse);

    const { updatePassword = false } = response;

    if (updatePassword) throw userNeedsToChangePasswordError();

    if (!dispatchPayload) return dispatch({ type: successAction });

    const payload = normalizeAuthPayload(response);

    return dispatch({
      type: successAction,
      payload: { authentication: payload },
    });
  } catch (error) {
    if (isHttpErrorWithStatus(error)) {
      const httpError = error as HttpError;
      if (httpError.status === HTTPStatuses.INTERNAL_SERVER_ERROR) {
        dispatch({ type: INTERNAL_SERVER_FAILURE });
      }
    }

    dispatch({ type: failureAction });
    throw error;
  }
};

const userNeedsToChangePasswordError = () => {
  const error: HttpError = new Error(USER_NEEDS_TO_CHANGE_PASSWORD);
  error.parsedBody = { errors: [{ error: USER_NEEDS_TO_CHANGE_PASSWORD }] };

  return error;
};

const logInWithStrategy =
  (loginStrategy: LogInStrategy): PayexThunkAction =>
  async (dispatch, getState, { payex }) => {
    const actions = {
      requestAction: LOGIN_USER_REQUEST,
      successAction: LOGIN_USER_SUCCESS,
      failureAction: LOGIN_USER_FAILURE,
    };

    const dispatchPayload = true;

    return logInUser({
      loginStrategy,
      dispatch,
      getState,
      actions,
      dispatchPayload,
      payex,
    });
  };

export const googleSignIn = (
  signInOpts: GoogleStrategyOpts,
): PayexThunkAction => {
  return logInWithStrategy(googleStrategy(signInOpts));
};

export const logIn = (signInOpts: PasswordStrategyOpts): PayexThunkAction => {
  return logInWithStrategy(passwordStrategy(signInOpts));
};

export const verifyUserCredentials =
  (user: PasswordStrategyOpts): PayexThunkAction =>
  async (dispatch, getState, { payex }) => {
    const actions = {
      requestAction: USER_CREDENTIALS_VERIFICATION_REQUEST,
      successAction: USER_CREDENTIALS_VERIFICATION_SUCCESS,
      failureAction: USER_CREDENTIALS_VERIFICATION_FAILURE,
    };

    return logInUser({
      loginStrategy: passwordStrategy(user),
      dispatch,
      getState,
      actions,
      payex,
    });
  };

export const refreshToken =
  (): PayexThunkAction =>
  async (dispatch, getState, { payex }) => {
    const GRANT_TYPE = 'refresh_token';
    const state = getState();
    const refreshToken = getRefreshToken(state);

    if (!refreshToken || refreshToken === IMPERSONATE_FAKE_REFRESH_TOKEN)
      return;

    try {
      const response = await payex.auth.refreshToken({
        refresh_token: refreshToken,
        grant_type: GRANT_TYPE,
      });

      const payload = normalizeAuthPayload(response);
      return dispatch(setRefreshToken(payload));
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const { status } = error as HttpError;

        if (status === HTTPStatuses.UNAUTHORIZED) {
          dispatch(cleanUserData());
        } else {
          errorNotifier.notify(error);
        }
      }
    }
  };

export const recoverRefreshTokenIfNeeded =
  (): PayexThunkAction => async (dispatch, getState) => {
    const state = getState();
    const loggedIn = isLoggedIn(state);
    const storedRefreshToken = getStoredRefreshToken();

    if (!loggedIn && storedRefreshToken) {
      dispatch({
        type: RECOVER_REFRESH_TOKEN,
        payload: {
          refreshToken: decodeURIComponent(storedRefreshToken),
        },
      });
    }
    dispatch({
      type: FORCE_AUTH_CHECK,
    });
  };

export const setRefreshToken = (payload = {}) => ({
  type: REFRESH_TOKEN,
  payload,
});

export const exchangeLoginToken =
  (loginToken: string): PayexThunkAction =>
  async (dispatch, _getState, { apiV3 }) => {
    const response = await apiV3.authentication.exchangeLoginToken({
      loginToken,
    });

    if (!response.refreshToken) return;

    dispatch({ type: EXCHANGE_LOGIN_TOKEN_SUCCESS, payload: response });

    return dispatch(refreshToken());
  };

export const impersonate =
  (accessToken: string): PayexThunkAction =>
  async dispatch => {
    const response = {
      expiresIn: IMPERSONATE_EXPIRATION_IN_SECONDS,
      accessToken: accessToken,
      refreshToken: IMPERSONATE_FAKE_REFRESH_TOKEN,
      tokenType: 'bearer',
      updatePassword: false,
    };

    const payload = normalizeAuthPayload(response);

    return dispatch({
      type: IMPERSONATE_USER_SUCCESS,
      payload: { authentication: payload },
    });
  };

export const normalizeAuthPayload = (payload: LoginResponse) => {
  const expiresIn = payload.expiresIn;
  const currentTimestamp = Math.round(Date.now() / 1000);
  const expiresInTimestamp = currentTimestamp + expiresIn;

  return { ...payload, expiresIn: expiresInTimestamp };
};
