import { cookies } from 'utils/cookies/cookies';
import { getKeepSession, getRefreshToken } from 'selectors';
import { AUTHENTICATION_COOKIE_NAME } from '../../constants';
import { RootState } from 'reducers/types';

function flywireDomain() {
  return (window.location.host.match(/(flywire.*)/) || [])?.[0];
}

export const getStoredRefreshToken = () => {
  const serializedRefreshToken = cookies.read(AUTHENTICATION_COOKIE_NAME);

  if (serializedRefreshToken === null) {
    return undefined;
  }

  const { refreshToken } = JSON.parse(serializedRefreshToken);
  return refreshToken;
};

export const loadStateFromCookie = () => {
  try {
    const serializedRefreshToken = cookies.read(AUTHENTICATION_COOKIE_NAME);

    if (serializedRefreshToken === null) {
      return undefined;
    }

    const { refreshToken, keepSession } = JSON.parse(serializedRefreshToken);
    return {
      entities: {
        authentication: {
          refreshToken: decodeURIComponent(refreshToken),
        },
      },
      ui: {
        authentication: {
          keepSession,
        },
      },
    };
  } catch (err) {
    return undefined;
  }
};

export const saveStateToCookie = (state: RootState) => {
  try {
    const shouldKeepSession = getKeepSession(state);
    const refreshToken = getRefreshToken(state);

    if (!refreshToken) return;

    cookies.set(
      AUTHENTICATION_COOKIE_NAME,
      JSON.stringify({
        refreshToken: encodeURIComponent(refreshToken),
        keepSession: shouldKeepSession,
      }),
      !shouldKeepSession,
      flywireDomain(),
    );
  } catch (e) {}
};

export const removeCookiesAuth = () => {
  cookies.delete(AUTHENTICATION_COOKIE_NAME, flywireDomain());
};
