import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardElement,
  type CardElementError,
  type CardElementEvent,
  type OnBinLookupResult,
  type PayerInfo,
} from '@flywire/internal-react-elements';
import {
  trackAcceptAndContinueCardSurchargePaymentWithFee,
  trackCardTokenizationSuccessForCardSurchargePayment,
} from 'actions/tracker/tracker';
import { getAccessToken } from 'selectors/entities/authentication/authentication';
import { getFieldIdsAndValues } from 'selectors/ui/ui';
import { getFieldByEntity } from 'selectors/entities/fields/fields';
import {
  getSenderCountry,
  getSenderCurrency,
  getOrder,
} from 'selectors/entities/order/order';
import { cardOnFile } from 'services/cardOnFile/cardOnFile';
import { useCreateCheckoutSession } from '../useCreateCheckoutSession';
import { useCharge, type ChargeError } from '../useCharge';
import { CardFormContent } from '../CardFormContent';
import { SurchargeModal } from '../SurchargeModal';
import { RedirectionModal } from '../RedirectionModal';
import { Success } from '../Success';
import { Error as ErrorComponent } from '../Error';
import { FLYWIRE_JS_ENVIRONMENT } from 'constants/config';
import { CARD_CHARGE_ERRORS } from 'constants/errors';
import { errorNotifier } from 'utils/errorNotifier/errorNotifier';
import type { RootState } from 'reducers/types';

type CardElementRef = {
  submit: () => Promise<void>;
};
type CardDetails = {
  cardClassification: string;
  country: string;
  productType: string;
};
type SuccessResponse = {
  confirm: {
    method: string;
    url: string;
  };
};
type Decision = {
  action: 'charge' | 'redirection' | 'surcharge';
  percentage?: number;
};
type ErrorType = ChargeError | 'session';

const CardForm = () => {
  const ref = useRef<CardElementRef>(null);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [cardDetails, setCardDetails] = useState<CardDetails>({
    cardClassification: '',
    country: '',
    productType: '',
  });
  const [showSurchargeModal, setShowSurchargeModal] = useState(false);
  const [showRedirectionModal, setShowRedirectionModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [decision, setDecision] = useState<Decision>({ action: 'charge' });
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [chargeErrorType, setChargeErrorType] = useState<ErrorType>('failed');

  const accessToken = useSelector(getAccessToken);
  const senderCountry = useSelector(getSenderCountry);
  const currency = useSelector(getSenderCurrency);
  const order = useSelector(getOrder);

  const onChargeError = async (error: ChargeError) => {
    if (!CARD_CHARGE_ERRORS.includes(error)) {
      error = 'generic';
    }

    setChargeErrorType(error);

    setShowErrorComponent(true);
  };

  const {
    isError: chargeError,
    isLoading: isCharging,
    isSuccess,
    mutate: charge,
  } = useCharge(onChargeError);

  useEffect(() => {
    setShowErrorComponent(chargeError);
  }, [chargeError]);

  const { createSession, createSessionError, sessionId } =
    useCreateCheckoutSession({
      sessionType: 'card_tokenization_for_one_off',
    });

  useEffect(() => {
    if (createSessionError) {
      setChargeErrorType('session');
      setShowErrorComponent(true);
    }
  }, [createSessionError]);

  const { ids } = useSelector((state: RootState) =>
    getFieldByEntity(state, 'sender'),
  );
  const senderFields = useSelector((state: RootState) =>
    getFieldIdsAndValues(state, ids),
  );
  const payerInfo: Omit<PayerInfo, 'firstName' | 'lastName'> = {
    address: senderFields.sender_address1 as string,
    city: senderFields.sender_city as string,
    country: senderFields.sender_country as string,
    email: senderFields.sender_email as string,
    phone: senderFields.sender_phone as string,
    state: senderFields.sender_state as string,
    zip: (senderFields.sender_zip as string) || '',
  };

  const environment = FLYWIRE_JS_ENVIRONMENT;

  const onStartLoading = () => setIsLoading(true);

  const onEndLoading = () => setIsLoading(false);

  const onBinLookup = async (response: OnBinLookupResult) => {
    if (response.status === 'success') {
      setCardDetails(response.result);
    } else {
      return;
    }

    try {
      const decision = await cardOnFile.chargeDecision({
        cardClassification: response.result.cardClassification,
        cardCountry: response.result.country,
        cardProductType: response.result.productType,
        orderId: order.id,
        orderToken: order.token,
      });

      setDecision(decision);
    } catch (error) {
      setDecision({ action: 'charge' });
      errorNotifier.notify(error);
    }
  };

  const onSubmit = async () => {
    if (!isFormValid) {
      submit();
      return;
    }

    switch (decision.action) {
      case 'redirection':
        setShowRedirectionModal(true);
        break;
      case 'surcharge':
        setShowSurchargeModal(true);
        break;
      case 'charge':
      default:
        submit();
    }
  };

  const onSuccess = (result: SuccessResponse) => {
    const {
      confirm: { url: confirmURL },
    } = result;

    dispatch(
      trackCardTokenizationSuccessForCardSurchargePayment({
        productType: cardDetails.productType,
      }),
    );

    charge({
      accessToken,
      confirmURL,
      cardClassification: cardDetails.cardClassification,
      cardCountry: cardDetails.country,
      cardProductType: cardDetails.productType,
      orderId: order.id,
      orderToken: order.token,
      sessionId,
    });
  };

  const onEvent = (event: CardElementEvent) => {
    if (event.type === 'form_changed') setIsFormValid(event.extra.isValid);
  };

  const onError = async (error: CardElementError) => {
    setIsLoading(false);

    if (error.type !== 'invalid_card_info') {
      switch (error.type) {
        case 'invalid_payer_info':
          errorNotifier.notify(
            new Error('CardForm error: Invalid payer information'),
          );
          break;
        case 'not_found':
          errorNotifier.notify(new Error('CardForm error: Session expired'));
          break;
        case 'challenge_error':
          break;
        case 'internal_error':
          errorNotifier.notify(
            new Error(`CardForm error: ${error.extra.message}`),
          );
          break;
      }

      setShowErrorComponent(true);
    }
  };

  const onErrorRetry = async () => {
    await createSession();
    setShowErrorComponent(false);
  };

  const handleSurchargeModalClose = () => setShowSurchargeModal(false);

  const handleAcceptSurcharge = async () => {
    dispatch(
      trackAcceptAndContinueCardSurchargePaymentWithFee({
        productType: cardDetails.productType,
      }),
    );
    setShowSurchargeModal(false);
    submit();
  };

  const submit = async () => await ref.current?.submit();

  return (
    <>
      <SurchargeModal
        cardProductType={cardDetails.productType}
        feeRate={decision.percentage || 0}
        handleAcceptSurcharge={handleAcceptSurcharge}
        handleModalClose={handleSurchargeModalClose}
        show={showSurchargeModal}
      />
      <RedirectionModal
        cardProductType={cardDetails.productType}
        country={senderCountry}
        show={showRedirectionModal}
      />
      {showErrorComponent ? (
        <ErrorComponent
          cardProductType={cardDetails.productType}
          error={chargeErrorType}
          onRetry={onErrorRetry}
        />
      ) : (
        <CardElement
          currency={currency?.code}
          environment={environment}
          onEndLoading={onEndLoading}
          onError={onError}
          onEvent={onEvent}
          onStartLoading={onStartLoading}
          onSuccess={onSuccess}
          onBinLookup={onBinLookup}
          payerInfo={payerInfo}
          sessionId={sessionId}
          ref={ref}
        >
          <CardFormContent
            isLoading={isLoading || isCharging}
            onSubmit={onSubmit}
          />
        </CardElement>
      )}
      {isSuccess && <Success />}
    </>
  );
};

export { CardForm };
