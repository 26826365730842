import React from 'react';
import PropTypes from 'prop-types';

import './Notes.scss';

function Notes({ i18n }) {
  return (
    <div className="Notes">
      <div className="Notes-content">
        <div className="Notes-title">
          {i18n.t('paymentOptions.recurringPayment.notes.title')}
        </div>
        <p className="Notes-description">
          {i18n.t('paymentOptions.recurringPayment.notes.description')}
        </p>
      </div>
    </div>
  );
}

Notes.displayName = 'EURateDisclaimer';
Notes.propTypes = {
  i18n: PropTypes.shape({ t: PropTypes.func }),
};

export { Notes };
