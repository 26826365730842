import { combineReducers } from 'redux';
import {
  CREATE_PAYMENT_SUCCESS,
  FETCH_OFFERS_SUCCESS,
  FETCH_PAYMENT_SUCCESS,
  FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS,
  FETCH_RECIPIENT_SUCCESS,
  FETCH_RECIPIENTS_SUCCESS,
  FETCH_LEGACY_RECURRING_OFFERS_SUCCESS,
  FETCH_RECURRING_OFFERS_SUCCESS,
  FETCH_USER_PAYMENTS_SUCCESS,
  SELECT_PAYMENT,
} from 'constants/index';
import type { Currency, Payment, Price, Recipient } from 'models';

type CurrenciesAction =
  | {
      type: typeof FETCH_USER_PAYMENTS_SUCCESS;
      payload: {
        payments: Payment[];
      };
    }
  | {
      type:
        | typeof CREATE_PAYMENT_SUCCESS
        | typeof FETCH_OFFERS_SUCCESS
        | typeof FETCH_LEGACY_RECURRING_OFFERS_SUCCESS
        | typeof FETCH_RECURRING_OFFERS_SUCCESS
        | typeof FETCH_PAYMENT_SUCCESS
        | typeof SELECT_PAYMENT;
      payload: {
        entities: {
          currencies: Record<string, Currency>;
        };
        result: string[];
      };
    }
  | {
      type: typeof FETCH_RECIPIENTS_SUCCESS;
      payload: {
        entities: {
          recipients: Record<string, Recipient>;
        };
        result: string[];
      };
    }
  | {
      type:
        | typeof FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS
        | typeof FETCH_RECIPIENT_SUCCESS;
      payload: {
        entities: {
          currencies: Record<string, Currency>;
        };
      };
    };

export const byId = (
  state: Record<string, Currency> = {},
  action: CurrenciesAction,
) => {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
    case FETCH_OFFERS_SUCCESS:
    case FETCH_LEGACY_RECURRING_OFFERS_SUCCESS:
    case FETCH_RECURRING_OFFERS_SUCCESS:
    case FETCH_RECIPIENT_SUCCESS:
    case FETCH_PAYMENT_SUCCESS:
    case FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS: {
      const {
        entities: { currencies = {} },
      } = action.payload;
      return { ...state, ...currencies };
    }
    case FETCH_USER_PAYMENTS_SUCCESS: {
      const { payments = [] } = action.payload;
      const newState: Record<string, Currency> = {};

      payments.forEach(payment => {
        newState[((payment.price as Price).currency as Currency).code] = (
          payment.price as Price
        ).currency as Currency;
      });

      return { ...state, ...newState };
    }
    case FETCH_RECIPIENTS_SUCCESS: {
      const recipients = action?.payload?.entities?.recipients || {};
      const recipientIds = action?.payload?.result || [];
      const newState: Record<string, Currency> = {};

      recipientIds.forEach((id: string) => {
        const currency = recipients[id].currency as Currency;
        if (currency) {
          newState[currency.code] = currency;
        }
      });

      return { ...state, ...newState };
    }
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: CurrenciesAction) => {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
    case FETCH_OFFERS_SUCCESS:
    case FETCH_RECIPIENT_SUCCESS:
    case FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS:
    case FETCH_PAYMENT_SUCCESS: {
      const {
        entities: { currencies = {} },
      } = action.payload;
      const currencyIds = Object.keys(currencies);
      return [...state, ...currencyIds];
    }
    case FETCH_USER_PAYMENTS_SUCCESS: {
      const { payments = [] } = action.payload;
      const currencyIds = payments.map(
        payment => ((payment.price as Price).currency as Currency).code,
      );

      return [...new Set([...state, ...currencyIds])];
    }
    case FETCH_RECIPIENTS_SUCCESS: {
      const recipients = action?.payload?.entities?.recipients || {};
      const recipientIds = action?.payload?.result || [];
      const currencyIds = recipientIds.map(
        id => (recipients[id].currency as Currency)?.code,
      );

      return [...new Set([...state, ...currencyIds])];
    }
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
