import { combineReducers } from 'redux';

import duplicatedTaxPayment from './duplicatedTaxPayment';
import tax from './tax';
import paymentProof from './paymentProof';
import cancellationFailed from './cancellationFailed';
import { remainingBalancePaymentCreated } from './remainingBalancePaymentCreated/remainingBalancePaymentCreated';

export default combineReducers({
  duplicatedTaxPayment,
  tax,
  paymentProof,
  cancellationFailed,
  remainingBalancePaymentCreated,
});
