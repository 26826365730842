import { combineReducers } from 'redux';
import entities from './entities';
import errors from './errors';
import isFetching from './isFetching';
import publicKey from './publicKey';
import ui from './ui';
import geolocation from './geolocation';
import features from './features';

export default combineReducers({
  entities,
  errors,
  features,
  geolocation,
  isFetching,
  publicKey,
  ui,
});
