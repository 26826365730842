import fetchJson from '../../../utils/fetchJson';
import { API_URL_V3 } from '../../../constants';
import { type ExchangeLoginTokenPayload, type RevokePayload } from './types';

export const authentication = {
  revoke: ({ ...payload }: RevokePayload) => {
    const path = `${API_URL_V3}/oauth/revoke`;
    const body = JSON.stringify(payload);

    return fetchJson<void>(path, { method: 'POST', body });
  },
  exchangeLoginToken: ({ loginToken }: ExchangeLoginTokenPayload) => {
    const path = `${API_URL_V3}/refresh_token_exchanger/${loginToken}`;

    return fetchJson<{ refreshToken?: string }>(path, { method: 'GET' });
  },
};
