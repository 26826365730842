import React from 'react';
import { createMarkup } from 'utils/createMarkup/createMarkup';
import { useTranslations } from 'utils';

import './FeeDisclosure.scss';

function FeeDisclosure() {
  const i18n = useTranslations();

  return (
    <div className="FeeDisclosure">
      <p
        className="FeeDisclosure-content"
        dangerouslySetInnerHTML={createMarkup(
          i18n.t('paymentOptions.fee.disclosure'),
        )}
      />
    </div>
  );
}

export { FeeDisclosure };
