import { extractLocaleCode, cmsLocales } from 'utils/translations';
import { FLYSITE_URL, DEFAULT_LOCALE, FLYSITE_HELP_URL } from 'constants/index';

const CONTACT_PATH = 'company/contact';
const PRIVACY_PATH = 'legal/privacy-policy';
const TERMS_PATH = 'legal/terms-of-use';
const RECURRING_TERMS_PATH = 'legal/automated-payments-terms-of-use';
const BEST_PRICE_GUARANTEE_PATH = 'legal/best-price-guarantee';
const COOKIE_POLICY = 'legal/cookie-policy';

export const getFlysitePathUrl = (path: string, locale = DEFAULT_LOCALE) => {
  return `${getFlysiteUrl(locale)}/${path}`;
};

export const getFlysiteUrl = (locale = DEFAULT_LOCALE) => {
  const localeCode = extractLocaleCode(locale).toLowerCase();
  const supportedCmsLocale = cmsLocales.find(
    cmsLocale => localeCode === extractLocaleCode(cmsLocale).toLowerCase(),
  );

  const localePath =
    localeCode === DEFAULT_LOCALE || !supportedCmsLocale
      ? ''
      : `/${localeCode}`;

  return `${FLYSITE_URL}${localePath}`;
};

export const helpUrl = (locale = DEFAULT_LOCALE) => {
  const localeCode = extractLocaleCode(locale).toLowerCase();
  const supportedCmsLocale = cmsLocales.find(
    cmsLocale => localeCode === extractLocaleCode(cmsLocale).toLowerCase(),
  );
  const localePath =
    localeCode === DEFAULT_LOCALE || !supportedCmsLocale
      ? '/en-us'
      : `/${localeCode}`;

  return `${FLYSITE_HELP_URL}/hc${localePath}`;
};

export const flysiteUrl = (locale: string) => getFlysiteUrl(locale);
export const contactUrl = (locale: string) =>
  getFlysitePathUrl(CONTACT_PATH, locale);
export const supportUrl = (locale: string) => helpUrl(locale);
export const privacyUrl = (locale: string) =>
  getFlysitePathUrl(PRIVACY_PATH, locale);
export const termsUrl = (locale: string) =>
  getFlysitePathUrl(TERMS_PATH, locale);
export const recurringTermsUrl = (locale: string) =>
  getFlysitePathUrl(RECURRING_TERMS_PATH, locale);
export const bestPriceGuaranteeUrl = (locale: string) =>
  getFlysitePathUrl(BEST_PRICE_GUARANTEE_PATH, locale);
export const cookiePolicyUrl = (locale: string) =>
  getFlysitePathUrl(COOKIE_POLICY, locale);
