import {
  FETCH_RECIPIENT_COUNTRIES_REQUEST,
  FETCH_RECIPIENT_COUNTRIES_SUCCESS,
  FETCH_RECIPIENT_FAILURE,
  FETCH_RECIPIENT_FROM_PAYMENT_FAILURE,
  FETCH_RECIPIENT_FROM_PAYMENT_REQUEST,
  FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS,
  FETCH_RECIPIENT_REQUEST,
  FETCH_RECIPIENT_SUCCESS,
  FETCH_RECIPIENTS_FAILURE,
  FETCH_RECIPIENTS_REQUEST,
  FETCH_RECIPIENTS_SUCCESS,
  SET_DEFAULT_FIELD_VALUES,
  SET_FIELDS_VALUES,
} from 'constants/index';
import {
  getCurrentLocale,
  getFieldValue,
  getFieldByEntity,
  getRecipientsIds,
  isFetching,
} from 'selectors';
import { errorNotifier } from 'utils/errorNotifier';
import get from 'lodash/get';
import {
  normalizeRecipient,
  normalizeRecipientCountries,
  normalizeRecipients,
  fetch as recipientsServiceFetch,
} from 'services/recipients';
import { RecipientsService } from '../../../services/apiV3/recipients/recipients';
import { Recipient } from 'models';
import type { PayexDispatch, PayexThunkAction } from 'store/configureStore';
import type { RootState } from 'reducers/types';

export const fetchRecipient =
  (id: string): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();

    if (isFetching(state, 'recipient')) return Promise.resolve();

    dispatch({
      type: FETCH_RECIPIENT_REQUEST,
    });

    const locale = getCurrentLocale(state);

    return (apiV3.recipients as RecipientsService)
      .fetch({ id, locale })
      .then(response => handleRecipientResponse(response, dispatch, state))
      .catch(error => {
        handleRecipientError(error, dispatch);
        throw error;
      });
  };

export const fetchRecipientFromPayment =
  (id: string): PayexThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    if (isFetching(state, 'recipient')) return Promise.resolve();

    dispatch({ type: FETCH_RECIPIENT_FROM_PAYMENT_REQUEST });

    const locale = getCurrentLocale(state);

    try {
      const response = await recipientsServiceFetch({ id, locale });
      dispatch({
        type: FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: FETCH_RECIPIENT_FROM_PAYMENT_FAILURE });
      throw error;
    }
  };

export const fetchRecipientBySubdomain =
  (subdomain: string): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const isFetchingRecipient = isFetching(state, 'recipient');
    if (isFetchingRecipient) return Promise.resolve();

    dispatch({
      type: FETCH_RECIPIENT_REQUEST,
    });

    const locale = getCurrentLocale(state);

    return (apiV3.recipients as RecipientsService)
      .fetchBySubdomain({ subdomain, locale })
      .then(response => {
        handleRecipientResponse(response, dispatch, state);
      })
      .catch(() => {
        return (apiV3.recipients as RecipientsService)
          .fetch({ id: subdomain, locale })
          .then(response => handleRecipientResponse(response, dispatch, state))
          .catch(error => handleRecipientError(error, dispatch));
      });
  };

export const fetchRecipientByCode =
  (id: string): PayexThunkAction =>
  (dispatch, getState, { apiV3 }) => {
    const state = getState();

    const isFetchingRecipient = isFetching(state, 'recipient');
    if (isFetchingRecipient) return Promise.resolve();

    dispatch({
      type: FETCH_RECIPIENT_REQUEST,
    });

    const locale = getCurrentLocale(state);

    return (apiV3.recipients as RecipientsService)
      .fetch({ id, locale })
      .then(response => handleRecipientResponse(response, dispatch, state))
      .catch(error => handleRecipientError(error, dispatch));
  };

const handleRecipientResponse = (
  response: Recipient,
  dispatch: PayexDispatch,
  state: RootState,
) => {
  const payload = normalizeRecipient(response);

  dispatch({
    type: FETCH_RECIPIENT_SUCCESS,
    payload,
  });

  const payloadWithoutPrefilledFields = removePrefilledFields(state, payload);

  dispatch({
    type: SET_DEFAULT_FIELD_VALUES,
    payload: payloadWithoutPrefilledFields,
  });
};

const handleRecipientError = (error: unknown, dispatch: PayexDispatch) => {
  errorNotifier.notify(error);
  dispatch({ type: FETCH_RECIPIENT_FAILURE });
};

const removePrefilledFields = (
  state: RootState,
  payload: ReturnType<typeof normalizeRecipient>,
) => {
  const NO_VALUE = '';
  const newPayload = { ...payload };

  newPayload.entities = { ...payload.entities };

  const {
    entities: { fields = {} },
  } = newPayload;

  newPayload.entities.fields = Object.keys(fields)
    .filter(field => getFieldValue(state, field) === NO_VALUE)
    .reduce((acc, key) => {
      // @ts-expect-error string is not a key
      acc[key] = fields[key];
      return acc;
    }, {});

  return newPayload;
};

export const fetchRecipients =
  (idOrIds: string[] = []): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingRecipients = isFetching(state, 'recipients');

    if (isFetchingRecipients) return Promise.resolve();

    const ids = Array.isArray(idOrIds) ? idOrIds : new Array(idOrIds);
    const locale = getCurrentLocale(state);

    dispatch({
      type: FETCH_RECIPIENTS_REQUEST,
    });

    try {
      const recipients = await Promise.all(
        ids.map(async id => apiV3.recipients.fetch({ id, locale })),
      );

      dispatch({
        type: FETCH_RECIPIENTS_SUCCESS,
        payload: normalizeRecipients(recipients),
      });
    } catch (error) {
      errorNotifier.notify(error);

      dispatch({ type: FETCH_RECIPIENTS_FAILURE });
    }
  };

export const fetchPaymentsRecipient =
  (): PayexThunkAction => async (dispatch, getState) => {
    const state = getState();
    const ids: string[] = getRecipientsIds(state);

    return dispatch(fetchRecipients(ids));
  };

export const fetchRecipientCountries =
  (recipientId: string): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingRecipient = isFetching(state, 'recipient');

    if (isFetchingRecipient) return Promise.resolve();
    dispatch({
      type: FETCH_RECIPIENT_COUNTRIES_REQUEST,
    });

    const response = await apiV3.recipients.fetchCountries({
      recipientId,
      locale: getCurrentLocale(state),
    });

    dispatch({
      type: FETCH_RECIPIENT_COUNTRIES_SUCCESS,
      payload: normalizeRecipientCountries(response),
    });
  };

export const resetRecipientHiddenFields =
  (): PayexThunkAction => (dispatch, getState) => {
    const state = getState();

    const { byId = {}, ids = [] } = getFieldByEntity(state, 'recipient') || {};

    const fields = ids.reduce((acc, current) => {
      if (!get(byId, `${current}.hidden`, false)) {
        return acc;
      }

      return { ...acc, [current]: get(byId, `${current}.value`, false) };
    }, {});

    return dispatch({
      type: SET_FIELDS_VALUES,
      payload: { fields },
    });
  };
