import React from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { CAMPUS_FIELD } from 'constants/index';
import {
  amountFrom,
  amountTo,
  getCurrentCountry,
  getExtraFees,
  getFieldById,
  getFieldValue,
  getHiddenFieldIds,
  getItems,
  getRecipient,
  getTaxes,
  isFetching,
  isSelectedOfferLegacyInstallment,
  isSelectedOfferLegacySubscription,
  isSelectedOfferRecurring,
} from 'selectors';
import { Breakdown } from '../Breakdown/Breakdown';
import { SelectedOfferFees } from './SelectedOfferFees/SelectedOfferFees';
import { FormattedAmount } from 'components/FormattedAmount/FormattedAmount';
import { RecurringReceivable } from 'containers/RecurringReceivable/RecurringReceivable';
import { Spinner } from 'components/Spinner/Spinner';
import { AmountFromWithFeesAndTaxes } from './AmountFromWithFeesAndTaxes/AmountFromWithFeesAndTaxes';
import { IsNotCAP } from 'components/CAP/IsNotCAP/IsNotCAP';
import { Discounts } from '../Discounts/Discounts';
import { useTranslations } from 'utils/translations/useTranslations';
import type { RootState } from 'reducers/types';

import './PaymentInformationBreakdown.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentInformationBreakdownComponent = ({
  amountFrom,
  amountTo,
  campus,
  country,
  extraFees,
  getFieldValue,
  hiddenFieldIds,
  isFetchingOrder,
  isLegacyInstallment = false,
  isLegacySubscription = false,
  isRecurring = false,
  items,
  recipient,
  taxes,
}: PropsFromRedux) => {
  const i18n = useTranslations();

  const itemsRows = items.map(item => {
    const itemValue = getFieldValue(item.id);

    return (
      <div key={item.id}>
        <dt>{item.label}</dt>
        <dd>
          <FormattedAmount
            value={itemValue as number}
            currency={amountTo.currency as string}
          />
          <span className="sr-only">,</span>
        </dd>
      </div>
    );
  });

  const { name: countryName } = country;
  const hasMultipleItems = () => items.length > 1;
  const isCampusFieldShowable = () => !hiddenFieldIds.includes(CAMPUS_FIELD);
  const shouldRenderCampus =
    campus && campus.text !== '' && isCampusFieldShowable();
  const hasFeesOrTaxes = taxes.length > 0 || extraFees.length > 0;

  return (
    <Breakdown
      id="paymentInformation"
      title={i18n.t('paymentInformationBreakdown.paymentInformation')}
    >
      <div
        id="paymentInformation-description"
        className="paymentInformation-description"
      >
        <dl>
          {isLegacyInstallment ? (
            <RecurringReceivable
              render={({ amountTo, currencyFrom, currencyTo, fee }) => (
                <>
                  <div>
                    <dt>{i18n.t('paymentInformationBreakdown.amountFrom')}</dt>
                    <dd>
                      <FormattedAmount value={fee} currency={currencyFrom} />
                    </dd>
                  </div>
                  <div>
                    {hasMultipleItems() && itemsRows}
                    <dt>
                      {i18n.t('paymentInformationBreakdown.amountTo', {
                        recipient: recipient.name as string,
                      })}
                    </dt>
                    <dd>
                      <FormattedAmount.Round
                        value={amountTo}
                        currency={currencyTo}
                      />
                      <span className="sr-only">,</span>
                    </dd>
                  </div>
                </>
              )}
            />
          ) : isLegacySubscription ? (
            <>
              <div>
                <dt>{i18n.t('paymentInformationBreakdown.firstRecurring')}</dt>
                <dd>
                  <FormattedAmount
                    value={amountFrom.value as number}
                    currency={amountFrom.currency as string}
                  />
                  <span className="sr-only">,</span>
                </dd>
              </div>
              <div>
                <dt>
                  {i18n.t('paymentInformationBreakdown.amountTo', {
                    recipient: recipient.name as string,
                  })}
                </dt>
                <dd>
                  <FormattedAmount
                    value={amountTo.value}
                    currency={amountTo.currency as string}
                  />
                  <span className="sr-only">,</span>
                </dd>
              </div>
            </>
          ) : (
            <>
              <div>
                <dt>
                  {isRecurring
                    ? i18n.t('paymentInformationBreakdown.recurring.amountFrom')
                    : i18n.t('paymentInformationBreakdown.amountFrom')}
                </dt>
                <dd data-testid="amount-from">
                  {isFetchingOrder() ? (
                    <Spinner className="paymentInformation-spinner" />
                  ) : (
                    <>
                      {hasFeesOrTaxes ? (
                        <AmountFromWithFeesAndTaxes
                          amountFrom={amountFrom}
                          taxes={taxes}
                          i18n={i18n}
                          extraFees={extraFees}
                        />
                      ) : (
                        <FormattedAmount
                          value={amountFrom.value as number}
                          currency={amountFrom.currency as string}
                        />
                      )}

                      <IsNotCAP>
                        <SelectedOfferFees />
                      </IsNotCAP>

                      <span className="sr-only">,</span>
                    </>
                  )}
                </dd>
              </div>

              <div>
                <dt>
                  {i18n.t('paymentInformationBreakdown.amountTo', {
                    recipient: recipient.name as string,
                  })}
                </dt>
                <dd>
                  <FormattedAmount
                    value={amountTo.value}
                    currency={amountTo.currency as string}
                  />
                  <span className="sr-only">,</span>
                </dd>
              </div>
            </>
          )}
          <Discounts />
          <div>
            <dt>{i18n.t('paymentInformationBreakdown.country.label')}</dt>
            <dd data-testid="sender-country" data-hj-suppress>
              {countryName}
            </dd>
          </div>

          {shouldRenderCampus && (
            <div>
              <dt data-testid="campusLabel">
                {i18n.t('paymentInformationBreakdown.campus.label')}
              </dt>
              <dd data-hj-suppress data-testid="campus">
                {campus.text}
              </dd>
            </div>
          )}
        </dl>
        {hasMultipleItems() && <dl>{itemsRows}</dl>}
      </div>
    </Breakdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  amountFrom: amountFrom(state),
  amountTo: amountTo(state),
  campus: getFieldById(state, 'campus'),
  country: getCurrentCountry(state),
  extraFees: getExtraFees(state),
  getFieldValue: (name: string) => getFieldValue(state, name),
  hiddenFieldIds: getHiddenFieldIds(state),
  isFetchingOrder: () => isFetching(state, 'order'),
  isLegacyInstallment: isSelectedOfferLegacyInstallment(state),
  isLegacySubscription: isSelectedOfferLegacySubscription(state),
  isRecurring: isSelectedOfferRecurring(state),
  items: getItems(state),
  recipient: getRecipient(state),
  taxes: getTaxes(state),
});

const connector = connect(mapStateToProps);
const PaymentInformationBreakdown = connector(
  PaymentInformationBreakdownComponent,
);

export { PaymentInformationBreakdown };
