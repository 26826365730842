import {
  CREATE_PAYMENT_SUCCESS,
  FETCH_OFFERS_SUCCESS,
  FETCH_PAYMENT_SUCCESS,
  FETCH_LEGACY_RECURRING_OFFERS_SUCCESS,
  FETCH_RECURRING_OFFERS_SUCCESS,
  SELECT_PAYMENT,
} from '../../../constants';
import type { Price } from 'models';

type PricesActions = {
  type:
    | typeof CREATE_PAYMENT_SUCCESS
    | typeof FETCH_OFFERS_SUCCESS
    | typeof FETCH_LEGACY_RECURRING_OFFERS_SUCCESS
    | typeof FETCH_RECURRING_OFFERS_SUCCESS
    | typeof FETCH_PAYMENT_SUCCESS
    | typeof SELECT_PAYMENT;
  payload: {
    entities: {
      prices: Record<string, Price>;
    };
  };
};

export default (state: Record<string, Price> = {}, action: PricesActions) => {
  switch (action.type) {
    case CREATE_PAYMENT_SUCCESS:
    case FETCH_OFFERS_SUCCESS:
    case FETCH_LEGACY_RECURRING_OFFERS_SUCCESS:
    case FETCH_RECURRING_OFFERS_SUCCESS:
    case FETCH_PAYMENT_SUCCESS:
    case SELECT_PAYMENT:
      const {
        entities: { prices = {} },
      } = action.payload;
      return { ...state, ...prices };
    default:
      return state;
  }
};
