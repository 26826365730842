import React from 'react';
import PropTypes from 'prop-types';

function Content({ children }) {
  return (
    <>
      <div id="content" data-testid="content" />
      {children}
    </>
  );
}

Content.displayName = 'Content';
Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Content };
