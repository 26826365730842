function getConstraints({ i18n }) {
  return {
    firstName: {
      presence: {
        allowEmpty: false,
        message: `^${i18n.t('form.errors.required')}`,
      },
      customFormat: {
        pattern:
          '^[^\\p{Han}|\\p{Katakana}|\\p{Hiragana}|\\p{Hangul}|\\p{Arabic}]+$',
        message: `^${i18n.t('form.errors.invalid.characters')}`,
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
        message: `^${i18n.t('form.errors.required')}`,
      },
      customFormat: {
        pattern:
          '^[^\\p{Han}|\\p{Katakana}|\\p{Hiragana}|\\p{Hangul}|\\p{Arabic}]+$',
        message: `^${i18n.t('form.errors.invalid.characters')}`,
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: `^${i18n.t('form.errors.required')}`,
      },
      email: {
        message: `^${i18n.t('form.errors.invalid')}`,
      },
    },
    confirmationEmail: {
      presence: {
        allowEmpty: false,
        message: `^${i18n.t('form.errors.required')}`,
      },
      equality: {
        attribute: 'email',
        message: `^${i18n.t('form.errors.confirmation')}`,
      },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: `^${i18n.t('form.errors.required')}`,
      },
    },
    repeatPassword: {
      length: { maximum: 0 },
    },
    terms: {
      presence: true,
      inclusion: {
        within: [true],
      },
    },
  };
}

export { getConstraints };
