import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useWizard } from 'components/Wizard/components/context';
import { createRecurringReceivable, newNotification } from 'actions';
import { getAccessToken, getFieldValues } from 'selectors';
import { Navigation } from 'components/Navigation/Navigation';
import { PaymentStep } from 'components/PaymentStep/PaymentStep';
import {
  STEP_RECURRING_DIRECT_DEBIT_PAYMENT,
  SENDER_COUNTRY_FIELD,
  RECIPIENT_FIELD,
} from 'constants/index';
import { RootState } from 'reducers/types';
import { useTranslations } from 'utils';
import { Steps } from './hooks/useSteps';
import {
  useDirectDebitPaymentContext,
  DirectDebitPaymentProvider,
} from './hooks/useDirectDebitPaymentContext';

type DirectDebitProps = ConnectedProps<typeof connector>;
const EMPTY_STRING = '';

function Wizard() {
  const { currentStep, index, steps } = useDirectDebitPaymentContext();
  const i18n = useTranslations();
  const { goToPrevStep } = useWizard();
  const navigation = (
    <Navigation
      hideNext
      onClickPrev={goToPrevStep}
      nextStepName={i18n.t('stepsList.track') as string}
    />
  );

  return (
    <PaymentStep
      navigation={navigation}
      stepName={STEP_RECURRING_DIRECT_DEBIT_PAYMENT}
      title={
        [Steps.Success, Steps.Error].includes(currentStep.id)
          ? EMPTY_STRING
          : (i18n.t('stepsList.pay') as string)
      }
    >
      {steps[index].element}
    </PaymentStep>
  );
}

function DirectDebitComponent({
  accessToken,
  country,
  createRecurringReceivable,
  newNotification,
  recipientId,
}: DirectDebitProps) {
  const { goToNextStep, goToPrevStep, goToStep } = useWizard();

  return (
    <DirectDebitPaymentProvider
      accessToken={accessToken as string}
      country={country as string}
      createRecurringReceivable={createRecurringReceivable}
      newNotification={newNotification}
      recipientId={recipientId as string}
      paymentWizardNavigation={{
        next: goToNextStep,
        prev: goToPrevStep,
        go: goToStep,
      }}
    >
      <Wizard />
    </DirectDebitPaymentProvider>
  );
}

const mapStateToProps = (state: RootState) => {
  const { [SENDER_COUNTRY_FIELD]: country, [RECIPIENT_FIELD]: recipientId } =
    getFieldValues(state);
  const accessToken = getAccessToken(state);

  return { accessToken, country, recipientId };
};

const mapDispatchToProps = {
  createRecurringReceivable,
  newNotification,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
const DirectDebit = connector(DirectDebitComponent);

export { DirectDebit };
