import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isLulu,
  isLuluOfferSelected,
  isCashfreeOfferSelected,
} from 'selectors';
import { TextInputField } from '../TextInputField/TextInputField';
import { useTranslations } from 'utils';
import type { RootState } from 'reducers/types';

export const SenderAddressInputFieldComponent = ({
  field,
  handleBlur,
  handleChange,
  isLulu,
  isLuluOfferSelected,
  isCashfreeOfferSelected,
}) => {
  const i18n = useTranslations();

  let fieldValue = {};
  if (isLulu || isLuluOfferSelected || isCashfreeOfferSelected) {
    fieldValue = {
      ...field,
      hint: i18n.t('senderInformation.address.india.hint'),
    };
  } else {
    fieldValue = field;
  }

  return (
    <TextInputField
      field={fieldValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
    />
  );
};

SenderAddressInputFieldComponent.defaulProps = {
  handleBlur: () => {},
  handleChange: () => {},
  isLulu: false,
  isLuluOfferSelected: false,
  isCashfreeOfferSelected: false,
};

SenderAddressInputFieldComponent.propTypes = {
  field: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isCashfreeOfferSelected: PropTypes.bool,
  isLulu: PropTypes.bool,
  isLuluOfferSelected: PropTypes.bool,
};

const mapStateToProps = (state: RootState) => ({
  isLulu: isLulu(state),
  isLuluOfferSelected: isLuluOfferSelected(state),
  isCashfreeOfferSelected: isCashfreeOfferSelected(state),
});

const SenderAddressInputField = connect(mapStateToProps)(
  SenderAddressInputFieldComponent,
);

export { SenderAddressInputField };
