/* eslint-disable camelcase */

export type InstructionsParams = {
  hostedFormUrl: string;
  payorId: string;
  uuidToken: string;
};

type CardInstructionsAttributes = {
  accessToken?: string;
  actionButton?: 'pay' | 'save' | 'confirm';
  country?: string;
  currencyCode?: string;
  locale?: string;
  payorIdPrefix?: string;
  payorId?: string;
  recipientId?: string;
  theme?: string;
  payorIdType?: 'autogenerated' | 'user';
};

type BankAccountInstructionsAttributes = {
  accessToken?: string;
  country?: string;
  locale?: string;
  recipientId?: string;
  scheme: string;
};

class Instructions {
  hostedFormUrl: string;
  payorId: string;
  uuidToken: string;

  constructor(params: InstructionsParams) {
    this.hostedFormUrl = params.hostedFormUrl;
    this.payorId = params.payorId;
    this.uuidToken = params.uuidToken;
  }
}

const isCardTokenizationEvent = (
  type: string,
  action: string,
  sender: string,
) =>
  type === 'message' && sender === 'flywire' && action === 'card_tokenization';

type TokenizationCardDetails = {
  last_four?: string;
  expiration_month?: string;
  expiration_year?: string;
  type?: string;
  brand?: string;
  issuer?: string;
  country?: string;
  card_holder_name?: string;
  product_type?: string;
};

type TokenizationError = Record<string, string>;

type EventParamsData = {
  api_confirmation_url: string;
  type?: string;
  uuid_token: string;
  action: string;
  result?: string;
  errors?: TokenizationError[];
  sender: string;
  card_details: TokenizationCardDetails;
};

type CardTokenizationEventParams = {
  data?: string;
  type: string;
};

class CardTokenizationEvent {
  apiConfirmationURL?: string;
  cardDetails: TokenizationCardDetails & {
    isSuccess: boolean;
    errors?: TokenizationError[];
    productType?: string;
  };

  isCardTokenizationEvent: boolean;
  type?: string;
  uuidToken?: string;

  constructor(params: CardTokenizationEventParams) {
    let data = {} as EventParamsData;

    try {
      data = params?.data ? JSON.parse(params.data) : {};
    } catch (e) {
      // Ignoring postMessage with wrong format
      this.isCardTokenizationEvent = false;
      this.cardDetails = {
        isSuccess: false,
        country: '',
        productType: '',
        errors: [{ render_tokenization_form: 'Tokenization form error' }],
      };
      return;
    }

    this.type = params.type;
    this.isCardTokenizationEvent = isCardTokenizationEvent(
      params.type,
      data.action,
      data.sender,
    );
    this.cardDetails = {
      ...data.card_details,
      ...(data.errors && { errors: data.errors }),
      ...(data.card_details?.product_type && {
        productType: data.card_details.product_type,
      }),
      isSuccess: this.isCardTokenizationEvent && !data.errors,
    };
    this.apiConfirmationURL = data.api_confirmation_url;
    this.uuidToken = data.uuid_token;
  }
}

type TokenizationFormErrorParams = {
  status: string;
};

class TokenizationFormError {
  status: string;

  constructor(params: TokenizationFormErrorParams) {
    this.status = params.status;
  }
}

class CardError {
  reason:
    | {
        code: string;
        description: string;
      }
    | Record<string, never>;

  constructor({ reason = {} } = {}) {
    this.reason = reason;
  }

  get status() {
    const { code } = this.reason;

    if (this.failedErrorCodes.includes(code)) {
      return 'failed';
    }

    if (this.balanceErrorCodes.includes(code)) {
      return 'balance';
    }

    return 'declined';
  }

  get failedErrorCodes() {
    return [
      '000',
      '009',
      '021',
      '039',
      '040',
      '041',
      '042',
      '043',
      '045',
      '047',
      '049',
      '050',
      '051',
      '052',
      '053',
      '901',
    ];
  }

  get balanceErrorCodes() {
    return ['013', '028'];
  }
}

type bankAccountEventData =
  | {
      confirmationUrl: string;
      errors: never;
      expiresAt: string;
      paymentMethod: {
        accountHolderName: string;
        transitNumber: string;
        institutionNumber: string;
        accountNumber: string;
        lastFour: string;
      };
      result: string;
      uuidToken: string;
    }
  | {
      confirmationUrl: never;
      errors: Record<string, string>[];
      expiresAt: never;
      paymentMethod: never;
      uuidToken: never;
    };

class BankAccountTokenizationEvent {
  data: bankAccountEventData;

  constructor(data: bankAccountEventData) {
    this.data = data;
  }

  get isValid() {
    if (!this.data) {
      return false;
    }

    return Object.prototype.hasOwnProperty.call(this.data, 'paymentMethod');
  }

  get isInvalid() {
    return (
      !this.isValid && Object.prototype.hasOwnProperty.call(this.data, 'errors')
    );
  }
}

export {
  BankAccountInstructionsAttributes,
  BankAccountTokenizationEvent,
  CardError,
  CardInstructionsAttributes,
  CardTokenizationEvent,
  Instructions,
  TokenizationFormError,
};
