import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LOGIN_ROUTE } from 'constants/index';
import { useAuth } from 'hooks/useAuth/useAuth';
import { Spinner } from 'components/Spinner/Spinner';

const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { isAuthenticated, canAuthenticate } = useAuth();

  if (!isAuthenticated && canAuthenticate) {
    return <Spinner />;
  }

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={LOGIN_ROUTE} state={{ referrer: location }} replace />
  );
};

export { AuthRoute };
