import { combineReducers } from 'redux';
import offers from './offers';
import recipient from './recipient';
import sender from './sender';

export default combineReducers({
  offers,
  recipient,
  sender,
});
