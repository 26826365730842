import React from 'react';
import { Alert } from 'components/Alert/Alert';
import { useTranslations } from 'utils';

function NoOptions() {
  const i18n = useTranslations();

  return (
    <Alert>
      <strong>{i18n.t('paymentOptions.noOffers.title')}</strong>{' '}
      {i18n.t('paymentOptions.noOffers.description')}
    </Alert>
  );
}

export { NoOptions };
