import fetchJson from 'utils/fetchJson';
import { PAYEX_URL } from 'constants/config';

const extraFees = {
  add: (data: Record<string, string>) => {
    const path = `${PAYEX_URL}/extra_fees`;
    const options = {
      method: 'POST',
      body: JSON.stringify(data),
    };

    return fetchJson(path, options);
  },
};

export { extraFees };
