import React, { useState, MouseEventHandler } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { isLoggedIn } from 'selectors';
import { UserAccessModal } from 'components/UserAccessModal/UserAccessModal';
import { useTranslations } from 'utils';
import type { RootState } from 'reducers/types';

import './Login.scss';

type LoginProps = ConnectedProps<typeof connector> & {
  onLoginSuccess?: () => void;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const LoginComponent = ({
  onLoginSuccess,
  isLoggedIn,
  onClick,
}: LoginProps) => {
  const i18n = useTranslations();
  const [showModal, setShowModal] = useState(false);
  const handleClick: MouseEventHandler<HTMLButtonElement> = evt => {
    setShowModal(true);
    onClick?.(evt);
  };

  const closeModal = () => setShowModal(false);
  const handleLoginSuccess = () => {
    onLoginSuccess?.();
    closeModal();
  };

  if (isLoggedIn) return null;

  return (
    <>
      <button
        className="Login-button"
        onClick={handleClick}
        data-testid="loginButton"
      >
        {i18n.t('logIn')}
      </button>
      <UserAccessModal
        isOpen={showModal}
        onClose={closeModal}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: isLoggedIn(state),
});

const connector = connect(mapStateToProps);
const Login = connector(LoginComponent);

export { Login };
