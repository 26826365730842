import React, { useEffect, Component } from 'react';
import { Modal } from 'components/ui/Modal/Modal';
import PropTypes from 'prop-types';
import { UserLogin } from 'components/UserLogin/UserLogin';
import { UserSignup } from 'components/UserSignup/UserSignup';
import { UserForgotPassword } from 'components/UserForgotPassword/UserForgotPassword';
import { WithTranslations } from 'components/HOC/WithTranslations/WithTranslations';
import { Logo } from 'containers/Logo/Logo';
import { Tabs } from 'components/Tabs/Tabs';

const DELAY = 125;

class UserAccessModalComponent extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func,
    onSignUpSuccess: PropTypes.func,
    showSignupTab: PropTypes.bool,
  };

  static defaultProps = {
    defaultOpen: false,
    onLoginSuccess: () => {},
    onSignUpSuccess: () => {},
    showSignupTab: false,
  };

  state = {
    showResetPassword: false,
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ showResetPassword: false });
    onClose();
  };

  onResetPassword = (showUpdatePasswordAlert = false) => {
    this.setState({
      showResetPassword: !this.state.showResetPassword,
      showUpdatePasswordAlert,
    });
  };

  render() {
    const { isOpen } = this.props;
    const { showResetPassword, showUpdatePasswordAlert } = this.state;

    return (
      <Modal isOpen={isOpen} onClose={this.handleClose}>
        <div
          className="textAlign-center marginBottom-md"
          data-testid="userAccessModal"
        >
          <Logo />
        </div>
        {showResetPassword ? (
          <UserForgotPassword
            onLogin={this.onResetPassword}
            showUpdatePasswordAlert={showUpdatePasswordAlert}
          />
        ) : (
          <UserAccessTabs
            {...this.props}
            onResetPassword={this.onResetPassword}
          />
        )}
      </Modal>
    );
  }
}

const UserAccessTabs = ({
  i18n,
  onLoginSuccess,
  onSignUpSuccess,
  onResetPassword,
  showSignupTab,
}) => {
  const { TabList, Tab, TabPanels, TabPanel } = Tabs;
  const loginTabIndex = 0;
  const signupTabIndex = 1;
  const defaultTab = showSignupTab ? signupTabIndex : loginTabIndex;
  const loginFirstInputRef = React.useRef();
  const signupFirstInputRef = React.useRef();

  function handleOnTabChange({
    index,
  }: {
    index: typeof loginTabIndex | typeof signupTabIndex;
  }) {
    const textInput =
      index === loginTabIndex
        ? loginFirstInputRef?.current
        : signupFirstInputRef?.current;

    setTimeout(() => textInput?.focus(), DELAY);
  }

  useEffect(() => handleOnTabChange({ index: loginTabIndex }), []);

  return (
    <Tabs defaultActiveIndex={defaultTab} onChange={handleOnTabChange}>
      <TabList>
        <Tab data-testid="userAccessModal-login">
          {i18n.t('userAccessModal.login')}
        </Tab>
        <Tab data-testid="userAccessModal-signup">
          {i18n.t('userAccessModal.signup')}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          {({ selectTab, isActive }) =>
            isActive && (
              <UserLogin
                onLoginSuccess={onLoginSuccess}
                handleSignUpClick={() => selectTab(signupTabIndex)}
                resetPassword={onResetPassword}
                firstInputRef={loginFirstInputRef}
              />
            )
          }
        </TabPanel>
        <TabPanel>
          {({ selectTab, isActive }) =>
            isActive && (
              <UserSignup
                onSignUpSuccess={onSignUpSuccess}
                handleLoginClick={() => selectTab(loginTabIndex)}
                firstInputRef={signupFirstInputRef}
              />
            )
          }
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

UserAccessTabs.propTypes = {
  i18n: PropTypes.object.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onSignUpSuccess: PropTypes.func.isRequired,
  showSignupTab: PropTypes.bool.isRequired,
};

const UserAccessModal = WithTranslations(UserAccessModalComponent);

export { UserAccessModal };
