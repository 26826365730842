const MARCH = 2;

function getFiscalYearRange({ lastMonthFiscalYear = MARCH } = {}) {
  const today = new Date();
  const currentMonth = today.getMonth();
  const fullYear = today.getFullYear();

  if (currentMonth > lastMonthFiscalYear) {
    return [fullYear, fullYear + 1];
  }

  return [fullYear - 1, fullYear];
}

export default getFiscalYearRange;
