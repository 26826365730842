export const MAINTENANCE_MODE = 'pAAR2945PayexMaintenanceMode';
export const ADA_SUPPORT = 'eS1484EnableAdaSupport';
export const TRACKING_POLLING = 'pAAR4230PollingInTrackingPage';
export const PROFILE_CREDIT_CARDS_FEATURE_FLAG = 'pAAR4583ProfileCreditCards';
export const USE_TAG_MANAGER_FEATURE = 'pAAR4646UseTagManager';
export const CAMELIZED_FEATURES = [
  MAINTENANCE_MODE,
  TRACKING_POLLING,
  PROFILE_CREDIT_CARDS_FEATURE_FLAG,
] as const;
export const ENABLE_FEATURE = 'ENABLE_FEATURE';
export const FETCH_FEATURES_REQUEST = 'FETCH_FEATURES_REQUEST';
export const FETCH_FEATURES_SUCCESS = 'FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_ERROR = 'FETCH_FEATURES_ERROR';
export const FF_HOLIDAY_BANNER_CN = 'PAAR-5353-holidayBannerCN';
export const FF_HOLIDAY_BANNER_VN = 'PAAR-5353-holidayBannerVN';
export const FF_CAPTCHA = 'PAAR-4218-VerifyTokenInBackend';
export const FF_COUPONS_CN = 'REM-1040-coupons';
export const FF_COUPONS_IN = 'REM-1186-coupons-in';
export const FF_PAY_REMAINING_BALANCE = 'PAAR-5246-payRemainingBalance';
export const FF_USER_WALLET = 'PAAR-5247-userWallet';
export const FF_REDEEM_CASHBACK = 'REM-867-RedeemCashback';
export const FF_WHATSAPP_SUBSCRIPTIONS = 'REM-1238-WhatsappSubscriptions';
export const FF_PAYING_FROM_INDIA_IN_USD = 'PAAR-5643-Mario';
export const FF_PAYING_FROM_INDIA_NON_FX = 'PAAR-5647';
export const FF_NON_FX_PAYMENTS_INFO = 'PAAR-5645-Non_FX_Payments';

export const FEATURES = [
  'PAAR-2945-payex_maintenance_mode',
  'PAAR-4230-PollingInTrackingPage',
  'PAAR-4583-profileCreditCards',
  'PAAR-4646-use-tag-manager',
  FF_COUPONS_CN,
  FF_COUPONS_IN,
  FF_PAY_REMAINING_BALANCE,
  FF_HOLIDAY_BANNER_CN,
  FF_HOLIDAY_BANNER_VN,
  FF_USER_WALLET,
  FF_REDEEM_CASHBACK,
  FF_WHATSAPP_SUBSCRIPTIONS,
  FF_NON_FX_PAYMENTS_INFO,
  FF_PAYING_FROM_INDIA_IN_USD,
] as const;

const FEATURE_TYPES = [FF_CAPTCHA, FF_PAYING_FROM_INDIA_NON_FX] as const;
export type FeatureFlag =
  | (typeof FEATURES)[number]
  | (typeof FEATURE_TYPES)[number];
