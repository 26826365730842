import {
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_SUCCESS,
} from 'constants/index';
import { Cashback } from 'models/cashBack';

type CashbackAction =
  | {
      type: typeof FETCH_DOCUMENTS_SUCCESS;
      payload: {
        cashback: Cashback;
      };
    }
  | { type: typeof FETCH_DOCUMENTS_FAILURE };

export const cashback = (
  state: Cashback | Record<string, never> = {},
  action: CashbackAction,
) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      const { cashback: { agreedTransaction = {} } = {} } = action.payload;

      return {
        ...state,
        agreedTransaction: agreedTransaction,
      };
    case FETCH_DOCUMENTS_FAILURE:
      return {};
    default:
      return state;
  }
};
