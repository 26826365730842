import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Heading } from 'components/Heading/Heading';
import { useSearchParams, useParams, Navigate } from 'react-router-dom';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header/Header';
import { RedirectToPay } from 'components/Routes/RedirectToPay/RedirectToPay';
import { Spinner } from 'components/Spinner/Spinner';
import {
  fetchOrder,
  fetchPayment,
  setGatewayOrigin,
  setGatewaySource,
} from 'actions';
import { getOrder, hasPayment as selectHasPayment } from 'selectors';
import isEmpty from 'lodash/isEmpty';
import { GATEWAY_DUPLICATED_ROUTE } from 'constants/index';
import { ReactComponent as RemoveCircle } from 'icons/remove-circle.svg';
import type { RootState } from 'reducers/types';
import { useTranslations } from 'utils';

function PrefillOrderAndPay() {
  const [isFetching, setIsFetching] = useState(true);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const hasPayment = useSelector((state: RootState) => selectHasPayment(state));
  const i18n = useTranslations();
  const order = useSelector((state: RootState) => getOrder(state));
  const { orderId } = useParams<{ orderId: string }>();

  useEffect(() => {
    const fetchData = async () => {
      const token = searchParams.get('token');
      const platform = searchParams.get('platform');

      dispatch(setGatewayOrigin());
      if (platform) dispatch(setGatewaySource(platform));

      try {
        if (!orderId) {
          return;
        }

        await dispatch(fetchOrder({ orderId, token }));
        await dispatch(fetchPayment({ orderId, token }));
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, [dispatch, orderId, searchParams]);

  if (isFetching) return <Spinner />;
  if (hasPayment) {
    return <Navigate to={GATEWAY_DUPLICATED_ROUTE} />;
  }

  return isEmpty(order) ? (
    <div className="Site">
      <Header />
      <section className="ErrorPage">
        <RemoveCircle />
        <Heading as="h1" size="huge" className="ErrorPage-title">
          {i18n.t('prefillOrderAndPay.errorTitle')}
        </Heading>
        <p className="fontSize-lg marginBottom-sm">
          {i18n.t('prefillOrderAndPay.notFound')}
        </p>
      </section>
      <Footer />
    </div>
  ) : (
    <RedirectToPay />
  );
}

// const mapStateToProps = (state: RootState) => ({
//   hasPayment: hasPayment(state),
//   order: getOrder(state),
// });

// const mapDispatchToProps = {
//   fetchOrder,
//   fetchPayment,
//   setGatewayOrigin,
//   setGatewaySource,
// };

// const PrefillOrderAndPay = WithTranslations(
//   connect(mapStateToProps, mapDispatchToProps)(PrefillOrderAndPayComponent),
// );

export { PrefillOrderAndPay };
