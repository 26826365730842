function hasSessionStorage() {
  try {
    const key = '__test_key__';
    sessionStorage.setItem(key, key);
    sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

const storage = () => {
  const memoryStorage: Record<string, string> = {};

  const setItem = (key: string, value: string) => {
    if (hasSessionStorage()) sessionStorage.setItem(key, value);
    memoryStorage[key] = value;
  };

  const getItem = (key: string) => {
    if (hasSessionStorage()) return sessionStorage.getItem(key);
    return memoryStorage[key];
  };

  const removeItem = (key: string) => {
    if (hasSessionStorage()) sessionStorage.removeItem(key);
    delete memoryStorage[key];
  };

  return {
    setItem,
    getItem,
    removeItem,
  };
};

export default storage();
