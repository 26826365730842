import { useState } from 'react';
import { v4 } from 'uuid';

const useUniqueId = (prefix: string) => {
  const [uniqueId] = useState(`${prefix}-${v4()}`);

  return uniqueId;
};

export default useUniqueId;
