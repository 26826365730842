type InputAriaProps = {
  'aria-describedby'?: string;
  disabled?: boolean;
  error?: string | boolean;
  hint?: string;
  label: string;
  name: string;
  readOnly?: boolean;
  required?: boolean;
};

export const useInputAriaProps = ({
  'aria-describedby': ariaDescribedBy,
  disabled = false,
  error,
  hint,
  label,
  name,
  readOnly = false,
  required = false,
}: InputAriaProps) => {
  return {
    inputAriaProps: {
      ...(hint || error || ariaDescribedBy
        ? {
            'aria-describedby': [
              ...(hint ? [`${name}-description`] : []),
              ...(error && typeof error === 'string' ? [`${name}-error`] : []),
              ...(ariaDescribedBy ? [ariaDescribedBy] : []),
            ].join(' '),
          }
        : {}),
      ...(error ? { 'aria-invalid': true } : {}),
      ...(readOnly ? { 'aria-readonly': true } : {}),
      ...(disabled ? { 'aria-disabled': true } : {}),
      ...(required ? { 'aria-required': true } : {}),
      ...(label ? { 'aria-labelledby': `${name}-label` } : {}),
    },
  };
};
