import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { Field } from '@flywire/react-headlessui';
import classNames from 'classnames';
import './Autocomplete.scss';

function standarizeText(text: string) {
  return text
    .toLowerCase()
    .replace(/\s+/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

type Option = {
  label: string;
  value: string;
};

const EmptyOption: Option = { label: '', value: '' };

export type AutocompleteProps = {
  children: React.ReactNode;
  className?: string;
  error?: string | boolean;
  hint?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  options: Option[];
  selected?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
};

interface ContextInterface {
  error?: string | boolean;
  name?: string;
  hasValue?: boolean;
  hint?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  filteredOptions: Option[];
  setQuery: Dispatch<SetStateAction<string>>;
  onBlur?: () => void;
}

const Context = createContext<ContextInterface>({
  filteredOptions: [],
  required: false,
  setQuery: () => {
    // does nothing
  },
});

function Input({
  className,
  'data-testid': dataTestid,
  placeholder,
}: {
  className?: string;
  'data-testid'?: string;
  placeholder?: string;
}) {
  const {
    disabled,
    filteredOptions,
    hasValue,
    name,
    readOnly,
    required,
    setQuery,
  } = useContext(Context);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const displayValue = (selected: Option) =>
    (selected &&
      filteredOptions.find(option => option.value === selected.value)?.label) ||
    '';

  const label = required ? `${placeholder} *` : placeholder;

  return (
    <div
      className={classNames(
        'Autocomplete-field',
        {
          'Autocomplete-field--readonly': readOnly,
          'Autocomplete-field--hasValue': hasValue,
        },
        className,
      )}
    >
      <Combobox.Button
        className={classNames('Autocomplete-button', className)}
        ref={buttonRef}
      />
      <Combobox.Label
        className={classNames(
          'Label',
          'Autocomplete-Label',
          {
            'Label--disabled': disabled,
            'Label--hasValue': hasValue,
          },
          className,
        )}
        onClick={() => buttonRef.current && buttonRef.current.click()}
      >
        {label}
      </Combobox.Label>
      <Combobox.Input
        id={name}
        className={classNames('Autocomplete-search', className)}
        data-testid={`input-${dataTestid}`}
        displayValue={displayValue}
        onChange={(evt: ChangeEvent<HTMLInputElement>) =>
          setQuery(evt.target.value)
        }
        onClick={() => buttonRef.current && buttonRef.current.click()}
        required={required}
        autoComplete="off"
        aria-required={required}
        readOnly={readOnly}
        aria-readonly={readOnly}
        aria-disabled={disabled}
        aria-autocomplete="list"
        aria-controls={`${name}-options`}
        aria-labelledby={`${name}-label`}
      />
    </div>
  );
}

function Options({ className }: { className?: string }) {
  const { filteredOptions, setQuery, onBlur } = useContext(Context);

  return (
    <Transition
      as={Fragment}
      afterLeave={() => {
        setQuery('');
        onBlur?.();
      }}
    >
      <Combobox.Options
        className={classNames(
          'Autocomplete-options',
          'is-searching',
          className,
        )}
      >
        {filteredOptions.map((option: Option) => (
          <Option option={option} key={`${option.value}-${option.label}`} />
        ))}
      </Combobox.Options>
    </Transition>
  );
}

function Option({ className, option }: { className?: string; option: Option }) {
  const { name } = useContext(Context);

  return (
    <Combobox.Option
      className={({ active }) =>
        classNames('Autocomplete-option', { 'is-active': active }, className)
      }
      data-testid={`${name}-${option.value}`}
      value={option}
    >
      {option.label}
    </Combobox.Option>
  );
}

function Error(props: { children: ReactNode; className?: string }) {
  const { name } = useContext(Context);

  return <Field.Error {...props} id={`${name}-error`} />;
}

function Hint(props: { children: ReactNode; className?: string }) {
  const { name } = useContext(Context);

  return <Field.Hint {...props} id={`${name}-description`} />;
}

function Autocomplete({
  children,
  className,
  error,
  hint,
  name,
  onBlur,
  onChange,
  onFocus,
  options = [],
  selected,
  required,
  readOnly,
  disabled,
  'data-testid': dataTestid,
}: AutocompleteProps) {
  const [query, setQuery] = useState('');
  const filteredOptions =
    query === ''
      ? options
      : options.filter(option =>
          standarizeText(option.label).includes(standarizeText(query)),
        );
  const selectedOption =
    options.find(
      option => option.value.toLowerCase() === selected?.toLowerCase(),
    ) || EmptyOption;

  return (
    <Context.Provider
      value={{
        name,
        error,
        hasValue: !!selected,
        hint,
        required,
        readOnly,
        disabled,
        setQuery,
        filteredOptions,
        onBlur,
      }}
    >
      <Combobox
        as="div"
        name={name}
        data-testid={`autocomplete-${dataTestid}`}
        onChange={(option: Option) => {
          onChange?.(option.value);
          setQuery('');
        }}
        onFocus={() => name && onFocus?.(name)}
        value={selectedOption}
        disabled={readOnly || disabled}
        className={({ open }: { open: boolean }) =>
          classNames(
            'Autocomplete',
            { 'is-searching': open },
            { 'has-error': error },
            className,
          )
        }
      >
        {children}
      </Combobox>
    </Context.Provider>
  );
}

Autocomplete.Input = Input;
Autocomplete.Option = Option;
Autocomplete.Options = Options;
Autocomplete.Error = Error;
Autocomplete.Hint = Hint;

export { Autocomplete };
