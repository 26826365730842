import {
  createPayment,
  addOrderToUser,
  enableSenderSMSNotification,
} from 'actions';
import {
  isLoggedIn,
  orderHasOwner,
  shouldReceiveSMSNotification,
  getPlatform,
  shouldReceiveWhatsappNotification,
} from 'selectors';
import {
  CREATE_PAYMENT_FROM_ORDER_REQUEST,
  CREATE_PAYMENT_FROM_ORDER_SUCCESS,
  CREATE_PAYMENT_FROM_ORDER_FAILURE,
} from 'constants/index';
import type { PayexThunkAction } from 'store/configureStore';

export const createPaymentFromOrder =
  (): PayexThunkAction => async (dispatch, getState) => {
    dispatch({ type: CREATE_PAYMENT_FROM_ORDER_REQUEST });

    try {
      const state = getState();
      const shouldAddOrderToUser = isLoggedIn(state) && !orderHasOwner(state);
      if (shouldAddOrderToUser) await dispatch(addOrderToUser());

      await dispatch(createPayment(getPlatform(state)));

      if (
        shouldReceiveSMSNotification(state) &&
        !shouldReceiveWhatsappNotification(state)
      ) {
        await dispatch(enableSenderSMSNotification());
      }

      dispatch({ type: CREATE_PAYMENT_FROM_ORDER_SUCCESS });
    } catch (error) {
      dispatch({ type: CREATE_PAYMENT_FROM_ORDER_FAILURE });
    }
  };
