import { RootState } from 'reducers/types';

export const getRecipientById = (state: RootState, id: string) => {
  const {
    entities: { recipients: { byId: recipientsById } = {} },
  } = state;

  const recipient = recipientsById?.[id];

  return recipient;
};
