import { errorNotifier } from 'utils/errorNotifier';
import { isFetching } from 'selectors';
import {
  FETCH_KYC_REQUEST,
  FETCH_KYC_SUCCESS,
  FETCH_KYC_FAILURE,
  UPLOAD_REFUND_KYC_DOCUMENT_REQUEST,
  UPLOAD_REFUND_KYC_DOCUMENT_SUCCESS,
  UPLOAD_REFUND_KYC_DOCUMENT_FAILURE,
  REFUND_KYC_FIELDS_MISSING,
  HTTPStatuses,
  HttpError,
} from '../../../constants';
import type { PayexThunkAction } from 'store/configureStore';
import { isHttpErrorWithStatus } from 'utils/errors/errors';
import { normalizeKycFields } from 'services/kyc/kyc';

export const fetchKYC =
  (id: string): PayexThunkAction =>
  (dispatch, _, { apiV3 }) => {
    dispatch({ type: FETCH_KYC_REQUEST });

    return apiV3.kycs
      .fetch({ id })
      .then(({ fields = [], uploadUrl }) =>
        dispatch({
          type: FETCH_KYC_SUCCESS,
          payload: {
            ...normalizeKycFields(fields),
            uploadUrl,
          },
        }),
      )
      .catch(error => {
        errorNotifier.notify(error);
        dispatch({ type: FETCH_KYC_FAILURE });
      });
  };

export const uploadRefundKYC =
  (id: string, file: File): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const isFetchingRefundKycDocument = isFetching(state, 'refundKycDocument');

    if (isFetchingRefundKycDocument) return;

    const { recipient, theme, ...fields } = state.ui.fieldValues;
    const { ids: fieldIds } = state.entities.fields.kycs;
    const documentUploadURL = state.entities.links.uploadUrl;

    const emptyFields = fieldIds.filter(key => !fields[key]);

    if (emptyFields.length > 0) {
      return dispatch({
        type: REFUND_KYC_FIELDS_MISSING,
        payload: { fields: emptyFields },
      });
    }

    const formattedFields = Object.keys(fields).map(key => {
      return { label: key, value: fields[key] };
    });

    dispatch({ type: UPLOAD_REFUND_KYC_DOCUMENT_REQUEST });

    try {
      await apiV3.kycs.uploadDocument(file, documentUploadURL || '');
      await apiV3.kycs.uploadInfo(id, formattedFields);

      return dispatch({ type: UPLOAD_REFUND_KYC_DOCUMENT_SUCCESS });
    } catch (error) {
      if (isHttpErrorWithStatus(error)) {
        const httpError = error as HttpError;

        const { status } = httpError;

        if (
          status !== HTTPStatuses.NOT_FOUND &&
          status !== HTTPStatuses.BAD_REQUEST
        ) {
          httpError.message = `${UPLOAD_REFUND_KYC_DOCUMENT_FAILURE} ${httpError.message}`;
          errorNotifier.notifyWithFingerprint(httpError, [
            UPLOAD_REFUND_KYC_DOCUMENT_FAILURE,
            `${status}`,
          ]);
        }
      }
      return dispatch({ type: UPLOAD_REFUND_KYC_DOCUMENT_FAILURE });
    }
  };
