import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from '@sentry/react';
import store from 'store/configureStore';
import { Analytics } from 'components/Analytics/Analytics';
import { Chat } from 'components/Chat/Chat';
import { AnalyticsHeap } from 'components/AnalyticsHeap/AnalyticsHeap';
import { EmbeddedSetup } from 'components/EmbeddedSetup/EmbeddedSetup';
import { TranslationProvider } from 'contexts/TranslationContext/TranslationContext';
import { Routes } from 'components/Routes/Routes';
import { Sift } from 'components/Sift/Sift';
import { AuthUser } from 'containers/AuthUser/AuthUser';
import { embedded } from 'repositories';
import './EmbeddedEntry';

import './styles/main.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

render(
  <ErrorBoundary>
    <Provider store={store.configure()}>
      <Analytics />
      <React.StrictMode>
        <AnalyticsHeap />
        <Sift />
        <AuthUser>
          <Router>
            <TranslationProvider>
              <QueryClientProvider client={queryClient}>
                {embedded.isEnabled() && <EmbeddedSetup />}
                <Routes />
                {!embedded.isEnabled() && <Chat />}
              </QueryClientProvider>
            </TranslationProvider>
          </Router>
        </AuthUser>
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('payex-app-root'),
);
