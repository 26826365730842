import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PaymentSetup } from 'components/PaymentSetup/PaymentSetup';
import { Notifications } from 'components/Notifications/Notifications';
import { Autologout } from 'containers/Autologout/Autologout';
import { RedirectToSelectInstitution } from 'components/RedirectToSelectInstitution/RedirectToSelectInstitution';
import { InvalidRecipient } from 'components/InvalidRecipient/InvalidRecipient';
import { Spinner } from 'components/Spinner/Spinner';
import {
  getCurrentLocale,
  getFieldValue,
  getRecipientId,
  isRecipientActive,
} from 'selectors';
import { setPaymentProcess, fetchData } from 'actions';
import { WithPaymentCompletedRedirection } from 'components/HOC/WithPaymentCompletedRedirection/WithPaymentCompletedRedirection';
import type { RootState } from 'reducers/types';

const PaymentProcessComponent = ({
  setPaymentProcess,
  fetchData,
  recipient,
  hasRecipient,
  isRecipientActive,
  locale,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [searchParams] = useSearchParams();

  function stopFetching() {
    setIsFetching(false);
  }

  function getRecipientCode() {
    const destination = searchParams.get('destination');
    const code = searchParams.get('recipient');

    return destination || code || recipient;
  }

  const recipientCode = getRecipientCode();

  useEffect(() => {
    setPaymentProcess();
  }, [setPaymentProcess]);

  useEffect(() => {
    fetchData(recipientCode).then(stopFetching);
  }, [fetchData, locale, recipientCode]);

  if (!recipientCode) return <RedirectToSelectInstitution />;
  if (isFetching) return <Spinner />;
  if (!hasRecipient || !isRecipientActive) {
    return (
      <>
        <Notifications />
        <InvalidRecipient />
      </>
    );
  }

  return (
    <Autologout>
      <PaymentSetup {...props} />
    </Autologout>
  );
};

PaymentProcessComponent.propTypes = {
  fetchData: PropTypes.func.isRequired,
  hasRecipient: PropTypes.bool.isRequired,
  isRecipientActive: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  recipient: PropTypes.string,
  setPaymentProcess: PropTypes.func.isRequired,
};

const mapStateToProps = (state: RootState) => ({
  recipient: getFieldValue(state, 'recipient'),
  hasRecipient: !!getRecipientId(state),
  isRecipientActive: isRecipientActive(state),
  locale: getCurrentLocale(state),
});

const mapDispatchToProps = {
  fetchData,
  setPaymentProcess,
};

const PaymentProcess = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPaymentCompletedRedirection(PaymentProcessComponent));

export { PaymentProcess };
