import {
  FETCH_ORDER_OWNERSHIP_FAILURE,
  FETCH_ORDER_OWNERSHIP_REQUEST,
  FETCH_ORDER_OWNERSHIP_SUCCESS,
  HTTPStatuses,
} from 'constants/index';
import { errorNotifier } from 'utils/errorNotifier';
import { OrderService } from 'services/apiV3/orders/orders';
import type { PayexThunkAction } from 'store/configureStore';

export const orderOwnership =
  (): PayexThunkAction =>
  async (dispatch, getState, { apiV3 }) => {
    const state = getState();
    const {
      entities: {
        order: { id, token },
      },
    } = state;

    dispatch({ type: FETCH_ORDER_OWNERSHIP_REQUEST });

    return (apiV3.orders as OrderService)
      .ownership({ id, token })
      .then(({ id, token }) =>
        dispatch({
          type: FETCH_ORDER_OWNERSHIP_SUCCESS,
          payload: { id, token },
        }),
      )
      .catch(error => {
        const { status } = error;

        if (status !== HTTPStatuses.NOT_FOUND) errorNotifier.notify(error);

        dispatch({ type: FETCH_ORDER_OWNERSHIP_FAILURE });
      });
  };
