import React, { useRef } from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { useTranslations } from 'utils';
import { GoogleIcon } from './assets/GoogleIcon';
import {
  googleSignIn,
  setCaptchaResponse,
  trackUserLoginWithGoogle,
} from 'actions';
import { useDispatch } from 'react-redux';
import './SocialSignIn.scss';
import {
  GOOGLE_CLIENT_ID_DEV,
  GOOGLE_CLIENT_ID_PROD,
  PROD_DOMAINS_FOR_SOCIAL_SIGN_IN,
} from '../../constants';
import { Captcha, CaptchaRef } from '../thirdParty/Captcha/Captcha';

const hostName = window.location.hostname;
const domainName = hostName.substring(
  hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
);

export function SocialSignIn({
  onSuccess,
  onError,
  isLogin,
}: {
  onSuccess?: () => void;
  onError: (err?: unknown) => void;
  isLogin: boolean;
}) {
  const i18n = useTranslations();
  const dispatch = useDispatch();
  const captchaRef = useRef<CaptchaRef>(null);

  const onClickSignIn = (handleProviderSignIn: () => void) => {
    if (captchaRef?.current && captchaRef?.current.execute) {
      try {
        const result = captchaRef.current?.execute({ async: true });
        result?.then(
          () => handleProviderSignIn(),
          () => {},
        );
      } catch (e) {}
    } else {
      handleProviderSignIn();
    }
  };

  const handleSignInWithCaptcha = async (token: string) => {
    if (token) {
      dispatch(setCaptchaResponse(token));
    } else {
      onError();
    }
  };

  return (
    <>
      <GoogleSignIn
        onClick={onClickSignIn}
        onSuccess={onSuccess}
        onError={onError}
        isLogin={isLogin}
      />
      <Captcha ref={captchaRef} onVerify={handleSignInWithCaptcha} />
      <div className="SocialSignIn-or">
        <div className="SocialSignIn-or-line">&nbsp;</div>
        <span>{i18n.t('userLogin.social.or')}</span>
        <div className="SocialSignIn-or-line">&nbsp;</div>
      </div>
    </>
  );
}

function GoogleSignIn({
  isLogin,
  onClick,
  onSuccess,
  onError,
}: {
  isLogin: boolean;
  onClick: (handleProviderSignIn: () => void) => void;
  onSuccess?: () => void;
  onError: (err: unknown) => void;
}) {
  const clientId = PROD_DOMAINS_FOR_SOCIAL_SIGN_IN.includes(domainName)
    ? GOOGLE_CLIENT_ID_PROD
    : GOOGLE_CLIENT_ID_DEV;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleSignInButton
        isLogin={isLogin}
        onClick={onClick}
        onError={onError}
        onSuccess={onSuccess}
      />
    </GoogleOAuthProvider>
  );
}

function GoogleSignInButton({
  isLogin,
  onClick,
  onSuccess,
  onError,
}: {
  isLogin: boolean;
  onClick: (handleProviderSignIn: () => void) => void;
  onSuccess?: () => void;
  onError: (err: unknown) => void;
}) {
  const i18n = useTranslations();
  const dispatch = useDispatch();

  const handleResponse = async (authCode: string) => {
    try {
      await dispatch(
        googleSignIn({
          authCode,
        }),
      );
      onSuccess?.();
      dispatch(trackUserLoginWithGoogle(isLogin));
    } catch (error) {
      onError(error);
    }
  };

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: codeResponse => {
      handleResponse(codeResponse.code);
    },
    onError: error => {
      onError(error);
    },
    flow: 'auth-code',
  });

  return (
    <>
      <button
        className="SocialSignInButton"
        onClick={() => onClick(handleGoogleSignIn)}
        data-testid="googleSignInButton"
        type="submit"
      >
        <GoogleIcon className="GoogleButton-icon" />
        <span>
          {i18n.t(
            isLogin
              ? 'userLogin.social.google.login'
              : 'userLogin.social.google.signup',
          )}
        </span>
      </button>
    </>
  );
}
