import {
  FETCH_PUBLIC_KEY_FAILURE,
  FETCH_PUBLIC_KEY_REQUEST,
  FETCH_PUBLIC_KEY_SUCCESS,
  PUBLIC_KEY_URL,
} from '../../constants';
import fetchJson from 'utils/fetchJson';
import { errorNotifier } from 'utils/errorNotifier';
import type { PayexThunkAction } from 'store/configureStore';

export const fetchPublicKey = (): PayexThunkAction => async dispatch => {
  dispatch({ type: FETCH_PUBLIC_KEY_REQUEST });

  try {
    const publicKey = await fetchJson(PUBLIC_KEY_URL);
    dispatch({ type: FETCH_PUBLIC_KEY_SUCCESS, payload: publicKey });
  } catch (error) {
    errorNotifier.notify(error);

    dispatch({ type: FETCH_PUBLIC_KEY_FAILURE });
  }
};
