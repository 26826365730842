import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentStepIndex, getPaymentSteps } from '../../selectors';
import type { RootState } from 'reducers/types';

class ScrollToTopComponent extends Component {
  static propTypes = {
    children: PropTypes.node,
    stepIndex: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.scroll();
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.stepIndex !== this.props.stepIndex ||
      nextProps.totalSteps !== this.props.totalSteps
    );
  }

  componentDidUpdate() {
    if (window.scrollY === 0) return;
    this.scroll();
  }

  scroll() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state: RootState) => ({
  stepIndex: getCurrentStepIndex(state),
  totalSteps: getPaymentSteps(state).length,
});

const ScrollToTop = connect(mapStateToProps)(ScrollToTopComponent);

export { ScrollToTop };
