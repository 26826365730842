import React, { useState, useEffect } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { useWizard } from 'components/Wizard/components/context';
import { EURateDisclaimerContainer as EURateDisclaimer } from 'containers/EURateDisclaimer/EURateDisclaimer';
import { OptionList } from '../OptionList/OptionList';
import { NoLocalOptions } from './components/NoLocalOptions/NoLocalOptions';
import { NoOptions } from './components/NoOptions/NoOptions';
import { useTranslations } from 'utils';
import { Nothing } from 'components/Nothing/Nothing';
import { SpinnerOverlay } from 'components/SpinnerOverlay/SpinnerOverlay';
import { NonFxInfoModal } from './components/NonFxInfoModal/NonFxInfoModal';
import { UserAccessModal } from '../../../UserAccessModal/UserAccessModal';
import { trackHeapSelectedPaymentOption } from 'actions/analytics/heapAgent/heapAgent';
import { getIndianPayerFromMetadata } from 'selectors/entities/order/order';
import { usePeertransferFlags } from 'hooks/usePeertransferFlags/usePeertransferFlags';
import { FeedbackQuestionsModal } from './components/FeedbackQuestionsModal/FeedbackQuestionsModal';
import { keysEnum } from './components/FeedbackQuestionsModal/data/constants';
import {
  clearOfferFields,
  fetchOfferFromOrder,
  fetchOffersFromOrderIfNeeded,
  fetchSender,
  newNotification,
  setFieldValue,
  updateOrderWithEntities,
} from 'actions';
import {
  trackCheckMarkup,
  trackPaymentOption,
  trackPayInAnotherCurrency,
} from 'actions/tracker/tracker';
import { addExtraFees } from 'actions/entities/orders/orders';
import {
  getCurrency,
  getForeignOffers,
  getLocalOffers,
  getOrderAmount,
  getRecipient,
  getSelectedOffer,
  getSenderCountry,
  isEmbedded,
  isFetching,
  isLoggedIn,
  isReadOnly,
} from 'selectors';
import {
  FF_PAYING_FROM_INDIA_IN_USD,
  FF_NON_FX_PAYMENTS_INFO,
} from 'constants/index';
import type { Offer } from 'models';
import type { RootState } from 'reducers/types';

const BANK_TRANSFER = 'bank_transfer';
const OFFER_FIELD = 'offer';
const OFFERS_ENTITY = 'offers';
const INDIA = 'IN';
const USD = 'USD';

type SinglePaymentOptionsContainerProps = ConnectedProps<typeof connector> & {
  handleOfferClickOverrider?: () => void;
};

function SinglePaymentOptionsComponent({
  addExtraFees,
  amount,
  clearOfferFields,
  country,
  fetchOffer,
  fetchOffers,
  fetchSender,
  foreigns = [],
  getCurrency,
  handleOfferClickOverrider,
  indianPayerFromMetadata,
  isEmbedded,
  isFetchingOffers = false,
  isFetchingOrder = false,
  isUserLoggedIn,
  locals = [],
  newNotification,
  readonly,
  recipient,
  selected,
  setFieldValue,
  trackCheckMarkup,
  trackHeapSelectedPaymentOption,
  trackPayInAnotherCurrency,
  trackPaymentOption,
  updateOrderWithEntities,
}: SinglePaymentOptionsContainerProps) {
  const i18n = useTranslations();
  const { goToNextStep } = useWizard();
  const [alreadySelected, setAlreadySelected] = useState(false);
  const [selectingOffer, setSelectingOffer] = useState(false);
  const [areForeignsVisible, setAreForeignsVisible] = useState(false);
  const noLocals = locals.length === 0;
  const noForeings = foreigns.length === 0;
  const noOptions = noLocals && noForeings;
  const anyLocal = locals.length > 0;
  const anyForeign = foreigns.length > 0;
  const everyLocalIsOnline = locals.length > 0 && locals.every(isOnline);
  const [nonFxInfoModalOpened, setNonFxInfoModalOpened] = useState(false);
  const [userAccessModalOpened, setUserAccessModalOpened] = useState(false);
  const [questionsModalOpened, setQuestionsModalOpened] = useState(false);
  const [questionFormSubmited, setQuestionFormSubmited] = useState(false);

  const { currentLocale: locale } = i18n;
  const { currency, id: recipientId } = recipient;
  const { data: featureFlags } = usePeertransferFlags([
    FF_NON_FX_PAYMENTS_INFO,
    FF_PAYING_FROM_INDIA_IN_USD,
  ]);

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      fetchOffers();
    }

    return () => {
      didCancel = true;
    };
  }, [amount, country, recipientId, locale, fetchOffers]);

  useEffect(() => {
    const isSelectedForeign = !!(
      selected?.id &&
      (foreigns as Offer[]).find((option: Offer) => option.id === selected.id)
    );

    if (isSelectedForeign || (noLocals && anyForeign) || everyLocalIsOnline) {
      setAreForeignsVisible(true);
    }
  }, [selected, foreigns, anyForeign, noLocals, everyLocalIsOnline]);

  const useNonFxPayments = featureFlags?.[FF_NON_FX_PAYMENTS_INFO];

  async function selectOption(optionId: string) {
    if (readonly || alreadySelected) {
      return;
    }

    setSelectingOffer(true);
    setAlreadySelected(true);

    const callback = handleOfferClickOverrider || goToNextStep;

    await clearOfferFields();

    await Promise.all([
      setFieldValue(OFFER_FIELD, optionId),
      fetchOffer(optionId),
      fetchSender(),
    ]);

    setSelectingOffer(false);
    try {
      await updateOrderWithEntities(OFFERS_ENTITY).then(callback);

      if (indianPayerFromMetadata) addExtraFees();
    } catch (error) {
      setAlreadySelected(false);
      newNotification({
        message: 'offers.select.error',
        type: 'error',
      });
    }
  }

  const payingFromIndiaInUSD =
    featureFlags?.[FF_PAYING_FROM_INDIA_IN_USD] &&
    country === INDIA &&
    currency === USD &&
    !isEmbedded;

  const handleShowForeign = () => {
    setAreForeignsVisible(!areForeignsVisible);
    trackPayInAnotherCurrency();
  };

  const nonFxCarouselOfActions = useNonFxPayments && payingFromIndiaInUSD;

  const onForeignOptionsExpandClick = () => {
    if (!nonFxCarouselOfActions || questionFormSubmited) {
      handleShowForeign();
      return;
    }
    setAreForeignsVisible(false);
    setNonFxInfoModalOpened(true);
  };

  const onContinueWithForeignOptions = () => {
    setNonFxInfoModalOpened(false);
    if (!isUserLoggedIn) {
      newNotification({
        message: keysEnum['nonFxPaymentInfo.authentication.notification'],
      });
      setUserAccessModalOpened(true);
    } else {
      setQuestionsModalOpened(true);
    }
  };

  const onAuthSuccess = () => {
    setUserAccessModalOpened(false);
    setQuestionsModalOpened(true);
  };

  const onCloseNonFxInfoModal = () => {
    setNonFxInfoModalOpened(false);
    window.scrollTo(0, 0);
  };

  const onCloseQuestionnaireModal = () => {
    setQuestionsModalOpened(false);
    window.scrollTo(0, 0);
  };

  const onSubmitNonFxQuestionnaire = () => {
    setQuestionsModalOpened(false);
    setQuestionFormSubmited(true);
    handleShowForeign();
  };

  return (
    <>
      {isFetchingOffers || isFetchingOrder || selectingOffer ? (
        <SpinnerOverlay />
      ) : (
        <Nothing />
      )}

      {isFetchingOffers ? (
        <Nothing />
      ) : noOptions ? (
        <NoOptions />
      ) : (
        <div
          data-testid="payment-options"
          className={classNames('PaymentOptions', {
            'is-open': areForeignsVisible,
          })}
        >
          {anyLocal ? (
            <div className="PaymentOptions-localOptions">
              <OptionList
                a11yMessage={i18n.t('paymentOptions.localOptions') as string}
                getCurrency={getCurrency}
                onOptionSelect={selectOption}
                options={locals}
                payingFromIndiaInUSD={payingFromIndiaInUSD}
                readonly
                trackCheckMarkup={trackCheckMarkup}
                trackHeapSelectedPaymentOption={trackHeapSelectedPaymentOption}
                trackPaymentOption={trackPaymentOption}
              />
            </div>
          ) : (
            <NoLocalOptions />
          )}

          {anyLocal && anyForeign && !everyLocalIsOnline && (
            <>
              <button
                onClick={onForeignOptionsExpandClick}
                className="PaymentOptions-showMore"
                aria-controls="PaymentOptions-foreignOptions"
                aria-expanded={areForeignsVisible}
                data-testid="showForeignOptions"
              >
                {payingFromIndiaInUSD
                  ? i18n.t('paymentOptions.showMore.fromIndiaInUSD')
                  : i18n.t('paymentOptions.showMore')}
              </button>
              {nonFxInfoModalOpened && (
                <NonFxInfoModal
                  isOpen={nonFxInfoModalOpened}
                  closeModal={onCloseNonFxInfoModal}
                  onContinueWithForeignOptions={onContinueWithForeignOptions}
                />
              )}
              {userAccessModalOpened && (
                <UserAccessModal
                  isOpen={userAccessModalOpened}
                  showSignupTab={true}
                  onClose={() => setUserAccessModalOpened(false)}
                  onLoginSuccess={onAuthSuccess}
                  onSignUpSuccess={onAuthSuccess}
                />
              )}
              {questionsModalOpened && (
                <FeedbackQuestionsModal
                  isOpen={questionsModalOpened}
                  closeModal={onCloseQuestionnaireModal}
                  onSubmit={onSubmitNonFxQuestionnaire}
                />
              )}
            </>
          )}

          {anyForeign && (
            <div
              className="PaymentOptions-foreignOptions"
              id="PaymentOptions-foreignOptions"
            >
              <EURateDisclaimer />
              <OptionList
                a11yMessage={
                  payingFromIndiaInUSD
                    ? (i18n.t(
                        'paymentOptions.foreignOptions.fromIndiaInUSD',
                      ) as string)
                    : (i18n.t('paymentOptions.foreignOptions') as string)
                }
                getCurrency={getCurrency}
                i18n={i18n}
                onOptionSelect={selectOption}
                options={foreigns}
                payingFromIndiaInUSD={payingFromIndiaInUSD}
                readonly
                trackCheckMarkup={trackCheckMarkup}
                trackHeapSelectedPaymentOption={trackHeapSelectedPaymentOption}
                trackPaymentOption={trackPaymentOption}
                unfoldImportantInfo={payingFromIndiaInUSD}
              />
              <div
                aria-live="polite"
                role="status"
                className="paymentOptions-statusMessage"
              >
                {foreigns.length === 1
                  ? i18n.t('paymentOptions.results.one')
                  : i18n.t('paymentOptions.results.many', {
                      resultCount: foreigns.length.toString(),
                    })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const isOnline = ({ paymentMethod }: { paymentMethod: string }) =>
  paymentMethod !== BANK_TRANSFER;

const mapStateToProps = (state: RootState) => ({
  amount: getOrderAmount(state),
  country: getSenderCountry(state),
  foreigns: getForeignOffers(state),
  getCurrency: (code: string) => getCurrency(state, code),
  indianPayerFromMetadata: getIndianPayerFromMetadata(state),
  isFetchingOffers: isFetching(state, 'offers'),
  isFetchingOrder: isFetching(state, 'order'),
  isUserLoggedIn: isLoggedIn(state),
  locals: getLocalOffers(state),
  readonly: isReadOnly(state, 'offer'),
  recipient: getRecipient(state),
  selected: getSelectedOffer(state),
  isEmbedded: isEmbedded(state),
});

const mapDispatchToProps = {
  addExtraFees,
  clearOfferFields,
  fetchOffer: fetchOfferFromOrder,
  fetchOffers: fetchOffersFromOrderIfNeeded,
  fetchSender: fetchSender,
  newNotification,
  setFieldValue,
  trackCheckMarkup,
  trackHeapSelectedPaymentOption,
  trackPayInAnotherCurrency,
  trackPaymentOption,
  updateOrderWithEntities,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SinglePaymentOptions = connector(SinglePaymentOptionsComponent);

export { SinglePaymentOptions };
