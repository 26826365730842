import 'whatwg-fetch';
import { camelizeKeys } from 'humps';
import {
  HTTPStatuses,
  ACCEPT_JSON_HEADER,
  GET_METHOD,
  REQUEST_ID_HEADER,
  HttpError,
  REQUESTER_HEADER,
} from '../../constants';
import { v4 } from 'uuid';
import { isHttpErrorWithStatus } from 'utils/errors/errors';

const SIX_SECONDS = 6 * 1000;

export type FetchOptions = {
  Accept?: string;
  attempts?: number;
  body?: BodyInit;
  camelize?: boolean;
  delay?: number;
  headers?: HeadersInit;
  maxRetries?: number;
  method?: string;
  timeout?: number;
};

export type FetchResponse = {
  payload: unknown;
};

export type WindowWithRequester = typeof window & {
  requester: string;
};

const defaultOptions: FetchOptions = {
  method: 'GET',
  timeout: SIX_SECONDS,
  Accept: ACCEPT_JSON_HEADER,
  maxRetries: 2,
  delay: 500,
  attempts: 0,
};

const fetchJson = async <ReturnType = unknown>(
  url: string,
  { camelize = true, ...options }: FetchOptions = {},
): Promise<ReturnType> => {
  const requester = (window as WindowWithRequester).requester;
  const headers = {
    ...(!(options.body instanceof FormData)
      ? {
          'Content-Type': ACCEPT_JSON_HEADER,
          'Access-Control-Allow-Origin': '*',
          mode: 'cors',
        }
      : {}),
    ...options.headers,
    [REQUEST_ID_HEADER]: v4(),
    ...(requester && { [REQUESTER_HEADER]: requester }),
  };
  const fetchOptions = { ...defaultOptions, ...options, headers };
  const { method, maxRetries = 2, attempts = 0 } = fetchOptions;

  try {
    const response = await fetch(url, fetchOptions);

    const json = await checkResponse(response);

    return camelize ? (camelizeJSON(json) as ReturnType) : json;
  } catch (error) {
    if (isHttpErrorWithStatus(error)) {
      const { status } = error as HttpError;
      if (
        status &&
        status >= 500 &&
        method === GET_METHOD &&
        attempts < maxRetries
      ) {
        return retry(url, fetchOptions);
      }
    }
    throw error;
  }
};

const checkResponse = (response: Response) => {
  if (response.status === HTTPStatuses.NO_CONTENT) return Promise.resolve({});

  return response.json().then(
    json => {
      if (response.ok) {
        return Promise.resolve(json);
      } else {
        const error: HttpError = new Error(`${response.status}`);
        error.status = response.status;
        error.parsedBody = json;

        return Promise.reject(error);
      }
    },
    reason => {
      const error: HttpError = new Error(`${response.status} ${reason}`);
      error.status = response.status;
      error.response = response;
      throw error;
    },
  );
};

const camelizeJSON = (json: Record<string, unknown>[]) =>
  camelizeKeys<Record<string, unknown>[]>(json, (key, convert) => {
    return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
  });

const retry = async <ReturnType = unknown>(
  url: string,
  fetchOptions: FetchOptions,
): Promise<ReturnType> => {
  const { attempts = 0, delay = 100 } = fetchOptions;
  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const incrementedAttempts = attempts + 1;

  await wait(incrementedAttempts * delay);
  return fetchJson(url, {
    ...fetchOptions,
    attempts: incrementedAttempts,
  });
};

export default fetchJson;
