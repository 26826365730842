import { keysEnum } from './constants';

export const dealerOptions = [
  {
    label: 'Ahalia Finforex Limited',
    value: 'Ahalia Finforex Limited',
  },
  {
    label: 'BFC Forex & Financial Services Pvt. Ltd.',
    value: 'BFC Forex & Financial Services Pvt. Ltd.',
  },
  {
    label: 'Capital India Finance Ltd',
    value: 'Capital India Finance Ltd',
  },
  {
    label: 'Delphi World Money Limited',
    value: 'Delphi World Money Limited',
  },
  {
    label: "D.Paul'S Travel & Tours Ltd",
    value: "D.Paul'S Travel & Tours Ltd",
  },
  {
    label: 'Ebixcash World Money Limited',
    value: 'Ebixcash World Money Limited',
  },
  {
    label: 'Gilpin Tours & Travel Mgt.(I) Pvt. Ltd.',
    value: 'Gilpin Tours & Travel Mgt.(I) Pvt. Ltd.',
  },
  {
    label: 'Indel Money Ltd.',
    value: 'Indel Money Ltd.',
  },
  {
    label: 'India Cements Capital Ltd',
    value: 'India Cements Capital Ltd',
  },
  {
    label: 'Kanji Forex Pvt. Ltd.',
    value: 'Kanji Forex Pvt. Ltd.',
  },
  {
    label: 'Kesari Tours Pvt. Ltd.',
    value: 'Kesari Tours Pvt. Ltd.',
  },
  {
    label: 'Laxmii Forex Limited',
    value: 'Laxmii Forex Limited',
  },
  {
    label: 'Lulu Forex Private Ltd',
    value: 'Lulu Forex Private Ltd',
  },
  {
    label: 'M/s Honest Deal Forex Limited',
    value: 'M/s Honest Deal Forex Limited',
  },
  {
    label: 'Make my Travel (India) Pvt Ltd.',
    value: 'Make my Travel (India) Pvt Ltd.',
  },
  {
    label: 'Manappuram Finance Ltd',
    value: 'Manappuram Finance Ltd',
  },
  {
    label: 'Matrix Forex Services Pvt Ltd',
    value: 'Matrix Forex Services Pvt Ltd',
  },
  {
    label: 'Multimoney Forex Limited',
    value: 'Multimoney Forex Limited',
  },
  {
    label: 'Muthoot Forex Ltd',
    value: 'Muthoot Forex Ltd',
  },
  {
    label: 'Muthoot Fincorp Ltd',
    value: 'Muthoot Fincorp Ltd',
  },
  {
    label: 'Nishi Forex and Leisure Pvt Ltd.',
    value: 'Nishi Forex and Leisure Pvt Ltd.',
  },
  {
    label: 'NIUM Forex India Private Limited',
    value: 'NIUM Forex India Private Limited',
  },
  {
    label: 'Orient Exchange & Financial Services Pvt. Ltd.',
    value: 'Orient Exchange & Financial Services Pvt. Ltd.',
  },
  {
    label: 'Paul Merchants Ltd',
    value: 'Paul Merchants Ltd',
  },
  {
    label: 'Pheroze Framroze & Company Private Limited',
    value: 'Pheroze Framroze & Company Private Limited',
  },
  {
    label: 'Prithivi (Exchange) India Limited',
    value: 'Prithivi (Exchange) India Limited',
  },
  {
    label: 'Quick Forex Ltd',
    value: 'Quick Forex Ltd',
  },
  {
    label: 'R R Sen and Bros Private Limited',
    value: 'R R Sen and Bros Private Limited',
  },
  {
    label: 'Revolut Forex India Pvt Ltd. (Previously Arvog Forex Pvt. Ltd.)',
    value: 'Revolut Forex India Pvt Ltd. (Previously Arvog Forex Pvt. Ltd.)',
  },
  {
    label: 'Riya Travel & ToRiya Travel & Tours (India) Pvt. Ltd.',
    value: 'Riya Travel & ToRiya Travel & Tours (India) Pvt. Ltd.',
  },
  {
    label: 'Supreme Securities Ltd.',
    value: 'Supreme Securities Ltd.',
  },
  {
    label: 'Thomas Cook (India) Ltd',
    value: 'Thomas Cook (India) Ltd',
  },
  {
    label: 'Transcorp International Ltd.',
    value: 'Transcorp International Ltd.',
  },
  {
    label: 'Unimoni Financial Services Ltd',
    value: 'Unimoni Financial Services Ltd',
  },
  {
    label: 'Vaho Forex Pvt Ltd.',
    value: 'Vaho Forex Pvt Ltd.',
  },
  {
    label: 'WSFX Global Pay Limited (erstwhile: Wall Street Finance Ltd.)',
    value: 'WSFX Global Pay Limited (erstwhile: Wall Street Finance Ltd.)',
  },
  {
    label: 'WorldOne India Forex Pvt. Limited',
    value: 'WorldOne India Forex Pvt. Limited',
  },
  {
    label: 'Xotic Travel And Forex Pvt Ltd',
    value: 'Xotic Travel And Forex Pvt Ltd',
  },
  {
    label: 'Zenith Leisure Holidays Limited',
    value: 'Zenith Leisure Holidays Limited',
  },
];

export const bankOptions = [
  {
    label: 'Axis Bank Limited',
    value: 'Axis Bank Limited',
  },
  {
    label: 'Bandhan Bank Limited',
    value: 'Bandhan Bank Limited',
  },
  {
    label: 'CSB Bank Limited',
    value: 'CSB Bank Limited',
  },
  {
    label: 'City Union Bank Limited',
    value: 'City Union Bank Limited',
  },
  {
    label: 'DCB Bank Limited',
    value: 'DCB Bank Limited',
  },
  {
    label: 'Dhanlaxmi Bank Limited',
    value: 'Dhanlaxmi Bank Limited',
  },
  {
    label: 'Federal Bank Limited',
    value: 'Federal Bank Limited',
  },
  {
    label: 'HDFC Bank Limited',
    value: 'HDFC Bank Limited',
  },
  {
    label: 'ICICI Bank Limited',
    value: 'ICICI Bank Limited',
  },
  {
    label: 'IndusInd Bank Limited',
    value: 'IndusInd Bank Limited',
  },
  {
    label: 'IDFC FIRST Bank Limited',
    value: 'IDFC FIRST Bank Limited',
  },
  {
    label: 'Jammu & Kashmir Bank Limited',
    value: 'Jammu & Kashmir Bank Limited',
  },
  {
    label: 'Karnataka Bank Limited',
    value: 'Karnataka Bank Limited',
  },
  {
    label: 'Karur Vysya Bank Limited',
    value: 'Karur Vysya Bank Limited',
  },
  {
    label: 'Kotak Mahindra Bank Limited',
    value: 'Kotak Mahindra Bank Limited',
  },
  {
    label: 'Nainital bank Limited',
    value: 'Nainital bank Limited',
  },
  {
    label: 'RBL Bank Limited',
    value: 'RBL Bank Limited',
  },
  {
    label: 'South Indian Bank Limited',
    value: 'South Indian Bank Limited',
  },
  {
    label: 'Tamilnad Mercantile Bank Limited',
    value: 'Tamilnad Mercantile Bank Limited',
  },
  {
    label: 'YES Bank Limited',
    value: 'YES Bank Limited',
  },
  {
    label: 'IDBI Bank Limited',
    value: 'IDBI Bank Limited',
  },
  {
    label: 'Bank of Baroda',
    value: 'Bank of Baroda',
  },
  {
    label: 'Bank of India',
    value: 'Bank of India',
  },
  {
    label: 'Bank of Maharashtra',
    value: 'Bank of Maharashtra',
  },
  {
    label: 'Canara Bank',
    value: 'Canara Bank',
  },
  {
    label: 'Central Bank of India',
    value: 'Central Bank of India',
  },
  {
    label: 'Indian Bank',
    value: 'Indian Bank',
  },
  {
    label: 'Indian Overseas Bank',
    value: 'Indian Overseas Bank',
  },
  {
    label: 'Punjab & Sind Bank',
    value: 'Punjab & Sind Bank',
  },
  {
    label: 'Punjab National Bank',
    value: 'Punjab National Bank',
  },
  {
    label: 'State Bank of India',
    value: 'State Bank of India',
  },
  {
    label: 'UCO Bank',
    value: 'UCO Bank',
  },
  {
    label: 'Union Bank of India',
    value: 'Union Bank of India',
  },
  {
    label: 'Au Small Finance Bank Limited',
    value: 'Au Small Finance Bank Limited',
  },
  {
    label: 'Capital Small Finance Bank Limited',
    value: 'Capital Small Finance Bank Limited',
  },
  {
    label: 'Fincare Small Finance Bank Limited',
    value: 'Fincare Small Finance Bank Limited',
  },
  {
    label: 'Equitas Small Finance Bank Limited',
    value: 'Equitas Small Finance Bank Limited',
  },
  {
    label: 'ESAF Small Finance Bank Limited',
    value: 'ESAF Small Finance Bank Limited',
  },
  {
    label: 'Suryoday Small Finance Bank Limited',
    value: 'Suryoday Small Finance Bank Limited',
  },
  {
    label: 'Ujjivan Small Finance Bank Limited',
    value: 'Ujjivan Small Finance Bank Limited',
  },
  {
    label: 'Utkarsh Small Finance Bank Limited',
    value: 'Utkarsh Small Finance Bank Limited',
  },
  {
    label: 'North East Small finance Bank Limited',
    value: 'North East Small finance Bank Limited',
  },
  {
    label: 'Jana Small Finance Bank Limited',
    value: 'Jana Small Finance Bank Limited',
  },
  {
    label: 'AB Bank Limited',
    value: 'AB Bank Limited',
  },
  {
    label: 'American Express Banking Corporation',
    value: 'American Express Banking Corporation',
  },
  {
    label: 'Australia and New Zealand Banking Group Limited',
    value: 'Australia and New Zealand Banking Group Limited',
  },
  {
    label: 'Barclays Bank Plc',
    value: 'Barclays Bank Plc',
  },
  {
    label: 'Bank of America',
    value: 'Bank of America',
  },
  {
    label: 'Bank of Bahrain & Kuwait BSC',
    value: 'Bank of Bahrain & Kuwait BSC',
  },
  {
    label: 'Bank of Ceylon',
    value: 'Bank of Ceylon',
  },
  {
    label: 'Bank of China',
    value: 'Bank of China',
  },
  {
    label: 'Bank of Nova Scotia',
    value: 'Bank of Nova Scotia',
  },
  {
    label: 'BNP Paribas',
    value: 'BNP Paribas',
  },
  {
    label: 'Citibank NA',
    value: 'Citibank NA',
  },
  {
    label:
      'Cooperatieve Rabobank UA/ Coöperatieve Centrale Raiffeisen-Boerenleenbank BA',
    value:
      'Cooperatieve Rabobank UA/ Coöperatieve Centrale Raiffeisen-Boerenleenbank BA',
  },
  {
    label: 'Credit Agricole Corporate & Investment Bank',
    value: 'Credit Agricole Corporate & Investment Bank',
  },
  {
    label: 'Credit Suisse AG',
    value: 'Credit Suisse AG',
  },
  {
    label: 'CTBC Bank Co, Limited',
    value: 'CTBC Bank Co, Limited',
  },
  {
    label: 'DBS Bank India Limited',
    value: 'DBS Bank India Limited',
  },
  {
    label: 'Deutsche Bank AG',
    value: 'Deutsche Bank AG',
  },
  {
    label: 'Doha Bank QPSC',
    value: 'Doha Bank QPSC',
  },
  {
    label: 'Emirates NBD Bank PJSC',
    value: 'Emirates NBD Bank PJSC',
  },
  {
    label: 'First Abu Dhabi Bank PJSC',
    value: 'First Abu Dhabi Bank PJSC',
  },
  {
    label: 'FirstRand Bank Limited',
    value: 'FirstRand Bank Limited',
  },
  {
    label: 'Hong Kong and Shanghai Banking Corporation Limited',
    value: 'Hong Kong and Shanghai Banking Corporation Limited',
  },
  {
    label: 'Industrial & Commercial Bank of China Limited',
    value: 'Industrial & Commercial Bank of China Limited',
  },
  {
    label: 'Industrial Bank of Korea',
    value: 'Industrial Bank of Korea',
  },
  {
    label: 'JP Morgan Chase Bank NA',
    value: 'JP Morgan Chase Bank NA',
  },
  {
    label: 'JSC VTB Bank',
    value: 'JSC VTB Bank',
  },
  {
    label: 'KEB Hana Bank',
    value: 'KEB Hana Bank',
  },
  {
    label: 'Kookmin Bank',
    value: 'Kookmin Bank',
  },
  {
    label: 'Krung Thai Bank Public Co Limited $',
    value: 'Krung Thai Bank Public Co Limited $',
  },
  {
    label: 'Mashreq bank PSC',
    value: 'Mashreq bank PSC',
  },
  {
    label: 'Mizuho Bank Limited',
    value: 'Mizuho Bank Limited',
  },
  {
    label: 'MUFG Bank, Limited',
    value: 'MUFG Bank, Limited',
  },
  {
    label: 'PT Bank Maybank Indonesia TBK',
    value: 'PT Bank Maybank Indonesia TBK',
  },
  {
    label: 'Qatar National Bank (QPSC)',
    value: 'Qatar National Bank (QPSC)',
  },
  {
    label: 'Sberbank',
    value: 'Sberbank',
  },
  {
    label: 'SBM Bank (India) Limited',
    value: 'SBM Bank (India) Limited',
  },
  {
    label: 'Shinhan Bank',
    value: 'Shinhan Bank',
  },
  {
    label: 'Societe Generale India',
    value: 'Societe Generale India',
  },
  {
    label: 'Sonali Bank PLC %',
    value: 'Sonali Bank PLC %',
  },
  {
    label: 'Standard Chartered Bank',
    value: 'Standard Chartered Bank',
  },
  {
    label: 'Sumitomo Mitsui Banking Corporation',
    value: 'Sumitomo Mitsui Banking Corporation',
  },
  {
    label: 'United Overseas Bank Limited',
    value: 'United Overseas Bank Limited',
  },
  {
    label: 'Woori Bank',
    value: 'Woori Bank',
  },
  {
    label: 'The Andhra Pradesh State Co-operative Bank Limited',
    value: 'The Andhra Pradesh State Co-operative Bank Limited',
  },
  {
    label: 'The Bihar State Co-operative Bank Limited',
    value: 'The Bihar State Co-operative Bank Limited',
  },
  {
    label: 'The Chhatisgarh Rajya Sahakari Bank Maryadit',
    value: 'The Chhatisgarh Rajya Sahakari Bank Maryadit',
  },
  {
    label: 'The Goa State Co-operative Bank Limited',
    value: 'The Goa State Co-operative Bank Limited',
  },
  {
    label: 'Gujarat State Co-operative Bank Limited',
    value: 'Gujarat State Co-operative Bank Limited',
  },
  {
    label: 'The Haryana State Co-operative Apex Bank Limited',
    value: 'The Haryana State Co-operative Apex Bank Limited',
  },
  {
    label: 'The Himachal Pradesh State Co-operative Bank Limited',
    value: 'The Himachal Pradesh State Co-operative Bank Limited',
  },
  {
    label: 'The Karnataka State Co-operative Apex Bank Limited',
    value: 'The Karnataka State Co-operative Apex Bank Limited',
  },
  {
    label: 'The Kerala State Co-operative Bank Limited',
    value: 'The Kerala State Co-operative Bank Limited',
  },
  {
    label: 'The Madhya Pradesh Rajya Sahakari Bank Maryadit',
    value: 'The Madhya Pradesh Rajya Sahakari Bank Maryadit',
  },
  {
    label: 'The Maharashtra State Co-operative Bank Limited',
    value: 'The Maharashtra State Co-operative Bank Limited',
  },
  {
    label: 'The Odisha State Co-operative Bank Limited',
    value: 'The Odisha State Co-operative Bank Limited',
  },
  {
    label: 'The Puducherry State Co-operative Bank Limited',
    value: 'The Puducherry State Co-operative Bank Limited',
  },
  {
    label: 'The Punjab State Co-operative Bank Limited',
    value: 'The Punjab State Co-operative Bank Limited',
  },
  {
    label: 'The Rajasthan State Co-operative Bank Limited',
    value: 'The Rajasthan State Co-operative Bank Limited',
  },
  {
    label: 'The Tamil Nadu State Apex Co-operative Bank Limited',
    value: 'The Tamil Nadu State Apex Co-operative Bank Limited',
  },
  {
    label: 'The Telangana State Cooperative Apex Bank Limited',
    value: 'The Telangana State Cooperative Apex Bank Limited',
  },
  {
    label: 'The Uttar Pradesh Co-operative Bank Limited',
    value: 'The Uttar Pradesh Co-operative Bank Limited',
  },
  {
    label: 'The Uttarakhand State Co-operative Bank Limited',
    value: 'The Uttarakhand State Co-operative Bank Limited',
  },
  {
    label: 'The West Bengal State Co-operative Bank Limited',
    value: 'The West Bengal State Co-operative Bank Limited',
  },
  {
    label: 'Tripura State Co-operative Bank Limited',
    value: 'Tripura State Co-operative Bank Limited',
  },
  {
    label: 'The Delhi State Cooperative Bank Limited',
    value: 'The Delhi State Cooperative Bank Limited',
  },
  {
    label: 'The Meghalaya Co-operative Apex Bank Limited',
    value: 'The Meghalaya Co-operative Apex Bank Limited',
  },
  {
    label: 'Sikkim State Co-operative Bank Limited',
    value: 'Sikkim State Co-operative Bank Limited',
  },
  {
    label: 'Other Banks (not part of list above)',
    value: 'Other Banks (not part of list above)',
  },
];

export const nonBankingOptions = [
  {
    label: 'Credila',
    value: 'Credila',
  },
  {
    label: 'Avanse',
    value: 'Avanse',
  },
  {
    label: 'Incred',
    value: 'Incred',
  },
  {
    label: 'Auxilo',
    value: 'Auxilo',
  },
  {
    label: 'Tata Capital',
    value: 'Tata Capital',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const usdPaymentReasonsOptions = [
  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.betterFXRate'
      ],
    value: 'usdBetterRate',
  },
  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.usdBankAccount'
      ],
    value: 'usdBanks',
  },
  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.usdPaymentReasonOptions.anotherProvider'
      ],
    value: 'usdOther',
  },
];

export const payerOptions = [
  {
    label: keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.student'],
    value: 'student',
  },
  {
    label:
      keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.familyMember'],
    value: 'familyMember',
  },
  {
    label: keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.agent'],
    value: 'agent',
  },

  {
    label: keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.dealer'],
    value: 'dealer',
  },
  {
    label: keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.bank'],
    value: 'bank',
  },
  {
    label:
      keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.nonBanking'],
    value: 'nonBanking',
  },
  {
    label: keysEnum['nonFxPaymentInfo.feedbackQuestions.payerOptions.other'],
    value: 'other',
  },
];

export const sourceOfFundsOptions = [
  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.savings'
      ],
    value: 'savings',
  },
  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.loanCredila'
      ],
    value: 'credila',
  },
  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.bankLoans'
      ],
    value: 'banks',
  },
  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.otherInstitutions'
      ],
    value: 'otherInstitutions',
  },

  {
    label:
      keysEnum[
        'nonFxPaymentInfo.feedbackQuestions.sourceOfFundsOptions.savingsAndLoans'
      ],
    value: 'savingsAndLoans',
  },
];
