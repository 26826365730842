import React from 'react';
import { CardTokenizationProvider } from 'contexts/CardTokenizationContext/CardTokenizationContext';
import { CardTokenizationForm } from 'components/CardTokenizationForm/CardTokenizationForm';
import {
  CardTokenizationEvent,
  CardInstructionsAttributes,
} from 'services/tokenization/models/models';

import './CreditCardTokenization.scss';

type CreditCardTokenizationProps = {
  className?: string;
  instructionsAttributes: CardInstructionsAttributes;
  showDisclaimer?: boolean;
  onSuccess: (tokenizationResult: CardTokenizationEvent) => void;
  onError: () => void;
};

const CreditCardTokenization = ({
  className,
  instructionsAttributes,
  showDisclaimer,
  onError,
  onSuccess,
}: CreditCardTokenizationProps) => (
  <CardTokenizationProvider
    instructionsAttributes={instructionsAttributes}
    onSuccess={onSuccess}
    onError={onError}
  >
    <div className={className}>
      <CardTokenizationForm showDisclaimer={showDisclaimer} />
    </div>
  </CardTokenizationProvider>
);

export { CreditCardTokenization };
