import React from 'react';
import classNames from 'classnames';
import { Option } from './components/Option/Option';
import { Currency, Offer, Price } from 'models';

type OptionItem = Omit<Offer, 'price'> & {
  price: Omit<Price, 'currency'> & {
    currency: string;
  };
  interval?: string;
  chargeDay?: number;
};

type OptionListProps = {
  a11yMessage: string;
  getCurrency: (currency: string) => Currency;
  onOptionSelect?: (id: string) => void;
  options: OptionItem[];
  payingFromIndiaInUSD: boolean;
  readonly?: boolean;
  trackCheckMarkup: () => void;
  trackHeapSelectedPaymentOption: () => void;
  trackPaymentOption: (paymentOption: string, value: string) => void;
  unfoldImportantInfo?: boolean;
};

function OptionList({
  a11yMessage,
  getCurrency,
  onOptionSelect,
  options = [],
  payingFromIndiaInUSD,
  readonly,
  trackCheckMarkup,
  trackHeapSelectedPaymentOption,
  trackPaymentOption,
  unfoldImportantInfo = false,
}: OptionListProps) {
  return (
    <ul
      className={classNames('PaymentOptions-offersList', {
        'PaymentOptions--readOnly': readonly,
      })}
      aria-label={a11yMessage}
    >
      {options.map(option => {
        const currency = getCurrency(option.price.currency);

        return (
          <Option
            {...option}
            currency={currency}
            key={option.id}
            onSelect={onOptionSelect}
            payingFromIndiaInUSD={payingFromIndiaInUSD}
            trackCheckMarkup={trackCheckMarkup}
            trackHeapSelectedPaymentOption={trackHeapSelectedPaymentOption}
            trackPaymentOption={trackPaymentOption}
            unfoldImportantInfo={unfoldImportantInfo}
          />
        );
      })}
    </ul>
  );
}

export { OptionList };
