import { combineReducers } from 'redux';
import {
  FETCH_RECIPIENT_SUCCESS,
  FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS,
} from 'constants/index';
import type { Section } from 'models';

type RecipientAction = {
  type:
    | typeof FETCH_RECIPIENT_SUCCESS
    | typeof FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS;
  payload: {
    entities: {
      sections: Record<string, Section>;
    };
  };
};

export const byId = (
  state: Record<string, Section> = {},
  action: RecipientAction,
) => {
  switch (action.type) {
    case FETCH_RECIPIENT_SUCCESS:
    case FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS:
      const {
        entities: { sections = {} },
      } = action.payload;
      return sections;
    default:
      return state;
  }
};

export const ids = (state: string[] = [], action: RecipientAction) => {
  switch (action.type) {
    case FETCH_RECIPIENT_SUCCESS:
    case FETCH_RECIPIENT_FROM_PAYMENT_SUCCESS:
      const {
        entities: { sections = {} },
      } = action.payload;
      return Object.keys(sections);
    default:
      return state;
  }
};

export default combineReducers({ byId, ids });
