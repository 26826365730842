import {
  getPaymentPrice,
  getPaymentPurchase,
  getRecipientFieldValue,
  getSenderFieldValue,
} from 'selectors';
import type { PayexThunkAction } from 'store/configureStore';

export const createPreorderFromPayment =
  (productType: string): PayexThunkAction =>
  async (_dispatch, getState, { studentExpenses }) => {
    const DEFAULT_PREORDER_URL =
      'https://studentservices.flywire.com/bank-transfer?utm_source=payex&utm_medium=referral&utm_campaign=banktransfer_banner_12.14';

    const state = getState();
    try {
      const price = getPaymentPrice(state);
      const purchase = getPaymentPurchase(state);
      const senderEmail = getSenderFieldValue(state, 'email') as string;
      const senderFirstName = getSenderFieldValue(
        state,
        'first_name',
      ) as string;
      const senderLastName = getSenderFieldValue(state, 'last_name') as string;
      const studentFirstName = getRecipientFieldValue(
        state,
        'student_first_name',
      ) as string;
      const studentLastName = getRecipientFieldValue(
        state,
        'student_last_name',
      ) as string;

      const preOrder = await studentExpenses.preOrders.create({
        currencyFrom: price.currency as string,
        currencyTo: purchase.currency as string,
        productType: productType,
        remitter: {
          email: senderEmail,
          firstName: senderFirstName,
          lastName: senderLastName,
        },
        beneficiary: {
          student: {
            firstName: studentFirstName,
            lastName: studentLastName,
          },
        },
      });

      const newWindow = window.open(
        preOrder.url,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
    } catch (e) {
      window.open(DEFAULT_PREORDER_URL, '_blank', 'noopener,noreferrer');
    }
  };
