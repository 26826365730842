import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { WithTranslations } from 'components/HOC/WithTranslations/WithTranslations';
import { WithFlysiteUrls } from 'components/HOC/WithFlysiteUrls/WithFlysiteUrls';
import { cookies } from 'utils/cookies/cookies';
import { COOKIE_NAME } from 'constants/index';
import { isEmbedded, isEUMemberAccess, isFetching } from 'selectors';

import './CookiesBanner.scss';

class CookiesBannerComponent extends Component {
  state = { hasCookie: cookies.read(COOKIE_NAME) };

  handleClick = () => {
    cookies.set(COOKIE_NAME, true);

    this.setState({
      hasCookie: true,
    });
  };

  render() {
    const { cookiePolicyUrl, i18n, isEmbedded, isEUMemberAccess, isFetching } =
      this.props;
    const { hasCookie } = this.state;

    if (isFetching || isEmbedded) return null;

    const shouldShowCookieBanner = !hasCookie && isEUMemberAccess;
    const link = (
      <a
        href={cookiePolicyUrl}
        title={i18n.t('cookiesBanner.linkTitle')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {i18n.t('cookiesBanner.linkTitle')}
      </a>
    );

    return (
      shouldShowCookieBanner && (
        <div
          className="CookiesBanner"
          role="dialog"
          aria-describedby="CookiesBanner-content"
          aria-label="Cookie Law"
        >
          <button
            type="button"
            className="CookiesBanner-close"
            onClick={this.handleClick}
            aria-label="Close"
          />
          <div id="CookiesBanner-content">
            {i18n.t('cookiesBanner.description')} {link}
          </div>
        </div>
      )
    );
  }
}

CookiesBannerComponent.propTypes = {
  cookiePolicyUrl: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  isEUMemberAccess: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  isFetching: PropTypes.bool,
};

const mapStateToProps = state => ({
  isEmbedded: isEmbedded(state),
  isFetching: isFetching(state, 'geolocation'),
  isEUMemberAccess: isEUMemberAccess(state),
});

const CookiesBanner = WithTranslations(
  WithFlysiteUrls(connect(mapStateToProps)(CookiesBannerComponent)),
);

export { CookiesBanner };
